import React, { useState, useEffect, useRef  } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useMutation } from "graphql-hooks";
import { useNavigate } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import logoImg from "../../assets/preditcal_logo.png";

import { seo_tag_config } from "../../store/data/Constants";

import { FORGET_PASSWORD_MUTATION } from "../../graphQL/user_qry";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 416,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    height: 416,
    width: 430,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 450,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 450,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
    //  maxWidth: 700,
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const ForgetPass = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({mode: "all"});

  const navigate = useNavigate();
  const form = useRef(null);
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [email, setEmail] = useState("");

  const [forgetPassword] = useMutation(FORGET_PASSWORD_MUTATION);


  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  setTimeout(() => {
    setSuccessMsg("");
    setErrorMsg("");
  }, 30000);

  /*##########################################################################
  ## Const : onSubmit
  ## Description : Complete the sumbit functionality of the data form 
  ## Author : SF
  ## Date: : 2023/03/12
  ##########################################################################*/

  const onSubmit = async (formData) => {
    await sleep(2000);

    const { data, error } = await forgetPassword({
      variables: {
        email: formData.email,
      },
    });
    if (error) {
      console.log("Error", error, data);
      setErrorMsg(error.graphQLErrors[0].message);
      setTimeout("");
    } else {
      console.log("Success");
      setSuccessMsg(
        "An email will be sent to the email address you provided. Please follow the instructions in that email."
      );
      setTimeout("");
      // navigate("/lms/signin");
    }
  };

  /*##########################################################################
  ## Const : emailChanged
  ## Description : Email Changed functionality 
  ## Author : SF
  ## Date: : 2023/03/12
  ##########################################################################*/

  const emailChanged = (event) => {
    setEmail(event.target.value);
  };

  /*##########################################################################
  ## Render Section
  ##########################################################################*/

  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Forgot password</title>
          <meta 
            name="description" 
            content="Predcital forgot password to football team prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, forgot password, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }
      
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        {mobileView && (
          <Card className={classes.cardMobile}>
            <CardContent className={classes.cardContentMobile}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={1}
                >
                  <div>
                    <Typography gutterBottom variant="subtitle2">
                      Forgot password
                    </Typography>
                  </div>
                </Box>
                <Typography variant="body2">
                  Predictal is a premier global destination that connects sports
                  fans to share and challenge their knowledge about sports in a
                  safe and collaborative environment. At its inaugural launch,
                  Predictal is home to the leading digital experience platform for
                  Team Selector in the sport of football.
                </Typography>
              </Box>
              <Box flexGrow={1} mb={3}>
                <TextField
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  required
                  label="Email" // Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" 
                ? (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      Email cannot be blank.
                    </FormHelperText>
                  </Box>
                ) : (errors.email && errors.email.type === "pattern")
                  ? (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Invalid email format.
                      </FormHelperText>
                    </Box>
                  ) : errorMsg
                    ? (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          {errorMsg}
                        </FormHelperText>
                      </Box>
                    ) : (
                      <Box ml={2}>
                        <Typography variant="h6">
                           Enter your email
                        </Typography>
                      </Box>
                    )
                }                 
                {successMsg && (
                  <Box mt={2} className={classes.successStyleMobile}>
                    {successMsg}
                  </Box>
                )}
                <Box
                  mt={successMsg ? 2 : 5}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
        {tabletView && (
          <Container className={classes.cardContainerTablet} maxWidth="sm">
            <Card className={classes.cardTablet}>
              <CardContent className={classes.cardContentTablet}>
                <Box alignItems="center" mb={3}>
                  <Box
                    flexGrow={1}
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <div>
                      <Typography gutterBottom variant="subtitle1">
                        Forgot password
                      </Typography>
                    </div>
                  </Box>
                  <Typography variant="body1">
                    Predictal is a premier global destination that connects sports
                    fans to share and challenge their knowledge about sports in a
                    safe and collaborative environment. At its inaugural launch,
                    Predictal is home to the leading digital experience platform
                    for Team Selector in the sport of football.
                  </Typography>
                </Box>
                <Box flexGrow={1} mb={3}>
                  <TextField
                    className={classes.textFieldStyle}
                    fullWidth
                    required
                    label="Email" // Address"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email && errors.email.type === "required" 
                  ? (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Email cannot be blank.
                      </FormHelperText>
                    </Box>
                  ) : (errors.email && errors.email.type === "pattern")
                    ? (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          Invalid email format.
                        </FormHelperText>
                      </Box>
                    ) : errorMsg
                      ? (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            {errorMsg}
                          </FormHelperText>
                        </Box>
                      ) : (
                        <Box ml={2}>
                          <Typography variant="h5">
                            Enter your email
                          </Typography>
                        </Box>
                      )
                  }                  
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box
                    mt={successMsg ? 2 : 5}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>
        )}
        {!mobileView && !tabletView && (
          <Container className={classes.cardContainer} maxWidth="sm">
            <Card style={{ display: "flex", flexDirection: "row" }}>
              <CardContent className={classes.cardContent}>
                <Box alignItems="center" mb={3} flexGrow={1}>
                  <Box mb={2}>
                    <img src={logoImg} alt="logo" />
                  </Box>
                  <Typography variant="body1">
                    Predictal is a premier global destination that connects sports
                    fans to share and challenge their knowledge about sports in a
                    safe and collaborative environment. At its inaugural launch,
                    Predictal is home to the leading digital experience platform
                    for Team Selector in the sport of football.
                  </Typography>
                </Box>
              </CardContent>
              <Divider orientation="vertical" flexItem />
              <CardContent className={classes.cardContent}>
                <Box
                  //  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography gutterBottom variant="subtitle1">
                    Forgot password
                  </Typography>
                </Box>

                <Box flexGrow={1}>
                  <TextField
                    className={classes.textFieldStyle}
                    fullWidth
                    //autoFocus
                    required
                    label="Email" // Address"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email && errors.email.type === "required" 
                  ? (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Email cannot be blank.
                      </FormHelperText>
                    </Box>
                  ) : (errors.email && errors.email.type === "pattern")
                    ? (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          Invalid email format.
                        </FormHelperText>
                      </Box>
                    ) : errorMsg
                      ? (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            {errorMsg}
                          </FormHelperText>
                        </Box>
                      ) : (
                        <Box ml={2}>
                          <Typography variant="h5">
                            Enter your email
                          </Typography>
                        </Box>
                      )
                  }                  
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box
                    mt={successMsg ? 2 : 5}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>
        )}
      </form>
    </>
  );
};

export default ForgetPass;
