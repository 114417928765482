import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../graphQL/formationToLeague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function LeagueFormationsList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [leagueFormationsData, setLeagueFormationsData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const navigate = useNavigate();
  const [fetchLeagueFormations] = useManualQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: leaguesLoading,
    error: leaguesError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!leaguesLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
      }
      if (
        leaguesError &&
        leaguesError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );

  const handleChange = async e => {
    const lId = e.target.value;
    console.log(lId);
    const {
      loading: leagueFormationLoading,
      error: leagueFormationError,
      data: leagueFormationData
    } = await fetchLeagueFormations({
      variables: { leagueId: parseInt(lId) }
    });

    if (
      !leagueFormationLoading &&
      leagueFormationData &&
      leagueFormationData.leagueFormation
    ) {
      console.log(leagueFormationData);
      setLeagueFormationsData(leagueFormationData.leagueFormation);
    }
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",

      width: 120,
      editable: false
    },
    {
      field: "primaryFormation",
      headerName: "Primary Formation",
      width: 200,
      editable: false
    },
    {
      field: "formationSeqNO",
      headerName: "Formation Seq. No.",
      width: 250,
      editable: false
    },
    {
      field: "description",
      headerName: "Description",
      width: 600,
      editable: false
    }
  ];

  const rows = leagueFormationsData.map((leagueFormation, id) => ({
    id: leagueFormation.formationId.formationId,
    title: leagueFormation.formationId.title,
    primaryFormation: leagueFormation.formationId.formationPrimary,
    formationSeqNO: leagueFormation.formationId.formationSeqNO,
    description: leagueFormation.formationId.description
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | League Formations | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of League Formations</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>

          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/league-formation/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
