import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  useMutation,
  ClientContext,
  useManualQuery,
  useQuery,
} from "graphql-hooks";
import { useForm } from "react-hook-form";
import {project_url, admin_url} from "../store/data/Constants";

import { useNavigate, Link } from "react-router-dom";

import { DataGrid } from "@material-ui/data-grid";
import {
  gridDateComparator,
  gridNumberComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import { Grid, Typography, Box, Paper, IconButton } from "@material-ui/core";
import Button from "@mui/material/Button";

import CircularProgress from "@mui/material/CircularProgress";

import AlertDialog from "../components/AlertDialog.js";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from "@material-ui/core";

import CloseIcon from "@mui/icons-material/Close";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../graphQL/user_qry";

import { GET_ALL_SLEAGUES_QUERY } from "../graphQL/sleague_qry";

import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";

import {
  FAV_TEAMS_FIXTURES_QUERY,
  GET_SELECTED_TEAM_FIXTURES,
} from "../graphQL/fixture_qry";

import { GET_ALL_SETTINGS_QUERY } from "../graphQL/setting_qry";

import adminStyle from "./Admin.module.scss";

import SelectedTeamCoachTeamSelectorDialog from "./selectedTeamCoach/CoachTeamSelectorDialog.js";

import { makeStyles } from "@material-ui/core";

import _ from "lodash";

const FIXTURE_TEAM_SEQs_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    coachSeq
  }
}`;

const COMMUNITY_SEQ_QUERY = `query favTeamCommunityTS($fixtureTeamId:Int!, $algorithmId:Int!){
  favTeamCommunityTS(fixtureTeamId:$fixtureTeamId, algorithmId: $algorithmId){
    communitySeq
  }
}`;

/////###################################
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
}));
///#############

export default function TeamSelectionResults() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [sortModel, setSortModel] = React.useState([
    {
      field: "week",
      sort: "asc",
    },
  ]);

  const useStyles = makeStyles({
    linkStyle: {
      marginRight: "5px",
      marginLeft: "5px",
    },
    rightStyle: {
      marginRight: "5px",
    },
    leftStyle: {
      marginLeft: "5px",
    },
    root: {
      border: "solid 1px #e0e7cf",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: "#ec645f",
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: 12,
          color: "#ffffff",
          fontFamily: "Verdana, Arial, Helvetica, sans-serif",
        },
      },
      "& .MuiDataGrid-renderingZone": {
        "& .MuiDataGrid-row": {
          backgroundColor: "#fdfff8",
          margin: 0,
          fontSize: 13,
          // padding: "7px 2px 7px 2px",
          "&:nth-child(2n)": { backgroundColor: "#ebd7f4" /*"#f5ffdf"*/ },
          "&:hover": { backgroundColor: "#ce9be4" /*"#e7f7c2"*/ },
        },
      },
    },
  });

  const classes = useStyles();
  const navigate = useNavigate();
  const client = useContext(ClientContext);

  const [userData, setUserData] = useState([]);


  const [teamsData, setTeamsData] = useState(JSON.parse(localStorage.getItem("ReturnFromOtherPages")) === true ?
      JSON.parse(localStorage.getItem("LeagueTeams")) : []);

  const [selectedTeam, setSelectedTeam] = useState(JSON.parse(localStorage.getItem("ReturnFromOtherPages")) === true ?
      parseInt(localStorage.getItem("TeamId")) : -1);

  const [fixturesData, setFixturesData] = useState(JSON.parse(localStorage.getItem("ReturnFromOtherPages")) === true ?
      JSON.parse(localStorage.getItem("FixturesData")) : []);

  const [selectedFixtureId, setSelectedFixtureId] = useState(0);
  const [selectedFixtureTeam, setSelectedFixtureTeam] = useState([]);

  const [selectedFixtureName, setSelectedFixtureName] = useState("");

  const [sleaguesData, setSleaguesData] = useState([]);

  const [embargoTime, setEmbargoTime] = useState(0);

  const [alertMsg, setAlertMsg] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [currLeagueSeasonId, setCurrLeagueSeasonId] =
    useState(JSON.parse(localStorage.getItem("ReturnFromOtherPages")) === true ?
      parseInt(localStorage.getItem("LeagueSeasonId")) : -1);



  const [open, setOpen] = React.useState(false);

  // Get "coachSeq" and value for a fixture team
  const [fixtureTeamSeqs] = useManualQuery(FIXTURE_TEAM_SEQs_QUERY);

  // Get "communitySeq" value for a fixture team
  const [communityTsSeq] = useManualQuery(COMMUNITY_SEQ_QUERY);

  // Get Setting value; need to find embargo time
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: dataSettings,
  } = useQuery(GET_ALL_SETTINGS_QUERY);

  // Get "Embargo Time" from DB
  useEffect(() => {
    if (!loadingSettings && dataSettings && dataSettings.allSettings) {
      const findSetting = dataSettings.allSettings.find(
        (setting) => setting.name === "Embargo Time (in hours)"
      );
      findSetting !== undefined
        ? setEmbargoTime(findSetting.value)
        : setEmbargoTime(4);
      localStorage.setItem("ReturnFromOtherPages", false);
    }
  }, [dataSettings]);

  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchFixtures] = useManualQuery(GET_SELECTED_TEAM_FIXTURES);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });

  const chkCondition = (fixture) => {

    let fTime =
      new Date(fixture.fixtureWeek.scheduledDate).getTime() - Date.now();
    // is embargo return false
    if (fTime > embargoTime * 3600000) /*not embargo*/ return true;
    else return false;
  };

  const chkEmbargo = (fixture) => {
    // Time remaining until the start of the fixture
    let timeDiff =
      new Date(fixture.fixtureWeek.scheduledDate).getTime() - Date.now();
    if (
      Date.now() > new Date(fixture.fixtureWeek.scheduledDate).getTime() ||
      timeDiff > embargoTime * 3600000
    )
      /*not embargo*/ return false;
    else return true;
  };

  const chkCoachComDataIsAvailable = async (fixtureTeamId, teamType) => {
    if (teamType === "Community") {
      let { loading, error, data } = await communityTsSeq({
        variables: {
          fixtureTeamId: parseInt(fixtureTeamId),
          algorithmId: 1,
        },
      });
      if (data.favTeamCommunityTS && data.favTeamCommunityTS.communitySeq)
        return true;
      else return false;
    } else if (teamType === "Coach") {
      let { loading, error, data } = await fixtureTeamSeqs({
        variables: {
          fixtureTeamId: parseInt(fixtureTeamId),
        },
      });
      if (data.fixtureTeamById && data.fixtureTeamById.coachSeq) return true;
      else return false;
    } else {
      return false;
    }
  };

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });

  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "day").value +
        " " +
        dayFormat.find((a) => a.type == "month").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value +
        " - " +
        dayFormat.find((a) => a.type == "hour").value +
        ":" +
        dayFormat.find((a) => a.type == "minute").value +
        " " +
        dayFormat.find((a) => a.type == "dayPeriod").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value +
        " - " +
        dayFormat.find((a) => a.type == "hour").value +
        ":" +
        dayFormat.find((a) => a.type == "minute").value +
        " " +
        dayFormat.find((a) => a.type == "dayPeriod").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value +
        " - " +
        dayFormat.find((a) => a.type == "hour").value +
        ":" +
        dayFormat.find((a) => a.type == "minute").value +
        " " +
        dayFormat.find((a) => a.type == "dayPeriod").value;
      return newFormat;
    }
  };

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpen = (fixtureTeam, fixtureTeamName) => {
    setSelectedFixtureId(fixtureTeam.fixtureTeamId);
    setSelectedFixtureTeam(fixtureTeam);
    setSelectedFixtureName(fixtureTeamName);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedFixtureId(0);
    setSelectedFixtureTeam([]);
    setOpen(false);
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              align: "right",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleSLeagueChange = async (e) => {
    const tid = e.target.value;

    if (e.target.value !== ''){
      setCurrLeagueSeasonId(parseInt(e.target.value));
      localStorage.setItem("LeagueSeasonId", e.target.value);
    }
    localStorage.setItem("ReturnFromOtherPages", false);
    setSelectedTeam(-1);
    setFixturesData([]);
    const {
      loading: teamLoading,
      error: teamError,
      data: teamData,
    } = await fetchTeams({
      variables: {
        sleagueId: parseInt(tid),
      },
    });

    if (!teamLoading && teamData && teamData.sleagueTeam) {
      setTeamsData(teamData.sleagueTeam);

      localStorage.setItem("LeagueTeams", JSON.stringify(teamData.sleagueTeam));
    }
    if (
      teamError &&
      teamError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleTeamChange = async (e) => {
    const tid = e.target.value;

    if (e.target.value !== ''){
      setSelectedTeam(parseInt(e.target.value));
      localStorage.setItem("TeamId", e.target.value);
    } else {
      localStorage.setItem("ReturnFromOtherPages", false);
    }
    setSelectedTeam(tid);

    const {
      loading: fixtureLoading,
      error: fixtureError,
      data: fixtureData,
    } = await fetchFixtures({
      variables: {
        sleagueTeamId: parseInt(tid),
      },
    });

    if (!fixtureLoading && fixtureData && fixtureData.selectedTeamFixtures) {
      setFixturesData([]);
      localStorage.setItem("FixturesData", []);
      let teams = fixtureData.selectedTeamFixtures;

      let ars = [];
      teams.map((team) => {
        let a = team.fixtureId.fixtureId.toString();

        if (ars[a] === undefined) {
          ars[a] = [];
          ars[a].push(team);
        } else {
          ars[a].push(team);
        }
      });

      setFixturesData(ars);
      localStorage.setItem("FixturesData",
          JSON.stringify(ars));
    }
    if (
      fixtureError &&
      fixtureError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const columns = [
    {
      field: "fixture",
      headerName: "Fixture (Team Selection)",
      width: 550,
      editable: false,
      headerAlign: "center",
      renderCell: (teams) => {
        let home = teams.value[0];
        let guest = teams.value[1];
        if (teams.value[1].home) {
          home = teams.value[1];
          guest = teams.value[0];
        }

        let fixtureTeamId =
          selectedTeam.toString() === home.sleagueTeamId.sleagueTeamId
            ? home.fixtureTeamId
            : guest.fixtureTeamId;


        return (
          <>

           <Typography className={adminStyle.typography}>

            <Link
              to=""
              onClick={async (e) => {
                e.preventDefault();
                handleOpen(
                  home,
                  home.sleagueTeamId.teamId.shortName
                );
              }}
            >

              {home.sleagueTeamId.teamId.shortName}{" "}
            </Link>
            &nbsp;{"("}
            {home.scoreFinal}
            {")"}
            &nbsp;{"-"}&nbsp;
            <Link
              to=""
              onClick={async (e) => {
                e.preventDefault();
                handleOpen(
                  guest,
                  guest.sleagueTeamId.teamId.shortName
                );
              }}
            >
              {guest.sleagueTeamId.teamId.shortName}
            </Link>
            &nbsp;{"("}
            {guest.scoreFinal}
            {")"}&nbsp;
            </Typography>
            {"("}
            <Link
              to={`/lms/${admin_url}/ts/3teams/${fixtureTeamId}`}
              onClick={async (e) => {
                e.preventDefault();
                let coachComData = await chkCoachComDataIsAvailable(
                  fixtureTeamId,
                  "Community"
                );
                // is not in embargo and community team is not created
                if (
                  !chkEmbargo(home.fixtureId) &&
                  !coachComData
                ) {
                  setAlertMsg(
                    "The Community Team will be published shortly after the fixture starts."
                  );
                  setOpenDialog(true);
                } else if (
                  //is in embargo has no data
                  chkEmbargo(home.fixtureId) &&
                  !coachComData
                ) {
                  setAlertMsg(
                    "This fixture is in embargo at this time. The Community Team will be published shortly after the fixture starts."
                  );
                  setOpenDialog(true);
                } else
                  window.open(
                    `/lms/${admin_url}/ts/3teams/${fixtureTeamId}`,
                    "_self"
                  );
              }}
              target="_self"
            >
              Community
            </Link>
            &nbsp;{"|"}&nbsp;
            <Link
              to={`/lms/${admin_url}/ts/3teams/${fixtureTeamId}`}
              onClick={async (e) => {
                e.preventDefault();
                let coachDataReady = await chkCoachComDataIsAvailable(
                  fixtureTeamId,
                  "Coach"
                );
                // is not in embargo and coach team is not created
                if (
                  !chkEmbargo(home.fixtureId) &&
                  !coachDataReady
                ) {
                  setAlertMsg(
                    "The Coach Team will be published shortly after the fixture starts."
                  );
                  setOpenDialog(true);
                }  else if (
                  //is in embargo has no data
                  chkEmbargo(home.fixtureId) &&
                  !coachDataReady
                ) {
                  setAlertMsg(
                    "This fixture is in embargo at this time. The Coach Team will be published shortly after the fixture starts."
                  );
                  setOpenDialog(true);
                } else
                  window.open(
                    `/lms/${admin_url}/ts/3teams/${fixtureTeamId}`,
                    "_self"
                  );
              }}
              target="_self"
            >
              Coach
            </Link>
            &nbsp;{"|"}&nbsp;
            <Link
              to={`/lms/${admin_url}/ts/votes/${fixtureTeamId}`}
              onClick={(e) => {
                e.preventDefault();
                // is not in embargo and no vote data

                let votes =
                  selectedTeam === teams.value[0].sleagueTeamId.sleagueTeamId
                    ? teams.value[0].votsFormations.totalVots
                    : teams.value[1].votsFormations.totalVots;

                if (
                  !chkEmbargo(home.fixtureId) &&
                  votes === "Stay Tuned"
                ) {
                  setOpenDialog(true);
                  setAlertMsg(
                    "The Community Votes will be available for viewing shortly after the fixture has started"
                  );
                } else if (chkEmbargo(home.fixtureId) ) {
                  // is in embargo
                  setAlertMsg(
                    "This fixture is in embargo at this time. The Community Votes will be published shortly after the fixture starts."
                  );
                  setOpenDialog(true);
                }  else
                  window.open(
                    `/lms/${admin_url}/ts/votes/${fixtureTeamId}`,
                    "_self"
                  );
              }}
              target="_self"
            >
              Votes
            </Link>
            {")"}
          </>
        );
      },
    },
    {
      field: "week",
      headerName: "Match Week",
      width: 160,
      headerAlign: "center",
      renderCell: (teams) => {
        let week = teams.value.replace(/Week-/gi, "");

        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {week}
          </div>
        );
      },
      sortComparator: gridStringOrNumberComparator,
    },
    {
      field: "date",
      headerName: "Date (Your local time)",
      width: 250,
      headerAlign: "center",
      renderCell: (teams) => {
        return <>{dayFormat(teams.value)}</>;
      },
    },

    {
      field: "votes",
      headerName: "Total Votes",
      width: 160,
      headerAlign: "center",
      renderCell: (team) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {selectedTeam === team.value[0].sleagueTeamId.sleagueTeamId
              ? team.value[0].votsFormations.totalVots
              : team.value[1].votsFormations.totalVots}
          </div>
        );
      },
    },

    {
      field: "formation",
      headerName: "Popular Formation",
      width: 270,
      headerAlign: "center",
      renderCell: (team) => {
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {selectedTeam === team.value[0].sleagueTeamId.sleagueTeamId
              ? team.value[0].votsFormations.popularFormation
              : team.value[1].votsFormations.popularFormation}
          </div>
        );
      },
    },
  ];

  const filteredFixData = (fixturesData !== null ? fixturesData.filter(e => e != null) : [])


  const rows = filteredFixData.map((tm, index) => ({
    id: index,
    fixture: tm,
    week: tm[0].fixtureId.fixtureWeek.weekId.name,
    date: tm[0].fixtureId.fixtureWeek.scheduledDate,
    votes: tm,
    formation: tm,
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Team Selection Results | Admin</title>
      </Helmet>
      <div>
        <h3 align="center">Team Selection Results</h3>

        <Grid item>
          <form className={adminStyle.form}>
            <label className={adminStyle.label} htmlFor="season">
              League Season
            </label>
            <select
              className={adminStyle.select}
              id="season"
              {...register("sleague")}
              onChange={handleSLeagueChange}
              value={currLeagueSeasonId}
            >
              <option value="0">Select a League Season...</option>
              {sleaguesData.map((sleague, i) => (
                <option key={i} value={sleague.sleagueId}>
                  {sleague.leagueId.title} (
                  {sleague.seasonName})
                </option>
              ))}
            </select>
          </form>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <label className={adminStyle.label} htmlFor="week">
              Team
            </label>
            <select
              className={adminStyle.select}
              id="team"
              {...register("team")}
              onChange={handleTeamChange}
              value={selectedTeam}
            >
              <option value="-1">Select a Team...</option>

              {teamsData && teamsData.map((team, i) => (
                <option key={i} value={team.sleagueTeamId}>
                  {team.teamId.name}
                </option>
              ))}
            </select>
          </form>
        </Grid>

        <div align="center">
          <Grid container direction="row" style={{ width: "90%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              sortingOrder={["desc", "asc"]}
              onSortModelChange={(newSortModel) => {
                if (!_.isEqual(newSortModel, sortModel)) {
                  setSortModel(newSortModel);
                }
              }}
              sortModel={sortModel}
              className={classes.root}
              autoHeight={true}
            />

            {openDialog && (
              <AlertDialog
                title="Alert"
                closeDialog={handleCloseDialog}
                contentMsg={alertMsg}
              />
            )}

            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              scroll="body"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              ></BootstrapDialogTitle>
              <Paper style={{ minWidth: 650, minHeight: 670 }}>
                <DndProvider backend={HTML5Backend} debugMode={true}>
                  {selectedFixtureId && selectedFixtureName && (
                    <SelectedTeamCoachTeamSelectorDialog
                      fixtureTeam={selectedFixtureTeam}
                      fixtureTeamName={selectedFixtureName}
                    />
                  )}

                </DndProvider>

              </Paper>
              <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
          </Grid>
        </div>
      </div>
    </>
  );
}
