import React from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import strong from "../../../../assets/BioImages/strong.gif";
import soso from "../../../../assets/BioImages/soso.gif";
import weak from "../../../../assets/BioImages/weak.gif";

const Bio = ({ players, selectedRole }) => {
  console.log("players---------positions", players);

  const defPlayers = [];
  const mfPlayers = [];
  const forPlayers = [];
  const gkPlayers = [];

  const imagesSrc = { "3": strong, "2": soso, "1": weak };

  var playersList = [];
  var playerAge = [];
  var currentTime = new Date();

  players.map((player) => {
    if (player.playerId.position.toLowerCase() === "defender") {
      defPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "midfielder") {
      mfPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "forward") {
      forPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "goalkeeper") {
      gkPlayers.push(player);
    }

    playerAge =
      currentTime.getFullYear() -
      parseInt(player.playerId.birthDate.split("-")[0]);

    player.playerId["age"] = playerAge;
  });

  if (selectedRole == "goalkeeper") {
    playersList = gkPlayers;
  } else if (selectedRole == "defender") {
    playersList = defPlayers;
  } else if (selectedRole == "midfielder") {
    playersList = mfPlayers;
  } else if (selectedRole == "forward") {
    playersList = forPlayers;
  }

  return (
    <div style={{ width: "100%" }}>
      {playersList.map((player, index) => {
        return (
          <Grid container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 15,
                paddingRight: 5,
                height: "39px",
                borderBottom: "1px solid white",
              }}
            >
              <Box style={{ width: 35 }}>
                <Typography
                  xs={1}
                  spacing={2}
                  variant="h6"
                  style={{ display: "contents" }}
                >
                  {player.playerId.age}
                </Typography>
              </Box>
              <Box style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  {player.playerId.weight}
                </Typography>
              </Box>
              <Box style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  {player.playerId.height}
                </Typography>
              </Box>
              <Box style={{ width: 30 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  {player.playerId.foot}
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrPower]} />
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrSpeed]} />
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrTechnical]} />
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrStrategy]} />
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 35 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrAttack]} />
                </Typography>
              </Box>
              <Box mt={1} style={{ width: 30 }}>
                <Typography xs={1} variant="h6" style={{ display: "contents" }}>
                  <img src={imagesSrc[player.playerId.attrDefense]} />
                </Typography>
              </Box>
            </div>
          </Grid>
        );
      })}
    </div>
  );
};

export default Bio;
