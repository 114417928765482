import { memo, useState, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { useDrop } from "react-dnd";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { REFRESH_TOKEN_MUTATION } from "../../../../graphQL/user_qry";

const useStyles = makeStyles({
  mainStyle: {
    color: "#212121",
    textAlign: "center",
    font: "10px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
  },
  pStyle: {
    backgroundColor: "#E0E0E0",
    borderRadius: "50%",
    width: "41px", //35
    height: "41px", //35
    //  lineHeight: "45px",
    color: "#212121",
    justifyContent: "center",
    font: "8px Roboto, Helvetica, Arial, sans-serif", //9
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "-0.2px",
  },
  droppedCnt: {
    display: "flex",
    border: "1px solid #FFFFFF",
    padding: "1px 1px 0px 1px",
    //minHeight: 20,
    //width: 60, //90,
    position: "relative",
    background: "#9FC6A3",
    borderRadius: 2,
  },
  closeBtn: {
    width: "18px",
    height: "18px",
    borderRadius: "18px",
    position: "absolute",
    top: "-13px",
    right: "-13px",
    background: "#E0E0E0",
    color: "#212121",
    cursor: "pointer",
    justifyContent: "center",
    border: "1px solid #FFFFFF",
  },
});
export const Selection = memo(function Selection({
  accept,
  text,
  lastDroppedItem,
  height,
  width,
  img,
  onRemoveDroppedItem,
  onDrop,
  embargoTime,
}) {
  const classes = useStyles();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [removeDroppedItem, setRemoveDroppedItem] = useState(false);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  const onClickEvent = () => {
    // remove player
    setRemoveDroppedItem(true);
    onRemoveDroppedItem(lastDroppedItem);
    // let drop again
    setRemoveDroppedItem(false);
    //update token to keep user signed in
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  };

  return (
    <div
      className={classes.mainStyle}
      style={{
        height,
        width,
        backgroundImage: `url(${img})`,
        //backgroundRepeat: "no-repeat",
        flexDirection: "column",
        //border: "1px solid white"
      }}
    >
      <div
        ref={drop}
        role="Selection"
        className={classes.mainStyle}
        style={{
          height: "100px",
          width: text === "GK(1)" ? "110%" : "100%",
          paddingLeft:"10px",
          flexDirection: "column",
        }}
      >
        <p className={classes.pStyle}>{text}</p>
        {lastDroppedItem && !removeDroppedItem && (
          <div className={classes.droppedCnt}>
            {" "}
            <p style={{ /*paddingRight: "5px",*/ margin: "2px" }}>
              {lastDroppedItem.jerseyNO} {lastDroppedItem.onFieldName}
            </p>{" "}
            {!embargoTime && (
              <div className={classes.closeBtn} onClick={onClickEvent}>
                <CloseIcon style={{ fontSize: 12, marginTop: 2 }} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
