import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, ClientContext, useManualQuery } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Box from '@mui/material/Box';
import {project_url, admin_url} from "../store/data/Constants";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  CREATE_GAME_STAT_MUTATION,
  GET_ALL_GAME_STATS_QUERY,
  GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY
} from "../graphQL/gameStat_qry";
import { GET_ALL_POSITIONS_QUERY } from "../graphQL/position_qry";
import { GET_ALL_FIXTURETEAMS_QUERY,
        GET_FIXTURETEAM_TEAMS ,
        GET_FIXTURE_OBJECT} from "../graphQL/fixture_qry";
import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../graphQL/sleague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
         CREATE_GAME_STAT_AGGR_MUTATION,
         EDIT_GAME_STAT_AGGR_MUTATION,
         GET_ALL_GAME_STATS_AGGR_QUERY } from "../graphQL/gameStatAggr_qry";
import { GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
         CREATE_POSITION_PLAYED_AGGR_MUTATION,
         EDIT_POSITION_PLAYED_AGGR_MUTATION,
         GET_ALL_POSITION_PLAYED_AGGR_QUERY } from "../graphQL/positionPlayedAggr_qry";
import {
       GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY} from "../graphQL/current_bucket_sleague_qry";
import {
       GET_CURRENT_SLEAGUE_WEEKS_QUERY} from "../graphQL/current_bucket_week_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";

import { styled } from '@mui/material/styles';

import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  }));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>      
      <Box sx={{ mt: 25 }}>No Player!</Box>
    </StyledGridOverlay>
  );
}

/*##########################################################################
## Function : Game Stat
## Description : Submit form data and Add Game Stat for a Player in a Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function GameStat() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    setValue
  } = useForm();
  
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [pageSize, setPageSize] = React.useState(20);
  const [sortValue , setSortValue] = useState("name")

  const [
    selectedSleagueTeamPlayerId,
    setSelectedSleagueTeamPlayerId
  ] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const [selectedTeamName, setSelectedTeamName] = useState("");
  const navigate = useNavigate();
  const [sleagueTeamPlayerData, setSleagueTeamPlayerData] = useState([]);
  const [fixtureTeamData, setFixtureTeamData] = useState([]);
  const [teamFixturesData, setTeamFixturesData] = useState([]);
  const [allFixtureTeams, setAllFixtureTeams] = useState([]);
  const [allTeamFixtures, setAllTeamFixtures] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [allGameStats, setAllGameStats] = useState([]);
  const [allGameStatsAggr, setAllGameStatsAggr] = useState([]);
  const [allPositionPlayedAggr, setAllPositionPlayedAggr] = useState([]);
  const [selectedPlayerOnField, setSelectedPlayerOnField] = useState(0);
  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  const [sleagueTeamsData, setSleagueTeamsData] = useState([]);
  const [curSleagueWeek, setCurSleagueWeek] = useState('');  
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [disabledPlayerInputs, setDisabledPlayerInputs] = useState(false);
  const [disabledPositionInput, setDisabledPositionInput] = useState(false);
  const [displayNoPosition,setDisplayNoPosition] = useState(false);
  const [currentFixtureId, setCurrentFixtureId] = useState(0);
  const [currentFixtureStatus, setCurrentFixtureStatus] = useState("");
  const [currentFixtureObject, setCurrentFixtureObject] = useState("");
  const [currentFixtureDate, setCurrentFixtureDate] = useState("");
  const [currentFixtureScheduledDate, setCurrentFixtureScheduledDate] = useState("");
  const [disableBtns, setDisableBtns] = useState(false);  
  const [fixtureTeamGamStats, setFixtureTeamGamStats] = useState([]);
  const [allTeamFixtureData, setAllTeamFixtureData] = useState([]);
  const [currentSleagueId, setCurrentSleagueId] = useState(''); 
  const [minutesPlayedError , setMinutesPlayedError] = useState(false);
  
  const [rows, setRows] = useState([]); 

  const [open, setOpen] = useState(false);

  const [fetchSleagueTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeamFixtures] = useManualQuery(GET_FIXTURETEAM_TEAMS, {
    fetchPolicy: "network-only",
  }); 
  
  const [fetchFixture] = useManualQuery(GET_FIXTURE_OBJECT, {
    fetchPolicy: "network-only",
  }); 

  const [fetchSleagueTeamPlayers] = useManualQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    fetchPolicy: "network-only",
  });  

  const [fetchCurrentSleagueWeek] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });  

  const [fetchSleagueTeamGameStatAggrs] = useManualQuery(
    GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );

  const [fetchSleagueTeamPosPlayedAggrs] = useManualQuery(
    GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );

  const [fetchGameStatByFixTeamId] = useManualQuery(
    GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );

  const { loading: sleagueLoading, 
          error: sleagueError, 
          data: sleagueData} = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, 
                                {fetchPolicy: "network-only"});

  const [createGamestat] = useMutation(CREATE_GAME_STAT_MUTATION, {
    refetchQueries: [{ query: GET_ALL_GAME_STATS_QUERY }],
    awaitRefetchQueries: true
  });
  const [createGamestatAggr] = useMutation(CREATE_GAME_STAT_AGGR_MUTATION, {
    refetchQueries: [{ query: GET_ALL_GAME_STATS_AGGR_QUERY }],
    awaitRefetchQueries: true
  });
  const [editGameStatsAggr, 
        { loadingEdit, errorEdit }] = useMutation(EDIT_GAME_STAT_AGGR_MUTATION);

  const [createPositionPlayedAggr] = useMutation(CREATE_POSITION_PLAYED_AGGR_MUTATION, {
    refetchQueries: [{ query: GET_ALL_POSITION_PLAYED_AGGR_QUERY }],
    awaitRefetchQueries: true
  });
  const [editPositionPlayedAggr, 
        { loadingPosEdit, 
          errorPosEdit }] = useMutation(EDIT_POSITION_PLAYED_AGGR_MUTATION);

  const { loading, error, data } = useQuery(GET_ALL_GAME_STATS_QUERY, {
    fetchPolicy: "network-only"
  });
  
  const { loading: loadingPos, error: errorPos, data: dataPos } = useQuery(
    GET_ALL_POSITIONS_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );
  const { loading: loadingFix, error: errorFix, data: dataFix } = useQuery(
    GET_ALL_FIXTURETEAMS_QUERY,
    {
      fetchPolicy: "network-only"
    }
  ); 

  const localTime = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };
  
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
    onRefreshToken();
  }
  else
    navigate("/lms/signin");
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
  if (refreshError) {
    if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
      navigate("/lms/signin");
    if(refreshError.graphQLErrors[0].message === "Signature has expired")
      navigate("/lms/signin");
  }
  if(refreshData && refreshData.refreshToken) {
    localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
  }
  };

  useEffect(
    () => {
      if (!loading && data && data.gameStat) {
        setAllGameStats(data.gameStat);
        
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate("/lms/signin");
      }
    },
    [data]
  );
  
  useEffect(
    () => {
      if (!loadingPos && dataPos && dataPos.position) {
        setPositionData(dataPos.position);
        
      }
    },
    [dataPos]
  );
  useEffect(
    () => {
      if (!loadingFix && dataFix && dataFix.fixtureTeam) {
        setAllFixtureTeams(dataFix.fixtureTeam);
      }
    },
    [dataFix]
  );
  useEffect(() => {
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
      setCurrentSleagueId(sleagueData.currentBucketSleague[0].sleagueId.sleagueId)
    }
    if(sleagueError && sleagueError.graphQLErrors[0].message === "Signature has expired"){
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]);

  useEffect(() => {
    if (teamFixturesData){
        localStorage.setItem("fixtureData", 
            JSON.stringify(teamFixturesData));    
    }
  }, [teamFixturesData]);   



  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };
  const updateFixture  = ( async(fixtureId,teamId) => {
      
       setCurrentFixtureId(parseInt(fixtureId));
       localStorage.setItem("curFixId", parseInt(fixtureId));

      //get fixture info to fill fixtrure status
      const {
        loading: fixtureLoading,
        error: fixtureError,
        data: fixtureData,
      } = await fetchFixture({
        variables: { fixtureId: parseInt(fixtureId) },
      });
      if (!fixtureLoading && fixtureData && fixtureData.fixtureObject) {
           setCurrentFixtureObject(fixtureData.fixtureObject)
           setCurrentFixtureDate(dayFormat(fixtureData.fixtureObject.fixtureWeek.scheduledDate))
           setCurrentFixtureScheduledDate(dayFormat(fixtureData.fixtureObject.scheduledWeek.scheduledDate))
          if (fixtureData.fixtureObject.delayed === true)
          {
              setCurrentFixtureStatus("Delayed")
             
          }
          else if (fixtureData.fixtureObject.fixtureWeek.weekId.name !== curSleagueWeek.name)
           {
               setCurrentFixtureStatus("RescheduledToAnotherWeek")
              
           }
           else if (fixtureData.fixtureObject.rescheduled === true)
           {
               setCurrentFixtureStatus("Rescheduled")
              
           }
           
           else
              setCurrentFixtureStatus("OnTime")
          
        } 

     /////////////////////////////////

      

        const {
          loading: loadingGSAggr,
          error: errorGSAggr,
          data: dataGSAggr
        } = await fetchSleagueTeamGameStatAggrs({
          variables: { sleagueTeamId: parseInt(teamId) }
        });
        if (!loadingGSAggr && dataGSAggr && dataGSAggr.gameStatAggrSleagueteam) {
          setAllGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
        } 

        const {
          loading: loadingPosPlayedAggr,
          error: errorPosPlayedAggr,
          data: dataPosPlayedAggr
        } = await fetchSleagueTeamPosPlayedAggrs({
          variables: { sleagueTeamId: parseInt(teamId) }
        });
        if (!loadingPosPlayedAggr && 
          dataPosPlayedAggr && dataPosPlayedAggr.positionPlayedAggrSleagueteam) {
          setAllPositionPlayedAggr(dataPosPlayedAggr.positionPlayedAggrSleagueteam);
        } 

        const {
              loading: fixturePlayersStatLoading,
              error: fixturePlayersStatError,
              data: fixturePlayersStatData,
            } = await fetchGameStatByFixTeamId({
              variables: {  fixtureId: parseInt(fixtureId),
                            sleagueTeamId: parseInt(teamId)},
            });

            if (!fixturePlayersStatLoading && 
                fixturePlayersStatData && 
                fixturePlayersStatData.gameStatByFixtureTeam) {
                  setFixtureTeamGamStats(fixturePlayersStatData.gameStatByFixtureTeam); 
                  setRows(fixturePlayersStatData.gameStatByFixtureTeam);  
                  localStorage.setItem("statRows", 
                      JSON.stringify(fixturePlayersStatData.gameStatByFixtureTeam));
                }; 

            //var fixtId = await getTeamFixturesData(allTeamFixtureData);
      });

  /*
   * Function : getTeamFixtureData
   * Description : Get Team Fixture Data
   */
  const getTeamFixturesData = async(data,sleagueTeamId) => {
    let tData = [];
    var matchedFixtureId = []
    data.map(fixture => {
      let filterFixId = 0;
      let reschduledFixture = false;
      filterFixId = data.filter(
        x => x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
             x.fixtureId.fixtureWeek.weekId.weekId === curSleagueWeek.weekId
      );

      if (filterFixId.length == 0)
          {
            filterFixId = data.filter(
            x => (x.fixtureId.fixtureId === fixture.fixtureId.fixtureId) &&
                  (x.fixtureId.scheduledWeek.weekId.weekId === 
                  curSleagueWeek.weekId)                  
          ); 
            if (data[0].fixtureId.delayed === false)
                reschduledFixture = true;
            
           }
          else
          {
            reschduledFixture = false;
            
          }

      let teamData = [];
      if (filterFixId && filterFixId.length > 0) {
        // search for fixtureId
        const index = tData.findIndex(
          x => x.fixtureId === fixture.fixtureId.fixtureId
        );
        // add the fixtureId if doesn't exist in list
        if (index == -1) {
          filterFixId.map(i => {
            teamData.push({
              teamName: i.sleagueTeamId.teamId.name,
              halfScore: i.scoreHalf1,
              finalScore: i.scoreFinal,
            });
          });
        }

        // if teamData exist add its data to result
        if (teamData && teamData.length > 0) {
          var formatedDate = dayFormat(fixture.fixtureId.fixtureWeek.scheduledDate)
          
          // check there are assigned two team for a fixture
          if (teamData && teamData.length > 1) {
            tData.push({
              fixtureId: fixture.fixtureId.fixtureId,
              fixtureWeek: fixture.fixtureId.fixtureWeek.weekId.name,
              fixtureDate: formatedDate,
              delayed: fixture.fixtureId.delayed,
              reschduledFixture: reschduledFixture,
              scheduledWeek: fixture.fixtureId.scheduledWeek.weekId.name,
              fixtureTeams: [
                { teamName: teamData[0].teamName, 
                  halfScore: teamData[0].halfScore,
                  finalScore: teamData[0].finalScore},
                { teamName: teamData[1].teamName, 
                  halfScore: teamData[1].halfScore,
                  finalScore: teamData[1].finalScore }
              ]
            });
            matchedFixtureId.push(parseInt(fixture.fixtureId.fixtureId));
          }
        }
      }
    }); 
    tData.sort((a,b) => 
      new Date(a.fixtureDate) > new Date(b.fixtureDate) ? 1 : -1);    
    setTeamFixturesData(tData);
    
    if (matchedFixtureId.length ){
        let selectedFixture = tData.find(
          item => item.scheduledWeek === curSleagueWeek.name
        )
        if (selectedFixture){
          await updateFixture(parseInt(selectedFixture.fixtureId),sleagueTeamId)
          //setCurrentFixtureId(parseInt(selectedFixture.fixtureId));
        //setCurrentFixtureId(matchedFixtureId[0]);
        localStorage.setItem("curFixId", parseInt(selectedFixture.fixtureId));
      }
   }
    return matchedFixtureId
  };
  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const sortChange = event => { 

    setSortValue(event.target.value);    

    if (event.target.value === "name"){
      sleagueTeamPlayerData.sort((a,b) => 
        a.playerId.fName.toLowerCase() > b.playerId.fName.toLowerCase() ? 1 : -1);
    } 
    else if (event.target.value === "jersey"){
      sleagueTeamPlayerData.sort((a,b) => 
        parseInt(a.jerseyNO) > parseInt(b.jerseyNO) ? 1 : -1);
    }
  };
  

  /*
   * Function : playerOnFieldChange
   * Description : handle Player On Field change
  */
  const playerOnFieldChange = event => {
    setSelectedPlayerOnField(event.target.value);
    // if "No Play" is selected the next input (Minutes Played) is set to zero
    if (event.target.value == "No-Play"){
      setValue("minutesPlayed", 0);
      setValue("goal", 0);
      setValue("ownGoal", 0);
      setValue("cardYellow", false);
      setValue("cardRed", false); 
      
      setDisplayNoPosition(true);   

      setDisabledPositionInput(true);  

      setDisabledInputs(true);
      setValue("positionId", "999");

      
    }     
    else {
      setDisplayNoPosition(false);
      setValue("positionId", "");
      setDisabledInputs(false);  
      setDisabledPositionInput(false);
      if (event.target.value == "Full-Game")
        setValue("minutesPlayed", "90");
      else
        setValue("minutesPlayed", "");
      setValue("goal", 0);
      setValue("ownGoal", 0);
      setValue("cardYellow", false);
      setValue("cardRed", false);
   
        
 
    };                             
  };

  /*###################################################################
   // Function : fillGameStatAggrTable
   // Description : Fill Game Stat Aggr. table based on game stat info
  ###################################################################*/

  const fillGameStatAggrTable = async gamestatData => { 

    // if (allGameStatsAggr.length > 0){
    let matchedSleagueTeamPlayer = allGameStatsAggr.filter(
      item => parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) === 
              parseInt(gamestatData.sleagueTeamPlayerId)
    );    
      
    
    var fullGame = 0, fullMin = 0, subinGame = 0, subinMin = 0,
        suboutGame = 0, suboutMin = 0, subInOutGame = 0, subInOutMin = 0,
        ejectGame = 0, ejectMin = 0,
        totalGame = 0, totalMin = 0,
        avgMinPerGame = 0,goal = 0, ownGoal = 0,
        cardYellow = 0, cardRed = 0;

    if (matchedSleagueTeamPlayer.length >= 2){
      alert("There is more than one record for this player in GameStatAggr table!");
      return
    } 

    let foundSleagueTeamPlayer = matchedSleagueTeamPlayer[0];

    if (foundSleagueTeamPlayer){      
      fullGame  = parseInt(foundSleagueTeamPlayer.fullGame);
      fullMin   = parseInt(foundSleagueTeamPlayer.fullMin);
      subinGame = parseInt(foundSleagueTeamPlayer.subinGame);
      subinMin  = parseInt(foundSleagueTeamPlayer.subinMin);
      suboutGame= parseInt(foundSleagueTeamPlayer.suboutGame);
      suboutMin = parseInt(foundSleagueTeamPlayer.suboutMin);
      subInOutGame = parseInt(foundSleagueTeamPlayer.subInOutGame);
      subInOutMin  = parseInt(foundSleagueTeamPlayer.subInOutMin);
      ejectGame = parseInt(foundSleagueTeamPlayer.ejectGame);
      ejectMin  = parseInt(foundSleagueTeamPlayer.ejectMin);
      goal      = parseInt(foundSleagueTeamPlayer.goal);
      ownGoal   = parseInt(foundSleagueTeamPlayer.ownGoal); 
      cardYellow= parseInt(foundSleagueTeamPlayer.cardYellow);
      cardRed   = parseInt(foundSleagueTeamPlayer.cardRed);
        
    };

    if (gamestatData.playerOnField == 'Full-Game') {      
      fullGame  = fullGame + 1;
      fullMin   = fullMin + parseInt(gamestatData.minutePlayed);    

    } else if (gamestatData.playerOnField == 'Sub-In') {      
      subinGame = subinGame + 1;
      subinMin  = subinMin + parseInt(gamestatData.minutePlayed); 

    } else if (gamestatData.playerOnField == 'Sub-Out') {          
      suboutGame= suboutGame + 1;
      suboutMin = suboutMin + parseInt(gamestatData.minutePlayed); 

    } else if (gamestatData.playerOnField == 'Sub-In-Out') {          
      subInOutGame = subInOutGame + 1;
      subInOutMin  = subInOutMin + parseInt(gamestatData.minutePlayed);        
     
    } else if (gamestatData.playerOnField == 'Ejected') {          
      ejectGame = ejectGame + 1;
      ejectMin  = ejectMin + parseInt(gamestatData.minutePlayed);        
    }; 

    totalGame     = parseInt(fullGame + subinGame + suboutGame +  subInOutGame + ejectGame);
    totalMin      = parseInt(fullMin + subinMin + suboutMin +  subInOutMin + ejectMin);

    if (totalGame < 0 ) totalGame = 0;
    if (totalMin < 0 ) totalMin = 0;

    avgMinPerGame = totalGame
      ? Math.ceil(totalMin / totalGame)
      : 0;

    if (gamestatData.goal) goal = goal + parseInt(gamestatData.goal);
    if (gamestatData.ownGoal) ownGoal = ownGoal + parseInt(gamestatData.ownGoal);
    if (gamestatData.cardYellow) cardYellow = cardYellow + 1;
    if (gamestatData.cardRed) cardRed = cardRed + 1;
      

    if (foundSleagueTeamPlayer){
      // edit an existing record in game stat aggr table by new counts
      const { data, error } = await editGameStatsAggr({
        variables: {
          gameStatAggrId: parseInt(foundSleagueTeamPlayer.gameStatAggrId),
          sleagueTeamPlayerId: 
          parseInt(foundSleagueTeamPlayer.sleagueTeamPlayerId.sleagueTeamPlayerId),
          filterId: parseInt(foundSleagueTeamPlayer.filterId.filterId),
          fullGame: fullGame,
          fullMin: fullMin,
          subinGame: subinGame,
          subinMin: subinMin,
          suboutGame: suboutGame,
          suboutMin: suboutMin,
          subInOutGame: subInOutGame,
          subInOutMin: subInOutMin,
          ejectGame: ejectGame,
          ejectMin: ejectMin,
          totalGame: totalGame,
          totalMin: totalMin,
          avgMinPerGame: avgMinPerGame,
          goal: goal,
          ownGoal: ownGoal,
          cardYellow: cardYellow,
          cardRed: cardRed        
        },
      });
      
      if (error) {
        console.log("Error", error, data);
        alert("There is an error");
      } else if (data && data.editGamestataggr) {
        console.log("Success Edit",data.editGamestataggr);            

        setAllGameStatsAggr(allGameStatsAggr);
    
        alert("The existing Game Stat Aggr record has been updated!");          
      }
    } else {
      const { data: createData, 
              error: createError } = await createGamestatAggr({
        variables: {
          sleagueTeamPlayerId: parseInt(gamestatData.sleagueTeamPlayerId),
          filterId: 1,
          fullGame: fullGame,
          fullMin: fullMin,
          subinGame: subinGame,
          subinMin: subinMin,
          suboutGame: suboutGame,
          suboutMin: suboutMin,
          subInOutGame: subInOutGame,
          subInOutMin: subInOutMin,
          ejectGame: ejectGame,
          ejectMin: ejectMin,
          totalGame: totalGame,
          totalMin: totalMin,
          avgMinPerGame: avgMinPerGame,
          goal: goal,
          ownGoal: ownGoal,
          cardYellow: cardYellow,
          cardRed: cardRed,
        }
      });

      if (createError) {
        console.log("Error", createError, createData);
        alert("There is an error");
      } else if (createData && createData.createGamestataggr) {
        console.log("Success game stat aggr record creation",createData.createGamestataggr);            
        alert("A new record in GameStatAggr table has been created for this player!"); 
        setAllGameStatsAggr(allGameStatsAggr);    
      }          
    }
  };

  /*#########################################################################
   // Function : fillPositionPlayedAggrTable
   // Description : Fill Position Played Aggr. table based on game stat info
  ###########################################################################*/ 

  const fillPositionPlayedAggrTable = async posPlayedData => { 

    let matchedSleagueTeamPlayer = allPositionPlayedAggr.filter(
      item => parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) === 
              parseInt(posPlayedData.sleagueTeamPlayerId)
    );    
    

    let foundPosition = positionData.find(
      item => parseInt(item.positionId) === 
              parseInt(posPlayedData.positionId)
    );  

    var GKCount = 0, LDEFCount = 0, CDEFCount = 0, RDEFCount = 0,
      LMFCount = 0, CMFCount = 0, RMFCount = 0, LFORCount = 0,
      CFORCount = 0,RFORCount = 0, GKMin = 0, LDEFMin = 0,
      CDEFMin = 0,RDEFMin = 0,LMFMin = 0, CMFMin = 0, RMFMin = 0,
      LFORMin = 0, CFORMin = 0, RFORMin = 0; 

    if (matchedSleagueTeamPlayer.length >= 2){
      alert("There is more than one record for this player in PositionPlayedAggr table!");
      return
    } 

    let foundSleagueTeamPlayer = matchedSleagueTeamPlayer[0];

    if (foundSleagueTeamPlayer){        
      GKCount   = parseInt(foundSleagueTeamPlayer.GKCount);
      LDEFCount = parseInt(foundSleagueTeamPlayer.LDEFCount);
      CDEFCount = parseInt(foundSleagueTeamPlayer.CDEFCount);
      RDEFCount = parseInt(foundSleagueTeamPlayer.RDEFCount);
      LMFCount  = parseInt(foundSleagueTeamPlayer.LMFCount);
      CMFCount  = parseInt(foundSleagueTeamPlayer.CMFCount);
      RMFCount  = parseInt(foundSleagueTeamPlayer.RMFCount); 
      LFORCount = parseInt(foundSleagueTeamPlayer.LFORCount);
      CFORCount = parseInt(foundSleagueTeamPlayer.CFORCount);
      RFORCount = parseInt(foundSleagueTeamPlayer.RFORCount);
      GKMin     = parseInt(foundSleagueTeamPlayer.GKMin); 
      LDEFMin   = parseInt(foundSleagueTeamPlayer.LDEFMin);
      CDEFMin   = parseInt(foundSleagueTeamPlayer.CDEFMin);
      RDEFMin   = parseInt(foundSleagueTeamPlayer.RDEFMin);
      LMFMin    = parseInt(foundSleagueTeamPlayer.LMFMin);
      CMFMin    = parseInt(foundSleagueTeamPlayer.CMFMin);
      RMFMin    = parseInt(foundSleagueTeamPlayer.RMFMin);
      LFORMin   = parseInt(foundSleagueTeamPlayer.LFORMin);
      CFORMin   = parseInt(foundSleagueTeamPlayer.CFORMin);
      RFORMin   = parseInt(foundSleagueTeamPlayer.RFORMin);
    };

    if (posPlayedData.playerOnField !== 'No-Play'){

      if (foundPosition && foundPosition.label == 'GK') {      
        GKCount   = GKCount + 1;
        GKMin     = GKMin + parseInt(posPlayedData.minutePlayed);    

      } else if (foundPosition && foundPosition.label == 'LDEF') {      
        LDEFCount = LDEFCount + 1;
        LDEFMin   = LDEFMin + parseInt(posPlayedData.minutePlayed); 

      } else if (foundPosition && foundPosition.label == 'CDEF') {          
        CDEFCount = CDEFCount + 1;
        CDEFMin   = CDEFMin + parseInt(posPlayedData.minutePlayed); 

      } else if (foundPosition && foundPosition.label == 'RDEF') {      
        RDEFCount = RDEFCount + 1;
        RDEFMin   = RDEFMin + parseInt(posPlayedData.minutePlayed); 

      } else if (foundPosition && foundPosition.label == 'LMF') {      
        LMFCount  = LMFCount + 1;
        LMFMin    = LMFMin + parseInt(posPlayedData.minutePlayed);

      } else if (foundPosition && foundPosition.label == 'CMF') {      
        CMFCount  = CMFCount + 1;
        CMFMin    = CMFMin + parseInt(posPlayedData.minutePlayed); 

      } else if (foundPosition && foundPosition.label == 'RMF') {      
        RMFCount  = RMFCount + 1;
        RMFMin    = RMFMin + parseInt(posPlayedData.minutePlayed); 

      } else if (foundPosition && foundPosition.label == 'LFOR') {      
        LFORCount = LFORCount + 1;
        LFORMin   = LFORMin + parseInt(posPlayedData.minutePlayed);

      } else if (foundPosition && foundPosition.label == 'CFOR') {      
        CFORCount = CFORCount + 1;
        CFORMin   = CFORMin + parseInt(posPlayedData.minutePlayed);

      } else if (foundPosition && foundPosition.label == 'RFOR') {      
        RFORCount = RFORCount + 1;
        RFORMin   = RFORMin + parseInt(posPlayedData.minutePlayed);
      };   
    }; 

    if (foundSleagueTeamPlayer){
      // edit an existing record in position played aggr table by new counts
      const { data, error } = await editPositionPlayedAggr({
        variables: {
          positionPlayedAggrId: 
              parseInt(foundSleagueTeamPlayer.positionPlayedAggrId),
          sleagueTeamPlayerId: 
          parseInt(foundSleagueTeamPlayer.sleagueTeamPlayerId.sleagueTeamPlayerId),
          filterId: parseInt(foundSleagueTeamPlayer.filterId.filterId),
          GKCount: GKCount,
          LDEFCount: LDEFCount,
          CDEFCount: CDEFCount,
          RDEFCount: RDEFCount,
          LMFCount: LMFCount,
          CMFCount: CMFCount,
          RMFCount: RMFCount,
          LFORCount: LFORCount,
          CFORCount: CFORCount,
          RFORCount: RFORCount,
          GKMin: GKMin,
          LDEFMin: LDEFMin,
          CDEFMin: CDEFMin,
          RDEFMin: RDEFMin,
          LMFMin: LMFMin,
          CMFMin: CMFMin,
          RMFMin: RMFMin,
          LFORMin: LFORMin,
          CFORMin: CFORMin,
          RFORMin: RFORMin      
        },
      });
      if (error) {
        console.log("Error", error, data);
        alert("There is an error");
      } else if (data && data.editPositionplayedaggr) {
        console.log("Success Edit",data.editPositionplayedaggr); 
        setAllPositionPlayedAggr(allPositionPlayedAggr);
        alert("The existing Position Played Aggr record has been updated!");          
      }
    } else {
      const { data: createData, 
              error: createError } = await createPositionPlayedAggr({
        variables: {
          sleagueTeamPlayerId: parseInt(posPlayedData.sleagueTeamPlayerId),
          filterId: 1,
          GKCount: GKCount,
          LDEFCount: LDEFCount,
          CDEFCount: CDEFCount,
          RDEFCount: RDEFCount,
          LMFCount: LMFCount,
          CMFCount: CMFCount,
          RMFCount: RMFCount,
          LFORCount: LFORCount,
          CFORCount: CFORCount,
          RFORCount: RFORCount,
          GKMin: GKMin,
          LDEFMin: LDEFMin,
          CDEFMin: CDEFMin,
          RDEFMin: RDEFMin,
          LMFMin: LMFMin,
          CMFMin: CMFMin,
          RMFMin: RMFMin,
          LFORMin: LFORMin,
          CFORMin: CFORMin,
          RFORMin: RFORMin,
        }
      });

      if (createError) {
        console.log("Error", createError, createData);
        alert("There is an error");
      } else if (createData && createData.createPositionplayedaggr) {
        console.log("Success player position aggr record creation",createData.createPositionplayedaggr);            
        alert("A new record in PositionPlayedAggr table has been created for this player!"); 
        setAllPositionPlayedAggr(allPositionPlayedAggr);   
      }
    }
  };


  const sleagueChange = (async (e) => {

    const sid = e.target.value;
    const leagueName = sid.split("~@")[0];
    const seasonId = sid.split("~@")[1];

    localStorage.setItem("sleagueId", parseInt(seasonId));
    setCurrentSleagueId(parseInt(seasonId));

    const {
      loading: currentSleagueWeekLoading,
      error: currentSleagueWeekErrors,
      data: currentSleagueWeekData,
    } = await fetchCurrentSleagueWeek({
      variables: { sleagueId: parseInt(seasonId) },
    });
    if (!currentSleagueWeekLoading && 
    currentSleagueWeekData && currentSleagueWeekData.currentSleagueWeek) {
      localStorage.setItem("weekData", 
                JSON.stringify(currentSleagueWeekData.currentSleagueWeek));
      let selectedWeek = currentSleagueWeekData.currentSleagueWeek.find(
        item => item.extraField === true
      )
      if (selectedWeek){
        setCurSleagueWeek(selectedWeek); 
        localStorage.setItem("weekId", selectedWeek.weekId);       
      } 
      else{
        alert("Please select a week in the 'Current League Season Weeks Bucket' menu first!");
      }
    }
    if (currentSleagueWeekErrors && 
      currentSleagueWeekErrors.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }

    const {
      loading: sleagueTeamsLoading,
      error: sleagueTeamsError,
      data: allSleagueTeamsData,
    } = await fetchSleagueTeams({
      variables: { sleagueId: parseInt(seasonId) },
    });

    if (!sleagueTeamsLoading && 
        allSleagueTeamsData && allSleagueTeamsData.sleagueTeam) {
      allSleagueTeamsData.sleagueTeam.sort((a,b) => 
        a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1); 
      setSleagueTeamsData(allSleagueTeamsData.sleagueTeam);
      localStorage.setItem("teamData", 
                JSON.stringify(allSleagueTeamsData.sleagueTeam));
    }
    if (sleagueTeamsError && 
      sleagueTeamsError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  });

  const sleaguePlayerChange = ( e => {
    setDisabledPlayerInputs(false);
    setDisabledPositionInput(false);
    setDisplayNoPosition(false);
  })

  const sleagueTeamChange = (async (e) => {



    let selectedTeam = sleagueTeamsData.find(
      item => parseInt(item.teamId.teamId) === parseInt(e.target.value)
    )
    if (selectedTeam){ 
      setSelectedTeamName(selectedTeam.teamId.name)
      localStorage.setItem("sleagueTeamId", parseInt(selectedTeam.sleagueTeamId));
      localStorage.setItem("sleagueTeamIndex", parseInt(selectedTeam.teamId.teamId));

      const {
        loading: loadingGSAggr,
        error: errorGSAggr,
        data: dataGSAggr
      } = await fetchSleagueTeamGameStatAggrs({
        variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) }
      });
      if (!loadingGSAggr && dataGSAggr && dataGSAggr.gameStatAggrSleagueteam) {
        setAllGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
        
      } 

      const {
        loading: loadingPosPlayedAggr,
        error: errorPosPlayedAggr,
        data: dataPosPlayedAggr
      } = await fetchSleagueTeamPosPlayedAggrs({
        variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) }
      });

      if (!loadingPosPlayedAggr && 
        dataPosPlayedAggr && dataPosPlayedAggr.positionPlayedAggrSleagueteam) {
        setAllPositionPlayedAggr(dataPosPlayedAggr.positionPlayedAggrSleagueteam);
        
      } 

      const {
        loading: teamFixturesLoading,
        error: teamFixturesError,
        data: allTeamFixturesData,
      } = await fetchTeamFixtures({
        variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) },
      });

      if (!teamFixturesLoading && 
      allTeamFixturesData && 
      allTeamFixturesData.fixtureTeamTeams) {
        setAllTeamFixtureData(allTeamFixturesData.fixtureTeamTeams); 

        localStorage.setItem("allTeamFixtureData", 
        JSON.stringify(allTeamFixturesData.fixtureTeamTeams));          
      };

      setSelectedTeamId(parseInt(selectedTeam.sleagueTeamId));
     
      const {
        loading: loadingSTP,
        error: errorsSTP,
        data: dataSTP,
      } = await fetchSleagueTeamPlayers({
        variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) },
      });

      if (!loadingSTP && dataSTP && dataSTP.sleagueTeamPlayer) {
        if ( sortValue === "name"){
          dataSTP.sleagueTeamPlayer.sort((a,b) => 
          a.playerId.fName.toLowerCase() > b.playerId.fName.toLowerCase() ? 1 : -1);
        }
        else if ( sortValue === "jersey"){ 
          dataSTP.sleagueTeamPlayer.sort((a,b) => 
          parseInt(a.jerseyNO) > parseInt(b.jerseyNO) ? 1 : -1);
        };
        setSleagueTeamPlayerData(dataSTP.sleagueTeamPlayer);
      }   
      await setCurrentFixtureStatus("");   
      var fixtId = await getTeamFixturesData(allTeamFixturesData.fixtureTeamTeams,parseInt(selectedTeam.sleagueTeamId));
      setPlayersList(parseInt(selectedTeam.sleagueTeamId), fixtId);
    };
  });
  

  const handleSubmitSave = handleSubmit(async (formData) => {
    //await sleep(500);
    if (parseInt(formData.minutesPlayed) == 0 && formData.playerOnField != "No-Play")
    {
      
          alert("Minutes Played should be greater than 0.")
    }

    else if (parseInt(formData.minutesPlayed) > 90)
      alert("Minutes Played cannot be greater than 90.")

    else if (formData.playerOnField == "Full-Game" && parseInt(formData.minutesPlayed) != 90)
       alert("Minutes Played should be set to 90, when option 'Full Game' is selected in the field 'Player On-Field' for a player.")

    
    
    else
      {
      setDisableBtns(true);
     
      const { data, error } = await createGamestat({
        variables: {
          sleagueTeamPlayerId: parseInt(formData.teamPlayerId),
          fixtureId: parseInt(currentFixtureId),
          positionId: parseInt(formData.positionId),
          playerOnField: formData.playerOnField,
          minutePlayed: parseInt(formData.minutesPlayed),
          goal: parseInt(formData.goal),
          ownGoal: parseInt(formData.ownGoal),
          cardYellow: JSON.parse(formData.cardYellow),
          cardRed: JSON.parse(formData.cardRed)
        }
      });

      let foundPlayer = sleagueTeamPlayerData.find(
        item => parseInt(item.sleagueTeamPlayerId) === 
                parseInt(formData.teamPlayerId)
      );

      let playerName = '';

      if (foundPlayer){
        playerName = foundPlayer.playerId.fName + ' ' + 
        foundPlayer.playerId.lName;
      }

      if (error || data === 'None') {
        console.log("Error", error, data);
        alert("Stats for player '" + playerName + 
          "' is already added for this fixture. Player will not be added again");
        setFailedMessage("Unfortunately an error occurred.");
      } else if (data && data.createGamestat) {
        console.log("Success", data.createGamestat);
        allGameStats.push(data.createGamestat);
        alert("Stats for player '" + playerName +  
          "' is successfully added for this fixture.");
        setSuccessMessage("New game stat added successfully.");
        fillGameStatAggrTable(data.createGamestat);
        fillPositionPlayedAggrTable(data.createGamestat);
        setTimeout(() => {
          navigate(`/lms/${admin_url}/fixture-player-stats`);
        }, 1000);
      }
    }
  })


  const handleSubmitSaveAndAdd = handleSubmit(async (formData) => {
    //await sleep(500);
    if (parseInt(formData.minutesPlayed) == 0 && formData.playerOnField != "No-Play")
    {
      
          alert("Minutes Played should be greater than 0.")
    }

    else if (parseInt(formData.minutesPlayed) > 90)
      alert("Minutes Played cannot be greater than 90.")

    else if (formData.playerOnField == "Full-Game" && parseInt(formData.minutesPlayed) != 90)
       alert("Minutes Played should be set to 90, when option 'Full Game' is selected in the field 'Player On-Field' for a player.")

    
    
    else
      {
      setDisableBtns(true);
     
      const { data, error } = await createGamestat({
        variables: {
          sleagueTeamPlayerId: parseInt(formData.teamPlayerId),
          fixtureId: parseInt(currentFixtureId),
          positionId: parseInt(formData.positionId),
          playerOnField: formData.playerOnField,
          minutePlayed: parseInt(formData.minutesPlayed),
          goal: parseInt(formData.goal),
          ownGoal: parseInt(formData.ownGoal),
          cardYellow: JSON.parse(formData.cardYellow),
          cardRed: JSON.parse(formData.cardRed)
        }
      });

      let foundPlayer = sleagueTeamPlayerData.find(
        item => parseInt(item.sleagueTeamPlayerId) === 
                parseInt(formData.teamPlayerId)
      );

      let playerName = '';

      if (foundPlayer){
        playerName = foundPlayer.playerId.fName + ' ' + 
        foundPlayer.playerId.lName;
      }

      if (error || data === 'None') {
        console.log("Error", error, data);
        alert("Stats for player '" + playerName + 
          "' is already added for this fixture. Player will not be added again");
        setFailedMessage("Unfortunately an error occurred.");
      } else if (data && data.createGamestat) {
        console.log("Success1", data.createGamestat);
        allGameStats.push(data.createGamestat);
        alert("Stats for player '" + playerName +  
          "' is successfully added for this fixture.");
        setSuccessMessage("New game stat added successfully.");
        fillGameStatAggrTable(data.createGamestat);
        fillPositionPlayedAggrTable(data.createGamestat);
      };
        
        // Refresh the fields with new blank fields
      setValue("teamPlayerId", "");
      setValue("positionId", "");
      setValue("playerOnField", "Full-Game");
      setValue("minutesPlayed", "90");
      setValue("goal", "0");
      setValue("ownGoal", "0");
      setValue("cardYellow", false);
      setValue("cardRed", false);

      setPlayersList(selectedTeamId, currentFixtureId)
        
      setDisabledInputs(false);
      setDisabledPlayerInputs(true);
      setTimeout(() => {
        setDisableBtns(false);  
      }, 2000);   
    }
  });

  const setPlayersList = async(curTeamId, curFixId) => {

    if (curFixId.length === 1){
      const {
        loading: fixtureTeamGameStatLoading,
        error: fixtureTeamGameStatError,
        data: fixtureTeamGameStatData,
      } = await fetchGameStatByFixTeamId({
        variables: {
          sleagueTeamId: parseInt(curTeamId),
          fixtureId: parseInt(curFixId[0])
        },
      });

      if (!fixtureTeamGameStatLoading &&
        fixtureTeamGameStatData && fixtureTeamGameStatData.gameStatByFixtureTeam) {
        setFixtureTeamGamStats(fixtureTeamGameStatData.gameStatByFixtureTeam);          
      }            
    
    } else if (curFixId !== 0){
      const {
        loading: fixtureTeamGameStatLoading,
        error: fixtureTeamGameStatError,
        data: fixtureTeamGameStatData,
      } = await fetchGameStatByFixTeamId({
        variables: {
          sleagueTeamId: parseInt(curTeamId),
          fixtureId: parseInt(currentFixtureId)
        },
      });

      if (!fixtureTeamGameStatLoading &&
        fixtureTeamGameStatData && fixtureTeamGameStatData.gameStatByFixtureTeam) {
        setFixtureTeamGamStats(fixtureTeamGameStatData.gameStatByFixtureTeam);          
      }            
    }   
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (async(e) => {
    const {
      loading: fixtureWeekGameStatLoading,
      error: fixtureWeekGameStatError,
      data: fixtureWeekGameStatData,
    } = await fetchGameStatByFixTeamId({
      variables: {
        sleagueTeamId: parseInt(selectedTeamId),
        fixtureId: parseInt(currentFixtureId)
      },
    });

    if (!fixtureWeekGameStatLoading &&
      fixtureWeekGameStatData && fixtureWeekGameStatData.gameStatByFixtureTeam) {
      setRows(fixtureWeekGameStatData.gameStatByFixtureTeam);
      localStorage.setItem("statRows", 
          JSON.stringify(fixtureWeekGameStatData.gameStatByFixtureTeam));      
    }  
    
    setOpen(true);
  });

  const selectFixture = (async (event) => {
    const fixtureId = event.target.value;
    await updateFixture(fixtureId,selectedTeamId)

  })
  
  const handleMinuteChange = event => {
    
    const value = event.target.value;
    if ( value < 1 || value > 90 )
       setMinutesPlayedError(true);
     else
        setMinutesPlayedError(false);
  };

  const columns = [
    {
      field: "player",
      headerName: "Player Name",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 200,
      renderCell: rows => (
        <a
          href={`/lms/${admin_url}/fixture-player-stats/edit/${rows.row.gameStatId}`}
          target="_self"
        >
          ({rows.row.sleagueTeamPlayerId.jerseyNO}) {rows.row.sleagueTeamPlayerId.playerId.name}
        </a>
      ),
    },   
    {
      field: "position",
      headerName: "Position",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 130,
      renderCell: rows => rows.row.positionId.label,
      // editable: true,
    },
    {
      field: "onField",
      headerName: "On the Field",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: rows => rows.row.playerOnField,

      // editable: true,
    },
    {
      field: "minPlayed",
      headerName: "Min. Played",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: rows => rows.row.minutePlayed,
      // editable: true,
    },
    {
      field: "goal",
      headerName: "Goal",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
      renderCell: rows => rows.row.goal,
      // editable: true,
    },
    {
      field: "ownGoal",
      headerName: "Own Goal",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
      renderCell: rows => rows.row.ownGoal,
      // editable: true,
    },
    {
      field: "yc",
      headerName: "YC",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 100,
      renderCell: rows => rows.row.cardYellow ? 1 : 0,
      // editable: true,
    },
    {
      field: "rc",
      headerName: "RC",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 100,
      renderCell: rows => rows.row.cardRed ? 1 : 0,
      // editable: true,
    },
  ];

  /////////////////////////////////////////////////////////////////////
 
  return (
    <>
      <Helmet>
        <title>Predictal | Add Game Stat | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form}>
          <h2 className={adminStyle.h2}>
            Add Game Stat for a Player in a Fixture
          </h2>
          <div>
            <select
              className={adminStyle.select}
              id="sleague" 
              {...register("sleague", { required: true })}
              onChange={sleagueChange}
            >
              <option value="">Select a league and a season...</option>
              {sleaguesBucketData &&
                sleaguesBucketData.length > 0 &&
                sleaguesBucketData.map(( sleague, sleagueId ) => (
                  <option key={sleagueId} 
                    value={sleague.sleagueId.leagueId.subtitle + '~@' + 
                          sleague.sleagueId.sleagueId}>
                    {sleague.sleagueId.leagueId.subtitle} - Season {sleague.sleagueId.seasonName}
                  </option>
                ))}
            </select>
            {errors.sleague &&
              errors.sleague.type === "required" && (
                <span>Required</span>
            )}
            <label className={adminStyle.label} htmlFor="matchWeek">
              Match Week:  <b>{curSleagueWeek.name}</b>
            </label>
            <select
              className={adminStyle.select}
              id="sleagueTeams" 
              {...register("sleagueTeams", { required: true })}
              onChange={sleagueTeamChange}
            >
              <option value="">Select a team in the season...</option>
              {sleagueTeamsData &&
                sleagueTeamsData.length > 0 &&
                sleagueTeamsData.map(( sleagueTeam, sleagueTeamId ) => (
                  <option key={sleagueTeamId} 
                    value={sleagueTeam.teamId.teamId}>
                    {sleagueTeam.teamId.name}
                  </option>
                ))}
            </select>
            {errors.sleagueTeams &&
              errors.sleagueTeams.type === "required" && (
                <span>Required</span>
            )}

            { currentFixtureStatus === "" ?
             ("")
             :
             (
             <>

                         
                    
                  
             
                    <label className={adminStyle.label} htmlFor="weekFixture">
                      Fixture:
                            {teamFixturesData.length > 1 ? 
                              teamFixturesData.map((fixture, i) => (
                                  <div>
                                    <input  id="fixture" {...register("fixture")}
                                      key={i}
                                      value={fixture.fixtureId}  name="fixture"   
                                       type="radio" 
                                       checked={parseInt(fixture.fixtureId) === parseInt(currentFixtureId)}
                                       onChange={selectFixture} />

                                      <label htmlFor="fixture">
                                      {fixture && (
                                        <b> {fixture.fixtureTeams[0].teamName} {fixture.fixtureTeams[0].finalScore} ({fixture.fixtureTeams[0].halfScore}) - {fixture.fixtureTeams[1].teamName} {fixture.fixtureTeams[1].finalScore} ({fixture.fixtureTeams[1].halfScore})
                                        </b>)}
                                      </label>
                                  </div>
                              ))
                          :
                          teamFixturesData[0] && (
                          <b> {teamFixturesData[0].fixtureTeams[0].teamName} {teamFixturesData[0].fixtureTeams[0].finalScore} ({teamFixturesData[0].fixtureTeams[0].halfScore}) - {teamFixturesData[0].fixtureTeams[1].teamName} {teamFixturesData[0].fixtureTeams[1].finalScore} ({teamFixturesData[0].fixtureTeams[1].halfScore})
                          </b>)}
                    </label>

                    <label className={adminStyle.dateLabel} htmlFor="weekFixture">
                      

                      {currentFixtureStatus && currentFixtureStatus === "Delayed" ?
                        
                        (
                          <>
                              Date : <b> {currentFixtureDate}</b>
                              <br /><br />
                              Delayed
                     
                          </>
                        )
                      :
                      (
                        <>


                          {currentFixtureStatus && (currentFixtureStatus === "Rescheduled" || currentFixtureStatus === "RescheduledToAnotherWeek") ? 
                            (
                               <>
                               Scheduled Date :                        
                                <b> {currentFixtureScheduledDate} 
                                { (currentFixtureStatus === "Rescheduled") ? <> ({currentFixtureObject.scheduledWeek.weekId.name})</> : "" }</b>
                                <br /><br />
                                Rescheduled Date : <b> {currentFixtureDate}
                                { (currentFixtureStatus === "RescheduledToAnotherWeek") ? <> ({currentFixtureObject.fixtureWeek.weekId.name})</> : "" }
                                </b>
                              
                               </>
                            )


                          :
                           (
                            <>
                             {currentFixtureStatus && currentFixtureStatus === "OnTime" ?
                               (
                                <>
                                  Date : <b> {currentFixtureDate} </b>
                                </> 
                               )
                              :
                              (
                               <>
                                Date:
                               </> 
                              )} 
                            </>
                              
                          )}
                        </>                       
                        
                        )}

                    </label> 
                    
             </>
             )
            }


            { ((currentFixtureStatus === "") || (currentFixtureStatus === "Delayed") || currentFixtureStatus === "RescheduledToAnotherWeek") ? 
               ("")
              : 
              (
                <>
                <label className={adminStyle.label} htmlFor="sort">
                    Sort by:             
                      <input  
                        id="sort" {...register("sort")}                
                        name="sort"   
                        type="radio" 
                        className={adminStyle.sortRadioButtons}
                        value= "name"
                        checked = {sortValue === "name" ? true : false}
                        onChange={sortChange}
                        disabled={parseInt(selectedTeamId) == 0 || 
                          parseInt(currentFixtureId) == 0  }
                      />

                      <label htmlFor="nameSort">
                        First Name
                      </label>

                      <input  
                        id="sort" {...register("sort")}                  
                        name="sort"   
                        type="radio"
                        className={adminStyle.sortRadioButtons} 
                        value = "jersey"
                        checked = {sortValue === "jersey" ? true : false}
                        onChange={sortChange}
                        disabled={parseInt(selectedTeamId) == 0 || 
                          parseInt(currentFixtureId) == 0}
                      />

                      <label htmlFor="jerseyNOSort">
                        Jersey No
                      </label>            
                  </label>
                 
                 <select
                    className={adminStyle.select}
                    id="teamPlayerId"
                    {...register("teamPlayerId", { required: true })}
                    onChange={sleaguePlayerChange}
                  >
                    <option value="">Select a player in the team...</option>
                    
                    {sleagueTeamPlayerData &&
                      sleagueTeamPlayerData.length > 0 &&
                      sleagueTeamPlayerData.map(({ sleagueTeamPlayerId, playerId, jerseyNO, status }) => (
                      <>
                        
                        <option 
                          style={ status === "Unavailable" ||
                            status === "Injured" ||
                            status === "Suspended" ? {color: 'red'} : null} 
                          key={sleagueTeamPlayerId} 
                          value={sleagueTeamPlayerId} 
                          disabled={(fixtureTeamGamStats.findIndex(
                            x => x.sleagueTeamPlayerId.sleagueTeamPlayerId === sleagueTeamPlayerId
                            )) !==-1 || 
                            status === "Unavailable" ||
                            status === "Injured" ||
                            status === "Suspended" } >
                          ({jerseyNO}) {playerId.fName} {playerId.lName}{" "}
                            {playerId.shortName ? "(" + playerId.shortName + ")" : ""}
                            {status === "Unavailable" ||
                            status === "Injured" ||
                            status === "Suspended" ? 
                            "[" + status + "]" : ""}
                        </option>
                      </>
                      ))}
                     

                  </select>
                  {errors.teamPlayerId &&
                    errors.teamPlayerId.type === "required" && (
                      <span>Required</span>
                  )}
                  <div className={adminStyle.divider} />
                  <label className={adminStyle.label} style={{ textAlign: 'center'}}>
                    Enter Player Information:
                  </label>

                  <label className={adminStyle.label} htmlFor="playerOnFld">
                    Player On-Field
                  </label>
                  <select
                    className={adminStyle.selectWithLabel}
                    disabled= {disabledPlayerInputs}
                    id="playerOnField"
                      {...register("playerOnField", { required: true })}
                    onChange={playerOnFieldChange}
                  >
                    {[{ name: "Full Game",      value: "Full-Game" }, 
                      { name: "Substitute-in",  value: "Sub-In" },
                      { name: "Substitute-out", value: "Sub-Out" },
                      { name: "Substitute-in-out", value: "Sub-In-Out" },
                      { name: "Ejected",        value: "Ejected" },
                      { name: "No Play",        value: "No-Play" }].map(
                      option => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      )
                    )}            
                  </select>
                  {errors.playerOnField && errors.playerOnField.type === "required" && (
                    <span>Required</span>
                  )}

                  <label className={adminStyle.label} htmlFor="playerOnFld">
                    Position
                  </label>

                  { displayNoPosition === true ?
                  (
                  <div>
                    <select
                      className={adminStyle.select}
                      disabled= {disabledPlayerInputs || disabledPositionInput}
                      id="positionId"
                      {...register("positionId")}
                    >
                      <option value="999">No Position</option>
                                      
                    </select>
                  </div>
                  )
                  :
                  (
                  <div>
                    <select
                      className={adminStyle.select}
                      disabled= {disabledPlayerInputs || disabledPositionInput}
                      id="positionId"
                      {...register("positionId", { required: true })}
                    >
                      <option value="">Select a player position...</option>
                      
                      {positionData &&
                        positionData.length > 0 &&
                        positionData.map(({ positionId, label }) => (
                          <option key={positionId} value={positionId}>
                            {label}
                          </option>
                        ))}
                    </select>

                    {errors.positionId && errors.positionId.type === "required" && (
                      <span>Required</span>
                    )}
                  </div>
                  )
                }

                <label className={adminStyle.label} htmlFor="minutesPlayed">
                      Minutes Played
                    </label>
                    <input
                      className={adminStyle.input}
                      type="number"
                      disabled={disabledInputs || disabledPlayerInputs}
                      id="minutesPlayed"
                      {...register("minutesPlayed", { required: true })}
                      defaultValue={90}
                      onChange={handleMinuteChange}
                    />
                    {errors.minutesPlayed && errors.minutesPlayed.type === "required" && (
                      <span>Required</span>
                    )}
                    {minutesPlayedError === true && (
                      <span>Minutes Played should be between 1 and 90.</span>
                    )}
                    

                    <label className={adminStyle.label} htmlFor="goal">
                      Goal
                    </label>
                    <input
                      className={adminStyle.input}
                      type="number"
                      disabled={disabledInputs || disabledPlayerInputs}
                      id="goal"
                      defaultValue={0}
                      {...register("goal", { required: true })}
                    />
                    {errors.goal && errors.goal.type === "required" && (
                      <span>Required</span>
                    )}

                    <label className={adminStyle.label} htmlFor="ownGoal">
                      Own Goal
                    </label>
                    <input
                      className={adminStyle.input}
                      type="number"
                      disabled={disabledInputs || disabledPlayerInputs}
                      id="ownGoal"
                      defaultValue={0}
                      {...register("ownGoal", { required: true })}
                    />
                    {errors.ownGoal && errors.ownGoal.type === "required" && (
                      <span>Required</span>
                    )}


                   <label className={adminStyle.label} htmlFor="YCard">
                    Yellow Card
                  </label>
                  <select
                    className={adminStyle.selectWithLabel}
                    disabled= {disabledPlayerInputs}
                    id="cardYellow"
                    {...register("cardYellow")}
                  >
                    {[{ name: "No", value: false }, { name: "Yes", value: true }].map(
                      option => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select> 

                  <label className={adminStyle.label} htmlFor="RCard">
                  Red Card
                </label>
                <select
                  className={adminStyle.selectWithLabel}
                  disabled= {disabledPlayerInputs}
                  id="cardRed"
                  {...register("cardRed")}
                >
                  {[{ name: "No", value: false }, { name: "Yes", value: true }].map(
                    option => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    )
                  )}
                </select> 

                   <Button style={{  marginLeft: '80px', 
                                marginRight: '30px',
                                marginTop: '20px',
                                marginBottom: '20px'}}
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={handleSubmitSave}
                disabled={disableBtns}
              >
                {" "}
                Save{" "}
              </Button>

              <Button style={{ marginLeft: '30px'}}
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={handleSubmitSaveAndAdd}
                disabled={disableBtns}
              >
                {" "}
                Save and Add{" "}
              </Button>
                                   
               
             </>                  
             )     
            
            }
        </div>
        </form>
        <Button
            variant="contained"
            color="primary"
            disabled={ (parseInt(selectedTeamId) == 0) || 
                       (parseInt(currentFixtureId) == 0) ||
                       (currentFixtureStatus === "") ||
                      (currentFixtureStatus === "Delayed") ||
                      (currentFixtureStatus === "RescheduledToAnotherWeek") }
            onClick={handleOpen}
            style={{
                                         
                  borderRadius: "1px",
                  marginTop: "80px",
                  marginRight: "120px",
                }}
          >
            View Fixture Players Stats
          </Button>     
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "650px",
                height: "100%",
              },
            },
          }}
        >
          <DialogTitle id="alert-dialog-title"
                style={{textAlign: "center",
                        color: "gray"}}>Players Stat for {selectedTeamName}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid item>
              <Box
                sx={{
                  height: 100,
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: '#CCCCCC',
                  },
                }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                  columns={columns}
                  rows={rows}
                  // rowHeight={80}
                  getRowId={row => row.gameStatId}
                  sortingOrder={["desc", "asc"]}
                  autoHeight={true}
                  pageSize={pageSize}
                  onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                  rowsPerPageOptions={[20, 40]}
                  pagination
                />
              </Box>
              </Grid>            
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid> 
    </>     
  );    
}
