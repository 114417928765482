const AddFormation_mutate = `
    mutation addFormation(
      $title: String!,
      $formationSeqNO: String!,
      $description: String,
      $formationPrimary: String!) {
      addFormation(
        title: $title,
        formationSeqNO: $formationSeqNO,
        description: $description,
        formationPrimary: $formationPrimary) {
        formationId
        title
        formationSeqNO
        description
        formationPrimary
      }
    }
`;

const AllFormations_query = `
    query{
      allFormations{
        formationId
        formationSeqNO
        title
        description
        formationPrimary
        updateBy
        updateDate
        createBy
        createDate
      }
    }
`;


const formationPopularity_qry =  `query ($fixtureTeamId:Int!) {
  formationPopularity (fixtureTeamId:$fixtureTeamId)
  {
    formationPopularityId
    votes
    percentage
    formationLeagueId{
      formationId {
	    formationId
        title
        formationSeqNO
      }
    }
  }
}`


const getTopPopularFormation_qry =  `query ($fixtureTeamId:Int!) {
  getTopPopularFormation (fixtureTeamId:$fixtureTeamId)
  {
    formationPopularityId
    votes
    percentage
    formationLeagueId{
      formationId {
        title
      }
    }
  }
}`

module.exports = {
  ADD_FORMATION_MUTATION : AddFormation_mutate,
  ALL_FORMATIONS_QUERY : AllFormations_query,
  GET_FORMATION_POPULARITIES_QUERY: formationPopularity_qry,
  GET_POPULAR_FORMATION_QUERY: getTopPopularFormation_qry
}
