const CreateUser_mutate = `
    mutation createUser(
      $email: String!,
	  $password: String!,
      $repeatPassword: String!,
	  $username: String!,
	  $favTeam: String!) {
      createUser(
        email: $email,
		password: $password,
        repeatPassword: $repeatPassword,
		username: $username,
		favTeam: $favTeam) {
        userId
		email
		password
        repeatPassword
		username
		favTeam
      }
    }
`;

const SleagueTeams_query = `
    query sleagueTeams() {
        sleagueTeams() {
            sleagueId
            teamId
        }
    }
`;


const DenyUserAccess_mutate = `
    mutation denyUserAccess($userId: Int!) {
      denyUserAccess(userId:$userId) {
        userId

      }
    }
`;

const AllowUserAccess_mutate = `
    mutation allowUserAccess($userId: Int!) {
      allowUserAccess(userId:$userId) {
        userId

      }
    }
`;


const AdminActivateUser_mutate = `
    mutation adminActivateUser($userId: Int!) {
      adminActivateUser(userId:$userId) {
        userId

      }
    }
`;


const GetAllUsers_query = `
  query AllUsers {
    allUsers {
      id
      username
      firstName
      lastName
      email
      isActive
      isSuperuser
      userFavTeamField{
        sleagueTeamId {
          teamId {
            name
          }
        }
        favTeamSeq
      }
      profileField {
        isAdmin
        activationDate
        accessAllowedDate
        accessDeniedDate
        idleTime
        autoLogin
        preferedDateFormat
      }
      deleteDate
      dateJoined
    }
  }
`;

const GetAllUsersForRegUser_query = `
  query AllUsersForRegUser {
    allUsersForRegUser {
      id
      username      
      isActive 
    }
  }
`;

module.exports = {
  CREATE_USER_MUTATION : CreateUser_mutate,
  SLEAGUE_TEAMS_QUERY : SleagueTeams_query,
  DENY_USER_ACCESS_MUTATION : DenyUserAccess_mutate,
  ALLOW_USER_ACCESS_MUTATION : AllowUserAccess_mutate,
  ADMIN_ACTIVATE_USER_MUTATION : AdminActivateUser_mutate,
  GET_ALL_USERS_QUERY : GetAllUsers_query,
  GET_ALL_USERS_QUERY_FOR_REG_USER : GetAllUsersForRegUser_query,
}
