import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Layout from "./Layout.js";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function SleagueList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [sleaguesData, setSleaguesData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const navigate = useNavigate();
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!leagueLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const handleChange = async e => {
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid)
      }
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
  };

  const dayFormat = day => {
    const dayDate = new Date(day);
    const newFormat = dayDate.toDateString();
    return newFormat;
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      editable: false
    },
    {
      field: "seasonName",
      headerName: "Season Name",
      width: 200,
      editable: false
    },
    {
      field: "leagueId",
      headerName: "League",
      width: 200,
      editable: false
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      editable: false,
      renderCell: rows => <> {dayFormat(rows.row.startDate)} </>
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 200,
      editable: false,
      renderCell: rows => <>{dayFormat(rows.row.endDate)}</>
    },
    {
      field: "endDateFinal",
      headerName: "Final Date",
      width: 200,
      editable: false,
      renderCell: rows => <> {dayFormat(rows.row.endDateFinal)} </>
    }
  ];
  const rows = sleaguesData.map((sleague, id) => ({
    id: sleague.sleagueId,
    title: sleague.title,
    seasonName: sleague.seasonName,
    startDate: sleague.startDate,
    endDate: sleague.endDate,
    endDateFinal: sleague.endDateFinal,
    leagueId: sleague.leagueId.title
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Season Leagues | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />{" "}
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of League Seasons</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/sleague/add`)}
            >
              + New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
