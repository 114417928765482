import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SPDetailsDialog from "./SPDetailsDialog.js";

import { USERTS_BYUSERID_QUERY } from "../../graphQL/user_ts_qry";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { USERNOTS_BYUSERSLEAGUEID_QUERY } from "../../graphQL/user_sp_no_ts_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";

import AlertDialog from "../../components/AlertDialog.js";

import { project_url } from "../../store/data/Constants";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1280,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    // height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#F5F5F5",
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    minWidth: 950,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  oddCardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    backgroundColor: "#FBF3FF",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    // minHeight: 400,
  },
  oddCardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    backgroundColor: "#FBF3FF",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#FBF3FF",
    },
    // height: 550,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 18,
      fontWeight: 500,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 16,
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  dataGridFooterStyle: {
    height: 56,
    marginBottom: 0,
    backgroundColor: "#765285",
  },
  dataGridFooterTextStyle: {
    color: "#ffffff",
    fontWeight: 500,
    fontSize: 18,
    marginLeft: 22,
    paddingTop: 13,
  },
  dataGridFooterDateStyle: {
    color: "#D2D2D2",
    fontWeight: 400,
    fontSize: 12,
    marginLeft: 20,
    paddingTop: 15,
  },
  leftTeamNameStyle: {
    width: 120,
    textAlign: "right",
    "& .MuiTypography-body2": {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 26,
    },
  },
  rightTeamNameStyle: {
    width: 120,
    textAlign: "left",
    "& .MuiTypography-body2": {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 26,
    },
  },
  scoreNoStyle: {
    display: "flex",
    justifyContent: "center",
    "& .MuiTypography-body2": {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 32,
    },
  },
  scoreNoMobileStyle: {
    display: "flex",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: 700,
  },
  scoreNoTabletStyle: {
    display: "flex",
    justifyContent: "center",
    fontSize: 24,
    fontWeight: 600,
  },
  logoStyle: {
    height: 32,
    width: 32,
    marginTop: 5,
    marginRight: 12,
  },
  logoMobileStyle: {
    height: 48,
    width: 48,
    marginTop: 5,
    marginRight: 12,
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "125px",
    padding: 0,
    height: "35px",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 16,
  },
  mobileFooter: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
  mobileFooterDateStyle: {
    color: "#D2D2D2",
    fontWeight: 400,
    fontSize: 12,
  },
  tabletFooter: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
}));

const SPLedger = () => {
  const classes = useStyles();
  const mediaPath = `/${project_url}/media/`;
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);

  const [totalSP, setTotalSP] = useState(0);
  const [totalNoSP, setTotalNoSP] = useState(0);
  const [userTsData, setUserTsData] = useState([]);
  const [userTSNoData, setUserTSNoData] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [rowData, setRowData] = useState({});
  const [currentLeagueId, setCurrentLeagueId] = useState("");
  const [currentSLeagueId, setCurrentSLeagueId] = useState("");
  const [currentSLeagueText, setCurrentSLeagueText] = useState("");

  // const sleagueId = JSON.parse(localStorage.getItem("LeagueSeasonId")) !== null ?
  //   JSON.parse(localStorage.getItem("LeagueSeasonId")) : 1 ;

  const date =
    new Date().toLocaleString("en-us", { weekday: "long" }) +
    " " +
    new Date().toLocaleString();

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1070 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const { loading, error, data } = useQuery(USERTS_BYUSERID_QUERY);

  const { loading: loadingCurBucketSlg,
          error: errorCurBucketSlge,
          data: dataCurBucketSlg } =
          useQuery(CURRENT_LEAGUE_SEASONS_QUERY);

  const [userSPNoTSData] = useManualQuery(USERNOTS_BYUSERSLEAGUEID_QUERY);
  const client = useContext(ClientContext);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  // Extracting only date from date & time value

  const extractDate = (day) => {
    const date = new Date(day);
    const onlyDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");

    return onlyDate;
  };

  useEffect(() => {
    if (!loadingCurBucketSlg &&
        dataCurBucketSlg &&
        dataCurBucketSlg.currentBucketSleague) {

      setCurrentLeagueId(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.leagueId.leagueId);

      setCurrentSLeagueId(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.sleagueId)

      setCurrentSLeagueText(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.leagueId.subtitle + " - " + dataCurBucketSlg.currentBucketSleague[0].sleagueId.title)

    }
  }, [dataCurBucketSlg])

  useEffect(async () => {

    let totalPoints = 0;

    if (!loading && data && data.userTSByUserid && currentSLeagueId) {

      let filteredData = data.userTSByUserid

      filteredData.map((userTsRecord) => {
        const awardSP = userTsRecord.awardSP;
        if (awardSP != null) {
          totalPoints = totalPoints + parseInt(awardSP);
        }
        setTotalSP(totalPoints);

        const awardDate = userTsRecord.awardDate;
      });

      setUserTsData(filteredData);
    }

    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }

    if (currentSLeagueId){
      let spNoPoints = 0;
      const {
        loading: loadingUserNoTSData,
        error: errorUserNoTSData,
        data: dataUserNoTSData,
      } = await userSPNoTSData({
        variables: {
          sleagueId: parseInt(currentSLeagueId),
        },
      });

      if (
        !loadingUserNoTSData &&
        dataUserNoTSData &&
        dataUserNoTSData.userSpNoTsByUsersleagueid
      ) {

        let userSPNoTsList = [];

        dataUserNoTSData.userSpNoTsByUsersleagueid.map(
          (userSpNoTsRecord, index) => {
            let date = new Date(userSpNoTsRecord.awardDate);

            const awardSP = userSpNoTsRecord.awardSP;
            if (awardSP != null) {
              spNoPoints = spNoPoints + parseInt(awardSP);
            }
            userSPNoTsList.push({
              award: userSpNoTsRecord.awardLabel,
              sp: "+" + userSpNoTsRecord.awardSP,
              awardType: "Registration",
              awardDate: extractDate(userSpNoTsRecord.awardDate),
            });
          }
        );

        setTotalNoSP(spNoPoints);
        setUserTSNoData(userSPNoTsList);
      };
    };
  }, [data, currentSLeagueId]);

  const tsRows = userTsData.map((tm, index) => {

    return {
      id: index,
      team1: tm.home.sleagueTeamId.teamId.shortName,
      logo1: mediaPath + tm.home.sleagueTeamId.teamId.logo,
      team1FinalScore: tm.home.scoreFinal !== null ? tm.home.scoreFinal : "()",
      team2: tm.guest.sleagueTeamId.teamId.shortName,
      logo2: mediaPath + tm.guest.sleagueTeamId.teamId.logo,
      team2FinalScore:
        tm.guest.scoreFinal !== null ? tm.guest.scoreFinal : "()",
      sp: tm.awardSP !== null ? "+" + tm.awardSP : "Pending",
      maxSp: tm.fixtureTeamId.sleagueTeamId.sleagueId.spMax,
      awardType: "Team Selection",
      awardDate: tm.awardDate !== null ? extractDate(tm.awardDate): "Pending",
      spSeq: tm.userSPSeq,
      coachSeq: tm.fixtureTeamId.coachSeq,
      userSeq: tm.userSeq,
      fixtureTeamId: tm.fixtureTeamId.fixtureTeamId,
      sleagueTeamId: tm.fixtureTeamId.sleagueTeamId.sleagueTeamId,
      calcSPUsersDate: tm.fixtureTeamId.calcSPUsersDate,
      weekName: tm.fixtureTeamId.fixtureId.fixtureWeek.playedWeek == true ?
        tm.fixtureTeamId.fixtureId.fixtureWeek.weekId.name : null,
    };
  });

  tsRows.sort((a,b) => 
      (a.awardDate !== "Pending" && b.awardDate !== "Pending" && 
        new Date(b.awardDate) > new Date(a.awardDate)) ? 1 : -1);

  userTSNoData.map((tm, index) => {
    tm.id = parseInt(tsRows.length + index)
  });

  const rows = tsRows.concat(userTSNoData);

  const columns: GridColDef[] = [
    {
      field: "award",
      headerName: "Award",
      minWidth: 420,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      textAlign: "left",
      resizable: false,
      renderCell: (params) => {
        if (params.row.award) {
          return (
            <Box ml={2}>
              <Typography variant="h3">{params.row.award}</Typography>
            </Box>
          );
        } else {
          return (
            <Box
              mt={1}
              mb={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box mt={1} mr={2}>
                <Typography className={classes.leftTeamNameStyle}>
                  {params.row.team1}
                </Typography>
              </Box>

              <img
                src={params.row.logo1 && params.row.logo1}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography className={classes.scoreNoStyle}>
                  {params.row.team1FinalScore} - {params.row.team2FinalScore}
                </Typography>
              </Box>

              <img
                src={params.row.logo2 && params.row.logo2}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography className={classes.rightTeamNameStyle}>
                  {params.row.team2}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
    },
    {
      field: "sp",
      headerName: "Sport Points (PSP)",
      width: 190,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "awardType",
      headerName: "Award Type",
      width: 180,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "awardDate",
      headerName: "Award Date",
      width: 240,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "details",
      headerName: "Details",
      type: "actions",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        if (!params.row.award) {
          return (
            <Button
              className={classes.outlinedButtonStyle}
              color="primary"
              variant="outlined"
              onClick={() => params.row.calcSPUsersDate != undefined ?
                      showDetails(params.row) : alertDialog(params.row)}
            >
              PSP Details
            </Button>
          );
        }
      },
    },
  ];

  // const generateUniqueRowId = () => {
  //   return Date.now().toString(36) + Math.random().toString(36).substr(2);
  // };

  const showDetails = (rowData) => {
    console.log("row data:",rowData)
    setRowData(rowData);
    setOpenDetailDialog(true);
  }

  const closeDetailDialog = () => {
    setOpenDetailDialog(false);
  }

  const alertDialog = (rowData) => {
    console.log("row data:",rowData)
    setAlertMsg("PSP Calculation for this fixture is not ready yet!");
    setOpenAlertDialog(true);
  }

  const closeAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const customFooter = () => {
    return (
      <Box
        mb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
        className={classes.dataGridFooterStyle}
      >
        <Box mr={1}>
          <Typography gutterBottom className={classes.dataGridFooterTextStyle}>
            Total PSP
          </Typography>
        </Box>
        <Box mr={1} mt={0.5}>
          <Typography gutterBottom className={classes.dataGridFooterDateStyle}>
            (as of {date})
          </Typography>
        </Box>
        <Box mr={1} ml={{ md: 13, lg: 16 }}>
          <Typography gutterBottom className={classes.dataGridFooterTextStyle}>
            { (totalSP + totalNoSP) > 0 ? "+" + (totalSP + totalNoSP) : 0}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Predictal | PSP Ledger</title>        
      </Helmet>
      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="sm">
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
                <Typography gutterBottom variant="subtitle2">
                  {currentSLeagueText}
                </Typography>
              </Box>
              <Box ml={2}>
                <Typography gutterBottom variant="body1">
                  My PSP Ledger
                </Typography>
              </Box>
            </Grid>

            {rows.map((row) => (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent
                    className={`${
                      rows.indexOf(row) % 2 === 0
                        ? classes.oddCardContentMobile
                        : classes.cardContentMobile
                    }`}
                  >
                    {!row.award ? (
                      <Box>
                        <Box
                          mb={1.5}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={row.logo1 && row.logo1}
                            className={classes.logoMobileStyle}
                          />

                          <Box mt={1} mr={2} ml={1}>
                            <Typography className={classes.scoreNoMobileStyle}>
                              {row.team1FinalScore} &nbsp; - &nbsp;{" "}
                              {row.team2FinalScore}
                            </Typography>
                          </Box>

                          <img
                            src={row.logo2 && row.logo2}
                            className={classes.logoMobileStyle}
                          />
                        </Box>
                        <Box
                          mb={2}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Box mr={8}>
                            <Typography className={classes.leftTeamNameStyle}>
                              {row.team1}
                            </Typography>
                          </Box>
                          <Box ml={3}>
                            <Typography className={classes.rightTeamNameStyle}>
                              {row.team2}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box mb={2} style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: "#7D7D7D",
                          }}
                        >
                          {row.award}
                        </Typography>
                      </Box>
                    )}

                    <Divider />

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box mt={2}>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                          Sport Points (PSP):
                        </Typography>
                      </Box>
                      <Box mt={2.5}>
                        <Typography variant="h3">{row.sp}</Typography>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box mt={2}>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                          Award Type:
                        </Typography>
                      </Box>
                      <Box mt={2.5}>
                        <Typography variant="h3">{row.awardType}</Typography>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box mt={2}>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                          Award Date:
                        </Typography>
                      </Box>
                      <Box mt={2.5}>
                        <Typography variant="h3">{row.awardDate}</Typography>
                      </Box>
                    </Box>

                    {!row.award && (
                      <Box
                        mt={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className={classes.outlinedButtonStyle}
                          color="primary"
                          variant="outlined"
                          onClick={() => row.calcSPUsersDate != undefined ?
                              showDetails(row) : alertDialog()}
                        >
                          PSP Details
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent
                  className={classes.mobileFooter}
                  style={{ backgroundColor: "#765285" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box mt={2.5}>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#FFFFFF",
                          }}
                        >
                          Total PSP
                        </Typography>
                      </Box>

                      <Box mt={3} ml={2}>
                        <Typography
                          style={{
                            fontSize: 10,
                            fontWeight: 400,
                            color: "#D2D2D2",
                          }}
                        >
                          (as of {date})
                        </Typography>
                      </Box>
                    </Box>

                    <Box mt={2.5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        { (totalSP + totalNoSP) > 0 ? "+" + (totalSP + totalNoSP) : 0}
                      </Typography>
                    </Box>
                  </Box>
                  {openDetailDialog && (
                    <SPDetailsDialog
                      leagueId={currentLeagueId}
                      fixtureData={rowData}
                      closeDialog={closeDetailDialog}
                    />
                  )}
                  {openAlertDialog && (
                    <AlertDialog
                      title="Alert"
                      closeDialog={closeAlertDialog}
                      contentMsg={alertMsg}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Grid container spacing={1}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
                <Typography gutterBottom variant="subtitle1">
                  { currentSLeagueText }
                </Typography>
              </Box>
              <Box ml={2}>
                <Typography gutterBottom variant="subtitle2">
                  My PSP Ledger
                </Typography>
              </Box>
            </Grid>

            {rows.map((row) => (
              <Grid item lg={4} md={12} xl={3} xs={12}>
                <Card>
                  <CardContent
                    className={`${
                      rows.indexOf(row) % 2 === 0
                        ? classes.oddCardContentTablet
                        : classes.cardContentTablet
                    }`}
                  >
                    {!row.award ? (
                      <Box
                        mb={2.5}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Box mt={1.5} mr={3}>
                          <Typography className={classes.leftTeamNameStyle}>
                            {row.team1}
                          </Typography>
                        </Box>

                        <img
                          src={row.logo1 && row.logo1}
                          className={classes.logoMobileStyle}
                        />

                        <Box mt={1} mr={2} ml={1}>
                          <Typography className={classes.scoreNoTabletStyle}>
                            {row.team1FinalScore} &nbsp; - &nbsp;{" "}
                            {row.team2FinalScore}
                          </Typography>
                        </Box>

                        <img
                          src={row.logo2 && row.logo2}
                          className={classes.logoMobileStyle}
                        />

                        <Box mt={1.5} ml={2}>
                          <Typography className={classes.rightTeamNameStyle}>
                            {row.team2}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box mb={2} style={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: "#7D7D7D",
                          }}
                        >
                          {row.award}
                        </Typography>
                      </Box>
                    )}

                    <Divider />

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box mt={2} mr={2}>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                          Sport Points (PSP):
                        </Typography>
                      </Box>
                      <Box mt={2.75}>
                        <Typography variant="h3">{row.sp}</Typography>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box mt={2} mr={2}>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                          Award Type:
                        </Typography>
                      </Box>
                      <Box mt={2.75}>
                        <Typography variant="h3">{row.awardType}</Typography>
                      </Box>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box mt={2} mr={2}>
                          <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                            Award Date:
                          </Typography>
                        </Box>
                        <Box mt={2.75}>
                          <Typography variant="h3">{row.awardDate}</Typography>
                        </Box>
                      </Box>

                      {!row.award && (
                        <Box mt={1}>
                          <Button
                            className={classes.outlinedButtonStyle}
                            color="primary"
                            variant="outlined"
                            onClick={() => row.calcSPUsersDate != undefined ?
                              showDetails(row) : alertDialog()}
                          >
                            PSP Details
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}

            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent
                  className={classes.tabletFooter}
                  style={{ backgroundColor: "#765285" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box mt={2}>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: "#FFFFFF",
                          }}
                        >
                          Total PSP
                        </Typography>
                      </Box>

                      <Box mt={2.75} ml={2}>
                        <Typography className={classes.mobileFooterDateStyle}>
                          (as of {date})
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2.5}>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        { (totalSP + totalNoSP) > 0 ? "+" + (totalSP + totalNoSP) : 0}
                      </Typography>
                    </Box>
                  </Box>
                  {openDetailDialog && (
                    <SPDetailsDialog
                      leagueId={currentLeagueId}
                      fixtureData={rowData}
                      closeDialog={closeDetailDialog}
                    />
                  )}
                  {openAlertDialog && (
                    <AlertDialog
                      title="Alert"
                      closeDialog={closeAlertDialog}
                      contentMsg={alertMsg}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1">
              { currentSLeagueText }
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box mb={1}>
                    <Typography gutterBottom variant="subtitle2">
                      My PSP Ledger
                    </Typography>
                  </Box>
                  <Box className={classes.dataGridStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${rows.indexOf(params.row) % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      components={{
                        Footer: customFooter,
                      }}
                    />
                  </Box>
                  {openDetailDialog && (
                    <SPDetailsDialog
                      leagueId={currentLeagueId}
                      fixtureData={rowData}
                      closeDialog={closeDetailDialog}
                    />
                  )}
                  {openAlertDialog && (
                    <AlertDialog
                      title="Alert"
                      closeDialog={closeAlertDialog}
                      contentMsg={alertMsg}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default SPLedger;
