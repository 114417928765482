import { memo, useContext } from "react";
import { useDrag } from "react-dnd";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { TeamSelectorContext } from "../../UserTeamSelectorApp";

const useStyles = makeStyles({
  mainStyle: {
    cursor: "move",
    float: "right",
    color: "#212121",
    fontStyle: "normal",
    fontWeight: "400",
    font: "14px Roboto, Helvetica, Arial, sans-serif",
    lineHeight: "270%",
    //letterSpacing: "0.15px",
  },
});
export const DragDropPlayer = memo(function DragDropPlayer({
  name,
  onFieldName,
  formationPos,
  jerseyNO,
  playerSeqNO,
  type,
  isDropped,
  status,
}) {
  const classes = useStyles();
  let pointerEventsName = "auto";
  let pointerEvents = "none";
  let color = "black";
  if (isDropped) {
    //  pointerEventsName = "none";
    color = "gray";
  }
  if (status !== "Fit" && status !== "Booked") color = "gray";

  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, onFieldName, playerSeqNO, jerseyNO },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, onFieldName, type, playerSeqNO]
  );

  const showSeqNo = useContext(TeamSelectorContext);
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        paddingLeft: "5px",
      }}
    >
      <div
        className={classes.mainStyle}
        style={{ opacity, pointerEvents, color, width: "20px" }}
      >
        {jerseyNO}
      </div>
      <Tooltip title={name} placement="right">
        <div
          className={classes.mainStyle}
          ref={drag}
          role="DragDropPlayer"
          style={{
            opacity,
            pointerEvents: pointerEventsName,
            color,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px", //115 90
          }}
        >
          {name}
        </div>
      </Tooltip>
    </div>
  );
});

// {showSeqNo && (
//   <div
//     className={classes.mainStyle}
//     style={{ opacity, pointerEvents, color, paddingLeft: "5px" }}
//   >
//     {" (" + `${playerSeqNO}` + ")"}
//   </div>
// )}
