import React from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import Tooltip from "@material-ui/core/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import styled from "styled-components";

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} 
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: '0px 0px 0px -15px'
          }
        }
      }
    }}/>
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #ffffff;
    border-radius: 4;
    font-size: 12px;
    font-weight: 400px;
  }
`;

const Stats = ({ players, selectedRole }) => {
  const defPlayers = [];
  const mfPlayers = [];
  const forPlayers = [];
  const gkPlayers = [];

  players.map((player) => {
    if (player.playerId.position.toLowerCase() === "defender") {
      defPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "midfielder") {
      mfPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "forward") {
      forPlayers.push(player);
    } else if (player.playerId.position.toLowerCase() === "goalkeeper") {
      gkPlayers.push(player);
    }
  });

  var playersList = [];
  if (selectedRole == "goalkeeper") {
    playersList = gkPlayers;
  } else if (selectedRole == "defender") {
    playersList = defPlayers;
  } else if (selectedRole == "midfielder") {
    playersList = mfPlayers;
  } else if (selectedRole == "forward") {
    playersList = forPlayers;
  }

  return (
    <div style={{ width: "100%" }}>
      {console.log("PPP  ", playersList)}
      {playersList.map((player, index) => {
        return (
          <Grid container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 3,
                height: "39px",
                borderBottom: "1px solid white",
              }}
            >
              <Box style={{ width: 50, textAlign: "center" }}>
                <Typography
                  xs={2}
                  spacing={2}
                  variant="h6"
                  style={{ display: "contents" }}
                >
                  {player.stats.games}
                </Typography>
              </Box>
              <Box style={{ width: 60, textAlign: "center" }}>
                <Typography xs={2} variant="h6" style={{ display: "contents" }}>
                  {player.gamesInfo.eject.gamesNo}
                </Typography>
              </Box>
              <Box style={{ width: 60, textAlign: "center" }}>
                <StyledTooltip title={"G(" + parseInt(player.stats.goals)+ ")+OG(" + 
                  parseInt(player.stats.ownGoals) + ")"}>
                  <div>
                    <Typography xs={2} variant="h6" style={{ display: "contents" }}>
                      {player.stats.goals + player.stats.ownGoals}
                    </Typography>   
                  </div>               
                </StyledTooltip> 
              </Box>
              <Box style={{ width: 50, textAlign: "center" }}>
                <Typography xs={2} variant="h6" style={{ display: "contents" }}>
                  {player.stats.yc}
                </Typography>
              </Box>
              <Box style={{ width: 50, textAlign: "center" }}>
                <Typography xs={2} variant="h6" style={{ display: "contents" }}>
                  {player.stats.rc}
                </Typography>
              </Box>
            </div>
          </Grid>
        );
      })}
    </div>
  );
};

export default Stats;
