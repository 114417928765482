const user_SP_No_TS_By_userSleagueId = `
  query ($sleagueId: Int!){
    userSpNoTsByUsersleagueid (sleagueId: $sleagueId){      
      awardLabel
      awardDate
      awardSP      
    }
  }
`;

const user_SP_No_TS_By_selected_userId = `
  query ($sleagueId: Int!, 
         $userId:Int!){
    userSpNoTsBySelectedUserid (sleagueId: $sleagueId,
                                userId: $userId){      
      awardLabel
      awardDate
      awardSP      
    }
  }
`;

module.exports = {
 USERNOTS_BYUSERSLEAGUEID_QUERY: user_SP_No_TS_By_userSleagueId,
 USERNOTS_BY_SELECTED_USERSLEAGUEID_QUERY: user_SP_No_TS_By_selected_userId,
};
