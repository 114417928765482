import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../../graphQL/sleague_team_query";

import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../../graphQL/sleague_qry";
import { GET_SELECTED_TEAM_PAST_FIXTURES } from "../../../graphQL/fixture_qry";

import TeamInfo from "./leftPanel/TeamInfo";
//import PlayerInfo from "./rightPanel/PlayerInfo";
import RightPanelBasicTabs from "./rightPanel/RightPanelBasicTabs";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 1366,
  },
}));
const TeamSelector = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqVal,
  coachSeqVal,
  userTSId,
  spAward,
  embargoTime,
  serverTime,
  selectionDate,
}) => {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [nowPastPlayers, setNowPastPlayers] = useState([]);
  const [userSeqData, setUserSeqData] = useState([]);
  const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [teamSelectionData, setTeamSelectionData] = useState([]);
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState("01");
  const [pastFixtures, setPastFixtures] = useState([]);
  const [copyPastFixtureData, setCopyPastFixtureData] = useState("");
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);

  const {
    loading: nowPastPlayersDataLoading,
    error: nowPastPlayersDataError,
    data: nowPastPlayersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });
  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });

  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  /////////////
  const {
    loading: pastFixtureLoading,
    error: pastFixtureError,
    data: pastFixtureData,
  } = useQuery(GET_SELECTED_TEAM_PAST_FIXTURES, {
    variables: {
      sleagueTeamId: parseInt(sleagueTeamId),
    },
  });

  useEffect(() => {
    console.log("____pastFixtureData_________", pastFixtureData);
    if (
      !pastFixtureLoading &&
      pastFixtureData &&
      pastFixtureData.selectedTeamPastFixtures
    ) {
      let pastFixtures = [];
      let fixIdList = [];

      pastFixtureData.selectedTeamPastFixtures.map((item) => {
        if (
          new Date(item.fixtureId.fixtureWeek.scheduledDate).getTime() >
          serverTime.getTime()
        )
          return;
        let secoundTeam = pastFixtureData.selectedTeamPastFixtures.filter(
          (fx) => {
            return fx.fixtureId.fixtureId === item.fixtureId.fixtureId;
          }
        );
        let index = fixIdList.findIndex((fx) => {
          return fx === item.fixtureId.fixtureId;
        });

        fixIdList.push(item.fixtureId.fixtureId);
        if (index === -1) pastFixtures.push(secoundTeam);
      });
      setPastFixtures(pastFixtures);
    }
  }, [pastFixtureData]);
  ////////////
  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSLTeam]);

  useEffect(() => {
    if (
      !nowPastPlayersDataLoading &&
      nowPastPlayersData &&
      nowPastPlayersData.sleagueTeamNowPastPlayer
    ) {
      console.log(
        "nowPastPlayersData____",
        nowPastPlayersData.sleagueTeamNowPastPlayer
      );
      setNowPastPlayers(nowPastPlayersData.sleagueTeamNowPastPlayer);
    }
  }, [nowPastPlayersData]);

  useEffect(() => {
    if (!playersDataLoading && playersData && playersData.sleagueTeamPlayer) {
      setPlayers(playersData.sleagueTeamPlayer);
    }
  }, [playersData]);

  useEffect(() => {
    let userSeq = userSeqVal.trim();
    let coachSeq = coachSeqVal.trim();
    if (userSeq !== "") setSelectedFormation(userSeq.slice(0, 2));
    else if (coachSeq !== "") setSelectedFormation(coachSeq.slice(0, 2));
    else setSelectedFormation("01");
  }, [coachSeqVal, userSeqVal]);

  const getTeamSelectionsData = (droppedPlayerNames, teamSelections) => {
    setTeamSelectionData(teamSelections);
    let userSeq = "<[" + selectedFormation + "]>";
    let draftList = {};
    // GK

    let key = parseInt(teamSelections.GK[0].positionSeqNo);
    let val = teamSelections.GK[0].lastDroppedItem
      ? teamSelections.GK[0].lastDroppedItem.playerSeqNO
      : "00";
    draftList[key] = val;

    // DEF
    teamSelections.DEF.defLane_col_1 &&
      teamSelections.DEF.defLane_col_1.length > 0 &&
      teamSelections.DEF.defLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.DEF.defLane_col_2 &&
      teamSelections.DEF.defLane_col_2.length > 0 &&
      teamSelections.DEF.defLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // MID
    teamSelections.MID.midLane_col_1 &&
      teamSelections.MID.midLane_col_1.length > 0 &&
      teamSelections.MID.midLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_2 &&
      teamSelections.MID.midLane_col_2.length > 0 &&
      teamSelections.MID.midLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_3 &&
      teamSelections.MID.midLane_col_3.length > 0 &&
      teamSelections.MID.midLane_col_3.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // FOR
    teamSelections.FOR.forLane_col_1 &&
      teamSelections.FOR.forLane_col_1.length > 0 &&
      teamSelections.FOR.forLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.FOR.forLane_col_2 &&
      teamSelections.FOR.forLane_col_2.length > 0 &&
      teamSelections.FOR.forLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );

    Object.keys(draftList).map((i) => {
      if (i < 10) userSeq += "<[0" + i + "]" + "[" + draftList[i] + "]>";
      else userSeq += "<[" + i + "]" + "[" + draftList[i] + "]>";
    });

    setUserSeqData(userSeq);
    setDroppedPlayerNamesData(droppedPlayerNames);
  };
  const getSelectedFormation = (selectedFormation) => {
    setSelectedFormation(selectedFormation);
  };
  const getCopyPastFixtureData = (copyPastFixtureData) => {
    setCopyPastFixtureData(copyPastFixtureData.toString());
  };
  return (
    <Container maxWidth="xl">
      <Grid
        className={classes.container}
        container
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={6}>
          <Paper style={{ width: "100%", minWidth: 639, height: 793 }}>
            <TeamInfo
              userData={userData}
              userFavTeam={userFavTeam}
              fixtureTeamId={fixtureTeamId}
              sleagueTeamId={sleagueTeamId}
              fixtureId={fixtureId}
              leagueId={leagueId}
              userSeqData={userSeqData}
              userSeqVal={userSeqVal}
              coachSeqVal={coachSeqVal}
              userTSId={userTSId}
              spAward={spAward}
              embargoTime={embargoTime}
              serverTime={serverTime}
              sleaguePlayers={players}
              sleagueNowPastPlayers={nowPastPlayers}
              sleagueTeamsData={allSleagueTeamsData}
              passTeamSelectionsData={getTeamSelectionsData}
              passSelectedFormation={getSelectedFormation}
              selectionDate={selectionDate}
              copyPastFixtureDataTS={copyPastFixtureData}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Paper style={{ width: "100%", minWidth: 689, height: 793 }}>
            <RightPanelBasicTabs
              userSeqData={userSeqData}
              userFavTeam={userFavTeam}
              droppedPlayerNamesData={droppedPlayerNamesData}
              teamSelectionData={teamSelectionData}
              sleagueTeamId={sleagueTeamId}
              fixtureId={fixtureId}
              embargoTime={embargoTime}
              serverTime={serverTime}
              pastFixtures={pastFixtures}
              passCopyPastFixtureDataTS={getCopyPastFixtureData}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeamSelector;
