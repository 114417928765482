import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { 
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  TextField,
  makeStyles} from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import {GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY} from "../graphQL/current_bucket_sleague_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";
import { ALL_FORMATIONS_QUERY } from "../graphQL/formation_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_SELECTED_TEAM_FIXTURES } from "../graphQL/fixture_qry";
import { RUN_SELECTION_TEST_MUTATION } from "../graphQL/algorithm_qry";
// import "../styles.css";
import adminStyle from "./Admin.module.scss";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1000,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  dateStyle: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  dateStyleMobile: {
    "& .MuiTypography-body2": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleTablet: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleMobile: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldDelStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldDelStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldStyle: {
    width: "60%",
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#F7F4F7",
    },
  },
  listItemText: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      "&:hover": {
        color: "#765285",
      },
    },
  },
  listItemTextSignout: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      color: "#d32f2f",
    },
  },
  ListItemButton: {
    height: 56,
    "&:hover": {
      borderLeft: "2px solid #765285",
      paddingLeft: "14px",
    },
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#765285",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#765285",
    },
  },
  teamCardSelected: {
    height: "56px",
    border: "1px solid #10A339",
    borderRadius: "8px",
    backgroundColor: "#F7F4F7",
  },
  teamCard: {
    height: "56px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "935px",
      },
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  textButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 16,
    height: "48px",
    padding: 0,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  outlinedRedButtonStyle: {
    width: 140,
    borderRadius: 8,
    color: "#E0281D",
    borderColor: "#E0281D",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  arrowIconStyle: {
    width: 20,
    height: 20,
    padding: 14,
    backgroundColor: "#F8F8F8",
    borderRadius: 8,
  },

  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

///#############

export default function TestTeamSelection() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  const [currentSleagueId, setCurrentSleagueId] = useState(-1);
  const [sleagueTeamsData, setSleagueTeamsData] = useState([]);
  const [currentTeamId, setCurrentTeamId] = useState(-1);
  const [allTeamFixtureData, setAllTeamFixtureData] = useState([]);
  const [currentFixtureId, setCurrentFixtureId] = useState(0);
  const [teamFixtureId, setTeamFixtureId] = useState(0);
  const [teamFixturesData, setTeamFixturesData] = useState([]);
  const [formationData, setFormationData] = useState([]);
  const [formation, setFormation] = useState([]);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [viewVotes, setViewVotes] = useState(false);
  const classes = useStyles();
  const client = useContext(ClientContext);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };
  const { loading: sleagueLoading, 
          error: sleagueError, 
          data: sleagueData} = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, 
                                        {fetchPolicy: "network-only"});
  const [fetchTeamFixtures] = useManualQuery(GET_SELECTED_TEAM_FIXTURES, {
    fetchPolicy: "network-only",
  }); 
  useEffect(() => {
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
    }
    if(sleagueError && sleagueError.graphQLErrors[0].message === "Signature has expired"){
        navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]); 
 
  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });
  const { loading, error, data } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [runSelectionTestMutation] = useMutation(RUN_SELECTION_TEST_MUTATION);
  
  useEffect(
    () => {
      if (!loading && data && data.allFormations) {
        setFormationData(data.allFormations);
        console.log("allData----", formationData);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, formationData]
  );
  const handleSleagueChange = (async (e) => {
    const tid = e.target.value;
    if (e.target.value !== ''){
        setCurrentSleagueId(parseInt(tid));
    }  

    const {
      loading: teamsLoading,
      error: teamsError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!teamsLoading && allTeamsData && allTeamsData.sleagueTeam) {
      allTeamsData.sleagueTeam.sort((a,b) => 
        a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1); 
      setSleagueTeamsData(allTeamsData.sleagueTeam);
    }
    if (teamsError && teamsError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  });
  const handleTeamFixtureChange = (async (e) => {
    if (e.target.value !== ''){
        const fid = e.target.value;
        setTeamFixtureId(parseInt(fid));
    }
  });
  const handleTeamChange = (async (e) => {
    if (e.target.value !== ''){
        const tid = e.target.value;
        setCurrentTeamId(parseInt(tid));
		console.log(tid);

        const {
          loading: teamFixturesLoading,
          error: teamFixturesError,
          data: allTeamFixturesData,
        } = await fetchTeamFixtures({
          variables: { sleagueTeamId: parseInt(tid) },
        });
        console.log(allTeamFixturesData.selectedTeamFixtures);
		console.log(teamFixturesError);
        if (!teamFixturesLoading && 
            allTeamFixturesData && 
            allTeamFixturesData.selectedTeamFixtures) {
			console.log(allTeamFixturesData.selectedTeamFixtures);
			let ars = [];
			allTeamFixturesData.selectedTeamFixtures.map(fixture => {
			  let a = fixture.fixtureId.fixtureId.toString();
			  if (ars[a] === undefined) {
				ars[a] = [];
				ars[a].push(fixture.fixtureTeamId);
				ars[a].push(fixture.sleagueTeamId.teamId);
			  } else {
				ars[a].push(fixture.sleagueTeamId.teamId);
			  }
			});
			console.log(ars);
            setAllTeamFixtureData(ars);
            allTeamFixtureData.map((item, i) => {
                 console.log(item);
				 console.log(item[0]);
                 console.log(item[1]);
				 console.log(item[1].name);});			
        };
    }
  });
  
  const getTeamFixturesData = async (data, selectedTeamId) => {
    let tData = [];
    var matchedFixtureId = []
    await data.map(fixture => {
      let filterFixId = data.filter(
        x => x.fixtureId.fixtureId === fixture.fixtureId.fixtureId); 

      let teamData = [];
      if (filterFixId && filterFixId.length > 0) {
        // search for fixtureId
        const index = tData.findIndex(
          x => x.fixtureId === fixture.fixtureId.fixtureId
        );
        // add the fixtureId if doesn't exist in list
        if (index == -1) {
          filterFixId.map(i => {
            teamData.push({
              teamName: i.sleagueTeamId.teamId.name
            });
          });
        }

        // if teamData exist add its data to result
        if (teamData && teamData.length > 0) {
          // check there are assigned two team for a fixture
          if (teamData && teamData.length > 1) {
            tData.push({
              fixtureId: fixture.fixtureId.fixtureId,
              fixtureWeek: fixture.fixtureId.fixtureWeek.weekId.name,
              scheduledWeek: fixture.fixtureId.scheduledWeek.weekId.name,
              fixtureTeams: [
                { teamName: teamData[0].teamName },
                { teamName: teamData[1].teamName }
              ]
            });
            matchedFixtureId.push(parseInt(fixture.fixtureId.fixtureId));
          }
        }
      }
    });

    setTeamFixturesData(tData);    
    if (matchedFixtureId.length <= 1){
        setCurrentFixtureId(matchedFixtureId[0]);
    }
    return matchedFixtureId
  };
  const handleMultipleChange = (event: SelectChangeEvent<typeof formation>) => {
    const {
      target: { value },
    } = event;
	console.log(value);
	console.log(typeof value);
    setFormation(
      typeof value === 'string' ? value.split(',') : value,
    );
	console.log(formation);
  };
  
  const runSelectionTest = async (formData) =>{
    console.log(formData);
    const {
      data: selectionTestData,
      error: selectionTestError
    } = await runSelectionTestMutation({
      variables: {
        usersNo: parseInt(formData.users),
        sleagueTeamId: parseInt(formData.team),
        fixtureTeamId: parseInt(formData.fixture),
        formation1: parseInt(formData.formation1),
        formation2: parseInt(formData.formation2),
        formation3: parseInt(formData.formation3),
        formation4: parseInt(formData.formation4),
      },
      headers: {
        Authorization: "JWT " + token,
      },
    });
    if (selectionTestError) {
      console.log("Error", selectionTestError, selectionTestData);
      alert(selectionTestError.graphQLErrors[0].message);
      setTimeout("");
    }
	if (selectionTestData && selectionTestData.RunSelectionTest && selectionTestData.RunSelectionTest.maxFormation !== 0) {
        alert("Popular formation is " + selectionTestData.RunSelectionTest.formationTitle + " with " + selectionTestData.RunSelectionTest.maxFormation + " votes. \nPopular players are " + selectionTestData.RunSelectionTest.players + " with " + selectionTestData.RunSelectionTest.votes + " votes.");
        alert(
        "Blank formations: " + selectionTestData.RunSelectionTest.blankFormations +
        "Non-Exist formations: " + selectionTestData.RunSelectionTest.nonExistFormations +
        "Blank players: " + selectionTestData.RunSelectionTest.blankPlayer +
        "Non-Exist players: " + selectionTestData.RunSelectionTest.nonExistPlayer);
		setViewVotes(true);
      }
  };

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2>Test Team Selection Results</h2>
        </Grid>
        <Grid item>
		  <form className={adminStyle.form} onSubmit={handleSubmit(runSelectionTest)}>
            <TextField
				required
				label="Number of Users"
				margin="normal"
				name="users"
				{...register("users", {
                    required: true,
                    value: "Number of Users"
                  })}
				variant="outlined"
				type="number"
			  />
			  {errorMsg && (
				<Box ml={2}>
				  <FormHelperText className={classes.errorStyle} error>
					Number of users cannot be blank.
				  </FormHelperText>
				</Box>
			  )}
			  
			<label className={adminStyle.label} 
              htmlFor="sleague">Current League Season</label>
            <select className={adminStyle.selectWithLabel} 
                id="sleague"
				style={{width:'50%'}}
				name="sleague"
				{...register("sleague", {
                    required: true})}
                onChange={handleSleagueChange}
                value={currentSleagueId}>
              <option value="0">Select a League Season...</option>
              { 
                sleaguesBucketData.map((sleague, i) => (<option key={i} value={sleague.sleagueId.sleagueId}>
                  {sleague.sleagueId.leagueId.subtitle}, Season {sleague.sleagueId.seasonName}
                </option>))
              }
            </select>
			
			<label className={adminStyle.label} 
              htmlFor="team">Teams in the Current League Season</label>
            <select className={adminStyle.selectWithLabel} 
			  id="team"
			  style={{width:'50%'}}
			  name="team"
			  {...register("team", {
                    required: true})} 
              onChange={handleTeamChange}
              value={currentTeamId}>  
              <option value="">Select a Team ...</option>
              {
                sleagueTeamsData.map((sleagueTeam, i) => (
                    <option key={i} value={sleagueTeam.sleagueTeamId}>
                  {sleagueTeam.teamId.name}
                </option>))
              }
            </select>
			
			<label className={adminStyle.label} 
              htmlFor="fixture">Fixtures of Selected Team</label>
            <select className={adminStyle.selectWithLabel}
			  id="fixture"
			  style={{width:'50%'}}
			  name="fixture"
			  {...register("fixture", {
                    required: true})}
			  onChange={handleTeamFixtureChange}>  
              <option value="">Select a Fixture ...</option>
              {
                allTeamFixtureData.map((item, i) => (
                  <option key={i} value={item[0]}>
                    {item[1].name} - {item[2].name}
                  </option>))
              }
            </select>
			
		    <label className={adminStyle.label} 
              htmlFor="formation1">Formations</label>
            <select className={adminStyle.selectWithLabel}
			  id="formation1"
			  style={{width:'50%'}}
			  name="formation1"
			  {...register("formation1", {
                    required: true})}>
              <option value="">Select a Formation ...</option>
              {
                formationData.slice(0,8).map((formation, i) => (
                  <option key={i} value={formation.formationId}>
                    {formation.title}
                  </option>))
              }
            </select>
			<select className={adminStyle.selectWithLabel}
			  id="formation2"
			  style={{width:'50%'}}
			  name="formation2"
			  {...register("formation2", {
                    required: true})}>
              <option value="">Select a Formation ...</option>
              {
                formationData.slice(8,16).map((formation, i) => (
                  <option key={i} value={formation.formationId}>
                    {formation.title}
                  </option>))
              }
            </select>
			<select className={adminStyle.selectWithLabel}
			  id="formation3"
			  style={{width:'50%'}}
			  name="formation3"
			  {...register("formation3", {
                    required: true})}>
              <option value="">Select a Formation ...</option>
              {
                formationData.slice(16,24).map((formation, i) => (
                  <option key={i} value={formation.formationId}>
                    {formation.title}
                  </option>))
              }
            </select>
			<Grid container direction="row" alignItems="flex-start">
			<select className={adminStyle.selectWithLabel}
			  id="formation4"
			  style={{width:'50%'}}
			  name="formation4"
			  {...register("formation4", {
                    required: true})}>
              <option value="">Select a Formation ...</option>
              {
                formationData.slice(24,).map((formation, i) => (
                  <option key={i} value={formation.formationId}>
                    {formation.title}
                  </option>))
              }
            </select>
			<Typography style={{ fontSize: 15, fontWeight: 400, marginLeft: 10 }}>(Note: This formation is used<br /> for the Popular Formation)</Typography>
			</Grid>
			<Button
				className={adminStyle.button}
				style={{ paddingTop: 5,paddingLeft:10, letterSpacing: 0, marginTop: 0}}
				variant="contained"
				color="primary"
				fullWidth={false}
				type="submit"
			  >Run Test</Button>
			&nbsp;&nbsp;
			{viewVotes && (
			  <Button
				className={adminStyle.button}
				variant="contained"
				color="primary"
				fullWidth={false}
				onClick={() => navigate(`/lms/favTeamResults/votes/${teamFixtureId}/`)}
			  >View Votes</Button>
			)}
			</form>
        </Grid>
	  </Grid>
    </Grid>
  );
}