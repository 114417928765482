import React, { useState, useEffect, useCallback } from "react";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import {
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  TextField,
  Radio,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";

import {
  DataGrid,
  GridCellModes,
  GridRenderCellParams,
  GridValueGetterParams,
  gridClasses,
  useGridApiContext,
} from "@mui/x-data-grid";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

import Layout from "../Layout.js";
import { useNavigate } from "react-router-dom";

import suboutImg from "../../assets/subout.png";
import subinImg from "../../assets/subin.png";
import subinoutImg from "../../assets/subinout.png";
import cancelImg from "../../assets/redCancel.png";
import penImg from "../../assets/pen.png";
import delImg from "../../assets/trash.png";

import AlertDialog from "../../components/AlertDialog.js";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY } from "../../graphQL/current_bucket_sleague_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../../graphQL/current_bucket_week_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../../graphQL/sleague_team_query";
import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../graphQL/sleague_qry";
import {
  GET_FIXTURETEAM_TEAMS,
  GET_FIXTURETEAM_BY_ID,
} from "../../graphQL/fixture_qry";
import { GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY } from "../../graphQL/gameStat_qry";

import { POSITIONS_DATA } from "../../store/data/DummyData";
import { project_url, admin_url } from "../../store/data/Constants";
var findAnd = require("find-and");
const mainPath = "/lms/media/";
const useStyles = makeStyles((theme) => ({
  subDialog: {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "800px",
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 4,
    width: "100px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#6A1B9A",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  saveButtonStyle: {
    borderRadius: 4,
    width: "64px",
    padding: 0,
    color: "#fff",
    height: "34px",
    backgroundColor: "#6A1B9A",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  subDataGridStyle: {
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "transparent !important",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: " none !important",
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      fontSize: 12,
    },
  },
  dataGridStyle: {
    "& .MuiDataGrid-cell[data-field='name']": {
      paddingLeft: 0,
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      fontSize: 12,
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#FFFFFF",
    },
    [`& .${gridClasses.row}.odd.Mui-selected`]: {
      backgroundColor: "#FFFFFF !important",
    },
    [`& .${gridClasses.row}.odd:hover`]: {
      backgroundColor: "#FFFFFF",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#FDF2FF",
    },
    [`& .${gridClasses.row}.even.Mui-selected`]: {
      backgroundColor: "#FDF2FF !important",
    },

    [`& .${gridClasses.row}.even:hover`]: {
      backgroundColor: "#FDF2FF",
    },
    [`& .${gridClasses.cell}.rowSpan1[data-field='action']`]: {
      minHeight: "104px !important",
      zIndex: 0,
    },
    [`& .${gridClasses.cell}.rowSpan2[data-field='action']`]: {
      minHeight: "156px !important",
      zIndex: 0,
    },
    [`& .${gridClasses.cell}.subIn1RowSpan[data-field='action']`]: {
      borderBottom: "0px !important",
    },
  },
  radioStyle: {
    marginBottom: 10,
    "& .MuiIconButton-label": {
      color: "#643d74",
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#643d74",
    },
  },
  playerOnFieldStyle: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      height: 40,
      fontSize: 12,
      color: "#212121",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      height: 34,
      width: 50,
      textAlign: "center",
      fontSize: 14,
      //  backgroundColor: "White",
      color: "#212121",
      paddingLeft: 2,
    },
  },
  selectFieldTeam: {
    flexBasis: 490,
    "& .MuiFormLabel-root": {
      fontSize: 14,
      paddingTop: 5,
    },
    "& label.Mui-focused": {
      color: "#643d74",
      paddingTop: 0,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 50,
      width: 460,
      fontSize: 14,
      backgroundColor: "White",
      color: "#212121",
    },
  },

  selectField: {
    flexBasis: 490,
    "& .MuiFormLabel-root": {
      fontSize: 14,
      //  paddingTop:5
    },
    "& label.Mui-focused": {
      color: "#643d74",
      //  paddingTop:0
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 50,
      width: 460,
      fontSize: 14,
      backgroundColor: "White",
      color: "#212121",
    },
  },

  cardContent: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    height: 148,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    backgroundColor: " #F5F5F5",
  },

  listItemFirstChild: {
    borderTopLeftRadius: 8,
    backgroundColor: "#E1BEE7",
    color: "#6A1B9A",

    "&:hover": {
      backgroundColor: "#F7F4F7",
    },
  },
  listItemLastChild: {
    borderBottomLeftRadius: 8,
    backgroundColor: "#E1BEE7",
    color: "#6A1B9A",

    "&:hover": {
      backgroundColor: "#F7F4F7",
    },
  },
  listItem: {
    backgroundColor: "#E1BEE7",
    color: "#6A1B9A",

    "&:hover": {
      backgroundColor: "#F7F4F7",
    },
  },
  listItemTextPanel: {
    "& .MuiTypography-body1": {
      fontSize: 12,
      fontWeight: 400,
      //  color: "#6A1B9A",
      textAlign: "center",
      padding: 5,
    },
  },
  listItemText: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 400,
      color: "#6A1B9A",
      textAlign: "center",
    },
  },
  listItemWeekText: {
    "& .MuiTypography-body1": {
      fontSize: 12,
      fontWeight: 700,
      color: "#6A1B9A",
      textAlign: "center",
    },
  },
  listItemDateText: {
    "& .MuiTypography-body1": {
      fontSize: 10,
      fontWeight: 400,
      color: "#6A1B9A",
      textAlign: "center",
    },
  },
  listItemTextTeam: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 400,
      color: "#6A1B9A",
      textAlign: "left",
    },
  },
  ListItemButton: {
    "&.Mui-selected": {
      backgroundColor: "red",
    },
    height: 45,
    // "&:hover": {
    //   borderLeft: "2px solid #765285",
    //   paddingLeft: "14px",
    // },
  },
}));
const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 500;
  }
`;

const formatter = new Intl.DateTimeFormat("en-us", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});

const dayFormat = (day) => {
  const dayDate = new Date(day);
  const dayFormat = formatter.formatToParts(dayDate);
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  }
};
const FixPlayerStats = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  //Sahar
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [openSelectSubPlayerDialog, setOpenSelectSubPlayerDialog] = useState(
    false
  );
  const [subSelectedPlayer, setSubSelectedPlayer] = useState(null);
  const [doubleSubSelectedRow, setDoubleSubSelectedRow] = useState("");
  const [doubleSubSelectedPlayer1, setDoubleSubSelectedPlayer1] = useState(
    null
  );
  const [doubleSubSelectedPlayer2, setDoubleSubSelectedPlayer2] = useState(
    null
  );
  const [singleSubRows, setSingleSubRows] = useState([]);
  const [doubleSubRows, setDoubleSubRows] = useState([]);
  const [completeEditingSubMin, setCompleteEditingSubMin] = useState(true);
  const [currentPlayerSubChange, setCurrentPlayerSubChange] = useState("");
  const [sleagueTeamPlayersByPos, setSleagueTeamPlayersByPos] = useState({});
  const [coachPlayerSeqNoList, setCoachPlayerSeqNoList] = useState([]);
  const [cardPos, setCardPos] = useState("Goalkeeper");
  const [openDeleteSubDialog, setOpenDeleteSubDialog] = useState([]);
  const [openDeleteNoPlayDialog, setOpenDeleteNoPlayDialog] = useState(null);
  const [startEditSub, setStartEditSub] = useState(null);
  const [editingSubMin, setEditingSubMin] = useState(null);
  const [editingEjectedItem, setEditingEjectedItem] = useState(null);
  const [editingNoPlayItem, setEditingNoPlayItem] = useState(null);
  const [noPlayPlayer, setNoPlayPlayer] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const onFieldOptions = [
    { value: "fullGame", label: "Full Game" },
    { value: "substitution", label: "Substitution" },
    { value: "ejected", label: "Ejected" },
  ];
  const onFieldEjectedOptions = [
    { value: "ejected", label: "Ejected" },
    { value: "fullGame", label: "Full Game" },
    { value: "substitution", label: "Substitution" },
  ];
  // Sahar

  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  const [currentWeekName, setCurrentWeekName] = useState("");
  const [matchedFixturesId, setMatchedFixturesId] = useState([]);

  const [allTeamFixtureData, setAllTeamFixtureData] = useState([]);
  const [currentFixtureId, setCurrentFixtureId] = useState("");
  const [currentFixtureTeamId, setCurrentFixtureTeamId] = useState("");

  const [sleagueWeeksData, setSleagueWeeksData] = useState([]);

  const [sleagueTeamsData, setSleagueTeamsData] = useState([]);

  const [teamFixturesData, setTeamFixturesData] = useState([]);

  const [rows, setRows] = useState([]);

  const [currentSleagueId, setCurrentSleagueId] = useState(-1);

  const [currentWeekId, setCurrentWeekId] = useState(-1);

  const [currentSleagueTeamId, setCurrentSleagueTeamId] = useState(-1);

  const [currentTeamIndex, setCurrentTeamIndex] = useState(null);
  const [currentTeamName, setCurrentTeamName] = useState("");
  const [sleagueTeamPlayers, setSleagueTeamPlayers] = useState([]);
  const [coachSeqVal, setCoachSeqVal] = useState("");
  const [rowList, setRowList] = useState([]);
  const [singleCheck, setSingleCheck] = useState(true);

  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchWeeks] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeamFixtures] = useManualQuery(GET_FIXTURETEAM_TEAMS, {
    fetchPolicy: "network-only",
  });
  const [fetchSleagueTeamPlayer] = useManualQuery(
    GET_SLEAGUETEAM_PLAYER_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );
  const [fixtureTeam] = useManualQuery(GET_FIXTURETEAM_BY_ID);
  const [fetchFixtureTeamGameStats] = useManualQuery(
    GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(async () => {
    if (!currentFixtureTeamId) return;
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(currentFixtureTeamId),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setCoachSeqVal(data.fixtureTeamById.coachSeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [currentFixtureTeamId]);

  useEffect(async () => {
    if (currentSleagueTeamId) {
      const { loading, error, data } = await fetchSleagueTeamPlayer({
        variables: {
          sleagueTeamId: currentSleagueTeamId,
        },
      });

      if (!loading && data && data.sleagueTeamPlayer) {
        console.log("data.sleagueTeamPlayer", data.sleagueTeamPlayer);
        setSleagueTeamPlayers(
          data.sleagueTeamPlayer.map((player) => {
            let playerId = player.playerId.playerId;
            let sleagueTeamPlayerId = player.sleagueTeamPlayerId;
            let name = player.playerId.name;
            let position = player.playerId.position;
            let jerseyNO = player.jerseyNO;
            let playerSeqNO = player.playerSeqNO;
            let status = player.status;
            return {
              playerId,
              sleagueTeamPlayerId,
              name,
              position,
              jerseyNO,
              playerSeqNO,
              status,
            };
          })
        );
      }
    }
  }, [currentSleagueTeamId]);

  useEffect(() => {
    const stpGk = sleagueTeamPlayers.filter(
      (stp) =>
        stp.position === "Goalkeeper" &&
        !coachPlayerSeqNoList.includes(stp.playerSeqNO)
    );
    const stpDef = sleagueTeamPlayers.filter(
      (stp) =>
        stp.position === "Defender" &&
        !coachPlayerSeqNoList.includes(stp.playerSeqNO)
    );
    const stpMid = sleagueTeamPlayers.filter(
      (stp) =>
        stp.position === "Midfielder" &&
        !coachPlayerSeqNoList.includes(stp.playerSeqNO)
    );
    const stpFor = sleagueTeamPlayers.filter(
      (stp) =>
        stp.position === "Forward" &&
        !coachPlayerSeqNoList.includes(stp.playerSeqNO)
    );
    setSleagueTeamPlayersByPos({ stpGk, stpDef, stpMid, stpFor });
  }, [sleagueTeamPlayers, coachPlayerSeqNoList]);

  useEffect(() => {
    if (!coachSeqVal || sleagueTeamPlayers.length === 0) return;
    let selectedFormation = coachSeqVal.slice(0, 2);

    let findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let val = "";
    let seqNoList = [];
    let draftRowList = [];
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i, index) => {
        switch (i) {
          case "01":
            val = coachSeqVal.slice(4, 6);
            break;
          case "02":
            val = coachSeqVal.slice(8, 10);
            break;
          case "03":
            val = coachSeqVal.slice(12, 14);
            break;
          case "04":
            val = coachSeqVal.slice(16, 18);
            break;
          case "05":
            val = coachSeqVal.slice(20, 22);
            break;
          case "06":
            val = coachSeqVal.slice(24, 26);
            break;
          case "07":
            val = coachSeqVal.slice(28, 30);
            break;
          case "08":
            val = coachSeqVal.slice(32, 34);
            break;
          case "09":
            val = coachSeqVal.slice(36, 38);
            break;
          case "10":
            val = coachSeqVal.slice(40, 42);
            break;
          case "11":
            val = coachSeqVal.slice(44, 46);
        }
        seqNoList.push(val);
        resFound = findAnd.returnFound(sleagueTeamPlayers, {
          playerSeqNO: val ? val : null,
        });
        //console.log("resFound___________", resFound);
        if (resFound) {
          let findPosition = findAnd.returnFound(
            findPositionsData.positionsData,
            {
              positionSeqNo: i,
            }
          );
          let cardPosition = "";
          //findPosition.text.replace(/[^a-zA-Z]/g, '')
          if (findPosition.text.includes("GK")) cardPosition = "Goalkeeper";
          if (findPosition.text.includes("DEF")) cardPosition = "Defender";
          if (findPosition.text.includes("MF")) cardPosition = "Midfielder";
          if (findPosition.text.includes("FOR")) cardPosition = "Forward";
          draftRowList.push({
            index,
            playerId: resFound.playerId,
            sleagueTeamPlayerId: resFound.sleagueTeamPlayerId,
            name: resFound.name,
            playerSeqNO: resFound.playerSeqNO,
            jerseyNO: resFound.jerseyNO,
            position: findPosition.text ? findPosition.text : "",
            cardPosition, //cardPosition: resFound.position,
            onField: "Full Game",
            typePlayer: "readOnly",
            typeOnField: "editable",
            subOut: false,
            subIns: [],
            subInDone: false, // select subIns Done
            minPlayed: 90,
            typeMin: "readOnly",
            goal: 0,
            ownGoal: 0,
            typeGoal: "editable",
            rc: false,
            yc: false,
            addingDone: false,
            ejected: false,
          });
        }
      }
    );
    setRowList(draftRowList);
    console.log("seqNoList__", seqNoList, coachSeqVal);
    setCoachPlayerSeqNoList(seqNoList);
  }, [sleagueTeamPlayers, coachSeqVal]);

  useEffect(() => {
    //localStorage.setItem("ReturnedFromEditPage", false);
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
      handleSleagueChange(
        sleagueData.currentBucketSleague[0].sleagueId.sleagueId
      );
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]);

  useEffect(async () => {
    if (currentSleagueTeamId && currentFixtureId && currentFixtureId !== "") {
      const {
        loading: fixtureWeekGameStatLoading,
        error: fixtureWeekGameStatError,
        data: fixtureWeekGameStatData,
      } = await fetchFixtureTeamGameStats({
        variables: {
          sleagueTeamId: currentSleagueTeamId,
          fixtureId: currentFixtureId,
        },
      });

      if (
        !fixtureWeekGameStatLoading &&
        fixtureWeekGameStatData &&
        fixtureWeekGameStatData.gameStatByFixtureTeam
      ) {
        setRows(fixtureWeekGameStatData.gameStatByFixtureTeam);
        // localStorage.setItem(
        //   "statRows",
        //   JSON.stringify(fixtureWeekGameStatData.gameStatByFixtureTeam)
        // );
        // localStorage.setItem("ReturnedFromEditPage", false);
      }
    }
  }, [currentSleagueTeamId, currentFixtureId]);

  // useEffect(() => {
  //   if (teamFixturesData){
  //       localStorage.setItem("fixtureData",
  //           JSON.stringify(teamFixturesData));
  //   }
  // }, [teamFixturesData]);

  useEffect(async () => {
    let tData = [];
    var matchedFixtureId = [];
    await allTeamFixtureData.map((fixture) => {
      let filterFixId = allTeamFixtureData.filter(
        (x) =>
          x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
          parseInt(x.fixtureId.fixtureWeek.weekId.weekId) ===
            parseInt(currentWeekId)
      );

      let teamData = [];
      if (filterFixId && filterFixId.length > 0) {
        // search for fixtureId
        const index = tData.findIndex(
          (x) => x.fixtureId === fixture.fixtureId.fixtureId
        );

        const findPosition = findAnd.returnFound(filterFixId, {
          sleagueTeamId: currentSleagueTeamId,
        });
        // Sahar
        filterFixId.map((i) => {
          if (parseInt(i.sleagueTeamId.sleagueTeamId) === currentSleagueTeamId)
            setCurrentFixtureTeamId(i.fixtureTeamId);
        });
        // Sahar

        // add the fixtureId if doesn't exist in list
        if (index == -1) {
          filterFixId.map((i) => {
            teamData.push({
              teamName: i.sleagueTeamId.teamId.name,
              halfScore: i.scoreHalf1,
              finalScore: i.scoreFinal,
            });
          });
        }

        // if teamData exist add its data to result
        if (teamData && teamData.length > 0) {
          var formatedDate = dayFormat(
            fixture.fixtureId.fixtureWeek.scheduledDate
          );

          // check there are assigned two team for a fixture
          if (teamData && teamData.length > 1) {
            tData.push({
              fixtureId: fixture.fixtureId.fixtureId,
              fixtureWeek: fixture.fixtureId.fixtureWeek.weekId.name,
              fixtureDate: formatedDate,
              scheduledWeek: fixture.fixtureId.scheduledWeek.weekId.name,
              fixtureTeams: [
                {
                  teamName: teamData[0].teamName,
                  halfScore: teamData[0].halfScore,
                  finalScore: teamData[0].finalScore,
                },
                {
                  teamName: teamData[1].teamName,
                  halfScore: teamData[1].halfScore,
                  finalScore: teamData[1].finalScore,
                },
              ],
            });
            matchedFixtureId.push(parseInt(fixture.fixtureId.fixtureId));
          }
        }
      }
    });

    setTeamFixturesData(tData);
    setMatchedFixturesId(matchedFixtureId);

    //localStorage.setItem("fixtureData", JSON.stringify(tData));

    if (matchedFixtureId.length) {
      let selectedFixture = tData.find(
        (item) => item.scheduledWeek === currentWeekName
      );
      if (selectedFixture) {
        setCurrentFixtureId(parseInt(selectedFixture.fixtureId));
        //  localStorage.setItem("curFixId", parseInt(selectedFixture.fixtureId));

        const {
          loading: fixturePlayersStatLoading,
          error: fixturePlayersStatError,
          data: fixturePlayersStatData,
        } = await fetchFixtureTeamGameStats({
          variables: {
            fixtureId: parseInt(selectedFixture.fixtureId),
            sleagueTeamId: parseInt(currentSleagueTeamId),
          },
        });

        if (
          !fixturePlayersStatLoading &&
          fixturePlayersStatData &&
          fixturePlayersStatData.gameStatByFixtureTeam
        ) {
          setRows(fixturePlayersStatData.gameStatByFixtureTeam);
          // localStorage.setItem("statRows",
          //   JSON.stringify(fixturePlayersStatData.gameStatByFixtureTeam));
          // localStorage.setItem("ReturnedFromEditPage", false);
        }
      }
    } else {
      setRows([]);
      setCurrentFixtureId("");
      // localStorage.setItem("curFixId", "");
      // localStorage.setItem("ReturnedFromEditPage", false);
    }
  }, [allTeamFixtureData, currentSleagueTeamId, currentWeekId]);

  const selectFixture = async (fixtureId) => {
    setCurrentFixtureId(parseInt(fixtureId));
    //  localStorage.setItem("curFixId", parseInt(fixtureId));
    //  localStorage.setItem("ReturnedFromEditPage", false);

    const {
      loading: fixturePlayersStatLoading,
      error: fixturePlayersStatError,
      data: fixturePlayersStatData,
    } = await fetchFixtureTeamGameStats({
      variables: {
        fixtureId: parseInt(fixtureId),
        sleagueTeamId: parseInt(currentSleagueTeamId),
      },
    });

    if (
      !fixturePlayersStatLoading &&
      fixturePlayersStatData &&
      fixturePlayersStatData.gameStatByFixtureTeam
    ) {
      setRows(fixturePlayersStatData.gameStatByFixtureTeam);
      //  localStorage.setItem("statRows",
      //    JSON.stringify(fixturePlayersStatData.gameStatByFixtureTeam));
      //  localStorage.setItem("ReturnedFromEditPage", false);
    }
  };

  const handleSleagueChange = async (tid) => {
    if (tid !== "") {
      setCurrentSleagueId(parseInt(tid));
      //  localStorage.setItem("sleagueId", tid);
    }
    //  localStorage.setItem("ReturnedFromEditPage", false);

    if (parseInt(tid) != 0) {
      const {
        loading: weekLoading,
        error: weekError,
        data: weekData,
      } = await fetchWeeks({
        variables: {
          sleagueId: parseInt(tid),
        },
      });

      if (!weekLoading && weekData && weekData.currentSleagueWeek) {
        weekData.currentSleagueWeek.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        setSleagueWeeksData(weekData.currentSleagueWeek);
        // localStorage.setItem("weekData",
        //     JSON.stringify(weekData.currentSleagueWeek));
        //  localStorage.setItem("ReturnedFromEditPage", false);

        if (currentWeekId === -1) {
          let selectedWeek = weekData.currentSleagueWeek.find(
            (item) => item.extraField === true
          );

          if (selectedWeek) {
            setCurrentWeekId(selectedWeek.weekId);
            //Sahar
            //  setCurrentTeamIndex(-1);
            //
            setCurrentWeekName(selectedWeek.name);

            //  localStorage.setItem("weekId", selectedWeek.weekId);
          } else {
            setCurrentWeekId(-1);
            setCurrentWeekName("");
            //    localStorage.setItem("weekId", -1);
          }
        }
      }
    } else {
      setSleagueWeeksData([]);
      //  localStorage.setItem("weekData", []);
      setTeamFixturesData([]);
      //  localStorage.setItem("fixtureData", []);
      //  localStorage.setItem("ReturnedFromEditPage", false);
    }

    const {
      loading: teamsLoading,
      error: teamsError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!teamsLoading && allTeamsData && allTeamsData.sleagueTeam) {
      allTeamsData.sleagueTeam.sort((a, b) =>
        a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1
      );
      setSleagueTeamsData(allTeamsData.sleagueTeam);
      // localStorage.setItem("teamData",
      //           JSON.stringify(allTeamsData.sleagueTeam));
      // localStorage.setItem("ReturnedFromEditPage", false);
    }
    if (
      teamsError &&
      teamsError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleWeekChange = async (e) => {
    if (e.target.value !== "") {
      const wid = e.target.value;
      let selectedWeek = sleagueWeeksData.find((item) => item.weekId === wid);
      setCurrentWeekId(selectedWeek.weekId);
      setCurrentWeekName(selectedWeek.name);

      //  localStorage.setItem("weekId", selectedWeek.weekId);
    }
    //  localStorage.setItem("ReturnedFromEditPage", false);
  };

  const handleTeamChange = async (e) => {
    if (e.target.value !== "") {
      //  localStorage.setItem("sleagueTeamIndex", e.target.value);
      const tid = e.target.value;

      setCurrentTeamIndex(parseInt(tid));

      let selectedTeam = sleagueTeamsData.find(
        (item) => parseInt(item.teamId.teamId) === parseInt(tid)
      );
      if (selectedTeam) {
        setCurrentSleagueTeamId(parseInt(selectedTeam.sleagueTeamId));
        //Sahar
        setCurrentTeamName(selectedTeam.teamId.shortName);
        //

        //  localStorage.setItem("sleagueTeamId", selectedTeam.sleagueTeamId);

        const {
          loading: teamFixturesLoading,
          error: teamFixturesError,
          data: allTeamFixturesData,
        } = await fetchTeamFixtures({
          variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) },
        });

        if (
          !teamFixturesLoading &&
          allTeamFixturesData &&
          allTeamFixturesData.fixtureTeamTeams
        ) {
          setAllTeamFixtureData(allTeamFixturesData.fixtureTeamTeams);
          // localStorage.setItem("allTeamFixtureData",
          //   JSON.stringify(allTeamFixturesData.fixtureTeamTeams));
        }
      }
    }
    //  localStorage.setItem("ReturnedFromEditPage", false);
  };

  const handlePlayerOnfieldChange = async (event) => {
    let changeValue = JSON.parse(event.target.value);
    if (
      editingNoPlayItem &&
      editingNoPlayItem.index !== changeValue.item.index
    ) {
      setAlertMsg("You are editing another player!");
      return;
    }
    if (startEditSub && startEditSub.index !== changeValue.item.index) {
      setAlertMsg("You are editing another player!");
      return;
    }
    if (changeValue.value === "substitution") {
      setOpenSubDialog(true);
      setSingleCheck(true);
      setStartEditSub(null);
      setSubSelectedPlayer(null);
      setCompleteEditingSubMin(true);
      setDoubleSubSelectedPlayer1(null);
      setDoubleSubSelectedPlayer2(null);

      setCurrentPlayerSubChange(changeValue.item);
      setCardPos(changeValue.item.cardPosition);
      getInitSingleSubRows(changeValue.item);
    }
    if (changeValue.value === "ejected") {
      const chgPlayerProps = findAnd.changeProps(
        rowList,
        { playerId: changeValue.item.playerId },
        {
          onField: "Ejected",
          typeOnField: "readOnly",
          typeMin: "editable",
          ejected: "adding",
          minPlayed: 89,
          rc: true,
        }
      );
      setRowList(chgPlayerProps);
    }
    if (changeValue.value === "fullGame") {
      const chgPlayerProps = findAnd.changeProps(
        rowList,
        { playerId: changeValue.item.playerId },
        {
          onField: "Full Game",
          typeOnField: "readOnly",
          typeMin: "readOnly",
          minPlayed: 90,
          rc: false,
          yc: false,
          goal: 0,
          ownGoal: 0,
          ejected: false,
        }
      );
      setRowList(chgPlayerProps);
    }
  };

  const getSleagueTeamPlayersByPos = (
    addedPlayer,
    addedPlayer2 = null,
    cancelRemove = false
  ) => {
    if (cancelRemove) {
      const findRemovedPlayer = sleagueTeamPlayers.filter(
        (x) => x.playerId === addedPlayer.playerId
      )[0];
      if (findRemovedPlayer.position === "Goalkeeper")
        sleagueTeamPlayersByPos.stpGk.push(findRemovedPlayer);

      if (findRemovedPlayer.position === "Defender")
        sleagueTeamPlayersByPos.stpDef.push(findRemovedPlayer);

      if (findRemovedPlayer.position === "Midfielder")
        sleagueTeamPlayersByPos.stpMid.push(findRemovedPlayer);

      if (findRemovedPlayer.position === "Forward")
        sleagueTeamPlayersByPos.stpFor.push(findRemovedPlayer);

      if (addedPlayer2) {
        const findRemovedPlayer2 = sleagueTeamPlayers.filter(
          (x) => x.playerId === addedPlayer2.playerId
        )[0];
        if (findRemovedPlayer.position === "Goalkeeper")
          sleagueTeamPlayersByPos.stpGk.push(findRemovedPlayer2);

        if (findRemovedPlayer.position === "Defender")
          sleagueTeamPlayersByPos.stpDef.push(findRemovedPlayer2);

        if (findRemovedPlayer.position === "Midfielder")
          sleagueTeamPlayersByPos.stpMid.push(findRemovedPlayer2);

        if (findRemovedPlayer.position === "Forward")
          sleagueTeamPlayersByPos.stpFor.push(findRemovedPlayer2);
      }
    } else {
      const stpGk = sleagueTeamPlayersByPos.stpGk.filter(
        (stp) =>
          stp.playerId !== addedPlayer.playerId &&
          stp.playerId !== (addedPlayer2 && addedPlayer2.playerId)
      );
      const stpDef = sleagueTeamPlayersByPos.stpDef.filter(
        (stp) =>
          stp.playerId !== addedPlayer.playerId &&
          stp.playerId !== (addedPlayer2 && addedPlayer2.playerId)
      );
      const stpMid = sleagueTeamPlayersByPos.stpMid.filter(
        (stp) =>
          stp.playerId !== addedPlayer.playerId &&
          stp.playerId !== (addedPlayer2 && addedPlayer2.playerId)
      );
      const stpFor = sleagueTeamPlayersByPos.stpFor.filter(
        (stp) =>
          stp.playerId !== addedPlayer.playerId &&
          stp.playerId !== (addedPlayer2 && addedPlayer2.playerId)
      );
      setSleagueTeamPlayersByPos({ stpGk, stpDef, stpMid, stpFor });
    }
  };

  const getRowListbyDoneAddingSubIns = async () => {
    if (singleCheck) {
      const findPlayer = findAnd.returnFound(singleSubRows, {
        playerId:
          currentPlayerSubChange && currentPlayerSubChange.playerId.toString(),
      });

      const chgPlayerProps = findAnd.changeProps(
        rowList,
        { playerId: currentPlayerSubChange.playerId },
        {
          onField: "Sub-out",
          typeOnField: "readOnly",
          subOut: true,
          minPlayed: findPlayer && findPlayer.minPlayed,
          typeMin: "editable",
          ejected: false,
          yc: false,
          rc: false,
          goal: 0,
          ownGoal: 0,
          subInDone: true,
          subIns: [
            {
              ...subSelectedPlayer,
              minPlayed: singleSubRows && singleSubRows[1].minPlayed,
            },
          ],
        }
      );
      const subPlayer = {
        index: currentPlayerSubChange.index,
        playerId: subSelectedPlayer.playerId,
        sleagueTeamPlayerId: subSelectedPlayer.sleagueTeamPlayerId,
        name: subSelectedPlayer.name,
        playerSeqNO: subSelectedPlayer.playerSeqNO,
        jerseyNO: subSelectedPlayer.jerseyNO,
        position: currentPlayerSubChange.position,
        onField: "Sub-in",
        typePlayer: "selected",
        typeOnField: "readOnly",
        minPlayed: singleSubRows && singleSubRows[1].minPlayed,
        typeMin: "readOnly",
        goal: 0,
        ownGoal: 0,
        typeGoal: "editable",
        rc: false,
        yc: false,
        subInFor: { playerId: currentPlayerSubChange.playerId },
      };
      const addSubPlayerToList = findAnd.insertObjectAfter(
        chgPlayerProps,
        { playerId: currentPlayerSubChange && currentPlayerSubChange.playerId },
        { ...subPlayer }
      );
      // to remove this player from the list of choices in substitution popup
      getSleagueTeamPlayersByPos(subSelectedPlayer);
      setRowList(addSubPlayerToList);
    } else {
      const findPlayer = findAnd.returnFound(doubleSubRows, {
        playerId:
          currentPlayerSubChange && currentPlayerSubChange.playerId.toString(),
      });

      const chgPlayerProps = findAnd.changeProps(
        rowList,
        { playerId: currentPlayerSubChange.playerId },
        {
          onField: "Sub-out",
          typeOnField: "readOnly",
          subOut: true,
          minPlayed: findPlayer && findPlayer.minPlayed,
          typeMin: "editable",
          subInDone: true,
          ejected: false,
          yc: false,
          rc: false,
          goal: 0,
          ownGoal: 0,
          subIns: [
            {
              ...doubleSubSelectedPlayer1,
              minPlayed: doubleSubRows && doubleSubRows[1].minPlayed,
            },
            {
              ...doubleSubSelectedPlayer2,
              minPlayed: doubleSubRows && doubleSubRows[2].minPlayed,
            },
          ],
        }
      );
      const doubleSubPlayer1 = {
        index: currentPlayerSubChange.index,
        playerId: doubleSubSelectedPlayer1.playerId,
        sleagueTeamPlayerId: doubleSubSelectedPlayer1.sleagueTeamPlayerId,
        name: doubleSubSelectedPlayer1.name,
        playerSeqNO: doubleSubSelectedPlayer1.playerSeqNO,
        jerseyNO: doubleSubSelectedPlayer1.jerseyNO,
        position: currentPlayerSubChange.position,
        onField: "Sub-in-out",
        typePlayer: "selected",
        typeOnField: "readOnly",
        minPlayed: doubleSubRows && doubleSubRows[1].minPlayed,
        typeMin: "editable",
        goal: 0,
        ownGoal: 0,
        typeGoal: "editable",
        rc: false,
        yc: false,
        subIn1For: { playerId: currentPlayerSubChange.playerId }, //...currentPlayerSubChange
      };
      const addDoubleSubPlayer1ToList = findAnd.insertObjectAfter(
        chgPlayerProps,
        { playerId: currentPlayerSubChange && currentPlayerSubChange.playerId },
        { ...doubleSubPlayer1 }
      );

      const doubleSubPlayer2 = {
        index: currentPlayerSubChange.index,
        playerId: doubleSubSelectedPlayer2.playerId,
        sleagueTeamPlayerId: doubleSubSelectedPlayer2.sleagueTeamPlayerId,
        name: doubleSubSelectedPlayer2.name,
        playerSeqNO: doubleSubSelectedPlayer2.playerSeqNO,
        jerseyNO: doubleSubSelectedPlayer2.jerseyNO,
        position: currentPlayerSubChange.position,
        onField: "Sub-in",
        typePlayer: "selected",
        typeOnField: "readOnly",
        minPlayed: doubleSubRows && doubleSubRows[2].minPlayed,
        typeMin: "readOnly",
        goal: 0,
        ownGoal: 0,
        typeGoal: "editable",
        rc: false,
        yc: false,
        subIn2For: { playerId: currentPlayerSubChange.playerId },
      };
      const addDoubleSubPlayer2ToList = findAnd.insertObjectAfter(
        addDoubleSubPlayer1ToList,
        {
          playerId: doubleSubSelectedPlayer1.playerId,
          onField: "Sub-in-out",
        },
        { ...doubleSubPlayer2 }
      );
      setRowList(addDoubleSubPlayer2ToList);
      // to remove theses players from the list of choices in substitution popup
      getSleagueTeamPlayersByPos(
        doubleSubSelectedPlayer1,
        doubleSubSelectedPlayer2
      );
    }
    setStartEditSub(currentPlayerSubChange);
    setCompleteEditingSubMin(true);
    setSubSelectedPlayer(null);
    setDoubleSubSelectedRow(null);
    setDoubleSubSelectedPlayer1(null);
    setDoubleSubSelectedPlayer2(null);
  };
  const getRowListByDoneAddingNoPlay = async () => {
    setOpenSelectSubPlayerDialog(false);
    const chgPlayerProps = findAnd.changeProps(
      rowList,
      { index: editingNoPlayItem.index },
      {
        playerId: noPlayPlayer.playerId,
        sleagueTeamPlayerId: noPlayPlayer.sleagueTeamPlayerId,
        name: noPlayPlayer.name,
        playerSeqNO: noPlayPlayer.playerSeqNO,
        jerseyNO: noPlayPlayer.jerseyNO,
        noPlay: editingNoPlayItem.noPlay === "done" ? "editing" : "adding",
      }
    );
    const findPlayer = findAnd.returnFound(rowList, {
      index: editingNoPlayItem.index,
    });
    if (findPlayer && findPlayer.sleagueTeamPlayerId)
      await getSleagueTeamPlayersByPos(findPlayer, null, true);
    //to remove this player from the list of choices
    getSleagueTeamPlayersByPos(noPlayPlayer);
    setNoPlayPlayer(null);
    setRowList(chgPlayerProps);
  };

  const getRowListbyDoneEditingSubIns = async () => {
    setOpenSelectSubPlayerDialog(false);
    if (singleCheck) {
      if (!currentPlayerSubChange.addingDone) {
        let findPlayerItem = findAnd.returnFound(rowList, {
          playerId: currentPlayerSubChange.playerId,
        });
        if (!findPlayerItem) return;
        findPlayerItem = findPlayerItem[0] ? findPlayerItem[0] : findPlayerItem;
        const chgPlayerProps1 = findAnd.changeProps(
          rowList,
          { playerId: currentPlayerSubChange.playerId },
          {
            subIns: [
              {
                ...subSelectedPlayer,
                minPlayed: findPlayerItem.subIns[0].minPlayed,
              },
            ],
          }
        );
        const chgPlayerProps2 = findAnd.changeProps(
          chgPlayerProps1,
          { playerId: findPlayerItem.subIns[0].playerId },
          {
            playerId: subSelectedPlayer.playerId,
            sleagueTeamPlayerId: subSelectedPlayer.playerId,
            name: subSelectedPlayer.name,
            playerSeqNO: subSelectedPlayer.playerSeqNO,
            jerseyNO: subSelectedPlayer.jerseyNO,
          }
        );
        // Return the players to the list again
        await getSleagueTeamPlayersByPos(findPlayerItem.subIns[0], null, true);
        //to remove this player from the list of choices in substitution popup
        await getSleagueTeamPlayersByPos(subSelectedPlayer);
        setRowList(chgPlayerProps2);
        setSubSelectedPlayer(null);
        return;
      }
      const chgPlayerProps1 = findAnd.changeProps(
        rowList,
        { playerId: currentPlayerSubChange.playerId },
        {
          subIns: [
            {
              ...subSelectedPlayer,
              minPlayed: currentPlayerSubChange.subIns[0].minPlayed,
            },
          ],
        }
      );
      const chgPlayerProps2 = findAnd.changeProps(
        chgPlayerProps1,
        { playerId: currentPlayerSubChange.subIns[0].playerId },
        {
          playerId: subSelectedPlayer.playerId,
          sleagueTeamPlayerId: subSelectedPlayer.playerId,
          name: subSelectedPlayer.name,
          playerSeqNO: subSelectedPlayer.playerSeqNO,
          jerseyNO: subSelectedPlayer.jerseyNO,
        }
      );
      // Return the players to the list again
      await getSleagueTeamPlayersByPos(
        currentPlayerSubChange.subIns[0],
        null,
        true
      );
      //to remove this player from the list of choices in substitution popup
      getSleagueTeamPlayersByPos(subSelectedPlayer);
      setRowList(chgPlayerProps2);
    } else {
      if (!currentPlayerSubChange.addingDone) {
        let findPlayerItem = findAnd.returnFound(rowList, {
          playerId: currentPlayerSubChange.playerId,
        });
        if (!findPlayerItem) return;
        findPlayerItem = findPlayerItem[0] ? findPlayerItem[0] : findPlayerItem;
        const subIn1 = doubleSubSelectedPlayer1
          ? doubleSubSelectedPlayer1
          : findPlayerItem.subIns[0];
        const subIn2 = doubleSubSelectedPlayer2
          ? doubleSubSelectedPlayer2
          : findPlayerItem.subIns[1];
        const chgPlayerProps1 = findAnd.changeProps(
          rowList,
          { playerId: currentPlayerSubChange.playerId },
          {
            subIns: [
              {
                ...subIn1,
                minPlayed: findPlayerItem.subIns[0].minPlayed,
              },
              {
                ...subIn2,
                minPlayed: findPlayerItem.subIns[1].minPlayed,
              },
            ],
          }
        );
        const chgPlayerProps2 = findAnd.changeProps(
          chgPlayerProps1,
          { playerId: findPlayerItem.subIns[0].playerId },
          {
            playerId: subIn1.playerId,
            sleagueTeamPlayerId: subIn1.playerId,
            name: subIn1.name,
            playerSeqNO: subIn1.playerSeqNO,
            jerseyNO: subIn1.jerseyNO,
          }
        );
        const chgPlayerProps3 = findAnd.changeProps(
          chgPlayerProps2,
          { playerId: findPlayerItem.subIns[1].playerId },
          {
            playerId: subIn2.playerId,
            sleagueTeamPlayerId: subIn2.playerId,
            name: subIn2.name,
            playerSeqNO: subIn2.playerSeqNO,
            jerseyNO: subIn2.jerseyNO,
          }
        );
        // Return the players to the list again
        if (doubleSubSelectedRow === "2")
          await getSleagueTeamPlayersByPos(
            findPlayerItem.subIns[0],
            null,
            true
          );
        if (doubleSubSelectedRow === "3")
          await getSleagueTeamPlayersByPos(
            findPlayerItem.subIns[1],
            null,
            true
          );
        //to remove this player from the list of choices in substitution popup
        getSleagueTeamPlayersByPos(
          doubleSubSelectedPlayer1
            ? doubleSubSelectedPlayer1
            : doubleSubSelectedPlayer2
        );
        setRowList(chgPlayerProps3);
        setDoubleSubSelectedRow(null);
        setDoubleSubSelectedPlayer1(null);
        setDoubleSubSelectedPlayer2(null);
        return;
      }
      const subIn1 = doubleSubSelectedPlayer1
        ? doubleSubSelectedPlayer1
        : currentPlayerSubChange.subIns[0];
      const subIn2 = doubleSubSelectedPlayer2
        ? doubleSubSelectedPlayer2
        : currentPlayerSubChange.subIns[1];
      const chgPlayerProps1 = findAnd.changeProps(
        rowList,
        { playerId: currentPlayerSubChange.playerId },
        {
          subIns: [
            {
              ...subIn1,
              minPlayed: currentPlayerSubChange.subIns[0].minPlayed,
            },
            {
              ...subIn2,
              minPlayed: currentPlayerSubChange.subIns[1].minPlayed,
            },
          ],
        }
      );

      const chgPlayerProps2 = findAnd.changeProps(
        chgPlayerProps1,
        { playerId: currentPlayerSubChange.subIns[0].playerId },
        {
          playerId: subIn1.playerId,
          sleagueTeamPlayerId: subIn1.playerId,
          name: subIn1.name,
          playerSeqNO: subIn1.playerSeqNO,
          jerseyNO: subIn1.jerseyNO,
        }
      );
      const chgPlayerProps3 = findAnd.changeProps(
        chgPlayerProps2,
        { playerId: currentPlayerSubChange.subIns[1].playerId },
        {
          playerId: subIn2.playerId,
          sleagueTeamPlayerId: subIn2.playerId,
          name: subIn2.name,
          playerSeqNO: subIn2.playerSeqNO,
          jerseyNO: subIn2.jerseyNO,
        }
      );
      // Return the players to the list again
      if (doubleSubSelectedRow === "2")
        await getSleagueTeamPlayersByPos(
          currentPlayerSubChange.subIns[0],
          null,
          true
        );
      if (doubleSubSelectedRow === "3")
        await getSleagueTeamPlayersByPos(
          currentPlayerSubChange.subIns[1],
          null,
          true
        );
      //to remove this player from the list of choices in substitution popup
      getSleagueTeamPlayersByPos(
        doubleSubSelectedPlayer1
          ? doubleSubSelectedPlayer1
          : doubleSubSelectedPlayer2
      );
      setRowList(chgPlayerProps3);
      // change currentPlayerSubChange with new subIns (I am not sure it is needed)
      const chgCurrentPlayerSubChange = findAnd.changeProps(
        currentPlayerSubChange,
        { playerId: currentPlayerSubChange.playerId },
        {
          subIns: [
            {
              ...subIn1,
              minPlayed: currentPlayerSubChange.subIns[0].minPlayed,
            },
            {
              ...subIn2,
              minPlayed: currentPlayerSubChange.subIns[1].minPlayed,
            },
          ],
        }
      );
      setCurrentPlayerSubChange(chgCurrentPlayerSubChange);
    }
    //  setSingleCheck(true);
    setSubSelectedPlayer(null);
    setDoubleSubSelectedRow(null);
    setDoubleSubSelectedPlayer1(null);
    setDoubleSubSelectedPlayer2(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Player",
      minWidth: 170,
      flex: 2,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => {
        if (rowList.row.subInFor || rowList.row.subOut)
          return (
            <div
              style={{
                display: "flex",
                padding: 0,
                marginTop: rowList.row.subOut ? 8 : 0,
                marginBottom: rowList.row.subInFor ? 8 : 0,
                alignItems: "center",
                height: 50,
                borderLeft: "solid #6A1B9A 3px",
              }}
            >
              <Box pl={1}>
                {rowList.row.typePlayer === "readOnly" ? (
                  rowList.row.jerseyNO + " " + rowList.row.name
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "90%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 8,
                      padding: "5px 5px 5px 5px",
                      border: "1px solid #0000008A",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                        width: "120px",
                      }}
                    >
                      {rowList.row.jerseyNO} {rowList.row.name}
                    </div>
                    <IconButton size="small" sx={{ padding: 0 }}>
                      <ArrowDropDownIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenSelectSubPlayerDialog(true);
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </div>
          );
        else if (rowList.row.subIn1For || rowList.row.subIn2For)
          return (
            <div
              style={{
                display: "flex",
                padding: 0,
                marginBottom: rowList.row.subIn2For ? 8 : 0,
                alignItems: "center",
                height: 50,
                borderLeft: "solid #6A1B9A 3px",
              }}
            >
              <Box pl={1}>
                {rowList.row.typePlayer === "readOnly" ? (
                  rowList.row.jerseyNO + " " + rowList.row.name
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      width: "90%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 8,
                      padding: "5px 5px 5px 5px",
                      border: "1px solid #0000008A",
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                        width: "120px",
                      }}
                    >
                      {rowList.row.jerseyNO} {rowList.row.name}
                    </div>
                    <IconButton size="small" sx={{ padding: 0 }}>
                      <ArrowDropDownIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenSelectSubPlayerDialog(true);
                          setDoubleSubSelectedRow(
                            rowList.row.subIn1For ? "2" : "3"
                          );
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </div>
          );
        else {
          return (
            <Box pl={1}>
              {rowList.row.typePlayer === "readOnly" ? (
                rowList.row.jerseyNO + " " + rowList.row.name
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "90%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: 8,
                    padding: "5px 5px 5px 5px",
                    border: "1px solid #0000008A",
                  }}
                >
                  {rowList.row.onField === "No Play" &&
                  rowList.row.noPlay === "selecting" ? (
                    <div>Select a Player...</div>
                  ) : (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "120px",
                        width: "120px",
                      }}
                    >
                      {rowList.row.jerseyNO} {rowList.row.name}
                    </div>
                  )}
                  <IconButton size="small" sx={{ padding: 0 }}>
                    <ArrowDropDownIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenSelectSubPlayerDialog(true);
                      }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          );
        }
      },
    },
    {
      field: "position",
      headerName: "Position",
      minWidth: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (rowList) => rowList.position,
    },
    {
      field: "onField",
      headerName: "Player - On Field",
      minWidth: 140,
      flex: 1,
      sortable: false,
      //align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      editable: true,
      renderCell: (rowList) => {
        if (rowList.row.typeOnField === "readOnly") {
          return (
            <Box pl={1} style={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <img
                  style={{ paddingRight: 5 }}
                  src={
                    rowList.row.subOut
                      ? suboutImg
                      : rowList.row.subInFor || rowList.row.subIn2For
                      ? subinImg
                      : rowList.row.subIn1For
                      ? subinoutImg
                      : ""
                  }
                />
              </Box>
              <a style={{ paddingTop: rowList.row.subIn1For ? 2 : "" }}>
                {rowList.row.onField}
              </a>
            </Box>
          );
        } else {
          return (
            <Box display="flex" alignItems="center">
              <TextField
                className={classes.playerOnFieldStyle}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                value={rowList.row.onField}
                onChange={handlePlayerOnfieldChange}
              >
                {(rowList.row.onField === "Full Game" ||
                  rowList.row.onField === "Substitution") &&
                  onFieldOptions.map((item, i) => (
                    <option
                      key={rowList.id + i}
                      value={JSON.stringify({
                        id: rowList.id,
                        item: rowList.row,
                        value: item.value,
                      })}
                    >
                      {item.label}
                    </option>
                  ))}
                {rowList.row.ejected === "editing" &&
                  onFieldEjectedOptions.map((item, i) => (
                    <option
                      key={rowList.id + i}
                      value={JSON.stringify({
                        id: rowList.id,
                        item: rowList.row,
                        value: item.value,
                      })}
                    >
                      {item.label}
                    </option>
                  ))}
                >
              </TextField>
            </Box>
          );
        }
      },
    },
    {
      field: "goal",
      headerName: "Goal",
      minWidth: 80,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => {
        if (rowList.row.typeGoal === "readOnly")
          return <a>{rowList.row.goal}</a>;
        else
          return (
            <TextField
              className={classes.textField}
              name="goal"
              variant="outlined"
              onChange={(e) => {
                handleGoalChange(e, rowList.row);
              }}
              value={rowList.row.goal || 0}
            />
          );
      },
    },
    {
      field: "ownGoal",
      headerName: "Own Goal",
      minWidth: 90,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => {
        if (rowList.row.typeGoal === "readOnly")
          return <a>{rowList.row.ownGoal}</a>;
        else
          return (
            <TextField
              className={classes.textField}
              name="ownGoal"
              variant="outlined"
              onChange={(e) => {
                handleOwnGoalChange(e, rowList.row);
              }}
              value={rowList.row.ownGoal || 0}
            />
          );
      },
    },
    {
      field: "minPlayed",
      headerName: "Minutes Played",
      minWidth: 130,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => {
        if (rowList.row.typeMin === "editable") {
          return (
            <TextField
              className={classes.textField}
              name="minPlayed"
              variant="outlined"
              onChange={(e) => {
                if (rowList.row.onField === "Ejected")
                  handleEjectedMinPlayedChanged(e, rowList.row);
                else if (
                  (rowList.row.subOut && rowList.row.subIns.length > 1) ||
                  rowList.row.subIn1For ||
                  rowList.row.subIn2For /*RTS-1723*/
                )
                  handleDoubleMinPlayedChange(e, rowList.row);
                else handleSingleMinPlayedChange(e, rowList.row);
              }}
              value={rowList.row.minPlayed || ""}
            />
          );
        } else {
          return <a>{rowList.row.minPlayed}</a>;
        }
      },
    },
    {
      field: "yc",
      headerName: "Yellow Card",
      minWidth: 90,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => (
        <Checkbox
          checked={rowList.row.yc}
          disabled={
            (rowList.row.typeGoal === "readOnly" &&
              rowList.row.onField !== "No Play") ||
            (rowList.row.onField === "No Play" && rowList.row.noPlay === "done")
          }
          size="small"
          style={{
            color:
              (rowList.row.typeGoal === "readOnly" &&
                rowList.row.onField !== "No Play") ||
              (rowList.row.onField === "No Play" &&
                rowList.row.noPlay === "done")
                ? "gray"
                : "#000000DE",
          }}
          onChange={(e) => {
            handleCheckYellowCard(e.target.checked, rowList.row);
          }}
        />
      ),
    },
    {
      field: "rc",
      headerName: "Red Card",
      minWidth: 80,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => (
        <Checkbox
          checked={rowList.row.rc}
          disabled={
            (rowList.row.typeGoal === "readOnly" &&
              rowList.row.onField !== "No Play") ||
            (rowList.row.onField === "No Play" &&
              rowList.row.noPlay === "done") ||
            rowList.row.onField === "Ejected" ||
            rowList.row.onField === "Full Game" ||
            rowList.row.subOut ||
            rowList.row.subIn1For
          }
          size="small"
          style={{
            color:
              (rowList.row.typeGoal === "readOnly" &&
                rowList.row.onField !== "No Play") ||
              (rowList.row.onField === "No Play" &&
                rowList.row.noPlay === "done") ||
              rowList.row.onField === "Ejected" ||
              rowList.row.onField === "Full Game" ||
              rowList.row.subOut ||
              rowList.row.subIn1For
                ? "gray"
                : "#000000DE",
          }}
          onChange={(e) => {
            handleCheckRedCard(e.target.checked, rowList.row);
          }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 1,
      sortable: false,
      align: "right",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rowList) => {
        if (rowList.row.subOut !== undefined)
          return (
            <>
              {rowList.row.addingDone ? (
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    style={{
                      paddingRight: 20,
                      cursor: "pointer",
                      paddingBottom: 30,
                    }}
                    src={penImg}
                    onClick={() => {
                      if (startEditSub || editingNoPlayItem) {
                        setAlertMsg("You are editing another player!");
                      } else handleEdit(rowList.row);
                    }}
                  />
                  <StyledTooltip
                    title={
                      rowList.row.subIns && rowList.row.subIns.length > 1
                        ? "Delete Double Substitution"
                        : "Delete Single Substitution"
                    }
                  >
                    <img
                      style={{ paddingRight: 20, cursor: "pointer" }}
                      src={delImg}
                      onClick={() => {
                        if (startEditSub || editingNoPlayItem) {
                          setAlertMsg("You are editing another player!");
                        } else if (
                          rowList.row.subIns &&
                          rowList.row.subIns.length > 1
                        )
                          setOpenDeleteSubDialog([
                            rowList.row.subIns[0],
                            rowList.row.subIns[1],
                            rowList.row,
                          ]);
                        else
                          setOpenDeleteSubDialog([
                            rowList.row.subIns[0],
                            rowList.row,
                          ]);
                      }}
                    />
                  </StyledTooltip>
                </Box>
              ) : (
                <>
                  {(rowList.row.addingDone === null ||
                    rowList.row.ejected === "done" ||
                    rowList.row.noPlay === "done") && (
                    <img
                      style={{
                        paddingRight: rowList.row.noPlay === "done" ? 5 : 20,
                        cursor: "pointer",
                      }}
                      src={penImg}
                      onClick={() => {
                        if (startEditSub || editingNoPlayItem) {
                          setAlertMsg("You are editing another player!");
                        } else handleEdit(rowList.row);
                      }}
                    />
                  )}
                  {rowList.row.noPlay === "done" && (
                    <StyledTooltip title={"Delete No Player"}>
                      <img
                        style={{ paddingRight: 20, cursor: "pointer" }}
                        src={delImg}
                        onClick={() => {
                          if (startEditSub || editingNoPlayItem) {
                            setAlertMsg("You are editing another player!");
                          } else setOpenDeleteNoPlayDialog(rowList.row);
                        }}
                      />
                    </StyledTooltip>
                  )}
                  {rowList.row.onField === "Ejected" &&
                    (rowList.row.ejected === "adding" ||
                      rowList.row.ejected === "editing") && (
                      <StyledTooltip
                        title={
                          rowList.row.ejected === "editing"
                            ? "Cancel edit Ejected"
                            : "Cancel Ejected"
                        }
                      >
                        <img
                          style={{ paddingRight: 5, cursor: "pointer" }}
                          src={cancelImg}
                          onClick={() => handleCancelEditEjected(rowList.row)}
                        />
                      </StyledTooltip>
                    )}
                  {rowList.row.onField === "No Play" &&
                    rowList.row.noPlay !== "done" && (
                      <StyledTooltip
                        title={
                          rowList.row.noPlay === "editing"
                            ? "Cancel edit No Player"
                            : "Cancel adding No Player"
                        }
                      >
                        <img
                          style={{ paddingRight: 5, cursor: "pointer" }}
                          src={cancelImg}
                          onClick={() => handleCancelEditNoPlayer(rowList.row)}
                        />
                      </StyledTooltip>
                    )}
                  {startEditSub &&
                  !rowList.row.subInDone &&
                  startEditSub.playerId === rowList.row.playerId ? (
                    <StyledTooltip
                      title={
                        rowList.row.subIns && rowList.row.subIns.length > 1
                          ? "Cancel edit double substitution"
                          : "Cancel edit single substitution"
                      }
                    >
                      <img
                        style={{ paddingRight: 5, cursor: "pointer" }}
                        src={cancelImg}
                        onClick={() => handleCancelEditSub(rowList.row)}
                      />
                    </StyledTooltip>
                  ) : (
                    rowList.row.subInDone && (
                      <StyledTooltip
                        title={
                          rowList.row.subIns && rowList.row.subIns.length > 1
                            ? "Cancel adding double substitution"
                            : "Cancel adding single substitution"
                        }
                      >
                        <img
                          style={{ paddingRight: 5, cursor: "pointer" }}
                          src={cancelImg}
                          onClick={() => handleCancelAddingSub(rowList.row)}
                        />
                      </StyledTooltip>
                    )
                  )}
                  {rowList.row.addingDone !== null &&
                    rowList.row.ejected !== "done" &&
                    rowList.row.noPlay !== "done" && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={
                          (editingSubMin &&
                            editingSubMin === rowList.row.playerId &&
                            !completeEditingSubMin) ||
                          (rowList.row.onField === "No Play" &&
                            (rowList.row.noPlay === "selecting" ||
                              (!rowList.row.yc && !rowList.row.rc))) ||
                          (rowList.row.onField === "Ejected" &&
                            (rowList.row.minPlayed > 89 ||
                              !rowList.row.minPlayed))
                        }
                        className={classes.saveButtonStyle}
                        onClick={() => handleSave(rowList.row)}
                      >
                        Save
                      </Button>
                    )}
                </>
              )}
            </>
          );
      },
      //  renderCell: rows => rows.row.cardRed ? 1 : 0,
      // editable: true,
    },
  ];

  useEffect(() => {
    if (doubleSubSelectedPlayer1) {
      const chgPlayerProps = findAnd.changeProps(
        doubleSubRows,
        { id: "2" },
        {
          playerId: doubleSubSelectedPlayer1.playerId,
          sleagueTeamPlayerId: doubleSubSelectedPlayer1.sleagueTeamPlayerId,
          name: doubleSubSelectedPlayer1.name,
          playerSeqNO: doubleSubSelectedPlayer1.playerSeqNO,
          jerseyNO: doubleSubSelectedPlayer1.jerseyNO,
        }
      );
      setDoubleSubRows(chgPlayerProps);
    }
  }, [doubleSubSelectedPlayer1]);

  useEffect(() => {
    if (doubleSubSelectedPlayer2) {
      const chgPlayerProps = findAnd.changeProps(
        doubleSubRows,
        { id: "3" },
        {
          playerId: doubleSubSelectedPlayer2.playerId,
          sleagueTeamPlayerId: doubleSubSelectedPlayer2.sleagueTeamPlayerId,
          name: doubleSubSelectedPlayer2.name,
          playerSeqNO: doubleSubSelectedPlayer2.playerSeqNO,
          jerseyNO: doubleSubSelectedPlayer2.jerseyNO,
        }
      );
      setDoubleSubRows(chgPlayerProps);
    }
  }, [doubleSubSelectedPlayer2]);

  useEffect(() => {
    if (subSelectedPlayer) {
      const chgPlayerProps = findAnd.changeProps(
        singleSubRows,
        { id: "2" },
        {
          playerId: subSelectedPlayer.playerId,
          sleagueTeamPlayerId: subSelectedPlayer.sleagueTeamPlayerId,
          name: subSelectedPlayer.name,
          playerSeqNO: subSelectedPlayer.playerSeqNO,
          jerseyNO: subSelectedPlayer.jerseyNO,
        }
      );
      setSingleSubRows(chgPlayerProps);
    }
  }, [subSelectedPlayer]);

  function DataGridTitle() {
    return (
      <Grid
        container
        xs={12}
        style={{
          paddingTop: 5,
          paddingBottom: 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} style={{ textAlign: "center" }}>
          <Typography variant="h1" style={{ fontSize: 20, paddingLeft: 100 }}>
            Player Stats
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 10,
          }}
        >
          <Button
            variant="contained"
            size="small"
            disabled={rowList.length === 0 || editingNoPlayItem || startEditSub}
            className={classes.buttonStyle}
            onClick={() => handleNoPlayClick()}
          >
            Add No-player
          </Button>
        </Grid>
        <Divider />
      </Grid>
    );
  }
  const handleGoalChange = (e, player) => {
    const chgRow = findAnd.changeProps(
      rowList,
      { playerId: player.playerId },
      { goal: parseInt(e.target.value) }
    );
    setRowList(chgRow);
  };
  const handleOwnGoalChange = (e, player) => {
    const chgRow = findAnd.changeProps(
      rowList,
      { playerId: player.playerId },
      { ownGoal: parseInt(e.target.value) }
    );
    setRowList(chgRow);
  };
  const handleEjectedMinPlayedChanged = (e, player) => {
    const chgRow = findAnd.changeProps(
      rowList,
      { playerId: player.playerId },
      { minPlayed: parseInt(e.target.value) }
    );
    setRowList(chgRow);
  };
  const handleSingleMinPlayedChange = (e, player) => {
    const minPlayedValue = parseInt(e.target.value);
    // RTS-1723
    if (player.onField === "Sub-in") {
      const findSuboutPlayer = findAnd.returnFound(rowList, {
        playerId: player.subInFor.playerId,
        subOut: true,
      });
      setEditingSubMin(findSuboutPlayer.playerId);
      const chgRow = findAnd.changeProps(
        rowList,
        { playerId: player.playerId },
        { minPlayed: minPlayedValue }
      );
      setRowList(chgRow);
      if (
        !minPlayedValue ||
        !findSuboutPlayer.minPlayed ||
        minPlayedValue > 90 - findSuboutPlayer.minPlayed - 1
      ) {
        setCompleteEditingSubMin(false);
      } else setCompleteEditingSubMin(true);
      return;
    }
    setEditingSubMin(player.playerId);
    const reg = new RegExp("^([1-9]|[1-8][0-9]|89)$");
    const chgRow1 = findAnd.changeProps(
      rowList,
      { playerId: player.playerId },
      { minPlayed: parseInt(minPlayedValue) }
    );
    const findSubinPlayer = findAnd.returnFound(rowList, {
      playerId: player.subIns[0].playerId,
      rc: true,
    });
    const chgRow2 = findAnd.changeProps(
      chgRow1,
      { playerId: player.subIns[0].playerId },
      {
        // RTS-1723
        minPlayed: reg.test(minPlayedValue)
          ? findSubinPlayer
            ? 90 - minPlayedValue - 1
            : 90 - minPlayedValue
          : 0,
        //
      }
    );
    if (!minPlayedValue ||
      (!findSubinPlayer && !reg.test(minPlayedValue))||
      (findSubinPlayer && minPlayedValue > 88)
    )
      setCompleteEditingSubMin(false);
    else setCompleteEditingSubMin(true);
    setRowList(chgRow2);
  };
  const handleDoubleMinPlayedChange = (e, player) => {
    const minPlayedValue = parseInt(e.target.value);
    // RTS-1723
    if (player.onField === "Sub-in") {
      const findSuboutPlayer = findAnd.returnFound(rowList, {
        playerId: player.subIn2For.playerId,
        subOut: true,
      });
      setEditingSubMin(findSuboutPlayer.playerId);
      const chgRow = findAnd.changeProps(
        rowList,
        { playerId: player.playerId },
        { minPlayed: minPlayedValue }
      );
      setRowList(chgRow);
      const findSubin1Player = findAnd.returnFound(rowList, {
        playerId: findSuboutPlayer.subIns[0].playerId,
        onField: "Sub-in-out",
      });
      const findSubin2Player = findAnd.returnFound(rowList, {
        playerId: findSuboutPlayer.subIns[1].playerId,
        onField: "Sub-in",
      });
      if (
        !minPlayedValue ||
        !findSuboutPlayer.minPlayed ||
        !findSubin1Player.minPlayed ||
        minPlayedValue +
          findSuboutPlayer.minPlayed +
          findSubin1Player.minPlayed >
          89
      ) {
        setCompleteEditingSubMin(false);
      } else setCompleteEditingSubMin(true);
      return;
    }
    const reg = new RegExp("^([1-9]|[1-8][0-9]|89)$");
    if (player.subOut) {
      setEditingSubMin(player.playerId);
      const chgRow1 = findAnd.changeProps(
        rowList,
        { playerId: player.playerId },
        { minPlayed: parseInt(minPlayedValue) }
      );
      // reset sub minPlayed
      const chgRow2 = findAnd.changeProps(
        chgRow1,
        { playerId: player.subIns[0].playerId },
        { minPlayed: 0 }
      );
      const chgRow3 = findAnd.changeProps(
        chgRow2,
        { playerId: player.subIns[1].playerId },
        { minPlayed: 0 }
      );
      setCompleteEditingSubMin(false);
      setRowList(chgRow3);
    } else if (player.subIn1For) {
      setEditingSubMin(player.subIn1For.playerId);
      let chgRow3 = [];
      const chgRow2 = findAnd.changeProps(
        rowList,
        { playerId: player.playerId },
        {
          minPlayed: parseInt(minPlayedValue),
        }
      );
      const findSuboutPlayer = findAnd.returnFound(rowList, {
        playerId: player.subIn1For.playerId,
        subOut: true,
      });
      const findSubin1Player = findAnd.returnFound(chgRow2, {
        playerId: player.playerId,
        onField: "Sub-in-out",
      });
      const findSubin2Player = findAnd.returnFound(chgRow2, {
        playerId: findSuboutPlayer.subIns[1].playerId,
        rc: true,
      });

      const twoRowMin =
        findSuboutPlayer.minPlayed &&
        findSubin1Player.minPlayed &&
        findSuboutPlayer.minPlayed + findSubin1Player.minPlayed < 90
          ? findSuboutPlayer.minPlayed + findSubin1Player.minPlayed
          : 0;

      chgRow3 = findAnd.changeProps(
        chgRow2,
        { playerId: findSuboutPlayer.subIns[1].playerId },
        {
          // RTS-1723
          minPlayed:
            reg.test(minPlayedValue) && twoRowMin
              ? findSubin2Player
                ? 90 - twoRowMin - 1
                : 90 - twoRowMin
              : 0,
          //
        }
      );
      setRowList(chgRow3);

      if (
        twoRowMin === 0 ||
        (findSubin2Player &&
          findSuboutPlayer.minPlayed + findSubin1Player.minPlayed > 88)
      )
        setCompleteEditingSubMin(false);
      else setCompleteEditingSubMin(true);
    }
  };
  const handleCheckRedCard = (checked, item) => {
    const chgRow = findAnd.changeProps(
      rowList,
      { playerId: item.playerId },
      { rc: checked }
    );
    // RTS-1723
    let chgRowSubIn = [];
    if (item.onField === "Sub-in") {
      if (item.subInFor) {
        const findSuboutPlayer = findAnd.returnFound(rowList, {
          playerId: item.subInFor.playerId,
          subOut: true,
        });
        let chgRowSubOut = [];
        if (findSuboutPlayer && findSuboutPlayer.minPlayed === 89 && checked) {
          chgRowSubOut = findAnd.changeProps(
            chgRow,
            { playerId: findSuboutPlayer.playerId },
            { minPlayed: 88 }
          );
        }
        if (checked) {
          // check the Red Card checkbox
          chgRowSubIn = findAnd.changeProps(
            chgRowSubOut.length > 0 ? chgRowSubOut : chgRow,
            { playerId: item.playerId },
            {
              typeMin: "editable",
              minPlayed: item.minPlayed > 1 ? item.minPlayed - 1 : 1,
            }
          );
        } else {
          // uncheck the Red Card checkbox
          chgRowSubIn = findAnd.changeProps(
            chgRowSubOut.length > 0 ? chgRowSubOut : chgRow,
            { playerId: item.playerId },
            {
              typeMin: "readOnly",
              minPlayed: findSuboutPlayer.minPlayed
                ? 90 - findSuboutPlayer.minPlayed
                : 1,
            }
          );
          if (findSuboutPlayer.minPlayed)
            setCompleteEditingSubMin(true);
        }
      } else if (item.subIn2For) {
        // for double substitution
        const findSuboutPlayer = findAnd.returnFound(rowList, {
          playerId: item.subIn2For.playerId,
          subOut: true,
        });
        const findSubin1Player = findAnd.returnFound(rowList, {
          playerId: findSuboutPlayer.subIns[0].playerId,
          onField: "Sub-in-out",
        });
        let chgRowSubOut = [];
        if (findSuboutPlayer && findSuboutPlayer.minPlayed === 88 && checked) {
          chgRowSubOut = findAnd.changeProps(
            chgRow,
            { playerId: findSuboutPlayer.playerId },
            { minPlayed: 87 }
          );
        } else if (
          findSuboutPlayer &&
          findSubin1Player &&
          findSuboutPlayer.minPlayed + findSubin1Player.minPlayed > 88 &&
          checked
        ) {
          chgRowSubOut = findAnd.changeProps(
            chgRow,
            { playerId: findSubin1Player.playerId },
            {
              minPlayed:
                findSubin1Player.minPlayed > 1
                  ? findSubin1Player.minPlayed - 1
                  : 0,
            }
          );
        }
        if (checked) {
          // check the Red Card checkbox
          chgRowSubIn = findAnd.changeProps(
            chgRowSubOut.length > 0 ? chgRowSubOut : chgRow,
            { playerId: item.playerId },
            {
              typeMin: "editable",
              minPlayed: item.minPlayed > 1 ? item.minPlayed - 1 : 1,
            }
          );
        } else {
          // uncheck the Red Card checkbox
          const twoRowMin =
            findSubin1Player &&
            findSubin1Player.minPlayed &&
            findSuboutPlayer.minPlayed &&
            findSuboutPlayer.minPlayed + findSubin1Player.minPlayed < 90
              ? 90 - (findSuboutPlayer.minPlayed + findSubin1Player.minPlayed)
              : 1;

          chgRowSubIn = findAnd.changeProps(
            chgRowSubOut.length > 0 ? chgRowSubOut : chgRow,
            { playerId: item.playerId },
            {
              typeMin: "readOnly",
              minPlayed: twoRowMin,
            }
          );
          if (findSuboutPlayer.minPlayed && findSubin1Player.minPlayed)
            setCompleteEditingSubMin(true);
        }
      }
    }
    //
    setRowList(chgRowSubIn.length > 0 ? chgRowSubIn : chgRow);
  };
  const handleCheckYellowCard = (checked, item) => {
    const chgRow = findAnd.changeProps(
      rowList,
      { playerId: item.playerId },
      { yc: checked }
    );
    setRowList(chgRow);
  };
  const handleSave = (item) => {
    if (!item) return;
    //Save information in DB ...
    if (startEditSub && startEditSub.playerId !== item.playerId) {
      setAlertMsg("You are editing another player!");
      return;
    }
    if (
      !startEditSub ||
      (startEditSub && startEditSub.playerId === item.playerId)
    ) {
      if (item.onField === "Ejected") {
        const chgRow = findAnd.changeProps(
          rowList,
          { playerId: item.playerId },
          {
            typeMin: "readOnly",
            typeGoal: "readOnly",
            typeOnField: "readOnly",
            ejected: "done",
          }
        );
        setRowList(chgRow);
        setEditingEjectedItem(null);
        return;
      }
      if (item.onField === "No Play") {
        const chgRow = findAnd.changeProps(
          rowList,
          { playerId: item.playerId },
          {
            typePlayer: "readOnly",
            noPlay: "done",
          }
        );
        setRowList(chgRow);
        setEditingNoPlayItem(null);
        return;
      }
      if (!item.subOut) {
        const chgRow = findAnd.changeProps(
          rowList,
          { playerId: item.playerId },
          {
            typeMin: "readOnly",
            typeGoal: "readOnly",
            typeOnField: "readOnly",
            addingDone: null /*for not subout case*/,
            ejected: false,
          }
        );
        setEditingEjectedItem(null);
        setRowList(chgRow);
        return;
      }
      const chgRow1 = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typePlayer: "readOnly",
          typeGoal: "readOnly",
          addingDone: true,
          ejected: false,
        }
      );
      // for subIns
      const chgRow2 = findAnd.changeProps(
        chgRow1,
        { index: item.index },
        { typeMin: "readOnly", typePlayer: "readOnly", typeGoal: "readOnly" }
      );
      setRowList(chgRow2.length > 0 ? chgRow2 : chgRow1);
      if (startEditSub && startEditSub.playerId === item.playerId)
        setStartEditSub(null);
      setEditingSubMin(null);
    }
  };

  const handleEdit = (item) => {
    if (item.onField === "Ejected") {
      const chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "editable",
          typeGoal: "editable",
          typeOnField: "editable",
          ejected: "editing",
        }
      );
      setEditingEjectedItem(item);
      setRowList(chgRow);
      return;
    }
    if (item.onField === "No Play") {
      const chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typePlayer: "editable",
          noPlay: "editing",
        }
      );
      setEditingNoPlayItem(item);
      setRowList(chgRow);
      return;
    } else if (!item.subOut) {
      const chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typeGoal: "editable",
          typeOnField: "editable",
          addingDone: false,
        }
      );
      setRowList(chgRow);
      return;
    }
    const chgRow1 = findAnd.changeProps(
      rowList,
      { playerId: item.playerId },
      {
        typeMin: "editable",
        typeGoal: "editable",
        subInDone: false,
        addingDone: false,
      }
    );
    // for subIns
    const findSuboutPlayer = findAnd.returnFound(rowList, {
      playerId: item.playerId,
      subOut: true,
    });
    // RTS-1723
    const findSubin1Player = findAnd.returnFound(rowList, {
      playerId: findSuboutPlayer.subIns[0].playerId,
      rc: true,
    });
    const findSubin2Player = findAnd.returnFound(rowList, {
      playerId:
        findSuboutPlayer.subIns[1] && findSuboutPlayer.subIns[1].playerId,
      rc: true,
    });
    const chgRow2 = findAnd.changeProps(
      chgRow1,
      { playerId: findSuboutPlayer.subIns[0].playerId },
      {
        typeMin:
          findSuboutPlayer.subIns[1] || findSubin1Player
            ? "editable"
            : "readOnly",
        typePlayer: "selected",
        typeGoal: "editable",
      }
    );
    const chgRow3 = findAnd.changeProps(
      chgRow2,
      {
        playerId:
          findSuboutPlayer.subIns[1] && findSuboutPlayer.subIns[1].playerId,
      },
      {
        typeMin: findSubin2Player ? "editable" : "readOnly",
        typePlayer: "selected",
        typeGoal: "editable",
      }
    );
    //
    if (!findSuboutPlayer.subIns[1]) {
      const indexPlayer = findAnd.returnFound(rowList, {
        index: item.index,
      });
      const subIn1 = item.subIns[0];
      let goal1 = 0;
      let ownGoal1 = 0;
      let yc1 = 0;
      let rc1 = 0;
      indexPlayer.map((i) => {
        if (i.subInFor) {
          goal1 = i.goal;
          ownGoal1 = i.ownGoal;
          yc1 = i.yc;
          rc1 = i.rc;
        }
      });
      const chgItem = findAnd.changeProps(
        item,
        { playerId: item.playerId },
        {
          subIns: [
            { ...subIn1, goal: goal1, ownGoal: ownGoal1, yc: yc1, rc: rc1 },
          ],
        }
      );
      setStartEditSub(chgItem);
    }
    if (findSuboutPlayer.subIns[1]) {
      const indexPlayer = findAnd.returnFound(rowList, {
        index: item.index,
      });
      const subIn1 = item.subIns[0];
      const subIn2 = item.subIns[1];
      let goal1 = 0;
      let ownGoal1 = 0;
      let yc1 = 0;
      let rc1 = 0;
      let goal2 = 0;
      let ownGoal2 = 0;
      let yc2 = 0;
      let rc2 = 0;
      indexPlayer.map((i) => {
        if (i.subIn1For) {
          goal1 = i.goal;
          ownGoal1 = i.ownGoal;
          yc1 = i.yc;
          rc1 = i.rc;
        }
        if (i.subIn2For) {
          goal2 = i.goal;
          ownGoal2 = i.ownGoal;
          yc2 = i.yc;
          rc2 = i.rc;
        }
      });
      const chgItem = findAnd.changeProps(
        item,
        { playerId: item.playerId },
        {
          subIns: [
            { ...subIn1, goal: goal1, ownGoal: ownGoal1, yc: yc1, rc: rc1 },
            { ...subIn2, goal: goal2, ownGoal: ownGoal2, yc: yc2, rc: rc2 },
          ],
        }
      );
      setStartEditSub(chgItem);
    }
    setSingleCheck(findSuboutPlayer.subIns[1] ? false : true);
    setCardPos(item.cardPosition);
    setCurrentPlayerSubChange(item);
    setRowList(chgRow3.length > 0 ? chgRow3 : chgRow2);
  };
  const handleCancelAddingSub = (item) => {
    const chgRow1 = findAnd.changeProps(
      rowList,
      { playerId: item.playerId },
      {
        typeMin: "readOnly",
        typeOnField: "editable",
        typeGoal: "editable",
        onField: "Full Game",
        minPlayed: 90,
        yc: false,
        rc: false,
        subOut: false,
        subIns: [],
        subInDone: false,
        addingDone: false,
      }
    );
    // to delete subIns
    const filterSuboutRow = rowList.filter((x) => x.playerId === item.playerId);
    let chgRow2 = [];
    let chgRow3 = [];
    if (filterSuboutRow[0].subIns.length > 1) {
      const chgRow2 = findAnd.removeObject(chgRow1, {
        playerId: filterSuboutRow[0].subIns[0].playerId,
      });
      chgRow3 = findAnd.removeObject(chgRow2, {
        playerId: filterSuboutRow[0].subIns[1].playerId,
      });
      // Return the players to the list again
      getSleagueTeamPlayersByPos(
        filterSuboutRow[0].subIns[0],
        filterSuboutRow[0].subIns[1],
        true
      );
      setRowList(chgRow3);
    } else if (filterSuboutRow[0].subIns.length === 1) {
      chgRow2 = findAnd.removeObject(chgRow1, {
        playerId: filterSuboutRow[0].subIns[0].playerId,
      });
      // Return the player to the list again
      getSleagueTeamPlayersByPos(filterSuboutRow[0].subIns[0], null, true);
      setRowList(chgRow2);
    }
    setStartEditSub(null);
    setEditingSubMin(null);
  };
  const handleCancelEditEjected = (item) => {
    let chgRow = [];
    if (editingEjectedItem) {
      chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typeOnField: "readOnly",
          typeGoal: "readOnly",
          onField: "Ejected",
          minPlayed: editingEjectedItem.minPlayed,
          yc: editingEjectedItem.yc,
          rc: editingEjectedItem.rc,
          goal: editingEjectedItem.goal,
          ownGoal: editingEjectedItem.ownGoal,
          ejected: "done",
        }
      );
    } else {
      chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typeOnField: "editable",
          typeGoal: "editable",
          onField: "Full Game",
          minPlayed: 90,
          yc: false,
          rc: false,
          goal: 0,
          ownGoal: 0,
          ejected: false,
        }
      );
    }
    setRowList(chgRow);
    setEditingEjectedItem(null);
  };

  const handleDeleteNoPlayer = (item) => {
    const chgRow = findAnd.removeObject(rowList, { playerId: item.playerId });
    // Return the player to the list again
    getSleagueTeamPlayersByPos(item, null, true);
    //  change index of another rows
    const newRowList = [];
    let draftRowList = chgRow;
    let chgRowIdx = [];
    for (var i = item.index + 1; i < rowList.length; i++) {
      chgRowIdx = findAnd.changeProps(
        draftRowList,
        { index: i },
        { index: i - 1 }
      );
      draftRowList = chgRowIdx;
    }
    setOpenDeleteNoPlayDialog(null);
    setRowList(chgRowIdx);
  };
  const handleCancelEditNoPlayer = async (item) => {
    let chgRow = [];
    if (editingNoPlayItem && editingNoPlayItem.noPlay === "selecting") {
      chgRow = findAnd.removeObject(rowList, { playerId: item.playerId });
      if (item.sleagueTeamPlayerId)
        // Return the player to the list again
        getSleagueTeamPlayersByPos(item, null, true);
      //  change index of another rows
      const newRowList = [];
      let draftRowList = chgRow;
      let chgRowIdx = [];
      for (var i = item.index + 1; i < rowList.length; i++) {
        chgRowIdx = findAnd.changeProps(
          draftRowList,
          { index: i },
          { index: i - 1 }
        );
        draftRowList = chgRowIdx;
      }
      setRowList(chgRowIdx);
    } else {
      chgRow = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          playerId: editingNoPlayItem.playerId,
          sleagueTeamPlayerId: editingNoPlayItem.sleagueTeamPlayerId,
          name: editingNoPlayItem.name,
          playerSeqNO: editingNoPlayItem.playerSeqNO,
          jerseyNO: editingNoPlayItem.jerseyNO,
          rc: editingNoPlayItem.rc,
          yc: editingNoPlayItem.yc,
          noPlay: "done",
          typePlayer: "readOnly",
        }
      );

      if (
        editingNoPlayItem.playerId !== item.playerId &&
        editingNoPlayItem.noPlay !== "selecting"
      ) {
        // Return the player to the list again
        await getSleagueTeamPlayersByPos(editingNoPlayItem, null, true);
        //to remove this player from the list of choices
        getSleagueTeamPlayersByPos(item);
      }
      setRowList(chgRow);
    }
    setEditingNoPlayItem(null);
  };
  const handleCancelEditSub = async (item) => {
    if (!startEditSub) return;
    if (singleCheck) {
      const subIn = startEditSub.subIns[0];
      const chgRow1 = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typeGoal: "readOnly",
          minPlayed: startEditSub.minPlayed,
          goal: startEditSub.goal,
          ownGoal: startEditSub.ownGoal,
          yc: startEditSub.yc,
          rc: startEditSub.rc,
          subIns: [{ ...subIn }],
          addingDone: true,
        }
      );

      const chgRow2 = findAnd.changeProps(
        chgRow1,
        { playerId: item.subIns[0].playerId },
        {
          typeMin: "readOnly",
          typePlayer: "readOnly",
          playerId: subIn.playerId,
          sleagueTeamPlayerId: subIn.sleagueTeamPlayerId,
          name: subIn.name,
          playerSeqNO: subIn.playerSeqNO,
          jerseyNO: subIn.jerseyNO,
          typeGoal: "readOnly",
          minPlayed: subIn.minPlayed,
          goal: subIn.goal,
          ownGoal: subIn.ownGoal,
          yc: subIn.yc,
          rc: subIn.rc,
        }
      );

      // Return the player to the list again
      if (startEditSub.subIns[0].playerId !== item.subIns[0].playerId) {
        await getSleagueTeamPlayersByPos(item.subIns[0], null, true);
        //to remove this player from the list of choices in substitution popup
        getSleagueTeamPlayersByPos(subIn);
      }
      setRowList(chgRow2);
    } else {
      const subIn1 = startEditSub.subIns[0];
      const subIn2 = startEditSub.subIns[1];
      const chgRow1 = findAnd.changeProps(
        rowList,
        { playerId: item.playerId },
        {
          typeMin: "readOnly",
          typeGoal: "readOnly",
          minPlayed: startEditSub.minPlayed,
          goal: startEditSub.goal,
          ownGoal: startEditSub.ownGoal,
          yc: startEditSub.yc,
          rc: startEditSub.rc,
          subIns: [{ ...subIn1 }, { ...subIn2 }],
          addingDone: true,
        }
      );
      let newItem = startEditSub.subIns[0];
      let itemToChange = item.subIns[0];
      const chgRow2 = findAnd.changeProps(
        chgRow1,
        { playerId: itemToChange.playerId },
        {
          typeMin: "readOnly",
          typePlayer: "readOnly",
          playerId: newItem.playerId,
          sleagueTeamPlayerId: newItem.sleagueTeamPlayerId,
          name: newItem.name,
          playerSeqNO: newItem.playerSeqNO,
          jerseyNO: newItem.jerseyNO,
          typeGoal: "readOnly",
          minPlayed: newItem.minPlayed,
          goal: newItem.goal,
          ownGoal: newItem.ownGoal,
          yc: newItem.yc,
          rc: newItem.rc,
        }
      );

      if (newItem.playerId !== itemToChange.playerId) {
        // Return the player to the list again
        await getSleagueTeamPlayersByPos(itemToChange, null, true);
        //to remove this player from the list of choices in substitution popup
        getSleagueTeamPlayersByPos(subIn1);
      }

      newItem = startEditSub.subIns[1];
      itemToChange = item.subIns[1];
      const chgRow3 = findAnd.changeProps(
        chgRow2,
        { playerId: itemToChange.playerId },
        {
          typeMin: "readOnly",
          typePlayer: "readOnly",
          playerId: newItem.playerId,
          sleagueTeamPlayerId: newItem.sleagueTeamPlayerId,
          name: newItem.name,
          playerSeqNO: newItem.playerSeqNO,
          jerseyNO: newItem.jerseyNO,
          typeGoal: "readOnly",
          minPlayed: newItem.minPlayed,
          goal: newItem.goal,
          ownGoal: newItem.ownGoal,
          yc: newItem.yc,
          rc: newItem.rc,
        }
      );

      if (newItem.playerId !== itemToChange.playerId) {
        // Return the player to the list again
        await getSleagueTeamPlayersByPos(itemToChange, null, true);
        //to remove this player from the list of choices in substitution popup
        getSleagueTeamPlayersByPos(subIn2);
      }
      setRowList(chgRow3);
    }
    setStartEditSub(null);
    setEditingSubMin(null);
  };

  const handleNoPlayClick = () => {
    const noPlayRow = {
      index: 0,
      playerId: 0,
      sleagueTeamPlayerId: "",
      name: "",
      position: "N/A",
      playerSeqNO: "",
      jerseyNO: "",
      onField: "No Play",
      noPlay: "selecting",
      typePlayer: "editable",
      typeOnField: "readOnly",
      typeMin: "readOnly",
      typeGoal: "readOnly",
      minPlayed: 0,
      goal: 0,
      ownGoal: 0,
      subOut: false,
      yc: false,
      rc: false,
    };
    const newRowList = [];
    let draftRowList = rowList;
    let chgRow = [];
    var i = rowList[rowList.length - 1].index;
    for (i; i >= 0; i--) {
      chgRow = findAnd.changeProps(
        draftRowList,
        { index: i },
        { index: i + 1 }
      );
      draftRowList = chgRow;
    }
    newRowList.push({ ...noPlayRow }, ...draftRowList);
    setEditingNoPlayItem(noPlayRow);
    setCardPos("Goalkeeper");
    setRowList(newRowList);
  };
  //////// Substitute Dialog

  const handleSingleSubMinPlayedChange = (e) => {
    const reg = new RegExp("^([1-9]|[1-8][0-9]|90)$");
    const chgRow1 = findAnd.changeProps(
      singleSubRows,
      { id: "1" },
      { minPlayed: parseInt(e.target.value) }
    );
    const chgRow2 = findAnd.changeProps(
      chgRow1,
      { id: "2" },
      {
        minPlayed: reg.test(e.target.value) ? 90 - parseInt(e.target.value) : 0,
      }
    );
    if (chgRow2[0].minPlayed > 0 && chgRow2[1].minPlayed > 0)
      setCompleteEditingSubMin(true);
    else setCompleteEditingSubMin(false);
    setSingleSubRows(chgRow2);
  };

  const handleDoubleSubMinPlayedChange = (e, id) => {
    const reg = new RegExp("^([1-9]|[1-8][0-9]|90)$");
    if (id === "1") {
      doubleSubRows[1].minPlayed = 0;
      doubleSubRows[2].minPlayed = 0;
      const chgRow1 = findAnd.changeProps(
        doubleSubRows,
        { id: "1" },
        { minPlayed: parseInt(e.target.value) }
      );
      if (
        chgRow1[0].minPlayed > 0 &&
        chgRow1[1].minPlayed > 0 &&
        chgRow1[2].minPlayed > 0
      )
        setCompleteEditingSubMin(true);
      else setCompleteEditingSubMin(false);
      setDoubleSubRows(chgRow1);
    } else {
      let chgRow3 = [];
      const chgRow2 = findAnd.changeProps(
        doubleSubRows,
        { id: "2" },
        {
          minPlayed: parseInt(e.target.value),
        }
      );

      const twoRowMin =
        chgRow2[0].minPlayed &&
        chgRow2[1].minPlayed &&
        chgRow2[0].minPlayed + chgRow2[1].minPlayed < 90
          ? chgRow2[0].minPlayed + chgRow2[1].minPlayed
          : 0;
      chgRow3 = findAnd.changeProps(
        chgRow2,
        { id: "3" },
        {
          minPlayed: reg.test(e.target.value) && twoRowMin ? 90 - twoRowMin : 0,
        }
      );
      if (
        chgRow3[0].minPlayed > 0 &&
        chgRow3[1].minPlayed > 0 &&
        chgRow3[2].minPlayed > 0
      )
        setCompleteEditingSubMin(true);
      else setCompleteEditingSubMin(false);
      setDoubleSubRows(chgRow3);
    }
  };
  const subColumns = [
    {
      field: "name",
      headerName: "Name of Player",
      width: 200,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rows) => {
        if (rows.row.typePlayer === "readOnly") {
          return (
            <a>
              {rows.row.jerseyNO} {rows.row.name}
            </a>
          );
        } else {
          if (singleCheck && subSelectedPlayer)
            return (
              <a>
                {subSelectedPlayer.jerseyNO} {subSelectedPlayer.name}
              </a>
            );
          else if (
            !singleCheck &&
            doubleSubSelectedPlayer1 &&
            rows.row.id === "2"
          )
            return (
              <a>
                {doubleSubSelectedPlayer1.jerseyNO}{" "}
                {doubleSubSelectedPlayer1.name}
              </a>
            );
          else if (doubleSubSelectedPlayer2 && rows.row.id === "3")
            return (
              <a>
                {doubleSubSelectedPlayer2.jerseyNO}{" "}
                {doubleSubSelectedPlayer2.name}
              </a>
            );
          else
            return (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 8,
                  padding: "8px 8px 8px 16px",
                  border: "1px solid #0000008A",
                }}
              >
                <div>Select a Player...</div>
                <IconButton size="small" sx={{ padding: 0 }}>
                  <ArrowDropDownIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenSelectSubPlayerDialog(true);
                      if (!singleCheck) setDoubleSubSelectedRow(rows.row.id);
                    }}
                  />
                </IconButton>
              </Box>
            );
        }
      },
    },
    {
      field: "position",
      headerName: "Position",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "onField",
      headerName: "Player-On field",
      width: 120,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "minPlayed",
      headerName: "Minutes Played",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (rows) => {
        if (rows.row.typeMin === "editable") {
          return (
            <TextField
              className={classes.textField}
              name="minPlayed"
              variant="outlined"
              onChange={(e) => {
                if (singleCheck) handleSingleSubMinPlayedChange(e);
                else handleDoubleSubMinPlayedChange(e, rows.row.id);
              }}
              value={rows.row.minPlayed || ""}
            />
          );
        } else {
          return <a>{rows.row.minPlayed}</a>;
        }
      },
    },
  ];

  const getInitSingleSubRows = (curPlayer) => {
    setSingleSubRows([
      {
        id: "1",
        playerId: curPlayer.playerId,
        sleagueTeamPlayerId: curPlayer.playerId,
        name: curPlayer.name,
        playerSeqNO: curPlayer.playerSeqNO,
        jerseyNO: curPlayer.jerseyNO,
        position: curPlayer.position,
        onField: "Sub-out",
        typeMin: "editable",
        typePlayer: "readOnly",
        minPlayed: 89,
      },
      {
        id: "2",
        playerId: "",
        sleagueTeamPlayerId: "",
        name: "",
        playerSeqNO: "",
        jerseyNO: "",
        position: curPlayer.position,
        onField: "Sub-in",
        typeMin: "readOnly",
        typePlayer: "selected",
        minPlayed: 1,
      },
    ]);
  };

  const getInitDoubleSubRows = (curPlayer) => {
    setDoubleSubRows([
      {
        id: "1",
        playerId: curPlayer.playerId,
        sleagueTeamPlayerId: curPlayer.playerId,
        name: curPlayer.name,
        playerSeqNO: curPlayer.playerSeqNO,
        jerseyNO: curPlayer.jerseyNO,
        position: curPlayer.position,
        onField: "Sub-out",
        typeMin: "editable",
        typePlayer: "readOnly",
        minPlayed: 88,
      },
      {
        id: "2",
        playerId: "",
        sleagueTeamPlayerId: "",
        name: "",
        playerSeqNO: "",
        jerseyNO: "",
        position: curPlayer.position,
        onField: "Sub-in-out",
        typeMin: "editable",
        typePlayer: "selected",
        minPlayed: 1,
      },
      {
        id: "3",
        playerId: "",
        sleagueTeamPlayerId: "",
        name: "",
        playerSeqNO: "",
        jerseyNO: "",
        position: curPlayer.position,
        onField: "Sub-in",
        typeMin: "readOnly",
        typePlayer: "selected",
        minPlayed: 1,
      },
    ]);
  };

  const handleCloseSubDialog = () => {
    setOpenSubDialog(false);
    setSingleCheck(true);
    setSubSelectedPlayer(null);
    setCompleteEditingSubMin(true);
    setDoubleSubSelectedPlayer1(null);
    setDoubleSubSelectedPlayer2(null);
  };
  const handleCloseSelectSubPlayerDialog = () => {
    setOpenSelectSubPlayerDialog(false);
    setNoPlayPlayer(null);
    setSubSelectedPlayer(null);
    setCompleteEditingSubMin(true);
    setDoubleSubSelectedPlayer1(null);
    setDoubleSubSelectedPlayer2(null);
  };
  const handleClickMenuItem = (name) => {
    setCardPos(name);
  };
  return (
    <Card className={classes.root}>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <Container maxWidth={false} style={{ paddingLeft: 0 }}>
              <Box mt={3} mb={3} textAlign="center">
                <Typography
                  variant="h1"
                  style={{ fontWeight: 400, marginBottom: 20 }}
                >
                  Fixture Player Stats
                </Typography>
                <Divider />
              </Box>
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <TextField
                    className={classes.selectField}
                    label="Select a League Season"
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    onChange={handleSleagueChange}
                    value={currentSleagueId}
                  >
                    {sleaguesBucketData.map((sleague, i) => (
                      <option key={i} value={sleague.sleagueId.sleagueId}>
                        {sleague.sleagueId.leagueId.subtitle}, Season{" "}
                        {sleague.sleagueId.seasonName}
                      </option>
                    ))}
                    >
                  </TextField>

                  <div className={classes.selectField}>
                    <FormControl size="small">
                      <InputLabel>Select a Week</InputLabel>
                      <Select
                        label="Select a Week"
                        onChange={handleWeekChange}
                        variant="outlined"
                        value={currentWeekId}
                        renderValue={() => currentWeekName}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 500,
                              width: 570,
                            },
                          },
                        }}
                      >
                        {sleagueWeeksData.map((week, i) => (
                          <MenuItem
                            key={i}
                            value={week.weekId}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#E0E0E0 !important",
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#616161 !important",
                              },
                              display: "inline-flex",
                              backgroundColor: "#E0E0E0",
                              height: 48,
                              width: 106,
                              borderRadius: 2,
                              padding: 0,
                              margin: 0.5,
                            }}
                          >
                            <Box
                              pl={2}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <ListItemText
                                primary={week.name}
                                className={classes.listItemWeekText}
                                primaryTypographyProps={{
                                  color:
                                    currentWeekId === week.weekId
                                      ? "#FFFFFF !important"
                                      : "#6A1B9A !important",
                                }}
                              />
                              <ListItemText
                                primary="27 Oct - 29 Oct"
                                className={classes.listItemDateText}
                                primaryTypographyProps={{
                                  color:
                                    currentWeekId === week.weekId
                                      ? "#FFFFFF !important"
                                      : "#616161 !important",
                                }}
                              />
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Box>
                <Box pt={5} display="flex" alignItems="center">
                  <div className={classes.selectFieldTeam}>
                    <FormControl size="small">
                      <InputLabel>Select a Team</InputLabel>
                      <Select
                        label="Select a Team"
                        variant="outlined"
                        onChange={handleTeamChange}
                        value={currentTeamIndex}
                        renderValue={() => currentTeamName}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 220,
                              width: 560,
                            },
                          },
                        }}
                      >
                        {sleagueTeamsData.map((team, i) => (
                          <MenuItem
                            key={i}
                            value={team.teamId.teamId}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#E0E0E0 !important",
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#616161 !important",
                              },
                              display: "inline-flex",
                              backgroundColor: "#E0E0E0",
                              height: 32,
                              width: 130,
                              borderRadius: 2,
                              padding: 0,
                              margin: 0.5,
                            }}
                          >
                            <img
                              style={{
                                paddingRight: 3,
                                paddingLeft: 5,
                                width: 14,
                              }}
                              src={mainPath + team.teamId.logo}
                            />
                            <ListItemText
                              primary={team.teamId.shortName}
                              className={classes.listItemTextTeam}
                              primaryTypographyProps={{
                                color:
                                  currentTeamIndex ===
                                  parseInt(team.teamId.teamId)
                                    ? "#FFFFFF !important"
                                    : "#6A1B9A !important",
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    {teamFixturesData.length == 1 && (
                      <>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Typography variant="h3" style={{ marginBottom: 15 }}>
                            Fixture:
                          </Typography>
                          <Typography
                            variant="h3"
                            style={{
                              fontWeight: 500,
                              marginLeft: 5,
                              marginBottom: 15,
                            }}
                          >
                            {teamFixturesData[0] &&
                              teamFixturesData[0].fixtureTeams[0].teamName +
                                " " +
                                teamFixturesData[0].fixtureTeams[0].finalScore +
                                " (" +
                                teamFixturesData[0].fixtureTeams[0].halfScore +
                                ")" +
                                " - " +
                                teamFixturesData[0].fixtureTeams[1].teamName +
                                " " +
                                teamFixturesData[0].fixtureTeams[1].finalScore +
                                " (" +
                                teamFixturesData[0].fixtureTeams[1].halfScore +
                                ")"}
                          </Typography>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Typography variant="h3">Date:</Typography>
                          <Typography
                            variant="h3"
                            style={{
                              fontWeight: 500,
                              marginLeft: 5,
                              //  marginBottom: 5,
                            }}
                          >
                            {teamFixturesData[0] &&
                              teamFixturesData[0].fixtureDate}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {teamFixturesData.length > 1 &&
                    teamFixturesData.map(
                      (fixture, i) =>
                        fixture && (
                          <>
                            <Radio
                              size="small"
                              className={classes.radioStyle}
                              checked={
                                parseInt(fixture.fixtureId) ===
                                parseInt(currentFixtureId)
                              }
                              onChange={() => selectFixture(fixture.fixtureId)}
                            />
                            <Box
                              pt={5}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexBasis: 490,
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  style={{ marginBottom: 15 }}
                                >
                                  Fixture:
                                </Typography>
                                <Typography
                                  variant="h3"
                                  style={{
                                    fontWeight: 500,
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  {fixture.fixtureTeams[0].teamName}{" "}
                                  {fixture.fixtureTeams[0].finalScore} (
                                  {fixture.fixtureTeams[0].halfScore}) -{" "}
                                  {fixture.fixtureTeams[1].teamName}{" "}
                                  {fixture.fixtureTeams[1].finalScore} (
                                  {fixture.fixtureTeams[1].halfScore})
                                </Typography>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography variant="h3">Date:</Typography>
                                <Typography
                                  variant="h3"
                                  style={{
                                    fontWeight: 500,
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  {fixture.fixtureDate}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )
                    )}
                </Box>
              </Box>
            </Container>
          </Grid>
          <Grid item>
            <DataGrid
              className={classes.dataGridStyle}
              columns={columns}
              rows={rowList}
              showCellRightBorder={true}
              hideFooter={true}
              components={{ Toolbar: DataGridTitle }}
              getRowId={(row) => row.playerId}
              autoHeight={true}
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) => {
                if (params.row.index % 2 === 0) return "even";
                else return "odd";
              }}
              getCellClassName={(params) => {
                if (params.row.subOut === true)
                  if (params.row.subIns.length > 1) return "rowSpan2";
                  else return "rowSpan1";
                if (params.row.subIn1For) return "subIn1RowSpan";
              }}
            />
          </Grid>
          <Dialog
            open={openSubDialog}
            //  onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.subDialog}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center", paddingTop: 40 }}
            >
              <Typography variant="subtitle2">
                Substitution For{` "`}
                {currentPlayerSubChange && currentPlayerSubChange.name + `"`}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseSubDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  item
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 350,
                      width: 680,
                    }}
                  >
                    <Grid
                      item
                      style={{
                        alignItems: "center",
                        height: 30,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Radio
                        size="small"
                        className={classes.radioStyle}
                        style={{ marginBottom: 5 }}
                        checked={singleCheck}
                        onChange={() => {
                          setSingleCheck(true);
                          setCompleteEditingSubMin(true);
                          setDoubleSubSelectedPlayer1(null);
                          setDoubleSubSelectedPlayer2(null);
                        }}
                      />
                      <Typography variant="h3">Single Substitution</Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        alignItems: "center",
                        height: 30,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Radio
                        size="small"
                        className={classes.radioStyle}
                        style={{ paddingLeft: 15, marginBottom: 5 }}
                        checked={!singleCheck}
                        onChange={() => {
                          setSingleCheck(false);
                          setCompleteEditingSubMin(true);
                          setSubSelectedPlayer(null);
                          getInitDoubleSubRows(currentPlayerSubChange);
                        }}
                      />
                      <Typography variant="h3">Double Substitution</Typography>
                    </Grid>
                    <Grid item style={{ padding: 30 }}>
                      {singleCheck ? (
                        <DataGrid
                          rows={singleSubRows}
                          columns={subColumns}
                          autoHeight={true}
                          hideFooter={true}
                          showCellRightBorder={true}
                          getRowId={(row) => row.id}
                          className={classes.subDataGridStyle}
                        />
                      ) : (
                        <DataGrid
                          rows={doubleSubRows}
                          columns={subColumns}
                          autoHeight={true}
                          hideFooter={true}
                          showCellRightBorder={true}
                          getRowId={(row) => row.id}
                          className={classes.subDataGridStyle}
                        />
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Box pb={3}>
                <Button
                  variant="contained"
                  disabled={
                    !completeEditingSubMin ||
                    (singleCheck && !subSelectedPlayer) ||
                    (!singleCheck && !doubleSubSelectedPlayer1) ||
                    (!singleCheck && !doubleSubSelectedPlayer2)
                  }
                  onClick={() => {
                    setOpenSubDialog(false);
                    getRowListbyDoneAddingSubIns();
                  }}
                  color="primary"
                  className={classes.buttonStyle}
                  style={{ marginRight: 5 }}
                >
                  Done
                </Button>
                <Button
                  variant="text"
                  //  disabled={}
                  onClick={handleCloseSubDialog}
                  color="primary"
                  className={classes.outlinedButtonStyle}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openSelectSubPlayerDialog}
            //  onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.subDialog}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center", paddingTop: 40 }}
            >
              <Typography variant="subtitle2">
                {editingNoPlayItem
                  ? "Select a No-Player"
                  : "Select Substitute-in Player"}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseSelectSubPlayerDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    height: 350,
                    width: 680,
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    xs={12}
                  >
                    <Grid item xs={1}>
                      <Card>
                        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                          <ListItem
                            disablePadding
                            className={classes.listItemFirstChild}
                            onClick={() => handleClickMenuItem("Goalkeeper")}
                            style={{
                              backgroundColor:
                                cardPos === "Goalkeeper" ? "#F7F4F7" : "",
                            }}
                          >
                            <ListItemButton className={classes.ListItemButton}>
                              <ListItemText
                                primary="GK"
                                className={classes.listItemText}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className={classes.listItem}
                            onClick={() => handleClickMenuItem("Defender")}
                            style={{
                              backgroundColor:
                                cardPos === "Defender" ? "#F7F4F7" : "",
                            }}
                          >
                            <ListItemButton className={classes.ListItemButton}>
                              <ListItemText
                                primary="DEF"
                                className={classes.listItemText}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className={classes.listItem}
                            onClick={() => handleClickMenuItem("Midfielder")}
                            style={{
                              backgroundColor:
                                cardPos === "Midfielder" ? "#F7F4F7" : "",
                            }}
                          >
                            <ListItemButton className={classes.ListItemButton}>
                              <ListItemText
                                primary="MF"
                                className={classes.listItemText}
                              />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            disablePadding
                            className={classes.listItemLastChild}
                            onClick={() => handleClickMenuItem("Forward")}
                            style={{
                              backgroundColor:
                                cardPos === "Forward" ? "#F7F4F7" : "",
                            }}
                          >
                            <ListItemButton className={classes.ListItemButton}>
                              <ListItemText
                                primary="FOR"
                                className={classes.listItemText}
                              />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                    <Grid item xs={9}>
                      <Card>
                        <CardContent className={classes.cardContent}>
                          <Grid item xs={4}>
                            {cardPos === "Goalkeeper" &&
                              sleagueTeamPlayersByPos.stpGk &&
                              sleagueTeamPlayersByPos.stpGk.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpGk.indexOf(i) <
                                    4 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Defender" &&
                              sleagueTeamPlayersByPos.stpDef &&
                              sleagueTeamPlayersByPos.stpDef.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpDef.indexOf(i) <
                                    4 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Midfielder" &&
                              sleagueTeamPlayersByPos.stpMid &&
                              sleagueTeamPlayersByPos.stpMid.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpMid.indexOf(i) <
                                    4 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Forward" &&
                              sleagueTeamPlayersByPos.stpFor &&
                              sleagueTeamPlayersByPos.stpFor.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpFor.indexOf(i) <
                                    4 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                          </Grid>
                          <Grid item xs={4}>
                            {cardPos === "Goalkeeper" &&
                              sleagueTeamPlayersByPos.stpGk &&
                              sleagueTeamPlayersByPos.stpGk.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpGk.indexOf(i) >
                                    3 &&
                                  sleagueTeamPlayersByPos.stpGk.indexOf(i) <
                                    8 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Defender" &&
                              sleagueTeamPlayersByPos.stpDef &&
                              sleagueTeamPlayersByPos.stpDef.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpDef.indexOf(i) >
                                    3 &&
                                  sleagueTeamPlayersByPos.stpDef.indexOf(i) <
                                    8 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Midfielder" &&
                              sleagueTeamPlayersByPos.stpMid &&
                              sleagueTeamPlayersByPos.stpMid.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpMid.indexOf(i) >
                                    3 &&
                                  sleagueTeamPlayersByPos.stpMid.indexOf(i) <
                                    8 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Forward" &&
                              sleagueTeamPlayersByPos.stpFor &&
                              sleagueTeamPlayersByPos.stpFor.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpFor.indexOf(i) >
                                    3 &&
                                  sleagueTeamPlayersByPos.stpFor.indexOf(i) <
                                    8 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                          </Grid>
                          <Grid item xs={4}>
                            {cardPos === "Goalkeeper" &&
                              sleagueTeamPlayersByPos.stpGk &&
                              sleagueTeamPlayersByPos.stpGk.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpGk.indexOf(i) >
                                    7 &&
                                  sleagueTeamPlayersByPos.stpGk.indexOf(i) <
                                    12 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Defender" &&
                              sleagueTeamPlayersByPos.stpDef &&
                              sleagueTeamPlayersByPos.stpDef.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpDef.indexOf(i) >
                                    7 &&
                                  sleagueTeamPlayersByPos.stpDef.indexOf(i) <
                                    12 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Midfielder" &&
                              sleagueTeamPlayersByPos.stpMid &&
                              sleagueTeamPlayersByPos.stpMid.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpMid.indexOf(i) >
                                    7 &&
                                  sleagueTeamPlayersByPos.stpMid.indexOf(i) <
                                    12 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                            {cardPos === "Forward" &&
                              sleagueTeamPlayersByPos.stpFor &&
                              sleagueTeamPlayersByPos.stpFor.map(
                                (i) =>
                                  sleagueTeamPlayersByPos.stpFor.indexOf(i) >
                                    7 &&
                                  sleagueTeamPlayersByPos.stpFor.indexOf(i) <
                                    12 && (
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        cursor:
                                          doubleSubSelectedRow === "3" &&
                                          doubleSubSelectedPlayer1 &&
                                          doubleSubSelectedPlayer1.playerId ===
                                            i.playerId
                                            ? ""
                                            : "pointer",
                                        backgroundColor:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "#6A1B9A"
                                            : "",
                                        borderRadius:
                                          (subSelectedPlayer &&
                                            subSelectedPlayer.playerId ===
                                              i.playerId) ||
                                          (doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "2") ||
                                          (doubleSubSelectedPlayer2 &&
                                            doubleSubSelectedPlayer2.playerId ===
                                              i.playerId &&
                                            doubleSubSelectedRow === "3") ||
                                          (noPlayPlayer &&
                                            noPlayPlayer.playerId ===
                                              i.playerId)
                                            ? "8px"
                                            : "",
                                      }}
                                      onClick={(e) => {
                                        if (editingNoPlayItem)
                                          setNoPlayPlayer(i);
                                        else if (singleCheck)
                                          setSubSelectedPlayer(i);
                                        else {
                                          if (
                                            doubleSubSelectedRow === "3" &&
                                            doubleSubSelectedPlayer1 &&
                                            doubleSubSelectedPlayer1.playerId ===
                                              i.playerId
                                          )
                                            return;
                                          if (doubleSubSelectedRow === "2")
                                            setDoubleSubSelectedPlayer1(i);
                                          if (doubleSubSelectedRow === "3")
                                            setDoubleSubSelectedPlayer2(i);
                                        }
                                      }}
                                    >
                                      <Typography
                                        variant="h5"
                                        style={{
                                          lineHeight: 3,
                                          color:
                                            (subSelectedPlayer &&
                                              subSelectedPlayer.playerId ===
                                                i.playerId) ||
                                            (doubleSubSelectedPlayer1 &&
                                              doubleSubSelectedPlayer1.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "2") ||
                                            (doubleSubSelectedPlayer2 &&
                                              doubleSubSelectedPlayer2.playerId ===
                                                i.playerId &&
                                              doubleSubSelectedRow === "3") ||
                                            (noPlayPlayer &&
                                              noPlayPlayer.playerId ===
                                                i.playerId)
                                              ? "#FFFFFF"
                                              : doubleSubSelectedRow === "3" &&
                                                doubleSubSelectedPlayer1 &&
                                                doubleSubSelectedPlayer1.playerId ===
                                                  i.playerId
                                              ? "#00000061"
                                              : "#6A1B9A",
                                        }}
                                      >
                                        {i.jerseyNO + "  " + i.name}
                                      </Typography>
                                    </div>
                                  )
                              )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Box pb={3}>
                <Button
                  variant="contained"
                  disabled={
                    (editingNoPlayItem && !noPlayPlayer) ||
                    (singleCheck && !editingNoPlayItem && !subSelectedPlayer) ||
                    (!singleCheck &&
                      ((doubleSubSelectedRow === "2" &&
                        !doubleSubSelectedPlayer1) ||
                        (doubleSubSelectedRow === "3" &&
                          !doubleSubSelectedPlayer2)))
                  }
                  onClick={() => {
                    if (editingNoPlayItem) getRowListByDoneAddingNoPlay();
                    else if (startEditSub) getRowListbyDoneEditingSubIns();
                    else setOpenSelectSubPlayerDialog(false);
                  }}
                  color="primary"
                  className={classes.buttonStyle}
                  style={{ marginRight: 5 }}
                >
                  OK
                </Button>
                <Button
                  variant="text"
                  //  disabled={}
                  onClick={handleCloseSelectSubPlayerDialog}
                  color="primary"
                  className={classes.outlinedButtonStyle}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDeleteSubDialog.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.subDialog}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center", paddingTop: 40 }}
            >
              <Typography variant="subtitle2">
                Delete{` "`}
                {openDeleteSubDialog[0] &&
                  openDeleteSubDialog[0].jerseyNO +
                    "  " +
                    openDeleteSubDialog[0].name}
                {`" `}
                {openDeleteSubDialog[1] && openDeleteSubDialog[2]
                  ? " and " +
                    `" ` +
                    openDeleteSubDialog[1].jerseyNO +
                    "  " +
                    openDeleteSubDialog[1].name +
                    `" `
                  : ""}
                {openDeleteSubDialog[1] && openDeleteSubDialog[2]
                  ? "Substitutions"
                  : "Substitution"}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpenDeleteSubDialog([])}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 40,
                }}
              >
                <Typography
                  variant="h2"
                  style={{ fontSize: 20, fontWeight: 600, color: "#000000DE" }}
                >
                  {openDeleteSubDialog[1] && openDeleteSubDialog[2]
                    ? "Are you sure you want to delete these players?"
                    : "Are you sure you want to delete this player?"}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Box pb={3}>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await handleCancelAddingSub(
                      openDeleteSubDialog[2]
                        ? openDeleteSubDialog[2]
                        : openDeleteSubDialog[1]
                    );
                    setOpenDeleteSubDialog([]);
                  }}
                  color="primary"
                  className={classes.buttonStyle}
                  style={{ marginRight: 5 }}
                >
                  Delete
                </Button>
                <Button
                  variant="text"
                  onClick={() => setOpenDeleteSubDialog([])}
                  color="primary"
                  className={classes.outlinedButtonStyle}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDeleteNoPlayDialog}
            //  onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.subDialog}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center", paddingTop: 40 }}
            >
              <Typography variant="subtitle2">
                Delete No Play{` "`}
                {openDeleteNoPlayDialog &&
                  openDeleteNoPlayDialog.jerseyNO +
                    "  " +
                    openDeleteNoPlayDialog.name}
                {`" `}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpenDeleteNoPlayDialog(null)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  align: "right",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 40,
                }}
              >
                <Typography
                  variant="h2"
                  style={{ fontSize: 20, fontWeight: 600, color: "#000000DE" }}
                >
                  Are you sure you want to delete this No Player player?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Box pb={3}>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteNoPlayer(openDeleteNoPlayDialog)}
                  color="primary"
                  className={classes.buttonStyle}
                  style={{ marginRight: 5 }}
                >
                  Delete
                </Button>
                <Button
                  variant="text"
                  onClick={() => setOpenDeleteNoPlayDialog(null)}
                  color="primary"
                  className={classes.outlinedButtonStyle}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Dialog>

          {alertMsg && (
            <AlertDialog
              title="Alert"
              contentMsg={alertMsg}
              closeDialog={() => setAlertMsg(false)}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default FixPlayerStats;
