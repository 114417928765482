import React, { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import UserTeamSelectorApp from "./userTs/UserTeamSelectorApp";
import UserTeamSelectorAppMobile from "./userTsMobile/UserTeamSelectorAppMobile";
import UserTeamSelectorAppTablet from "./userTsTablet/UserTeamSelectorAppTablet";

import { ACCESS_FIXURE_TEAM_ID_QUERY } from "../../graphQL/authorized_user";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 80,
    paddingTop: 40,
    minWidth: 1366,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 10,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    //  height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
}));

const UserTeamSelectorAppMain = () => {
  const classes = useStyles();
  const { fixtureTeamId } = useParams();
  const [accessUser, setAccessUser] = useState(true);
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 963 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const { loading, error, data } = useQuery(ACCESS_FIXURE_TEAM_ID_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });

  useEffect(() => {
    if (!loading && data) {
      setAccessUser(data.accessFixtureTeamId);
    }
  }, [data]);
  if (!accessUser) {
    return (
      <h3>
        <br />
        <center>
          You are not able to view this team selector page. Please start from your{" "}
          <Link to="/lms/fixtures">Fixtures</Link> page.
        </center>
      </h3>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predictal | Team Selector | My starting line-up</title>        
      </Helmet>

      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="xs">
          <UserTeamSelectorAppMobile />
        </Container>
      )}

      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="md">
          <UserTeamSelectorAppTablet />
        </Container>
      )}

      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="lg">
          <UserTeamSelectorApp />
        </Container>
      )}
    </>
  );
};

export default UserTeamSelectorAppMain;
