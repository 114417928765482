import React, { useState, useEffect } from "react";
import { useQuery, useManualQuery, useMutation } from "graphql-hooks";

import { DataGrid } from "@material-ui/data-grid";
import { Grid, Button } from "@material-ui/core";

import Layout from "./Layout.js";
import adminStyle from "./Admin.module.scss";

const ALL_LEAGUES_QUERY = `query{
  league{
    leagueId
    title
    subtitle
  }
}`;

const SLEAGUE_QUERY = `query sleague($leagueId:Int!){
  sleague(leagueId:$leagueId){
    sleagueId
    title
    seasonName
  }
}`;

const ALL_SLEAGUETEAMS_QUERY = `query sleagueTeam($sleagueId:Int!){
  sleagueTeam(sleagueId:$sleagueId){
    sleagueTeamId
    teamId{
      teamId
      name
      shortName
    }
  }
}`;

const SLEAGUETEAM_ALL_GAME_STATS_QUERY = `query sleagueTeamGameStats($sleagueTeamId:Int!){
  sleagueTeamGameStats(sleagueTeamId:$sleagueTeamId){
    gameStatId
    sleagueTeamPlayerId{
      sleagueTeamId{
        teamId{
          name
        }
      }
      playerId{
        fName
        lName
        name
      }
    }
    fixtureId{
      scheduledWeek{
        weekId {
          name
        }
      }
    }
    positionId{
      label
    }
    playerOnField
    minutePlayed
    positionSeqNO
  }
}`;

const RUN_SET_POSITION_SEQ_NO_SCRIPT = `mutation setGamestatPositionseqno($sleagueTeamId: Int){
  setGamestatPositionseqno(sleagueTeamId: $sleagueTeamId) {
    response
  }
}`;

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 250,
    renderCell: rows =>
      rows.row.sleagueTeamPlayerId.playerId.shortName
        ? rows.row.sleagueTeamPlayerId.playerId.shortName
        : rows.row.sleagueTeamPlayerId.playerId.fName +
          " " +
          rows.row.sleagueTeamPlayerId.playerId.lName,
  },
  {
    field: "week",
    headerName: "Fixture Week",
    width: 200,
    renderCell: rows => rows.row.fixtureId.scheduledWeek.weekId.name,
  },
  {
    field: "position",
    headerName: "Position",
    width: 150,
    renderCell: rows => rows.row.positionId.label,
  },
  {
    field: "playerOnField",
    headerName: "On-Field",
    width: 150,
  },
  {
    field: "positionSeqNO",
    headerName: "Seq No.",
    width: 200,
  },
];
export default function GamestatPositionseqno() {
  //----------- Get All Leagues
  const [allLeagues, setAllLeagues] = useState([]);
  const [selcLeague, setSelcLeague] = useState(-1);
  const allLeaguesQuery = useQuery(ALL_LEAGUES_QUERY);
  useEffect(() => {
    if (allLeaguesQuery.data && allLeaguesQuery.data.league) {
      setAllLeagues(allLeaguesQuery.data.league);
    }
  }, [allLeaguesQuery.data]);

  //----------- Get list of Seasons based on the selected League
  const [leagueSeasons, setLeagueSeasons] = useState([]);
  const [selcSeason, setSelcSeason] = useState(-1);
  const leagueSeasonsQuery = useQuery(SLEAGUE_QUERY, {
    variables: {
      leagueId: selcLeague,
    },
  });
  useEffect(() => {
    if (selcLeague === -1) return setLeagueSeasons([]);
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.sleague) {
      setLeagueSeasons(leagueSeasonsQuery.data.sleague);
    }
  }, [leagueSeasonsQuery.data, selcLeague]);

  //----------- Get All Sleague Team
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [selcSleagueTeam, setSelcSleagueTeam] = useState(-1);
  const {
    loading: allSleagueTeamsLoading,
    error: allSleagueTeamsError,
    data: allSleagueTeamsData,
  } = useQuery(ALL_SLEAGUETEAMS_QUERY, {
    variables: {
      sleagueId: selcSeason,
    },
  });
  useEffect(() => {
    if (selcSeason === -1) return setAllSleagueTeams([]);
    if (!allSleagueTeamsLoading && !allSleagueTeamsError && allSleagueTeamsData) {
      setAllSleagueTeams(allSleagueTeamsData.sleagueTeam);
    }
  }, [allSleagueTeamsData, selcSeason, selcLeague]);

  //----------- Get All Game Stats values of a Sleague Team
  const [rows, setRows] = useState([]);
  const [fetchGameStat] = useManualQuery(SLEAGUETEAM_ALL_GAME_STATS_QUERY);
  const {
    loading: gameStatLoading,
    error: gameStatError,
    data: gameStatData,
  } = useQuery(SLEAGUETEAM_ALL_GAME_STATS_QUERY, {
    variables: {
      sleagueTeamId: selcSleagueTeam,
    },
  });
  useEffect(() => {
    if (selcSleagueTeam === -1) return setRows([]);
    if (!gameStatLoading && gameStatData) {
      setRows(gameStatData.sleagueTeamGameStats);
    }
  }, [gameStatData, selcSleagueTeam]);

  // Run the script through Mutation
  const [runSetGameStatPositionSeqNoMutation] = useMutation(
    RUN_SET_POSITION_SEQ_NO_SCRIPT
  );
  const runUpdateScript = async () => {
    // Run the script through Mutation; Set Game Stat position seq No.
    const {
      loading: runGameStatLoading,
      error: runGameStatError,
      data: runGameStatData,
    } = await runSetGameStatPositionSeqNoMutation({
      variables: {
        sleagueTeamId: selcSleagueTeam,
      },
    });
    //----------- Get All Game Stats values of a Sleague Team after calculate
    if (!runGameStatLoading && runGameStatData.setGamestatPositionseqno.response) {
      const {
        loading: endScriptLoading,
        error: endScriptError,
        data: endScriptData,
      } = await fetchGameStat({
        variables: {
          sleagueTeamId: selcSleagueTeam,
        },
      });
      if (!endScriptLoading && endScriptData) {
        setRows(endScriptData.sleagueTeamGameStats);
      }
    }
  };

  const runAllUpdateScript = async () => {
    setRows([]);
    // Run the script through Mutation; Set Game Stat position seq No.
    const {
      loading: runAllGameStatLoading,
      error: runAllGameStatError,
      data: runAllGameStatData,
    } = await runSetGameStatPositionSeqNoMutation();
    //----------- Get All Game Stats values of a Sleague Team after calculate
    if (!runAllGameStatLoading && runAllGameStatData.setGamestatPositionseqno.response) {
      alert("Position Seq No for all players has been set");
    }
  };

  const leagueHandleChange = e => {
    if (e.target.value !== "") {
      setSelcLeague(parseInt(e.target.value));
    } else {
      setSelcLeague(-1);
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const seasonHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSeason(parseInt(e.target.value));
    } else {
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const teamHandleChange = e => {
    if (e.target.value !== "") {
      setRows([]);
      setSelcSleagueTeam(parseInt(e.target.value));
    } else {
      setSelcSleagueTeam(-1);
    }
  };

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2 className={adminStyle.h2}>Update Seq No in Players Stats</h2>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <select
              className={adminStyle.select}
              onChange={leagueHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a League ...</option>
              {allLeagues.map(({ leagueId, title }) => (
                <option key={leagueId} value={leagueId}>
                  {title}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={seasonHandleChange}
              // value={currSleagueTeamId}
            >
              <option value="">Select a Season ...</option>
              {leagueSeasons.map(({ sleagueId, title, seasonName }) => (
                <option key={sleagueId} value={sleagueId}>
                  {seasonName}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={teamHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a Team ...</option>
              {allSleagueTeams.map(({ sleagueTeamId, teamId }) => (
                <option key={sleagueTeamId} value={sleagueTeamId}>
                  {teamId.name}
                </option>
              ))}
            </select>
          </form>
        </Grid>

        <Grid item>
          <h4>Update Players Position Seq No:</h4>
          <Button
            className={adminStyle.button}
            variant="contained"
            color="primary"
            fullWidth={false}
            disabled={selcSleagueTeam === -1}
            style={{
              textTransform: "none",
              marginBottom: "10px",
            }}
            onClick={runUpdateScript}
          >
            In a Team
          </Button>

          <Button
            className={adminStyle.button}
            variant="contained"
            color="primary"
            fullWidth={false}
            style={{
              textTransform: "none",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            onClick={runAllUpdateScript}
          >
            In All Teams
          </Button>

          <DataGrid
            columns={columns}
            rows={rows}
            rowHeight={80}
            getRowId={row => row.gameStatId}
            autoHeight={true}
            pagination
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
