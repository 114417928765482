import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "../Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../../store/data/Constants";

//Material UI Imports
import { DataGrid } from '@mui/x-data-grid';
import { Button, Grid, Typography, Box, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {GET_CURRENT_SLEAGUES_QUERY } from "../../graphQL/week_qry";
import {GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../../graphQL/current_bucket_week_qry";
import {GET_WEEK_FIXTURE_TEAMS } from "../../graphQL/fixture_qry";
import {TS_RESULT_SEND_EMAIL, TS_RESULT_PREVIEW_EMAIL } from "../../graphQL/email_qry";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "../Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function TeamSelectionResultsNitofication() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [currentBucketSleague, setCurrentBucketSleague] = useState(0);
  const [sleague, setSleague] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState([]);
  const [fixtureTeams, setFixtureTeams] = useState([]);
  const [teamsAll, setTeamsAll] = useState([]);
  const [sendOpen, setSendOpen] = React.useState(false);
  const [fixtureTeamId, setFixtureTeamId] = React.useState(0);
  const [previewText, setPreviewText] = React.useState("");

  const navigate = useNavigate();


  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true

  });



  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_CURRENT_SLEAGUES_QUERY, { fetchPolicy: "network-only" });


  const [getCurrentBucketWeek] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, { fetchPolicy: "network-only" });
  const [getFixtureTeams] = useManualQuery(GET_WEEK_FIXTURE_TEAMS, { fetchPolicy: "network-only" });
  const [sendEmail] = useMutation(TS_RESULT_SEND_EMAIL);
  const [previewEmail] = useMutation(TS_RESULT_PREVIEW_EMAIL);
  //const [GetFixtureTeam] = useManualQuery(GET_FIXTURETEAM_BY_ID, { fetchPolicy: "network-only" });


  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);


  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
  	onRefreshToken();
  	}
  	else
  		navigate("/lms//signin");
    }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
      navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
      navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

 useEffect(() => {
    console.log("useEffect: ",fixtureTeamId);
    if(fixtureTeamId != 0)
        previewResultEmail();
    }, [fixtureTeamId]);

  const handleClickOpen = () => {
    //previewResultEmail()
  };

  const handleClose = () => {
    setSendOpen(false);
    setFixtureTeamId(0)
    getweekFixtureTeams(selectedWeek);
  };

  const previewResultEmail = async () => {
    console.log("previewResultEmail: ",fixtureTeamId);
    const {data: previewEmailData, loading:previewEmailLoading, error:previewEmailError} =
    await previewEmail({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId)
      }
    });
    if (!previewEmailLoading && previewEmailData){
      setPreviewText(previewEmailData.previewEmail);
      setSendOpen(true);
    }
    if(previewEmailError){
      console.log(previewEmailError);
    }
  };
  const sendResultEmail = async () => {
    console.log(fixtureTeamId);
    const {data: emailData, loading:emailLoading, error:emailError} =
    await sendEmail({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId)
      }
    });
    if (!emailLoading && emailData){
      handleClose();
    }
    if(emailError){
      console.log(emailError);
    }
  };


  const getweekFixtureTeams = async weekId =>{

    const {
      loading: teamLoading,
      error: teamError,
      data: teamData
    } = await getFixtureTeams({
      variables: {
        weekId: parseInt(weekId)
      }
    });
    if (!teamLoading && teamData && teamData.weekFixtureTeams){
      setFixtureTeams(teamData.weekFixtureTeams);
    }

    if (!teamLoading && teamData && teamData.weekFixtureTeams) {
      let teamArr = ([]);
      let teams = teamData.weekFixtureTeams;
      let ars = [];
      teams.map(team => {
        let a = "" + team.fixtureId.fixtureId.toString();
        if (!ars.find(({ k }) => k === a)) {
          let u =[];
          u.push(team);
          ars.push({'k':a, 'v':u})

        } else {
          let n = ars.findIndex(({ k }) => k === a);
          ars[n].v.push(team);
        }
      });

      ars.map((item, i)=>{
        let t1 = item.v[0];
        t1['opponent'] = item.v[1];
        let t2 = item.v[1];
        t2['opponent'] = item.v[0];
        teamArr.push(t1);
        teamArr.push(t2);
      });
      setTeamsAll(teamArr);
    }
    if (teamError) {
      let ars = [];
      setTeamsAll(ars);
    }
  };

  const handleSelectedWeekChange = e =>{
    const weekId = e.target.value;
    setSelectedWeek(weekId);
    getweekFixtureTeams(weekId);

  };


  const getWeek = async (sleagueId) =>{
    const {
      loading: weekBucketLoading,
      error: weekBucketError,
      data: weekBucketData,
    } = await  getCurrentBucketWeek({
      variables: {
        sleagueId: parseInt(sleagueId),
      },
    }
  );
  if (!weekBucketLoading && weekBucketData && weekBucketData.currentSleagueWeek ){
    setWeeks(weekBucketData.currentSleagueWeek);
  };
  if (weekBucketError){
    console.log(weekBucketError);
  }
};



useEffect(() => {
  if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
    setCurrentBucketSleague(sleagueData.currentBucketSleague[0].sleagueId.sleagueId);
    setSleague(sleagueData.currentBucketSleague[0].sleagueId);
    getWeek(sleagueData.currentBucketSleague[0].sleagueId.sleagueId);

  }
  if (sleagueError && sleagueError.graphQLErrors[0].message === "Signature has expired") {
    console.log(sleagueError);
    navigate(`/lms/${admin_url}/signin`);
  }
}, [sleagueData]);

useEffect(()=>{
  weeks.forEach(item =>{
    if (item.extraField===true){
      setSelectedWeek(item.weekId);
      getweekFixtureTeams(item.weekId);

    }
  });
}, [weeks]);

setTimeout(() => {
  setSuccessMessage("");
  setFailedMessage("");
}, 15000);

const dayFormat = day => {
  const dayDate = new Date(day);
  const dayFormat = formatter.formatToParts(dayDate);
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")){
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
    dayFormat.find(a=>a.type=='day').value + " " +
    dayFormat.find(a=>a.type=='month').value + ", " +
    dayFormat.find(a=>a.type=='year').value +" - " +
    dayFormat.find(a=>a.type=='hour').value +":" +
    dayFormat.find(a=>a.type=='minute').value +" "+
    dayFormat.find(a=>a.type=='dayPeriod').value
    return newFormat;
  }
  else if (format==="monthDDYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
    dayFormat.find(a=>a.type=='month').value + " " +
    dayFormat.find(a=>a.type=='day').value + ", " +
    dayFormat.find(a=>a.type=='year').value +" - " +
    dayFormat.find(a=>a.type=='hour').value +":" +
    dayFormat.find(a=>a.type=='minute').value +" "+
    dayFormat.find(a=>a.type=='dayPeriod').value
    return newFormat;
  }
  else{


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
    dayFormat.find(a=>a.type=='year').value + ", " +
    dayFormat.find(a=>a.type=='month').value + " " +
    dayFormat.find(a=>a.type=='day').value +" - " +
    dayFormat.find(a=>a.type=='hour').value +":" +
    dayFormat.find(a=>a.type=='minute').value +" "+
    dayFormat.find(a=>a.type=='dayPeriod').value
    return newFormat;
  }
};

const coachSelectionHandleChange = e => {
  let fixtureTeamId = parseInt(e.target.value);
  navigate(`/lms/${admin_url}/ts/coachteam/${fixtureTeamId}`);
};

const columns = [
  {
    field: "team",
    headerName: "Team",
    width: 300,
    editable: false,

  },
  {
    field: "fixture",
    headerName: "Fixture",

    width: 300,
    editable: false,
    renderCell: team => {
      if (team.value.home)
        return (
          <>
            {" "}
            {team.value.sleagueTeamId.teamId.name} {team.value.scoreFinal} (
            {team.value.scoreHalf1}) - {"  "}
            {team.value.opponent.sleagueTeamId.teamId.name} {team.value.opponent.scoreFinal} (
            {team.value.opponent.scoreHalf1}){" "}
          </>
        );
      else
        return (
          <>
           {" "}
            {team.value.opponent.sleagueTeamId.teamId.name} {team.value.opponent.scoreFinal} (
            {team.value.opponent.scoreHalf1}) - {"  "}
            {team.value.sleagueTeamId.teamId.name} {team.value.scoreFinal} (
            {team.value.scoreHalf1}){" "}
          </>
        );


    },
  },

  {
    field: "users_no",
    headerName: "No. of Fans",

    width: 200,
    editable: false,

  },

  {
    field: "votes_no",
    headerName: "No. of Votes",

    width: 200,
    editable: false,

  },

  {
    field: "community",
    headerName: "Community Team",

    width: 200,
    editable: false,

  },
  {
    field: "coach",
    headerName: "Coach's Team",

    width: 200,
    editable: false,

  },
  {
    field: "send_email",
    headerName: "Send Email",
    width: 400,

    renderCell: id => (
      <>

      {
        id.row.send  ?
        (id.row.emails_count==0 || id.row.emails_count==null)  ?
        <Button
          className={adminStyle.button}
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          fullWidth={false}
          disabled={false}
          onClick={() =>{
            setFixtureTeamId(id.row.fixtureTeamId)
            handleClickOpen()
          }
          }
          >Send</Button>:
          <span>{"Sent to "+id.row.emails_count + " fans  on " +
            dayFormat(id.row.emails_date)}</span> :
            (id.row.votes_no)? "Team is Not Ready":"No Votes/No Email"
          }
          </>
      ),
    },
  ];

  // const rows = [{id:'1',team:'Arsenal', users_no:'230',community:'Yes', coach:'Yes'},
  //   {id:'2',team:'Aston Villa', users_no:'230',community:'Yes', coach:'Yes'}]

  const rows = teamsAll.map((tm, i)=>({
    id: i,
    team: tm.sleagueTeamId.teamId.name,
    fixture: tm,
    users_no: tm.sleagueTeamId.totalFans,
    votes_no: tm.totalVotes,
    community:(tm.communitySeq?"Ready":"Need to Run"),
    coach: (tm.coachSeq?"Ready":"Need to Create"),
    send :(tm.totalVotes && tm.communitySeq && tm.coachSeq ),
    emails_count : (tm.resultEmailsCounts),
    emails_date: (tm.resultEmailsSentDate),
    fixtureTeamId: tm.fixtureTeamId

  })
);
//   const rows = fixtureTeams.map((fixtureTeam, id)=>({
//     id: id,
//     team: fixtureTeam.sleagueTeamId.teamId.name,
//     users_no: fixtureTeam.sleagueTeamId.totalFans,
//     community:(fixtureTeam.communitySeq?"Ready":"Should Run"),
//     coach: (fixtureTeam.coachSeq?"Ready":"Should Create"),
//     send :(fixtureTeam.sleagueTeamId.totalFans && fixtureTeam.communitySeq && fixtureTeam.coachSeq),
//     emails_count : (fixtureTeam.resultEmailsCounts),
//     emails_date: (fixtureTeam.resultEmailsSentDate),
//     fixtureTeamId: fixtureTeam.fixtureTeamId
//   })
// );

return (
  <>
  <Grid container direction="row">
    <Layout />
    <Grid container item direction="column" xs={9} spacing={1}>
      <Grid item>
        <h2>Team Results Email Notifications</h2>
      </Grid>
      <Grid item>
        <h3>{sleague && sleague.leagueId && sleague.leagueId.title ? sleague.leagueId.title + " - Season  " + sleague.seasonName : "Not Found"}</h3>
      </Grid>
      <Grid item>
        <form className={adminStyle.form}>
          <label className={adminStyle.label} htmlFor="week">
            Week{" "}
          </label>
          <select
            className={adminStyle.select}
            id="week"
            {...register("week")}
            onChange={handleSelectedWeekChange}
            value={selectedWeek}
            >
            <option value="0">Select a Week...</option>
            {weeks.map((week, i) => (
              <option  key={i} value={week.weekId}>
                {week.name}
              </option>
            ))}
          </select>
        </form>
      </Grid>

      <Grid item>
        <DataGrid rows={rows} columns={columns} autoHeight={true}
        initialState={{sorting: {sortModel: [{ field: 'team', sort: 'asc' }],},}}
/>
      </Grid>
  </Grid>
</Grid>
<Dialog
        open={sendOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you ready to send the email?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            An email will be sent to each team fan with below content:
          </DialogContentText>
          <div dangerouslySetInnerHTML={{__html: previewText}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={sendResultEmail} autoFocus>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      </>
  );
  }
