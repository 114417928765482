import React, { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import CookieBubble from "react-cookie-bubble";
import StaticLayout from "../components/StaticLayout.js";
import { Helmet } from "react-helmet";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import overlapGroup2 from "../assets/overlapGroup2.png";
import background from "../assets/background.png";
import logo from "../assets/preditcal_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { content_display_config, seo_tag_config } from "../store/data/Constants";

import { GET_USERPROFILE_QUERY } from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 15,
	paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1610,
	width: "100% !important",
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 1330,
	marginLeft: 100,
	marginRight: 100
  },
  footerCardContent: {
    backgroundColor: "#662483",
	borderRadius: "0 !important",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
	marginLeft: 0,
	marginRight: 0
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  readMoreButtonStyle: {
    width: "140px !important",
    height: "42px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  mobileReadMoreButtonStyle: {
    width: "117px !important",
    height: "63px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
}));

/*##########################################################################
## Const : AboutUs
## Description : About Us page
## Author : RR
## Date: : 2022/05/08
##########################################################################*/
const CookiePolicy = () => {
  const [userData, setUserData] = useState([]);
  const [sleagueId, setSleagueId] = useState(0);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [favFixtureTeam, setFavFixtureTeam] = useState("");
  const [lastFixtureTeam, setLastFixtureTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1099 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (!loading && data && data.profile) {
	  console.log(data.profile);
      setUserData(data.profile);
	  setSleagueId(data.profile.userFavTeamField.sleagueTeamId.sleagueId.sleagueId);
    }
    if (error) {
	  localStorage.removeItem("token");
	  localStorage.removeItem("refreshToken");
	  localStorage.removeItem("role");
	  localStorage.removeItem("email");
      localStorage.removeItem("username");
	  //client.removeHeader("Authorization");
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      setAllSleagueTeams(dataSleague.allSleagueTeam);
      dataSleague.allSleagueTeam.map((sleagueteam) => {
        if (
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
		  console.log(userFavTeam);
        }
      });
    }
    if (errorSleague) {
	  console.log(errorSleague);
    }
	console.log(userFavTeam);
  }, [dataSleague, data]);

  return (
  <Grid>
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Cookie Policy</title>
          <meta 
            name="description" 
            content="Predictal Cookie Policy" 
          />
          <meta 
            property="keywords" 
            content="Predictal, cookie policy, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet> 
      }

      {content_display_config ?
      <Grid container direction="row" alignItems="flex-start">
	    <Container className={classes.cardContainer}>
		  {mobileView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
		    <CardContent className={classes.cardContentMobile}>
              <section>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '2',color: 'black' }}>Cookie Policy</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Please read this cookie policy (“cookie policy”, "policy") carefully before using the Predictal.com website (“website”, "service") operated by Predictal ("us", 'we", "our").
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What are cookies?</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Cookies are simple text files that are stored on your computer or mobile device by a website’s server.
                Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What types of cookies do we use?</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Necessary Cookies</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Necessary Cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features.
                For example, these cookies let us recognize that you have created an account and have logged into that account.

              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Functionality Cookies</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Functionality Cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Analytical Cookies </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal
                information such as names and email addresses and are used to help us improve your user experience of the website.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> How to delete cookies? </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. Alternatively, you can visit https://internetcookies.com, which contains comprehensive information on how to do this on a wide variety of browsers and devices.
                You will find general information about cookies and details on how to delete cookies from your device.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Contacting Us </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                 If you have any questions about this policy or our use of cookies, please contact us at support@predictal.com.
              </Typography>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={5} md={5} xl={5} xs={12} style={{textAlign: 'center'}}>
                  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} xl={4} xs={12} style={{textAlign: 'center',marginTop:"-20px"}}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team. The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={4} xl={3} xs={12} style={{textAlign: 'center'}}>
                  <Button
				    className={classes.readMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
		  {tabletView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
		    <CardContent className={classes.cardContentTablet}>
              <section>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '2',color: 'black' }}>Cookie Policy</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Please read this cookie policy (“cookie policy”, "policy") carefully before using the Predictal.com website (“website”, "service") operated by Predictal ("us", 'we", "our").
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What are cookies?</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Cookies are simple text files that are stored on your computer or mobile device by a website’s server.
                Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What types of cookies do we use?</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Necessary Cookies</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Necessary Cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features.
                For example, these cookies let us recognize that you have created an account and have logged into that account.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Functionality Cookies</Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                Functionality Cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Analytical Cookies </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal
                information such as names and email addresses and are used to help us improve your user experience of the website.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> How to delete cookies? </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. Alternatively, you can visit https://internetcookies.com, which contains comprehensive information on how to do this on a wide variety of browsers and devices.
                You will find general information about cookies and details on how to delete cookies from your device.
              </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Contacting Us </Typography>
              <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                 If you have any questions about this policy or our use of cookies, please contact us at support@predictal.com.
              </Typography>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={1} md={1} xl={1} xs={1}></Grid>
			    <Grid item lg={5} md={5} xl={5} xs={5} style={{ border: 'solid #E6312B',borderBottomWidth:0,borderRightWidth:0,borderTopWidth:0,borderLeftWidth:15,height:70,marginTop:1}}>
                  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} xl={4} xs={4} style={{marginTop: '10px',marginLeft:'-20px'}} >
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '0.9rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team.<br />The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={2} xl={2} xs={2} style={{ paddingLeft: '0 !important',marginLeft:'-20px'}}>
                  <Button
				    className={classes.mobileReadMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
		  {!tabletView && !mobileView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
            <CardContent className={classes.cardContent}>
              <section>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '2',color: 'black' }}>Cookie Policy</Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  Please read this cookie policy (“cookie policy”, "policy") carefully before using the Predictal.com website (“website”, "service") operated by Predictal ("us", 'we", "our").
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What are cookies?</Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  Cookies are simple text files that are stored on your computer or mobile device by a website’s server.
                  Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>What types of cookies do we use?</Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Necessary Cookies</Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  Necessary Cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features.
                  For example, these cookies let us recognize that you have created an account and have logged into that account.
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}>Functionality Cookies</Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  Functionality Cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Analytical Cookies </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal
                  information such as names and email addresses and are used to help us improve your user experience of the website.
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> How to delete cookies? </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. Alternatively, you can visit https://internetcookies.com, which contains comprehensive information on how to do this on a wide variety of browsers and devices.
                  You will find general information about cookies and details on how to delete cookies from your device.
                </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 600,lineHeight: '1.3',color: 'black', marginBottom: '1rem' }}> Contacting Us </Typography>
                <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                   If you have any questions about this policy or our use of cookies, please contact us at support@predictal.com.
                </Typography>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
			    <Grid item lg={5} md={5} sm={5} xs={5} style={{ border: 'solid #E6312B',borderBottomWidth:0,borderRightWidth:0,borderTopWidth:0,borderLeftWidth:15,height:70,marginTop:1}}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.1rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} sm={4} xs={4} style={{marginTop: '-10px'}} >
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team.<br />The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={2} sm={2} xs={2} style={{ paddingLeft: '0 !important'}}>
                  <Button
				    className={classes.readMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
        </Container>
      </Grid>
      :
      <b>Temporary Content</b>
      }
    </>
  </Grid>
);
}

export default CookiePolicy;
