import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  
  makeStyles,
} from "@material-ui/core";

import styled from "styled-components";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { GET_SLEAGUE_TEAMS_QUERY } from "../../graphQL/sleague_team_query";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";

import { project_url } from "../../store/data/Constants";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1280,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    // height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: "#F5F5F5",
  },
  cardContent: {
    padding: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    minWidth: 950,
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    // height: 550,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 16,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 16,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  dataGridMobileStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    width: "100%",
    "& .super-app-theme--header--mobile": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 12,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 12,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  logoStyle: {
    height: 32,
    width: 32,
    marginTop: 5,
    marginRight: 12,
  },
  logoMobileStyle: {
    height: 20,
    width: 20,
    marginTop: 5,
    marginRight: 12,
  },
}));



const StandingTable = () => {
  const classes = useStyles();
  const mediaPath = `/${project_url}/media/`;
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);
  const [currentSLeagueId, setCurrentSLeagueId] = useState("");
  const [slgTeamData, setSlgTeamData] = useState([]);

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1070 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const { loading: loadingCurBucketSlg,
          error: errorCurBucketSlge,
          data: dataCurBucketSlg } =
          useQuery(CURRENT_LEAGUE_SEASONS_QUERY);

  const [sleagueTeamData] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY);
  const client = useContext(ClientContext);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  // Sorting teams based on tie-breaker rules
  // ( first : noPoints, second: noGoalDifference, third: noGoalsFor)
  const sortSleagueTeams = (leagueTeams) => {

    leagueTeams.sort((a,b) =>
        b.noPoints - a.noPoints ||
        b.noGoalDifference - a.noGoalDifference ||
        b.noGoalsFor - a.noGoalsFor);

    return leagueTeams;
  };

  useEffect(() => {
    if (!loadingCurBucketSlg &&
        dataCurBucketSlg &&
        dataCurBucketSlg.currentBucketSleague) {

      setCurrentSLeagueId(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.sleagueId)
    }
  }, [dataCurBucketSlg])

  useEffect(async() => {
    if (currentSLeagueId){

      const {
        loading: loadingSleagueTeam,
        error: errorSleagueTeam,
        data: dataSleagueTeam,
      } = await sleagueTeamData({
        variables: {
          sleagueId: parseInt(currentSLeagueId),
        },
      });

      if (
        !loadingSleagueTeam &&
        dataSleagueTeam &&
        dataSleagueTeam.sleagueTeam
      ) {
        // Sorting teams based on tie-breaker rules
        let sortedTeams = sortSleagueTeams(dataSleagueTeam.sleagueTeam);
        setSlgTeamData(sortedTeams);
      };
    };
  }, [currentSLeagueId]);

  const rows = slgTeamData.map((sleagueTeam, index) => {

    return {
      id: index,
      position: index + 1,
      club: mobileView ? sleagueTeam.teamId.abbrName : sleagueTeam.teamId.name,
      logo: mediaPath + sleagueTeam.teamId.logo,
      played: sleagueTeam.noGames ? sleagueTeam.noGames : 0,
      won: sleagueTeam.noWins ? sleagueTeam.noWins : 0,
      drawn: sleagueTeam.noDraws ? sleagueTeam.noDraws : 0,
      lost: sleagueTeam.noLosses ? sleagueTeam.noLosses : 0,
      gf: sleagueTeam.noGoalsFor ? sleagueTeam.noGoalsFor : 0,
      ga: sleagueTeam.noGoalsAgainst ? sleagueTeam.noGoalsAgainst : 0,
      gd : sleagueTeam.noGoalDifference > 0 ?
        "+" + sleagueTeam.noGoalDifference :
        sleagueTeam.noGoalDifference ?
        sleagueTeam.noGoalDifference : 0,
      points: sleagueTeam.noPoints ? sleagueTeam.noPoints : 0,
      notes: sleagueTeam.notes ,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "position",
      headerName: mobileView || tabletView ? "Pos" : "Position",
      width: mobileView ? 35 : 60,
      minWidth: mobileView ? 45 : 60,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      flex: mobileView ? 0 : 1,
      sortable : false,
      description: mobileView || tabletView ? "Position" : null,
    },
    {
      field: "club",
      headerName: "Club",
      width: mobileView ? 85 : tabletView ? 256 : 472,
      minWidth: mobileView ? 10 : tabletView ? 250 : 290,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      flex: mobileView ? 0 : 2,
      renderCell: (params) => {
        if (params.row.club && params.row.logo) {
          return (
            <Box  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}>
              <img
                src={params.row.logo}
                className={mobileView ?
                          classes.logoMobileStyle :
                          classes.logoStyle}
              />
              <Box mt={mobileView ? 1 : 1.5}>
                <Typography variant= {mobileView ? "h6" : "h3"}>
                  {params.row.club}
                </Typography>
              </Box>
            </Box>
          );
        };
      }
    },
    {
      field: "played",
      headerName: mobileView || tabletView ? "Pl" : "Played",
      width: mobileView ? 30 : 82,
      minWidth: mobileView ? 10 : 20,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: mobileView || tabletView ? "Playes" : null,
    },
    {
      field: "won",
      headerName: mobileView || tabletView ? "W" : "Won",
      width: mobileView ? 30 : 82,
      minWidth: mobileView ? 10 : 20,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: mobileView || tabletView ? "Wins" : null,
    },
    {
      field: "drawn",
      headerName: mobileView || tabletView ? "D" : "Drawn",
      width: mobileView ? 37 : 82,
      minWidth: mobileView ? 10 : 20,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: mobileView || tabletView ? "Draws" : null,
    },
    {
      field: "lost",
      headerName: mobileView || tabletView ? "L" : "Lost",
      width: mobileView ? 30 : 82,
      minWidth: mobileView ? 10 : 20,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: mobileView || tabletView ? "Losses" : null,
    },
    {
      field: "gf",
      headerName: "GF",
      width: mobileView ? 0 : 82,
      minWidth: mobileView ? 0 : 45,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      hide: mobileView ? true : false,
      sortable : false,
      description: !mobileView ? "Goals For" : null,
    },
    {
      field: "ga",
      headerName: "GA",
      width: mobileView ? 0 : 82,
      minWidth: mobileView ? 0 : 45,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      hide: mobileView ? true : false,
      sortable : false,
      description: !mobileView ? "Goals Against" : null,
    },
    {
      field: "gd",
      headerName: "GD",
      width: mobileView ? 45 : 82,
      minWidth: mobileView ? 10 : 48,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: "Goal Difference",
    },
    {
      field: "points",
      headerName: mobileView || tabletView ? "Pts" : "Points",
      width: mobileView ? 45 : 82,
      minWidth: mobileView ? 10 : 45,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
      description: mobileView || tabletView ? "Points" : null,
      renderCell: (params) => {
        let notes = params.row.notes ;
        let points = params.row.points;

        if (notes) {
          return (                
            
              <Box
                mt={2}
                mb={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                
                  <label title={notes}> {points}*</label>
                
              </Box>
            
          )
        }
        else{
          return <div>{points}</div>;
        }     
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Predictal | English Premier League Table</title>
      </Helmet>

      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="sm">
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
                <Typography gutterBottom variant="subtitle2">
                  EPL - Season 2023-2024
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                  <Box className={classes.dataGridMobileStyle}>
                    <DataGrid
                      autoHeight={true}
                      disableColumnMenu
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      sx={{
                        '& .MuiDataGrid-cell': {
                          padding: '5px',
                        },
                      }}
                      hideFooter
                    />
                  </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}

      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1">
              EPL - Season 2023-2024
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                  <Box className={classes.dataGridStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      disableColumnMenu
                    />
                  </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}

      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1">
              EPL - Season 2023-2024
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                  <Box className={classes.dataGridStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default StandingTable;
