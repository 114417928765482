import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import EditIcon from '@mui/icons-material/Edit';


import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY
} from "../graphQL/current_bucket_sleague_qry";

import {
  GET_WEEK_FIXTURE_TEAMS,
  GET_SLEAGUE_FIXTURE_TEAMS,
} from "../graphQL/fixture_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../graphQL/current_bucket_week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "./Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function FixtureList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  const [leaguesData, setLeaguesData] = useState([]);
  const [weekNote, setWeekNote] = useState("");

  const [currentLeagueId, setCurrentLeagueId] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("leagueId")) !== null ?
      parseInt(localStorage.getItem("leagueId")) : "");

  const [selectedSleague, setSelectedSleague] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("sleagueId")) !== null ?
      parseInt(localStorage.getItem("sleagueId")) : "");

  const [sleaguesData, setSleaguesData] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("sleagueData")) !== null ?
      JSON.parse(localStorage.getItem("sleagueData")) : []);

  const [weeksData, setWeeksData] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("weeksData")) !== null ?
      JSON.parse(localStorage.getItem("weeksData")) : []);

  const [selectedWeek, setSelectedWeek] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("weekId")) !== null ?
      parseInt(localStorage.getItem("weekId")) : "");

  const [selectedWeekName, setSelectedWeekName] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    localStorage.getItem("weekName") !== null ?
      localStorage.getItem("weekName") : "");

  const [teamsAll, setTeamsAll] =
    useState(JSON.parse(localStorage.getItem("ReturnedFromCoachPage")) &&
    JSON.parse(localStorage.getItem("fixtureData")) !== null ?
      JSON.parse(localStorage.getItem("fixtureData")) : []);

  const navigate = useNavigate();

  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true

  });



  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });

  const {
    loading: curLeagueLoading,
    error: curLeagueError,
    data: curLeagueData,
  } = useQuery(CURRENT_LEAGUE_SEASONS_QUERY, { fetchPolicy: "network-only" });

  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchCurrentSleagueWeek] =
    useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeams] = useManualQuery(GET_WEEK_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [fetchTeamsBySleague] = useManualQuery(GET_SLEAGUE_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(() => {
    localStorage.setItem("ReturnedFromCoachPage", false);
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (leagueError && leagueError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);

  useEffect(async() => {
    if (
      !curLeagueLoading && curLeagueData &&
      curLeagueData.currentBucketSleague &&
      currentLeagueId === "" ){
      setCurrentLeagueId(
      curLeagueData.currentBucketSleague[0].sleagueId.leagueId.leagueId);

      const {
        loading: sleagueLoading,
        error: sleagueError,
        data: sleagueData,
      } = await fetchSleagues({
        variables: {
          leagueId:
          parseInt(curLeagueData.currentBucketSleague[0].sleagueId.leagueId.leagueId),
        },
      });

      if (!sleagueLoading && sleagueData && sleagueData.sleague) {
        setSleaguesData(sleagueData.sleague);
        setSelectedSleague(
        curLeagueData.currentBucketSleague[0].sleagueId.sleagueId);

        const {
          loading: currentSleagueWeekLoading,
          error: currentSleagueWeekErrors,
          data: currentSleagueWeekData,
        } = await fetchCurrentSleagueWeek({
          variables: { sleagueId:
            parseInt(curLeagueData.currentBucketSleague[0].sleagueId.sleagueId)},
        });
        if (!currentSleagueWeekLoading &&
        currentSleagueWeekData && currentSleagueWeekData.currentSleagueWeek) {
          setWeeksData(currentSleagueWeekData.currentSleagueWeek);
          localStorage.setItem("weeksData",
                  JSON.stringify(currentSleagueWeekData.currentSleagueWeek));

          let selectedWeek = currentSleagueWeekData.currentSleagueWeek.find(
            item => item.extraField === true
          )
          if (selectedWeek){
            setSelectedWeek(selectedWeek.weekId);
            localStorage.setItem("weekId", selectedWeek.weekId);
            setSelectedWeekName(selectedWeek.name);
            localStorage.setItem("weekName", selectedWeek.name);
            handleWeekChange(selectedWeek.weekId, selectedWeek.name);
          }
          else{
            alert("Please select a week in the 'Current League Season Weeks Bucket' menu first!");
          }
        }
        if (currentSleagueWeekErrors &&
          currentSleagueWeekErrors.graphQLErrors[0].message === "Signature has expired") {
          navigate(`/lms/${admin_url}/signin`);
        };
      };
    };
  }, [curLeagueData]);

  const handleLeagueChange = async e => {
    const tid = e.target.value;

    if (tid !== ''){
      setCurrentLeagueId(parseInt(tid));
      localStorage.setItem("leagueId", tid);
    }

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData,
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid),
      },
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
      localStorage.setItem("sleagueData",
        JSON.stringify(sleagueData.sleague));
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
      localStorage.setItem("sleagueData", []);
    }
    localStorage.setItem("ReturnedFromCoachPage", false);
  };

  const handleSLeagueChange = async e => {
    const tid = e.target.value;

    if (tid !== ''){
      setSelectedSleague(parseInt(tid));
      localStorage.setItem("sleagueId", tid);
    }

    if (parseInt(tid) != 0){

      const {
        loading: currentSleagueWeekLoading,
        error: currentSleagueWeekErrors,
        data: currentSleagueWeekData,
      } = await fetchCurrentSleagueWeek({
        variables: { sleagueId: parseInt(tid) },
      });
      if (!currentSleagueWeekLoading &&
      currentSleagueWeekData && currentSleagueWeekData.currentSleagueWeek) {
        setWeeksData(currentSleagueWeekData.currentSleagueWeek);
        localStorage.setItem("weeksData",
                  JSON.stringify(currentSleagueWeekData.currentSleagueWeek));
        let selectedWeek = currentSleagueWeekData.currentSleagueWeek.find(
          item => item.extraField === true
        )
        if (selectedWeek){
          setSelectedWeek(selectedWeek.weekId);
          localStorage.setItem("weekId", selectedWeek.weekId);
          setSelectedWeekName(selectedWeek.name);
          localStorage.setItem("weekName", selectedWeek.name);
          handleWeekChange(selectedWeek.weekId, selectedWeek.name);
        }
        else{
          alert("Please select a week in the 'Current League Season Weeks Bucket' menu first!");
        }
      }
      if (currentSleagueWeekErrors &&
        currentSleagueWeekErrors.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }


      // const {
      //   loading: weekLoading,
      //   error: weekError,
      //   data: weekData,
      // } = await fetchWeeks({
      //   variables: {
      //     sleagueId: parseInt(tid),
      //   },
      // });

      // if (!weekLoading && weekData && weekData.sleagueWeek) {
      //   setWeeksData(weekData.sleagueWeek);
      //   localStorage.setItem("weeksData",
      //     JSON.stringify(weekData.sleagueWeek));
      //   if(weekData.sleagueWeek.length>0){
      //     setSelectedWeek(weekData.sleagueWeek[0].weekId);
      //     localStorage.setItem("weekId",
      //       parseInt(weekData.sleagueWeek[0].weekId));
      //     setSelectedWeekName(weekData.sleagueWeek[0].name);
      //     localStorage.setItem("weekName",
      //       parseInt(weekData.sleagueWeek[0].name));
      //     handleWeekChange(weekData.sleagueWeek[0].weekId);
      //   }
      // }
      // if (weekError && weekError.graphQLErrors[0].message === "Signature has expired") {
      //   navigate(`/lms/${admin_url}/signin`);
      // }
    }
    else{
        setWeeksData([]);
        localStorage.setItem("weeksData", []);
    }
    localStorage.setItem("ReturnedFromCoachPage", false);
  };

  const handleWeekChange = async (tid, wName) => {

    if (tid && tid !== "0") {
      setSelectedWeek(parseInt(tid));
      localStorage.setItem("weekId", tid);

      let weekName = '';

      if (!wName){
        weekName = weeksData.find(
          item => parseInt(item.weekId) === parseInt(tid)
        ).name;
      } else {
        weekName = wName;
      };

      if (weekName){
        setSelectedWeekName(weekName);
        localStorage.setItem("weekName", weekName);
      }

      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeams({
        variables: {
          weekId: parseInt(tid),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.weekFixtureTeams) {
        console.log(allTeamsData);
        setTeamsAll([]);
        // localStorage.setItem("fixtureData", []);
        let teams = allTeamsData.weekFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });
        let weekN = "Fixtures Listed are for " + weekName;
        setWeekNote(weekN);
        setTeamsAll(ars);
        var res = ars.filter(item => {
         return item !== null;
        });
        localStorage.setItem("fixtureData", JSON.stringify(res));
      }
      if (teamError) {
        let ars = [];
        setTeamsAll(ars);
        localStorage.setItem("fixtureData", []);
      }
    } else {
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeamsBySleague({
        variables: {
          sleagueId: parseInt(selectedSleague),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.sleagueFixtureTeams) {
        console.log(allTeamsData);
        setTeamsAll([]);
        localStorage.setItem("fixtureData", []);
        let teams = allTeamsData.sleagueFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });
        let weekN = "Fixtures Listed are for week ";
        setWeekNote(weekN);
        setTeamsAll(ars);
        var res = ars.filter(item => {
         return item !== null;
        });
        localStorage.setItem("fixtureData", JSON.stringify(res));
      }
    }
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };

  const coachSelectionHandleChange = e => {
    let fixtureTeamId = parseInt(e.target.value);
    navigate(`/lms/${admin_url}/ts/coachteam/${fixtureTeamId}`);
  };

  const columns = [


    {
      field: "fixture",
      headerName: "Fixture",

      width: 300,
      editable: false,
      renderCell: teams => {
        if (teams.value[0].home)
          return (

            <a href={`/lms/${admin_url}/fixture/edit/${teams.value[0].fixtureId.fixtureId}`} target="_self">
              {" "}
              {teams.value[0].sleagueTeamId.teamId.shortName} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}) - {"  "}
              {teams.value[1].sleagueTeamId.teamId.shortName} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}){" "}
            </a>
          );
        else
          return (
            <a href={`/lms/${admin_url}/fixture/edit/${teams.value[0].fixtureId.fixtureId}`} target="_self">
             {" "}
              {teams.value[1].sleagueTeamId.teamId.shortName} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}) - {"  "}
              {teams.value[0].sleagueTeamId.teamId.shortName} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}){" "}
            </a>
          );
      },
    },

    {
      field: "fixtureDate",
      headerName: "Fixture Date",

      width: 250,
      editable: false,
      renderCell: team => {
        return <>{team.value.fixtureId.fixtureWeek.playedWeek==true?dayFormat(team.value.fixtureId.fixtureWeek.scheduledDate):"Delayed"}</>;
      }
    },

    {
      field: "scheduledWeek",
      headerName: "Scheduled Week",

      width: 130,
      editable: false,
      renderCell: team => {
        return <> {team.value.fixtureId.scheduledWeek.weekId.name}</>;
      },
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",

      width: 220,
      editable: false,
      renderCell: team => {
        return <>{team.value.fixtureId.scheduledWeek.scheduledDate===team.value.fixtureId.fixtureWeek.scheduledDate?'Same as fixture date':dayFormat(team.value.fixtureId.scheduledWeek.scheduledDate)}</>;
      },
    },

    {
      field: "action",
      headerName: "Reschedule Date",

      width: 160,

      renderCell: id => (
        <>
          {
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled={false}
              onClick={() =>
                navigate(`/lms/${admin_url}/fixture/reschedule/${id.id}`, {})
              }
            >
              {console.log(id.id)}
              Reschedule
            </Button>
          }
        </>
      ),
    },
    {
      field: "coachSelection",
      headerName: "Coach Selection",
      width: 200,
      editable: false,
      sortable: false,
      renderCell: rows => {
        return (
          <select className={adminStyle.select} onChange={coachSelectionHandleChange}>
            <option value="">Select a Team ...</option>
            {rows.row.fixture.map(i => {
              return (
                <option key={i.sleagueTeamId.sleagueTeamId} value={i.fixtureTeamId}>
                  {i.sleagueTeamId.teamId.name}
                </option>
              );
            })}
          </select>
        );
      },
    },
  ];

  var filteredTeamsAll = teamsAll.filter(item => {
    return item !== null;
  });

  const rows = filteredTeamsAll.map(tm => ({
    id: tm[0].fixtureId.fixtureId,
    fixture: tm,
    fixtureDate: tm[0],
    scheduledWeek: tm[0],
    scheduledDate: tm[0],
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Fixtures | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2>List of Fixtures</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League{" "}
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                value={currentLeagueId}
                onChange={handleLeagueChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                Season
              </label>
              <select
                className={adminStyle.select}
                id="season"
                {...register("sleague")}
                value={selectedSleague}
                onChange={handleSLeagueChange}
              >
                <option value="0">Select a Season...</option>
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="week">
                Week
              </label>
              <select
                className={adminStyle.select}
                id="week"
                value={selectedWeek}
                {...register("week")}
                onChange={e =>handleWeekChange(e.target.value)}
              >
                <option value="-1">Select a Week...</option>

                {weeksData.map((week, i) => (
                  <option key={i} value={week.weekId}>
                    {week.name}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter
            />
          </Grid>
          <Grid item>{weekNote}</Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/fixture/add`)}
            >
              + New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
