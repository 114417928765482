import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { ALL_FORMATIONS_QUERY } from "../graphQL/formation_qry";
import { GET_FORMATION_POSITION_QUERY } from "../graphQL/positionToFormationLeague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function FormationsList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [
    formationPositionsData,
    setFormationPositionsData
  ] = useState([]);
  const [formationsData, setFormationsData] = useState([]);
  const navigate = useNavigate();
  const [fetchFormationPositions] = useManualQuery(
    GET_FORMATION_POSITION_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );
  const {
    loading: formationsLoading,
    error: formationsError,
    data: formationData
  } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (
        !formationsLoading &&
        formationData &&
        formationData.allFormations
      ) {
        setFormationsData(formationData.allFormations);
      }
      if (
        formationsError &&
        formationsError.graphQLErrors[0].message ===
          "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [formationData]
  );

  const handleChange = async e => {
    const formationId = e.target.value;
    console.log(formationId);

    const {
      loading: frmPosLoading,
      error: frmPosError,
      data: frmPosData
    } = await fetchFormationPositions({
      variables: { formationId: parseInt(formationId) }
    });

    if (
      !frmPosLoading &&
      frmPosData &&
      frmPosData.formationPosition
    ) {
      console.log(frmPosData);
      frmPosData.formationPosition.sort((a,b) =>
              a.positionSeqNO > b.positionSeqNO ? 1 : -1);
      setFormationPositionsData(frmPosData.formationPosition);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 220,
      editable: false
    },
    {
      field: "positionSeqNO",
      headerName: "Position Seq. No.",
      width: 250,
      editable: false
    }
  ];

  const rows = formationPositionsData.map((frmPos, id) => ({
    id: frmPos.formationPositionId,   //frmPos.positionSeqNO,
    name: frmPos.positionId.label,
    positionSeqNO: frmPos.positionSeqNO
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Formation Positions | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Formation Positions</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="formation">
                Formation
              </label>
              <select
                className={adminStyle.select}
                id="formation"
                {...register("formation")}
                onChange={handleChange}
              >
                <option value="0">Select a Formation...</option>
                {formationsData.map((formation, i) => (
                  <option key={i} value={formation.formationId}>
                    {formation.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>

          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/formation-position/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
