import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";
import { GET_USER_SP_ACCOUNT_QUERY } from "../../graphQL/sp_accounting_qry"

import { project_url } from "../../store/data/Constants";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1280,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    // height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#F5F5F5",
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    minWidth: 950,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    // minHeight: 400,
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    // height: 550,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 16,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 16,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  dataGridMobileStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    width: "100%",
    "& .super-app-theme--header--mobile": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 12,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 12,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
}));

const ScoreBoard = () => {
  const classes = useStyles();
  const mediaPath = `/${project_url}/media/`;
  const navigate = useNavigate();
  const [pathname] = useState(window.location.pathname);

  const [currentSLeagueName, setCurrentSLeagueName] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const date =
    new Date().toLocaleString("en-us", { weekday: "long" }) +
    " " +
    new Date().toLocaleString();

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1070 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const { loading: loadingCurBucketSlg,
          error: errorCurBucketSlge,
          data: dataCurBucketSlg } =
          useQuery(CURRENT_LEAGUE_SEASONS_QUERY);

  const { loading: usersSPLoading,
          error: usersSPError,
          data: usersSPData } = useQuery(GET_USER_SP_ACCOUNT_QUERY);

  const client = useContext(ClientContext);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!loadingCurBucketSlg &&
        dataCurBucketSlg &&
        dataCurBucketSlg.currentBucketSleague) {

      setCurrentSLeagueName(dataCurBucketSlg.currentBucketSleague[0].sleagueId.title);
    }
  }, [dataCurBucketSlg])

  useEffect(async() => {
    if (!usersSPLoading &&
        usersSPData &&
        usersSPData.allUsersSPAccounting) {

      setUserInfo(usersSPData.allUsersSPAccounting);
    }
  }, [usersSPData])

  const rows = userInfo.map((userInf, index) => {

    return {
      id: index,
      position: index + 1,
      username: userInf.userId.username,
      sp: userInf.spTotal
    };
  });

  const columns: GridColDef[] = [
    {
      field: "position",
      headerName: mobileView || tabletView ? "Pos" : "Position",
      width: mobileView ? 50 : 150,
      maxWidth: 150,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: mobileView ? 0 : 1,
      sortable : false,
      description: mobileView || tabletView ? "Position" : null,
    },
    {
      field: "username",
      headerName: "Username",
      width: 120,
      minWidth: mobileView ? 45 : 120,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      flex: mobileView ? 0 : 1,
      sortable : false,
    },
    {
      field: "sp",
      headerName: "Sport Points(PSP)",
      width: 300,
      minWidth: mobileView ? 100 : 800,
      editable: false,
      headerClassName: mobileView ? "super-app-theme--header--mobile" :
                                    "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable : false,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Predictal | Sport Points Board</title>        
      </Helmet>
      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1">
              EPL - {currentSLeagueName}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentMobile}>
                  <Box>
                    <Typography gutterBottom style={{
                          fontSize: 18, fontWeight: 700 }}>
                      Community PSP Leaderboard
                    </Typography>
                  </Box>
                  <Box mr={1} mb={3}>
                    <Typography gutterBottom variant="h6">
                      As of {date}
                    </Typography>
                  </Box>
                  <Box className={classes.dataGridMobileStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      pageSize={pageSize}
                      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                      disableColumnMenu
                      headerHeight={42}
                      sx={{
                        '& .MuiDataGrid-cell': {
                          padding: '5px',
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle2">
              EPL - {currentSLeagueName}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentTablet}>
                  <Box>
                    <Typography gutterBottom variant="subtitle2">
                      Community PSP Leaderboard
                    </Typography>
                  </Box>
                  <Box mr={1} mb={3}>
                    <Typography gutterBottom variant="h5">
                      As of {date}
                    </Typography>
                  </Box>
                  <Box className={classes.dataGridStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      pageSize={pageSize}
                      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                      disableColumnMenu
                      sx={{
                        '& .MuiDataGrid-cell': {
                          padding: '15px',
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Box mb={1} style={{ display: "flex", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1">
              EPL - {currentSLeagueName}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box>
                    <Typography gutterBottom variant="subtitle2">
                      Community PSP Leaderboard
                    </Typography>
                  </Box>
                  <Box mr={1} mb={3}>
                    <Typography gutterBottom variant="h5">
                      As of {date}
                    </Typography>
                  </Box>
                  <Box className={classes.dataGridStyle}>
                    <DataGrid
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      getRowClassName={(params) =>
                        `style--${params.row.id % 2 == 0}`
                      }
                      hideFooterSelectedRowCount
                      pageSize={pageSize}
                      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                      disableColumnMenu
                      sx={{
                        '& .MuiDataGrid-cell': {
                          padding: '15px',
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ScoreBoard;
