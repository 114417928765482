const CreateSetting_mutate = `
    mutation createSetting(
      $name: String!,
	    $values: [String]!,
      $selected: String!,
      $type: String!)
	  {
		createSetting(
			name: $name,
			values: $values,
      selected: $selected,
      type: $type
		  )
		  {
			setting{
			  settingId
			  name
			  value
        type
			}
		  }
    }
`;

const EditSetting_mutate = `
    mutation editSetting(
	    $settingId : Int!,
	    $name : String!,
			$values : [String]!,
      $selected : String!,
      $type : String!,

          )
				{
					editSetting(
					  settingId : $settingId,
						name : $name,
						values: $values,
            selected : $selected,
            type : $type
						)
						{
							setting
							{
							  settingId
							  name
							  value
                type
                allValues {
                  value
                  label
                }

							}
						}
				}
`;

const GetAllSettings_query = `
    query{
      allSettings{
        settingId
        name
		    value
    }
  }
`;

const GetSettingObject = `
  query($settingId: Int!){
    settingObject (settingId: $settingId){
        settingId
		    name
		    value
        type
        allValues {
          value
          label
        }
      }
  }
`;

const GetServerDateTime = `
    query serverDateTime{
        serverDateTime
  }
`;

const GetServerDateTimeString = `
    query serverDateTimeString{
        serverDateTimeString
  }
`;

const GetDjangoDateTime = `
    query djangoDateTime{
        djangoDateTime
  }
`;

const GetSettingByName = `
  query($settingName: String!){
    settingByName (name: $settingName){
		    name
		    value
        type
        label
        allValues {
          value
          label
        }
      }
  }
`;

module.exports = {
  CREATE_SETTING_MUTATION : CreateSetting_mutate,
  EDIT_SETTING_MUTATION : EditSetting_mutate,
  GET_ALL_SETTINGS_QUERY : GetAllSettings_query,
  GET_SETTING_OBJECT: GetSettingObject,
  GET_SETTING_BY_NAME: GetSettingByName,
  GET_SERVER_DATE_TIME: GetServerDateTime,
  GET_SERVER_DATE_TIME_STRING: GetServerDateTimeString,
  GET_DJANGO_DATE_TIME: GetDjangoDateTime
}
