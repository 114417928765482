import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Divider,
  Button,
  IconButton,
  Drawer,
  DialogTitle,
  Link,
  Box,
  MenuItem,
} from "@material-ui/core";
import { useQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import logoTeam from "../assets/Chelsea.png";
import logoImg from "../assets/preditcal_logo.png";
import adminHomeIcon from "../assets/admin_home.png";
import spLedgerIcon from "../assets/sp_ledger.png";
import SaveIcon from "@mui/icons-material/Save";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";

import { GET_USERPROFILE_QUERY } from "../graphQL/user_qry";
import { GET_ALL_TEAM_QUERY } from "../graphQL/team_qry";

import CryptoJS from "crypto-js";
import { project_url, admin_url, secretPass } from "../store/data/Constants";

const findAnd = require("find-and");

const headerData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About Us",
    href: "/lms/about-us.html",
  },
  {
    label: "How Team Selector Works",
    href: "/lms/how-team-selector-works.html",
  },
  {
    label: "Contact",
    href: "/lms/contact.html",
  },
];
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 160,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 16,
      "& .MuiSvgIcon-root": {
        fontSize: 24,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFEFE",
    color: "black",
    height: "80px",
    justifyContent: "center",
    paddingRight: "10px",
    paddingLeft: "118px",
    "@media (max-width: 963px)": {
      //900
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  logo: {
    textAlign: "left",
    height: "58px",
  },
  menuButton: {
    fontFamily: "Roboto, Open Sans, sans-serif",
    fontWeight: 600,
    fontSize: "20px",
    marginRight: "20px",
    height: "50 !important",
  },
  menuItem1: {
    "& .MuiMenuItem-root": {
      fontSize: 16,
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        marginRight: 12,
      },
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "0px 30px",
  },
  buttonStyle: {
    borderRadius: 40,
    paddingLeft: 20,
    paddingRight: 10,
    textTransform: "none",
    backgroundColor: "#F7F4F7",
    "& .MuiButton-startIcon": {
      marginRight: 3,
    },
    "& .MuiButton-endIcon": {
      marginRight: 3,
    },
  },
  signUpButtonStyle: {
    borderRadius: 4,
    width: "99px !important",
    height: "42px",
    textTransform: "none",
    marginBottom: 25,
    "& .MuiButton-label": {
      fontFamily: "Roboto, Open Sans, sans-serif",
    },
    "&:active": {
      backgroundColor: "#6A1B9A",
    },
    "&:hover": {
      backgroundColor: "#6A1B9A",
    },
  },
  "MuiButton-label": {
    fontFamily: "Roboto, Open Sans, sans-serif",
  },
  mobileSignUpButtonStyle: {
    borderRadius: 4,
    fontFamily: "Roboto, Open Sans, sans-serif",
    width: "107px !important",
    height: "42px",
    textTransform: "none",
    marginTop: 15,
    marginLeft: 40,
    "&:active": {
      backgroundColor: "#6A1B9A",
    },
    "&:hover": {
      backgroundColor: "#6A1B9A",
    },
  },
  drawerTitle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0,
  },
}));

export default function Header() {
  const {
    header,
    logo,
    menuButton,
    toolbar,
    drawerContainer,
    buttonStyle,
    drawerTitle,
    menuItem1,
  } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [userFavTeamName, setUserFavTeamName] = useState("");
  const {
    loading: allTeamsLoading,
    error: allTeamsError,
    data: allTeamsData,
  } = useQuery(GET_ALL_TEAM_QUERY);

  let role = localStorage.getItem("role") ? localStorage.getItem("role") : null;
  if (role) role = CryptoJS.AES.decrypt(role, secretPass).toString(CryptoJS.enc.Utf8);
  /// Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // Sahar RTS-1913
    localStorage.removeItem("pastFixData")
  };
  console.log(localStorage);
  ///
  const location = useLocation();
  let username = localStorage.getItem("username")
    ? localStorage.getItem("username")
    : null;
  //if (
  //  role == "Admin" &&
  //  location.pathname.indexOf(admin_url) == -1
  //)
  //  username = "";

  if (location.pathname.indexOf("signin") != -1) username = "";

  // const userFavTeamName = localStorage.getItem("favTeam")
  //   ? localStorage.getItem("favTeam")
  //   : "";
  useEffect(() => {
    if (localStorage.getItem("favTeam")) {
      if (!allTeamsLoading && allTeamsData && allTeamsData.team) {
        let resFound = findAnd.returnFound(allTeamsData.team, {
          name: localStorage.getItem("favTeam"),
        });
        if (resFound) {
          setUserFavTeamName(resFound.shortName);
        }
      }
    }
  });

  const userFavTeamLogo = localStorage.getItem("favTeamLogo")
    ? localStorage.getItem("favTeamLogo")
    : "";
  const { mobileView, drawerOpen } = state;

  /*const {
    loading: profileLoading,
    error: profileError,
    data: profileData,
  } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });*/
  useEffect(() => {
    setAnchorEl(null);
  }, [window.innerWidth]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1370 //(RTS-1380)1310 //900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handleSignin = () => {
    setState(prevState => ({ ...prevState, drawerOpen: false }));
    navigate(`/${project_url}/signin`);
  };

  const handleSignup = () => {
    setState(prevState => ({ ...prevState, drawerOpen: false }));
    navigate(`/${project_url}/registration`);
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <a href="/">
          <img src={logoImg} alt="logo" className={logo} />
        </a>
        <div style={{ marginLeft: 50, marginTop: 25, width: "100%" }}>
          {getMenuButtons()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <Box alignItems="center" flexGrow={1}>
          <a href="/">
            <img src={logoImg} alt="logo" className={logo} />
          </a>
        </Box>
        {username && (
          <a href="/lms/fixtures">
            <img
              src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
              height={40}
              width={40}
              style={{ marginRight: 12 }}
            />
          </a>
        )}
        <IconButton
          {...{
            edge: "end",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon style={{ marginTop: 0 }} fontSize="28px" />
        </IconButton>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <DialogTitle className={drawerTitle}>
            <IconButton onClick={handleDrawerClose}>
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    let data = headerData;
    if (username && role == "Admin") {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: `/lms/${admin_url}/dashboard`,
              color: "inherit",
              style: { textDecoration: "none" },
              key: "Home",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem className={menuItem1} disableRipple>
              <img
                src={adminHomeIcon}
                height={20}
                width={20}
                title="Admin Dashboard"
                alt="  "
                style={{ marginRight: 12, marginLeft: 3 }}
              />
              Admin Home
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/dashboard",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "Home",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem className={menuItem1} disableRipple>
              <HomeIcon style={{ marginRight: 12 }} />
              Dashboard
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/sp-ledger",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "userName",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <img
                src={spLedgerIcon}
                height={20}
                width={20}
                title="See Fixtures"
                alt="  "
                style={{ marginRight: 12, marginLeft: 3 }}
              />
              My PSP Ledger
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/fixtures",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "favTeam",
              title: "See Fixtures",
              alt: "See Fixtures",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <img
                src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                height={20}
                width={20}
                title="See Fixtures"
                alt="  "
                style={{ marginRight: 12, marginLeft: 3 }}
              />
              {userFavTeamName}
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/profile",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "userName",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <PersonOutlineIcon style={{ marginRight: 12 }} />
              {username}
            </MenuItem>
          </Link>
          <Divider sx={{ my: 0.5 }} />
          {data.map(({ label, href }) => (
            <Link
              {...{
                component: RouterLink,
                to: href,
                color: "inherit",
                style: { textDecoration: "none", fontSize: "20px !important" },
                key: label,
                onClick: { drawerOpen: false },
              }}
            >
              <MenuItem {...{ style: { fontSize: "20px !important" } }}>{label}</MenuItem>
            </Link>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <Link
            {...{
              component: RouterLink,
              to: "/lms/signout",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "signout",
              onClick: { drawerOpen: false },
            }}
          >
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <LogoutIcon style={{ marginRight: 12, marginLeft: 3 }} color="error" />
              Sign Out
            </MenuItem>
          </Link>
        </>
      );
    }
    if (username) {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/dashboard",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "Home",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem className={menuItem1} disableRipple>
              <HomeIcon style={{ marginRight: 12 }} />
              Dashboard
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/sp-ledger",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "userName",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <img
                src={spLedgerIcon}
                height={20}
                width={20}
                title="See Fixtures"
                alt="  "
                style={{ marginRight: 12, marginLeft: 3 }}
              />
              My PSP Ledger
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/fixtures",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "favTeam",
              title: "See Fixtures",
              alt: "See Fixtures",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <img
                src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                height={20}
                width={20}
                title="See Fixtures"
                alt="  "
                style={{ marginRight: 12, marginLeft: 3 }}
              />
              {userFavTeamName}
            </MenuItem>
          </Link>
          <Link
            {...{
              component: RouterLink,
              to: "/lms/profile",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "userName",
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem disableRipple>
              <PersonOutlineIcon style={{ marginRight: 12 }} />
              {username}
            </MenuItem>
          </Link>
          <Divider sx={{ my: 0.5 }} />
          {data.map(({ label, href }) => (
            <Link
              {...{
                component: RouterLink,
                to: href,
                color: "inherit",
                style: { textDecoration: "none" },
                key: label,
                onClick: { drawerOpen: false },
              }}
            >
              <MenuItem>{label}</MenuItem>
            </Link>
          ))}
          <Divider sx={{ my: 0.5 }} />
          <Link
            {...{
              component: RouterLink,
              to: "/lms/signout",
              color: "inherit",
              style: { textDecoration: "none" },
              key: "signout",
              onClick: { drawerOpen: false },
            }}
          >
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <LogoutIcon style={{ marginRight: 12, marginLeft: 3 }} color="error" />
              Sign Out
            </MenuItem>
          </Link>
        </>
      );
    }
    return (
      <>
        {data.map(({ label, href }) => (
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
              onClick: { drawerOpen: false },
            }}
          >
            <MenuItem>{label}</MenuItem>
          </Link>
        ))}
        <Divider sx={{ my: 0.5 }} />
        <Button
          style={{
            top: 8,
            left: 11,
            color: "#9C27B0",
            textTransform: "none",
            fontFamily: "Roboto, Open Sans, sans-serif",
            fontSize: 15,
            fontWeight: 500,
            display: "inline",
          }}
          disableRipple={true}
          id="signinBtn"
          variant="text"
          onClick={handleSignin}
        >
          Sign In
        </Button>
        <Button
          className={classes.mobileSignUpButtonStyle}
          style={{ width: 107, height: 42 }}
          color="primary"
          variant="contained"
          onClick={handleSignup}
        >
          <Typography
            style={{
              color: "white",
              fontFamily: "Roboto, Open Sans, sans-serif",
              fontSize: 15,
              fontWeight: 500,
              lineHeight: 26,
              letterSpacing: "0.46",
            }}
          >
            Sign Up
          </Typography>
        </Button>
      </>
    );
  };

  const getMenuButtons = () => {
    let data = headerData;
    if (username && role == "Admin") {
      return (
        <>
          {data.map(({ label, href }) => (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                style: { textTransform: "none", marginBottom: 25 },
                className: menuButton,
              }}
            >
              {label}
            </Button>
          ))}
          <Box
            mt={1}
            style={{
              display: "inline-flex",
              flexDirection: "row",
              textAlign: "center",
              paddingRight: 0,
            }}
          >
            <Box mr={1}>
              <a href="/lms/fixtures" alt="See Fixtures" title="See Fixtures">
                <img
                  src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                  height={40}
                  width={40}
                />
              </a>
            </Box>
            <Box mr={2}>
              <Button
                {...{
                  key: "favTeam",
                  color: "inherit",
                  to: "/lms/fixtures",
                  component: RouterLink,
                  style: { textTransform: "none" },
                  alt: "See Fixtures",
                  title: "See Fixtures",
                }}
              >
                {userFavTeamName}
              </Button>
            </Box>
            <Box>
              <Button
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                {...{
                  key: "userName",
                  style: { textTransform: "none" },
                  className: buttonStyle,
                  startIcon: <PersonOutlineIcon />,
                  endIcon: open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />,
                }}
              >
                {username}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <Link
                  {...{
                    component: RouterLink,
                    to: `/lms/${admin_url}/dashboard/`,
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "home",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <img
                      src={adminHomeIcon}
                      height={20}
                      width={20}
                      title="Admin Dashboard"
                      alt="  "
                      style={{ marginRight: 12, marginLeft: 3 }}
                    />
                    Admin Home
                  </MenuItem>
                </Link>
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/dashboard/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "home",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <HomeIcon />
                    Home
                  </MenuItem>
                </Link>
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/sp-ledger/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "home",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <img
                      src={spLedgerIcon}
                      height={20}
                      width={20}
                      title="See Fixtures"
                      alt="  "
                      style={{ marginRight: 12, marginLeft: 3 }}
                    />
                    My PSP Ledger
                  </MenuItem>
                </Link>

                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/fixtures/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "fixtures",
                    alt: "See Fixtures",
                    title: "See Fixtures",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <img
                      src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                      height={20}
                      width={20}
                      style={{ marginRight: 12, marginLeft: 2 }}
                      title="See Fixtures"
                      alt=" "
                    />
                    {userFavTeamName}
                  </MenuItem>
                </Link>
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/profile/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "userName",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <PersonOutlineIcon />
                    {username}
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/signout/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "signout",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <LogoutIcon color="error" />
                    Sign Out
                  </MenuItem>
                </Link>
              </StyledMenu>
            </Box>
          </Box>
        </>
      );
    }
    if (username) {
      return (
        <>
         {data.map(({ label, href }) => (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                style: { textTransform: "none", marginBottom: 25 },
                className: menuButton,
              }}
            >
              {label}
            </Button>
          ))}
          <Box
            mt={1}
            style={{
              display: "inline-flex",
              flexDirection: "row",
              textAlign: "center",
              paddingRight: 0,
            }}
          >
            <Box mr={1}>
              <a href="/lms/fixtures" alt="See Fixtures" title="See Fixtures">
                <img
                  src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                  height={40}
                  width={40}
                />
              </a>
            </Box>
            <Box mr={2}>
              <Button
                {...{
                  key: "favTeam",
                  color: "inherit",
                  to: "/lms/fixtures",
                  component: RouterLink,
                  style: { textTransform: "none" },
                  alt: "See Fixtures",
                  title: "See Fixtures",
                }}
              >
                {userFavTeamName}
              </Button>
            </Box>
            <Box>
              <Button
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                {...{
                  key: "userName",
                  style: { textTransform: "none" },
                  className: buttonStyle,
                  startIcon: <PersonOutlineIcon />,
                  endIcon: open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />,
                }}
              >
                {username}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/dashboard/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "home",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <HomeIcon />
                    Home
                  </MenuItem>
                </Link>
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/sp-ledger/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "home",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <img
                      src={spLedgerIcon}
                      height={20}
                      width={20}
                      title="See Fixtures"
                      alt="  "
                      style={{ marginRight: 12, marginLeft: 3 }}
                    />
                    My PSP Ledger
                  </MenuItem>
                </Link>

                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/fixtures/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "fixtures",
                    alt: "See Fixtures",
                    title: "See Fixtures",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <img
                      src={userFavTeamLogo && "/lms/media/" + userFavTeamLogo}
                      height={20}
                      width={20}
                      style={{ marginRight: 12, marginLeft: 2 }}
                      title="See Fixtures"
                      alt=" "
                    />
                    {userFavTeamName}
                  </MenuItem>
                </Link>
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/profile/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "userName",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <PersonOutlineIcon />
                    {username}
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  {...{
                    component: RouterLink,
                    to: "/lms/signout/",
                    color: "inherit",
                    style: { textDecoration: "none" },
                    key: "signout",
                  }}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    <LogoutIcon color="error" />
                    Sign Out
                  </MenuItem>
                </Link>
              </StyledMenu>
            </Box>
          </Box>
        </>
      );
    }
    return (
      <>
        {data.map(({ label, href }) => (
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              style: { textTransform: "none", marginBottom: 25 },
              className: menuButton,
            }}
          >
            {label}
          </Button>
        ))}
        <Button
          style={{
            color: "#9C27B0",
            textTransform: "none",
            fontFamily: "Roboto, Open Sans, sans-serif",
            fontSize: 15,
            fontWeight: 500,
            display: "inline",
            marginLeft: "50px",
            paddingRight: "29px",
            marginBottom: 25,
          }}
          disableRipple={true}
          id="signinBtn"
          variant="text"
          onClick={handleSignin}
        >
          Sign In
        </Button>
        <Button
          className={classes.signUpButtonStyle}
          style={{ width: 99, height: 42 }}
          color="primary"
          variant="contained"
          onClick={handleSignup}
        >
          <Typography
            style={{
              color: "white",
              fontFamily: "Roboto, Open Sans, sans-serif",
              fontSize: 15,
              fontWeight: 500,
              lineHeight: 26,
              letterSpacing: "0.46000000834465027",
              textAlign: "left",
            }}
          >
            Sign Up
          </Typography>
        </Button>
      </>
    );
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
