import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url} from "../store/data/Constants";

import {
  ADD_ALGORITHM_MUTATION,
  ALL_ALGORITHMS_QUERY
} from "../graphQL/algorithm_qry";
// import "../styles.css";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Formation
## Description : Submit form data and create new record in formation table
## Author : SF
## Date: : 2022/02/23
##########################################################################*/
export default function Algorithm() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [allAlgorithms, setAllAlgorithms] = useState([]);
  const [addAlgorithm] = useMutation(ADD_ALGORITHM_MUTATION, {
    refetchQueries: [{ query: ALL_ALGORITHMS_QUERY }],
    awaitRefetchQueries: true
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  const { loading, error, data } = useQuery(ALL_ALGORITHMS_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(
    () => {
      if (!loading && data && data.allAlgorithms) {
        setAllAlgorithms(data.allAlgorithms);
        console.log("AllAlgorithmsData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 7000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    console.log("Algorithms----", allAlgorithms);
    await sleep(2000);

    let foundAlgorithm = allAlgorithms.find(
      item => item.algorithmName.toLowerCase() === formData.name.toLowerCase()
    );

    if (foundAlgorithm) {
      setFailedMessage(
        "This is a duplicate algorithm name. Please enter another one."
      );
    } else {
      const { data, error } = await addAlgorithm({
        variables: {
          algorithmName: formData.name
        }
      });
      if (error) {
        console.log("Error", error, data);
        setFailedMessage("Unfortunately an error occurred.");
        //  setTimeout("");
      } else if (data && data.addAlgorithm) {
        console.log("Success", data.addAlgorithm);
        allAlgorithms.push(data.addAlgorithm);
        setSuccessMessage("New algorithm added successfully.");
        setTimeout(() => {
          navigate(`/lms/${admin_url}/algorithm`);
        }, 2000);
        //  setTimeout("");
      }
    }
  };
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Algorithm | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>
            Add an "Algorithm" to "Algorithm" table
          </h2>
          <label className={adminStyle.label} htmlFor="name">
            <b>Algorithm Name</b>
          </label>
          <input
            className={adminStyle.input}
            id="name"
            {...register("name", { required: true })}
          />
          {errors.name && errors.name.type === "required" && (
            <span>Required</span>
          )}
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
