import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url} from "../store/data/Constants";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function LeagueList() {
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [leaguesData, setLeaguesData] = useState([]);
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  const { loading, error, data } = useQuery(GET_ALL_LEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(
    () => {
      if (!loading && data && data.league) {
        setLeaguesData(data.league);
        console.log("allSleaguesData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, leaguesData]
  );

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      editable: false
    },
    {
      field: "subtitle",
      headerName: "Subtitle",
      width: 200,
      editable: false
    },
    {
      field: "foundYear",
      headerName: "Year Found",

      width: 220,
      editable: false
    }
  ];
  const rows = leaguesData.map((league, id) => ({
    id: league.leagueId,
    title: league.title,
    subtitle: league.subtitle,
    foundYear: league.foundYear
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Leagues | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Leagues</h2>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/league/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
