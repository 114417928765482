import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMQuery, useManualQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,Divider,
  makeStyles,
} from "@material-ui/core";
import logoImg from "../../assets/preditcal_logo.png";
import AlertDialog from "../../components/AlertDialog.js";
import { TS_BY_FOOTY_EXISTS_QUERY } from "../../graphQL/footy_qry";


const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 10,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    //  height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // paddingTop: 40,
    minHeight: 400,
    maxWidth: 430,
    //  minWidth: 310,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",

  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  buttonStyle: {
    borderRadius: 4,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    minWidth:350,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 4,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    justifyContent:"spaceBetween",
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 4,
      fontSize: 16,
    },
  },
  text1: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px", /* 177.778% */
  }
}));
const Footy = () => {
  const classes = useStyles();
  const [footyNo, setFootyNo] = useState("");
  const [fieldError,setFieldError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });

  const [checkFootyNumber] = useManualQuery(TS_BY_FOOTY_EXISTS_QUERY);
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 963 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const textFieldUpdate = (e) =>{
    setFootyNo(e.target.value);
    setFieldError(false);
    setErrorMsg("");
  }
  const submitClick = async () =>{
    if (footyNo==""){
      setFieldError(true);
      setErrorMsg("");
      return;
    }
    const { loading, error, data } = await checkFootyNumber(
      {
        variables: {
          fixtureTeamId: parseInt(footyNo.substring(46)),
          userSeq: footyNo.substring(0, 46)
        }
      }
    );
    if (!loading && !error && data && data.tsByFootyExists) {
        navigate("/lms/footy-view/"+footyNo);
    }
    else {
      setFieldError(true);
      setErrorMsg("No selected team was found. Please enter a valid Footy No.");
    }
  };


  return (
    <>
    <Helmet>
      <title>Predictal | Footy No</title>      
    </Helmet> 
    {mobileView && (
      <Card className={classes.cardMobile}>
        <CardContent className={classes.cardContentMobile}>
          <Box alignItems="center" mb={3} flexGrow={1}>
            <Box
              flexGrow={1}
              alignItems="center"
              display="flex"
              justifyContent="space-between">
              <Typography gutterBottom
                style={{ marginBottom: 0 }}
                className={classes.text1}>
                Please Enter the footy number.
              </Typography>
            </Box>
            <Box flexGrow={1} mb={1} alignItems="center" display="flex" justifyContent="space-between" flex="1">
              <TextField label="Footy Number"  fullWidth className={classes.textFieldStyleTablet} variant="outlined"
              required error={fieldError} helperText={errorMsg}
              onChange={textFieldUpdate}/>
            </Box>
            <Box mt={5} display="flex" justifyContent="center" flex="2">
              <Button
                className={classes.buttonStyle}
                color="primary"
                type="submit"
                variant="contained"
                onClick = {submitClick}>
                  Go
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

    )}
    {tabletView && (
      <Container className={classes.cardContainerTablet}>
        <Card className={classes.cardTablet}>
          <CardContent className={classes.cardContentTablet}>
            <Box
              flexGrow={1}
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              flex="2"
              >
              <Typography gutterBottom
                style={{ marginBottom: 0 }}
                className={classes.text1}>
                Please Enter the footy number.
              </Typography>
            </Box>
            <Box flexGrow={1} mb={1} alignItems="center" display="flex" justifyContent="space-between" flex="1">
              <TextField label="Footy Number" fullWidth className={classes.textFieldStyleTablet} variant="outlined"
              required error={fieldError} helperText={errorMsg}
              onChange={textFieldUpdate}/>
            </Box>

            <Box mt={5} display="flex" justifyContent="center" flex="2">
              <Button
                className={classes.buttonStyle}
                color="primary"
                type="submit"
                variant="contained"
                onClick = {submitClick}>
                  Go
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    )}
    {!mobileView && !tabletView && (
    <Container className={classes.cardContainer} maxWidth="sm">
      <Card style={{ display: "flex", flexDirection: "row" }}>
        <CardContent className={classes.cardContent}>
          <Box alignItems="center" mb={3} flexGrow={1}>
            <Box mb={2}>
              <img src={logoImg} alt="logo" />
            </Box>
            <Typography variant="body1" class={classes.text1}>
              Predictal Footy No&#8482; is a universal number that identifies the
              starting lineup of a team that is selected by a Predictal community member.
              Community members can then share their Footy No with their peers on the Predictal
              platform, in effect they share the visual view of the starting lineup of their
               favourite team.
            </Typography>
          </Box>
        </CardContent>

        <Divider orientation="vertical" flexItem />
        <CardContent className={classes.cardContent} style={{ minWidth: 350 }}>

            <Box
              flexGrow={1}
              alignItems="flex-end"
              display="flex"
              justifyContent="space-between"
              flex="2"
            >
            <Typography gutterBottom
            style={{ marginBottom: 0 }}
            className={classes.text1}>
              Please Enter the footy number.
            </Typography>
            </Box>
            <Box flexGrow={1} mb={1} alignItems="center" display="flex" justifyContent="space-between" flex="1">
            <TextField label="Footy Number" className={classes.textFieldStyle} variant="outlined"
              required error={fieldError} helperText={errorMsg}
              onChange={textFieldUpdate}/>
            </Box>

            <Box mt={5} display="flex" justifyContent="center" flex="2">
              <Button
                className={classes.buttonStyle}
                color="primary"
                type="submit"
                variant="contained"
                onClick = {submitClick}>
                  Go
              </Button>

            </Box>
            </CardContent>
          </Card>
          </Container>
        )}

    </>
  );


};
export default Footy;
