import React, { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import Layout from "./Layout.js";

import { DataGrid } from "@material-ui/data-grid";
import { Grid, Select } from "@material-ui/core";

import adminStyle from "./Admin.module.scss";

const findAnd = require("find-and");

const ALL_LEAGUES_QUERY = `query{
  league{
    leagueId
    title
    subtitle
  }
}`;

const SLEAGUE_QUERY = `query sleague($leagueId:Int!){
  sleague(leagueId:$leagueId){
    sleagueId
    title
    seasonName
  }
}`;

const ALL_SLEAGUETEAMS_QUERY = `query sleagueTeam($sleagueId:Int!){
  sleagueTeam(sleagueId:$sleagueId){
    sleagueTeamId
    teamId{
      teamId
      name
      shortName
    }
  }
}`;

const SLEAGUETEAMPLAYES_QUERY = `query sleagueTeamPlayerAssignment($sleagueTeamId:Int!){
  sleagueTeamPlayerAssignment(sleagueTeamId:$sleagueTeamId){
    sleagueTeamPlayerId
    playerId{
      fName
      lName
	    shortName
	    name
      position
    }
    jerseyNO
    status
    playerSeqNO
    currentTeam
  }
}`;

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 250,
    renderCell: rows =>
      rows.row.playerId.shortName
        ? rows.row.playerId.shortName
        : rows.row.playerId.fName + " " + rows.row.playerId.lName,
  },
  {
    field: "assignment",
    headerName: "Assignment",
    width: 200,
    renderCell: rows => (rows.row.currentTeam ? "Assigned" : "Unassigned"),
  },
  {
    field: "position",
    headerName: "Position",
    width: 150,
    renderCell: rows => rows.row.playerId.position,
  },
  {
    field: "jerseyNO",
    headerName: "Jersey No",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "playerSeqNO",
    headerName: "Sequence No",
    width: 200,
  },
];
export default function PlayerAssignment() {
  //----------- Get All Leagues
  const [allLeagues, setAllLeagues] = useState([]);
  const [selcLeague, setSelcLeague] = useState(-1);
  const allLeaguesQuery = useQuery(ALL_LEAGUES_QUERY);
  useEffect(() => {
    if (allLeaguesQuery.data && allLeaguesQuery.data.league) {
      setAllLeagues(allLeaguesQuery.data.league);
    }
  }, [allLeaguesQuery.data]);

  //----------- Get list of Seasons based on the selected League
  const [leagueSeasons, setLeagueSeasons] = useState([]);
  const [selcSeason, setSelcSeason] = useState(-1);
  const leagueSeasonsQuery = useQuery(SLEAGUE_QUERY, {
    variables: {
      leagueId: selcLeague,
    },
  });
  useEffect(() => {
    if (selcLeague === -1) return setLeagueSeasons([]);
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.sleague) {
      setLeagueSeasons(leagueSeasonsQuery.data.sleague);
    }
  }, [leagueSeasonsQuery.data, selcLeague]);

  //----------- Get All Sleague Team
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [selcSleagueTeam, setSelcSleagueTeam] = useState(-1);
  const {
    loading: allSleagueTeamsLoading,
    error: allSleagueTeamsError,
    data: allSleagueTeamsData,
  } = useQuery(ALL_SLEAGUETEAMS_QUERY, {
    variables: {
      sleagueId: selcSeason,
    },
  });
  useEffect(() => {
    if (selcSeason === -1) return setAllSleagueTeams([]);
    if (!allSleagueTeamsLoading && !allSleagueTeamsError && allSleagueTeamsData) {
      setAllSleagueTeams(allSleagueTeamsData.sleagueTeam);
    }
  }, [allSleagueTeamsData, selcSeason, selcLeague]);

  //----------- Assigned Options
  const [allAssignOpt, setAllAssignOpt] = useState(["All", "Assigned", "Unassigned"]);
  const [selcAssignOpt, setSelcAssignOpt] = useState(-1);

  // //----------- Get list of Sleague Team Players
  const [rows, setRows] = useState([]);
  const sleagueTeamPlayerQuery = useQuery(SLEAGUETEAMPLAYES_QUERY, {
    variables: {
      sleagueTeamId: selcSleagueTeam,
    },
  });
  useEffect(() => {
    if (
      sleagueTeamPlayerQuery.data &&
      sleagueTeamPlayerQuery.data.sleagueTeamPlayerAssignment
    ) {
      let sTeamPlayerAssignment = sleagueTeamPlayerQuery.data.sleagueTeamPlayerAssignment;
      if (selcAssignOpt === "All") {
        setRows(sTeamPlayerAssignment);
      } else if (selcAssignOpt === "Assigned") {
        setRows(
          findAnd.returnFound(sTeamPlayerAssignment, {
            currentTeam: true,
          })
        );
      } else if (selcAssignOpt === "Unassigned") {
        setRows(
          findAnd.returnFound(sTeamPlayerAssignment, {
            currentTeam: false,
          })
        );
      } else {
        setRows([]);
      }
    }
  }, [sleagueTeamPlayerQuery.data, selcSleagueTeam, selcAssignOpt]);

  const leagueHandleChange = e => {
    if (e.target.value !== "") {
      setSelcLeague(parseInt(e.target.value));
    } else {
      setSelcLeague(-1);
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const seasonHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSeason(parseInt(e.target.value));
    } else {
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const teamHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSleagueTeam(parseInt(e.target.value));
    } else {
      setSelcSleagueTeam(-1);
    }
  };

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2 className={adminStyle.h2}>Player Assignment</h2>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <select
              className={adminStyle.select}
              onChange={leagueHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a League ...</option>
              {allLeagues.map(({ leagueId, title }) => (
                <option key={leagueId} value={leagueId}>
                  {title}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={seasonHandleChange}
              // value={currSleagueTeamId}
            >
              <option value="">Select a Season ...</option>
              {leagueSeasons.map(({ sleagueId, title, seasonName }) => (
                <option key={sleagueId} value={sleagueId}>
                  {title} ({seasonName})
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={teamHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a Team ...</option>
              {allSleagueTeams.map(({ sleagueTeamId, teamId }) => (
                <option key={sleagueTeamId} value={sleagueTeamId}>
                  {teamId.name}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={e => setSelcAssignOpt(e.target.value)}
              // value={currSleagueTeamId}
            >
              <option value="">Select an Assignment Option ...</option>
              {allAssignOpt.map(opt => (
                <option value={opt}>{opt}</option>
              ))}
            </select>
          </form>
        </Grid>

        <Grid item>
          <DataGrid
            columns={columns}
            rows={rows}
            rowHeight={80}
            getRowId={row => row.sleagueTeamPlayerId}
            autoHeight={true}
            pagination
            // checkboxSelection
            // disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
