import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

const Positions = ({ players, selectedRole, positionPlayedAggrList }) => {
  console.log("players---------positions", players);
  const [playersList, setPlayersList] = useState([]);

  const defPlayers = [];
  const mfPlayers = [];
  const forPlayers = [];
  const gkPlayers = [];

  useEffect(() => {
    players.map((player) => {
      if (player.playerId.position.toLowerCase() === "defender") {
        defPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "midfielder") {
        mfPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "forward") {
        forPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "goalkeeper") {
        gkPlayers.push(player);
      }
    });

    if (selectedRole == "goalkeeper") {
      setPlayersList(gkPlayers);
    } else if (selectedRole == "defender") {
      setPlayersList(defPlayers);
    } else if (selectedRole == "midfielder") {
      setPlayersList(mfPlayers);
    } else if (selectedRole == "forward") {
      setPlayersList(forPlayers);
    }
  }, [players, selectedRole, positionPlayedAggrList]);

  return (
    <div style={{ width: "100%" }}>
      {playersList.map((player, index) => {
        return (
          <Grid container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 15,
                paddingRight: 10,
                height: "39px",
                borderBottom: "1px solid white",
              }}
            >
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.gkCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.ldefCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.cdefCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.rdefCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.lmfCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.cmfCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.rmfCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.lforCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.cforCount}
                  </Typography>
                </Box>
              )}
              {player.counts && (
                <Box style={{ width: 20 }}>
                  <Typography variant="h6" style={{ display: "contents" }}>
                    {player.counts.rforCount}
                  </Typography>
                </Box>
              )}
            </div>
          </Grid>
        );
      })}
    </div>
  );
};

export default Positions;
