import { useState, useEffect, useContext, createContext } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import TeamSelector from "./TeamSelector";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { project_url, admin_url } from "../../store/data/Constants";

import {
  useQuery,
  useMutation,
  ClientContext,
  useManualQuery,
} from "graphql-hooks";
import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../../graphQL/user_qry";
import { GET_ALL_SETTINGS_QUERY } from "../../graphQL/setting_qry";
import { GET_COACH_SEQ_LATEST_FIXTURE_BY_SLEAGUE_TEAM_ID_QUERY } from "../../graphQL/fixture_qry";
import { useNavigate, useParams } from "react-router-dom";

const PLAYER_SEQ = ["player seq", "player_seq", "player-seq"];
const PLAYER_SEQ_REGEXP = new RegExp(PLAYER_SEQ.join("|"), "i");

const FIXTURETEAM_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    sleagueTeamId {
      sleagueTeamId
    }
    fixtureId{
      fixtureId
    }
    coachSeq
  }
}`;
const SLEAGUETEAM_QUERY = `query sleagueTeamById($sleagueTeamId:Int!){
  sleagueTeamById(sleagueTeamId:$sleagueTeamId){
    sleagueTeamId
    sleagueId{
      sleagueId
      title
      leagueId{
        leagueId
        title
      }
    }
    teamId{
      name
      shortName
      logo
    }
  }
}`;

export const TeamSelectorContext = createContext();

function CoachTeamSelector() {
  const [userData, setUserData] = useState([]);
  const [coachTeam, setCoachTeam] = useState("");
  const [userFavTeam, setUserFavTeam] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const [coachSeqVal, setCoachSeqVal] = useState("");

  const [lstCoachSeqVal, setLstCoachSeqVal] = useState("");
  const [showSeqNo, setShowSeqNo] = useState(false);

  const { fixtureTeamId } = useParams();

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: dataSettings,
  } = useQuery(GET_ALL_SETTINGS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [coachSeqLstFix] = useManualQuery(
    GET_COACH_SEQ_LATEST_FIXTURE_BY_SLEAGUE_TEAM_ID_QUERY
  );
  const [fixtureTeam] = useManualQuery(FIXTURETEAM_QUERY);
  const [sleagueTeam] = useManualQuery(SLEAGUETEAM_QUERY);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSettings && dataSettings && dataSettings.allSettings) {
      // Check showing player seq
      if (!loading && data && data.profile) {
        if (data.profile.isSuperuser) {
          dataSettings.allSettings.forEach((setting) => {
            if (PLAYER_SEQ_REGEXP.test(setting.name)) {
              if (
                ["True", "true", "TRUE", true, 1, "1"].includes(setting.value)
              )
                setShowSeqNo(true);
            }
          });
        }
      }
    }
    if (
      errorSettings &&
      errorSettings.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [dataSettings, data]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setSleagueTeamId(
        parseInt(data.fixtureTeamById.sleagueTeamId.sleagueTeamId)
      );
      setFixtureId(parseInt(data.fixtureTeamById.fixtureId.fixtureId));
      setCoachSeqVal(data.fixtureTeamById.coachSeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [fixtureTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await sleagueTeam({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });
    if (!loading && data && data.sleagueTeamById) {
      setCoachTeam({
        teamInfo: data.sleagueTeamById.teamId,
        sleagueteam: data.sleagueTeamById,
      });
      setLeagueId(parseInt(data.sleagueTeamById.sleagueId.leagueId.leagueId));
    }
  }, [sleagueTeamId]);

  useEffect(async () => {
    if (
      userData.userFavTeamField &&
      userData.userFavTeamField.sleagueTeamId.sleagueTeamId
    ) {
      // get userFavTeam data according to its sleagueTeamId
      let { loading, error, data } = await sleagueTeam({
        variables: {
          sleagueTeamId: parseInt(
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
          ),
        },
      });
      if (!loading && data && data.sleagueTeamById) {
        setUserFavTeam({
          teamInfo: data.sleagueTeamById.teamId,
          sleagueteam: data.sleagueTeamById,
        });
      }
    }
  }, [userData]);

  useEffect(async() => {
    let { loading, error, data } = await coachSeqLstFix({
      variables: {
        sleagueTeamId: parseInt(sleagueTeamId),
      },
    });
    if (!loading && data && data.coachSeqLatestFixtureBySleagueTeamId) {
      setLstCoachSeqVal(data.coachSeqLatestFixtureBySleagueTeamId[0].coachSeq);
    }
    if (
      error &&
      error.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    } else if (error) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueTeamId]);

  return (
    <>
      <Helmet>
        <title>Predictal | Coach Team Selection | Admin</title>
      </Helmet>
      <TeamSelectorContext.Provider value={showSeqNo}>
        <div>
          {coachTeam && userFavTeam && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <TeamSelector
                userFavTeam={coachTeam} // Coach's selected team
                fixtureTeamId={parseInt(fixtureTeamId)}
                sleagueTeamId={sleagueTeamId}
                fixtureId={fixtureId}
                leagueId={leagueId}
                coachSeqVal={coachSeqVal}
                lstCoachSeqVal={lstCoachSeqVal}
              />
            </DndProvider>
          )}
        </div>
      </TeamSelectorContext.Provider>
    </>
  );
}

export default CoachTeamSelector;
