import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_ALL_SLEAGUES_QUERY, GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import { ADD_AWARD_MUTATION } from "../graphQL/award_qry";
import {project_url, admin_url} from "../store/data/Constants";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from '@mui/material/FormControlLabel';

export default function SleagueAward() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };


  const [active, setActive] = useState(true);

  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);

  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only"
  });

  const [addAward] = useMutation(ADD_AWARD_MUTATION,
    {awaitRefetchQueries: true}
  );

  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(
    () => {
      if (!leagueLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );

  const handleLeagueChange = async e => {
    // setAwardsData(null);
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid)
      }
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSleagueChange = async e => {
    setValue("awardName", "");
    setValue("SP", "");
    setActive(true);
  };

  const selectActive = (e) =>{
    setActive(e.target.checked);
  }

  const onSubmit = async formData => {
    const { data, error } = await addAward({
      variables: {
        sleagueId: parseInt(formData.sleague),
        name: formData.awardName,
        sp: parseInt(formData.SP),
        active: active,
      },
    });
    if (error) {
      console.log("Error", error, data);
      alert("There is an error");
    } else {
      console.log("Success");
      alert("New award has been added to this league season successfully.");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/sleague-award`);
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Add Award | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add an Award for a League Season</h2>

            <label className={adminStyle.label} htmlFor="league">
              League
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id="league"
              {...register("league")}
              onChange={handleLeagueChange}
            >
              <option value="0">Select a League...</option>
              {leaguesData.map((league, i) => (
                <option key={i} value={league.leagueId}>
                  {league.title}
                </option>
              ))}
            </select>

            <label className={adminStyle.label} htmlFor="sleague">
              League Season
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id="sleague"
              {...register("sleague")}
              onChange={handleSleagueChange}
            >
              <option value="0">Select a Season...</option>
              {sleaguesData.map((sleague, i) => (
                <option key={i} value={sleague.sleagueId}>
                  {sleague.seasonName}
                </option>
              ))}
            </select>

            <label className={adminStyle.label} htmlFor="awardName">
              Award Name
            </label>
            <input
              className={adminStyle.input}
              id="awardName"
              {...register("awardName", { required: true })}
            />
            {errors.awardName && errors.awardName.type === "required" && (
              <span>Required</span>
            )}

            <label className={adminStyle.label} htmlFor="SP">
              Sport Points
            </label>
            <input
              className={adminStyle.input}
              type="number"
              min="1"
              {...register("SP", {
                required: true,
              })}
            />

            <FormControlLabel
              control={<Checkbox defaultChecked onChange={selectActive} />}
              className={adminStyle.label}
              label="Active"
            />

          <input className={adminStyle.input} type="submit" />
        </form>
      </Grid>
    </>
  );
}
