import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Link,
  Icon,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import { useManualQuery } from "graphql-hooks";
import styled from "styled-components";
import { DataGrid } from "@material-ui/data-grid";
// import { DataGrid } from "@mui/x-data-grid";

import { project_url } from "../../../../store/data/Constants";

import AlertDialog from "../../../../components/AlertDialog.js";

import undoImg from "../../../../assets/Undo.png";
import teamImg from "../../../../assets/Arsenal.png";
import delayedIcon from "../../../../assets/delayed.png";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingLeft: 22,
    paddingTop: 20,
    paddingRight: 22,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    minHeight: 100,
    borderRadius: 8,
  },
  textButtonStyle: {
    textTransform: "none",
    fontWeight: 700,
    width: "auto",
    fontSize: 12,
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    //padding: 0,
    //paddingRight: 8,
    //margin: 0,
    textDecorationLine: "underline",
    textAlign: "center",
    // "&:active": {
    //   backgroundColor: "#F5F5F5",
    // },
    // "&:select": {
    //   backgroundColor: "#F5F5F5",
    // },
    // "&:hover": {
    //   backgroundColor: "#F5F5F5",
    // },
  },
  dataGridStyle: {
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
}));
const StyledWeekTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px, 10px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
& .MuiTooltip-tooltip {
}
`;
const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "20px, 0px, 20px, -5px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    background: #f7e4ff;
    color: #000;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    display: inline-flex;
    padding: 8px 8px 8px 8px;
    flex-direction: column;
    align-items: center;
  }
`;
const formatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      delayed
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
      sleagueId{
        sleagueId
        spMax
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;
const timeFormatter = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
  hour12: true,
});
const getDayString = (dateFormat, dateandtime, withHour) => {
  const dayDate = new Date(dateandtime);
  const dayFormat = formatter.formatToParts(dayDate);
  let dayandTimeString = "";
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value;
    dayandTimeString = newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value;
    dayandTimeString = newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value;
    dayandTimeString = newFormat;
  }

  if (withHour) {
    const newFormat = " - " + timeFormatter.format(dayDate);

    dayandTimeString = dayandTimeString + newFormat;
  }

  return dayandTimeString;
};
const PastFixtures = ({
  pastFixtures,
  sleagueTeamId,
  fixtureId,
  embargoTime,
  serverTime,
  passCopyPastFixtureData,
}) => {
  const classes = useStyles();
  console.log("pastFixtures__", pastFixtures);
  const mediaPath = `/${project_url}/media/`;
  const [pageSize, setPageSize] = useState(10);
  const [undo, setUndo] = useState(
    JSON.parse(localStorage.getItem("pastFixData"))
      ? JSON.parse(localStorage.getItem("pastFixData"))
      : []
  );
  const [dataGridMsg, setDataGridMsg] = useState(null);
  const [alertMsg, setAlertMsg] = useState("");

  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);

  const isConclude = (fixture) => {
    // number 2 represents two hours after the start of the fixture. 95% of the fixtures finish within 2 hours after the fixture start time.
    if (
      serverTime.getTime() >
      new Date(fixture.fixtureId.fixtureWeek.scheduledDate).getTime() +
        2 * 3600000
    )
      return true;
    else return false; /*not conclude*/
  };

  const isEmbargo = (fixture) => {
    let fixDate = new Date(
      fixture.fixtureId.fixtureWeek.scheduledDate
    ).getTime();
    if (
      serverTime.getTime() > fixDate - embargoTime.value * 3600000 &&
      serverTime.getTime() < fixDate + 2 * 3600000
    )
      return true;
    else return false; /*not embargo*/
  };

  useEffect(async () => {
    if (!fixtureId || !serverTime || !embargoTime) return;
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    if (!loading && data && data.fixtureObjectTeams) {
      data.fixtureObjectTeams.map(async (fixtureTeam) => {
        if (
          parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId
        ) {
          let isEmb = await isEmbargo(fixtureTeam);
          let isConc = await isConclude(fixtureTeam);
          console.log("isEmb || isConc___", isEmb, isConc);
          if (isEmb)
            setDataGridMsg(
              "This fixture is in embargo at this time. You can not select the fixture now."
            );
          else if (isConc)
            setDataGridMsg(
              "This fixture has concluded. You can not select the fixture now."
            );
          else {
            setDataGridMsg("");
          }
        }
      });
    }
  }, [fixtureId]);

  const columns = [
    {
      field: "fixture",
      headerName: "Fixture",
      width: 320,
      editable: false,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (pastFixtures) => {
        let home = pastFixtures.value[0];
        let guest = pastFixtures.value[1];
        let favTeamPastFix = pastFixtures.value[0];
        if (
          parseInt(pastFixtures.value[1].sleagueTeamId.sleagueTeamId) ===
          sleagueTeamId
        )
          favTeamPastFix = pastFixtures.value[1];
        if (pastFixtures.value[1].home) {
          home = pastFixtures.value[1];
          guest = pastFixtures.value[0];
        }
        let fixDateTime = getDayString(
          "Y",
          pastFixtures.value[1].fixtureId.fixtureWeek.scheduledDate,
          true
        );
        var formatedTime =
          fixDateTime.split(" ")[5] + " " + fixDateTime.split(" ")[6];
        let fixDate = getDayString(
          "Y",
          pastFixtures.value[1].fixtureId.fixtureWeek.scheduledDate,
          false
        );
        let coachFormation = favTeamPastFix.coachFormation
          ? favTeamPastFix.coachFormation
          : "Stay Tuned";
        let myTeamFormation = favTeamPastFix.userFormation
          ? favTeamPastFix.userFormation
          : "Not Selected";
        let text = (
          <div style={{ whiteSpace: "pre-line" }}>
            <div
              style={{
                fontSize: 13,
                paddingTop: 5,
                paddingBottom: 5,
                fontWeight: 600,
                letterSpacing: 0.46,
                textAlign: "center",
              }}
            >
              Community Team Results
            </div>
            {"Community Votes: " +
              favTeamPastFix.votsFormations.totalVots +
              "\n" +
              "Community Formation: " +
              favTeamPastFix.votsFormations.popularFormation +
              "\n" +
              "Coach Formation: " +
              coachFormation +
              "\n" +
              "My Team Formation: " +
              myTeamFormation +
              "\n" +
              "Fixture Date: " +
              fixDate +
              "\n" +
              "Time: " +
              formatedTime +
              " (Your local Time)"}
          </div>
        );

        return (
          <StyledTooltip title={text}>
            <Box
              mt={2}
              mb={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box mr={1} mt={0.5} style={{ textAlign: "right", width: 100 }}>
                <Typography variant="h6">
                  {home.sleagueTeamId.teamId.shortName}
                </Typography>
              </Box>
              <img
                src={mediaPath + home.sleagueTeamId.teamId.logo}
                height={24}
                width={24}
                //  style={{ marginRight: 5 }}
              />
              <Box
                mt={0.5}
                style={{
                  width: 56,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">
                  {home.scoreFinal}
                  {" - "}
                  {guest.scoreFinal}
                </Typography>
              </Box>

              <img
                src={mediaPath + guest.sleagueTeamId.teamId.logo}
                height={24}
                width={24}
                style={{ marginRight: 5 }}
              />

              <Box mr={1} mt={0.5}>
                <Typography variant="h6">
                  {guest.sleagueTeamId.teamId.shortName}
                </Typography>
              </Box>
            </Box>
          </StyledTooltip>
        );
      },
    },
    {
      field: "week",
      headerName: "Week",
      width: 75, //110,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (pastFixtures) => {
        let week = pastFixtures.value[0].replace(/Week-/gi, "");
        let weekTooltip = pastFixtures.value[1];

        return weekTooltip ? (
          <StyledWeekTooltip title={weekTooltip}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Typography variant="h6">{week}</Typography>
            </div>
          </StyledWeekTooltip>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">{week}</Typography>
          </div>
        );
      },
    },
    {
      field: "teams",
      headerName: "Teams",
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      width: 248,
      renderCell: (params) => (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {params.row.fixture[0].fixtureId.delayed ? (
            <Box
              pl={2}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={delayedIcon}
                style={{ verticalAlign: "middle" }}
                width={24}
              />
              <Typography
                variant="h6"
                style={{
                  fontWeight: 700,
                  color: "#D32F2F",
                  paddingLeft: 10,
                  paddingTop: 3,
                }}
              >
                This fixture has been delayed.
              </Typography>
            </Box>
          ) : (
            <>
              {JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                undo[0] === params.id &&
                undo[1] === "myTeam" && (
                  <img
                    src={undoImg}
                    height={15}
                    width={20}
                    style={{ marginRight: "-16px", cursor: "pointer" }}
                    onClick={() => {
                      setUndo([]);
                      localStorage.removeItem("pastFixData");
                      passCopyPastFixtureData("clear");
                    }}
                  />
                )}
              <Button
                className={classes.textButtonStyle}
                style={{ marginLeft: 18 }}
                disableRipple={true}
                color="primary"
                variant="text"
                disabled={
                  JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                  undo[0] === params.id &&
                  undo[1] === "myTeam"
                }
                onClick={async (e) => {
                  e.preventDefault();
                  let seqData = await params.row.fixture.find((x) => {
                    return (
                      parseInt(x.sleagueTeamId.sleagueTeamId) === sleagueTeamId
                    );
                  });
                  console.log(
                    "eeeeeeeeeeee",
                    seqData,
                    params.row.fixture[0].sleagueTeamId.sleagueTeamId
                  );

                  if (!seqData.userSeq)
                    setAlertMsg("There is no User Team for this fixture.");
                  else {
                    setUndo([params.id, "myTeam"]);
                    localStorage.setItem(
                      "pastFixData",
                      JSON.stringify([params.id, "myTeam"])
                    );
                    passCopyPastFixtureData(seqData.userSeq);
                  }
                }}
              >
                My Team
              </Button>
              {JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                undo[0] === params.id &&
                undo[1] === "community" && (
                  <img
                    src={undoImg}
                    height={15}
                    width={20}
                    style={{
                      marginRight: "-23px",
                      marginLeft: 6,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setUndo([]);
                      localStorage.removeItem("pastFixData");
                      passCopyPastFixtureData("clear");
                    }}
                  />
                )}
              <Button
                className={classes.textButtonStyle}
                style={{ marginLeft: 25 }}
                disableRipple={true}
                color="primary"
                variant="text"
                disabled={
                  JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                  undo[0] === params.id &&
                  undo[1] === "community"
                }
                onClick={async (e) => {
                  e.preventDefault();
                  let seqData = await params.row.fixture.find((x) => {
                    return (
                      parseInt(x.sleagueTeamId.sleagueTeamId) === sleagueTeamId
                    );
                  });
                  console.log(
                    "eeeeeeeeeeee",
                    seqData,
                    params.row.fixture[0].sleagueTeamId.sleagueTeamId
                  );

                  if (!seqData.communitySeq)
                    setAlertMsg(
                      "The Community Team will be published shortly."
                    );
                  else {
                    setUndo([params.id, "community"]);
                    localStorage.setItem(
                      "pastFixData",
                      JSON.stringify([params.id, "community"])
                    );
                    passCopyPastFixtureData(seqData.communitySeq);
                  }
                }}
              >
                Community
              </Button>
              {JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                undo[0] === params.id &&
                undo[1] === "coach" && (
                  <img
                    src={undoImg}
                    height={15}
                    width={20}
                    style={{
                      marginRight: "-23px",
                      marginLeft: 6,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setUndo([]);
                      localStorage.removeItem("pastFixData");
                      passCopyPastFixtureData("clear");
                    }}
                  />
                )}
              <Button
                className={classes.textButtonStyle}
                style={{ marginLeft: 25 }}
                disableRipple={true}
                color="primary"
                variant="text"
                disabled={
                  JSON.parse(localStorage.getItem("pastFixData")) !== null &&
                  undo[0] === params.id &&
                  undo[1] === "coach"
                }
                onClick={async (e) => {
                  e.preventDefault();
                  let seqData = await params.row.fixture.find((x) => {
                    return (
                      parseInt(x.sleagueTeamId.sleagueTeamId) === sleagueTeamId
                    );
                  });
                  console.log(
                    "eeeeeeeeeeee",
                    seqData,
                    params.row.fixture[0].sleagueTeamId.sleagueTeamId
                  );

                  if (!seqData.coachSeq)
                    setAlertMsg("The Coach Team will be published shortly.");
                  else {
                    setUndo([params.id, "coach"]);
                    localStorage.setItem(
                      "pastFixData",
                      JSON.stringify([params.id, "coach"])
                    );
                    passCopyPastFixtureData(seqData.coachSeq);
                  }
                }}
              >
                Coach
              </Button>
            </>
          )}
        </Box>
      ),
      //  editable: true,
    },
  ];
  const rows = pastFixtures.map((fx, index) => ({
    id: index,
    fixture: fx,
    //RTS-1810
    //week: fx[0].fixtureId.fixtureWeek.weekId.name,
    week: [
      fx[0].fixtureId.fixtureWeek.scheduledDate ===
      fx[0].fixtureId.scheduledWeek.scheduledDate
        ? fx[0].fixtureId.fixtureWeek.weekId.name
        : fx[0].fixtureId.fixtureWeek.weekId.name + "*",
      fx[0].fixtureId.fixtureWeek.scheduledDate !==
      fx[0].fixtureId.scheduledWeek.scheduledDate
        ? "Rescheduled from " + fx[0].fixtureId.scheduledWeek.weekId.name
        : null,
    ],
  }));
  return (
    <Card className={classes.cardContainer}>
      <Box sx={{ height: 686, width: "100%" }}>
        {dataGridMsg && (
          <Box mt={4} style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h3">{dataGridMsg}</Typography>
          </Box>
        )}
        {dataGridMsg === "" && (
          <DataGrid
            className={classes.dataGridStyle}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
          />
        )}
      </Box>
      {alertMsg && (
        <AlertDialog
          title="Alert"
          contentMsg={alertMsg}
          closeDialog={() => setAlertMsg(false)}
        />
      )}
    </Card>
  );
};
export default PastFixtures;
