import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../graphQL/sleague_team_query";

import TeamInfo from "./leftPanel/TeamInfo";
import PlayerInfo from "./rightPanel/PlayerInfo";
import { project_url, admin_url } from "../../store/data/Constants";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 1366,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 80,
    paddingTop: 40,
    minHeight: 458,
  },
}));
const TeamSelector = ({
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  coachSeqVal,
  lstCoachSeqVal
}) => {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [userSeqData, setUserSeqData] = useState([]);
  const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState("01")

  const navigate = useNavigate();

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });

  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  useEffect(() => {
    let coachSeq = coachSeqVal.trim();
    let lstCoachSeq = lstCoachSeqVal.trim();
    if (coachSeq !== "") setSelectedFormation(coachSeq.slice(0, 2));
    else if (lstCoachSeq !== "") setSelectedFormation(lstCoachSeq.slice(0, 2));
    else setSelectedFormation("01");
  }, [coachSeqVal, lstCoachSeqVal]);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [dataSLTeam]);

  useEffect(() => {
    if (
      !playersDataLoading &&
      playersData &&
      playersData.sleagueTeamNowPastPlayer
    ) {
      setPlayers(playersData.sleagueTeamNowPastPlayer);
    }
  }, [playersData]);

  const getTeamSelectionsData = (droppedPlayerNames, teamSelections) => {
    let userSeq = "<[" + selectedFormation + "]>";
    let draftList = {};
    // GK

    let key = parseInt(teamSelections.GK[0].positionSeqNo);
    let val = teamSelections.GK[0].lastDroppedItem
      ? teamSelections.GK[0].lastDroppedItem.playerSeqNO
      : "00";
    draftList[key] = val;

    // DEF
    teamSelections.DEF.defLane_col_1 &&
      teamSelections.DEF.defLane_col_1.length > 0 &&
      teamSelections.DEF.defLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.DEF.defLane_col_2 &&
      teamSelections.DEF.defLane_col_2.length > 0 &&
      teamSelections.DEF.defLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // MID
    teamSelections.MID.midLane_col_1 &&
      teamSelections.MID.midLane_col_1.length > 0 &&
      teamSelections.MID.midLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_2 &&
      teamSelections.MID.midLane_col_2.length > 0 &&
      teamSelections.MID.midLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_3 &&
      teamSelections.MID.midLane_col_3.length > 0 &&
      teamSelections.MID.midLane_col_3.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // FOR
    teamSelections.FOR.forLane_col_1 &&
      teamSelections.FOR.forLane_col_1.length > 0 &&
      teamSelections.FOR.forLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.FOR.forLane_col_2 &&
      teamSelections.FOR.forLane_col_2.length > 0 &&
      teamSelections.FOR.forLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );

    Object.keys(draftList).map((i) => {
      if (i < 10) userSeq += "<[0" + i + "]" + "[" + draftList[i] + "]>";
      else userSeq += "<[" + i + "]" + "[" + draftList[i] + "]>";
    });

    setUserSeqData(userSeq);
    setDroppedPlayerNamesData(droppedPlayerNames);
  };
  const getSelectedFormation = (selectedFormation) => {
    setSelectedFormation(selectedFormation);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid
        container
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={6}>
          <Paper style={{ width: "100%", minWidth: 639, height: 793 }}>
            <TeamInfo
              userFavTeam={userFavTeam}
              fixtureTeamId={fixtureTeamId}
              sleagueTeamId={sleagueTeamId}
              fixtureId={fixtureId}
              leagueId={leagueId}
              userSeqData={userSeqData}
              coachSeqVal={coachSeqVal}
              lstCoachSeqVal={lstCoachSeqVal}
              sleaguePlayers={players}
              sleagueTeamsData={allSleagueTeamsData}
              passTeamSelectionsData={getTeamSelectionsData}
              passSelectedFormation={getSelectedFormation}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Paper style={{ width: "100%", minWidth: 689, height: 793 }}>
            <PlayerInfo
              userSeqData={userSeqData}
              userFavTeam={userFavTeam}
              droppedPlayerNamesData={droppedPlayerNamesData}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeamSelector;
