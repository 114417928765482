import { memo } from "react";
import { useDrop } from "react-dnd";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

import { Box, Typography } from "@material-ui/core";
const useStyles = makeStyles({
  mainStyle: {
    color: "white",
    textAlign: "center",
    font: "12px Arial, sans-serif",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    border: "1px",
  },
  pStyle: {
    color: "#4D4D4D",
    font: "12px Arial, sans-serif", //10
    fontWeight: 400,
    //display: "flex",
    // alignItems: "center",
    // textAlign: "center"
  },
});
export const HeadToHead = memo(function HeadToHead({
  accept,
  lastH2HDroppedItem,
  onRemoveH2HDroppedItem,
  onDrop,
  margin
}) {
  const classes = useStyles();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  //console.log("lastH2HDroppedItemHead2Head", lastH2HDroppedItem);
  return (
    <Box
      ref={drop}
      role="HeadToHead"
      className={classes.mainStyle}
      style={{
        height: "55px",
        width: "100%",
border: "1px solid #D9D9D9",
        marginRight: margin ? margin : 0,
        borderBottom: "none",
        borderLeft: 0,
      //  borderRight: index === 1 ? "none" : "1px solid #D9D9D9",
      }}
    >
      {lastH2HDroppedItem && lastH2HDroppedItem.name ? (
        <Typography className={classes.pStyle}>
          {lastH2HDroppedItem.name}
        </Typography>
      ) : (
        <Typography className={classes.pStyle}>Drop a player</Typography>
      )}
    </Box>
  );
});
