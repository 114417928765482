import React from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import booked from "../../../../assets/StatusImages/booked.gif";
import suspended from "../../../../assets/StatusImages/suspended.gif";
import injured from "../../../../assets/StatusImages/injured.png";
import unavailable from "../../../../assets/StatusImages/unavailable.png";
import available from "../../../../assets/StatusImages/available.png";
import selected from "../../../../assets/StatusImages/selected.png";
import sick from "../../../../assets/StatusImages/sick.png";
import staron from "../../../../assets/BioImages/staron.png";
import staroff from "../../../../assets/BioImages/staroff.png";

const useStyles = makeStyles({
  root: {
    fontSize: 20,
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FBF3FF",
    },
  },
});

const pos = {
  Goalkeeper: "GK",
  Defender: "DEF",
  Midfielder: "MF",
  Forward: "FOR",
};
const Stats = ({ player }) => {
  const mainPath = "/lms/media/";

  const classes = useStyles();

  // Player stats list
  const stats = [
    {
      name: "Games",
      stat: player.stats.games,
    },
    {
      name: "Ejected",
      stat: player.stats.ejected,
    },
    {
      name: "Goals",
      stat: player.stats.goals,
    },
    {
      name: "Yellow Card",
      stat: player.stats.yc,
    },
    {
      name: "Red Card",
      stat: player.stats.rc,
    },
  ];

  const columns = [
    {
      field: "stat",
      width: 100,
      align: "center",
      flex: 2,
      renderCell: params => {
        return (
          <Typography variant="h2" style={{ fontWeight: 700 }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "playerStat",
      width: 70,
      align: "center",
      flex: 1,
      renderCell: params => {
        return <Typography variant="h3">{params.value}</Typography>;
      },
    },
  ];

  const rows = stats.map((stat, id) => ({
    id: id,
    playerStat: stat.stat,
    stat: stat.name,
  }));

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        //  spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={1} sm={2} md={2} />
        <Grid item xs={4} sm={3} md={3}>
          <Box mr={1}>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 64, height: 64 }}
                src={
                  player.playerId.headImage
                    ? mainPath + player.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Box mr={0.5}>
              <Typography
                variant="body1"
                style={{ textAlign: "center", color: "#212121" }}
              >
                {player.jerseyNO} {player.playerId.name}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {player.playerId.position}
              </Typography>
            </Box>
            <Box mt={1} style={{ textAlign: "center" }}>
              {player.status === "Unavailable" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={unavailable}
                />
              )}
              {player.status === "Fit" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={available}
                />
              )}
              {player.status === "Booked" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={booked}
                />
              )}
              {player.status === "Suspended" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={suspended}
                />
              )}
              {player.status === "Injured" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={injured}
                />
              )}
              {player.status === "Sick" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={sick}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Box
            className={classes.root}
            style={{
              // marginRight: "20px",
              // marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              rowHeight={48}
              headerHeight={0}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Stats;
