import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

// import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../graphQL/sleague_qry";
import { GET_ALL_SLEAGUE_TEAMS_QUERY } from "../../../graphQL/sleague_team_query";

import TeamInfo from "./leftPanel/TeamInfo";
import PlayerInfo from "./rightPanel/PlayerInfo";

const GET_SLEAGUETEAM_PLAYER_QUERY = `query sleagueTeamNowPastPlayer($sleagueTeamId: Int!){
  sleagueTeamNowPastPlayer(sleagueTeamId: $sleagueTeamId){
    sleagueTeamPlayerId
    sleagueTeamId{
      sleagueId{
        sleagueId
        seasonName
      }
      teamId{
        teamId
        name
        code
      }
    }
    playerId{
      playerId
      fName
      lName
      name
      foot
      height
      weight
      birthDate
      headImage
      position
      attrPower
      attrSpeed
      attrDefense
      attrAttack
      attrStrategy
      attrTechnical
      createBy
      createDate
      updateBy
      updateDate
    }
    jerseyNO
    playerSeqNO
    status
  }
}`;

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 1366,
  },
}));
const TeamSelector = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqVal,
  embargoTime,
  selectionDate,
  footyNo
}) => {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [userSeqData, setUserSeqData] = useState([]);
  const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [teamSelectionData, setTeamSelectionData] = useState([]);
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState(() => {
    let userSeq = userSeqVal.trim();
    if (userSeq !== "") return userSeq.slice(0, 2);
    return "01";
  });
  const navigate = useNavigate();

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });

  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin`);
    }
  }, [dataSLTeam]);

  useEffect(() => {
    if (
      !playersDataLoading &&
      playersData &&
      playersData.sleagueTeamNowPastPlayer
    ) {
      setPlayers(playersData.sleagueTeamNowPastPlayer);
      // setPlayers(
      //   playersData.sleagueTeamPlayer.map(player => {
      //     let name = player.playerId.fName + " " + player.playerId.lName;
      //     let position = player.playerId.position;
      //     let jerseyNO = player.jerseyNO;
      //     let playerSeqNO = player.playerSeqNO;
      //     let type = ItemTypes.PLAYER;
      //     return { name, position, jerseyNO, playerSeqNO, type };
      //   })
      // );
    }
  }, [playersData]);

  const getTeamSelectionsData = (droppedPlayerNames, teamSelections) => {
    setTeamSelectionData(teamSelections);
    let userSeq = "<[" + selectedFormation + "]>";
    let draftList = {};
    // GK

    let key = parseInt(teamSelections.GK[0].positionSeqNo);
    let val = teamSelections.GK[0].lastDroppedItem
      ? teamSelections.GK[0].lastDroppedItem.playerSeqNO
      : "00";
    draftList[key] = val;

    // DEF
    teamSelections.DEF.defLane_col_1 &&
      teamSelections.DEF.defLane_col_1.length > 0 &&
      teamSelections.DEF.defLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.DEF.defLane_col_2 &&
      teamSelections.DEF.defLane_col_2.length > 0 &&
      teamSelections.DEF.defLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // MID
    teamSelections.MID.midLane_col_1 &&
      teamSelections.MID.midLane_col_1.length > 0 &&
      teamSelections.MID.midLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_2 &&
      teamSelections.MID.midLane_col_2.length > 0 &&
      teamSelections.MID.midLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_3 &&
      teamSelections.MID.midLane_col_3.length > 0 &&
      teamSelections.MID.midLane_col_3.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // FOR
    teamSelections.FOR.forLane_col_1 &&
      teamSelections.FOR.forLane_col_1.length > 0 &&
      teamSelections.FOR.forLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.FOR.forLane_col_2 &&
      teamSelections.FOR.forLane_col_2.length > 0 &&
      teamSelections.FOR.forLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );

    Object.keys(draftList).map((i) => {
      if (i < 10) userSeq += "<[0" + i + "]" + "[" + draftList[i] + "]>";
      else userSeq += "<[" + i + "]" + "[" + draftList[i] + "]>";
    });

    setUserSeqData(userSeq);
    setDroppedPlayerNamesData(droppedPlayerNames);
  };
  const getSelectedFormation = (selectedFormation) => {
    setSelectedFormation(selectedFormation);
  };

  return (
    <Container maxWidth="xl">
      <Grid
        className={classes.container}
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={6} sm={6} md={6}>
          <Paper style={{ width: "100%", minWidth: 639, height: 793 }}>
            <TeamInfo
              userData={userData}
              userFavTeam={userFavTeam}
              fixtureTeamId={fixtureTeamId}
              sleagueTeamId={sleagueTeamId}
              fixtureId={fixtureId}
              leagueId={leagueId}
              userSeqData={userSeqData}
              userSeqVal={userSeqVal}
              embargoTime={embargoTime}
              sleaguePlayers={players}
              sleagueTeamsData={allSleagueTeamsData}
              passTeamSelectionsData={getTeamSelectionsData}
              passSelectedFormation={getSelectedFormation}
              selectionDate={selectionDate}
              footyNo={footyNo}
            />
          </Paper>
        </Grid>

      </Grid>
    </Container>
  );
};

export default TeamSelector;
