import React, { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";
import classes from "./StaticHeader.module.scss";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import logo from "../assets/predictal_logo.svg";
import profile from "../assets/profile.png";

const USERFAVTEAM_QUERY = `query profile {
  profile {
    userFavTeamField {
      sleagueTeamId {
        teamId {
          name
          shortName
          logo
        }
      }
    }
  }
}`;

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px;
  }
`;
const StaticHeader = ({ userData, userFavTeam }) => {
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const username = userData && userData.username ? userData.username : "";
  // const favTeamName = userFavTeam ? userFavTeam.teamInfo.shortName : "";

  // Set user fav team data
  const [userFavTeamName, setUserFavTeamName] = useState("");
  const [userFavTeamLogo, setUserFavTeamLogo] = useState("");
  const { loading, error, data } = useQuery(USERFAVTEAM_QUERY);
  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserFavTeamName(data.profile.userFavTeamField.sleagueTeamId.teamId.shortName);
      setUserFavTeamLogo(data.profile.userFavTeamField.sleagueTeamId.teamId.logo);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate("/wc22/signin");
    }
  }, [data]);

  return (
    <header className={classes.header}>
      <div className={classes.header__content}>
        <a href="/">
          <img height={88} src={logo} alt="logo" className={classes.header__content__logo} />
        </a>
		<a href="/">Home</a>
		<a href="/about-us.html">About Us</a>
		<a href="/how-team-selector-works.html">How Team Selector Works</a>
        {username && (
		<nav className={classes.header__content__nav}>
          <div className={classes.header__content__nav__navitem}>
            <a href="/wc22/fixtures">
              <StyledTooltip title="See Fixtures">
                <img
                  src={userFavTeamLogo && "/wc22/media/" + userFavTeamLogo}
                  alt="teamLogo"
                  height={36}
                  width={60}
                  //title="See Fixtures"
                />
              </StyledTooltip>
            </a>
            <StyledTooltip title="See Fixtures">
              <div
                className={classes.header__content__nav__navitem__name}
                onClick={() => navigate("/wc22/fixtures")}
              >
                {userFavTeamName}
              </div>
            </StyledTooltip>
          </div>
          <div className={classes.header__content__nav__navitem}>
            <img src={profile} alt="profile" height={45} width={45} />
            <StyledTooltip title="Profile">
              <div
                className={classes.header__content__nav__navitem__name}
                onClick={() => {
                  navigate("/wc22/profile");
                }}
              >
                {username}
              </div>
            </StyledTooltip>
          </div>
          <StyledTooltip title="Home">
            <IconButton
              aria-label="Home"
              component="span"
              onClick={() => {
                navigate("/wc22/dashboard");
              }}
            >
              <HomeIcon sx={{ color: "#662483" }} height={40} width={40} />
            </IconButton>
          </StyledTooltip>
          <StyledTooltip title="Sign out">
            <IconButton
              aria-label="signout"
              component="span"
              onClick={() => {
                navigate("/wc22/signout");
              }}
            >
              <LogoutIcon sx={{ color: "#662483" }} height={36} width={36} />
            </IconButton>
          </StyledTooltip>
        </nav>
		)}
        <div className={classes.header__content__toggle}>
          <div className={classes.header__content__nav__navitem}>
            <img
              src={userFavTeamLogo && "/wc22/media/" + userFavTeamLogo}
              alt="teamLogo"
              height={36}
              width={60}
            />
            <div
              className={classes.header__content__nav__navitem__name}
              onClick={() => navigate("/wc22/fixtures")}
            >
              {userFavTeamName}
            </div>
          </div>
          <div className={classes.header__content__nav__navitem}>
            <img src={profile} alt="profile" height={45} width={45} />
            <div
              className={classes.header__content__nav__navitem__name}
              onClick={() => {
                navigate("/wc22/profile");
              }}
            >
              {username}
            </div>
          </div>

          <IconButton
            aria-label="Home"
            component="span"
            onClick={() => {
              navigate("/wc22/dashboard");
            }}
          >
            <HomeIcon color="#646464" height={40} width={40} />
          </IconButton>

          <IconButton
            aria-label="upload picture"
            component="span"
            onClick={() => {
              navigate("/wc22/signout");
            }}
          >
            <LogoutIcon color="#646464" height={36} width={36} />
          </IconButton>
        </div>
      </div>
    </header>
  );
};

export default StaticHeader;
// <MenuIcon color="white" onClick={handleClick} />
// <Menu
//   id="demo-positioned-menu"
//   aria-labelledby="demo-positioned-button"
//   open={open}
//   onClose={handleClose}
//   anchorOrigin={{
//     vertical: "top",
//     horizontal: "right"
//   }}
//   transformOrigin={{
//     vertical: "top",
//     horizontal: "right"
//   }}
// >
//   <MenuItem onClick={() => navigate("/lms/fixtures")}>
//     {favTeamName}
//   </MenuItem>
//   <MenuItem
//     onClick={() => {
//       navigate("/lms/profile");
//     }}
//   >
//     {username}
//   </MenuItem>
//   <MenuItem
//     onClick={() => {
//       navigate("/lms/signout");
//     }}
//   >
//     Signout
//   </MenuItem>
// </Menu>

// title={
//   <span
//     style={{
//       padding:0,
//       margin:0,
//
//     }}
//   >
//     See Fixtures
//   </span>
// }
