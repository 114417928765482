import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";

import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  Icon,
  makeStyles,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import update from "immutability-helper";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

import AlertDialog from "../../../../components/AlertDialog.js";
import ShareDialog from "../../ShareDialog.js";
import leagueLogo from "../../../../assets/Premier_League-Logo.png";
import imgRes from "../../../../assets/positionImgs/res.png";
import footField from "../../../../assets/positionImgs/football_field.png";
import { Selection } from "./Selection";
import { POSITIONS_DATA } from "../../../../store/data/DummyData";
import { REFRESH_TOKEN_MUTATION } from "../../../../graphQL/user_qry";
import { ItemTypes } from "../../../../store/data/ItemTypes";

import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../../graphQL/formationToLeague_qry";
import { GET_SETTING_BY_NAME } from "../../../../graphQL/setting_qry";

import styled from "styled-components";
//import IconButton from "@mui/material/IconButton";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip from "@material-ui/core/Tooltip";
import delayedIcon from "../../../../assets/delayed.png";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

var findAnd = require("find-and");

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingLeft: 33,
    paddingTop: 23,
    paddingRight: 25,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    minHeight: 100,
    borderRadius: 8,
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      height: 48,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 48,
      fontSize: 16,
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      delayed
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
      sleagueId{
        sleagueId
        spMax
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;

const ADD_USER_TS = `mutation addUserTS($fixtureTeamId: Int!,  $userSeq: String!) {
  addUserTS(
    fixtureTeamId: $fixtureTeamId,
    userSeq: $userSeq,
  ) {
    userTS{
      userTSId
      userSeq
    }
  }
}`;

const UPDATE_USER_TS = `mutation updateUserTS($userTSId: Int!, $userSeq: String!) {
  updateUserTS(
    userTSId: $userTSId,
    userSeq: $userSeq,
  ) {
    userTS{
      userTSId
      userSeq
    }
  }
}`;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    font-size: 14px;
  }
`;

const TeamInfo = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqData,
  userSeqVal,
  coachSeqVal,
  userTSId,
  spAward,
  embargoTime,
  serverTime,
  sleaguePlayers,
  sleagueNowPastPlayers,
  sleagueTeamsData,
  passTeamSelectionsData,
  passSelectedFormation,
  selectionDate,
  copyPastFixtureDataTS,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [nowPastPlayers, setNowPastPlayers] = useState([]);
  const [formations, setFormations] = useState();
  const [selectedFormation, setSelectedFormation] = useState("01");
  const [notSelectedPositions, setNotSelectedPositions] = useState([]);
  //   () => {
  //   let userSeq = userSeqVal.trim();
  //   let coachSeq = coachSeqVal.trim();
  //   if (userSeq !== "") return userSeq.slice(0, 2);
  //   else if (coachSeq !== "") return coachSeq.slice(0, 2);
  //   return "01";
  // });
  const [selectedFormationTitle, setSelectedFormationTitle] = useState("");
  const [fixDate, setFixDate] = useState("");
  const [fixDateGetTime, setFixDateGetTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [counterDate, setCounterDate] = useState("");
  const [competitor, setCompetitor] = useState([]);
  const [homeTeam, setHomeTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [teamSelections, setTeamSelections] = useState([]);
  const [disableTeamSelection, setDisableTeamSelection] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [isOnshare, setIsOnshare] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [delayed, setDelayed] = useState(false);

  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);
  const [addUserTeam] = useMutation(ADD_USER_TS);
  const [updateUserTeam] = useMutation(UPDATE_USER_TS);
  const [pathname] = useState(window.location.pathname);
  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [copyPastFixtureData, setCopyPastFixtureData] = useState("");
  const [spMax, setSpMax] = useState(0);
  const { width, height } = useWindowSize();

  const exportRef = useRef();
  const [selectionCreateDate, setSelectionCreateDate] = useState("");
  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",
  });
  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "day").value +
        " " +
        dayFormat.find((a) => a.type == "month").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value;
      return newFormat;
    }
  };

  const timeFormat = (day) => {
    const dayDate = new Date();
    const tFormat = formatter.formatToParts(dayDate);
    const newFormat =
      tFormat.find((a) => a.type == "hour").value +
      ":" +
      tFormat.find((a) => a.type == "minute").value +
      " " +
      tFormat.find((a) => a.type == "dayPeriod").value +
      " (" +
      tFormat.find((a) => a.type == "timeZoneName").value +
      ")";
    return newFormat;
  };

  const mainPath = "/lms/media/";

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: leagueId,
    },
  });

  const {
    loading: loadingConfettiScore,
    error: errorConfettiScore,
    data: dataConfettiScore,
  } = useQuery(GET_SETTING_BY_NAME, {
    fetchPolicy: "network-only",
    variables: {
      settingName: "Confetti Score",
    },
  });
  useEffect(() => {
    if (
      !loadingConfettiScore &&
      dataConfettiScore &&
      dataConfettiScore.settingByName.allValues
    ) {
      if (
        parseInt(spAward) >=
        parseInt(dataConfettiScore.settingByName.allValues[0].value)
      ) {
        setDisplayAlert(true);
      }
    }
  });
  useEffect(() => {
    let userSeq = userSeqVal.trim();
    let coachSeq = coachSeqVal.trim();
    if (userSeq !== "") setSelectedFormation(userSeq.slice(0, 2));
    else if (coachSeq !== "") setSelectedFormation(coachSeq.slice(0, 2));
    else setSelectedFormation("01");
  }, [coachSeqVal, userSeqVal]);

  useEffect(async () => {
    if (copyPastFixtureDataTS === "clear") {
      localStorage.removeItem("pastFixData");
      let userSeq = userSeqVal.trim();
      if (userSeq !== "") {
        await setSelectedFormation(userSeq.slice(0, 2));
        passSelectedFormation(userSeq.slice(0, 2));
      } else clearHandler();
      setCopyPastFixtureData("");
      return;
    }
    if (copyPastFixtureDataTS) {
      let copyPastFixture = copyPastFixtureDataTS.trim();
      if (copyPastFixture !== "") {
        await setSelectedFormation(copyPastFixture.slice(0, 2));
        passSelectedFormation(copyPastFixture.slice(0, 2));
        setCopyPastFixtureData(copyPastFixture);
      }
    }
  }, [copyPastFixtureDataTS]);

  useEffect(() => {
    setPlayers(
      sleaguePlayers.map((player) => {
        let name = player.playerId.name;
        let onFieldName = player.playerId.onFieldName;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        let currentTeam = player.currentTeam;
        return {
          name,
          onFieldName,
          position,
          jerseyNO,
          playerSeqNO,
          type,
          status,
          currentTeam,
        };
      })
    );
  }, [sleaguePlayers]);

  useEffect(() => {
    setNowPastPlayers(
      sleagueNowPastPlayers.map((player) => {
        let name = player.playerId.name;
        let onFieldName = player.playerId.onFieldName;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        let currentTeam = player.currentTeam;
        return {
          name,
          onFieldName,
          position,
          jerseyNO,
          playerSeqNO,
          type,
          status,
          currentTeam,
        };
      })
    );
    if (displayAlert) {
      setDisplayAlert(false);
      setTimeout(() => {
        alert(
          "Congratulations! You earned " +
            spAward +
            " Sport Points (SP) out of a maximum " +
            spMax +
            " for your starting line-up team that highly matches the Coach Team."
        );
        setTimeout(() => {
          setDisplayConfetti(false);
        }, 10000);
      }, 100);
      setDisplayConfetti(true);
    }
  }, [sleagueNowPastPlayers]);

  useEffect(() => {
    let findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let droppedPlayerNamesVal = [];
    let draftTeamSelections = findPositionsData.positionsData;
    let val = "";
    let seqVal = userSeqVal;
    let playersData = nowPastPlayers;
    if (!seqVal && !copyPastFixtureData) return;
    if (copyPastFixtureData) {
      seqVal = copyPastFixtureData;
      playersData = players;
    }

    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i) => {
        switch (i) {
          case "01":
            val = seqVal.slice(4, 6);
            break;
          case "02":
            val = seqVal.slice(8, 10);
            break;
          case "03":
            val = seqVal.slice(12, 14);
            break;
          case "04":
            val = seqVal.slice(16, 18);
            break;
          case "05":
            val = seqVal.slice(20, 22);
            break;
          case "06":
            val = seqVal.slice(24, 26);
            break;
          case "07":
            val = seqVal.slice(28, 30);
            break;
          case "08":
            val = seqVal.slice(32, 34);
            break;
          case "09":
            val = seqVal.slice(36, 38);
            break;
          case "10":
            val = seqVal.slice(40, 42);
            break;
          case "11":
            val = seqVal.slice(44, 46);
        }

        resFound = findAnd.returnFound(playersData, {
          playerSeqNO: val ? val : "", // "" to support "" playerSeqNO case
        });

        // console.log("resFound____",resFound)
        if (
          copyPastFixtureData &&
          resFound &&
          resFound.status !== "Fit" &&
          resFound.status !== "Booked"
        )
          return;
        // for that case that found more than one player with this playerSeqNO(val)
        if (resFound && resFound.length > 1) {
          let resFoundTrueCurentTeam = findAnd.returnFound(resFound, {
            currentTeam: true,
          });
          resFound = resFoundTrueCurentTeam;
        }
        if (resFound) {
          // for that case that found more than one player with this playerSeqNO(val) and currentTeam == true
          if (resFound.length > 1) resFound = resFound[0];
          let resForPos = findAnd.returnFound(draftTeamSelections, {
            positionSeqNo: i,
          });
          draftTeamSelections = findAnd.changeProps(
            draftTeamSelections,
            { positionSeqNo: i },
            {
              lastDroppedItem: {
                name: resFound.name,
                onFieldName: resFound.onFieldName,
                playerSeqNO: resFound.playerSeqNO,
                jerseyNO: resFound.jerseyNO,
                formationPos: resForPos && resForPos.text,
              },
            }
          );
          droppedPlayerNamesVal.push(resFound.name);
        }
      }
    );

    let resForPos = findAnd.returnFound(draftTeamSelections, {
      lastDroppedItem: null,
    });

    let notSelectedPositions = [];
    if (resForPos && resForPos.text) notSelectedPositions.push(resForPos.text);
    else if (resForPos && resForPos.length > 0)
      resForPos.map((item) => {
        if (item.text) notSelectedPositions.push(item.text);
        for (var j = 0; j < 6; j++)
          if (item[j]) notSelectedPositions.push(item[j].text);
      });

    setDroppedPlayerNames(droppedPlayerNamesVal);
    setTeamSelections(draftTeamSelections);
    passTeamSelectionsData(droppedPlayerNamesVal, draftTeamSelections);
    setNotSelectedPositions(notSelectedPositions);
  }, [players, nowPastPlayers, copyPastFixtureData]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {
      leagueFormationsData.leagueFormation.map((leagueFrm) => {
        if (
          !parentFormations.includes(leagueFrm.formationId.formationPrimary)
        ) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(
          (frm) => {
            return frm.formationId.formationPrimary === formation;
          }
        );
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedFormation,
    });
    if (resFound) setSelectedFormationTitle(resFound.title);
    setFormations(formationsList);
  }, [leagueFormationsData, selectedFormation]);

  const renderSelectGroup = (formation) => {
    const items = formation.derivedCategories.map((f) => {
      return (
        <MenuItem
          style={{ paddingLeft: 30, fontSize: 14 }}
          divider={true}
          // onMouseEnter={(e) => (e.target.style.backgroundColor = "#F7F4F7")}
          // onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffffff")}
          key={f.formationId.formationSeqNO}
          value={f.formationId.formationSeqNO}
        >
          {f.formationId.title}
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        style={{
          color: "black",
          fontSize: 14,
          fontWeight: "bold",
          backgroundColor: "white",
          marginLeft: 0,
        }}
      >
        {formation.mainCategory}
      </ListSubheader>,
      items,
    ];
  };

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 500 milliseconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 200);
        loadImg.onerror = (err) => {
          reject(err);
        };
      });
    };
    let IMAGES = [];
    if (teamSelections) {
      if (teamSelections.GK) IMAGES.push(imgRes, teamSelections.GK[0].img);
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_1)
        teamSelections.DEF.defLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_2)
        teamSelections.DEF.defLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_1)
        teamSelections.MID.midLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_2)
        teamSelections.MID.midLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_3)
        teamSelections.MID.midLane_col_3.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_1)
        teamSelections.FOR.forLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_2)
        teamSelections.FOR.forLane_col_2.map((i) => IMAGES.push(i.img));
    }
    if (IMAGES.length == 0) return;
    Promise.all(IMAGES.map((img) => loadImage(img)))
      .then(() => setIsLoad(true))
      .catch((err) => console.log("Failed to load images", err));
  }, [teamSelections]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    // Set User Team Data
    if (!loading && data && data.fixtureObjectTeams) {
      setDelayed(data.fixtureObjectTeams[0].fixtureId.delayed);
      setSpMax(data.fixtureObjectTeams[0].sleagueTeamId.sleagueId.spMax);
      data.fixtureObjectTeams.map((fixtureTeam) => {
        // Set User team data
        if (
          parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId
        ) {
          if (fixtureTeam.home) {
            setHomeTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          } else
            setSecondTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);

          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            //second: "2-digit",
            hour12: true,
          };
          console.log(
            "serverTimecompair_____",
            "scheduledDate",
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate),
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime(),
            "serverTimeUTC",
            serverTime,
            serverTime.getTime(),
            "scheduledDate- embargo",
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() -
              (embargoTime && embargoTime.value) * 3600000,
            "Date.now"
          );
          //check embargo time with server time
          let diff =
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() - serverTime.getTime();
          if (
            serverTime.getTime() >
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() -
              (embargoTime && embargoTime.value) * 3600000
          ) {
            setCounterDate(diff);
          } else {
            setCounterDate(
              new Date(
                fixtureTeam.fixtureId.fixtureWeek.scheduledDate
              ).getTime() -
                (embargoTime && embargoTime.value) * 3600000
            );
          }
          var fixDate = new Date(
            fixtureTeam.fixtureId.fixtureWeek.scheduledDate
          ).toLocaleTimeString("en-us", options);
          setFixDateGetTime(
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime()
          );

          var formatedTime =
            fixDate.split(" ")[5] + " " + fixDate.split(" ")[6]; //time + " " + pam;
          setFixDate(
            dayFormat(fixtureTeam.fixtureId.fixtureWeek.scheduledDate)
          ); //formatedDate);
          setIsLocked(fixtureTeam.fixtureId.lock);
          //setDisableTeamSelection(fixtureTeam.fixtureId.lock); //false);
          setStartTime(formatedTime);

          setSelectionCreateDate(
            dayFormat(new Date()) + "  " + timeFormat(new Date())
          );
        }
        // Set User team competitor for that fixtureId
        else {
          if (fixtureTeam.home)
            setHomeTeam([
              fixtureTeam.sleagueTeamId.teamId,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          setCompetitor([
            fixtureTeam.sleagueTeamId.teamId,
            fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
          ]);
        }
      });
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureId, embargoTime, userFavTeam]);
  useEffect(() => {
    if (!fixDateGetTime) return;
    // Post Fixture
    var timeDiff = serverTime.getTime() /*Date.now()*/ > fixDateGetTime;
    if (isLocked && disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot edit your Starting XI team. "
        );
      else {
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot select your Starting XI team."
        );
      }
    }
    if (isLocked && !disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is locked now. You cannot edit your Starting XI team."
        );
      else {
        setAlertMsg(
          "This fixture is locked now. You cannot select your Starting XI team."
        );
      }
    }
    if (!isLocked && disableTeamSelection && !timeDiff)
      setAlertMsg(
        "This fixture is in embargo at this time. You cannot create or edit your Starting XI team. "
      );
    if (notSelectedPositions.length > 0) {
      let msg = createNotSelectedPosAlertMsg(notSelectedPositions);
      if (droppedPlayerNames.length > 0) setAlertMsg(msg);
    }
  }, [
    disableTeamSelection,
    isLocked,
    userSeqVal,
    fixDateGetTime,
    notSelectedPositions,
  ]);

  useEffect(() => {
    const findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (findPositionsData) {
      let resFound = [];
      let draftTeamSelections = findPositionsData.positionsData;

      // find position seq no from previous selected formation and put in new formation dropped item
      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
        (item) => {
          resFound = findAnd.returnFound(teamSelections, {
            positionSeqNo: item,
          });

          if (resFound) {
            draftTeamSelections = findAnd.changeProps(
              draftTeamSelections,
              { positionSeqNo: item },
              { lastDroppedItem: resFound.lastDroppedItem }
            );
          }
        }
      );

      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
    }
  }, [selectedFormation]);

  //const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUES_QUERY);

  const handleFormationChange = (event) => {
    var resFound = findAnd.returnFound(formations, {
      formationSeqNO: event.target.value,
    });
    if (droppedPlayerNames.length > 0 && resFound)
      setAlertMsg(
        `You changed the team formation from ${selectedFormationTitle} to ${resFound.title}. Check players' new positions on the Team Selection as you may want to move them into other positions in the new formation.`
      );
    setSelectedFormation(event.target.value);
    setIsLoad(false);
    passSelectedFormation(event.target.value);
  };

  useEffect(() => {
    if ((disableTeamSelection || isLocked) && userSeqVal === "")
      setSelectedFormation("");
  }, [isLocked, disableTeamSelection, userSeqVal]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  const handleDrop = useCallback(
    (index, item, pos, posLane, formationPos) => {
      if (localStorage.getItem("refreshToken")) {
        onRefreshToken();
      } else navigate(`/lms/signin/?next=${pathname}`);
      if (disableTeamSelection || isLocked) return;
      let resFound = findAnd.returnFound(players, {
        playerSeqNO: item.playerSeqNO,
      });
      if (!resFound) return;
      if (resFound.status !== "Fit" && resFound.status !== "Booked")
        return;
      let draftTeamSelections = [];
      const { name, playerSeqNO, jerseyNO, onFieldName } = item;
      if (droppedPlayerNames.indexOf(name) > -1) return;
      // If a player is dropped into the position of another player, delete the previous player
      if (pos === "GK") {
        if (teamSelections[pos][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [index]: {
              lastDroppedItem: {
                $set: {
                  name,
                  onFieldName,
                  playerSeqNO,
                  jerseyNO,
                  formationPos,
                },
              },
            },
          },
        });
      } else {
        if (teamSelections[pos][posLane][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][posLane][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [posLane]: {
              [index]: {
                lastDroppedItem: {
                  $set: {
                    name,
                    onFieldName,
                    playerSeqNO,
                    jerseyNO,
                    formationPos,
                  },
                },
              },
            },
          },
        });
      }
      let draftDroppedPlayerNames = update(
        droppedPlayerNames,
        name ? { $push: [name /*, playerSeqNO*/] } : { $push: [] }
      );
      setDroppedPlayerNames(draftDroppedPlayerNames);
      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(draftDroppedPlayerNames, draftTeamSelections);
    },
    [
      players,
      droppedPlayerNames,
      teamSelections,
      disableTeamSelection,
      isLocked,
    ]
  );

  const onRemoveDroppedItem = (item, pos, posLane) => {
    // remove player from droppedPlayerNames list
    var i = droppedPlayerNames.indexOf(item.name);
    i > -1 && droppedPlayerNames.splice(i, 1);

    // set lastDroppedItem to null for removed item
    let draftTeamSelections = [];
    if (pos === "GK") {
      if (
        teamSelections.GK[0] &&
        teamSelections.GK[0].lastDroppedItem.name === item.name
      ) {
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [0]: {
              lastDroppedItem: {
                $set: null,
              },
            },
          },
        });
        setTeamSelections(draftTeamSelections);
      }
    } else {
      teamSelections[pos][posLane] &&
        teamSelections[pos][posLane].map(({ lastDroppedItem }, index) => {
          if (lastDroppedItem && lastDroppedItem.name === item.name) {
            draftTeamSelections = update(teamSelections, {
              [pos]: {
                [posLane]: {
                  [index]: {
                    lastDroppedItem: {
                      $set: null,
                    },
                  },
                },
              },
            });
            setTeamSelections(draftTeamSelections);
          }
        });
    }
    // console.log("droppedPlayerNames----",droppedPlayerNames)
    // console.log("draftTeamSelections----",draftTeamSelections)
    passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
  };
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setDisableTeamSelection(true);
    }
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box
          mt={0.5}
          style={{
            display: "flex",
            flexDirection: "row",
            width: 225,
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.25 }}>
              {days.toString().length > 2 ? days : ("0" + days).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: 700, paddingLeft: 2, color: "#4D4D4D" }}
            >
              Days
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.25 }}>
              {("0" + hours).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: 700, paddingLeft: 2, color: "#4D4D4D" }}
            >
              Hrs
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.25 }}>
              {" "}
              {("0" + minutes).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: 700, paddingLeft: 2, color: "#4D4D4D" }}
            >
              Mins
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" style={{ lineHeight: 1.25 }}>
              {("0" + seconds).slice(-2)}{" "}
            </Typography>
            <Typography
              variant="h6"
              style={{ fontWeight: 700, paddingLeft: 2, color: "#4D4D4D" }}
            >
              Secs
            </Typography>
          </Box>
        </Box>
        <Box mt={0.5}>
          <Typography
            variant="h6"
            style={{ fontWeight: 400, letterSpacing: 0.7, color: "#757575" }}
          >
            Time left to select your starting team
          </Typography>
        </Box>
      </Box>
    );
  };

  const submitHandler = async (e) => {
    // alert(fixtureTeamId);
    // alert(userSeqData);
    if (Object.keys(userSeqData).length === 0 || userSeqData.includes("[00]")) {
      setAlertMsg(
        "You must complete your Starter XI team. Please select all 11 players."
      );
      return;
    }
    localStorage.removeItem("pastFixData");
    let userSeqStrVal = userSeqData.replace(/</g, "");
    userSeqStrVal = userSeqStrVal.replace(/>/g, "");
    userSeqStrVal = userSeqStrVal.replace(/\[/g, "");
    userSeqStrVal = userSeqStrVal.replace(/]/g, "");
    // alert(userSeqStrVal);

    if (userTSId !== 0) {
      let { data, error } = await updateUserTeam({
        variables: {
          userTSId: userTSId,
          userSeq: userSeqStrVal,
        },
      });
      if (error) {
        alert(error.graphQLErrors[0].message);
      } else {
        setAlertMsg("User Team Selection has been updated successfully.");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    } else {
      let { data, error } = await addUserTeam({
        variables: {
          fixtureTeamId: fixtureTeamId,
          profileId: parseInt(userData.id),
          userSeq: userSeqStrVal,
        },
      });
      if (error) {
        alert(error.graphQLErrors[0].message);
      } else {
        setAlertMsg("User Team Selection has been added successfully.");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    }
  };

  const clearHandler = async () => {
    localStorage.removeItem("pastFixData");

    let clearUsrSeq = userSeqData.slice(2, 4);
    let draftTeamSelections = teamSelections;
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i) => {
        draftTeamSelections = findAnd.changeProps(
          draftTeamSelections,
          { positionSeqNo: i },
          { lastDroppedItem: null }
        );
        clearUsrSeq += i + "00";
      }
    );
    setTeamSelections(draftTeamSelections);
    setDroppedPlayerNames([]);
    await passTeamSelectionsData([], draftTeamSelections);
  };

  const handleOpenShareDialog = (e) => {
    setOpenShareDialog(true);
    setIsOnshare(true);
  };
  const handleCloseShareDialog = (e) => {
    setOpenShareDialog(false);
    setIsOnshare(false);
  };

  const createNotSelectedPosAlertMsg = (notSelPosArr) => {
    let singleMsgCommonPart = "Please select a player in position ";
    let notSingleMsgCommonPart = "Please select players in positions ";

    if (notSelPosArr.length <= 1) {
      return singleMsgCommonPart + notSelPosArr[0] + ".";
    }
    return (
      notSingleMsgCommonPart +
      notSelPosArr.slice(0, -1).join(", ") +
      " and " +
      notSelPosArr.slice(-1) +
      "."
    );
  };

  return (
    <Card className={classes.cardContainer}>
      <Box
        style={{ display: "flex", flexDirection: "row", width: "95%" }}
        alignItems="center"
        flexGrow={1}
      >
        {displayConfetti && <Confetti width={width} height={height} />}
        <Box>
          <StyledTooltip title="Return to Fixtures">
            <IconButton
              edge="start"
              color="primary"
              onClick={() => {
                navigate("/lms/fixtures");
                localStorage.removeItem("pastFixData");
              }}
              size="large"
            >
              <KeyboardReturn sx={{ color: "#662483" }} fontSize="inherit" />
            </IconButton>
          </StyledTooltip>
        </Box>
        <Box
          style={{ display: "flex", justifyContent: "center", width: "95%" }}
        >
          <Typography variant="body1">
            My Starting XI Team: {userFavTeam.teamInfo.shortName}{" "}
          </Typography>
        </Box>
      </Box>
      <div ref={exportRef} style={{ paddingLeft: "17px" }}>
        <Box
          style={{
            display: openShareDialog === true ? "flex" : "none",
            justifyContent: "center",
            width: "95%",
          }}
        >
          <Typography variant="body1">
            {userData.username}'s Starting Lineup:{" "}
            {userFavTeam.teamInfo.shortName + ", " + selectedFormationTitle}
          </Typography>
        </Box>
        <Box
          mt={2}
          style={{
            display: openShareDialog === true ? "flex" : "none",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              width: 190,
              display: "flex",
              paddingTop: 5,
              justifyContent: "end",
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 18 }}>
              {homeTeam[0] && secondTeam
                ? homeTeam[0].shortName
                : userFavTeam.teamInfo && userFavTeam.teamInfo.shortName}{" "}
            </Typography>
          </Box>
          <Box
            ml={1}
            mr={1}
            style={{
              width: 200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box>
              <img
                src={
                  homeTeam[0] && secondTeam && homeTeam[0].logo
                    ? mainPath + homeTeam[0].logo
                    : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                    ? mainPath + userFavTeam.teamInfo.logo
                    : ""
                }
                //alt="teamLogo"
                height={48}
                width={48}
              />
            </Box>
            <Box
              ml={1}
              mr={1}
              style={{
                height: 40,
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                //  paddingTop: 5,
              }}
            >
              <Typography variant="subtitle2">-</Typography>
            </Box>
            <Box>
              <img
                src={
                  !secondTeam[0]
                    ? competitor[0] && competitor[0].logo
                      ? mainPath + competitor[0].logo
                      : ""
                    : secondTeam[0] && mainPath + secondTeam[0].logo
                }
                //alt="teamLogo"
                height={48}
                width={48}
              />
            </Box>
          </Box>
          <Box
            style={{
              width: 190,
              display: "flex",
              paddingTop: 5,
              justifyContent: "start",
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 18 }}>
              {!secondTeam[0]
                ? competitor[0] && competitor[0].shortName
                : secondTeam[0] && secondTeam[0].shortName}
            </Typography>
          </Box>
        </Box>

        <Box
          mt={1}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "94%",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            {delayed ? (
              <StyledTooltip title="The fixture has been delayed">
                <Icon
                  aria-label="delayed"
                  style={{
                    width: 32,
                    height: 32,
                    margin: "0.2em",
                    fontSize: "1em",
                    display: "inline",
                    verticalAlign: "middle",
                  }}
                >
                  <img
                    src={delayedIcon}
                    style={{ verticalAlign: "middle" }}
                    width={32}
                  />
                </Icon>
              </StyledTooltip>
            ) : (
              ""
            )}
            <Box>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, color: "#757575" }}
                >
                  Fixture Date:
                </Typography>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, color: "#4D4D4D", paddingLeft: 5 }}
                >
                  {fixDate}
                </Typography>
              </Box>
              <Box mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, color: "#757575" }}
                >
                  Start Time:
                </Typography>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700, color: "#4D4D4D", paddingLeft: 5 }}
                >
                  {startTime}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ color: "#4D4D4D", paddingLeft: 5, paddingTop: 2 }}
                >
                  (Your local time)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mb={0.5}>
            <img src={leagueLogo} alt="leagueLogo" height={39} width={88} />
          </Box>
        </Box>
        {!isLoad || selectedFormation === "" ? (
          <div
            style={{
              backgroundImage: `url(${footField})`,
              width: 580,
              height: 408,
              backgroundColor: "green",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              float: "left",
              backgroundColor: "green",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div
                style={{
                  //  overflow: "hidden",
                  clear: "both",
                }}
              >
                {teamSelections.GK &&
                  teamSelections.GK.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(index, item, "GK", null, text)
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "GK", null)
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
              </div>
              {teamSelections.DEF && teamSelections.DEF.defLane_col_1 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.DEF.defLane_col_1.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "DEF",
                                "defLane_col_1",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "DEF", "defLane_col_1")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.DEF && teamSelections.DEF.defLane_col_2 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.DEF.defLane_col_2.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "DEF",
                                "defLane_col_2",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "DEF", "defLane_col_2")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_1 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_1.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "MID",
                                "midLane_col_1",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "MID", "midLane_col_1")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_2 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_2.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "MID",
                                "midLane_col_2",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "MID", "midLane_col_2")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_3 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_3.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "MID",
                                "midLane_col_3",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "MID", "midLane_col_3")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.FOR && teamSelections.FOR.forLane_col_1 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.FOR &&
                    teamSelections.FOR.forLane_col_1.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              onDrop={(item) =>
                                handleDrop(
                                  index,
                                  item,
                                  "FOR",
                                  "forLane_col_1",
                                  text
                                )
                              }
                              onRemoveDroppedItem={(item) =>
                                onRemoveDroppedItem(
                                  item,
                                  "FOR",
                                  "forLane_col_1"
                                )
                              }
                              embargoTime={
                                disableTeamSelection || isLocked || isOnshare
                              }
                            />
                          </div>
                        );
                      }
                    )}
                </div>
              )}
              {teamSelections.FOR && teamSelections.FOR.forLane_col_2 && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {teamSelections.FOR.forLane_col_2.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "FOR",
                                "forLane_col_2",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "FOR", "forLane_col_2")
                            }
                            embargoTime={
                              disableTeamSelection || isLocked || isOnshare
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              <div
                style={{
                  height: 408,
                  width: 29,
                  backgroundImage: `url(${imgRes})`,
                  //backgroundRepeat: "no-repeat",
                  flexDirection: "column",
                  //border: "1px solid white"
                }}
              />
            </div>
          </div>
        )}
        <Box
          style={{
            display: openShareDialog ? "flex" : "none",
            flexDirection: "row",
            width: "580px",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Copyright &copy; 2023 <br />
            All rights reserved
          </Typography>
          <Typography variant="h6">{selectionCreateDate}</Typography>
        </Box>
        <Box
          style={{
            display: openShareDialog ? "none" : "flex",
            flexDirection: "row",
            width: "580px",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ textAlign: "left" }}>
            <StyledTooltip title="Share your team">
              <IconButton
                //  style={{ paddingLeft: 0 }}
                edge="start"
                color="primary"
                onClick={handleOpenShareDialog}
                size="large"
                disabled={userTSId == 0 || userTSId == ""}
              >
                <ShareIcon
                  sx={{
                    color:
                      userTSId == 0 || userTSId == "" ? "#AAAAAA" : "#662483",
                  }}
                  fontSize="inherit"
                />
              </IconButton>
            </StyledTooltip>
          </Box>
          <Box pr={5}>
            {counterDate && (
              <Countdown date={counterDate} renderer={countdownRenderer} />
            )}
          </Box>
          <Box />
        </Box>
        {homeTeam[0] && (secondTeam[0] || competitor[0]) && (
          <Box
            mt={2}
            style={{
              display: openShareDialog ? "none" : "flex",
              flexDirection: "row",
            }}
          >
            <Box
              style={{
                width: 190,
                display: "flex",
                paddingTop: 5,
                justifyContent: "end",
              }}
            >
              <Typography variant="subtitle2" style={{ fontSize: 18 }}>
                {homeTeam[0] && secondTeam
                  ? homeTeam[0].shortName
                  : userFavTeam.teamInfo && userFavTeam.teamInfo.shortName}{" "}
              </Typography>
            </Box>
            <Box
              ml={1}
              mr={1}
              style={{
                width: 200,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                <img
                  src={
                    homeTeam[0] && secondTeam && homeTeam[0].logo
                      ? mainPath + homeTeam[0].logo
                      : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                      ? mainPath + userFavTeam.teamInfo.logo
                      : ""
                  }
                  //alt="teamLogo"
                  height={48}
                  width={48}
                />
              </Box>
              <Box
                ml={1}
                mr={1}
                style={{
                  width: 94,
                  height: 40,
                  backgroundColor: "#E0E0E0",
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  //  paddingTop: 5,
                }}
              >
                <Typography variant="subtitle2">
                  {homeTeam[1] + " - "}
                  {!secondTeam[0]
                    ? competitor[0] && competitor[1]
                    : secondTeam[0] && secondTeam[1]}
                </Typography>
              </Box>
              <Box>
                <img
                  src={
                    !secondTeam[0]
                      ? competitor[0] && competitor[0].logo
                        ? mainPath + competitor[0].logo
                        : ""
                      : secondTeam[0] && mainPath + secondTeam[0].logo
                  }
                  //alt="teamLogo"
                  height={48}
                  width={48}
                />
              </Box>
            </Box>
            <Box
              style={{
                width: 190,
                display: "flex",
                paddingTop: 5,
                justifyContent: "start",
              }}
            >
              <Typography variant="subtitle2" style={{ fontSize: 18 }}>
                {!secondTeam[0]
                  ? competitor[0] && competitor[0].shortName
                  : secondTeam[0] && secondTeam[0].shortName}
              </Typography>
            </Box>
          </Box>
        )}
      </div>
      <Box
        mt={4}
        style={{
          display: openShareDialog === true ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "95%",
        }}
      >
        <div className={classes.autoComStyle}>
          <FormControl size="small" style={{ width: 200 }}>
            <InputLabel id="demo-simple-select-label">My Formation</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="My Formation"
              disabled={disableTeamSelection || isLocked}
              value={selectedFormation || ""}
              //  name={formationId.title}
              onChange={handleFormationChange}
              MenuProps={{
                sx: {
                  "&& .Mui-selected": {
                    backgroundColor: "#FBF3FF",
                  },
                },
                PaperProps: {
                  sx: { maxHeight: 200 },
                },
              }}
            >
              {formations?.map((p) => renderSelectGroup(p))}
            </Select>
          </FormControl>
        </div>
        <Box>
          <Button
            variant="text"
            disabled={disableTeamSelection || isLocked}
            onClick={clearHandler}
            color="primary"
            className={classes.outlinedButtonStyle}
            style={{ marginRight: 5 }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            disabled={disableTeamSelection || isLocked}
            onClick={submitHandler}
            color="primary"
            className={classes.buttonStyle}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {alertMsg && (
        <AlertDialog
          title="Alert"
          contentMsg={alertMsg}
          closeDialog={() => setAlertMsg(false)}
        />
      )}
      {openShareDialog && (
        <ShareDialog
          closeDialog={handleCloseShareDialog}
          userTSId={userTSId}
          reference={exportRef}
        />
      )}
    </Card>
  );
};

export default TeamInfo;
