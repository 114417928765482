import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import { project_url } from "../../store/data/Constants";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Icon,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import StadiumIcon from "@mui/icons-material/Stadium";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AlertDialog from "../../components/AlertDialog.js";

import {
  GET_UPCOMING_FIXTURE_QUERY,
  GET_LAST_FIXTURE_QUERY,
} from "../../graphQL/fixture_qry";
import { GET_POPULAR_FORMATION_QUERY } from "../../graphQL/formation_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../../graphQL/sleague_team_query";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import delayedIcon from "../../assets/delayed.png";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1320,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    //height: 460,
    maxWidth: 430,
  },
  cardScore: {
    marginTop: 26,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 30,
    //minHeight: 400,
  },
  dateStyle: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  dateStyleMobile: {
    "& .MuiTypography-body2": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  textButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 16,
    height: "48px",
    padding: 0,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  spLedgerButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 13,
    height: "30px",
    width: "124px",
    padding: 0,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  scoreButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 13,
    height: "30px",
    width: "124px",
    padding: 0,
    marginLeft: 7,
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "150px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  arrowIconStyle: {
    width: 50,
    height: 50,
    textAlign: "center",
    backgroundColor: "#F8F8F8",
    borderRadius: 8,
  },

  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  logoStyle: {
    height: 32,
    width: 32,
    marginTop: 5,
    marginRight: 12,
  },
  logoMobileStyle: {
    height: 20,
    width: 20,
    marginTop: 5,
    marginRight: 10,
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    // height: 550,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 16,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 16,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  dataGridMobileStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    // width: "100%",
    "& .super-app-theme--header--mobile": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 12,
      fontWeight: 400,
    },
    "& .MuiDataGrid-cell": {
      fontWeight: 400,
      fontSize: 12,
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
}));

const formatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});

const timeFormatter = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
  hour12: true,
});
const getDayString = (dateFormat, dateandtime, withHour) => {
  const dayDate = new Date(dateandtime);
  const dayFormat = formatter.formatToParts(dayDate);
  let dayandTimeString = "";
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find(a => a.type == "weekday").value +
      ", " +
      dayFormat.find(a => a.type == "day").value +
      " " +
      dayFormat.find(a => a.type == "month").value +
      ", " +
      dayFormat.find(a => a.type == "year").value;
    dayandTimeString = newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find(a => a.type == "weekday").value +
      ", " +
      dayFormat.find(a => a.type == "month").value +
      " " +
      dayFormat.find(a => a.type == "day").value +
      ", " +
      dayFormat.find(a => a.type == "year").value;
    dayandTimeString = newFormat;
  } else {
    const newFormat =
      dayFormat.find(a => a.type == "weekday").value +
      ", " +
      dayFormat.find(a => a.type == "year").value +
      ", " +
      dayFormat.find(a => a.type == "month").value +
      " " +
      dayFormat.find(a => a.type == "day").value;
    dayandTimeString = newFormat;
  }

  if (withHour) {
    const newFormat = " - " + timeFormatter.format(dayDate);

    dayandTimeString = dayandTimeString + newFormat;
  }

  return dayandTimeString;
};

const Dashboard = () => {
  const navigate = useNavigate();
  const mediaPath = `/${project_url}/media/`;
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const errorMsg = null;
  //  "Incorrect email or password. Or, your account is not activated. Or, this email does not exist. Please try again.";
  const successMsg = null;
  //console.log("mobileView:", mobileView, "tabletView:", tabletView);

  //Getting Data
  const favTeam = localStorage.getItem("favTeam");
  const favTeamLogo = localStorage.getItem("favTeamLogo");

  const [upcomingFixture, setUpcomingFixture] = useState("");
  const [upcomingFixtureTeamHome, setUpcomingFixtureTeamHome] = useState("");
  const [upcomingFixtureTeamOther, setUpcomingFixtureTeamOther] = useState("");
  const [upcomingFixtureTeamFav, setUpcomingFixtureTeamFav] = useState("");
  const [upcomingFixtureName, setUpcomingFixtureName] = useState("");
  const [lastFixtureName, setLastFixtureName] = useState("");
  const [lastFixtureTeam1Name, setLastFixtureTeam1Name] = useState("");
  const [lastFixtureTeam2Name, setLastFixtureTeam2Name] = useState("");
  const [lastFixtureTeam1Score, setLastFixtureTeam1Score] = useState("");
  const [lastFixtureTeam2Score, setLastFixtureTeam2Score] = useState("");
  const [lastFixtureTeam, setLastFixtureTeam] = useState("");
  const [lastTeamHomeLogo, setLastTeamHomeLogo] = useState("");
  const [lastTeamOutLogo, setLastTeamOutLogo] = useState("");
  const [lastFixture, setLastFixture] = useState("");
  const [totalVotes, setTotalVotes] = useState("");
  const [popularFormation, setPopularFormation] = useState("");
  const [currentSLeagueId, setCurrentSLeagueId] = useState("");
  const [slgTeamData, setSlgTeamData] = useState([]);
  const [tVotesNotReadyOpen, setTVotesNotReadyOpen] = useState(false);
  const [seqNotReadyOpen, setSeqNotReadyOpen] = useState("");
  const [seqNotReadyTxt, setSeqNotReadyTxt] = useState("");
  const [coachSeq, setCoachSeq] = useState("");
  const [communitySeq, setCommunitySeq] = useState("");
  const client = useContext(ClientContext);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin`);
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  const {
    loading: loadingCurBucketSlg,
    error: errorCurBucketSlge,
    data: dataCurBucketSlg,
  } = useQuery(CURRENT_LEAGUE_SEASONS_QUERY);

  useEffect(() => {
    if (
      !loadingCurBucketSlg &&
      dataCurBucketSlg &&
      dataCurBucketSlg.currentBucketSleague
    ) {
      setCurrentSLeagueId(dataCurBucketSlg.currentBucketSleague[0].sleagueId.sleagueId);
    }
  }, [dataCurBucketSlg]);

  // Sorting teams based on tie-breaker rules
  // ( first : noPoints, second: noGoalDifference, third: noGoalsFor)
  const sortSleagueTeams = leagueTeams => {
    leagueTeams.sort(
      (a, b) =>
        b.noPoints - a.noPoints ||
        b.noGoalDifference - a.noGoalDifference ||
        b.noGoalsFor - a.noGoalsFor
    );

    return leagueTeams;
  };

  const [sleagueTeamData] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY);

  useEffect(async () => {
    if (currentSLeagueId) {
      const {
        loading: loadingSleagueTeam,
        error: errorSleagueTeam,
        data: dataSleagueTeam,
      } = await sleagueTeamData({
        variables: {
          sleagueId: parseInt(currentSLeagueId),
        },
      });

      if (!loadingSleagueTeam && dataSleagueTeam && dataSleagueTeam.sleagueTeam) {
        // Sorting teams based on tie-breaker rules
        let sortedTeams = sortSleagueTeams(dataSleagueTeam.sleagueTeam);
        setSlgTeamData(sortedTeams);
      }
    }
  }, [currentSLeagueId]);

  const [fetchTopFormation] = useManualQuery(GET_POPULAR_FORMATION_QUERY, {
    fetchPolicy: "network-only",
  });
  const getFormationPopularities = async fixtureTeamId => {
    if (fixtureTeamId != null) {
      const {
        loading: topFromationLoading,
        error: topFromationError,
        data: topFromationData,
      } = await fetchTopFormation({
        variables: {
          fixtureTeamId: parseInt(fixtureTeamId),
        },
      });
      if (!topFromationLoading && topFromationData) {
        setPopularFormation(topFromationData.getTopPopularFormation);
      }
    }
  };

  const {
    loading: upcomingLoading,
    error: upcomingError,
    data: upcomingData,
  } = useQuery(GET_UPCOMING_FIXTURE_QUERY, {
    fetchPolicy: "network-only",
  });
  const {
    loading: lastLoading,
    error: lastError,
    data: lastData,
  } = useQuery(GET_LAST_FIXTURE_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!upcomingLoading && upcomingData && upcomingData.upcomingFixture) {
      setUpcomingFixture(upcomingData.upcomingFixture);
      if (upcomingData.upcomingFixture[0].home === true) {
        setUpcomingFixtureTeamHome(upcomingData.upcomingFixture[0]);
        setUpcomingFixtureTeamOther(upcomingData.upcomingFixture[1]);
      } else {
        setUpcomingFixtureTeamHome(upcomingData.upcomingFixture[1]);
        setUpcomingFixtureTeamOther(upcomingData.upcomingFixture[0]);
      }
      //check to find user favarite team in fixtureTeam
      if (upcomingData.upcomingFixture[0].sleagueTeamId.teamId.name === favTeam)
        setUpcomingFixtureTeamFav(upcomingData.upcomingFixture[0]);
      else setUpcomingFixtureTeamFav(upcomingData.upcomingFixture[1]);
    }
    if (upcomingError) {
      // console.log("error----", upcomingError);
      // if (upcomingError.graphQLErrors[0].message === "Signature has expired" || upcomingError.graphQLErrors[0].message === "You do not have permission to perform this action") {
      //   navigate("/lms/signin");
      // }
    }
  }, [upcomingData]);

  useEffect(() => {
    if (!lastLoading && lastData && lastData.lastFixture) {
      setLastFixture(lastData.lastFixture);

      let team1 = lastData.lastFixture[0].sleagueTeamId.teamId;
      let team2 = lastData.lastFixture[1].sleagueTeamId.teamId;

      let totalVotes = lastData.lastFixture[0].totalVotes;
      let lastFixtureTeamId = lastData.lastFixture[0].fixtureTeamId;
      let coachS = lastData.lastFixture[0].coachSeq;
      let commS = lastData.lastFixture[0].communitySeq;

      if (team2.shortName === favTeam || team2.name === favTeam) {
        totalVotes = lastData.lastFixture[1].totalVotes;
        lastFixtureTeamId = lastData.lastFixture[1].fixtureTeamId;
        let coachS = lastData.lastFixture[1].coachSeq;
        let commS = lastData.lastFixture[1].communitySeq;
      }
      setCommunitySeq(commS);
      setCoachSeq(coachS);
      getFormationPopularities(parseInt(lastFixtureTeamId));
      setTotalVotes(totalVotes);
      setLastFixtureTeam(lastFixtureTeamId);
      if (lastData.lastFixture) {
        if (lastData.lastFixture[0].home) {
          setLastTeamHomeLogo(lastData.lastFixture[0].sleagueTeamId.teamId.logo);
          setLastTeamOutLogo(lastData.lastFixture[1].sleagueTeamId.teamId.logo);
          setLastFixtureTeam1Name(team1.shortName);
          setLastFixtureTeam1Score(
            lastData.lastFixture[0].scoreFinal !== null
              ? lastData.lastFixture[0].scoreFinal
              : ""
          );
          setLastFixtureTeam2Name(team2.shortName);
          setLastFixtureTeam2Score(
            lastData.lastFixture[1].scoreFinal !== null
              ? lastData.lastFixture[1].scoreFinal
              : ""
          );
          // if (lastData.lastFixture[0].scoreFinal !== null)
          //   setLastFixtureName(
          //     team1.shortName +
          //       " " +
          //       lastData.lastFixture[0].scoreFinal +
          //       " - " +
          //       lastData.lastFixture[1].scoreFinal +
          //       " " +
          //       team2.shortName
          //
          //   );
          // else
          //   setLastFixtureName(
          //     team1.shortName + "  " + team2.shortName
          //   );
        } else {
          setLastTeamHomeLogo(lastData.lastFixture[1].sleagueTeamId.teamId.logo);
          setLastTeamOutLogo(lastData.lastFixture[0].sleagueTeamId.teamId.logo);
          setLastFixtureTeam1Name(team2.shortName);
          setLastFixtureTeam1Score(
            lastData.lastFixture[1].scoreFinal !== null
              ? lastData.lastFixture[1].scoreFinal
              : ""
          );
          setLastFixtureTeam2Name(team1.shortName);
          setLastFixtureTeam2Score(
            lastData.lastFixture[0].scoreFinal !== null
              ? lastData.lastFixture[0].scoreFinal
              : ""
          );
          // if (lastData.lastFixture[1].scoreFinal)
          //   setLastFixtureName(
          //     team2.shortName +
          //       " " +
          //       lastData.lastFixture[1].scoreFinal +
          //       " - " +
          //       lastData.lastFixture[0].scoreFinal +
          //       " " +
          //       team1.shortName
          //   );
          // else
          //   setLastFixtureName(
          //     team2.shortName + " - " + team1.shortName
          //   );
        }
      }
    }
    if (lastError) {
    }
  }, [lastData]);

  const rows = slgTeamData.slice(0, 6).map((sleagueTeam, index) => {
    return {
      id: index,
      position: index + 1,
      club: sleagueTeam.teamId.abbrName,
      logo: mediaPath + sleagueTeam.teamId.logo,
      played: sleagueTeam.noGames ? sleagueTeam.noGames : 0,
      won: sleagueTeam.noWins ? sleagueTeam.noWins : 0,
      drown: sleagueTeam.noDraws ? sleagueTeam.noDraws : 0,
      lost: sleagueTeam.noLosses ? sleagueTeam.noLosses : 0,
      gf: sleagueTeam.noGoalsFor ? sleagueTeam.noGoalsFor : 0,
      ga: sleagueTeam.noGoalsAgainst ? sleagueTeam.noGoalsAgainst : 0,
      gd:
        sleagueTeam.noGoalDifference > 0
          ? "+" + sleagueTeam.noGoalDifference
          : sleagueTeam.noGoalDifference
          ? sleagueTeam.noGoalDifference
          : 0,
      points: sleagueTeam.noPoints ? sleagueTeam.noPoints : 0,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "position",
      headerName: "Pos",
      width: 35,
      minWidth: tabletView ? 55 : 45,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "left",
      align: "left",
      flex: 0,
      sortable: false,
      description: "Position",
    },
    {
      field: "club",
      headerName: "Club",
      width: tabletView ? 105 : 85,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "left",
      align: "left",
      flex: 0,
      renderCell: params => {
        if (params.row.club && params.row.logo) {
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={params.row.logo} className={classes.logoMobileStyle} />
              <Box mt={1}>
                <Typography variant={tabletView ? "h3" : "h6"}>
                  {params.row.club}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
    },
    {
      field: "played",
      headerName: "Pl",
      width: 30,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Playes",
    },
    {
      field: "won",
      headerName: "W",
      width: 30,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Wins",
    },
    {
      field: "drown",
      headerName: "D",
      width: 37,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Draws",
    },
    {
      field: "lost",
      headerName: "L",
      width: 30,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Losses",
    },
    {
      field: "gf",
      headerName: "GF",
      width: 0,
      minWidth: 0,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      hide: true,
      sortable: false,
      description: "Goals For",
    },
    {
      field: "ga",
      headerName: "GA",
      width: 0,
      minWidth: 0,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      hide: true,
      sortable: false,
      description: "Goals Against",
    },
    {
      field: "gd",
      headerName: "GD",
      width: 35,
      minWidth: 15,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Goal Difference",
    },
    {
      field: "points",
      headerName: "Pts",
      width: 45,
      minWidth: 10,
      editable: false,
      headerClassName: tabletView
        ? "super-app-theme--header"
        : "super-app-theme--header--mobile",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      description: "Points",
    },
  ];

  const tVotesNotReadyModalOn = () => {
    setTVotesNotReadyOpen(true);
  };
  const tVotesNotReadyModalOff = () => {
    setTVotesNotReadyOpen(false);
  };

  const seqNotReadyModalOpen = () => {
    if (!coachSeq && communitySeq) {
      setSeqNotReadyTxt("The Coach Team will be available shortly.");
    } else if (coachSeq && !communitySeq) {
      setSeqNotReadyTxt("The Community Team will be available shortly.");
    } else {
      setSeqNotReadyTxt("The Coach and The Community Teams will be available shortly.");
    }
    setSeqNotReadyOpen(true);
  };
  const seqNotReadyModalClose = () => {
    setSeqNotReadyOpen(false);
  };
  console.log("lastFixtureTeam______", lastFixtureTeam);
  return (
    <>
      <Helmet>
        <title>Predictal | Dashboard</title>
      </Helmet>
      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardHeader
                  className={classes.dateStyleMobile}
                  avatar={<CalendarTodayIcon sx={{ color: "#643d74" }} />}
                  title={getDayString("Y", new Date(), true)}
                />
              </Card>
            </Grid>

            {lastFixture === "" ? (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContentMobile}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">Last Fixture Results</Typography>
                    </Box>

                    <Box mt={2} mb={2} style={{ display: "flex", flexDirection: "row" }}>
                      <Box mr={1} m="auto" style={{ marginBottom: 15 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 16 }}
                        >
                          No info is available at this time. You are just starting the new
                          league season. Select your Starting XI team for Week-01.
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />

                    <Box style={{ marginTop: 30, textAlign: "center" }}>
                      <Box mr={1} style={{ marginBottom: 23 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 16 }}
                        >
                          Your favourite team is:
                        </Typography>
                      </Box>

                      <Box
                        mr={1}
                        style={{ textAlign: "center", display: "flex" }}
                        justifyContent="center"
                      >
                        <Box style={{ paddingTop: 17, paddingRight: 10 }}>
                          <Typography variant="h2">{favTeam}</Typography>
                        </Box>
                        <img
                          src={favTeamLogo && mediaPath + favTeamLogo}
                          height={48}
                          width={48}
                        />
                      </Box>

                      <Box mr={1} style={{ paddingTop: 25 }}>
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 235 }}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            navigate(
                              `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                            );
                          }}
                        >
                          Create Your Starting XI Team
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContentMobile}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2" style={{ fontSize: 18 }}>
                        Last Fixture Results
                      </Typography>
                      <Box
                        mt={2}
                        mb={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Box mr={1}>
                          <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Name}
                          </Typography>
                        </Box>
                        <img
                          src={lastTeamHomeLogo && mediaPath + lastTeamHomeLogo}
                          height={24}
                          width={24}
                          style={{ marginRight: 5 }}
                        />
                        <Box
                          ml={1}
                          mr={1}
                          style={{
                            width: 56,
                            height: 28,
                            backgroundColor: "#E0E0E0",
                            borderRadius: 4,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Score + " - "}
                            {lastFixtureTeam2Score}
                          </Typography>
                        </Box>
                        <img
                          src={lastTeamOutLogo && mediaPath + lastTeamOutLogo}
                          height={24}
                          width={24}
                          style={{ marginRight: 5 }}
                        />
                        <Box mr={1}>
                          <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam2Name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      mt={4}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <CalendarTodayIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 18 }}
                      />
                      <Typography
                        variant="h5"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          getDayString(
                            "Y",
                            lastFixture[0].fixtureId.fixtureWeek.scheduledDate,
                            true
                          )}
                      </Typography>
                    </Box>
                    <Box
                      mt={1}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <StadiumIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 18 }}
                      />
                      <Typography
                        variant="h5"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          (lastFixture[0].home
                            ? lastFixture[0].sleagueTeamId.teamId.venueName
                            : lastFixture[1].sleagueTeamId.teamId.venueName)}
                      </Typography>
                    </Box>
                    <Box mt={1} mb={4} style={{ display: "flex", flexDirection: "row" }}>
                      <HowToVoteIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 18 }}
                      />
                      <Typography
                        variant="h5"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        Total Votes : {totalVotes}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      mt={2}
                      mb={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{ color: "#757575", lineHeight: 1.8 }}
                      >
                        Popular Formation:{" "}
                        {popularFormation &&
                        popularFormation.formationLeagueId.formationId.title
                          ? popularFormation.formationLeagueId.formationId.title
                          : "None "}
                        <br />{" "}
                        {popularFormation && popularFormation.votes
                          ? `[Votes: ${popularFormation.votes} (${popularFormation.percentage}%)]`
                          : ""}
                      </Typography>
                      <Box>
                        {popularFormation && popularFormation.votes ? (
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 110 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/ts/votes/${lastFixtureTeam}`);
                            }}
                          >
                            Total Votes
                          </Button>
                        ) : (
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 110 }}
                            color="primary"
                            variant="contained"
                            onClick={tVotesNotReadyModalOn}
                          >
                            Total Votes
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      mt={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {coachSeq && communitySeq ? (
                        <Button
                          className={classes.outlinedButtonStyle}
                          style={{ width: 175 }}
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            navigate(`/${project_url}/ts/3teams/${lastFixtureTeam}`);
                          }}
                        >
                          Community/Coach
                        </Button>
                      ) : (
                        <>
                          <Button
                            className={classes.outlinedButtonStyle}
                            style={{ width: 175 }}
                            color="primary"
                            variant="outlined"
                            onClick={seqNotReadyModalOpen}
                          >
                            Community/Coach
                          </Button>
                        </>
                      )}

                      <Button
                        className={classes.outlinedButtonStyle}
                        style={{ width: 135 }}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          navigate(`/${project_url}/ts/myteam/${lastFixtureTeam}`);
                        }}
                      >
                        My Team
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent
                  className={classes.cardContentMobile}
                  style={{ height: 330 }}
                >
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Upcoming Fixture</Typography>
                    <Box
                      mt={2}
                      mb={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box mr={1}>
                        <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                          {/*upcomingFixture && upcomingFixture[0].sleagueTeamId.teamId.name} - {upcomingFixture && upcomingFixture[1].sleagueTeamId.teamId.name*/}
                          {upcomingFixture &&
                            upcomingFixtureTeamHome &&
                            upcomingFixtureTeamHome.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                      <img
                        src={
                          upcomingFixtureTeamHome &&
                          mediaPath + upcomingFixtureTeamHome.sleagueTeamId.teamId.logo
                        }
                        height={24}
                        width={24}
                        style={{ marginRight: 5 }}
                      />
                      <Box
                        ml={1}
                        mr={1}
                        style={{
                          width: 56,
                          height: 28,
                          backgroundColor: "#E0E0E0",
                          borderRadius: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                          -
                        </Typography>
                      </Box>

                      <img
                        src={
                          upcomingFixtureTeamOther &&
                          mediaPath + upcomingFixtureTeamOther.sleagueTeamId.teamId.logo
                        }
                        height={24}
                        width={24}
                        style={{ marginRight: 5 }}
                      />
                      <Box mr={1}>
                        <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                          {upcomingFixture &&
                            upcomingFixtureTeamOther &&
                            upcomingFixtureTeamOther.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box mt={4} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    {upcomingFixture &&
                    upcomingFixture[0] &&
                    upcomingFixture[0].fixtureId.delayed ? (
                      <Tooltip title="The fixture has been delayed">
                        <Icon aria-label="delayed" style={{ marginRight: 16, width: 20 }}>
                          <img src={delayedIcon} width={20} />
                        </Icon>
                      </Tooltip>
                    ) : (
                      <CalendarTodayIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 18 }}
                      />
                    )}
                    <Typography variant="h5" style={{ color: "#757575", paddingTop: 2 }}>
                      {upcomingFixture &&
                        getDayString(
                          "Y",
                          upcomingFixture[0].fixtureId.fixtureWeek.scheduledDate,
                          true
                        )}
                    </Typography>
                  </Box>
                  <Box mt={1} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    <StadiumIcon
                      sx={{ color: "#757575", marginRight: 2, fontSize: 18 }}
                    />
                    <Typography variant="h5" style={{ color: "#757575", paddingTop: 2 }}>
                      {upcomingFixture &&
                        (upcomingFixture[0].home
                          ? upcomingFixture[0].sleagueTeamId.teamId.venueName
                          : upcomingFixture[1].sleagueTeamId.teamId.venueName)}
                    </Typography>
                  </Box>
                  <Box mt={2} style={{ textAlign: "center" }}>
                    <Button
                      className={classes.buttonStyle}
                      style={{ width: 328 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate(
                          `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                        );
                      }}
                    >
                      Create Your Starting XI Team
                    </Button>
                  </Box>
                  <Box mt={2} style={{ textAlign: "center" }}>
                    <Button
                      className={classes.textButtonStyle}
                      style={{ marginLeft: 7 }}
                      disableRipple={true}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        navigate(`/${project_url}/fixtures`);
                      }}
                    >
                      More Fixtures
                    </Button>
                  </Box>
                </CardContent>
              </Card>
              <Grid container spacing={2}>
                <Grid item lg={4} md={6} xl={3} xs={6}>
                  <Card className={classes.cardScore}>
                    <CardContent className={classes.cardContentMobile}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          You're earning Sport Points.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.spLedgerButtonStyle}
                            style={{ width: 139, height: 32 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/sp-ledger`);
                            }}
                          >
                            Your PSP Ledger
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} md={6} xl={3} xs={6}>
                  <Card className={classes.cardScore}>
                    <CardContent
                      className={classes.cardContentMobile}
                      style={{ paddingLeft: 5, paddingRight: 0 }}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          See Community's PSP Board.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.scoreButtonStyle}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/${project_url}/sp-board`);
                            }}
                          >
                            PSP Leaderboard
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent
                  className={classes.cardContentMobile}
                  style={{ paddingLeft: 1 }}
                >
                  <Box mb={2} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Premier League Table</Typography>
                  </Box>
                  <Box
                    m="auto"
                    className={classes.dataGridMobileStyle}
                    sx={{ height: 290, width: 365 }}
                  >
                    <DataGrid
                      rowHeight={42}
                      rows={rows}
                      headerHeight={36}
                      columns={columns}
                      getRowClassName={params => `style--${params.row.id % 2 == 0}`}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          padding: "5px",
                        },
                      }}
                      hideFooter
                      disableColumnMenu
                    />
                  </Box>
                  <Box
                    mt={3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        navigate(`/${project_url}/table`);
                      }}
                    >
                      View Full Table
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardHeader
                  className={classes.dateStyle}
                  avatar={<CalendarTodayIcon sx={{ color: "#643d74" }} />}
                  title={getDayString("Y", new Date(), true)}
                />
              </Card>
            </Grid>

            {lastFixture === "" ? (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContentTablet}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">Last Fixture Results</Typography>
                    </Box>

                    <Box mt={2} mb={2} style={{ display: "flex", flexDirection: "row" }}>
                      <Box mr={1} m="auto" style={{ marginBottom: 15 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 18 }}
                        >
                          No info is available at this time. You are just starting the new
                          league season. Select your Starting XI team for Week-01.
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />

                    <Box style={{ marginTop: 30, textAlign: "center" }}>
                      <Box mr={1} style={{ marginBottom: 23 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 18 }}
                        >
                          Your favourite team is:
                        </Typography>
                      </Box>

                      <Box
                        mr={1}
                        style={{ textAlign: "center", display: "flex" }}
                        justifyContent="center"
                      >
                        <Box style={{ paddingTop: 17, paddingRight: 10 }}>
                          <Typography variant="h2">{favTeam}</Typography>
                        </Box>
                        <img
                          src={favTeamLogo && mediaPath + favTeamLogo}
                          height={56}
                          width={56}
                        />
                      </Box>

                      <Box mr={1} style={{ paddingTop: 25 }}>
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 235 }}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            navigate(
                              `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                            );
                          }}
                        >
                          Create Your Starting XI Team
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContentTablet}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">Last Fixture Results</Typography>
                      <Box
                        mt={2}
                        mb={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Box mr={1}>
                          <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Name}
                          </Typography>
                        </Box>
                        <img
                          src={lastTeamHomeLogo && mediaPath + lastTeamHomeLogo}
                          height={32}
                          width={32}
                          style={{ marginRight: 5 }}
                        />
                        <Box
                          ml={1}
                          mr={1}
                          mt={0.5}
                          style={{
                            width: 56,
                            height: 28,
                            backgroundColor: "#E0E0E0",
                            borderRadius: 4,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Score + " - "}
                            {lastFixtureTeam2Score}
                          </Typography>
                        </Box>

                        <img
                          src={lastTeamOutLogo && mediaPath + lastTeamOutLogo}
                          height={32}
                          width={32}
                          style={{ marginRight: 5 }}
                        />
                        <Box mr={1}>
                          <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam2Name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      mt={4}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <CalendarTodayIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          getDayString(
                            "Y",
                            lastFixture[0].fixtureId.fixtureWeek.scheduledDate,
                            true
                          )}
                      </Typography>
                    </Box>
                    <Box
                      mt={1}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <StadiumIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          (lastFixture[0].home
                            ? lastFixture[0].sleagueTeamId.teamId.venueName
                            : lastFixture[1].sleagueTeamId.teamId.venueName)}
                      </Typography>
                    </Box>
                    <Box mt={1} mb={4} style={{ display: "flex", flexDirection: "row" }}>
                      <HowToVoteIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        Total Votes : {totalVotes}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box mt={4} mb={1}>
                      <Typography variant="h5" style={{ color: "#757575" }}>
                        Popular Formation:{" "}
                        {popularFormation &&
                        popularFormation.formationLeagueId.formationId.title
                          ? popularFormation.formationLeagueId.formationId.title
                          : "None "}{" "}
                        &nbsp;{" "}
                        {popularFormation && popularFormation.votes
                          ? ` [Votes: ${popularFormation.votes} (${popularFormation.percentage}%)]`
                          : ""}
                      </Typography>
                      <Box mt={4} style={{ textAlign: "center" }}>
                        {popularFormation && popularFormation.votes ? (
                          <Button
                            className={classes.buttonStyle}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/ts/votes/${lastFixtureTeam}`);
                            }}
                          >
                            Total Votes
                          </Button>
                        ) : (
                          <Button
                            className={classes.buttonStyle}
                            color="primary"
                            variant="contained"
                            onClick={tVotesNotReadyModalOn}
                          >
                            Total Votes
                          </Button>
                        )}
                        {coachSeq && communitySeq ? (
                          <Button
                            className={classes.textButtonStyle}
                            style={{ marginRight: 16, marginLeft: 16 }}
                            disableRipple={true}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              navigate(`/${project_url}/ts/3teams/${lastFixtureTeam}`);
                            }}
                          >
                            Community/Coach
                          </Button>
                        ) : (
                          <>
                            <Button
                              className={classes.textButtonStyle}
                              style={{ marginRight: 16, marginLeft: 16 }}
                              disableRipple={true}
                              color="primary"
                              variant="text"
                              onClick={seqNotReadyModalOpen}
                            >
                              Community/Coach
                            </Button>
                          </>
                        )}

                        <Button
                          className={classes.textButtonStyle}
                          disableRipple={true}
                          color="primary"
                          variant="text"
                          onClick={() => {
                            navigate(`/${project_url}/ts/myteam/${lastFixtureTeam}`);
                          }}
                        >
                          My Team
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentTablet}>
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Upcoming Fixture</Typography>
                    <Box
                      mt={2}
                      mb={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box mr={1}>
                        <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                          {/*upcomingFixture && upcomingFixture[0].sleagueTeamId.teamId.name} - {upcomingFixture && upcomingFixture[1].sleagueTeamId.teamId.name*/}
                          {upcomingFixture &&
                            upcomingFixtureTeamHome &&
                            upcomingFixtureTeamHome.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                      <img
                        src={
                          upcomingFixtureTeamHome &&
                          mediaPath + upcomingFixtureTeamHome.sleagueTeamId.teamId.logo
                        }
                        height={32}
                        width={32}
                        style={{ marginRight: 5 }}
                      />
                      <Box
                        ml={1}
                        mr={1}
                        mt={0.5}
                        style={{
                          width: 56,
                          height: 28,
                          backgroundColor: "#E0E0E0",
                          borderRadius: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                          -
                        </Typography>
                      </Box>

                      <img
                        src={
                          upcomingFixtureTeamOther &&
                          mediaPath + upcomingFixtureTeamOther.sleagueTeamId.teamId.logo
                        }
                        height={32}
                        width={32}
                        style={{ marginRight: 5 }}
                      />
                      <Box mr={1}>
                        <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                          {upcomingFixture &&
                            upcomingFixtureTeamOther &&
                            upcomingFixtureTeamOther.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box mt={4} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    {upcomingFixture &&
                    upcomingFixture[0] &&
                    upcomingFixture[0].fixtureId.delayed ? (
                      <Tooltip title="The fixture has been delayed">
                        <Icon aria-label="delayed" style={{ marginRight: 16, width: 20 }}>
                          <img src={delayedIcon} width={20} />
                        </Icon>
                      </Tooltip>
                    ) : (
                      <CalendarTodayIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                    )}
                    <Typography variant="h3" style={{ color: "#757575", paddingTop: 2 }}>
                      {upcomingFixture &&
                        getDayString(
                          "Y",
                          upcomingFixture[0].fixtureId.fixtureWeek.scheduledDate,
                          true
                        )}
                    </Typography>
                  </Box>
                  <Box mt={1} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    <StadiumIcon
                      sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                    />
                    <Typography variant="h3" style={{ color: "#757575", paddingTop: 2 }}>
                      {upcomingFixture &&
                        (upcomingFixture[0].home
                          ? upcomingFixture[0].sleagueTeamId.teamId.venueName
                          : upcomingFixture[1].sleagueTeamId.teamId.venueName)}
                    </Typography>
                  </Box>

                  <Box mt={2} mb={1} style={{ textAlign: "center" }}>
                    <Button
                      className={classes.buttonStyle}
                      style={{ width: 235 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate(
                          `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                        );
                      }}
                    >
                      Create Your Starting XI Team
                    </Button>

                    <Button
                      className={classes.textButtonStyle}
                      style={{ marginLeft: 16 }}
                      disableRipple={true}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        navigate(`/${project_url}/fixtures`);
                      }}
                    >
                      More Fixtures
                    </Button>
                  </Box>
                </CardContent>
              </Card>
              <Grid container spacing={2}>
                <Grid item lg={4} md={3} xl={3} xs={12}>
                  <Card>
                    <CardContent className={classes.cardContentTablet}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          You're earning Sport Points.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 139, height: 32 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/sp-ledger`);
                            }}
                          >
                            Your PSP Ledger
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} md={3} xl={3} xs={12}>
                  <Card className={classes.cardScore}>
                    <CardContent
                      className={classes.cardContentTablet}
                      style={{ paddingLeft: 5, paddingRight: 0 }}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          See Community's PSP Leaderboard.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.scoreButtonStyle}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/${project_url}/sp-board`);
                            }}
                          >
                            PSP Leaderboard
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentTablet}>
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Premier League Table</Typography>
                  </Box>
                  <Box
                    mt={2}
                    mb={0}
                    className={classes.dataGridStyle}
                    sx={{ height: 400, width: 608 }}
                  >
                    <DataGrid
                      autoHeight
                      rows={rows}
                      columns={columns}
                      getRowClassName={params => `style--${params.row.id % 2 == 0}`}
                      hideFooter
                      disableColumnMenu
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        navigate(`/${project_url}/table`);
                      }}
                    >
                      View Full Table
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardHeader
                  className={classes.dateStyle}
                  avatar={<CalendarTodayIcon sx={{ color: "#643d74" }} />}
                  title={getDayString("Y", new Date(), true)}
                />
              </Card>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12} />
            <Grid item lg={4} md={6} xl={3} xs={12} />
          </Grid>
          <Grid container spacing={3}>
            {lastFixture === "" ? (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">Last Fixture Results</Typography>
                    </Box>

                    <Box mt={2} mb={2} style={{ display: "flex", flexDirection: "row" }}>
                      <Box mr={1} m="auto" style={{ marginBottom: 15 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 18 }}
                        >
                          No info is available at this time. You are just starting the new
                          league season. Select your Starting XI team for Week-01.
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />

                    <Box style={{ marginTop: 30, textAlign: "center" }}>
                      <Box mr={1} style={{ marginBottom: 23 }}>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: 1.7, fontSize: 18 }}
                        >
                          Your favourite team is:
                        </Typography>
                      </Box>

                      <Box
                        mr={1}
                        style={{ textAlign: "center", display: "flex" }}
                        justifyContent="center"
                      >
                        <Box style={{ paddingTop: 17, paddingRight: 10 }}>
                          <Typography variant="h2">{favTeam}</Typography>
                        </Box>
                        <img
                          src={favTeamLogo && mediaPath + favTeamLogo}
                          height={56}
                          width={56}
                        />
                      </Box>

                      <Box mr={1} style={{ paddingTop: 48 }}>
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 235 }}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            navigate(
                              `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                            );
                          }}
                        >
                          Create Your Starting XI Team
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Box style={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">Last Fixture Results</Typography>

                      <Box
                        mt={2}
                        mb={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Box mr={1}>
                          <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Name}
                          </Typography>
                        </Box>
                        <img
                          src={lastTeamHomeLogo && mediaPath + lastTeamHomeLogo}
                          height={32}
                          width={32}
                          style={{ marginRight: 5 }}
                        />
                        <Box
                          ml={1}
                          mr={1}
                          mt={0.5}
                          style={{
                            width: 56,
                            height: 28,
                            backgroundColor: "#E0E0E0",
                            borderRadius: 4,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam1Score + " - "}
                            {lastFixtureTeam2Score}
                          </Typography>
                        </Box>

                        <img
                          src={lastTeamOutLogo && mediaPath + lastTeamOutLogo}
                          height={32}
                          width={32}
                          style={{ marginRight: 5 }}
                        />
                        <Box mr={1}>
                          <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                            {lastFixtureTeam2Name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      mt={4}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <CalendarTodayIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          getDayString(
                            "Y",
                            lastFixture[0].fixtureId.fixtureWeek.scheduledDate,
                            true
                          )}
                      </Typography>
                    </Box>
                    <Box
                      mt={1}
                      mb={1.5}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <StadiumIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        {lastFixture &&
                          (lastFixture[0].home
                            ? lastFixture[0].sleagueTeamId.teamId.venueName
                            : lastFixture[1].sleagueTeamId.teamId.venueName)}
                      </Typography>
                    </Box>
                    <Box mt={1} mb={2} style={{ display: "flex", flexDirection: "row" }}>
                      <HowToVoteIcon
                        sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                      />
                      <Typography
                        variant="h3"
                        style={{ color: "#757575", paddingTop: 2 }}
                      >
                        Total Votes : {totalVotes}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box mt={4} mb={3.25}>
                      <Typography variant="h5" style={{ color: "#757575" }}>
                        Popular Formation:{" "}
                        {popularFormation &&
                        popularFormation.formationLeagueId.formationId.title
                          ? popularFormation.formationLeagueId.formationId.title
                          : "None "}{" "}
                        &nbsp;{" "}
                        {popularFormation && popularFormation.votes
                          ? `  [Votes: ${popularFormation.votes} (${popularFormation.percentage}%)]`
                          : ""}
                      </Typography>
                      <Box mt={4} style={{ display: "flex", flexDirection: "row" }}>
                        {popularFormation && popularFormation.votes ? (
                          <Button
                            className={classes.buttonStyle}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/ts/votes/${lastFixtureTeam}`);
                            }}
                          >
                            Total Votes
                          </Button>
                        ) : (
                          <Button
                            className={classes.buttonStyle}
                            color="primary"
                            variant="contained"
                            onClick={tVotesNotReadyModalOn}
                          >
                            Total Votes
                          </Button>
                        )}
                        {coachSeq && communitySeq ? (
                          <Button
                            className={classes.textButtonStyle}
                            style={{ marginRight: 10 }}
                            disableRipple={true}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              navigate(`/${project_url}/ts/3teams/${lastFixtureTeam}`);
                            }}
                          >
                            Community/Coach
                          </Button>
                        ) : (
                          <>
                            <Button
                              className={classes.textButtonStyle}
                              disableRipple={true}
                              color="primary"
                              variant="text"
                              onClick={seqNotReadyModalOpen}
                            >
                              Community/Coach
                            </Button>
                          </>
                        )}
                        <Button
                          className={classes.textButtonStyle}
                          disableRipple={true}
                          color="primary"
                          variant="text"
                          onClick={() => {
                            navigate(`/${project_url}/ts/myteam/${lastFixtureTeam}`);
                          }}
                        >
                          My Team
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Upcoming Fixture</Typography>
                    <Box
                      mt={2}
                      mb={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box mr={1}>
                        <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                          {/*upcomingFixture && upcomingFixture[0].sleagueTeamId.teamId.name} - {upcomingFixture && upcomingFixture[1].sleagueTeamId.teamId.name*/}
                          {upcomingFixture &&
                            upcomingFixtureTeamHome &&
                            upcomingFixtureTeamHome.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                      <img
                        src={
                          upcomingFixtureTeamHome &&
                          mediaPath + upcomingFixtureTeamHome.sleagueTeamId.teamId.logo
                        }
                        height={32}
                        width={32}
                        style={{ marginRight: 5 }}
                      />
                      <Box
                        ml={1}
                        mr={1}
                        mt={0.5}
                        style={{
                          width: 56,
                          height: 28,
                          backgroundColor: "#E0E0E0",
                          borderRadius: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h3" style={{ lineHeight: 1.7 }}>
                          -
                        </Typography>
                      </Box>

                      <img
                        src={
                          upcomingFixtureTeamOther &&
                          mediaPath + upcomingFixtureTeamOther.sleagueTeamId.teamId.logo
                        }
                        height={32}
                        width={32}
                        style={{ marginRight: 5 }}
                      />
                      <Box mr={1}>
                        <Typography variant="body1" style={{ lineHeight: 1.7 }}>
                          {upcomingFixture &&
                            upcomingFixtureTeamOther &&
                            upcomingFixtureTeamOther.sleagueTeamId.teamId.shortName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box mt={4} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    {upcomingFixture &&
                    upcomingFixture[0] &&
                    upcomingFixture[0].fixtureId.delayed ? (
                      <>
                        <Tooltip title="The fixture has been delayed">
                          <Icon
                            aria-label="delayed"
                            style={{ marginRight: 16, width: 20 }}
                          >
                            <img src={delayedIcon} width={20} />
                          </Icon>
                        </Tooltip>

                        <Typography
                          variant="h3"
                          style={{ color: "#757575", paddingTop: 2 }}
                        >
                          {upcomingFixture &&
                            getDayString(
                              "Y",
                              upcomingFixture[0].fixtureId.fixtureWeek.scheduledDate,
                              true
                            )}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CalendarTodayIcon
                          sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                        />
                        <Typography
                          variant="h3"
                          style={{ color: "#757575", paddingTop: 2 }}
                        >
                          {upcomingFixture &&
                            getDayString(
                              "Y",
                              upcomingFixture[0].fixtureId.fixtureWeek.scheduledDate,
                              true
                            )}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box mt={1} mb={1.5} style={{ display: "flex", flexDirection: "row" }}>
                    <StadiumIcon
                      sx={{ color: "#757575", marginRight: 2, fontSize: 20 }}
                    />
                    <Typography variant="h3" style={{ color: "#757575", paddingTop: 2 }}>
                      {upcomingFixture &&
                        (upcomingFixture[0].home
                          ? upcomingFixture[0].sleagueTeamId.teamId.venueName
                          : upcomingFixture[1].sleagueTeamId.teamId.venueName)}
                    </Typography>
                  </Box>

                  <Box mt={2} style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      className={classes.buttonStyle}
                      style={{ width: 235 }}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        navigate(
                          `/${project_url}/ts/myteam/${upcomingFixtureTeamFav.fixtureTeamId}`
                        );
                      }}
                    >
                      Create Your Starting XI Team
                    </Button>

                    <Button
                      className={classes.textButtonStyle}
                      style={{ marginLeft: 7 }}
                      disableRipple={true}
                      color="primary"
                      variant="text"
                      onClick={() => {
                        navigate(`/${project_url}/fixtures`);
                      }}
                    >
                      More Fixtures
                    </Button>
                  </Box>
                </CardContent>
              </Card>

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xl={3} xs={12}>
                  <Card className={classes.cardScore}>
                    <CardContent
                      className={classes.cardContent}
                      style={{ paddingLeft: 5, paddingRight: 0 }}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          You're earning Sport Points.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.spLedgerButtonStyle}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(`/${project_url}/sp-ledger`);
                            }}
                          >
                            Your PSP Ledger
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} md={6} xl={3} xs={12}>
                  <Card className={classes.cardScore}>
                    <CardContent
                      className={classes.cardContent}
                      style={{ paddingLeft: 5, paddingRight: 0 }}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                          See Community's PSP Board.
                        </Typography>
                        <Box mt={2}>
                          <Button
                            className={classes.scoreButtonStyle}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/${project_url}/sp-board`);
                            }}
                          >
                            PSP Leaderboard
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box mb={2} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Premier League Table</Typography>
                  </Box>
                  <Box
                    m="auto"
                    className={classes.dataGridMobileStyle}
                    sx={{ height: 290, width: 372 }}
                  >
                    <DataGrid
                      rowHeight={42}
                      rows={rows}
                      headerHeight={36}
                      columns={columns}
                      getRowClassName={params => `style--${params.row.id % 2 == 0}`}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          padding: "5px",
                        },
                      }}
                      hideFooter
                      disableColumnMenu
                    />
                  </Box>
                  <Box
                    mt={2.25}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        navigate(`/${project_url}/table`);
                      }}
                    >
                      View Full Table
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {tVotesNotReadyOpen && (
        <AlertDialog
          title=""
          closeDialog={tVotesNotReadyModalOff}
          contentMsg="The Votes data for the Community Team will be available shortly."
        />
      )}
      {seqNotReadyOpen && (
        <AlertDialog
          title=""
          closeDialog={seqNotReadyModalClose}
          contentMsg={seqNotReadyTxt}
        />
      )}
    </>
  );
};

export default Dashboard;
