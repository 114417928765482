const CreateTeam_mutate = `
mutation createTeam(
    $name: String!,
    $short_name: String!,
    $abbr_name: String!,
    $nick_name: String!,
    $venue_name: String!,
    $code: String!,
    $found_year: String!,
    $accept_year: String!,
    $logo: Upload,)
{
  createTeam(
      name: $name,
      shortName: $short_name,
      abbrName:$abbr_name,
      nickName:$nick_name,
      venueName:$venue_name,
      code:$code,
      foundYear:$found_year,
      acceptYear:$accept_year,
      logo: $logo,)
  {
      name
      shortName
      abbrName
      nickName
      venueName
      foundYear
      acceptYear
    }
}
`;
const GetAllTeam_query = `
    query{
        team {
            teamId
            name
            shortName
            abbrName
            nickName
            venueName
            code
            logo
        }
    }
`;
const Team_query = `query teamData($teamId:Int!){
    teamData(teamId:$teamId){
        teamId
        name
        shortName
        abbrName
        nickName
        venueName
        logo
        code
        foundYear
        acceptYear
    }
}`;
const UpdateTeam_mutate = `mutation updateTeam(
    $teamId: Int!,
    $name: String!,
    $shortName: String!,
    $abbrName: String!,
    $nickName: String!,
    $venueName: String!,
    $code: String!,
    $foundYear: String!,
    $acceptYear: String!,
    $logo: Upload) {
        updateTeam(
            teamId: $teamId,
            name: $name,
            shortName: $shortName,
            abbrName:$abbrName,
            nickName:$nickName,
            venueName:$venueName,
            code:$code,
            foundYear:$foundYear,
            acceptYear:$acceptYear,
            logo:$logo
            ) {
                team {
                    teamId
                    name
                    shortName
                    abbrName
                    nickName
                    venueName
                    foundYear
                    acceptYear
                }
            }
    }
`;

module.exports = {
  CREATE_TEAM_MUTATION: CreateTeam_mutate,
  GET_ALL_TEAM_QUERY: GetAllTeam_query,
  GET_TEAM_QURTY: Team_query,
  UPDATE_TEAM_MUTATION: UpdateTeam_mutate,
};
