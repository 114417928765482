import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import {project_url, admin_url} from "../store/data/Constants";

import {
  CREATE_SLEAGUE_MUTATE,
  GET_ALL_SLEAGUES_QUERY
} from "../graphQL/sleague_qry";
import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Fixture Player Stats
## Description : Submit form data and Add Game Stats for a Player in a Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function Sleague() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const radioOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false }
  ];
  //const { control } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [leaguesData, setLeaguesData] = useState([]);
  const [fixtureData, setFixtureData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [allSleagues, setSleagues] = useState([]);
  const navigate = useNavigate();
  const [createSleague] = useMutation(CREATE_SLEAGUE_MUTATE, {
    refetchQueries: [{ query: GET_ALL_SLEAGUES_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.sleague) {
        setSleagues(data.sleague);
        console.log("allSleaguesData----", data);
      }
      if (!leagueLoading && leagueData && leagueData.league) {
        setSleagues(leagueData.sleague);
        console.log("All Leagues----", leagueData);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, leagueData]
  );

  useEffect(
    () => {
      if (!leagueLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
        console.log("allLeaguesData----", leagueData);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );
  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(500);

    let start_date = formData.startedDate.toISOString();
    //console.log(start_date)
    let end_date = formData.endDate.toISOString();
    let end_date_final = formData.endDate.toISOString();
    const { data, error } = await createSleague({
      variables: {
        leagueId: parseInt(formData.leagueId),
        title: formData.title,
        seasonName: formData.seasonName,
        startDate: start_date,
        endDate: end_date,
        endDateFinal: end_date_final
      }
    });
    if (error) {
      console.log("Error", error, data);
      setFailedMessage("Unfortunately an error occurred.");
      
    } else if (data && data.createSleague) {

      setSuccessMessage("New League Season added successfully.");
      setTimeout("");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/sleague`);
      }, 2000);
    }
  };

  //console.log(errors);
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Season League | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add a Season League for League</h2>
          <select
            className={adminStyle.select}
            id="leagueId"
            {...register("leagueId", { required: true })}
          >
            <option>League</option>
            {leaguesData.map((league, i) => (
              <option key={i} value={league.leagueId}>
                {league.title}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="title">
            Title{" "}
          </label>
          <input
            className={adminStyle.input}
            id="title"
            {...register("title", { required: true })}
          />
          {errors.title && errors.title.type === "required" && (
            <span>Required</span>
          )}
          <label className={adminStyle.label} htmlFor="seasonName">
            Season Name
          </label>
          <input
            className={adminStyle.input}
            id="seasonName"
            {...register("seasonName", { required: true })}
          />
          {errors.seasonName && errors.seasonName.type === "required" && (
            <span>Required</span>
          )}

          <div>
            <label className={adminStyle.label} htmlFor="startedDate">
              Start Date
            </label>
            <Controller
              control={control}
              name="startedDate"
              id="startedDate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </div>
          <div>
            <label className={adminStyle.label} htmlFor="endDate">
              End Date
            </label>
            <Controller
              control={control}
              name="endDate"
              id="endDate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={endDate => field.onChange(endDate)}
                  selected={field.value}
                />
              )}
            />
          </div>
          <div>
            <label className={adminStyle.label} htmlFor="endDateFinal">
              End Final Date
            </label>
            <Controller
              control={control}
              name="endDateFinal"
              id="endDateFinal"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={endDateFinal => field.onChange(endDateFinal)}
                  selected={field.value}
                />
              )}
            />
          </div>

          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
