import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import {
  ADD_LEAGUE_FORMATION_MUTATION,
  ALL_LEAGUE_FORMATIONS_QUERY
} from "../graphQL/formationToLeague_qry";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { ALL_FORMATIONS_QUERY } from "../graphQL/formation_qry";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../graphQL/formationToLeague_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Add a formation to a league
## Description : Submit form data and a formation to a league
## Author : SF
## Date: : 2022/02/23
##########################################################################*/
export default function FormationToLeague() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [allLeagueFormations, setAllLeagueFormations] = useState([]);
  const [allLeagues, setAllLeagues] = useState([]);
  const [allFormations, setAllFormations] = useState([]);
  const [addLeagueFormation] = useMutation(ADD_LEAGUE_FORMATION_MUTATION, {
    refetchQueries: [{ query: ALL_LEAGUE_FORMATIONS_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(ALL_LEAGUE_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: loadingLeagues,
    error: errorLeagues,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: loadingFormations,
    error: errorFormations,
    data: formationData
  } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [fetchLeagueFormations] = useManualQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.allLeagueFormations) {
        setAllLeagueFormations(data.allLeagueFormations);
        console.log("AllLeagueFormations----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (!loadingLeagues && leagueData && leagueData.league) {
        setAllLeagues(leagueData.league);
      }
    },
    [leagueData]
  );

  useEffect(
    () => {
      if (!loadingFormations && formationData && formationData.allFormations) {
        setAllFormations(formationData.allFormations);
      }
    },
    [formationData]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 7000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    if (formData.league === "0") {
      setFailedMessage("Please select one league.");
    } else if (formData.formation === "0") {
      setFailedMessage("Please select one formation.");
    } else {
      let foundLeague = allLeagues.find(
        item => item.title.toLowerCase() === formData.league.toLowerCase()
      );

      let foundFormation = allFormations.find(
        item => item.formationSeqNO.toLowerCase() === formData.formation.toLowerCase()
      );

      const {
        loading: leagueFormationLoading,
        error: leagueFormationError,
        data: leagueFormationData
      } = await fetchLeagueFormations({
        variables: { leagueId: parseInt(foundLeague.leagueId) }
      });

      // let foundLeagueFormation = leagueFormationData.leagueFormation.find(
      //   item =>
      //     item.formationId.title.toLowerCase() ===
      //       formData.formation.toLowerCase() &&
      //     item.leagueId.title.toLowerCase() === formData.league.toLowerCase()
      // );

      // if (foundLeagueFormation) {
      //   setFailedMessage(
      //     "The " +
      //       foundFormation.title +
      //       " formation was already added to " +
      //       foundLeague.title +
      //       " league. Please select another formation."
      //   );
      // } else {
        const { data, error } = await addLeagueFormation({
          variables: {
            leagueId: parseInt(foundLeague.leagueId),
            formationId: parseInt(foundFormation.formationId)
          }
        });

        if (error) {
          console.log("Error", error, data);
          setFailedMessage("Unfortunately an error occurred.");
          //  setTimeout("");
        } else if (data && data.addLeagueFormation) {
          console.log("Success", data.addLeagueFormation);
          var newLeagueFormation = {
            formationId: {
              formationId: foundFormation.formationId,
              title: formData.formation.toLowerCase()
            }
          };
          data.addLeagueFormation = {
            ...data.addLeagueFormation,
            ...newLeagueFormation
          };
          allLeagueFormations.push(data.addLeagueFormation);
          setSuccessMessage(
            "Formation was added to '" +
              formData.league +
              "' league successfully."
          );
          setTimeout(() => {
            navigate(`/lms/${admin_url}/league-formation`);
          }, 2000);
          //  setTimeout("");
        }
      // }
    }
  };
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Formation to League | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add a "Formation" to a "League"</h2>
          <label className={adminStyle.label} htmlFor="league">
            <b>League Name</b>
          </label>
          <select
            className={adminStyle.select}
            id="league"
            {...register("league", { required: true })}
          >
            <option value="0">Select a league...</option>
            {allLeagues.map((league, i) => (
              <option key={i} value={league.title}>
                {league.title}
              </option>
            ))}
          </select>
          {errors.league && errors.league.type === "required" && (
            <span>Required</span>
          )}
          <label className={adminStyle.label} htmlFor="formation">
            <b>Formation</b>
          </label>
          <select
            className={adminStyle.input}
            id="formation"
            {...register("formation", { required: true })}
          >
            <option value="0">Select a formation...</option>
            {allFormations.map((formation, i) => (
              <option key={i} value={formation.formationSeqNO}>
                {formation.title}
              </option>
            ))}
          </select>
          {errors.formation && errors.formation.type === "required" && (
            <span>Required</span>
          )}
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
