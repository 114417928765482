const CreatePosition_mutate = `
    mutation createPosition(
      $label: String!,
      $name: String!) {
      createPosition(
        label: $label,
        name: $name) {
        positionId
        label
        name        
      }
    }
`;

const GetAllPositions_query = `
    query{
      position{
        positionId
        label
        name        
    }
  }
`;
module.exports = {
  CREATE_POSITION_MUTATION : CreatePosition_mutate,
  GET_ALL_POSITIONS_QUERY : GetAllPositions_query
}
