import React from "react";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@material-ui/core/Tooltip";

import { ItemTypes } from "../../../../../store/data/ItemTypes";

import booked from "../../../../../assets/StatusImages/booked.gif";
import suspended from "../../../../../assets/StatusImages/suspended.gif";
import injured from "../../../../../assets/StatusImages/injured.png";
import unavailable from "../../../../../assets/StatusImages/unavailable.png";
import available from "../../../../../assets/StatusImages/available.png";
import selected from "../../../../../assets/StatusImages/selected.png";
import sick from "../../../../../assets/StatusImages/sick.png";

import { Grid } from "@mui/material";
import TabLayout from "./TabLayout";
import { DragDropPlayer } from "./DragDropPlayer";

var findAnd = require("find-and");
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const TabsMainData = ({ droppedPlayerNamesData, selectedRole, players }) => {
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    setDroppedPlayerNames(droppedPlayerNamesData);
  }, [droppedPlayerNamesData]);

  useEffect(() => {
    setRole(selectedRole);
  }, [selectedRole]);
  function isDropped(playerName) {
    return droppedPlayerNames.indexOf(playerName) > -1;
  }

  return (
    <div>
      {players.map(
        (
          { playerId, onFieldName, jerseyNO, playerSeqNO, status, formationPos },
          index
        ) => {
          if (role === playerId.position.toLowerCase()) {
            return (
              <Grid
                container
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  borderBottom: "1px solid white",
                }}
              >
                <Grid item xs={8} style={{ display: "flex", flexDirection: "row" }}>
                  <DragDropPlayer
                    name={playerId.name}
                    onFieldName={playerId.onFieldName}
                    type={ItemTypes.PLAYER}
                    jerseyNO={jerseyNO}
                    playerSeqNO={playerSeqNO}
                    isDropped={isDropped(playerId.name)}
                    key={index}
                    status={status}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    font: "10px Roboto, Helvetica, Arial, sans-serif",
                    letterSpacing: "0.4px",
                    fontWeight: 400,
                  }}
                >
                  {formationPos}
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    paddingRight: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {status === "Fit" && (
                    <Tooltip
                      title="Fit"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={available} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}
                  {status === "Unavailable" && (
                    <Tooltip
                      title="Unavailable"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={unavailable} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}
                  {status === "Booked" && (
                    <Tooltip
                      title="Booked"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={booked} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}
                  {status === "Suspended" && (
                    <Tooltip
                      title="Suspended"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={suspended} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}
                  {status === "Injured" && (
                    <Tooltip
                      title="Injured"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={injured} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}

                  {status === "Sick" && (
                    <Tooltip
                      title="Sick"
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: "0px, -20px",
                          },
                        },
                      }}
                    >
                      <img src={sick} style={{ height: 20 }} draggable={false} />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            );
          }
        }
      )}
    </div>
  );
};

export default TabsMainData;
