import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { seo_tag_config } from "../../store/data/Constants";

import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
    maxWidth: 500,
    minHeight: 311, 
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 550,
    height: 311,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 260,
  },
  cardContent: {
    padding: theme.spacing(4),
    // paddingLeft:10,
    // paddingRight:10,
    display: "flex",
    flexDirection: "column",
    maxHeight: 311,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 260,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 340,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 60,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const ACTIVATE_USER_MUTATION = `
    mutation activateUser($activationCode: String!) {
      activateUser(activationCode: $activationCode) {
        userId
    activationCode
      }
    }
`;

const Activation = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;

  const { activationCode } = useParams();
  const navigate = useNavigate();
  const [activateUser] = useMutation(ACTIVATE_USER_MUTATION);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const client = useContext(ClientContext);

  useEffect(() => {
    console.log("executed only once!");
    submitActivateUser();
  }, [""]);

  const submitActivateUser = async () => {
    await sleep(2000);
    
    const { data, error } = await activateUser({
      variables: {
        activationCode: activationCode,
      },
    });
    
    
    if (error) {      
      
      setErrorMsg(error.graphQLErrors.map(x => x.message));
      setTimeout("");
    } else {
      console.log("Success");
      
      setTimeout(() => {
      navigate("/lms/signin");
    }, 15000);

    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Register Activation</title>
          <meta 
            name="description" 
            content="Predictal user activation to football team selector" 
          />
          <meta 
            property="keywords" 
            content="Predcital, user activation, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }

      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <Box
              mt={2}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
            </Box>
            <Box
              mt={2}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h2">
                Your account is successfully activated
              </Typography>
            </Box>
            <Box
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h5">
                Please sign in to your account.
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                className={classes.buttonStyle}
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => navigate("/lms/signin")}
              >
                Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <Box
                mt={2}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
              </Box>
              <Box
                mt={2}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h1">
                  Your account is successfully activated
                </Typography>
              </Box>
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5">
                  Please sign in to your account.
                </Typography>
              </Box>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/lms/signin")}
                >
                  Sign In
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box
                mt={1}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
              </Box>
              <Box
                //mt={4}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h1">Your account is successfully activated</Typography>
              </Box>
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5">
                  Please sign in to your account.
                </Typography>
              </Box>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/lms/signin")}
                >
                  Sign In
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
};

export default Activation;
