import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  CREATE_WEEK_MUTATION,
  GET_CURRENT_SLEAGUES_QUERY,
  GET_ALL_WEEKS_QUERY
} from "../graphQL/week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";

// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Game Stat
## Description : Submit form data and Add Game Stat for a Player in a Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function Week() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [currentBucketSleagues, setCurrentBucketSleagues] = useState([]);
  const [allWeeks, setAllWeeks] = useState([]);
  const [createWeek] = useMutation(CREATE_WEEK_MUTATION, {
    refetchQueries: [{ query: GET_ALL_WEEKS_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUES_QUERY);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      console.log(loading);

      if (!loading && data && data.currentBucketSleague) {
        setCurrentBucketSleagues(data.currentBucketSleague);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  const {
    loading: loadingAllWeeks,
    error: errorAllWeeks,
    data: dataAllWeeks
  } = useQuery(GET_ALL_WEEKS_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(
    () => {
      if (!loadingAllWeeks && dataAllWeeks && dataAllWeeks.allWeeks) {
        setAllWeeks(dataAllWeeks.allWeeks);
        console.log("AllWeeksData----", dataAllWeeks);
      }
    },
    [dataAllWeeks]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(500);
    console.log(formData);
    if (formData.sleagueId === "0") alert("Please select one sleague.");
    else {
      let findName = allWeeks.find(
        item =>
          item.name.toLowerCase() === formData.name.toLowerCase() &&
          parseInt(item.sleagueId.sleagueId) === parseInt(formData.sleagueId)
      );

      if (findName)
        alert(
          "This week name has been already exist in this season. Please enter another one."
        );
      else {
        console.log(formData);
        let start_date = formData.startDate.toISOString();
        let end_date = formData.endDate.toISOString();
        const { data, error } = await createWeek({
          variables: {
            sleagueId: parseInt(formData.sleagueId),
            name: formData.name,
            startDate: start_date,
            endDate: end_date
          }
        });

        if (error) {
          console.log("error", error);
          setFailedMessage("Unfortunately an error occurred.");
          //  setTimeout("");
        } else if (data && data.createWeek) {
          console.log("Success", data.createWeek);
          allWeeks.push(data.createWeek.week);
          console.log("allWeeks", allWeeks);
          setSuccessMessage("New week added successfully.");
          document.getElementById("name").value = "";
          document.getElementById("startDate").value = "";
          document.getElementById("endDate").value = "";
          //  setTimeout("");
        }
      }
    }
  };

  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Week | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add week for current season</h2>

          <label className={adminStyle.label} htmlFor="sleagueId">
            <b>Sleague</b>
          </label>
          <select
            className={adminStyle.select}
            id="sleagueId"
            {...register("sleagueId")}
          >
            <option value="0">Select a season...</option>
            {currentBucketSleagues.map((currentBucketSleague, i) => (
              <option key={i} value={currentBucketSleague.sleagueId.sleagueId}>
                {currentBucketSleague.sleagueId.leagueId.title +
                  " ( " +
                  currentBucketSleague.sleagueId.seasonName +
                  " ) "}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="name">
            Week Name
          </label>
          <input
            className={adminStyle.input}
            id="name"
            {...register("name", { required: true })}
          />
          {errors.name && errors.name.type === "required" && (
            <span>Required</span>
          )}
          
            <label className={adminStyle.label} htmlFor="startDate">
              Start Date
            </label>
            <Controller
              control={control}
              name="startDate"
              id="startDate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={startDate => field.onChange(startDate)}
                  selected={field.value}
                />
              )}
            />
            {errors.startDate && errors.startDate.type === "required" && (
              <span>Required</span>
            )}
            
            <label className={adminStyle.label} htmlFor="endDate">
              End Date
            </label>
            <Controller
              control={control}
              name="endDate"
              id="endDate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={endDate => field.onChange(endDate)}
                  selected={field.value}
                />
              )}
            />
            {errors.endDate && errors.endDate.type === "required" && (
              <span>Required</span>
            )}

          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
