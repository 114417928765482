import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, styled } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_GAME_STATS_BY_GAME_STAT_ID_QUERY,
         EDIT_GAME_STAT_MUTATION,
         DELETE_GAME_STAT_MUTATION } from "../graphQL/gameStat_qry";
import { GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
         EDIT_GAME_STAT_AGGR_MUTATION } from "../graphQL/gameStatAggr_qry";
import { GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
         EDIT_POSITION_PLAYED_AGGR_MUTATION } from "../graphQL/positionPlayedAggr_qry";
import { GET_ALL_POSITIONS_QUERY } from "../graphQL/position_qry";
import { SLEAGUE_TEAM_BY_TEAM_ID } from "../graphQL/sleague_team_query";
import {project_url, admin_url} from "../store/data/Constants";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default function FixturePlayerStatsEdit() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  const [disableBtns, setDisableBtns] = useState(false);
  const [disabledPositionInput, setDisabledPositionInput] = useState(false);

  const [displayNoPosition,setDisplayNoPosition] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [disabledPlayerInputs, setDisabledPlayerInputs] = useState(false);
  const [minutesPlayedError , setMinutesPlayedError] = useState(false);
  const [playerOnField, setPlayerOnField] = useState("");

  const [open, setOpen] = useState(false);  
  const [sleagueTeamId, setSleagueTeamId] = useState(0);  

  const {sleagueTeamPlayerId} = useParams();

  const fixData       = JSON.parse(localStorage.getItem("fixtureData"));
  const currentFixId  = localStorage.getItem("curFixId");
  let fixtureData     = [];

  if (fixData.length > 1){
    const index = fixData.findIndex(
      x => x.fixtureId === currentFixId
    );
    if (index !== -1) {
      fixtureData = fixData[index];
    };
  }
  else{
    fixtureData = fixData[0];
  }

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
    onRefreshToken();
  }
  else
    navigate(`/lms/${admin_url}/signin`);
  }, [])

  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
  if (refreshError) {
    if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
      navigate(`/lms/${admin_url}/signin`);
    if(refreshError.graphQLErrors[0].message === "Signature has expired")
      navigate(`/lms/${admin_url}/signin`);
  }
  if(refreshData && refreshData.refreshToken) {
    localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
  }
  };

  const [positionData, setPositionData] = useState([]);
  const [playerStat, setPlayerStat] = useState("");
  

  const [allGameStatsAggr, setAllGameStatsAggr] = useState([]);
  const [allPositionPlayedAggr, setAllPositionPlayedAggr] = useState([]);

  
  const { loading: loadingPos, error: errorPos, data: dataPos } = useQuery(
    GET_ALL_POSITIONS_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );

  const [editGameStat, 
      { loadingEdit, errorEdit }] = useMutation(EDIT_GAME_STAT_MUTATION);

  const [editGameStatsAggr, 
      { loadingGameAggrEdit, errorGameAggrEdit }] = useMutation(EDIT_GAME_STAT_AGGR_MUTATION);

  const [editPositionPlayedAggr, 
        { loadingPosAggrEdit, 
          errorPosAggrEdit }] = useMutation(EDIT_POSITION_PLAYED_AGGR_MUTATION); 

  const [deleteGameStat, 
      { loadingDelete, errorDelete }] = useMutation(DELETE_GAME_STAT_MUTATION);

  // const {
  //   loading: sleagueDataLoading,
  //   error: sleagueDataError,
  //   data: sleagueData,
  // } = useQuery(SLEAGUE_TEAM_BY_TEAM_ID, {
  //   variables: {
  //     teamId:parseInt(teamId),
  //   },
  // });

  //  useEffect(()=>{
  //   if (!sleagueDataLoading && sleagueData && sleagueData.sleagueTeamByTeamId) {
  //     setSleagueTmId(sleagueData.sleagueTeamByTeamId.sleagueTeamId);
  //   }  
  // });

  useEffect(
    () => {
      if (!loadingPos && dataPos && dataPos.position) {
        setPositionData(dataPos.position);
        console.log("AllPositionsData----", dataPos);
      }
    },
    [dataPos]
  );

  const {
    loading: playerStatLoading,
    error: playerStatError,
    data: playerStatData,
  } = useQuery(GET_GAME_STATS_BY_GAME_STAT_ID_QUERY, {
    variables: {
      gameStatId:parseInt(sleagueTeamPlayerId),
    },
  });

  useEffect(()=>{
    if (!playerStatLoading && playerStatData && playerStatData.gameStatByGameStat){

      setSleagueTeamId(parseInt(
        playerStatData.gameStatByGameStat.sleagueTeamPlayerId.sleagueTeamId.sleagueTeamId));
      setPlayerStat(playerStatData.gameStatByGameStat);

      
      if(playerStatData.gameStatByGameStat.playerOnField){
        setValue("playerOnField", playerStatData.gameStatByGameStat.playerOnField);
        setPlayerOnField(playerStatData.gameStatByGameStat.playerOnField);
        if (playerStatData.gameStatByGameStat.playerOnField == "No-Play")
        {
          setDisabledInputs(true);
          setDisabledPositionInput(true);
          setDisplayNoPosition(true);
        }
      }

      if(playerStatData.gameStatByGameStat.positionId.positionId){
        setValue("positionId", playerStatData.gameStatByGameStat.positionId.positionId);
      }

      if(playerStatData.gameStatByGameStat.minutePlayed){
        setValue("minutePlayed", playerStatData.gameStatByGameStat.minutePlayed);
      }
      
      
        setValue("goal", playerStatData.gameStatByGameStat.goal);
      
     
        setValue("ownGoal", playerStatData.gameStatByGameStat.ownGoal);
      
      
        setValue("cardYellow", playerStatData.gameStatByGameStat.cardYellow);
      
        setValue("cardRed", playerStatData.gameStatByGameStat.cardRed);
      

    }
  },playerStatData); 

  const {
    loading: loadingGSAggr,
    error: errorGSAggr,
    data: dataGSAggr
  } = useQuery( GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY, { 
    variables: { sleagueTeamId: parseInt(sleagueTeamId) }
  });

  useEffect(()=>{
    if (sleagueTeamId && 
      !loadingGSAggr && 
      dataGSAggr && 
      dataGSAggr.gameStatAggrSleagueteam) {
      setAllGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
      console.log("sleagueTeamGameStatsAggrData----", dataGSAggr);
    }  
  }, [sleagueTeamId, dataGSAggr]);

  const {
      loading: loadingPosPlayedAggr,
      error: errorPosPlayedAggr,
      data: dataPosPlayedAggr
    } = useQuery( GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY, { 
      variables: { sleagueTeamId: parseInt(sleagueTeamId) }
    });

    useEffect(()=>{
      if (sleagueTeamId && 
        !loadingPosPlayedAggr && 
        dataPosPlayedAggr && 
        dataPosPlayedAggr.positionPlayedAggrSleagueteam) {
        setAllPositionPlayedAggr(dataPosPlayedAggr.positionPlayedAggrSleagueteam);
        console.log("sleagueTeamPositionPlayedAggr----", dataPosPlayedAggr);
      } 
    }, [sleagueTeamId, dataPosPlayedAggr]); 


  /*###################################################################
   // Function : updateGameStatAggrTable
   // Description : Update Game Stat Aggr. table by new game stat info
  ###################################################################*/

  const updateGameStatAggrTable = (async (statData, 
                                          fData, 
                                          delState = false) => {

    // Calculating new data for updating game stat aggr table
    var fullGame = 0, fullMin = 0, subinGame = 0, subinMin = 0,
        suboutGame = 0, suboutMin = 0, subInOutGame = 0, subInOutMin = 0,
        ejectGame = 0, ejectMin = 0,
        totalGame = 0, totalMin = 0,
        avgMinPerGame = 0,goal = 0, ownGoal = 0,
        cardYellow = 0, cardRed = 0;


    // Updating Game State Aggr table by new data
    if (allGameStatsAggr.length > 0){
      let matchedSleagueTeamPlayer = allGameStatsAggr.filter(
        item => parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) === 
                parseInt(statData.sleagueTeamPlayerId.sleagueTeamPlayerId)
      ); 

      if (matchedSleagueTeamPlayer.length >= 2){
        alert("There is more than one record for this player in GameStatAggr table!");
        return
      }   

      let foundSleagueTeamPlayer = matchedSleagueTeamPlayer[0];

      if (foundSleagueTeamPlayer){      
        fullGame  = parseInt(foundSleagueTeamPlayer.fullGame);
        fullMin   = parseInt(foundSleagueTeamPlayer.fullMin);
        subinGame = parseInt(foundSleagueTeamPlayer.subinGame);
        subinMin  = parseInt(foundSleagueTeamPlayer.subinMin);
        suboutGame= parseInt(foundSleagueTeamPlayer.suboutGame);
        suboutMin = parseInt(foundSleagueTeamPlayer.suboutMin);
        subInOutGame = parseInt(foundSleagueTeamPlayer.subInOutGame);
        subInOutMin  = parseInt(foundSleagueTeamPlayer.subInOutMin);
        ejectGame = parseInt(foundSleagueTeamPlayer.ejectGame);
        ejectMin  = parseInt(foundSleagueTeamPlayer.ejectMin);
        totalGame = parseInt(foundSleagueTeamPlayer.totalGame);
        totalMin  = parseInt(foundSleagueTeamPlayer.totalMin);
        goal      = parseInt(foundSleagueTeamPlayer.goal); 
        ownGoal   = parseInt(foundSleagueTeamPlayer.ownGoal); 
        cardYellow= parseInt(foundSleagueTeamPlayer.cardYellow);
        cardRed   = parseInt(foundSleagueTeamPlayer.cardRed);
        
      };

      // Subtracting old values from proper fields
      if (statData.playerOnField == 'Full-Game') {      
        fullGame  = fullGame - 1;
        fullMin   = fullMin - parseInt(statData.minutePlayed);    

      } else if (statData.playerOnField == 'Sub-In') {      
        subinGame = subinGame - 1;
        subinMin  = subinMin - parseInt(statData.minutePlayed); 

      } else if (statData.playerOnField == 'Sub-Out') {          
        suboutGame= suboutGame - 1;
        suboutMin = suboutMin - parseInt(statData.minutePlayed); 

      } else if (statData.playerOnField == 'Sub-In-Out') {          
        subInOutGame= subInOutGame - 1;
        subInOutMin = subInOutMin - parseInt(statData.minutePlayed); 

      } else if (statData.playerOnField == 'Ejected') {          
        ejectGame = ejectGame - 1;
        ejectMin  = ejectMin - parseInt(statData.minutePlayed);        
      }; 

      totalGame = totalGame - 1;
      totalMin  = totalMin - parseInt(statData.minutePlayed);

      if (totalGame < 0 ) totalGame = 0;
      if (totalMin < 0 ) totalMin = 0;

      // Adding new values to proper fields
      if ( delState === false){
        if (fData.playerOnField == 'Full-Game') {      
          fullGame  = fullGame + 1;
          fullMin   = fullMin + parseInt(fData.minutePlayed);    

        } else if (fData.playerOnField == 'Sub-In') {      
          subinGame = subinGame + 1;
          subinMin  = subinMin + parseInt(fData.minutePlayed); 

        } else if (fData.playerOnField == 'Sub-Out') {          
          suboutGame= suboutGame + 1;
          suboutMin = suboutMin + parseInt(fData.minutePlayed); 

        } else if (fData.playerOnField == 'Sub-In-Out') {          
          subInOutGame= subInOutGame + 1;
          subInOutMin = subInOutMin + parseInt(fData.minutePlayed); 

        } else if (fData.playerOnField == 'Ejected') {          
          ejectGame = ejectGame + 1;
          ejectMin  = ejectMin + parseInt(fData.minutePlayed);        
        }; 

        if (fData.playerOnField !== "No-Play"){
          totalGame = totalGame + 1;
          totalMin  = totalMin + parseInt(fData.minutePlayed);
        }

        if (totalGame < 0 ) totalGame = 0;
        if (totalMin < 0 ) totalMin = 0;
      }

      avgMinPerGame = totalGame
        ? Math.ceil(totalMin / totalGame)
        : 0;

      if ( delState === false){
        goal        = goal - parseInt(statData.goal) + parseInt(fData.goal);
        ownGoal     = ownGoal - parseInt(statData.ownGoal) + parseInt(fData.ownGoal);
        cardYellow  = cardYellow - Number(statData.cardYellow) + 
                          Number(JSON.parse(fData.cardYellow));
        cardRed     = cardRed - Number(statData.cardRed) + 
                          Number(JSON.parse(fData.cardRed)); 
      } else {
        goal        = goal - parseInt(statData.goal);
        ownGoal     = ownGoal - parseInt(statData.ownGoal);
        cardYellow  = cardYellow - Number(statData.cardYellow);
        cardRed     = cardRed - Number(statData.cardRed);
      }       

      // edit an existing record in game stat aggr table by new data
      if (foundSleagueTeamPlayer){
        const { data, 
                editGameStatAggError } = await editGameStatsAggr({
          variables: {
            gameStatAggrId: parseInt(foundSleagueTeamPlayer.gameStatAggrId),
            sleagueTeamPlayerId: 
            parseInt(foundSleagueTeamPlayer.sleagueTeamPlayerId.sleagueTeamPlayerId),
            filterId: parseInt(foundSleagueTeamPlayer.filterId.filterId),
            fullGame: fullGame,
            fullMin: fullMin,
            subinGame: subinGame,
            subinMin: subinMin,
            suboutGame: suboutGame,
            suboutMin: suboutMin,
            subInOutGame: subInOutGame,
            subInOutMin: subInOutMin,
            ejectGame: ejectGame,
            ejectMin: ejectMin,
            totalGame: totalGame,
            totalMin: totalMin,
            avgMinPerGame: avgMinPerGame,
            goal: goal,
            ownGoal: ownGoal,
            cardYellow: cardYellow,
            cardRed: cardRed          
          },
        });

        if (editGameStatAggError) {
          console.log("Error", editGameStatAggError, data);
          alert("There is an error");
        } else if (data && data.editGamestataggr) {
          console.log("Success Edit",data.editGamestataggr);         
          alert("The existing Game Stat Aggr record has been updated!");                    
        };
      } else {
        alert("There is no existing record in GamStatAggr table for this player to be updated!");
      }
    }
  })

/*###################################################################
// Function : updatePositionPlayedAggrTable
// Description : Update Position Played Aggr. table by new game stat info
###################################################################*/

  const updatePositionPlayedAggrTable = (async (statData, 
                                                fData, 
                                                delState = false) => {

    // Calculating new data for updating Position Played aggr table

    var GKCount = 0, LDEFCount = 0, CDEFCount = 0, RDEFCount = 0,
        LMFCount = 0, CMFCount = 0, RMFCount = 0, LFORCount = 0,
        CFORCount = 0,RFORCount = 0, GKMin = 0, LDEFMin = 0,
        CDEFMin = 0,RDEFMin = 0,LMFMin = 0, CMFMin = 0, RMFMin = 0,
        LFORMin = 0, CFORMin = 0, RFORMin = 0;


    // Updating Game State Aggr table by new data
    if (allPositionPlayedAggr.length > 0){ 
      let matchedSleagueTeamPlayer = allPositionPlayedAggr.filter(
        item => parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) === 
                parseInt(statData.sleagueTeamPlayerId.sleagueTeamPlayerId)
      ); 

      let foundPosition = positionData.find(
        item => parseInt(item.positionId) === 
                parseInt(statData.positionId.positionId)
      );  

      let formPosition = positionData.find(
        item => parseInt(item.positionId) === 
                parseInt(fData.positionId)
      );  

      if (matchedSleagueTeamPlayer.length >= 2){
        alert("There is more than one record for this player in GameStatAggr table!");
        return
      }   

      let foundSleagueTeamPlayer = matchedSleagueTeamPlayer[0];

      if (foundSleagueTeamPlayer){      
        GKCount   = parseInt(foundSleagueTeamPlayer.GKCount);
        LDEFCount = parseInt(foundSleagueTeamPlayer.LDEFCount);
        CDEFCount = parseInt(foundSleagueTeamPlayer.CDEFCount);
        RDEFCount = parseInt(foundSleagueTeamPlayer.RDEFCount);
        LMFCount  = parseInt(foundSleagueTeamPlayer.LMFCount);
        CMFCount  = parseInt(foundSleagueTeamPlayer.CMFCount);
        RMFCount  = parseInt(foundSleagueTeamPlayer.RMFCount); 
        LFORCount = parseInt(foundSleagueTeamPlayer.LFORCount);
        CFORCount = parseInt(foundSleagueTeamPlayer.CFORCount);
        RFORCount = parseInt(foundSleagueTeamPlayer.RFORCount);
        GKMin     = parseInt(foundSleagueTeamPlayer.GKMin); 
        LDEFMin   = parseInt(foundSleagueTeamPlayer.LDEFMin);
        CDEFMin   = parseInt(foundSleagueTeamPlayer.CDEFMin);
        RDEFMin   = parseInt(foundSleagueTeamPlayer.RDEFMin);
        LMFMin    = parseInt(foundSleagueTeamPlayer.LMFMin);
        CMFMin    = parseInt(foundSleagueTeamPlayer.CMFMin);
        RMFMin    = parseInt(foundSleagueTeamPlayer.RMFMin);
        LFORMin   = parseInt(foundSleagueTeamPlayer.LFORMin);
        CFORMin   = parseInt(foundSleagueTeamPlayer.CFORMin);
        RFORMin   = parseInt(foundSleagueTeamPlayer.RFORMin);
      
      // Subtracting old values from proper fields
        if (foundPosition && foundPosition.label == 'GK') {      
          GKCount   = GKCount - 1;
          GKMin     = GKMin - parseInt(statData.minutePlayed);    

        } else if (foundPosition && foundPosition.label == 'LDEF') {      
          LDEFCount = LDEFCount - 1;
          LDEFMin   = LDEFMin - parseInt(statData.minutePlayed); 

        } else if (foundPosition && foundPosition.label == 'CDEF') {          
          CDEFCount = CDEFCount - 1;
          CDEFMin   = CDEFMin - parseInt(statData.minutePlayed); 

        } else if (foundPosition && foundPosition.label == 'RDEF') {      
          RDEFCount = RDEFCount - 1;
          RDEFMin   = RDEFMin - parseInt(statData.minutePlayed); 

        } else if (foundPosition && foundPosition.label == 'LMF') {      
          LMFCount  = LMFCount - 1;
          LMFMin    = LMFMin - parseInt(statData.minutePlayed);

        } else if (foundPosition && foundPosition.label == 'CMF') {      
          CMFCount  = CMFCount - 1;
          CMFMin    = CMFMin - parseInt(statData.minutePlayed); 

        } else if (foundPosition && foundPosition.label == 'RMF') {      
          RMFCount  = RMFCount - 1;
          RMFMin    = RMFMin - parseInt(statData.minutePlayed); 

        } else if (foundPosition && foundPosition.label == 'LFOR') {      
          LFORCount = LFORCount - 1;
          LFORMin   = LFORMin - parseInt(statData.minutePlayed);

        } else if (foundPosition && foundPosition.label == 'CFOR') {      
          CFORCount = CFORCount - 1;
          CFORMin   = CFORMin - parseInt(statData.minutePlayed);

        } else if (foundPosition && foundPosition.label == 'RFOR') {      
          RFORCount = RFORCount - 1;
          RFORMin   = RFORMin - parseInt(statData.minutePlayed);
        };

        if ( delState === false){
          // Adding new values to proper fields
          if (formPosition && formPosition.label === 'GK') {      
            GKCount   = GKCount + 1;
            GKMin     = GKMin + parseInt(fData.minutePlayed);    

          } else if (formPosition && formPosition.label === 'LDEF') {      
            LDEFCount = LDEFCount + 1;
            LDEFMin   = LDEFMin + parseInt(fData.minutePlayed); 

          } else if (formPosition && formPosition.label === 'CDEF') {          
            CDEFCount = CDEFCount + 1;
            CDEFMin   = CDEFMin + parseInt(fData.minutePlayed); 

          } else if (formPosition && formPosition.label === 'RDEF') {      
            RDEFCount = RDEFCount + 1;
            RDEFMin   = RDEFMin + parseInt(fData.minutePlayed); 

          } else if (formPosition && formPosition.label === 'LMF') {      
            LMFCount  = LMFCount + 1;
            LMFMin    = LMFMin + parseInt(fData.minutePlayed);

          } else if (formPosition && formPosition.label === 'CMF') {      
            CMFCount  = CMFCount + 1;
            CMFMin    = CMFMin + parseInt(fData.minutePlayed); 

          } else if (formPosition && formPosition.label === 'RMF') {      
            RMFCount  = RMFCount + 1;
            RMFMin    = RMFMin + parseInt(fData.minutePlayed); 

          } else if (formPosition && formPosition.label === 'LFOR') {      
            LFORCount = LFORCount + 1;
            LFORMin   = LFORMin + parseInt(fData.minutePlayed);

          } else if (formPosition && formPosition.label === 'CFOR') {      
            CFORCount = CFORCount + 1;
            CFORMin   = CFORMin + parseInt(fData.minutePlayed);

          } else if (formPosition && formPosition.label === 'RFOR') {      
            RFORCount = RFORCount + 1;
            RFORMin   = RFORMin + parseInt(fData.minutePlayed);
          };  
        };      

      // edit an existing record in position played aggr table by new data      
        const { data, error } = await editPositionPlayedAggr({
          variables: {
            positionPlayedAggrId: 
                parseInt(foundSleagueTeamPlayer.positionPlayedAggrId),
            sleagueTeamPlayerId: 
            parseInt(foundSleagueTeamPlayer.sleagueTeamPlayerId.sleagueTeamPlayerId),
            filterId: parseInt(foundSleagueTeamPlayer.filterId.filterId),
            GKCount: GKCount,
            LDEFCount: LDEFCount,
            CDEFCount: CDEFCount,
            RDEFCount: RDEFCount,
            LMFCount: LMFCount,
            CMFCount: CMFCount,
            RMFCount: RMFCount,
            LFORCount: LFORCount,
            CFORCount: CFORCount,
            RFORCount: RFORCount,
            GKMin: GKMin,
            LDEFMin: LDEFMin,
            CDEFMin: CDEFMin,
            RDEFMin: RDEFMin,
            LMFMin: LMFMin,
            CMFMin: CMFMin,
            RMFMin: RMFMin,
            LFORMin: LFORMin,
            CFORMin: CFORMin,
            RFORMin: RFORMin      
          },
        });
        if (error) {
          console.log("Error", error, data);
          alert("There is an error");
        } else if (data && data.editPositionplayedaggr) {
          console.log("Success Edit",data.editPositionplayedaggr);         
          alert("The existing Position Played Aggr record has been updated!");                    
        };
      } else {
        alert("There is no existing record in PositionPlayedAggr table for this player to be updated!");
      }
    }

  })


/*###################################################################
// Function : cancelHandler
// Description : Handle Cancel
###################################################################*/

  const cancelHandler = async e => {
    await sleep(2000);

    setDisableBtns(true);    

    setTimeout(() => {
      navigate(`/lms/${admin_url}/fixture-player-stats`);
    }, 100); 
    localStorage.setItem("ReturnedFromEditPage", true);                
    
  };

/*###################################################################
// Function : handleSubmitSave
// Description : Handle Submit & Save
###################################################################*/

  const handleSubmitSave = handleSubmit(async (formData) => {
    // await sleep(2000);
   
    if (parseInt(formData.minutePlayed) == 0 && formData.playerOnField != "No-Play")
    {
      
          alert("Minutes Played should be greater than 0.")
    }

     else if (parseInt(formData.minutePlayed) > 90)
      alert("Minutes Played cannot be greater than 90.")

    else if (formData.playerOnField == "Full-Game" && parseInt(formData.minutePlayed) != 90)
       alert("Minutes Played should be set to 90, when option 'Full Game' is selected in the field 'Player On-Field' for a player.")
   
    
    else
      {

        setDisableBtns(true);
        //===========================================================
        // Editing Game Stat Record
        //=========================================================== 
        if (playerStat){
            // edit an existing record in game stat table by new data
            const { data, error } = await editGameStat({
              variables: {
                gameStatId: parseInt(playerStat.gameStatId),
                sleagueTeamPlayerId: 
                  parseInt(playerStat.sleagueTeamPlayerId.sleagueTeamPlayerId),
                fixtureId: parseInt(playerStat.fixtureId.fixtureId),
                positionId: parseInt(formData.positionId),
                playerOnField: formData.playerOnField,
                minutePlayed: parseInt(formData.minutePlayed),
                goal: parseInt(formData.goal),
                ownGoal: parseInt(formData.ownGoal),
                cardYellow: JSON.parse(formData.cardYellow),
                cardRed: JSON.parse(formData.cardRed),              
              },
            });
            if (error) {
              console.log("Error", error, data);
              alert("Stats for player '" + playerStat.sleagueTeamPlayerId.playerId.name + 
                "' is already updated for this fixture. Player will not be updated again");        
            } else if (data && data.editGamestat) {
              console.log("Success Edit",data.editGamestat);
              alert("Stats for player '" + playerStat.sleagueTeamPlayerId.playerId.name +  
                "' is successfully updated for this fixture.");
              // alert("The existing Game Stat record has been updated!");
            }

            //===========================================================
            // Editing Game Stat Aggr Record
            //===========================================================
            updateGameStatAggrTable(playerStat, formData);

            //===========================================================
            // Editing Position Played Aggr Record
            //===========================================================
            updatePositionPlayedAggrTable(playerStat, formData);

            setTimeout(() => {
              navigate(`/lms/${admin_url}/fixture-player-stats`);
            }, 1000);
            localStorage.setItem("ReturnedFromEditPage", true);                  
        }; 
     }   
  })

/*###################################################################
// Function : handleDelete
// Description : Handle Delete
###################################################################*/

  const handleDelete = handleSubmit(async (formData) => {
    // await sleep(2000);

    

      setDisableBtns(true);
      setOpen(false);
      //===========================================================
      // Deleting Game Stat Record
      //=========================================================== 
      if (playerStat){   

          //===========================================================
          // Editing Game Stat Aggr Record
          //===========================================================
          updateGameStatAggrTable(playerStat, formData, true);

          //===========================================================
          // Editing Position Played Aggr Record
          //===========================================================
          updatePositionPlayedAggrTable(playerStat, formData, true);

          // delete an existing record in game stat table 
          const { data, error } = await deleteGameStat({
            variables: {
              gameStatId: parseInt(playerStat.gameStatId),                          
            },
          });
          if (error) {
            console.log("Error", error, data);
            alert("There is an error");
          } else if (data && data.deleteGamestat) {
            console.log("Success Delete",data.deleteGamestat);
            alert("The existing Game Stat record has been deleted!");
          }

          setTimeout(() => {
            navigate(`/lms/${admin_url}/fixture-player-stats`);
          }, 1000);
          localStorage.setItem("ReturnedFromEditPage", true);                  
      };        
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMinuteChange = event => {
    
    const value = event.target.value;
    if (playerOnField != "No-Play")
     {
      if ( value < 1 || value > 90 )
         setMinutesPlayedError(true);
       else
          setMinutesPlayedError(false);
      }
    else
      setMinutesPlayedError(false);
  };

   const playerOnFieldChange = event => {

    setPlayerOnField(event.target.value);
    
    // if "No Play" is selected the next input (Minutes Played) is set to zero
    if (event.target.value == "No-Play"){
      
      setValue("minutePlayed", 0);
      setValue("goal", 0);
      setValue("ownGoal", 0);
      setDisplayNoPosition(true);   

      setDisabledPositionInput(true);  

      setDisabledInputs(true);
      
      setValue("positionId", "999");
      
    }     
    else {
      setDisplayNoPosition(false);
      setDisabledInputs(false);  
      setDisabledPositionInput(false);  
      
      if (event.target.value == "Full-Game")
        setValue("minutePlayed", "90");
       
      
 
    };                             
  };

  //====================================================================
  //
  //====================================================================

  return (
    <>
      <Helmet>
        <title>Predictal | Edit A Game Stat | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form}>
          <h2 className={adminStyle.h2}>Edit a player stat in a fixture</h2>        

            <select
              className={adminStyle.select}
              id="sleague"
              {...register("sleague")}
              disabled={true}
            >
              <option >{playerStat != "" && 
                playerStat.sleagueTeamPlayerId.sleagueTeamId.sleagueId.leagueId != undefined &&
                playerStat.sleagueTeamPlayerId.sleagueTeamId.sleagueId != undefined ?
                playerStat.sleagueTeamPlayerId.sleagueTeamId.sleagueId.leagueId.subtitle +
                ", Season " + playerStat.sleagueTeamPlayerId.sleagueTeamId.sleagueId.seasonName : ""}</option>
            </select>

            <label className={adminStyle.label} htmlFor="matchWeek">
              Match Week:  <b>{playerStat != "" && 
              playerStat.fixtureId.scheduledWeek.weekId.name != undefined ?
              playerStat.fixtureId.scheduledWeek.weekId.name : ""}</b>
            </label>

            <select
              className={adminStyle.select}
              id="team"
              {...register("team")}
              disabled={true}
            >
              <option >{playerStat != "" && playerStat.sleagueTeamPlayerId.sleagueTeamId.teamId != undefined ?
                playerStat.sleagueTeamPlayerId.sleagueTeamId.teamId.name : ""}</option>
            </select>

            <label className={adminStyle.label} htmlFor="fixturedata">
              Fixture:  {fixtureData && (
              <b>{fixtureData.fixtureTeams[0].teamName} {fixtureData.fixtureTeams[0].finalScore} (
              {fixtureData.fixtureTeams[0].halfScore} ) - {fixtureData.fixtureTeams[1].teamName} 
              {fixtureData.fixtureTeams[1].finalScore} ( {fixtureData.fixtureTeams[1].halfScore} ) 
              </b>)}
            </label>

            <label className={adminStyle.dateLabel} htmlFor="fixturedata">
              Date:  <b>{fixtureData != "" && fixtureData.fixtureDate}</b>
            </label>

            <select
              className={adminStyle.select}
              id="team"
              {...register("player")}
              disabled={true}
            >
              <option >{playerStat != "" && playerStat.sleagueTeamPlayerId.playerId != undefined ?
                "(" + playerStat.sleagueTeamPlayerId.jerseyNO +
                ") " + playerStat.sleagueTeamPlayerId.playerId.name : ""}</option>
            </select>

            <label className={adminStyle.label} htmlFor="playerOnField">
              Player On Field
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id = "playerOnField"
              {...register("playerOnField")}
               onChange={playerOnFieldChange}
            >
              {[{ name: "Full Game",      value: "Full-Game" }, 
                { name: "Substitute-in",  value: "Sub-In" },
                { name: "Substitute-out", value: "Sub-Out" },
                { name: "Substitute-in-out", value: "Sub-in-Out" },
                { name: "Ejected",        value: "Ejected" },
                { name: "No Play",        value: "No-Play" }].map(
                option => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                )
              )}  
            </select>

            <label className={adminStyle.label} htmlFor="playerOnFld">
              Position
            </label>

            { displayNoPosition === true ?
            (
            <div>
              <select
                className={adminStyle.select}
                disabled= {disabledPlayerInputs || disabledPositionInput}
                id="positionId"
                {...register("positionId")}
              >
                <option value="999">No Position</option>
                                
              </select>
            </div>
            )
            :
            (
            <div>
              <select
                className={adminStyle.select}
                disabled= {disabledPlayerInputs || disabledPositionInput}
                id="positionId"
                {...register("positionId", { required: true })}
              >
                <option value="">Select a player position...</option>
                
                {positionData &&
                  positionData.length > 0 &&
                  positionData.map(({ positionId, label }) => (
                    <option key={positionId} value={positionId}>
                      {label}
                    </option>
                  ))}
              </select>

              {errors.positionId && errors.positionId.type === "required" && (
                <span>Required</span>
              )}
            </div>
            )
          }          

            <label className={adminStyle.label} htmlFor="minutePlayed">
              Minutes Played
            </label>
            <input
              className={adminStyle.input}
              id = "minutePlayed"
              type="number"              
              disabled={disabledInputs || disabledPlayerInputs}
              {...register("minutePlayed", { required: true })} 
              onChange={handleMinuteChange}
            />
            {errors.minutePlayed && errors.minutePlayed.type === "required" && (
              <span>Required</span>
            )}
            {minutesPlayedError === true && (
              <span>Minutes Played should be between 1 and 90.</span>
            )}

            <label className={adminStyle.label} htmlFor="goal">
              Goal
            </label>
            <input
              className={adminStyle.input}
              id = "goal"
              type="number"
              disabled={disabledInputs || disabledPlayerInputs}
              {...register("goal", { required: true })} 
            />
            {errors.goal && errors.goal.type === "required" && (
              <span>Required</span>
            )}

            <label className={adminStyle.label} htmlFor="ownGoal">
              Own Goal
            </label>
            <input
              className={adminStyle.input}
              id = "ownGoal"
              type="number"
              disabled={disabledInputs || disabledPlayerInputs}
              {...register("ownGoal", { required: true })} 
            />
            {errors.ownGoal && errors.ownGoal.type === "required" && (
              <span>Required</span>
            )}

            <label className={adminStyle.label} htmlFor="cardYellow">
              Yellow Card
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id = "cardYellow"
              {...register("cardYellow")}
            >
               {[ { name: "No", value: false }, 
                  { name: "Yes", value: true }].map(
                option => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                )
              )}
            </select>

            <label className={adminStyle.label} htmlFor="cardRed">
              Red Card
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id = "cardRed"
              {...register("cardRed")}
            >
               {[ { name: "No", value: false }, 
                  { name: "Yes", value: true }].map(
                option => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                )
              )}
            </select> 

            <Button
              variant="text"
              onClick={cancelHandler}
              style={{ marginLeft: "30px" }}
            >
              Cancel
            </Button>  

            <Button style={{  marginLeft: '30px', 
                            marginRight: '30px',
                            marginTop: '20px',
                            marginBottom: '20px'}}
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={handleSubmitSave}
            disabled={disableBtns}
          >
            {" "}
            Save{" "}
          </Button>

          <Button style={{  marginLeft: '20px', 
                            marginRight: '30px',
                            marginTop: '20px',
                            marginBottom: '20px',
                            backgroundColor: 'red'}}
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={handleOpen}
            disabled={disableBtns}
          >
            {" "}
            DELETE{" "}
          </Button>
                  
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this record? 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
