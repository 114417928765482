const GetAllPlayers_query = `
  query{
  allPlayers {
    playerId
    fName
    lName
    name
    onFieldName
    foot
    height
    weight
    birthDate
    headImage
    position
    attrPower
    attrSpeed
    attrDefense
    attrAttack
    attrStrategy
    attrTechnical
    createBy
    createDate
    updateBy
    updateDate
  }
}`;

const GetPlayerById_query = `
  query($playerId: Int!){
  playerById (playerId: $playerId){

    playerId
    fName
    lName
	   shortName
    name
    onFieldName
    foot
    height
    weight
    birthDate
    headImage
    position
    attrPower
    attrSpeed
    attrDefense
    attrAttack
    attrStrategy
    attrTechnical
    createBy
    createDate
    updateBy
    updateDate
  }
}`;
const EditPlayer_mutation = `
mutation editPlayer($playerId: Int!, $fName: String!, $lName: String!, $height: Int!,  $weight: Int!,
                  $foot: String!, $attrPower: String!, $attrSpeed: String!, $attrDefense: String!,
                  $attrAttack: String!, $attrStrategy: String!, $attrTechnical: String!,
                  $birthDate: String!, $headImage:Upload, $position: String!,$shortName: String,
) {
  editPlayer(
    playerId: $playerId,
    fName: $fName,
    lName: $lName,
    height: $height,
    weight: $weight,
    birthDate: $birthDate,
    foot: $foot,
    attrPower: $attrPower,
    attrSpeed: $attrSpeed,
    attrDefense: $attrDefense,
    attrAttack: $attrAttack,
    attrStrategy: $attrStrategy,
    attrTechnical: $attrTechnical,
    headImage: $headImage,
    position: $position,
	shortName: $shortName,
  ) {
    player{
      playerId
      fName
    }
  }
}
`;

const GetPlayerPopularities = `
query ($fixtureTeamId:Int!) {
  playerPopularity (fixtureTeamId:$fixtureTeamId)
  {
    playerPopularityId
    sleagueTeamPlayerId{
	  jerseyNO
      playerId {
        fName
        lName
        name
        onFieldName   
        position
      }
    }
    allFormationVotes
    popularFormationVotes
    popularFormationPercentage
  }
}`;

module.exports = {
  GET_ALL_PLAYERS_QUERY : GetAllPlayers_query,
  EDIT_PLAYER_MUTATION : EditPlayer_mutation,
  GET_PLAYER_BY_ID : GetPlayerById_query,
  GET_PLAYER_POPULARITIES_QUERY : GetPlayerPopularities
}
