const CreateGameStatAggr_mutate = `
    mutation createGamestataggr(
      $sleagueTeamPlayerId: Int!,
      $filterId: Int!,
      $fullGame: Int,
      $fullMin: Int,
      $subinGame: Int,
      $subinMin: Int,
      $suboutGame: Int,
      $suboutMin: Int,
      $subInOutGame: Int,
      $subInOutMin: Int,
      $ejectGame: Int,
      $ejectMin: Int,
      $totalGame: Int,
      $totalMin: Int,
      $avgMinPerGame: Int,
      $goal: Int,
      $ownGoal: Int,
      $cardYellow: Int,
      $cardRed: Int) {
      createGamestataggr(
        sleagueTeamPlayerId: $sleagueTeamPlayerId,
        filterId: $filterId,
        fullGame: $fullGame,
        fullMin: $fullMin,
        subinGame: $subinGame,
        subinMin: $subinMin,
        suboutGame: $suboutGame,
        suboutMin: $suboutMin,
        subInOutGame: $subInOutGame,
        subInOutMin: $subInOutMin,
        ejectGame: $ejectGame,
        ejectMin: $ejectMin,
        totalGame: $totalGame,
        totalMin: $totalMin,
        avgMinPerGame: $avgMinPerGame,
        goal: $goal,
        ownGoal: $ownGoal,
        cardYellow: $cardYellow,
        cardRed: $cardRed) {
        gameStatAggrId
        sleagueTeamPlayerId
        filterId
        fullGame
        fullMin
        subinGame
        subinMin
        suboutGame
        suboutMin
        subInOutGame
        subInOutMin
        ejectGame
        ejectMin
        totalGame
        totalMin
        avgMinPerGame
        goal
        ownGoal
        cardYellow
        cardRed
        
      }
    }
`;

const GetAllGameStatsAggr_query = `
query{
      gameStatAggr{
        gameStatAggrId
        sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        fullGame
        fullMin
        subinGame
        subinMin
        suboutGame
        suboutMin
        subInOutGame
        subInOutMin
        ejectGame
        ejectMin
        totalGame
        totalMin
        avgMinPerGame
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
`;

const GetGameStatsAggrBySTPId_query = `

  query($sleagueTeamPlayerId: Int!){

    gameStatAggrSleagueteamplayer(sleagueTeamPlayerId: $sleagueTeamPlayerId){
        gameStatAggrId
        sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        fullGame
        fullMin
        subinGame
        subinMin
        suboutGame
        suboutMin
        subInOutGame
        subInOutMin
        ejectGame
        ejectMin
        totalGame
        totalMin
        avgMinPerGame
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
`;

const GetGameStatsAggrBySleagueTeamId_query = `

  query($sleagueTeamId: Int!){

    gameStatAggrSleagueteam(sleagueTeamId: $sleagueTeamId){
        gameStatAggrId
        sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueTeamId
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        fullGame
        fullMin
        subinGame
        subinMin
        suboutGame
        suboutMin
        subInOutGame
        subInOutMin
        ejectGame
        ejectMin
        totalGame
        totalMin
        avgMinPerGame
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
`;

const EditGameStatsAggr_mutation = `
mutation editGamestataggr( $gameStatAggrId: Int!,
                            $fullGame: Int,  $fullMin: Int,
                            $subinGame: Int, $subinMin: Int, $suboutGame: Int,
                            $suboutMin: Int, $subInOutGame: Int, $subInOutMin: Int, $ejectGame: Int, $ejectMin: Int,
                            $totalGame: Int, $totalMin: Int,
                            $avgMinPerGame: Int, $goal: Int, $ownGoal: Int, 
                            $cardYellow:Int, $cardRed: Int
) {
  editGamestataggr(
    gameStatAggrId: $gameStatAggrId,
    fullGame: $fullGame,
    fullMin: $fullMin,
    subinGame: $subinGame,
    subinMin: $subinMin,
    suboutGame: $suboutGame,
    suboutMin: $suboutMin,
    subInOutGame: $subInOutGame,
    subInOutMin: $subInOutMin,
    ejectGame: $ejectGame,
    ejectMin: $ejectMin,
    totalGame: $totalGame,
    totalMin: $totalMin,
    avgMinPerGame: $avgMinPerGame,
    goal: $goal,
    ownGoal: $ownGoal,
    cardYellow: $cardYellow,
    cardRed: $cardRed) {
    gameStatAggr{
      gameStatAggrId
      sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueTeamId
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        fullGame
        fullMin
        subinGame
        subinMin
        suboutGame
        suboutMin
        subInOutGame
        subInOutMin
        ejectGame
        ejectMin
        totalGame
        totalMin
        avgMinPerGame
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
}
`;

module.exports = {
  CREATE_GAME_STAT_AGGR_MUTATION : CreateGameStatAggr_mutate,
  GET_ALL_GAME_STATS_AGGR_QUERY : GetAllGameStatsAggr_query,
  EDIT_GAME_STAT_AGGR_MUTATION : EditGameStatsAggr_mutation,
  GET_GAME_STATS_AGGR_By_ID_QUERY : GetGameStatsAggrBySTPId_query,
  GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY: GetGameStatsAggrBySleagueTeamId_query
}
