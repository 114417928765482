import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
//import { ThemeProvider } from "@material-ui/core";
//import { createTheme } from '@material-ui/core/styles';
import { useMutation, ClientContext, GraphQLClient } from "graphql-hooks";
import { useMediaQuery } from "react-responsive";

///////// Admin center pages

import AdminSignIn from "./userPages/signIn/signInAdmin";

import Position from "./adminPages/Position.js";
import PositionList from "./adminPages/PositionList.js";

import RegisterUsersList from "./adminPages/registerUser/RegisterUsersList.js";

import Player from "./adminPages/Player.js";
import PlayerEdit from "./adminPages/PlayerEdit.js";
import PlayerList from "./adminPages/PlayerList.js";

import League from "./adminPages/League.js";
import LeagueList from "./adminPages/LeagueList.js";

import Sleague from "./adminPages/Sleague.js";
import SleagueList from "./adminPages/SleagueList.js";

import Formation from "./adminPages/Formation.js";
import FormationList from "./adminPages/FormationList.js";

import GameStat from "./adminPages/GameStat.js";

import FixPlayerStats from "./adminPages/fixturePlayerStats/FixPlayerStats.js";
import FixPlayerStatsFull from "./adminPages/fixturePlayerStatsFull/FixPlayerStatsFull.js";
import FixturePlayerStats from "./adminPages/FixturePlayerStats.js";
import FixturePlayerStatsEdit from "./adminPages/FixturePlayerStatsEdit.js";

import Filter from "./adminPages/Filter.js";
import FilterList from "./adminPages/FilterList.js";

import Setting from "./adminPages/Setting.js";
import SettingList from "./adminPages/SettingList.js";
import SettingEdit from "./adminPages/SettingEdit.js";
import ServerDate from "./adminPages/ServerDate.js";

import Logout from "./adminPages/Logout.js";

import Team from "./adminPages/Team.js";
import TeamList from "./adminPages/TeamList.js";
import TeamEdit from "./adminPages/TeamEdit.js";

import AssignTeamToSLeague from "./adminPages/AssignTeamToSLeague.js";
import SleagueTeamList from "./adminPages/SleagueTeamList.js";
import SleagueTeamTableStanding from "./adminPages/SleagueTeamTableStanding.js";
import SleagueTeamRanking from "./adminPages/SleagueTeamRanking.js";

import AdminDashboard from "./adminPages/AdminDashboard.js";

import FormationToLeague from "./adminPages/FormationToLeague.js";
import FormationToLeagueList from "./adminPages/FormationToLeagueList.js";

import PositionToLeagueFormation from "./adminPages/PositionToLeagueFormation.js";
import LeagueFormationPositionList from "./adminPages/LeagueFormationPositionList.js";

import SleagueWeekList from "./adminPages/SleagueWeekList.js";
import Week from "./adminPages/Week.js";
import WeekEdit from "./adminPages/WeekEdit.js";
import CurrentBucket from "./adminPages/CurrentBucket.js";
import CurrentBucketWeek from "./adminPages/CurrentBucketWeek.js";

import SleagueTeamPlayer from "./adminPages/SleagueTeamPlayer";
import SleagueTeamPlayerList from "./adminPages/SleagueTeamPlayerList.js";
import SleagueTeamPlayerEdit from "./adminPages/SleagueTeamPlayerEdit.js";

import ContactList from "./adminPages/ContactList.js";

import SleagueAward from "./adminPages/SleagueAward";
import SleagueAwardList from "./adminPages/SleagueAwardList";
import SleagueAwardEdit from "./adminPages/SleagueAwardEdit.js";

import Algorithm from "./adminPages/Algorithm";
import AlgorithmList from "./adminPages/AlgorithmList";

import Fixture from "./adminPages/Fixture.js";
import FixtureList from "./adminPages/FixtureList.js";
import FixtureLock from "./adminPages/FixtureLock.js";
import FixtureEdit from "./adminPages/FixtureEdit.js";
import FixtureReschedule from "./adminPages/FixtureReschedule.js";

import ComputeTSPositionSize from "./adminPages/tsPosComputation/ComputeTSPositionSize.js";

import FavTeamFixtures from "./userPages/favTeamFixtures/FavTeamFixtures";
import FavTeamResults from "./userPages/FavTeamResults/FavTeamResults";

import CommunitySelection from "./adminPages/CommunitySelection";
import TestTeamSelection from "./adminPages/TestTeamSelection";

import UsersTeamSelection from "./adminPages/UsersTeamSelection";
import PlayerAssignment from "./adminPages/PlayerAssignment";
import GamestatPositionseqno from "./adminPages/GamestatPositionseqno";
import SetUserFavTeamSeq from "./adminPages/SetUserFavTeamSeq";

import SPCalculator from "./adminPages/SPCalculator";

import Profile from "./adminPages/Profile.js";

import TeamSelectionResults from "./adminPages/TeamSelectionResults";
import AdminFavTeamSelector from "./adminPages/favTeamSelection/AdminFavTeamSelector";
import CoachTeamSelector from "./adminPages/coach/CoachTeamSelector.js";
import SelectedTeamCommunityVotes from "./adminPages/favTeamResults/votes/SelectedTeamCommunityVotes.js";

import TeamSelectionResultsNitofication from "./adminPages/email/TeamSelectionResultsNitofication.js";
import NotifyUserEmail from "./adminPages/email/NotifyUserEmail.js";

import CRMInterestSubmissionsList from "./adminPages/CRM/CRMInterestSubmissionsList";

import SPAwardAutomationScript from "./adminPages/SPAwardAutomationScript";

/////////  User pages
import SignIn from "./userPages/signIn/signIn";
import Registration from "./userPages/registration/registration";
import SuccessRegistration from "./userPages/registration/successRegistration";
import ForgetPass from "./userPages/forgetPassword/forgetPass";
import ResetPass from "./userPages/resetPassword/resetPass";
import ResetPassConfirmation from "./userPages/resetPassword/ResetPassConfirmation";
import Activation from "./userPages/activation/activation";
import Dashboard from "./userPages/dashboard/dashboard";
import UserProfile from "./userPages/userProfile/userProfile";
import UserTeamSelectorAppMain from "./userPages/userTs/UserTeamSelectorAppMain";
import Footy from "./userPages/footy/Footy";
import FootyView from "./userPages/footy/FootyView";
import SPLedger from "./userPages/spLedger/spLedger";
import StandingTable from "./userPages/standingTable/standingTable";
import ScoreBoard from "./userPages/scoreBoard/scoreBoard";
import CoachCommunity from "./userPages/coachCommunity/coachCommunity";
import FavTeamSelector from "./userPages/favTeamSelection/FavTeamSelector.js";

import InterestedForm from "./crm/interestedform.js";
import ContactForm from "./crm/ContactForm.js";

import Header from "./components/Header";
import Footer from "./components/Footer";
import StaticFooter from "./components/StaticFooter";

import AboutUs from "./staticPages/AboutUs.js";
import PrivacyPolicy from "./staticPages/PrivacyPolicy.js";
import TermsOfUse from "./staticPages/TermsOfUse.js";
import HowItWorks from "./staticPages/HowItWorks.js";
import CookiePolicy from "./staticPages/CookiePolicy.js";
import Homepage from "./staticPages/Homepage.js";
import SportPointsAwards from "./staticPages/SportPointsAwards.js";
import HelmatTest from "./staticPages/HelmatTest.js";
/////////

import typography from "./theme/typography";
import palette from "./theme/palette";
import CryptoJS from "crypto-js";
import {
  project_url,
  admin_url,
  secretPass,
  content_display_config,
} from "./store/data/Constants";

const theme = createMuiTheme({
  //const theme = createTheme({
  shadows: ["none"],
  typography,
  palette,
});
/*##########################################################################
## Const : Root
## Description : Client Context page
## Author : SP
## Date: : 2023/01/15
##########################################################################*/
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    minHeight: "100vh",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

function Root() {
  const classes = useStyles();
  const [staticPage, setStaticPage] = useState(false);
  const getToken = () => localStorage.getItem("token");
  const token = getToken();
  const client = new GraphQLClient({
    // url: "http://localhost:8000/lms/graphql",
    // useGETForQueries: false,
    url: "/lms/graphql",
    headers: { Authorization: token ? `JWT ${token}` : "" },
    fetchOptions: () => {
      return {
        headers: { Authorization: token ? `JWT ${token}` : "" },
      };
    },
  });

  const StaticPageLayout = () => {
    setStaticPage(true);
    const location = useLocation();
    console.log(location);
    if (location.pathname.indexOf("about-us.html") !== -1) return <AboutUs />;
    if (location.pathname.indexOf("privacy-policy.html") !== -1) return <PrivacyPolicy />;
    if (location.pathname.indexOf("cookie-policy.html") !== -1) return <CookiePolicy />;
    if (location.pathname.indexOf("terms-of-use.html") !== -1) return <TermsOfUse />;
    if (location.pathname.indexOf("how-team-selector-works.html") !== -1)
      return <HowItWorks />;
    if (location.pathname.indexOf("sport-points-awards.html") !== -1)
      return <SportPointsAwards />;
    if (location.pathname.indexOf("helmet-test.html") !== -1) return <HelmatTest />;
    if (
      location.pathname.indexOf("index.html") !== -1 ||
      location.pathname === "/lms/" ||
      location.pathname === "/"
    )
      return <Homepage />;
    else
      return (
        <h3>
          <br />
          <center>Invalid URL</center>
        </h3>
      );
  };

  const AllPageLayout = () => {
    const location = useLocation();
    if (location.pathname.indexOf(".html") === -1) setStaticPage(false);
    return <></>;
  };

  const ProtectedLayout = () => {
    var role = null;
    if (localStorage.getItem("role"))
      role = CryptoJS.AES.decrypt(localStorage.getItem("role"), secretPass).toString(
        CryptoJS.enc.Utf8
      );
    if (!role || role !== "Admin") {
      return (
        <h3>
          <br />
          <center>
            You are in the wrong place! Go to Predictal <Link to="/">Homepage</Link>.
          </center>
        </h3>
      );
    }

    // const token = localStorage.getItem("token");
    // const username = localStorage.getItem("username");
    // const autoLogin = localStorage.getItem("autoLogin");

    // if (!token || !username) {
    //   if (isDesktop) return <SignIn />;
    //   if (isTabletDevice) return <SignInTablet />;
    //   if (isMobileDevice) return <SignInMobile />;
    // }
    // if (!autoLogin || autoLogin === false || autoLogin === "false" || autoLogin === 0) {
    //   let rtsLocation = window.sessionStorage.getItem("rtsLocation");
    //   if (!rtsLocation) {
    //     if (isDesktop) return <SignIn />;
    //     if (isTabletDevice) return <SignInTablet />;
    //     if (isMobileDevice) return <SignInMobile />;
    //   }
    // }

    return (
      <div>
        <Outlet />
      </div>
    );
  };

  const RequireAuthLayout = ({ children }) => {
    // const token = localStorage.getItem("token");
    // const username = localStorage.getItem("username");
    // const autoLogin = localStorage.getItem("autoLogin");

    // if (!token || !username) {
    //   if (isDesktop) return <SignIn />;
    //   if (isTabletDevice) return <SignInTablet />;
    //   if (isMobileDevice) return <SignInMobile />;
    // }
    // if (!autoLogin || autoLogin === false || autoLogin === "false" || autoLogin === 0) {
    //   let rtsLocation = window.sessionStorage.getItem("rtsLocation");
    //   if (!rtsLocation) {
    //     if (isDesktop) return <SignIn />;
    //     if (isTabletDevice) return <SignInTablet />;
    //     if (isMobileDevice) return <SignInMobile />;
    //   }
    // }

    return children;
  };
  const adminSignin = "/lms/" + admin_url + "/signin";
  const adminSignout = "/lms/" + admin_url + "/signout";
  const adminPaths = "/lms/" + admin_url;

  return (
    <ThemeProvider theme={theme}>
      <ClientContext.Provider value={client}>
        <BrowserRouter>
          <div className={classes.root}>
            {staticPage && content_display_config && <Header />}
            {!staticPage && <Header />}
            <div className={classes.wrapper}>
              <div className={classes.contentContainer}>
                <div className={classes.content}>
                  <AllPageLayout />
                  <Routes>
                    // Admin Pages
                    <Route path={adminSignin} element={<AdminSignIn />} />
                    <Route path={adminSignout} element={<Logout />} />
                    <Route path="/lms/i-am-interested" element={<InterestedForm />} />
                    <Route path="/lms/contact.html" element={<ContactForm />} />
                    <Route path="/lms/registration" element={<Registration />} />
                    <Route
                      path="/lms/register_success"
                      element={<SuccessRegistration />}
                    />
                    <Route path="/lms/forgot_password" element={<ForgetPass />} />
                    <Route
                      path="/lms/reset_password/:forgetCode"
                      element={<ResetPass />}
                    />
                    <Route
                      path="/lms/reset_password_confirmation"
                      element={<ResetPassConfirmation />}
                    />
                    <Route
                      path="/lms/register_activation/:activationCode"
                      element={<Activation />}
                    />
                    <Route path="/lms/dashboard" element={<Dashboard />} />
                    <Route path="/lms/profile" element={<UserProfile />} />
                    <Route
                      path="/lms/ts/myteam/:fixtureTeamId"
                      element={<UserTeamSelectorAppMain />}
                    />
                    <Route path="/lms/coachCommunity" element={<CoachCommunity />} />
                    <Route
                      path="/lms/ts/3teams/:fixtureTeamId"
                      element={<FavTeamSelector />}
                    />
                    <Route path={adminPaths} element={<ProtectedLayout />}>
                      <Route path="position" element={<PositionList />} />
                      <Route path="position/add" element={<Position />} />

                      <Route path="user/users" element={<RegisterUsersList />} />

                      <Route path="contact" element={<ContactList />} />

                      <Route path="player" element={<PlayerList />} />
                      <Route path="player/edit/:playerId" element={<PlayerEdit />} />
                      <Route path="player/add" element={<Player />} />

                      <Route path="league" element={<LeagueList />} />
                      <Route path="league/add" element={<League />} />

                      <Route path="sleague" element={<SleagueList />} />
                      <Route path="sleague/add" element={<Sleague />} />

                      <Route path="formation" element={<FormationList />} />
                      <Route path="formation/add" element={<Formation />} />

                      <Route path="fixture-player-stats/add" element={<GameStat />} />
                      <Route
                        path="fixture-player-stats"
                        element={<FixturePlayerStats />}
                      />
                      <Route
                        path="fixture-player-stats-improved"
                        element={<FixPlayerStats />}
                      />
                      <Route
                        path="fixture-player-stats-improved-full"
                        element={<FixPlayerStatsFull />}
                      />
                      <Route
                        path="fixture-player-stats/edit/:sleagueTeamPlayerId"
                        element={<FixturePlayerStatsEdit />}
                      />

                      <Route path="filter/add" element={<Filter />} />
                      <Route path="filter" element={<FilterList />} />

                      <Route path="setting/add" element={<Setting />} />
                      <Route path="setting" element={<SettingList />} />
                      <Route path="setting/edit/:settingId" element={<SettingEdit />} />
                      <Route path="server-date" element={<ServerDate />} />

                      <Route path="team" element={<TeamList />} />
                      <Route path="team/add" element={<Team />} />
                      <Route path="team/:teamId" element={<TeamEdit />} />

                      <Route path="sleague-team" element={<SleagueTeamList />} />

                      <Route
                        path="sleague-table"
                        element={<SleagueTeamTableStanding />}
                      />

                      <Route
                        path="sleague-team-ranking"
                        element={<SleagueTeamRanking />}
                      />
                      <Route path="sleague-team/add" element={<AssignTeamToSLeague />} />

                      <Route path="dashboard" element={<AdminDashboard />} />

                      <Route
                        path="league-formation"
                        element={<FormationToLeagueList />}
                      />
                      <Route
                        path="league-formation/add"
                        element={<FormationToLeague />}
                      />

                      <Route
                        path="formation-position"
                        element={<LeagueFormationPositionList />}
                      />
                      <Route
                        path="formation-position/add"
                        element={<PositionToLeagueFormation />}
                      />

                      <Route path="sleague-week" element={<SleagueWeekList />} />
                      <Route path="sleague-week/add" element={<Week />} />
                      <Route path="sleague-week/:weekId" element={<WeekEdit />} />

                      <Route path="current-bucket-sleague" element={<CurrentBucket />} />
                      <Route path="current-bucket-week" element={<CurrentBucketWeek />} />

                      <Route
                        path="sleague-team-player/add"
                        element={<SleagueTeamPlayer />}
                      />
                      <Route
                        path="sleague-team-player"
                        element={<SleagueTeamPlayerList />}
                      />
                      <Route
                        path="sleague-team-player/edit/:sleagueTeamPlayerId"
                        element={<SleagueTeamPlayerEdit />}
                      />

                      <Route path="sleague-award" element={<SleagueAwardList />} />
                      <Route path="sleague-award/add" element={<SleagueAward />} />
                      <Route
                        path="sleague-award/edit/:awardId"
                        element={<SleagueAwardEdit />}
                      />

                      <Route path="algorithm" element={<AlgorithmList />} />
                      <Route path="algorithm/add" element={<Algorithm />} />

                      <Route path="fixture" element={<FixtureList />} />
                      <Route path="fixture/lock" element={<FixtureLock />} />
                      <Route path="fixture/add" element={<Fixture />} />
                      <Route path="fixture/edit/:fixtureId" element={<FixtureEdit />} />
                      <Route
                        path="fixture/reschedule/:fixtureId"
                        element={<FixtureReschedule />}
                      />

                      <Route
                        path="community_selection"
                        element={<CommunitySelection />}
                      />

                      <Route path="test_teamselection" element={<TestTeamSelection />} />

                      <Route
                        path="users_teamselection"
                        element={<UsersTeamSelection />}
                      />

                      <Route path="player_assignment" element={<PlayerAssignment />} />

                      <Route
                        path="gamestat_positionseqno"
                        element={<GamestatPositionseqno />}
                      />

                      <Route
                        path="set_user_fav_team_seq"
                        element={<SetUserFavTeamSeq />}
                      />

                      <Route path="sp_calculator" element={<SPCalculator />} />

                      <Route
                        path="sp_award_automation_script"
                        element={<SPAwardAutomationScript />}
                      />

                      <Route path="fixtures" element={<TeamSelectionResults />} />
                      <Route
                        path="ts/3teams/:fixtureTeamId"
                        element={<AdminFavTeamSelector />}
                      />
                      <Route
                        path="ts/coachteam/:fixtureTeamId"
                        element={<CoachTeamSelector />}
                      />
                      <Route
                        path="ts/votes/:fixtureTeamId"
                        element={<SelectedTeamCommunityVotes />}
                      />

                      <Route
                        path="tsresultsemail"
                        element={<TeamSelectionResultsNitofication />}
                      />
                      <Route path="notifyUserEmail" element={<NotifyUserEmail />} />

                      <Route
                        path="crm/crm-interest-submissions"
                        element={<CRMInterestSubmissionsList />}
                      />
                    </Route>
                    <Route
                      path="/lms/ts-compute-pos-slice"
                      element={
                        <RequireAuthLayout>
                          <ComputeTSPositionSize />{" "}
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/footy-no"
                      element={
                        <RequireAuthLayout>
                          <Footy />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/footy-view/:footyNo"
                      element={
                        <RequireAuthLayout>
                          <FootyView />
                        </RequireAuthLayout>
                      }
                    />
                    // User Favourite team fixtures & select XI players
                    <Route
                      path="/lms/fixtures"
                      element={
                        <RequireAuthLayout>
                          <FavTeamFixtures />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/ts/votes/:fixtureTeamId"
                      element={
                        <RequireAuthLayout>
                          <FavTeamResults />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/profile_v1"
                      element={
                        <RequireAuthLayout>
                          <Profile />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/sp-ledger"
                      element={
                        <RequireAuthLayout>
                          <SPLedger />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/table"
                      element={
                        <RequireAuthLayout>
                          <StandingTable />
                        </RequireAuthLayout>
                      }
                    />
                    <Route
                      path="/lms/sp-board"
                      element={
                        <RequireAuthLayout>
                          <ScoreBoard />
                        </RequireAuthLayout>
                      }
                    />
                    //User Pages
                    <Route path="/lms/signin" element={<SignIn />} />
                    <Route path="/lms/signout" element={<Logout />} />
                    <Route path="*" element={<StaticPageLayout />} />
                  </Routes>
                </div>
              </div>
            </div>
            {!staticPage && <Footer />}
            {staticPage && content_display_config && <StaticFooter />}
          </div>
        </BrowserRouter>
      </ClientContext.Provider>
    </ThemeProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
