import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { seo_tag_config } from "../../store/data/Constants";

import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 100,
    maxWidth: 806,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 750,
    height: 468,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 250,
  },
  cardContent: {
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    minHeight: 250,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 250,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 250,
  },
  cardContentMobile: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 90,
  },
}));

const SuccessRegistration = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const [userEmailAddress, setUseEmailAddress] = useState(null);
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  useEffect(() => {
    console.log(
      "localStorage.getItemUserEmail",
      localStorage.getItem("userRegisterEmail")
    );
    setUseEmailAddress(localStorage.getItem("userRegisterEmail"));
  }, [localStorage]);
  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Successful Registration</title>
          <meta
            name="description"
            content="Predictal user registration to join football prediction"
          />
          <meta
            property="keywords"
            content="Predcital, user registration, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction"
          />
        </Helmet>
      }
      
      {/*userEmailAddress && */ mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
          <Box alignItems="center" mb={3} flexGrow={1}>
            <Box textAlign="center">
              <Typography variant="subtitle2">
                Welcome to Predictal!
              </Typography>
            </Box>
            <Typography variant="body2">
              Thank you for registering with Predictal. Your user account is
              created. You will receive an email from{" "}
              <a href="mailto:support@predictal.com" target="_self">
                support@predictal.com
              </a>.
              <br />
              <br />
              <Box textAlign="center">We sent the email to:</Box>
            </Typography>
            <Box textAlign="center">
              <Typography
                variant="body2"
                style={{ fontSize: 18, fontWeight: 600 }}
              >
                {userEmailAddress}
              </Typography>
            </Box>
            <Typography variant="body2">
              <br />
              Please check your inbox. To activate your account, confirm
              your email address by clicking the link in the email we sent
              you.
              <br />
              <br />
              If you did not receive the email in your inbox, check your
              spambox. You should have received an email from Predictal. If
              you still cannot find the email, please contact{" "}
              <a href="mailto:support@predictal.com" target="_self">
                support@predictal.com
              </a>{" "}
              and we will help you activate your account.
              <br />
              <br />
              Predictal | Reimagine Selecting Your Starting XI Team.
            </Typography>
          </Box>
          </CardContent>
        </Card>
      )}
      {/*userEmailAddress && */ tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
            <Box alignItems="center" mb={3} flexGrow={1}>
              <Box textAlign="center">
                <Typography variant="subtitle1">
                  Welcome to Predictal!
                </Typography>
              </Box>
              <Typography variant="body1">
                Thank you for registering with Predictal. Your user account is
                created. You will receive an email from{" "}
                <a href="mailto:support@predictal.com" target="_self">
                  support@predictal.com
                </a>.
                <br />
                <br />
                <Box textAlign="center">We sent the email to:</Box>
              </Typography>
              <Box textAlign="center">
                <Typography
                  variant="body1"
                  style={{ fontSize: 20, fontWeight: 600 }}
                >
                  {userEmailAddress}
                </Typography>
              </Box>
              <Typography variant="body1">
                <br />
                Please check your inbox. To activate your account, confirm
                your email address by clicking the link in the email we sent
                you.
                <br />
                <br />
                If you did not receive the email in your inbox, check your
                spambox. You should have received an email from Predictal. If
                you still cannot find the email, please contact{" "}
                <a href="mailto:support@predictal.com" target="_self">
                  support@predictal.com
                </a>{" "}
                and we will help you activate your account.
                <br />
                <br />
                Predictal | Reimagine Selecting Your Starting XI Team.
              </Typography>
            </Box>
            </CardContent>
          </Card>
        </Container>
      )}
      {/*userEmailAddress && */ !mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box textAlign="center">
                  <Typography variant="subtitle1">
                    Welcome to Predictal!
                  </Typography>
                </Box>
                <Typography variant="body1">
                  Thank you for registering with Predictal. Your user account is
                  created. You will receive an email from{" "}
                  <a href="mailto:support@predictal.com" target="_self">
                    support@predictal.com
                  </a>.
                  <br />
                  <br />
                  <Box textAlign="center">We sent the email to:</Box>
                </Typography>
                <Box textAlign="center">
                  <Typography
                    variant="body1"
                    style={{ fontSize: 20, fontWeight: 600 }}
                  >
                    {userEmailAddress}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  <br />
                  Please check your inbox. To activate your account, confirm
                  your email address by clicking the link in the email we sent
                  you.
                  <br />
                  <br />
                  If you did not receive the email in your inbox, check your
                  spambox. You should have received an email from Predictal. If
                  you still cannot find the email, please contact{" "}
                  <a href="mailto:support@predictal.com" target="_self">
                    support@predictal.com
                  </a>{" "}
                  and we will help you activate your account.
                  <br />
                  <br />
                  Predictal | Reimagine Selecting Your Starting XI Team.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
};

export default SuccessRegistration;
