import { memo, useState, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core";
import { REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";

const useStyles = makeStyles({
  mainStyle: {
    color: "white",
    textAlign: "center",
    font: "11px Arial, sans-serif",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    border: "1px",
  },
  pStyle: {
    backgroundColor: "#E0E0E0",
    borderRadius: "50%",
    width: "35px", //38
    height: "35px", //38
    lineHeight: "45px",
    background: "#fff",
    color: "#000",
    justifyContent: "center",
    font: "7.5px Arial, sans-serif", //8
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "2px",
    marginTop: " 2px",
    letterSpacing:"-0.05px"
  },
  droppedCnt: {
    display: "flex",
    border: "1px solid #FFFFFF",
    color: "#212121",
    position: "relative",
    background: "#9FC6A3",
    borderRadius: 2,
    padding: "1px 1px 0px 0px",
    height: 17,
    width: 59,
    font: "8.5px Arial, sans-serif", //8
    letterSpacing: "-0.3px", // -0.2
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
    position: "relative",
    cursor: "pointer",
  },
});
export const Selection = memo(function Selection({
  accept,
  text,
  lastDroppedItem,
  height,
  width,
  img,
  onDrop,
}) {
  const classes = useStyles();
  const [pathname] = useState(window.location.pathname);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  return (
    <div
      className={classes.mainStyle}
      style={{
        height,
        width,
        backgroundImage: `url(${img})`,
        //backgroundRepeat: "no-repeat",
        flexDirection: "column",
        //border: "1px solid white"
      }}
    >
      <div
        ref={drop}
        role="Selection"
        className={classes.mainStyle}
        style={{
          height: "100%",
          width: "100%",
          flexDirection: "column",
          //border: "1px solid black"
        }}
      >
        <p className={classes.pStyle}>{text}</p>
        {lastDroppedItem && (
          <div className={classes.droppedCnt}>
            <p style={{ margin: "2px" }}>
              {lastDroppedItem.jerseyNO} {lastDroppedItem.onFieldName}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
