import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import PlayerInfo from "./PlayerInfo";
import PastFixtures from "./PastFixtures";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RightPanelBasicTabs = ({
  droppedPlayerNamesData,
  userSeqData,
  userFavTeam,
  teamSelectionData,
  sleagueTeamId,
  fixtureId,
  embargoTime,
  serverTime,
  pastFixtures,
  passCopyPastFixtureDataTS,
}) => {
  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box pt={2} sx={{ width: "100%" }}>
      <Box /*sx={{ borderBottom: 1, borderColor: "divider" }}*/>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          style={{ marginLeft: 13, height: "48px" }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              width: "49%",
              fontSize: 15,
              fontWeight: 500,
              textTransform: "none",
              borderTop: value === 0 && "1px solid darkGray",
              borderLeft: value === 0 && "1px solid darkGray",
              borderRight: value === 0 && "1px solid darkGray",
              borderRadius: value === 0 && "4px 4px 0px 0px",
              borderBottom: value === 1 && "1px solid darkGray",
              color: value === 0 ? "#6A1B9A" /*"#765285"*/ : "#4D4D4D",
            }}
            label="Team Roster"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              width: "49%",
              fontSize: 15,
              fontWeight: 500,
              textTransform: "none",
              borderTop: value === 1 && "1px solid darkGray",
              borderLeft: value === 1 && "1px solid darkGray",
              borderRight: value === 1 && "1px solid darkGray",
              borderBottom: value === 0 && "1px solid darkGray",
              borderRadius: value === 1 && "4px 4px 0px 0px",
              color: value === 1 ? "#6A1B9A" /*"#765285"*/ : "#4D4D4D",
            }}
            label="Past Fixtures"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PlayerInfo
          userSeqData={userSeqData}
          userFavTeam={userFavTeam}
          droppedPlayerNamesData={droppedPlayerNamesData}
          teamSelectionData={teamSelectionData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PastFixtures
          pastFixtures={pastFixtures}
          sleagueTeamId={sleagueTeamId}
          fixtureId={fixtureId}
          embargoTime={embargoTime}
          serverTime={serverTime}
          passCopyPastFixtureData={passCopyPastFixtureDataTS}
        />
      </CustomTabPanel>
    </Box>
  );
};
export default RightPanelBasicTabs;
