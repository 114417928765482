import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { CONTACT_SUBMITS_LIST } from "../graphQL/crm_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function ContactList() {
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [contactData, setContactData] = useState([]);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(CONTACT_SUBMITS_LIST, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.contactSubmitsList) {
        setContactData(data.contactSubmitsList);
        console.log("allData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, contactData]
  );

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false
    },
    {
      field: "country",
      headerName: "Country",
      width: 190,
      editable: false
    },
    {
      field: "topic",
      headerName: "topic",
      width: 500,
      editable: false
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 500,
      editable: false
    },
    {
      field: "submitDate",
      headerName: "Submit Date",
      width: 500,
      editable: false
    }
  ];
  
  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };

  const rows = contactData.map((contact, id) => ({
    id: contact.contactId,
    submitDate: dayFormat(contact.createDate),
    email: contact.email,
    country: contact.country,
    name: contact.name,
    topic: contact.topic,
    comment:contact.comment,
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Contacts | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Contacts</h2>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
