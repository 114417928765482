import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import adminStyle from "./Admin.module.scss";

import { GET_ALL_TEAM_QUERY } from "../graphQL/team_qry";
import { GET_ALL_SLEAGUES_QUERY } from "../graphQL/sleague_qry";
import { CREATE_SLEAGUE_TEAM_MUTATION } from "../graphQL/sleague_team_query";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";

// const TEAM = [{ teamId: 1, name: "team1" }, { teamId: 2, name: "team2" }, { teamId: 3, name: "team3" }]
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const AssignTeamToSLeague = props => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const {
    loading: teamLoading,
    error: teamError,
    data: allTeamsData
  } = useQuery(GET_ALL_TEAM_QUERY);
  const [createSleagueTeam] = useMutation(CREATE_SLEAGUE_TEAM_MUTATION);
  const [teamsData, setTeamsData] = useState([]);
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: allSLeagueData
  } = useQuery(GET_ALL_SLEAGUES_QUERY);
  const [sleagueData, setSleagueData] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };
  
  useEffect(
    () => {
      allTeamsData && setTeamsData(allTeamsData.team);
    },
    [allTeamsData]
  );

  useEffect(
    () => {
      allSLeagueData && setTeamsData(allSLeagueData.sleague);
      if (
        sleagueError &&
        sleagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [allSLeagueData]
  );

  const clearData = () => {
    setFailedMessage("");
    setSuccessMessage("");
  };

  const onSubmit = async formData => {
    clearData();
    const newData = {
      sleagueId: parseInt(formData.sleague),
      teamId: parseInt(formData.team),
      rank: null
    };

    const { data, error } = await createSleagueTeam({ variables: newData });
    if (error) {
      console.log("Error", error, data);
      setFailedMessage("Unfortunately an error occurred.");
      //  setTimeout("");
    } else if (data && data.createSleagueTeam) {
      console.log("Success", data.createSleagueTeam);
      setSuccessMessage("team assigned successfully.");
      //  setTimeout("");
      await sleep(4000);
      clearData();
      // const form = document.getElementById("team_form");
      // form.reset();
      navigate(`/lms/${admin_url}/sleague-team`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Add Team to League Season | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Assign a team to a league seasion</h2>
          <label className={adminStyle.label} htmlFor="season">
            League Season
          </label>
          <select
            className={adminStyle.select}
            id="sleague"
            {...register("sleague")}
          >
            {allSLeagueData &&
              allSLeagueData.sleague.map(s => (
                <option key={s.sleagueId} value={s.sleagueId}>
                  {s.title}
                </option>
              ))}
          </select>
          <label className={adminStyle.label} htmlFor="team">
            Team
          </label>
          <select className={adminStyle.select} id="team" {...register("team")}>
            {allTeamsData &&
              allTeamsData.team.map(t => (
                <option key={t.teamId} value={t.teamId}>
                  {t.name}
                </option>
              ))}
          </select>
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
};

export default AssignTeamToSLeague;
