import React, { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import CookieBubble from "react-cookie-bubble";
import StaticLayout from "../components/StaticLayout.js";
import { Helmet } from "react-helmet";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import overlapGroup2 from "../assets/overlapGroup2.png";
import background from "../assets/background.png";
import logo from "../assets/preditcal_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import communityVotes from "../assets/Votes1.jpg";
import teamSelectorImg from "../assets/team-selector-web.jpg";
import teamsH2hImg from "../assets/Community team-team-web.jpg";
import victoryVideo from "../assets/predictal-victory.mp4";
import { content_display_config, seo_tag_config } from "../store/data/Constants";

import { GET_USERPROFILE_QUERY } from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { styled } from "@mui/material/styles";
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 15,
	paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1610,
	width: "100% !important",
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 1330,
  },
  footerCardContent: {
    backgroundColor: "#662483",
	borderRadius: "0 !important",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
	marginLeft: 0,
	marginRight: 0
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  readMoreButtonStyle: {
    width: "140px !important",
    height: "42px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  pickTeamButtonStyle: {
    width: "270px !important",
    height: "50px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  smallButtonStyle: {
    width: "190px !important",
    height: "50px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	marginBottom: 40,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  mobileReadMoreButtonStyle: {
    width: "117px !important",
    height: "63px",
    textTransform: "none",
	color: "white !important",
	fontFamily: "Poppins, sans-serif",
	fontSize: "1.1rem",
	borderRadius: 0,
	fontWeight: 500,
	marginLeft: 0,
	backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  videoContainer: {
    padding: 0,
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
}));

/*##########################################################################
## Const : Homepage
## Description : About Us page
## Author : RR
## Date: : 2022/05/08
##########################################################################*/
const Homepage = () => {
  const [userData, setUserData] = useState([]);
  const [sleagueId, setSleagueId] = useState(0);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [favFixtureTeam, setFavFixtureTeam] = useState("");
  const [lastFixtureTeam, setLastFixtureTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [h2hOpen, setH2hOpen] = React.useState(false);
  const [teamSelectorOpen, setTeamSelectorOpen] = React.useState(false);
  const [communityOpen, setCommunityOpen] = React.useState(false);
  
  const handleH2hOpen = () => {
    setH2hOpen(true);
  };
  const handleH2hClose = () => {
    setH2hOpen(false);
  };
  const handleTeamSelectorOpen = () => {
    setTeamSelectorOpen(true);
  };
  const handleTeamSelectorClose = () => {
    setTeamSelectorOpen(false);
  };
  const handleCommunityVotesOpen = () => {
    setCommunityOpen(true);
  };
  const handleCommunityVotesClose = () => {
    setCommunityOpen(false);
  };

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    console.log(window.innerWidth);
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (!loading && data && data.profile) {
	  console.log(data.profile);
      setUserData(data.profile);
	  setSleagueId(data.profile.userFavTeamField.sleagueTeamId.sleagueId.sleagueId);
    }
    if (error) {
	  localStorage.removeItem("token");
	  localStorage.removeItem("refreshToken");
	  localStorage.removeItem("role");
	  localStorage.removeItem("email");
      localStorage.removeItem("username");
	  //client.removeHeader("Authorization");
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      setAllSleagueTeams(dataSleague.allSleagueTeam);
      dataSleague.allSleagueTeam.map((sleagueteam) => {
        if (
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
		  console.log(userFavTeam);
        }
      });
    }
    if (errorSleague) {
	  console.log(errorSleague);
    }
	console.log(userFavTeam);
  }, [dataSleague, data]);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              align: "right",
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  return (
  <Grid>
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Reimagine Team Selector</title>
          <meta 
            name="description" 
            content="Predictal user football prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, user sign-in, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }
      
      {content_display_config ?
      <Grid container direction="row" alignItems="flex-start">
	    <Container className={classes.cardContainer}>
		  {mobileView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
		    <CardContent className={classes.cardContentMobile}>
			  {content_display_config && (<ReactPlayer url={victoryVideo} width="100%" height="100%" controls={true} volume={1} className={classes.videoContainer} />)}
              <section>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.8',color:'#E6312B',marginTop:40 }}>Reimagine Team Selector</Typography>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.3rem',fontWeight: 600,lineHeight: '1.3',color: 'black' }}>Line Up Your Football Team.Express Your Bragging Rights.</Typography>
				<img src={teamSelectorImg} alt="Team Selector" title="Team Selector" style={{ width: '100%',flex: '0 0 auto',marginTop: 50 }} />
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop: 20 }}>Send Your Team to the Pitch</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  It is simple. Create your starting XI football team in no time by using any of the formation templates. Pick 4-4-2, 4-2-3-1, or any formation from a list of over 30 formations. Then pick your players in each formation position through a simple drag-and-drop on the Team Selector Pitch.
                </Typography>
				<div style={{ width: "100%", textAlign: "center"}}>
  				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Want to Pick My Team
				  </Button>
				</div>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:40 }}>Your Team, The Coach Team, The Community Team</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Create your starting lineup and share it with your friends.</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Leverage Predictal's purpose-built and personalized Team Selector platform to create and publish your team lineup and challenge your peers with the players you selected.</Typography>
				<div style={{ width: "100%", textAlign: "center"}}>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Sign Up
				  </Button>
				</div>
				<img src={teamsH2hImg} alt="Team Selector" title="Team Selector" style={{ marginRight: 100,width: '100%',flex: '0 0 auto' }} />
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:20 }}>Explore Rich Analytics.<br />View How the Community Voted.</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.4rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Turn every vote into creating the Community Team.</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>The Community Team represents the collective wisdom of the community members. Each week votes of the Predical members are aggregated. View the numbers to discover what your peers are thinking when they create their starting lineups. Enjoy from anywhere anytime and on any device our mobile-first screens to view who voted for which player.</Typography>
				<div style={{ width: "100%", textAlign: "center"}}>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Vote
				  </Button>
				</div>
				<img src={communityVotes} alt="Community Votes" title="Community Votes" style={{ width: '100%',flex: '0 0 auto'}} />
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:20 }}>Get Awarded with Predictal Sport Points</Typography>
				<Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Every week you select your starting lineup team, you will get awarded with Predictal Sport Points (PSP) for your correct picks compared to the starting lineup of the Coach team.  We just wanted to award your team selection experience and efforts. But, there is one more thing. At the end of the half season and full season, we will present a gift to the Predictal community members with the highest Predictal Sports Points.</Typography>
				<div style={{ width: "100%", textAlign: "center"}}>
				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/sport-points-awards.html');
					}}
				  >
			        More about PSP Awards
				  </Button>
				</div>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={5} md={5} xl={5} xs={12} style={{textAlign: 'center'}}>
                  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} xl={4} xs={12} style={{textAlign: 'center',marginTop:"-20px"}}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team. The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={4} xl={3} xs={12} style={{textAlign: 'center'}}>
                  <Button
				    className={classes.readMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
		  {tabletView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
		    <CardContent className={classes.cardContentTablet}>
              {content_display_config && (<ReactPlayer url={victoryVideo} width="100%" height="100%" controls={true} volume={1} className={classes.videoContainer} />)}
              <section>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.8rem',fontWeight: 600,lineHeight: '1.8',color:'#E6312B',marginTop:50 }}>Reimagine Team Selector</Typography>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.6rem',fontWeight: 600,lineHeight: '1.4',color: 'black' }}>Line Up Your Football Team.<br />Express Your Bragging Rights.</Typography>
				<Grid container spacing={4}>
				<Grid item lg={6} md={6} xl={6} xs={6}>
				  <a href="javascript:;" onClick={handleTeamSelectorOpen}><img src={teamSelectorImg} alt="Team Selector" title="Team Selector" style={{ width: '100%',flex: '0 0 auto',marginTop: 150 }} /></a>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:120 }}>Your Team<br />The Coach Team<br />The Community Team</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Create your starting lineup and share it with your friends.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Leverage Predictal's purpose-built and personalized Team Selector platform to create and publish your team lineup and challenge your peers with the players you selected.</Typography>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Sign Up
				  </Button>
				  <a href="javascript:;" onClick={handleCommunityVotesOpen}><img src={communityVotes} alt="Community Votes" title="Community Votes" style={{ width: '100%',flex: '0 0 auto',marginTop: 140 }} /></a>
				</Grid>
				<Grid item lg={6} md={6} xl={6} xs={6}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop: 60 }}>Send Your Team to the Pitch</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                  It is simple. Create your starting XI football team in no time by using any of the formation templates. Pick 4-4-2, 4-2-3-1, or any formation from a list of over 30 formations. Then pick your players in each formation position through a simple drag-and-drop on the Team Selector Pitch.
                  </Typography>
				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Want to Pick My Team
				  </Button>
				  <a href="javascript:;" onClick={handleH2hOpen}><img src={teamsH2hImg} alt="Team Selector" title="Team Selector" style={{ marginRight: 100,width: '100%',flex: '0 0 auto',marginTop: 50 }} /></a>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:50 }}>Explore Rich Analytics.<br />View How the Community Voted.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.4rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Turn every vote into creating the Community Team.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>The Community Team represents the collective wisdom of the community members. Each week votes of the Predical members are aggregated. View the numbers to discover what your peers are thinking when they create their starting lineups. Enjoy from anywhere anytime and on any device our mobile-first screens to view who voted for which player.</Typography>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Vote
				  </Button>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.5',color: 'black',marginTop:50 }}>Get Awarded with Predictal Sport Points</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Every week you select your starting lineup team, you will get awarded with Predictal Sport Points (PSP) for your correct picks compared to the starting lineup of the Coach team.  We just wanted to award your team selection experience and efforts. But, there is one more thing. At the end of the half season and full season, we will present a gift to the Predictal community members with the highest Predictal Sports Points.</Typography>
				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/sport-points-awards.html');
					}}
				  >
					More about PSP Awards
				  </Button>
				</Grid>
				</Grid>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={1} md={1} xl={1} xs={1}></Grid>
			    <Grid item lg={5} md={5} xl={5} xs={5} style={{ border: 'solid #E6312B',borderBottomWidth:0,borderRightWidth:0,borderTopWidth:0,borderLeftWidth:15,height:70,marginTop:1}}>
                  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.7rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} xl={4} xs={4} style={{marginTop: '10px',marginLeft:'-20px'}} >
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '0.9rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team.<br />The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={2} xl={2} xs={2} style={{ paddingLeft: '0 !important',marginLeft:'-20px'}}>
                  <Button
				    className={classes.mobileReadMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/llms/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
		  {!tabletView && !mobileView && (
		  <>
		  <Card style={{ display: "flex", flexDirection: "row", width: "100%", padding: 0 }}>
            <CardContent className={classes.cardContent}>
			  {content_display_config && (<ReactPlayer url={victoryVideo} width="100%" height="100%" controls={true} volume={1} className={classes.videoContainer} />)}
              <section>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '1.8',color:'#E6312B' }}>Reimagine Team Selector</Typography>
                <Typography style={{ width: "100%", textAlign: "center",fontFamily: "Poppins, sans-serif",fontSize: '1.6rem',fontWeight: 600,lineHeight: '1.4',color: 'black' }}>Line Up Your Football Team.<br />Express Your Bragging Rights.</Typography>
				<Grid container spacing={4}>
				<Grid item lg={1} md={1} xl={1} xs={1}></Grid>
				<Grid item lg={5} md={5} xl={5} xs={5}>
				  <a href="javascript:;" onClick={handleTeamSelectorOpen}><img src={teamSelectorImg} alt="Team Selector" title="Team Selector" style={{ width: '100%',flex: '0 0 auto',marginTop: 90 }} /></a>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '1.3',color: 'black',marginTop: 70 }}>Your Team<br />The Coach Team<br />The Community Team</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Create your starting lineup and share it with your friends.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Leverage Predictal's purpose-built and personalized Team Selector platform to create and publish your team lineup and challenge your peers with the players you selected.</Typography>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Sign Up
				  </Button>
				  <a href="javascript:;" onClick={handleCommunityVotesOpen}><img src={communityVotes} alt="Community Votes" title="Community Votes" style={{ width: '100%',flex: '0 0 auto',marginTop: 50 }} /></a>
				</Grid>
				<Grid item lg={5} md={5} xl={5} xs={5}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '2',color: 'black',marginTop: 60 }}>Send Your Team to the Pitch</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8',color: 'black',marginBottom: '1.3rem' }}>
                    It is simple. Create your starting XI football team in no time by using any of the formation templates. Pick 4-4-2, 4-2-3-1, or any formation from a list of over 30 formations. Then pick your players in each formation position through a simple drag-and-drop on the Team Selector Pitch.
                  </Typography>
				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Want to Pick My Team
				  </Button>
				  <a href="javascript:;" onClick={handleH2hOpen}><img src={teamsH2hImg} alt="Team Selector" title="Team Selector" style={{ marginRight: 100,width: '100%',flex: '0 0 auto',marginTop: 90 }} /></a>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '1.3',color: 'black',marginTop: 40 }}>Explore Rich Analytics.<br />View How the Community Voted.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.5rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginTop: 20, marginBottom: '1rem' }}>Turn every vote into creating the Community Team.</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>The Community Team represents the collective wisdom of the community members. Each week votes of the Predical members are aggregated. View the numbers to discover what your peers are thinking when they create their starting lineups. Enjoy from anywhere anytime and on any device our mobile-first screens to view who voted for which player.</Typography>
				  <Button
				    className={classes.smallButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/registration');
					}}
				  >
					I Like to Vote
				  </Button>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.2rem',fontWeight: 600,lineHeight: '1.3',color: 'black' }}>Get Awarded with Predictal Sport Points</Typography>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.5',color: 'black', marginBottom: '1rem' }}>Every week you select your starting lineup team, you will get awarded with Predictal Sport Points (PSP) for your correct picks compared to the starting lineup of the Coach team.  We just wanted to award your team selection experience and efforts. But, there is one more thing. At the end of the half season and full season, we will present a gift to the Predictal community members with the highest Predictal Sports Points.</Typography>
				  <Button
				    className={classes.pickTeamButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/sport-points-awards.html');
					}}
				  >
					More about PSP Awards
				  </Button>
				</Grid>
				<Grid item lg={1} md={1} xl={1} xs={1}></Grid>
				</Grid>
              </section>
            </CardContent>
		  </Card>
      <CookieBubble
      messageText='This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation'
      messageFontSize='14px'
      buttonText='Accept Cookies'
      buttonColor='rgb(230, 49, 43)'
      cookiePolicyButtonText='Cookie Policy'
      cookiePolicyButtonUrl='/lms/cookie-policy.html'
      iconVisibility={false}
      />
          <Card className={classes.footerCardContent}>
            <CardContent>
			  <Grid container spacing={4} style={{ marginTop:1}}>
			    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
			    <Grid item lg={5} md={5} sm={5} xs={5} style={{ border: 'solid #E6312B',borderBottomWidth:0,borderRightWidth:0,borderTopWidth:0,borderLeftWidth:15,height:70,marginTop:1}}>
				  <Typography style={{ fontFamily: "Poppins, sans-serif",fontSize: '2.1rem',fontWeight: 600,lineHeight: '1.3', color: 'white' }}>How Team Selector Works</Typography>
				</Grid>
				<Grid item lg={4} md={4} sm={4} xs={4} style={{marginTop: '-10px'}} >
				  <Typography style={{ fontFamily: "Poppins, sans-serif",display:'inline',fontSize: '1.1rem',fontWeight: 400,lineHeight: '1.8', color: 'white'}}>Reimagine Selecting Your Starting XI Team.<br />The Ultimate Game Changer in Football.</Typography>
				</Grid>
				<Grid item lg={2} md={2} sm={2} xs={2} style={{ paddingLeft: '0 !important'}}>
                  <Button
				    className={classes.readMoreButtonStyle}
					variant="contained"
					onClick={() => {
					  navigate('/lms/how-team-selector-works.html');
					}}
				  >
					Learn more
				  </Button>
				</Grid>
			  </Grid>
            </CardContent>
          </Card>
		  </>
		  )}
        </Container>
      </Grid>
      :
      <b>Temporary Content</b>
      }
	  <BootstrapDialog
        onClose={handleTeamSelectorClose}
        aria-labelledby="customized-dialog-title"
        open={teamSelectorOpen}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleTeamSelectorClose}
        ></BootstrapDialogTitle>
        <Paper style={{ minWidth: 650, height: 570 }}>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            <img src={teamSelectorImg} alt="Team Selector" title="Team Selector" style={{ width: '100%',flex: '0 0 auto' }} />
          </DndProvider>
        </Paper>
      </BootstrapDialog>
	  
	  <BootstrapDialog
        onClose={handleH2hClose}
        aria-labelledby="customized-dialog-title"
        open={h2hOpen}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleH2hClose}
        ></BootstrapDialogTitle>
        <Paper style={{ minWidth: 650, height: 570 }}>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            <img src={teamsH2hImg} alt="Team Selector" title="Team Selector" style={{ width: '100%',flex: '0 0 auto' }} />
          </DndProvider>
        </Paper>
      </BootstrapDialog>
	  
	  <BootstrapDialog
        onClose={handleCommunityVotesClose}
        aria-labelledby="customized-dialog-title"
        open={communityOpen}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCommunityVotesClose}
        ></BootstrapDialogTitle>
        <Paper style={{ minWidth: 650, height: 570 }}>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            <img src={communityVotes} alt="Community Votes" title="Community Votes" style={{ width: '100%',flex: '0 0 auto' }} />
          </DndProvider>
        </Paper>
      </BootstrapDialog>
    </>
  </Grid>
);
}

export default Homepage;
