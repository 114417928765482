const CreateFilter_mutate = `
    mutation createFilter(
      $name: String!) {
      createFilter(
        name: $name) {
        filterId
        name
      }
    }
`;

const GetAllFilters_query = `
    query{
      allFilters{
        filterId
        name
    }
	
  }
`;

module.exports = {
  CREATE_FILTER_MUTATION : CreateFilter_mutate,
  GET_ALL_FILTERS_QUERY : GetAllFilters_query
}
