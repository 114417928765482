import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useQuery } from "graphql-hooks";


const Games = ({ players, selectedRole, gameStatsAggrList }) => {
  console.log("players---------positions", players);
  const [playersList, setPlayersList] = useState([]);

  const defPlayers = [];
  const mfPlayers = [];
  const forPlayers = [];
  const gkPlayers = [];

  useEffect(() => {
    players.map((player) => {
      if (player.playerId.position.toLowerCase() === "defender") {
        defPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "midfielder") {
        mfPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "forward") {
        forPlayers.push(player);
      } else if (player.playerId.position.toLowerCase() === "goalkeeper") {
        gkPlayers.push(player);
      }
    });

    if (selectedRole == "goalkeeper") {
      setPlayersList(gkPlayers);
    } else if (selectedRole == "defender") {
      setPlayersList(defPlayers);
    } else if (selectedRole == "midfielder") {
      setPlayersList(mfPlayers);
    } else if (selectedRole == "forward") {
      setPlayersList(forPlayers);
    }
  }, [players, selectedRole, gameStatsAggrList]);

  return (
    <div style={{ width: "100%" }}>
      {playersList.map((player, index) => {
        return (
          <Grid container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 5,
                //paddingRight: 3,
                height: "39px",
                borderBottom: "1px solid white",
              }}
            >
            <Box style={{width: 55}}>
              <Typography
                xs={1}
                spacing={2}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.full.gamesNo} ({player.gamesInfo.full.minutes}
                )
              </Typography>
              </Box>
              <Box style={{width: 40}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.eject.gamesNo} (
                {player.gamesInfo.eject.minutes})
              </Typography>
              </Box>
              <Box style={{width: 55}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.subIn.gamesNo} (
                {player.gamesInfo.subIn.minutes})
              </Typography>
              </Box>
              <Box style={{width: 55}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.subOut.gamesNo} (
                {player.gamesInfo.subOut.minutes})
              </Typography>
              </Box>
              <Box style={{width: 45}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.subInOut.gamesNo} (
                {player.gamesInfo.subInOut.minutes})
              </Typography>
              </Box>
              <Box style={{width: 55}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.total.gamesNo} (
                {player.gamesInfo.total.minutes})
              </Typography>
              </Box>
              <Box style={{width: 35}}>
              <Typography
                xs={1}
                variant="h6"
                style={{display: "contents"}}
              >
                {player.gamesInfo.perGame}
              </Typography>
              </Box>
            </div>
          </Grid>
        );
      })}
    </div>
  );
};

export default Games;
