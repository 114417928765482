import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  Link,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import { useManualQuery } from "graphql-hooks";
import styled from "styled-components";
import { project_url } from "../../../store/data/Constants";
import AlertDialog from "../../../components/AlertDialog.js";

import undoImg from "../../../assets/Undo.png";
import infoImg from "../../../assets/Info.png";
import delayedIcon from "../../../assets/delayed.png";

const useStyles = makeStyles((theme) => ({
  // cardContainer: {
  //   paddingLeft: 22,
  //   paddingTop: 20,
  //   paddingRight: 22,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   position: "relative",
  //   minHeight: 100,
  //   borderRadius: 8,
  // },
  textButtonStyle: {
    textTransform: "none",
    fontWeight: 700,
    width: "auto",
    fontSize: 12,
    display: "inline-block",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    textDecorationLine: "underline",
    textAlign: "center",
  },

  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },

  cardContentTablet: {
    padding: 5, //theme.spacing(2),
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    "&.MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
  },
  listItem: {
    minHeight: 196,
    marginBottom: 16,
    border: "1px solid #EAEDED",
    borderRadius: 8,
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  textButtonTabletStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 0,
    textAlign: "Center",
    marginLeft: 25,

    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  firstTextButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 20,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  listItemTextDate: {
    fontSize: 14,
    fontWeight: 400,
    color: "block",
    textAlign: "center",
    paddingBottom: "20px",
  },
  textStyle1: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#4D4D4D",
  },
  textStyle3: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#4D4D4D",
    textAlign: "center",
  },
  textStyle5: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#757575",
  },

  textStyle6: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#4D4D4D",
    paddingTop: "30 px",
  },
  textStyle7: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#4D4D4D",
    paddingTop: "30 px",
  },
  gridBorder: {
    borderLeft: "1px solid #EAEDED",
    borderRadius: 5,
  },
}));

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "-190px 0px 20px -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    background: #f7e4ff;
    color: #000;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    display: inline-flex;
    padding: 8px 8px 8px 8px;
    flex-direction: column;
    align-items: center;
  }
`;
const StyledWeekTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "40px, 0px, 0px, -25px",
          },
        },
      },
    }}
  />
))`
& .MuiTooltip-tooltip {
}
`;
const StyledSelTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "0px 0px 20px -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
  }
`;
const formatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      delayed
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
      sleagueId{
        sleagueId
        spMax
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;
const timeFormatter = new Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
  hour12: true,
});
const getDayString = (dateFormat, dateandtime, withHour) => {
  const dayDate = new Date(dateandtime);
  const dayFormat = formatter.formatToParts(dayDate);
  let dayandTimeString = "";
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value;
    dayandTimeString = newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value;
    dayandTimeString = newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value;
    dayandTimeString = newFormat;
  }

  if (withHour) {
    const newFormat = " - " + timeFormatter.format(dayDate);

    dayandTimeString = dayandTimeString + newFormat;
  }

  return dayandTimeString;
};
const PastFixtures = ({
  pastFixtures,
  sleagueTeamId,
  fixtureId,
  embargoTime,
  serverTime,
  passCopyPastFixtureData,
}) => {
  const classes = useStyles();
  console.log("pastFixtures__", pastFixtures);
  const mediaPath = `/${project_url}/media/`;
  const [pageSize, setPageSize] = useState(10);
  const [undo, setUndo] = useState(
    JSON.parse(localStorage.getItem("pastFixData"))
      ? JSON.parse(localStorage.getItem("pastFixData"))
      : []
  );
  const [dataGridMsg, setDataGridMsg] = useState(null);
  const [alertMsg, setAlertMsg] = useState("");

  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);

  const isConclude = (fixture) => {
    // number 2 represents two hours after the start of the fixture. 95% of the fixtures finish within 2 hours after the fixture start time.
    if (
      serverTime.getTime() >
      new Date(fixture.fixtureId.fixtureWeek.scheduledDate).getTime() +
        2 * 3600000
    )
      return true;
    else return false; /*not conclude*/
  };

  const isEmbargo = (fixture) => {
    let fixDate = new Date(
      fixture.fixtureId.fixtureWeek.scheduledDate
    ).getTime();
    if (
      serverTime.getTime() > fixDate - embargoTime.value * 3600000 &&
      serverTime.getTime() < fixDate + 2 * 3600000
    )
      return true;
    else return false; /*not embargo*/
  };

  useEffect(async () => {
    if (!fixtureId || !serverTime || !embargoTime) return;
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    if (!loading && data && data.fixtureObjectTeams) {
      data.fixtureObjectTeams.map(async (fixtureTeam) => {
        if (
          parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId
        ) {
          let isEmb = await isEmbargo(fixtureTeam);
          let isConc = await isConclude(fixtureTeam);
          console.log("isEmb || isConc___", isEmb, isConc);
          if (isEmb)
            setDataGridMsg(
              "This fixture is in embargo at this time. You can not select the fixture now."
            );
          else if (isConc)
            setDataGridMsg(
              "This fixture has concluded. You can not select the fixture now."
            );
          else {
            setDataGridMsg("");
          }
        }
      });
    }
  }, [fixtureId]);

  const rows = pastFixtures.map((pfx, index) => {
    let home = pfx[0];
    let guest = pfx[1];
    let favTeamPastFix = pfx[0];
    if (parseInt(pfx[1].sleagueTeamId.sleagueTeamId) === sleagueTeamId)
      favTeamPastFix = pfx[1];
    if (pfx[1].home) {
      home = pfx[1];
      guest = pfx[0];
    }
    let fixDateTime = getDayString(
      "Y",
      pfx[1].fixtureId.fixtureWeek.scheduledDate,
      true
    );
    var formatedTime =
      fixDateTime.split(" ")[5] + " " + fixDateTime.split(" ")[6];
    let fixDate = getDayString(
      "Y",
      pfx[1].fixtureId.fixtureWeek.scheduledDate,
      false
    );
    let coachFormation = favTeamPastFix.coachFormation
      ? favTeamPastFix.coachFormation
      : "Stay Tuned";
    let myTeamFormation = favTeamPastFix.userFormation
      ? favTeamPastFix.userFormation
      : "Not Selected";
    let text = (
      <div style={{ whiteSpace: "pre-line" }}>
        <div
          style={{
            fontSize: 13,
            paddingTop: 5,
            paddingBottom: 5,
            fontWeight: 600,
            letterSpacing: 0.46,
            textAlign: "center",
          }}
        >
          Community Team Results
        </div>
        {"Community Votes: " +
          favTeamPastFix.votsFormations.totalVots +
          "\n" +
          "Community Formation: " +
          favTeamPastFix.votsFormations.popularFormation +
          "\n" +
          "Coach Formation: " +
          coachFormation +
          "\n" +
          "My Team Formation: " +
          myTeamFormation +
          "\n" +
          "Fixture Date: " +
          fixDate +
          "\n" +
          "Time: " +
          formatedTime +
          " (Your local Time)"}
      </div>
    );

    return {
      id: index,
      home: home,
      guest: guest,
      tooltipTxt: text,
      fixture: pfx,
      //RTS-1810
      //week: pfx[0].fixtureId.fixtureWeek.weekId.name,
      week:
        pfx[0].fixtureId.fixtureWeek.scheduledDate ===
        pfx[0].fixtureId.scheduledWeek.scheduledDate
          ? pfx[0].fixtureId.fixtureWeek.weekId.name
          : pfx[0].fixtureId.fixtureWeek.weekId.name + "*",
      weekTooltip:
        pfx[0].fixtureId.fixtureWeek.scheduledDate !==
        pfx[0].fixtureId.scheduledWeek.scheduledDate
          ? "Rescheduled from " + pfx[0].fixtureId.scheduledWeek.weekId.name
          : null,
    };
  });
  return (
    <>
      <Box sx={{ height: 686, width: "100%" }}>
        {dataGridMsg && (
          <Box mt={4} style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h3">{dataGridMsg}</Typography>
          </Box>
        )}
        {dataGridMsg === "" && (
          <Container className={classes.cardContainerTablet}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {rows.map((row) => (
                  <Card variant="outlined" className={classes.listItem}>
                    <CardContent className={classes.cardContentTablet}>
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Grid item xs={11}>
                          {row.weekTooltip ? (
                            <StyledWeekTooltip title={row.weekTooltip}>
                              <Typography
                                variant="h5"
                                style={{ paddingLeft: 85 }}
                                className={classes.listItemTextDate}
                              >
                                {row.week}
                              </Typography>
                            </StyledWeekTooltip>
                          ) : (
                            <Typography
                              variant="h5"
                              style={{ paddingLeft: 85 }}
                              className={classes.listItemTextDate}
                            >
                              {row.week}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={1}>
                          <StyledTooltip title={row.tooltipTxt}>
                            <img
                              style={{ paddingBottom: 5 }}
                              height={18}
                              width={18}
                              src={infoImg}
                            />
                          </StyledTooltip>
                        </Grid>
                      </Grid>
                      <Divider />

                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Box
                          mt={5}
                          mb={4}
                          width="100%"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            item
                            xs={5}
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box
                              direction="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Box mr={2}>
                                <Typography
                                  className={classes.textStyle3}
                                  variant="h3"
                                  style={{ paddingTop: 14 }}
                                >
                                  {row.home.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                              <img
                                height={56}
                                width={56}
                                src={
                                  mediaPath + row.home.sleagueTeamId.teamId.logo
                                }
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={2} justifyContent="center">
                            <Typography
                              className={classes.textStyle7}
                              align="center"
                              style={{
                                paddingTop: 14,
                                justifyContent: "center",
                              }}
                            >
                              &nbsp;{row.home.scoreFinal} &nbsp;- &nbsp;
                              {row.guest.scoreFinal}
                            </Typography>
                          </Grid>

                          <Grid item xs={5}>
                            <Box
                              direction="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <img
                                height={56}
                                width={56}
                                src={
                                  mediaPath +
                                  row.guest.sleagueTeamId.teamId.logo
                                }
                              />
                              <Box ml={2}>
                                <Typography
                                  className={classes.textStyle3}
                                  variant="h3"
                                  style={{ paddingTop: 14 }}
                                >
                                  {row.guest.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>

                      <Divider />
                      {row.fixture[0].fixtureId.delayed ? (
                        <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={delayedIcon}
                              style={{ verticalAlign: "middle" }}
                              width={24}
                            />
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: 700,
                                color: "#D32F2F",
                                paddingLeft: 10,
                                paddingTop: 3,
                              }}
                            >
                              This fixture has been delayed.
                            </Typography>
                          </Box>
                        </div>
                      ) : (
                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          spacing={1}
                        >
                          <Grid item xs={4}>
                            <StyledSelTooltip
                              title={
                                undo[0] === row.id && undo[1] === "myTeam"
                                  ? "Currently selected team"
                                  : ""
                              }
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {undo[0] === row.id && undo[1] === "myTeam" && (
                                  <Box pr={0.5} pt={1}>
                                    <img
                                      src={undoImg}
                                      height={15}
                                      width={20}
                                      style={{
                                        marginRight: "-16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUndo([]);
                                        localStorage.removeItem("pastFixData");
                                        passCopyPastFixtureData("clear");
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box>
                                  <Button
                                    className={classes.firstTextButtonStyle}
                                    disableRipple={true}
                                    color="primary"
                                    variant="text"
                                    disabled={
                                      undo[0] === row.id && undo[1] === "myTeam"
                                    }
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      let seqData = await row.fixture.find(
                                        (x) => {
                                          return (
                                            parseInt(
                                              x.sleagueTeamId.sleagueTeamId
                                            ) === sleagueTeamId
                                          );
                                        }
                                      );
                                      console.log(
                                        "eeeeeeeeeeee",
                                        seqData,
                                        row.fixture[0].sleagueTeamId
                                          .sleagueTeamId
                                      );

                                      if (!seqData.userSeq)
                                        setAlertMsg(
                                          "There is no User Team for this fixture."
                                        );
                                      else {
                                        setUndo([row.id, "myTeam"]);
                                        localStorage.setItem(
                                          "pastFixData",
                                          JSON.stringify([row.id, "myTeam"])
                                        );
                                        passCopyPastFixtureData(
                                          seqData.userSeq
                                        );
                                      }
                                    }}
                                  >
                                    My Team
                                  </Button>
                                </Box>
                              </Box>
                            </StyledSelTooltip>
                          </Grid>

                          <Grid item xs={4} className={classes.gridBorder}>
                            <StyledSelTooltip
                              title={
                                undo[0] === row.id && undo[1] === "community"
                                  ? "Currently selected team"
                                  : ""
                              }
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                {undo[0] === row.id && undo[1] === "community" && (
                                  <Box pr={1} pt={1}>
                                    <img
                                      src={undoImg}
                                      height={15}
                                      width={20}
                                      style={{
                                        marginRight: "-16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUndo([]);
                                        localStorage.removeItem("pastFixData");
                                        passCopyPastFixtureData("clear");
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box>
                                  <Button
                                    className={classes.textButtonTabletStyle}
                                    disableRipple={true}
                                    color="primary"
                                    variant="text"
                                    disabled={
                                      undo[0] === row.id &&
                                      undo[1] === "community"
                                    }
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      let seqData = await row.fixture.find(
                                        (x) => {
                                          return (
                                            parseInt(
                                              x.sleagueTeamId.sleagueTeamId
                                            ) === sleagueTeamId
                                          );
                                        }
                                      );
                                      console.log(
                                        "eeeeeeeeeeee",
                                        seqData,
                                        row.fixture[0].sleagueTeamId
                                          .sleagueTeamId
                                      );

                                      if (!seqData.communitySeq)
                                        setAlertMsg(
                                          "The Community Team will be published shortly."
                                        );
                                      else {
                                        setUndo([row.id, "community"]);
                                        localStorage.setItem(
                                          "pastFixData",
                                          JSON.stringify([row.id, "community"])
                                        );
                                        passCopyPastFixtureData(
                                          seqData.communitySeq
                                        );
                                      }
                                    }}
                                  >
                                    Community
                                  </Button>
                                </Box>
                              </Box>
                            </StyledSelTooltip>
                          </Grid>

                          <Grid item xs={4} className={classes.gridBorder}>
                            <StyledSelTooltip
                              title={
                                undo[0] === row.id && undo[1] === "coach"
                                  ? "Currently selected team"
                                  : ""
                              }
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {undo[0] === row.id && undo[1] === "coach" && (
                                  <Box pt={1} pl={2} pr={0.5}>
                                    <img
                                      src={undoImg}
                                      height={15}
                                      width={20}
                                      style={{
                                        marginRight: "-16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUndo([]);
                                        localStorage.removeItem("pastFixData");
                                        passCopyPastFixtureData("clear");
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box>
                                  <Button
                                    className={classes.textButtonTabletStyle}
                                    disableRipple={true}
                                    color="primary"
                                    variant="text"
                                    disabled={
                                      undo[0] === row.id && undo[1] === "coach"
                                    }
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      let seqData = await row.fixture.find(
                                        (x) => {
                                          return (
                                            parseInt(
                                              x.sleagueTeamId.sleagueTeamId
                                            ) === sleagueTeamId
                                          );
                                        }
                                      );
                                      console.log(
                                        "eeeeeeeeeeee",
                                        seqData,
                                        row.fixture[0].sleagueTeamId
                                          .sleagueTeamId
                                      );

                                      if (!seqData.coachSeq)
                                        setAlertMsg(
                                          "The Coach Team will be published shortly."
                                        );
                                      else {
                                        setUndo([row.id, "coach"]);
                                        localStorage.setItem(
                                          "pastFixData",
                                          JSON.stringify([row.id, "coach"])
                                        );
                                        passCopyPastFixtureData(
                                          seqData.coachSeq
                                        );
                                      }
                                    }}
                                  >
                                    Coach
                                  </Button>
                                </Box>
                              </Box>
                            </StyledSelTooltip>
                          </Grid>
                        </Grid>
                      )}
                    </CardContent>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </Container>
        )}
      </Box>
      {alertMsg && (
        <AlertDialog
          title="Alert"
          contentMsg={alertMsg}
          closeDialog={() => setAlertMsg(false)}
        />
      )}
    </>
  );
};
export default PastFixtures;
