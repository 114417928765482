import { ItemTypes } from "./ItemTypes";

/////////////////  4_4_2
import imgGK_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/gk.png";
import imgLDEF_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/ldef.png";
import imgCDEF1_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cdef1.png";
import imgCDEF2_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cdef2.png";
import imgRDEF_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/rdef.png";
import imgLMF_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/lmf.png";
import imgCMF1_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cmf1.png";
import imgCMF2_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cmf2.png";
import imgRMF_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/rmf.png";
import imgCFOR1_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cfor1.png";
import imgCFOR2_4_4_2 from "../../assets/positionImgs/MP4-4-2/4-4-2/cfor2.png";

import imgGK_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/gk.png";
import imgLDEF_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/ldef.png";
import imgCDEF1_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cdef1.png";
import imgCDEF2_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cdef2.png";
import imgRDEF_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/rdef.png";
import imgCMF1_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cmf1.png";
import imgLMF_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/lmf.png";
import imgCMF2_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cmf2.png";
import imgRMF_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/rmf.png";
import imgCFOR1_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cfor1.png";
import imgCFOR2_4_1_3_2 from "../../assets/positionImgs/MP4-4-2/4-1-3-2/cfor2.png";

import imgGK_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/gk.png";
import imgLDEF_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/ldef.png";
import imgCDEF1_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cdef1.png";
import imgCDEF2_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cdef2.png";
import imgRDEF_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/rdef.png";
import imgLMF_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/lmf.png";
import imgCMF1_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cmf1.png";
import imgRMF_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/rmf.png";
import imgCMF2_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cmf2.png";
import imgCFOR1_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cfor1.png";
import imgCFOR2_4_3_1_2 from "../../assets/positionImgs/MP4-4-2/4-3-1-2/cfor2.png";

import imgGK_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/gk.png";
import imgLDEF_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/ldef.png";
import imgCDEF1_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cdef1.png";
import imgCDEF2_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cdef2.png";
import imgRDEF_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/rdef.png";
import imgCMF1_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cmf1.png";
import imgLMF_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/lmf.png";
import imgRMF_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/rmf.png";
import imgCMF2_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cmf2.png";
import imgCFOR1_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cfor1.png";
import imgCFOR2_4_1_2_1_2 from "../../assets/positionImgs/MP4-4-2/4-1-2-1-2/cfor2.png";

import imgGK_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/gk.png";
import imgLDEF_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/ldef.png";
import imgCDEF1_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cdef1.png";
import imgCDEF2_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cdef2.png";
import imgRDEF_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/rdef.png";
import imgCMF1_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cmf1.png";
import imgCMF2_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cmf2.png";
import imgLMF_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/lmf.png";
import imgRMF_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/rmf.png";
import imgCFOR1_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cfor1.png";
import imgCFOR2_4_2_2_2 from "../../assets/positionImgs/MP4-4-2/4-2-2-2/cfor2.png";

import imgGK_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/gk.png";
import imgCDEF1_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cdef1.png";
import imgLDEF_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/ldef.png";
import imgCDEF2_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cdef2.png";
import imgRDEF_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/rdef.png";
import imgCMF1_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cmf1.png";
import imgCMF2_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cmf2.png";
import imgLMF_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/lmf.png";
import imgRMF_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/rmf.png";
import imgCFOR1_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cfor1.png";
import imgCFOR2_1_3_4_2 from "../../assets/positionImgs/MP4-4-2/1-3-4-2/cfor2.png";

import imgGK_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/gk.png";
import imgLDEF_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/ldef.png";
import imgCDEF1_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cdef1.png";
import imgRDEF_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/rdef.png";
import imgCDEF2_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cdef2.png";
import imgCMF1_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cmf1.png";
import imgCMF2_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cmf2.png";
import imgLMF_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/lmf.png";
import imgRMF_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/rmf.png";
import imgCFOR1_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cfor1.png";
import imgCFOR2_3_1_4_2 from "../../assets/positionImgs/MP4-4-2/3-1-4-2/cfor2.png";

import imgGK_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/gk.png";
import imgLDEF_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/ldef.png";
import imgCDEF1_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cdef1.png";
import imgCDEF2_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cdef2.png";
import imgRDEF_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/rdef.png";
import imgLMF_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/lmf.png";
import imgCMF1_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cmf1.png";
import imgCMF2_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cmf2.png";
import imgRMF_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/rmf.png";
import imgCFOR1_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cfor1.png";
import imgCFOR2_4_4_1_1 from "../../assets/positionImgs/MP4-4-2/4-4-1-1/cfor2.png";
///////////////////// 4_2_4
import imgGK_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/gk.png";
import imgLDEF_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/ldef.png";
import imgCDEF1_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cdef1.png";
import imgCDEF2_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cdef2.png";
import imgRDEF_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/rdef.png";
import imgCMF1_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cmf1.png";
import imgCMF2_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cmf2.png";
import imgLFOR_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/lfor.png";
import imgCFOR1_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cfor1.png";
import imgCFOR2_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/cfor2.png";
import imgRFOR_4_2_4 from "../../assets/positionImgs/MP4-2-4/4-2-4/rfor.png";

////////////////////////  4_5_1
import imgGK_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/gk.png";
import imgLDEF_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/ldef.png";
import imgCDEF1_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cdef1.png";
import imgCDEF2_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cdef2.png";
import imgRDEF_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/rdef.png";
import imgLMF_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/lmf.png";
import imgCMF1_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cmf1.png";
import imgCMF2_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cmf2.png";
import imgCMF3_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cmf3.png";
import imgRMF_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/rmf.png";
import imgCFOR1_4_5_1 from "../../assets/positionImgs/MP4-5-1/4-5-1/cfor1.png";

import imgGK_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/gk.png";
import imgLDEF_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/ldef.png";
import imgCDEF1_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cdef1.png";
import imgCDEF2_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cdef2.png";
import imgRDEF_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/rdef.png";
import imgCMF1_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cmf1.png";
import imgLMF_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/lmf.png";
import imgCMF2_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cmf2.png";
import imgCMF3_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cmf3.png";
import imgRMF_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/rmf.png";
import imgCFOR1_4_1_4_1 from "../../assets/positionImgs/MP4-5-1/4-1-4-1/cfor1.png";

import imgGK_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/gk.png";
import imgLDEF_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/ldef.png";
import imgCDEF1_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cdef1.png";
import imgCDEF2_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cdef2.png";
import imgRDEF_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/rdef.png";
import imgLMF_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/lmf.png";
import imgCMF1_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cmf1.png";
import imgCMF2_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cmf2.png";
import imgRMF_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/rmf.png";
import imgCMF3_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cmf3.png";
import imgCFOR1_4_4_1_1_ from "../../assets/positionImgs/MP4-5-1/4-4-1-1/cfor1.png";

import imgGK_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/gk.png";
import imgLDEF_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/ldef.png";
import imgCDEF1_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cdef1.png";
import imgCDEF2_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cdef2.png";
import imgRDEF_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/rdef.png";
import imgCMF1_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cmf1.png";
import imgCMF2_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cmf2.png";
import imgLMF_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/lmf.png";
import imgCMF3_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cmf3.png";
import imgRMF_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/rmf.png";
import imgCFOR1_4_2_3_1 from "../../assets/positionImgs/MP4-5-1/4-2-3-1/cfor1.png";

import imgGK_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/gk.png";
import imgLDEF_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/ldef.png";
import imgCDEF1_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cdef1.png";
import imgCDEF2_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cdef2.png";
import imgRDEF_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/rdef.png";
import imgLMF_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/lmf.png";
import imgCMF1_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cmf1.png";
import imgRMF_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/rmf.png";
import imgCMF2_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cmf2.png";
import imgCMF3_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cmf3.png";
import imgCFOR1_4_3_2_1 from "../../assets/positionImgs/MP4-5-1/4-3-2-1/cfor1.png";

import imgGK_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/gk.png";
import imgCDEF1_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cdef1.png";
import imgLDEF_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/ldef.png";
import imgCDEF2_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cdef2.png";
import imgRDEF_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/rdef.png";
import imgLMF_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/lmf.png";
import imgCMF1_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cmf1.png";
import imgCMF2_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cmf2.png";
import imgCMF3_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cmf3.png";
import imgRMF_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/rmf.png";
import imgCFOR1_1_3_5_1 from "../../assets/positionImgs/MP4-5-1/1-3-5-1/cfor1.png";

import imgGK_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/gk.png";
import imgLDEF_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/ldef.png";
import imgCDEF1_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cdef1.png";
import imgRDEF_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/rdef.png";
import imgCDEF2_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cdef2.png";
import imgLMF_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/lmf.png";
import imgCMF1_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cmf1.png";
import imgCMF2_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cmf2.png";
import imgCMF3_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cmf3.png";
import imgRMF_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/rmf.png";
import imgCFOR1_3_1_5_1 from "../../assets/positionImgs/MP4-5-1/3-1-5-1/cfor1.png";

import imgGK_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/gk.png";
import imgCDEF1_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cdef1.png";
import imgCDEF2_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cdef2.png";
import imgLDEF_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/ldef.png";
import imgRDEF_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/rdef.png";
import imgLMF_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/lmf.png";
import imgCMF1_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cmf1.png";
import imgCMF2_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cmf2.png";
import imgCMF3_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cmf3.png";
import imgRMF_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/rmf.png";
import imgCFOR1_2_2_5_1 from "../../assets/positionImgs/MP4-5-1/2-2-5-1/cfor1.png";
////////////////////////


///////////////////// 3_4_3
import imgGK_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/gk.png";
import imgLDEF_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/ldef.png";
import imgCDEF_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/cdef.png";
import imgRDEF_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/rdef.png";
import imgLMF_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/lmf.png";
import imgCMF1_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/cmf1.png";
import imgCMF2_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/cmf2.png";
import imgRMF_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/rmf.png";
import imgLFOR_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/lfor.png";
import imgCFOR1_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/cfor1.png";
import imgRFOR_3_4_3 from "../../assets/positionImgs/MP3-4-3/3-4-3/rfor.png";

import imgGK_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/gk.png";
import imgLDEF_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/ldef.png";
import imgCDEF_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/cdef.png";
import imgRDEF_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/rdef.png";
import imgLMF_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/lmf.png";
import imgCMF1_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/cmf1.png";
import imgRMF_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/rmf.png";
import imgCMF2_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/cmf2.png";
import imgLFOR_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/lfor.png";
import imgCFOR1_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/cfor1.png";
import imgRFOR_3_3_1_3 from "../../assets/positionImgs/MP3-4-3/3-3-1-3/rfor.png";

import imgGK_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/gk.png";
import imgLDEF_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/ldef.png";
import imgCDEF_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/cdef.png";
import imgRDEF_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/rdef.png";
import imgCMF1_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/cmf1.png";
import imgLMF_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/lmf.png";
import imgCMF2_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/cmf2.png";
import imgRMF_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/rmf.png";
import imgLFOR_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/lfor.png";
import imgCFOR1_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/cfor1.png";
import imgRFOR_3_1_3_3_ from "../../assets/positionImgs/MP3-4-3/3-1-3-3/rfor.png";

import imgGK_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/gk.png";
import imgLDEF_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/ldef.png";
import imgCDEF_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/cdef.png";
import imgRDEF_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/rdef.png";
import imgCMF1_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/cmf1.png";
import imgLMF_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/lmf.png";
import imgCMF2_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/cmf2.png";
import imgRMF_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/rmf.png";
import imgLFOR_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/lfor.png";
import imgCFOR1_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/cfor1.png";
import imgRFOR_3_4_2_1 from "../../assets/positionImgs/MP3-4-3/3-4-2-1/rfor.png";

import imgGK_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/gk.png";
import imgLDEF_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/ldef.png";
import imgCDEF_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/cdef.png";
import imgRDEF_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/rdef.png";
import imgCMF1_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/cmf1.png";
import imgLMF_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/lmf.png";
import imgCMF2_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/cmf2.png";
import imgRMF_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/rmf.png";
import imgLFOR_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/lfor.png";
import imgCFOR1_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/cfor1.png";
import imgRFOR_3_4_1_2_ from "../../assets/positionImgs/MP3-4-3/3-4-1-2/rfor.png";

///////////////////// 4_3_3
import imgGK_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/gk.png";
import imgLDEF_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/ldef.png";
import imgCDEF1_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/cdef1.png";
import imgCDEF2_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/cdef2.png";
import imgRDEF_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/rdef.png";
import imgLMF_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/lmf.png";
import imgCMF1_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/cmf1.png";
import imgRMF_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/rmf.png";
import imgLFOR_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/lfor.png";
import imgCFOR1_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/cfor1.png";
import imgRFOR_4_3_3 from "../../assets/positionImgs/MP4-3-3/4-3-3/rfor.png";

import imgGK_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/gk.png";
import imgLDEF_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/ldef.png";
import imgCDEF1_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/cdef1.png";
import imgCDEF2_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/cdef2.png";
import imgRDEF_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/rdef.png";
import imgLMF_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/lmf.png";
import imgRMF_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/rmf.png";
import imgCMF1_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/cmf1.png";
import imgLFOR_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/lfor.png";
import imgCFOR1_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/cfor1.png";
import imgRFOR_4_2_1_3 from "../../assets/positionImgs/MP4-3-3/4-2-1-3/rfor.png";

import imgGK_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/gk.png";
import imgLDEF_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/ldef.png";
import imgCDEF1_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/cdef1.png";
import imgCDEF2_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/cdef2.png";
import imgRDEF_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/rdef.png";
import imgLMF_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/lmf.png";
import imgRMF_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/rmf.png";
import imgCMF1_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/cmf1.png";
import imgLFOR_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/lfor.png";
import imgCFOR1_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/cfor1.png";
import imgRFOR_4_1_2_3 from "../../assets/positionImgs/MP4-3-3/4-1-2-3/rfor.png";

import imgGK_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/gk.png";
import imgLDEF_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/ldef.png";
import imgCDEF1_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/cdef1.png";
import imgRDEF_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/rdef.png";
import imgCDEF2_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/cdef2.png";
import imgLMF_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/lmf.png";
import imgRMF_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/rmf.png";
import imgCMF1_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/cmf1.png";
import imgLFOR_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/lfor.png";
import imgCFOR1_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/cfor1.png";
import imgRFOR_3_1_3_3 from "../../assets/positionImgs/MP4-3-3/3-1-3-3/rfor.png";

import imgGK_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/gk.png";
import imgCDEF1_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/cdef1.png";
import imgLDEF_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/ldef.png";
import imgCDEF2_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/cdef2.png";
import imgRDEF_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/rdef.png";
import imgLMF_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/lmf.png";
import imgRMF_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/rmf.png";
import imgCMF1_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/cmf1.png";
import imgLFOR_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/lfor.png";
import imgCFOR1_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/cfor1.png";
import imgRFOR_1_3_3_3 from "../../assets/positionImgs/MP4-3-3/1-3-3-3/rfor.png";

///////////////////// 3_5_2
import imgGK_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/gk.png";
import imgLDEF_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/ldef.png";
import imgCDEF_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cdef.png";
import imgRDEF_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/rdef.png";
import imgLMF_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/lmf.png";
import imgCMF1_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cmf1.png";
import imgCMF2_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cmf2.png";
import imgCMF3_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cmf3.png";
import imgRMF_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/rmf.png";
import imgCFOR1_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cfor1.png";
import imgCFOR2_3_5_2 from "../../assets/positionImgs/MP3-5-2/3-5-2/cfor2.png";

import imgGK_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/gk.png";
import imgLDEF_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/ldef.png";
import imgCDEF_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cdef.png";
import imgRDEF_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/rdef.png";
import imgCMF1_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cmf1.png";
import imgLMF_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/lmf.png";
import imgCMF2_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cmf2.png";
import imgCMF3_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cmf3.png";
import imgRMF_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/rmf.png";
import imgCFOR1_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cfor1.png";
import imgCFOR2_3_1_4_2_ from "../../assets/positionImgs/MP3-5-2/3-1-4-2/cfor2.png";

import imgGK_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/gk.png";
import imgLDEF_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/ldef.png";
import imgCDEF_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cdef.png";
import imgRDEF_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/rdef.png";
import imgLMF_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/lmf.png";
import imgCMF1_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cmf1.png";
import imgCMF2_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cmf2.png";
import imgRMF_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/rmf.png";
import imgCMF3_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cmf3.png";
import imgCFOR1_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cfor1.png";
import imgCFOR2_3_4_1_2 from "../../assets/positionImgs/MP3-5-2/3-4-1-2/cfor2.png";

import imgGK_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/gk.png";
import imgLDEF_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/ldef.png";
import imgCDEF_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cdef.png";
import imgRDEF_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/rdef.png";
import imgLMF_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/lmf.png";
import imgCMF1_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cmf1.png";
import imgCMF2_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cmf2.png";
import imgCMF3_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cmf3.png";
import imgRMF_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/rmf.png";
import imgCFOR1_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cfor1.png";
import imgCFOR2_3_5_1_1 from "../../assets/positionImgs/MP3-5-2/3-5-1-1/cfor2.png";

///////////////////// 5_3_2
import imgGK_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/gk.png";
import imgLDEF_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/ldef.png";
import imgCDEF1_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cdef1.png";
import imgCDEF2_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cdef2.png";
import imgCDEF3_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cdef3.png";
import imgRDEF_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/rdef.png";
import imgLMF_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/lmf.png";
import imgCMF1_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cmf1.png";
import imgRMF_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/rmf.png";
import imgCFOR1_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cfor1.png";
import imgCFOR2_5_3_2 from "../../assets/positionImgs/MP5-3-2/5-3-2/cfor2.png";

import imgGK_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/gk.png";
import imgLDEF_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/ldef.png";
import imgCDEF1_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cdef1.png";
import imgCDEF2_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cdef2.png";
import imgCDEF3_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cdef3.png";
import imgRDEF_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/rdef.png";
import imgLMF_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/lmf.png";
import imgRMF_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/rmf.png";
import imgCMF1_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cmf1.png";
import imgCFOR1_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cfor1.png";
import imgCFOR2_5_2_1_2 from "../../assets/positionImgs/MP5-3-2/5-2-1-2/cfor2.png";

import imgGK_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/gk.png";
import imgLDEF_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/ldef.png";
import imgCDEF1_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cdef1.png";
import imgCDEF2_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cdef2.png";
import imgCDEF3_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cdef3.png";
import imgRDEF_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/rdef.png";
import imgCMF1_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cmf1.png";
import imgLMF_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/lmf.png";
import imgRMF_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/rmf.png";
import imgCFOR1_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cfor1.png";
import imgCFOR2_5_1_2_2 from "../../assets/positionImgs/MP5-3-2/5-1-2-2/cfor2.png";

import imgGK_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/gk.png";
import imgCDEF1_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cdef1.png";
import imgLDEF_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/ldef.png";
import imgCDEF2_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cdef2.png";
import imgCDEF3_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cdef3.png";
import imgRDEF_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/rdef.png";
import imgLMF_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/lmf.png";
import imgRMF_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/rmf.png";
import imgCMF1_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cmf1.png";
import imgCFOR1_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cfor1.png";
import imgCFOR2_1_4_3_2 from "../../assets/positionImgs/MP5-3-2/1-4-3-2/cfor2.png";

import imgGK_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/gk.png";
import imgLDEF_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/ldef.png";
import imgCDEF1_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cdef1.png";
import imgCDEF2_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cdef2.png";
import imgRDEF_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/rdef.png";
import imgCDEF3_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cdef3.png";
import imgLMF_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/lmf.png";
import imgRMF_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/rmf.png";
import imgCMF1_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cmf1.png";
import imgCFOR1_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cfor1.png";
import imgCFOR2_4_1_3_2_ from "../../assets/positionImgs/MP5-3-2/4-1-3-2/cfor2.png";

///////////////////// 5_4_1
import imgGK_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/gk.png";
import imgLDEF_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/ldef.png";
import imgCDEF1_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cdef1.png";
import imgCDEF2_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cdef2.png";
import imgCDEF3_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cdef3.png";
import imgRDEF_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/rdef.png";
import imgCMF1_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cmf1.png";
import imgCMF2_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cmf2.png";
import imgLMF_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/lmf.png";
import imgRMF_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/rmf.png";
import imgCFOR1_5_4_1 from "../../assets/positionImgs/MP5-4-1/5-4-1/cfor1.png";

import imgGK_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/gk.png";
import imgLDEF_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/ldef.png";
import imgCDEF1_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cdef1.png";
import imgCDEF2_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cdef2.png";
import imgCDEF3_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cdef3.png";
import imgRDEF_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/rdef.png";
import imgCMF1_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cmf1.png";
import imgCMF2_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cmf2.png";
import imgLMF_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/lmf.png";
import imgRMF_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/rmf.png";
import imgCFOR1_3_2_4_1 from "../../assets/positionImgs/MP5-4-1/3-2-4-1/cfor1.png";

import imgGK_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/gk.png";
import imgLDEF_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/ldef.png";
import imgCDEF1_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cdef1.png";
import imgCDEF2_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cdef2.png";
import imgCDEF3_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cdef3.png";
import imgRDEF_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/rdef.png";
import imgCMF1_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cmf1.png";
import imgCMF2_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cmf2.png";
import imgLMF_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/lmf.png";
import imgRMF_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/rmf.png";
import imgCFOR1_2_3_4_1 from "../../assets/positionImgs/MP5-4-1/2-3-4-1/cfor1.png";

///////////////////// 3-4-2-1 (3_6_1)
import imgGK_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/gk.png";
import imgLDEF_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/ldef.png";
import imgCDEF_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cdef1.png";
import imgRDEF_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/rdef.png";
import imgLMF_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/lmf.png";
import imgCMF1_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cmf1.png";
import imgCMF2_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cmf2.png";
import imgRMF_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/rmf.png";
import imgCMF3_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cmf3.png";
import imgCMF4_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cmf4.png";
import imgCFOR1_3_4_2_1_ from "../../assets/positionImgs/MP3-6-1/3-4-2-1/cfor1.png";

import imgGK_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/gk.png";
import imgLDEF_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/ldef.png";
import imgCDEF_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cdef1.png";
import imgRDEF_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/rdef.png";
import imgCMF1_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cmf1.png";
import imgCMF2_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cmf2.png";
import imgLMF_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/lmf.png";
import imgCMF3_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cmf3.png";
import imgCMF4_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cmf4.png";
import imgRMF_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/rmf.png";
import imgCFOR1_3_2_4_1_ from "../../assets/positionImgs/MP3-6-1/3-2-4-1/cfor1.png";
//width: 580, mobile : 320
//height: 408 mobile : 454
export const POSITIONS_DATA = [
  {
    formationSeqNo: "01", //"4-4-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_4_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_4_2,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_4_2,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_4_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_4_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "02", //"4-1-3-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_1_3_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_1_3_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_1_3_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_1_3_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_1_3_2,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_1_3_2,
            width: 320,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_1_3_2,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_1_3_2,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_1_3_2,
            width: 106,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_1_3_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_1_3_2,
            width: 160,
            height: 128
          },
        ]
      }
    }
  },
  {
    formationSeqNo: "03", //"4-3-1-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_3_1_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_3_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_3_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_3_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_3_1_2,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_3_1_2,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_3_1_2,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_3_1_2,
            width: 106,
            height: 65
          }

        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_3_1_2,
            width: 320,
            height: 64
          }

        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_3_1_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_3_1_2,
            width: 160,
            height: 128
          },
        ]
      }
    }
  },
  {
    formationSeqNo: "04", //"4-1-2-1-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_1_2_1_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_1_2_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_1_2_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_1_2_1_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_1_2_1_2,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_1_2_1_2,
            width: 320,
            height: 43
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_1_2_1_2,
            width: 160,
            height: 43
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_1_2_1_2,
            width: 160,
            height: 43
          }
        ],
          midLane_col_3: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_1_2_1_2,
            width: 320,
            height: 43
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_1_2_1_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_1_2_1_2,
            width: 160,
            height: 128
          },
        ]
      }
    }
  },
  {
    formationSeqNo: "05", //"4-2-2-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_2_2_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_2_2_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_2_2_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_2_2_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_2_2_2,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_2_2_2,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_2_2_2,
            width: 160,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_2_2_2,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_2_2_2,
            width: 160,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_2_2_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_2_2_2,
            width: 160,
            height: 128
          },
        ]
      }
    }
  },
  {
    formationSeqNo: "06", //"1-3-4-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_1_3_4_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_1_3_4_2,
            width: 320,
            height: 65
          },
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_1_3_4_2,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_1_3_4_2,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_1_3_4_2,
            width: 106,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_1_3_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_1_3_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_1_3_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_1_3_4_2,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_1_3_4_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_1_3_4_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "07", //"3-1-4-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_1_4_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_1_4_2,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_3_1_4_2,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_3_1_4_2,
            width: 106,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_3_1_4_2,
            width: 320,
            height: 64
          },
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_3_1_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_3_1_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_3_1_4_2,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_3_1_4_2,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_1_4_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_3_1_4_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "08", //"4-4-1-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_4_1_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_4_1_1,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_4_1_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_4_1_1,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_4_1_1,
            width: 320,
            height: 64
          }
        ],
        forLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_4_1_1,
            width: 320,
            height: 64
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "10", //"4-5-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_5_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_5_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_5_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_5_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_5_1,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_4_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_4_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_4_5_1,
            width: 64,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_4_5_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "11", //"4-1-4-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_1_4_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_1_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_1_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_1_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_1_4_1,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_4_1_4_1,
            width: 320,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_1_4_1,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_4_1_4_1,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_4_1_4_1,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_4_1_4_1,
            width: 80,
            height: 64
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_4_1_4_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "12", //"4-4-1-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_4_1_1_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_4_1_1_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_4_1_1_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_4_1_1_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_4_1_1_,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_4_1_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_4_1_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF2_4_4_1_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_4_4_1_1_,
            width: 80,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_4_4_1_1_,
            width: 320,
            height: 64
          },
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_4_4_1_1_,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "13", //"4-2-3-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_2_3_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_2_3_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_2_3_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_2_3_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_2_3_1,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_2_3_1,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF2_4_2_3_1,
            width: 160,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_2_3_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_4_2_3_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_4_2_3_1,
            width: 106,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_4_2_3_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "14", //"4-3-2-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_3_2_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_3_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_3_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_3_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_3_2_1,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_3_2_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_4_3_2_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_4_3_2_1,
            width: 106,
            height: 65
          },

        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_4_3_2_1,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_4_3_2_1,
            width: 160,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_4_3_2_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "15", //"1-3-5-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_1_3_5_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_1_3_5_1,
            width: 320,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_1_3_5_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_1_3_5_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_1_3_5_1,
            width: 106,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_1_3_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_1_3_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_1_3_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_1_3_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_1_3_5_1,
            width: 64,
            height: 129
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_1_3_5_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "16", //"3-1-5-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_1_5_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_1_5_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_3_1_5_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_3_1_5_1,
            width: 106,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_3_1_5_1,
            width: 320,
            height: 64
          },
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_3_1_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_3_1_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_3_1_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_3_1_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_3_1_5_1,
            width: 64,
            height: 129
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_3_1_5_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "17", //"2-2-5-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_2_2_5_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_2_2_5_1,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_2_2_5_1,
            width: 160,
            height: 65
          },

        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_2_2_5_1,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_2_2_5_1,
            width: 160,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_2_2_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_2_2_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_2_2_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_2_2_5_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_2_2_5_1,
            width: 64,
            height: 129
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_2_2_5_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "31", //"3-4-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_4_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_4_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_4_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_4_3,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_4_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_4_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_4_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_4_3,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_4_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_4_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_4_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "32", //"3-3-1-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_3_1_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_3_1_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_3_1_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_3_1_3,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_3_1_3,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_3_1_3,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_3_1_3,
            width: 106,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_3_1_3,
            width: 320,
            height: 64
          },
       ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_3_1_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_3_1_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_3_1_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "33", //"3-1-3-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_1_3_3_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_1_3_3_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_1_3_3_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_1_3_3_,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_1_3_3_,
            width: 320,
            height: 65
          },
       ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_1_3_3_,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_1_3_3_,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_1_3_3_,
            width: 106,
            height: 64
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_1_3_3_,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_1_3_3_,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_1_3_3_,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "34", //"3-4-2-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_4_2_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_4_2_1,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_4_2_1,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_4_2_1,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_4_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_4_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_4_2_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_4_2_1,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_4_2_1,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_4_2_1,
            width: 160,
            height: 64
          }
        ],
        forLane_col_2:[
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_4_2_1,
            width: 320,
            height: 64
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "35", //"3-4-1-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_4_1_2_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_4_1_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_4_1_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_4_1_2_,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_4_1_2_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_4_1_2_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_4_1_2_,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_4_1_2_,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_4_1_2_,
            width: 320,
            height: 64
          },
        ],
        forLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_4_1_2_,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_4_1_2_,
            width: 160,
            height: 64
          }
        ],

      }
    }
  },
  {
    formationSeqNo: "26", //"4-3-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_3_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_3_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_3_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_3_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_3_3,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_4_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_4_3_3,
            width: 106,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_4_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_4_3_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "27", //"4-2-1-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_2_1_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_2_1_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_2_1_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_2_1_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_2_1_3,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_2_1_3,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_4_2_1_3,
            width: 160,
            height: 65
          }
        ],
        midLane_col_2: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "CMF(7)",
          positionSeqNo: "07",
          img: imgCMF1_4_2_1_3,
          width: 320,
          height: 64
        }
      ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_4_2_1_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_2_1_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_4_2_1_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "28", //"4-1-2-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_1_2_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_1_2_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_1_2_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_1_2_3,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_1_2_3,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "CMF(7)",
          positionSeqNo: "07",
          img: imgCMF1_4_1_2_3,
          width: 320,
          height: 65
        }
      ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_4_1_2_3,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_4_1_2_3,
            width: 160,
            height: 64
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_4_1_2_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_1_2_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_4_1_2_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "29", //"3-1-3-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_1_3_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_1_3_3,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_3_1_3_3,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_3_1_3_3,
            width: 106,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_3_1_3_3,
            width: 320,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_3_1_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_3_1_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_1_3_3,
            width: 106,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_3_1_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_1_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_3_1_3_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "30", //"1-3-3-3",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_1_3_3_3,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_1_3_3_3,
            width: 320,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_1_3_3_3,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_1_3_3_3,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_1_3_3_3,
            width: 106,
            height: 64
          }
        ]

      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgLMF_1_3_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_1_3_3_3,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_1_3_3_3,
            width: 106,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(9)",
            positionSeqNo: "09",
            img: imgLFOR_1_3_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_1_3_3_3,
            width: 107,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_1_3_3_3,
            width: 106,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "09", //"4-2-4",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_2_4,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_2_4,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_2_4,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_4_2_4,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(5)",
            positionSeqNo: "05",
            img: imgRDEF_4_2_4,
            width: 80,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_4_2_4,
            width: 160,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_4_2_4,
            width: 160,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LFOR(8)",
            positionSeqNo: "08",
            img: imgLFOR_4_2_4,
            width: 80,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(9)",
            positionSeqNo: "09",
            img: imgCFOR1_4_2_4,
            width: 80,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR2_4_2_4,
            width: 80,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RFOR(11)",
            positionSeqNo: "11",
            img: imgRFOR_4_2_4,
            width: 80,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "23", //"3-5-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_5_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_5_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_5_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_5_2,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_5_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_5_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_5_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_3_5_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_3_5_2,
            width: 64,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_5_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_3_5_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "24", //"3-1-4-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_1_4_2_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_1_4_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_1_4_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_1_4_2_,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF1_3_1_4_2_,
            width: 320,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_1_4_2_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF2_3_1_4_2_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_3_1_4_2_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_3_1_4_2_,
            width: 80,
            height: 64
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_1_4_2_,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_3_1_4_2_,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "25", //"3-4-1-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_4_1_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_4_1_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_4_1_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_4_1_2,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_4_1_2,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_4_1_2,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF2_3_4_1_2,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_3_4_1_2,
            width: 80,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF3_3_4_1_2,
            width: 320,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_4_1_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_3_4_1_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "36", //"3-5-1-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_5_1_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_5_1_1,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_5_1_1,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_5_1_1,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_5_1_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_5_1_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_5_1_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_3_5_1_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_3_5_1_1,
            width: 64,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_3_5_1_1,
            width: 320,
            height: 64
          }
        ],
        forLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_3_5_1_1,
            width: 320,
            height: 64
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "18", //"5-3-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_5_3_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_5_3_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_5_3_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_5_3_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_5_3_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_5_3_2,
            width: 64,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_5_3_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_5_3_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_5_3_2,
            width: 106,
            height: 129
          },

        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_5_3_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_5_3_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "19", //"5-2-1-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_5_2_1_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_5_2_1_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_5_2_1_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_5_2_1_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_5_2_1_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_5_2_1_2,
            width: 64,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_5_2_1_2,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_5_2_1_2,
            width: 160,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_5_2_1_2,
            width: 320,
            height: 64
          }
        ]
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_5_2_1_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_5_2_1_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "20", //"5-1-2-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_5_1_2_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_5_1_2_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_5_1_2_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_5_1_2_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_5_1_2_2,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_5_1_2_2,
            width: 64,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_5_1_2_2,
            width: 320,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_5_1_2_2,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_5_1_2_2,
            width: 160,
            height: 64
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_5_1_2_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_5_1_2_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "21", //"1-4-3-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_1_4_3_2,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF1_1_4_3_2,
            width: 320,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_1_4_3_2,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF2_1_4_3_2,
            width: 80,
            height: 64
          },

          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_1_4_3_2,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_1_4_3_2,
            width: 80,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_1_4_3_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_1_4_3_2,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_1_4_3_2,
            width: 106,
            height: 129
          },

        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_1_4_3_2,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_1_4_3_2,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "22", //"4-1-3-2",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_4_1_3_2_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_4_1_3_2_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_4_1_3_2_,
            width: 80,
            height: 65
          },

          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF2_4_1_3_2_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_4_1_3_2_,
            width: 80,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF3_4_1_3_2_,
            width: 320,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_4_1_3_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_4_1_3_2_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(9)",
            positionSeqNo: "09",
            img: imgRMF_4_1_3_2_,
            width: 106,
            height: 129
          },

        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(10)",
            positionSeqNo: "10",
            img: imgCFOR1_4_1_3_2_,
            width: 160,
            height: 128
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR2_4_1_3_2_,
            width: 160,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "37", //"5-4-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_5_4_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_5_4_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_5_4_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_5_4_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_5_4_1,
            width: 64,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_5_4_1,
            width: 64,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_5_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_5_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF2_5_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_5_4_1,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_5_4_1,
            width: 320,
            height: 124
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "38", //"3-2-4-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_2_4_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_2_4_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_3_2_4_1,
            width: 107,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_3_2_4_1,
            width: 106,
            height: 65
          }
        ],
        defLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_3_2_4_1,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_3_2_4_1,
            width: 160,
            height: 64
          }
         ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_3_2_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_3_2_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF2_3_2_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_3_2_4_1,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_3_2_4_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "39", //"2-3-4-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_2_3_4_1,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF1_2_3_4_1,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(5)",
            positionSeqNo: "05",
            img: imgCDEF3_2_3_4_1,
            width: 160,
            height: 65
          }
        ],
        defLane_col_2:[
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_2_3_4_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(4)",
            positionSeqNo: "04",
            img: imgCDEF2_2_3_4_1,
            width: 107,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(6)",
            positionSeqNo: "06",
            img: imgRDEF_2_3_4_1,
            width: 106,
            height: 64
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_2_3_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF1_2_3_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF2_2_3_4_1,
            width: 80,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_2_3_4_1,
            width: 80,
            height: 129
          }
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_2_3_4_1,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "41", //"3-4-2-1" //"3-6-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_4_2_1_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_4_2_1_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_4_2_1_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_4_2_1_,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(5)",
            positionSeqNo: "05",
            img: imgLMF_3_4_2_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF1_3_4_2_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(7)",
            positionSeqNo: "07",
            img: imgCMF2_3_4_2_1_,
            width: 80,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(8)",
            positionSeqNo: "08",
            img: imgRMF_3_4_2_1_,
            width: 80,
            height: 65
          }
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF3_3_4_2_1_,
            width: 160,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(10)",
            positionSeqNo: "10",
            img: imgCMF4_3_4_2_1_,
            width: 160,
            height: 64
          },
        ],
      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_3_4_2_1_,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },
  {
    formationSeqNo: "42", //"3-2-4-1" //"3-6-1",
    positionsData: {
      GK: [
        {
          accepts: ItemTypes.PLAYER,
          lastDroppedItem: null,
          text: "GK(1)",
          positionSeqNo: "01",
          img: imgGK_3_2_4_1_,
          width: 320,
          height: 45
        }
      ],
      DEF: {
        defLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LDEF(2)",
            positionSeqNo: "02",
            img: imgLDEF_3_2_4_1_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CDEF(3)",
            positionSeqNo: "03",
            img: imgCDEF_3_2_4_1_,
            width: 107,
            height: 129
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RDEF(4)",
            positionSeqNo: "04",
            img: imgRDEF_3_2_4_1_,
            width: 106,
            height: 129
          }
        ]
      },
      MID: {
        midLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(5)",
            positionSeqNo: "05",
            img: imgCMF1_3_2_4_1_,
            width: 160,
            height: 65
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(6)",
            positionSeqNo: "06",
            img: imgCMF2_3_2_4_1_,
            width: 160,
            height: 65
          },
        ],
        midLane_col_2: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "LMF(7)",
            positionSeqNo: "07",
            img: imgLMF_3_2_4_1_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(8)",
            positionSeqNo: "08",
            img: imgCMF3_3_2_4_1_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CMF(9)",
            positionSeqNo: "09",
            img: imgCMF4_3_2_4_1_,
            width: 80,
            height: 64
          },
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "RMF(10)",
            positionSeqNo: "10",
            img: imgRMF_3_2_4_1_,
            width: 80,
            height: 64
          }
        ],

      },
      FOR: {
        forLane_col_1: [
          {
            accepts: ItemTypes.PLAYER,
            lastDroppedItem: null,
            text: "CFOR(11)",
            positionSeqNo: "11",
            img: imgCFOR1_3_2_4_1_,
            width: 320,
            height: 128
          }
        ]
      }
    }
  },

];
