import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../../store/data/Constants";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";

import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import Tooltip from "@material-ui/core/Tooltip";

import leagueLogo from "../../assets/Premier_League-Logo.png";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import AlertDialog from "../../components/AlertDialog.js";

var findAnd = require("find-and");
const mainPath = "/lms/media/";
const teamNames = { 0:"Community", 1: "Coach" };
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
    }
    scoreFinal
    home
    communitySeq
  }
}`;

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px;
  }
`;

const useStyles = makeStyles(theme => ({
  cardContent: {
    "&.MuiCardContent-root:last-child": {
      paddingBottom: 0,
    },
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
  },
  overview: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  teamName: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 18,
    fontWeight: 700,
    color: "#4D4D4D",
  },

  teamScore: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: 24,
    fontWeight: 600,
    color: "#212121",
  },

  teamImage: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    height: 40,
    width: 40,
  },
}));

const AdminFavTeamSelectorHeader = ({
  userFavTeam,
  sleagueTeamId,
  fixtureId,  
  sleagueTeamsData,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fixDate, setFixDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [competitor, setCompetitor] = useState("");
  const [homeTeam, setHomeTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [embargoTimeStatus, setEmbargoTimeStatus] = useState(false);
  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);
  const [pathname] = useState(window.location.pathname);

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });
  
  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "day").value +
        " " +
        dayFormat.find(a => a.type == "month").value +
        ", " +
        dayFormat.find(a => a.type == "year").value;

      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        ", " +
        dayFormat.find(a => a.type == "year").value;

      return newFormat;
    } else {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value;

      return newFormat;
    }
  };

  
  useEffect(async () => {
    if (!fixtureId) return;
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    // Set Community Team Data
    if (!loading && data && data.fixtureObjectTeams) {
      data.fixtureObjectTeams.map(fixtureTeam => {
        // Set Community team data
        if (parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId) {
          if (fixtureTeam.home)
            setHomeTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          else
            setSecondTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);

          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            //second: "2-digit",
            hour12: true,
          };
          var fixDate = new Date(
            fixtureTeam.fixtureId.fixtureWeek.scheduledDate
          ).toLocaleTimeString("en-us", options);
          var year = fixDate.split(" ")[3];
          var day = fixDate.split(" ")[2];
          var month = fixDate.split(" ")[1];
          var time = fixDate.split(" ")[4];
          var pam = fixDate.split(" ")[5];
          var weekday = fixDate.split(" ")[0];
          var formatedDate =
            weekday + " " + day.split(",")[0] + " " + month + " " + year.split(",")[0];
          var formatedTime = fixDate.split(" ")[5] + " " + fixDate.split(" ")[6]; //time + " " + pam;
          setFixDate(dayFormat(fixtureTeam.fixtureId.fixtureWeek.scheduledDate)); //formatedDate);
          setEmbargoTimeStatus(fixtureTeam.fixtureId.lock); //false);
          setStartTime(formatedTime);
        }
        // Set Community team competitor for that fixtureId
        else {
          if (fixtureTeam.home)
            setHomeTeam([
              fixtureTeam.sleagueTeamId.teamId,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          setCompetitor([
            fixtureTeam.sleagueTeamId.teamId,
            fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
          ]);
        }
      });
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureId, userFavTeam]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // console.log(homeTeam);
  // console.log(secondTeam);
  // console.log(competitor);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.overview}>
          <Box>
            <StyledTooltip title="Return to Fixtures">
              <IconButton
                edge="start"
                color="primary"
                onClick={() => {
                  localStorage.setItem("ReturnFromOtherPages", true);
                  navigate(`/lms/${admin_url}/fixtures`);
                }}
                size="large"
              >
                <KeyboardReturn sx={{ color: "#662483" }} fontSize="inherit" />
              </IconButton>
            </StyledTooltip>
          </Box>
          { homeTeam[0] && secondTeam && homeTeam[0].logo ?
          (
          <Box display="flex" alignItems="center">
            <Typography className={classes.teamName}>
              {homeTeam[0] && secondTeam
                ? homeTeam[0].shortName
                : userFavTeam.teamInfo && userFavTeam.teamInfo.shortName}
            </Typography>

            <img
              className={classes.teamImage}
              src={
                homeTeam[0] && secondTeam && homeTeam[0].logo
                  ? mainPath + homeTeam[0].logo
                  : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                  ? mainPath + userFavTeam.teamInfo.logo
                  : ""
              }
            />

            <Typography className={classes.teamScore}>
              {homeTeam[1]}&nbsp;&nbsp;{" - "}&nbsp;&nbsp;
              {!secondTeam[0]
                ? competitor[0] && competitor[1]
                : secondTeam[0] && secondTeam[1]}
            </Typography>

            <img
              className={classes.teamImage}
              src={
                !secondTeam[0]
                  ? competitor[0] && competitor[0].logo
                    ? mainPath + competitor[0].logo
                    : ""
                  : secondTeam[0] && mainPath + secondTeam[0].logo
              }
            />

            <Typography className={classes.teamName}>
              {!secondTeam[0]
                ? competitor[0] && competitor[0].shortName
                : secondTeam[0] && secondTeam[0].shortName}
            </Typography>
          </Box>

          )
        :
        (
          <Box display="flex" alignItems="center">
            
          </Box>
                 
        )}

          <div>
            <img src={leagueLogo} alt="leagueLogo" height={39} width={88} />
          </div>
        </Box>
        
        {openDialog && (
          <AlertDialog
            title="Alert"
            closeDialog={handleCloseDialog}
            contentMsg="You can only choose two teams to compare."
          />
        )}
      </CardContent>
    </Card>
  );
};

export default AdminFavTeamSelectorHeader;
