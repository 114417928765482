
const GetAllAwards_query = `
    query{
      award{
        awardId
          sleagueId
          {
            seasonName
          }
          name
          sp
          active              
    }
	
  }
`;

const GetSleagueAward_query = `
  query($sleagueId: Int!){
    sleagueAward(sleagueId: $sleagueId) {
          awardId
          sleagueId
          {
            seasonName
          }
          name
          sp
          active 
    }
  }
  `;

  const GetAward_query = `
  query($awardId: Int!){
    awardObject(awardId: $awardId) {
          awardId
          sleagueId
          {
            sleagueId
            seasonName
            leagueId
            {
              leagueId
              title
            }
          }
          name
          sp
          active 
    }
  }
  `;

const AddAward_mutate = `
    mutation addAward(
      $sleagueId: Int!,
      $name: String!,
      $sp: Int!,
      $active: Boolean!) {
      addAward(
        sleagueId: $sleagueId,
        name: $name,
        sp: $sp,
        active: $active) {
        awardId
        sleagueId
        name
        sp
        active 
      }
    }
`;

const EditAward_mutate = `
mutation editAward( $awardId: Int!, $sleagueId: Int!, $name: String!,
                       $sp: Int!, $active: Boolean!) {
  editAward(
    awardId: $awardId,
    sleagueId: $sleagueId,
    name: $name,
    sp: $sp,
    active: $active,
  ) {
    award{
      awardId
      sleagueId{
        sleagueId
        seasonName        
      }
      name 
      sp
      active
    }
  }
}
`;

module.exports = {
  GET_ALL_AWARDS_QUERY : GetAllAwards_query,
  GET_SLEAGUE_AWARD_QUERY : GetSleagueAward_query,
  GET_AWARD_QUERY : GetAward_query,
  ADD_AWARD_MUTATION : AddAward_mutate,
  EDIT_AWARD_MUTATION : EditAward_mutate,
}
