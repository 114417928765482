import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery, useManualQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  IconButton,
  Icon,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import { Paper } from "@mui/material";

import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../../graphQL/sleague_team_query";

import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../../graphQL/sleague_qry";
import { GET_FIXTURE_OBJECT_TEAMS, GET_SELECTED_TEAM_PAST_FIXTURES } from "../../../graphQL/fixture_qry";

import TeamInfo from "./TeamInfo";
import delayedIcon from "../../../assets/delayed.png";
import styled from "styled-components";

var findAnd = require("find-and");
const useStyles = makeStyles((theme) => ({}));

const formatter = new Intl.DateTimeFormat("en-us", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});

const dayFormat = (day) => {
  const dayDate = new Date(day);
  const dayFormat = formatter.formatToParts(dayDate);
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  }
};

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: '37px 0px 0px -15px'
          }
        }
      }
    }}/>
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 10px;
  }
`;

const TeamSelectorMobile = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqVal,
  coachSeqVal,
  userTSId,
  spAward,
  embargoTime,
  serverTime,
}) => {
  const [players, setPlayers] = useState([]);
  const [nowPastPlayers, setNowPastPlayers] = useState([]);
  const [userSeqData, setUserSeqData] = useState([]);
  const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState("01");

  const [fixtureTeam1, setFixtureTeam1] = useState([]);
  const [fixtureTeam2, setFixtureTeam2] = useState([]);
  const [sleagueTeamId1, setSleagueTeamId1] = useState("");
  const [sleagueTeamId2, setSleagueTeamId2] = useState("");
  const [scoreFinalTeam1, setScoreFinalTeam1] = useState("");
  const [scoreFinalTeam2, setScoreFinalTeam2] = useState("");
  const [homeValue, setHomeValue] = useState("");
  const [delayed, setDelayed] = useState(false);
  const [pastFixtures, setPastFixtures] = useState([]);
  const [pathname] = useState(window.location.pathname);
  const navigate = useNavigate();
  const [fetchFixtureTeams] = useManualQuery(GET_FIXTURE_OBJECT_TEAMS, {
    fetchPolicy: "network-only",
  });
  const {
    loading: nowPastPlayersDataLoading,
    error: nowPastPlayersDataError,
    data: nowPastPlayersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });
  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });
  const {
    loading: pastFixtureLoading,
    error: pastFixtureError,
    data: pastFixtureData,
  } = useQuery(GET_SELECTED_TEAM_PAST_FIXTURES, {
    variables: {
      sleagueTeamId: parseInt(sleagueTeamId),
    },
  });

  useEffect(() => {
    if (
      !pastFixtureLoading &&
      pastFixtureData &&
      pastFixtureData.selectedTeamPastFixtures
    ) {
      let pastFixtures = [];
      let fixIdList = [];
      pastFixtureData.selectedTeamPastFixtures.map((item) => {
        if (
          new Date(item.fixtureId.fixtureWeek.scheduledDate).getTime() >
          serverTime.getTime()
        )
          return;
        let secoundTeam = pastFixtureData.selectedTeamPastFixtures.filter(
          (fx) => {
            return fx.fixtureId.fixtureId === item.fixtureId.fixtureId;
          }
        );
        let index = fixIdList.findIndex((fx) => {
          return fx === item.fixtureId.fixtureId;
        });

        fixIdList.push(item.fixtureId.fixtureId);
        if (index === -1) pastFixtures.push(secoundTeam);
      });
      //  console.log("pastFixtures____", pastFixtures);
      setPastFixtures(pastFixtures);
    }
  }, [pastFixtureData]);

  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSLTeam]);

  useEffect(() => {
    if (
      !nowPastPlayersDataLoading &&
      nowPastPlayersData &&
      nowPastPlayersData.sleagueTeamNowPastPlayer
    ) {
      setNowPastPlayers(nowPastPlayersData.sleagueTeamNowPastPlayer);
    }
  }, [nowPastPlayersData]);

  useEffect(() => {
    if (!playersDataLoading && playersData && playersData.sleagueTeamPlayer) {
      setPlayers(playersData.sleagueTeamPlayer);
    }
  }, [playersData]);

  useEffect(() => {
    if (fixtureId) {
      getFixtureTeams(fixtureId);
    }
  }, [fixtureId]);
  useEffect(() => {
    let userSeq = userSeqVal.trim();
    let coachSeq = coachSeqVal.trim();
    if (userSeq !== "") setSelectedFormation(userSeq.slice(0, 2));
    else if (coachSeq !== "") setSelectedFormation(coachSeq.slice(0, 2));
    else setSelectedFormation("01");
  }, [coachSeqVal, userSeqVal]);

  const getFixtureTeams = async (fixtureId) => {
    if (fixtureId != null) {
      const { loading, error, data } = await fetchFixtureTeams({
        variables: {
          fixtureId: parseInt(fixtureId),
        },
      });

      if (!loading && data && data.fixtureObjectTeams) {
        setDelayed(data.fixtureObjectTeams[0].fixtureId.delayed);
        setFixtureTeam1(data.fixtureObjectTeams[0]);
        setSleagueTeamId1(
          data.fixtureObjectTeams[0].sleagueTeamId.sleagueTeamId
        );

        if (data.fixtureObjectTeams[0].scoreFinal != null)
          setScoreFinalTeam1(data.fixtureObjectTeams[0].scoreFinal);

        setFixtureTeam2(data.fixtureObjectTeams[1]);
        setSleagueTeamId2(
          data.fixtureObjectTeams[1].sleagueTeamId.sleagueTeamId
        );

        if (data.fixtureObjectTeams[1].scoreFinal != null)
          setScoreFinalTeam2(data.fixtureObjectTeams[1].scoreFinal);

        if (data.fixtureObjectTeams[0] && data.fixtureObjectTeams[0].home)
          setHomeValue("team1");
        else setHomeValue("team2");
      }
    }
  };
  const getTeamSelectionsData = (droppedPlayerNames, teamSelections) => {
    let userSeq = "<[" + selectedFormation + "]>";
    let draftList = {};
    // GK
    let key = parseInt(teamSelections.GK[0].positionSeqNo);
    let val = teamSelections.GK[0].lastDroppedItem
      ? teamSelections.GK[0].lastDroppedItem.playerSeqNO
      : "00";
    draftList[key] = val;

    // DEF
    teamSelections.DEF.defLane_col_1 &&
      teamSelections.DEF.defLane_col_1.length > 0 &&
      teamSelections.DEF.defLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.DEF.defLane_col_2 &&
      teamSelections.DEF.defLane_col_2.length > 0 &&
      teamSelections.DEF.defLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // MID
    teamSelections.MID.midLane_col_1 &&
      teamSelections.MID.midLane_col_1.length > 0 &&
      teamSelections.MID.midLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_2 &&
      teamSelections.MID.midLane_col_2.length > 0 &&
      teamSelections.MID.midLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_3 &&
      teamSelections.MID.midLane_col_3.length > 0 &&
      teamSelections.MID.midLane_col_3.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // FOR
    teamSelections.FOR.forLane_col_1 &&
      teamSelections.FOR.forLane_col_1.length > 0 &&
      teamSelections.FOR.forLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.FOR.forLane_col_2 &&
      teamSelections.FOR.forLane_col_2.length > 0 &&
      teamSelections.FOR.forLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );

    Object.keys(draftList).map((i) => {
      if (i < 10) userSeq += "<[0" + i + "]" + "[" + draftList[i] + "]>";
      else userSeq += "<[" + i + "]" + "[" + draftList[i] + "]>";
    });
    console.log("userSeq_______", userSeq);
    setUserSeqData(userSeq);
    setDroppedPlayerNamesData(droppedPlayerNames);
  };
  const getSelectedFormation = (selectedFormation) => {
    setSelectedFormation(selectedFormation);
  };

  return (
    <div>
      {fixtureTeam1 &&
        fixtureTeam2 &&
        fixtureTeam1.sleagueTeamId &&
        fixtureTeam2.sleagueTeamId && (
          <>
            <Box
              mt={2}
              mb={0.5}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                //  mb={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {homeValue === "team1" ? (
                  <>
                    <Box mt={0.5} mr={1}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 700,
                          letterSpacing: 0.5,
                          display: "flex",
                        }}
                      >
                        {fixtureTeam1.sleagueTeamId.teamId.shortName}
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src={
                          "/lms/media/" + fixtureTeam1.sleagueTeamId.teamId.logo
                        }
                        alt=""
                        height={30}
                        width={30}
                      />
                    </Box>
                    <Box
                      ml={1}
                      mr={1}
                      mt={0.5}
                      style={{
                        width: 56,
                        height: 24,
                        backgroundColor: "#E0E0E0",
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h2" style={{ paddingTop: 2 }}>
                        {typeof scoreFinalTeam1 === "number"
                          ? " " + scoreFinalTeam1
                          : ""}{" "}
                        {" -  "}
                        {typeof scoreFinalTeam2 === "number"
                          ? " " + scoreFinalTeam2
                          : ""}{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src={
                          "/lms/media/" + fixtureTeam2.sleagueTeamId.teamId.logo
                        }
                        alt=""
                        height={30}
                        width={30}
                      />
                    </Box>
                    <Box mt={0.5} ml={1}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 700,
                          letterSpacing: 0.5,
                          display: "flex",
                        }}
                      >
                        {fixtureTeam2.sleagueTeamId.teamId.shortName}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box mt={0.5}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 700,
                          letterSpacing: 0.5,
                          display: "flex",
                        }}
                      >
                        {fixtureTeam2.sleagueTeamId.teamId.shortName}
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src={
                          "/lms/media/" + fixtureTeam2.sleagueTeamId.teamId.logo
                        }
                        alt=""
                        height={30}
                        width={30}
                      />
                    </Box>
                    <Box
                      ml={1}
                      mr={1}
                      mt={0.5}
                      style={{
                        width: 56,
                        height: 24,
                        backgroundColor: "#E0E0E0",
                        borderRadius: 4,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h2" style={{ paddingTop: 2 }}>
                        {typeof scoreFinalTeam2 === "number"
                          ? " " + scoreFinalTeam2
                          : ""}{" "}
                        {" -  "}
                        {typeof scoreFinalTeam1 === "number"
                          ? " " + scoreFinalTeam1
                          : ""}{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src={
                          "/lms/media/" + fixtureTeam1.sleagueTeamId.teamId.logo
                        }
                        alt=""
                        height={30}
                        width={30}
                      />
                    </Box>
                    <Box mt={0.5}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 700,
                          letterSpacing: 0.5,
                          display: "flex",
                        }}
                      >
                        {fixtureTeam1.sleagueTeamId.teamId.shortName}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              <Typography
                variant="h6"
                style={{
                  display: "flex",
                  letterSpacing: 0.5,
                  justifyContent: "center",
                }}
              >
                {delayed ? (
                  <StyledTooltip title="The fixture has been delayed">
                    <Icon
                      aria-label="delayed"
                      style={{ marginRight: 6, height: 20, fontSize: "1.5em" }}
                    >
                      <img
                        src={delayedIcon}
                        width={18}
                        style={{ fontSize: "1em" }}
                      />
                    </Icon>
                  </StyledTooltip>
                ) : (
                  ""
                )}
                {dayFormat(fixtureTeam1.fixtureId.fixtureWeek.scheduledDate)}
              </Typography>
            </Box>
            <Grid xs="auto" align="center" style={{ paddingTop: 2 }}>
              <Paper
                style={{ width: "100%", minWidth: 320, height: 600 /*650*/ }}
              >
                <TeamInfo
                  userData={userData}
                  userFavTeam={userFavTeam}
                  fixtureTeamId={fixtureTeamId}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  leagueId={leagueId}
                  userSeqData={userSeqData}
                  userSeqVal={userSeqVal}
                  coachSeqVal={coachSeqVal}
                  userTSId={userTSId}
                  spAward={spAward}
                  embargoTime={embargoTime}
                  serverTime={serverTime}
                  sleaguePlayers={players}
                  sleagueNowPastPlayers={nowPastPlayers}
                  sleagueTeamsData={allSleagueTeamsData}
                  passTeamSelectionsData={getTeamSelectionsData}
                  passSelectedFormation={getSelectedFormation}
                  pastFixtures={pastFixtures}
                />
              </Paper>
            </Grid>
          </>
        )}
    </div>
  );
};

export default TeamSelectorMobile;
