import React, { useReducer, useCallback, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import {project_url, admin_url} from "../store/data/Constants";

import { CREATE_TEAM_MUTATION, GET_ALL_TEAM_QUERY } from "../graphQL/team_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Team = props => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { loading, error, data: allTeamsData } = useQuery(GET_ALL_TEAM_QUERY);
  const [createTeam] = useMutation(CREATE_TEAM_MUTATION);
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };
  useEffect(
    () => {
      if (!loading && allTeamsData && allTeamsData.team) {
        setTeamsData(allTeamsData.team);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [allTeamsData]
  );
  const checkName = name => {
    if (teamsData.find(t => t.name === name)) {
      return true;
    }
    return false;
  };
  const clearData = () => {
    setFailedMessage("");
    setSuccessMessage("");
  };
  const onSubmit = async formData => {
    await sleep(500);
    clearData();
    if (checkName(formData.name)) {
      setFailedMessage("This team exists");
      return;
    }
            
    const { data, error } = await createTeam({ 
      variables: {
        name: formData.name,
        short_name: formData.short_name,
        abbr_name: formData.abbr_name,
        nick_name: formData.nick_name,
        code: formData.code,
        found_year: formData.found_year,
        accept_year: formData.accept_year,
        venue_name: formData.venue_name,
        logo: formData.logo

      }

       });


    if (error) {
      console.log("Error", error, data);
      setFailedMessage("Unfortunately an error occurred.");
      //  setTimeout("");
    } else if (data && data.createTeam) {
      console.log("Success", data.createTeam);
      setSuccessMessage("New team added successfully.");
      //  setTimeout("");
      await sleep(4000);
      clearData();
      const form = document.getElementById("team_form");
      form.reset();
      navigate(`/lms/${admin_url}/team`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Add Team | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form
          className={adminStyle.form}
          id="team_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className={adminStyle.h2}>Add team to "team" table</h2>
          <label className={adminStyle.label} htmlFor="label">
            Name
          </label>
          <input
            className={adminStyle.input}
            id="name"
            {...register("name", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Abbreviation Name
          </label>
          <input
            className={adminStyle.input}
            id="abbr_name"
            {...register("abbr_name", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Short Name
          </label>
          <input
            className={adminStyle.input}
            id="short_name"
            {...register("short_name", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Nick Name
          </label>
          <input
            className={adminStyle.input}
            id="nick_name"
            {...register("nick_name", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="logo">
            Logo
          </label>
          <input className={adminStyle.input} {...register("logo")} type="file" />

          <label className={adminStyle.label} htmlFor="label">
            Code
          </label>
          <input
            className={adminStyle.input}
            id="code"
            {...register("code", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Found Year
          </label>
          <input
            className={adminStyle.input}
            id="found_year"
            {...register("found_year", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Accept Year
          </label>
          <input
            className={adminStyle.input}
            id="accept_year"
            {...register("accept_year", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="label">
            Venue Name
          </label>
          <input
            className={adminStyle.input}
            id="venue_name"
            {...register("venue_name", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}

          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
};

export default Team;
