import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "../Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { CRM_INTEREST_SUBMITS_LIST } from "../../graphQL/crm_qry";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "../Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function CRMInterestSubmissionsList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [submitters, setSubmitters] = useState([]);

  const navigate = useNavigate();


  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true
  });



  const {
    loading: submittersLoading,
    error: submittersError,
    data: submittersData,
  } = useQuery(CRM_INTEREST_SUBMITS_LIST, { fetchPolicy: "network-only" });


  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
    },
  });
  const client = useContext(ClientContext);

  // useEffect(() => {
  //   if(localStorage.getItem("refreshToken")){
  // 	onRefreshToken();
  // }
  // else
  // 	navigate(`/lms/${admin_url}/signin`);
  // }, [])

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!submittersLoading && submittersData && submittersData.crmInterestSubmitsList) {
      setSubmitters(submittersData.crmInterestSubmitsList);
    }
    if (submittersError && submittersError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [submittersData]);



  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };

//////////////////////

  const columns = [
    {
      field: "email",
      headerName: "Email Adress",
      width: 200,
      editable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      editable: false,
    },
    {
      field: "favLeague",
      headerName: "Favourite League",
      width: 200,
      editable: false,
    },
    {
      field: "favTeam",
      headerName: "Favourite Team",
      width: 200,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 200,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 200,
      editable: false,
    },
    {
      field: "submitDate",
      headerName: "Submission Date",

      width: 300,
      editable: false,
      renderCell: person =>{

        return (person?dayFormat(person.row.submitDate):"A");
      }
    },
  ];

  const rows = submitters.map(person => ({
    id: person.crmInterestInfoId,
    submitDate: person.createDate,
    email: person.email,
    country: person.country,
    city: person.city,
    firstName: person.firstName,
    lastName: person.lastName,
    favLeague:person.favouriteLeague,
    favTeam:person.favouriteTeam
  }));

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2>Interested People</h2>
        </Grid>



        <Grid item>
          <DataGrid rows={rows} columns={columns} autoHeight={true} />
        </Grid>
        <Grid item>{}</Grid>

      </Grid>
    </Grid>
  );
}
