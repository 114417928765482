import React from "react";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: (props) => ({
    backgroundColor: props.backgroundColor,
    width: "100%",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  }),
});

const TabLayout = ({ children, ...props }) => {
  const mstyle = useStyles(props);
  return <div className={mstyle.root}>{children}</div>;
};

export default TabLayout;
