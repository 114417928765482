import React, { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  makeStyles,
  Container,
} from "@material-ui/core";
import westHamLogo from "../../assets/West_Ham_United.png";
import chelseaLogo from "../../assets/Chelsea.png";
import TeamInfo from "./TeamInfo";

import { project_url } from "../../store/data/Constants";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { GET_LEAGUE_FORMATIONS_QUERY } from "../../graphQL/formationToLeague_qry";

var findAnd = require("find-and");

const GET_SLEAGUETEAM_PLAYER_QUERY = `query sleagueTeamNowPastPlayer($sleagueTeamId: Int!){
  sleagueTeamNowPastPlayer(sleagueTeamId: $sleagueTeamId){
    sleagueTeamPlayerId
    sleagueTeamId{
      sleagueId{
        sleagueId
        seasonName
      }
      teamId{
        teamId
        name
        code
      }
    }
    playerId{
      playerId
      fName
      lName
      name
      foot
      height
      weight
      birthDate
      headImage
      position
      attrPower
      attrSpeed
      attrDefense
      attrAttack
      attrStrategy
      attrTechnical
      createBy
      createDate
      updateBy
      updateDate
    }
    jerseyNO
    playerSeqNO
    status
  }
}`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function getUserSPAward(usrSPSeq){
  let usrAwardsVals = {};

  const usrSPSeqVals = usrSPSeq.split("&");
  usrAwardsVals["teamSelection"] = usrSPSeqVals[0];
  usrAwardsVals["extraPoint"] = usrSPSeqVals[1];
  usrAwardsVals["formation"] = usrSPSeqVals[2];

  const usrPlayerAwards = usrSPSeqVals[3].split("+");
  for (const i in usrPlayerAwards) {
    let data = usrPlayerAwards[i].split("#");
    // let positionSeqNo = data[0].slice(0, 2);
    let playerSeqNo = data[0].slice(2, 4);
    let playerSp = data[1];
    let postSp = data[2];
    let positionSp = data[3];
    let playerAwards = {};
    // playerAwards["playerSeqNo"] = palyerSeqNo;
    playerAwards["player"] = playerSp;
    playerAwards["post"] = postSp;
    playerAwards["position"] = positionSp;

    usrAwardsVals[playerSeqNo] = playerAwards;
  };  
  return usrAwardsVals;
}

export default function SPDetailsDialog({ leagueId, 
                                          fixtureData, 
                                          closeDialog }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [players, setPlayers] = useState([]);
  const [formations, setFormations] = useState();
  const [selectedCoachFormation, 
    setSelectedCoachFormation] = useState("");
  const [selectedCoachFormationTitle, 
    setSelectedCoachFormationTitle] = useState("");

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 963 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const coachSeqVal = fixtureData.coachSeq;
  const userSeqVal = fixtureData.userSeq;
  const sleagueTeamId = fixtureData.sleagueTeamId;

  const userSpSeq = fixtureData.spSeq;

  let awardData = [];
  let teamSelectionSP = 0;
  let extraSP = 0;
  let formationSP = 0;

  if (userSpSeq){
    awardData = getUserSPAward(userSpSeq);
    teamSelectionSP = awardData["teamSelection"];
    extraSP = awardData["extraPoint"];
    formationSP = awardData["formation"];    
  }

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: parseInt(leagueId),
    },
  });

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: parseInt(sleagueTeamId),
    },
  });

  useEffect(() => {
    if (coachSeqVal){
      let coachVal = coachSeqVal.slice(0, 2).trim();
      if (coachVal) setSelectedCoachFormation(coachVal);
    };
  }, [coachSeqVal]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {

      leagueFormationsData.leagueFormation.map(leagueFrm => {
        if (!parentFormations.includes(leagueFrm.formationId.formationPrimary)) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(frm => {
          return frm.formationId.formationPrimary === formation;
        });
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    setFormations(formationsList);

    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedCoachFormation,
    });
    if (resFound) setSelectedCoachFormationTitle(resFound.title);
  }, [leagueFormationsData, selectedCoachFormation]);


  useEffect(() => {
    if (
      !playersDataLoading &&
      playersData &&
      playersData.sleagueTeamNowPastPlayer
    ) {
      if (awardData){
        playersData.sleagueTeamNowPastPlayer.map(player => {
          if (awardData[player.playerSeqNO] !== undefined){
            player["SPDetailsData"] = awardData[player.playerSeqNO];
          } else {
            player["SPDetailsData"] = { player: "0", post: "0", position: "0" };
          }
        });
      }
      setPlayers(playersData.sleagueTeamNowPastPlayer);      
    }
  }, [playersData]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };  

  return (
    <>      
      {!mobileView && !tabletView && (
        <StyledDialog
          onClose={handleClose}
          sx={{ "& .MuiDialog-paper": { minWidth: 836, maxHeight: 862 } }}
          aria-labelledby="detail-dialog-title"
          aria-describedby="detail-dialog-description"
          open={open}
        >
          <StyledDialogTitle id="detail-dialog-title" onClose={handleClose}>
            <Typography variant="subtitle1" align="center">
              PSP Details
            </Typography>
            <Box mt={2} style={{ display: "flex", flexDirection: "row" }}>
              <Box
                mr={1}
                style={{
                  width: "42%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Typography style={{ paddingRight: 5 }} variant="subtitle2">
                  {fixtureData.team1}
                </Typography>
                <img src={fixtureData.logo1} height={40} />
              </Box>
              <Box
                style={{
                  width: "14%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Typography variant="subtitle2">
                  {fixtureData.team1FinalScore}
                </Typography>
                <Typography variant="subtitle2">-</Typography>
                <Typography variant="subtitle2">
                  {fixtureData.team2FinalScore}
                </Typography>
              </Box>
              <Box
                ml={1}
                style={{
                  width: "42%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <img src={fixtureData.logo2} height={40} />
                <Typography style={{ paddingLeft: 5 }} variant="subtitle2">
                  {fixtureData.team2}
                </Typography>
              </Box>
            </Box>
          </StyledDialogTitle>
          <Divider />
          <DialogContent>
            <Box align="center">
              <Typography variant="subtitle2">
                PSP Details Compared to Coach Team{" "}
              </Typography>
              <Box
                mt={1}
                mb={1}
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">
                  Total Award: {fixtureData.sp} PSP 
                </Typography>
                <Typography variant="body1">
                  Team Selection: {teamSelectionSP} PSP 
                </Typography>
                <Typography variant="body1">
                  Coach Formation: ({selectedCoachFormationTitle}): {formationSP} PSP
                </Typography>
              </Box>
              {formationSP === 0 && (
                <Typography variant="h6" style={{ color: "#F92626" }}>
                  You were not awarded any PSP for the formation, because the
                  coach's formation was different.
                </Typography>
              )}
            </Box>
          </DialogContent>
          <Paper style={{ minWidth: 650, height: 460 }}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <TeamInfo
                leagueId={leagueId}
                userSeqVal={userSeqVal}
                sleaguePlayers={players}
              />
            </DndProvider>
          </Paper>

          <DialogActions style={{ display: "block" }}>
            <Box mb={2} align="center" style={{ width: "center" }}>
              <Button
                variant="text"
                onClick={handleClose}
                color="primary"
                className={classes.outlinedButtonStyle}
              >
                Close
              </Button>
            </Box>
          </DialogActions>
        </StyledDialog>
      )}
    </>
  );
}
