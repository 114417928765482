import React, { useState, useEffect, useContext } from "react";
import { ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import { project_url, admin_url, 
         secretPass, seo_tag_config } from "../store/data/Constants";

// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function    : Logout
## Description : logout user
## Author      : RR
## Date        : 2022/03/12
##########################################################################*/
export default function Logout() {
  const client = useContext(ClientContext);
  const navigate = useNavigate();
  const role = CryptoJS.AES.decrypt(localStorage.getItem("role"), secretPass).toString(CryptoJS.enc.Utf8);
  useEffect(
    () => {
	  localStorage.removeItem("token");
	  localStorage.removeItem("refreshToken");
	  localStorage.removeItem("role");
	  localStorage.removeItem("email");
      localStorage.removeItem("username");
	  client.removeHeader("Authorization");
	  if(role && role === "Admin")
		  navigate(`/lms/${admin_url}/signin`);
	  else
		  navigate(`/lms/signin`);
    },
    [localStorage]
  );

  /////////////////////////////////////////////////////////////////////
  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title> Predictal | Log Out</title>
          <meta 
            name="description" 
            content="Predictal user sign-out" 
          />
          <meta 
            property="keywords" 
            content="Predcital, user sign-out, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }

    <h2 className={adminStyle.h2}>
      You are logged out. You will be redirected to login page.
    </h2>
    </>
  );
}
