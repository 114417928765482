const CreateGameStat_mutate = `
    mutation createGamestat(
      $sleagueTeamPlayerId: Int!,
      $fixtureId: Int!,
      $positionId: Int!,
      $playerOnField: String,
      $minutePlayed: Int!,
      $goal: Int,
      $ownGoal: Int,
      $cardYellow: Boolean,
      $cardRed: Boolean) {
      createGamestat(
        sleagueTeamPlayerId: $sleagueTeamPlayerId,
        fixtureId: $fixtureId,
        positionId: $positionId,
        playerOnField: $playerOnField,
        minutePlayed: $minutePlayed,
        goal: $goal,
        ownGoal: $ownGoal,
        cardYellow: $cardYellow,
        cardRed: $cardRed) {
        gameStatId
        sleagueTeamPlayerId
        positionId
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
      }
    }
`;

const GetAllGameStats_query = `
query{
      gameStat{
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
			name
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
        positionSeqNO
    }
  }
`;

const GetGameStatsByFixtureId_query = `
query($fixtureId: Int!){
      gameStatByFixture(fixtureId: $fixtureId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              teamId
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
			name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
    }
  }
`;

const GetGameStatsByGameStatId_query = `
query($gameStatId: Int!){
      gameStatByGameStat(gameStatId: $gameStatId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueTeamId
            sleagueId{
              sleagueId
              seasonName
              leagueId {
                subtitle
              }
            }
            teamId{
              teamId
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
      name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
    }
  }
`;

const GetGameStatsBySleaguePlayerId_query = `
query($sleagueTeamPlayerId: Int!){
      gameStatByPlayer(sleagueTeamPlayerId: $sleagueTeamPlayerId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
              leagueId {
                subtitle
              }
            }
            teamId{
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
			name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
    }
  }
`;

const GetGameStatsByPlayerWeekId_query = `
query($sleagueTeamPlayerId: Int!, ,$weekId: Int!){
      gameStatByPlayerWeek(sleagueTeamPlayerId: $sleagueTeamPlayerId,
                       weekId: $weekId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
              leagueId {
                subtitle
              }
            }
            teamId{
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
			name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
`;

const GetGameStatsByPlayerFixtureId_query = `
query($sleagueTeamPlayerId: Int!, ,$fixtureId: Int!){
      gameStatByPlayerFixture(sleagueTeamPlayerId: $sleagueTeamPlayerId,
                       fixtureId: $fixtureId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
              leagueId {
                subtitle
              }
            }
            teamId{
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
            name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
        
    }
  }
`;

const GetGameStatsByFixtureTeamId_query = `
query($fixtureId: Int!, ,$sleagueTeamId: Int!){
      gameStatByFixtureTeam(fixtureId: $fixtureId,
                       sleagueTeamId: $sleagueTeamId){
        gameStatId
        sleagueTeamPlayerId{
          jerseyNO
          sleagueTeamId{
            sleagueTeamId
            sleagueId{
              sleagueId
              seasonName
              leagueId {
                subtitle
              }
            }
            teamId{
              teamId
              name
              code
            }
          }
          sleagueTeamPlayerId
          playerId{
            playerId
            fName
            lName
      name
            position
          }
        }
        fixtureId{
            fixtureId
            fixtureWeek {
              playedWeek
              scheduledDate
            }
            scheduledWeek{
              playedWeek
              scheduledDate
              weekId {
                weekId
                name
              }
            }
        }
        positionId{
          positionId
          name
          label
        }
        playerOnField
        minutePlayed
        goal
        ownGoal
        cardYellow
        cardRed
        positionSeqNO
    }
  }
`;

const EditGameStat_mutation = `
mutation editGamestat( $gameStatId: Int!, $positionId: Int!, $playerOnField: String,
                       $minutePlayed: Int!, $goal: Int, $ownGoal: Int,
                       $cardYellow: Boolean,
                       $cardRed: Boolean
) {
  editGamestat(
    gameStatId: $gameStatId,
    positionId: $positionId,
    playerOnField: $playerOnField,
    minutePlayed: $minutePlayed,
    goal: $goal,
    ownGoal: $ownGoal,
    cardYellow: $cardYellow,
    cardRed: $cardRed
  ) {
    gameStat{
      gameStatId
      sleagueTeamPlayerId{
        sleagueTeamPlayerId
        jerseyNO
        sleagueTeamId{
          sleagueId{
            sleagueId
            seasonName
            leagueId {
              subtitle
            }
          }
          teamId{
            teamId
            name
            code
          }
        }
        playerId{
          playerId
          fName
          lName
		  name
          position
        }
      }
      fixtureId{
        fixtureId
        fixtureWeek {
          playedWeek
          scheduledDate
        }
        scheduledWeek{
          playedWeek
          scheduledDate
            weekId {
              weekId
              name
            }
          }
      }
      positionId{
        positionId
      }
      playerOnField
      minutePlayed
      goal
      ownGoal
      cardRed
      cardYellow
    }
  }
}
`;

const DeleteGameStat_mutation = `
mutation deleteGamestat($gameStatId: Int!,) {
  deleteGamestat(
    gameStatId: $gameStatId
  ) {
    gameStat{
      gameStatId
    }
  }
}`;

const GetGameStatCoachSeq_query = `
query($fixtureTeamId: Int!){
      gameStatCoachseq(fixtureTeamId: $fixtureTeamId){
          playerId
                    
          sleagueTeamPlayerId
          sleagueTeamPlayer
          {
            sleagueTeamPlayerId
            sleagueTeamId
            {
              sleagueTeamId
              sleagueId
              {
                sleagueId
              }
              teamId
              {
                name
              }        
            }
            playerId
            {
              name
              playerId
            }
            jerseyNO
            playerSeqNO      
            
          }
          name
          playerSeqNO
          jerseyNO
          position
          positionId
          positionSeqNO
          onField
          minPlayed
          goal
          ownGoal
          rc
          yc          
          sub
          savedData
          orginalPosition
          status
          deletable

      }
  }
`;

const CreateGameStatWIP_mutate = `
    mutation createGamestatWip(
      $sleagueTeamPlayerId: Int!,
      $fixtureId: Int!,
      $positionId: Int!,
      $positionSeqNO: String,
      $playerOnField: String,
      $minutePlayed: Int!,
      $goal: Int,
      $ownGoal: Int,
      $cardYellow: Boolean,
      $cardRed: Boolean) {
      createGamestatWip(
        sleagueTeamPlayerId: $sleagueTeamPlayerId,
        fixtureId: $fixtureId,
        positionId: $positionId,
        positionSeqNO: $positionSeqNO,
        playerOnField: $playerOnField,
        minutePlayed: $minutePlayed,
        goal: $goal,
        ownGoal: $ownGoal,
        cardYellow: $cardYellow,
        cardRed: $cardRed) {
          gameStat{
            
            sleagueTeamPlayerId{
              sleagueTeamPlayerId
              playerId{
                playerId
              }
              jerseyNO              
            }
            positionId{
              positionId
            }
            positionSeqNO
            playerOnField
            minutePlayed
            goal
            ownGoal
            cardYellow
            cardRed
          }
      }
    }
`;

const EditGameStatWIP_mutation = `
mutation editGamestatWip( $gameStatId: Int!, $positionId: Int!, $sleagueTeamPlayerId: Int!,
                       $positionSeqNO: String, $playerOnField: String,
                       $minutePlayed: Int!, $goal: Int, $ownGoal: Int,
                       $cardYellow: Boolean,
                       $cardRed: Boolean
) {
  editGamestatWip(
    gameStatId: $gameStatId,
    sleagueTeamPlayerId: $sleagueTeamPlayerId,
    positionId: $positionId,
    positionSeqNO: $positionSeqNO,
    playerOnField: $playerOnField,
    minutePlayed: $minutePlayed,
    goal: $goal,
    ownGoal: $ownGoal,
    cardYellow: $cardYellow,
    cardRed: $cardRed
  ) {
    gameStat{
      gameStatId
      sleagueTeamPlayerId{
        sleagueTeamPlayerId
        jerseyNO
        sleagueTeamId{
          sleagueId{
            sleagueId
            seasonName
            leagueId {
              subtitle
            }
          }
          teamId{
            teamId
            name
            code
          }
        }
        playerId{
          playerId
          fName
          lName
      name
          position
        }
      }
      fixtureId{
        fixtureId
        fixtureWeek {
          playedWeek
          scheduledDate
        }
        scheduledWeek{
          playedWeek
          scheduledDate
            weekId {
              weekId
              name
            }
          }
      }
      positionId{
        positionId
      }
      positionSeqNO
      playerOnField
      minutePlayed
      goal
      ownGoal
      cardRed
      cardYellow
    }
  }
}
`;
const DeleteGameStatWIP_mutation = `
mutation deleteGamestatWip($gameStatId: Int!,) {
  deleteGamestatWip(
    gameStatId: $gameStatId
  ) {
    gameStat{
      gameStatId
    }
  }
}`;
module.exports = {
  CREATE_GAME_STAT_MUTATION : CreateGameStat_mutate,
  GET_ALL_GAME_STATS_QUERY : GetAllGameStats_query,
  GET_GAME_STATS_BY_FIXTURE_ID_QUERY : GetGameStatsByFixtureId_query,
  GET_GAME_STATS_BY_PLAYER_ID_QUERY : GetGameStatsBySleaguePlayerId_query,
  GET_GAME_STATS_BY_PLAYER_WEEK_ID_QUERY : GetGameStatsByPlayerWeekId_query,
  GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY: GetGameStatsByFixtureTeamId_query,
  EDIT_GAME_STAT_MUTATION : EditGameStat_mutation,
  DELETE_GAME_STAT_MUTATION : DeleteGameStat_mutation,
  GET_GAME_STATS_BY_PLAYER_FIXTURE_ID_QUERY : GetGameStatsByPlayerFixtureId_query,
  GET_GAME_STATS_BY_GAME_STAT_ID_QUERY : GetGameStatsByGameStatId_query,
  GET_GAME_STAT_COACH_SEQ_QUERY : GetGameStatCoachSeq_query,
  CREATE_GAME_STAT_WIP_MUTATION : CreateGameStatWIP_mutate,
  EDIT_GAME_STAT_WIP_MUTATION : EditGameStatWIP_mutation,
  DELETE_GAME_STAT_WIP_MUTATION : DeleteGameStatWIP_mutation,
}
