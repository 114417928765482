import React, { useState, useEffect } from "react";
import { useQuery } from "graphql-hooks";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  makeStyles,
  Container,
} from "@material-ui/core";

import TeamInfo from "./TeamInfo";

import { project_url } from "../../../store/data/Constants";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../graphQL/formationToLeague_qry";
import { GET_SETTING_BY_NAME } from "../../../graphQL/setting_qry";

var findAnd = require("find-and");

const GET_SLEAGUETEAM_PLAYER_QUERY = `query sleagueTeamNowPastPlayer($sleagueTeamId: Int!){
  sleagueTeamNowPastPlayer(sleagueTeamId: $sleagueTeamId){
    sleagueTeamPlayerId
    sleagueTeamId{
      sleagueId{
        sleagueId
        seasonName
        spMax
      }
      teamId{
        teamId
        name
        code
      }
    }
    playerId{
      playerId
      fName
      lName
      name
      shortName
      onFieldName
      foot
      height
      weight
      birthDate
      headImage
      position
      attrPower
      attrSpeed
      attrDefense
      attrAttack
      attrStrategy
      attrTechnical
      createBy
      createDate
      updateBy
      updateDate
    }
    jerseyNO
    playerSeqNO
    status
    currentTeam
  }
}`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  formationStyle: {
    paddingTop: 5,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 18,    
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CoachSelDialog({ leagueId, 
                                         fixtureData,
                                         coachSeqVal,
                                         sleagueTeamId, 
                                         closeDialog }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [players, setPlayers] = useState([]);
  const [formations, setFormations] = useState();
  const [selectedCoachFormation, 
    setSelectedCoachFormation] = useState("");
  const [selectedCoachFormationTitle, 
    setSelectedCoachFormationTitle] = useState("");

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: parseInt(leagueId),
    },
  });

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: parseInt(sleagueTeamId),
    },
  });
  
  useEffect(() => {
    if (coachSeqVal){
      let coachVal = coachSeqVal.slice(0, 2).trim();
      if (coachVal) setSelectedCoachFormation(coachVal);
    };
  }, [coachSeqVal]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {

      leagueFormationsData.leagueFormation.map(leagueFrm => {
        if (!parentFormations.includes(leagueFrm.formationId.formationPrimary)) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(frm => {
          return frm.formationId.formationPrimary === formation;
        });
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    setFormations(formationsList);

    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedCoachFormation,
    });
    if (resFound) setSelectedCoachFormationTitle(resFound.title);
  }, [leagueFormationsData, selectedCoachFormation]);


  useEffect(() => {
    if (
      !playersDataLoading &&
      playersData &&
      playersData.sleagueTeamNowPastPlayer
    ) {      
      setPlayers(playersData.sleagueTeamNowPastPlayer);     
    }
  }, [playersData]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };

  return (
    <>      
      <StyledDialog
        onClose={handleClose}
        sx={{ "& .MuiDialog-paper": { minWidth: 836, maxHeight: 862 } }}
        aria-labelledby="detail-dialog-title"
        aria-describedby="detail-dialog-description"
        open={open}
      >
        <StyledDialogTitle id="detail-dialog-title" onClose={handleClose}>
          <Typography variant="subtitle1" align="center">
            Coach Team
          </Typography>
          <Box mt={2} style={{ display: "flex", flexDirection: "row" }}>
            <Box
              mr={1}
              style={{
                width: "42%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >              
              <Typography style={{ paddingRight: 5 }} variant="subtitle2">
                {fixtureData.fixtureTeams[0].shortName}
              </Typography>
              <img src={fixtureData.fixtureTeams[0].logo} height={40} />
            </Box>
            <Box
              style={{
                width: "14%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                backgroundColor: "#E0E0E0",
                borderRadius: 8,
              }}
            >
              <Typography variant="subtitle2">
                {fixtureData.fixtureTeams[0].finalScore}
              </Typography>
              <Typography variant="subtitle2">-</Typography>
              <Typography variant="subtitle2">
                {fixtureData.fixtureTeams[1].finalScore}
              </Typography>
            </Box>
            <Box
              ml={1}
              style={{
                width: "42%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <img src={fixtureData.fixtureTeams[1].logo} height={40} />
              <Typography style={{ paddingLeft: 5 }} variant="subtitle2">
                {fixtureData.fixtureTeams[1].shortName}
              </Typography>
            </Box>
          </Box>
        </StyledDialogTitle>
        <Divider />  
        <DialogContent> 
          <Box
            mt={2}
            mb={2}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >  
            <Box>     
              <Paper style={{ minWidth: 650 }}>
                <DndProvider backend={HTML5Backend} debugMode={true}>
                  <TeamInfo
                    leagueId={leagueId}
                    coachSeqVal={coachSeqVal}
                    sleaguePlayers={players}
                  />
                </DndProvider>
              </Paper>
            </Box>          
            <Box
              style={{
                width: "72%",
              }}
            >
              <Typography className={classes.formationStyle}>
                Formation: {selectedCoachFormationTitle}
              </Typography>            
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ display: "block" }}>
          <Box mb={2} align="center" style={{ width: "center" }}>
            <Button
              variant="text"
              onClick={handleClose}
              color="primary"
              className={classes.outlinedButtonStyle}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </StyledDialog>   
    </>
  );
}
