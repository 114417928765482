import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useManualQuery, useMutation } from "graphql-hooks";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Container,
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";

const SLEAGUE_TEAM_ADMIN_POINTS_QUERY = `query {
  sleagueTeamAdminPoints{
    sleagueTeamAdminPointId
    sleagueTeamId{
      teamId{
        shortName
      }
    }
    points
    notes
    createDate
  }
}`;

const ADD_SLEAGUE_TEAM_ADMIN_POINT_MUTATION = `
mutation addSleagueTeamAdminPoint($sleagueTeamId: Int!, $points: Int!, $notes: String) {
  addSleagueTeamAdminPoint(
    sleagueTeamId: $sleagueTeamId,
    points: $points,
    notes: $notes,
  ) {
    sleagueTeamAdminPoint {
      sleagueTeamAdminPointId
      points
      notes
    }
  }
}`;

const UPDATA_SLEAGUE_TEAM_POINTS_MUTATION = `
mutation updateSleagueTeamPoints($sleagueTeamId: Int!, $points: Int!) {
  updateSleagueTeamPoints(
    sleagueTeamId: $sleagueTeamId,
    points: $points,
  ) {
    sleagueTeam {
      sleagueTeamId
      noPoints
		}
	}
}`;

const useStyles = makeStyles(theme => ({
  cardContainer: {
    // minWidth: 600,
    // minHeight: 450,
  },
  cardContent: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  typographyStyle: {
    fontSize: 16,
    fontWeight: 500,
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 15,
      marginBottom: 2,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyle: {
    width: "100%",
    marginBottom: 2,
    marginTop: 20,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "40px",
    textTransform: "none",
    // "&:active": {
    //   backgroundColor: "#765285",
    // },
    // "&:hover": {
    //   backgroundColor: "#765285",
    // },
  },
}));

export default function DeductAddPoints(props) {
  const classes = useStyles();
  const [showDataTbl, setShowDataTbl] = useState(true);
  const [slTeams, setSlTeams] = useState(
    props.slTeams.sort((a, b) =>
      a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1
    )
  );
  const [rows, setRows] = useState([]);
  const [selcTeam, setSelcTeam] = useState("");
  const [teamError, setTeamError] = useState("");
  const [selcPoint, setSelcPoint] = useState("");
  const [pointError, setPointError] = useState("");
  const [selcNote, setSelcNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [addSleagueTeamAdminPoint] = useMutation(ADD_SLEAGUE_TEAM_ADMIN_POINT_MUTATION);
  const [updateSleagueTeamPoints] = useMutation(UPDATA_SLEAGUE_TEAM_POINTS_MUTATION);
  const {
    loading: loadingSleagueTeamAdminPoints,
    error: errorSleagueTeamAdminPoints,
    data: dataSleagueTeamAdminPoints,
  } = useQuery(SLEAGUE_TEAM_ADMIN_POINTS_QUERY);
  const [newSleagueTeamAdminPoints] = useManualQuery(SLEAGUE_TEAM_ADMIN_POINTS_QUERY);

  useEffect(() => {
    if (
      !loadingSleagueTeamAdminPoints &&
      dataSleagueTeamAdminPoints &&
      dataSleagueTeamAdminPoints.sleagueTeamAdminPoints
    ) {
      setRows(dataSleagueTeamAdminPoints.sleagueTeamAdminPoints);
    }
  }, [dataSleagueTeamAdminPoints]);

  const columns = [
    {
      field: "team",
      headerName: "Team",
      width: 150,
      // renderCell: rows => <a>{rows.row.sleagueTeamId.teamId.shortName}</a>,
    },
    {
      field: "points",
      headerName: "Points",
      renderCell: rows => {
        if (rows.row.points > 0) return "+" + rows.row.points;
        return rows.row.points;
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
    },
    {
      field: "date",
      headerName: "Date",
      width: 300,
      // renderCell: rows => dayFormat(rows.row.createDate),
    },
  ];

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (day == null) {
      return " ";
    }
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "day").value +
        " " +
        dayFormat.find(a => a.type == "month").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    }
  };

  const pointsHandleChange = event => {
    let value = event.target.value;
    if (isNaN(parseInt(value))) setSelcPoint(prvVal => prvVal);
    else {
      setSelcPoint(parseInt(value));
      setPointError("");
    }
  };

  const notesHandleChange = event => {
    let value = event.target.value;
    setSelcNote(value);
    setNoteError("");
  };

  const handlePoints = async event => {
    event.preventDefault();

    // Validate data
    if (selcTeam == "") {
      setTeamError("Please select a team");
      if (selcPoint == "") setPointError("Please set a point");
      if (selcNote == "") setNoteError("Please describe the point adjusment reason");
      return;
    }
    if (selcPoint === 0) {
      setPointError("Zero is not allowed. Please enter a negative or positive number");
      if (selcNote == "") setNoteError("Please describe the point adjusment reason");
      return;
    }
    if (selcPoint === "") {
      setPointError("Please set a point");
      if (selcNote == "") setNoteError("Please describe the point adjusment reason");
      return;
    }
    if (selcNote == "") {
      setNoteError("Please describe the point adjusment reason");
      return;
    }
    if (selcNote.length > 100) {
      setNoteError("The maximum length is 100 characters");
      return;
    }

    // Run Mutation to add the new point to SleagueTeamAdminPoint table
    const { data, loading, error } = await addSleagueTeamAdminPoint({
      variables: {
        sleagueTeamId: parseInt(selcTeam.sleagueTeamId),
        points: selcPoint,
        notes: selcNote,
      },
    });
    if (error) {
      alert(error);
    } else {
      setShowDataTbl(true);
      setSelcTeam("");
      setTeamError("");
      setSelcPoint("");
      setPointError("");
      setSelcNote("");
      setNoteError("");
      // Update sleague team points
      let {
        loading: loadSleagueTeamPoints,
        error: errorSleagueTeamPoints,
        data: dataSleagueTeamPoints,
      } = await updateSleagueTeamPoints({
        variables: {
          sleagueTeamId: parseInt(selcTeam.sleagueTeamId),
          points: selcPoint,
        },
      });
      if (errorSleagueTeamPoints) {
        alert(errorSleagueTeamPoints);
        return;
      }

      // Load the data again
      let {
        loading: loadNewSleagueAdminPoint,
        error: errorNewSleagueAdminPoint,
        data: dataNewSleagueAdminPoint,
      } = await newSleagueTeamAdminPoints();
      if (errorNewSleagueAdminPoint) {
        alert(errorNewSleagueAdminPoint);
        return;
      }
      if (
        !loadNewSleagueAdminPoint &&
        dataNewSleagueAdminPoint &&
        dataNewSleagueAdminPoint.sleagueTeamAdminPoints
      ) {
        setRows(dataNewSleagueAdminPoint.sleagueTeamAdminPoints);
      }
    }
  };

  const handleCloseAdminPointDlg = event => {
    event.preventDefault();
    setShowDataTbl(true);
    setSelcTeam("");
    setTeamError("");
    setSelcPoint("");
    setPointError("");
    setSelcNote("");
    setNoteError("");
  };

  const tblRows = rows.map(row => ({
    id: row.sleagueTeamAdminPointId,
    team: row.sleagueTeamId.teamId.shortName,
    points: row.points,
    date: dayFormat(row.createDate),
    notes: row.notes,
  }));

  return (
    <>
      <Container className={classes.cardContainer}>
        <Grid container>
          <Grid item>
            <Card>
              <CardContent className={classes.cardContent}>
                {showDataTbl ? (
                  <Box
                    style={{
                      minWidth: 800,
                      minHeight: 250,
                    }}
                  >
                    <DataGrid rows={tblRows} columns={columns} autoHeight={true} />
                    <Box
                      mt={2}
                      mb={1}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        className={classes.buttonStyle}
                        color="primary"
                        variant="contained"
                        onClick={() => setShowDataTbl(false)}
                      >
                        New
                      </Button>

                      <Box ml={2}>
                        <Button
                          className={classes.buttonStyle}
                          variant="outlined"
                          color="primary"
                          onClick={props.cancelbtnFunc}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <form onSubmit={handlePoints}>
                    <Typography gutterBottom variant="subtitle2">
                      Add Point Adjustment
                    </Typography>
                    <Divider />
                    <Autocomplete
                      options={slTeams}
                      className={classes.autoComStyle}
                      disableClearable
                      fullWidth
                      getOptionLabel={option => option.teamId.name}
                      onChange={(_event, newTeam) => {
                        setSelcTeam(newTeam);
                        setTeamError("");
                      }}
                      renderInput={params => (
                        <TextField
                          // required
                          fullWidth
                          label="Team"
                          margin="normal"
                          name="team"
                          id="team"
                          // {...register("team", { required: true })}
                          variant="outlined"
                          {...params}
                        />
                      )}
                    />
                    {teamError && (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          {teamError}
                        </FormHelperText>
                      </Box>
                    )}

                    <TextField
                      className={classes.textFieldStyle}
                      label="Points*"
                      margin="normal"
                      name="points"
                      variant="outlined"
                      type="number"
                      value={selcPoint}
                      onChange={pointsHandleChange}
                      // {...register("points", { required: true, pattern: /^[-+0-9]+$/i })}
                    />
                    {pointError && (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          {pointError}
                        </FormHelperText>
                      </Box>
                    )}
                    <TextField
                      className={classes.textFieldStyle}
                      label="Notes*"
                      margin="normal"
                      name="notes"
                      variant="outlined"
                      value={selcNote}
                      onChange={notesHandleChange}
                      multiline
                      minRows={3}
                    />
                    {noteError && (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyle} error>
                          {noteError}
                        </FormHelperText>
                      </Box>
                    )}
                    <Box
                      mt={2}
                      mb={1}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        className={classes.buttonStyle}
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Save
                      </Button>

                      <Box ml={2}>
                        <Button
                          className={classes.buttonStyle}
                          variant="outlined"
                          color="primary"
                          onClick={handleCloseAdminPointDlg}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
