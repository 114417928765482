import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import {
         GET_ALL_SLEAGUE_BUCKET_QUERY,
		 CREATE_CURRENT_BUCKET_SLEAGUE_MUTATION,
		 DELETE_CURRENT_BUCKET_SLEAGUE_MUTATION
		 } from "../graphQL/current_bucket_sleague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {
	     GET_ALL_LEAGUES_QUERY} from "../graphQL/league_qry";

import adminStyle from "./Admin.module.scss";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Current Bucket Sleague Add
## Description : Submit form data and Add/Remove current bucket sleague
## Author : SS
## Date: : 2022/04/28
##########################################################################*/
export default function CuurentBucketAdd() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [oldSleagueBucket,setOldSleagueBucket] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [sleaguesData, setSleaguesData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [fetchSleagues] = useManualQuery(GET_ALL_SLEAGUE_BUCKET_QUERY, {fetchPolicy: "network-only"});
  const [pageSize, setPageSize] = React.useState(5);
  const [AddSleagueBucket] = useMutation(CREATE_CURRENT_BUCKET_SLEAGUE_MUTATION, {
    awaitRefetchQueries: true
  });
  const [deleteSleagueBucket] = useManualQuery(DELETE_CURRENT_BUCKET_SLEAGUE_MUTATION, {fetchPolicy: "network-only"});
  const {loading: leagueLoading, error: leagueError, data: leagueData} = useQuery(GET_ALL_LEAGUES_QUERY, {fetchPolicy: "network-only"});
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
	if(leagueError && leagueError.graphQLErrors[0].message === "Signature has expired"){
		navigate(`/lms/${admin_url}/signin`);
	}
  }, [leagueData]);

  const handleChange = (async (e) => {
    const tid = e.target.value;
	if (parseInt(tid) != 0)
		{
			setOldSleagueBucket(0);
			const {loading: sleagueLoading, error: sleagueError, data: sleagueData} = await fetchSleagues({
			  variables: {
				leagueId: parseInt(tid)
			  }
			});

			if (!sleagueLoading && sleagueData && sleagueData.sleagueBucket) {
				sleagueData.sleagueBucket.map(sleagueOption  =>(
					sleagueOption.extraField===true?setOldSleagueBucket(parseInt(sleagueOption.sleagueId)):console.log("Nothing")
				))
				setSleaguesData(sleagueData.sleagueBucket);
			}
		}
	else{
		setSleaguesData([]);
	}
  });

  const columns = [
	{
		field: "current-season",
		headerName: "Current Season",
		width: 200,
        renderCell: sleaguesData => (
		<input type="radio"  id="sleagueId" {...register("sleagueId")} name="sleagueId" value={sleaguesData.row.sleagueId}
				 defaultChecked={sleaguesData.row.extraField?true:false}/>
    ),
		sortable: true,

	},
	{
		field: "season",
		headerName: "Season Name",
		width: 300,
		renderCell: sleaguesData => (
		<label>{sleaguesData.row.seasonName}</label>
    ),

		sortable: true,
	},

	];


  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 20000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    const checkedSleague = formData.sleagueId;


	//compare if checkedSleague updated, remove old one and add new
	if (oldSleagueBucket === 0)
		{
			//adding
				const { data, error} = await AddSleagueBucket({
				  variables: {
					sleagueId : parseInt(checkedSleague)
				  }

				});

				if (error) {

				  if(error.graphQLErrors[0].message === "Signature has expired"){
					navigate(`/lms/${admin_url}/signin`);
					}

				  setFailedMessage("Unfortunately an error occurred for adding sleague in current bucket.");

				} else if (data && data.createCurrentBucketSleague) {
				  console.log("Success", data.createCurrentBucketSleague);
				  setSuccessMessage("Season add for current bucket successfully.");

				}
		}
	else if (parseInt(checkedSleague) != oldSleagueBucket)
		{

			//removing
			const { data:deleteData, error:errorDelete } = await deleteSleagueBucket({
			  variables: {
				sleagueId : parseInt(oldSleagueBucket)
			  }

			});

			if (errorDelete) {

			  if(errorDelete.graphQLErrors[0].message === "Signature has expired"){
					navigate(`/lms/${admin_url}/signin`);
				}

			  setFailedMessage("Unfortunately an error occurred for removing sleague in current bucket.");

			} else if (deleteData && deleteData.deleteCurrentBucketSleague.ok === true) {
			  console.log("Success", deleteData.deleteCurrentBucketSleague);
			  setSuccessMessage("Season remove for current bucket successfully.");

			  //adding
				const { data, error} = await AddSleagueBucket({
				  variables: {
					sleagueId : parseInt(checkedSleague)
				  }

				});

				if (error) {

				  if(error.graphQLErrors[0].message === "Signature has expired"){
					navigate(`/lms/${admin_url}/signin`);
					}

				  setFailedMessage("Unfortunately an error occurred for adding sleague in current bucket.");

				} else if (data && data.createCurrentBucketSleague) {
				  console.log("Success", data.createCurrentBucketSleague);
				  setSuccessMessage("Season add for current bucket successfully.");

				}


			}

		}
		window.location.reload(false);


  };



/////////////////////////////////////////////////////////////////////
  return (
  	<>
      <Helmet>
        <title>Predictal |  League Seasons Bucket | Admin</title>
      </Helmet>
	  <Grid container direction="row" >
	      <Layout />


	     <Grid container item direction="column" xs={9} spacing={1}>


	        <Grid item>
			<form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
			<h2 className={adminStyle.h2} >Bucket of Current League Seasons</h2>
	          <label className={adminStyle.label} htmlFor="league">League</label>
	            <select className={adminStyle.select} id="league" {...register("league")} onChange={handleChange}>
	              <option value="0">Select a League...</option>
	              {
	                leaguesData.map((league, i) => (<option key={i} value={league.leagueId}>
	                  {league.title}
	                </option>))
	              }
	            </select>

				<DataGrid id="sleagueTable"
	            columns={columns}
	            rows={sleaguesData}
				getRowId={row => row.sleagueId}
	            autoHeight={true}
	            pageSize={pageSize}
	            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
	            pagination

	          />
			  <input className={adminStyle.input} type="submit" />
			 {successMessage && <div className={adminStyle.success}>{successMessage}</div>}
	         {failedMessage && <div className={adminStyle.error}>{failedMessage}</div>}
			   </form >
	        </Grid>

	      </Grid>


		</Grid>
		</>
  );
}
