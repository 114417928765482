const CreateSleagueTeam_mutate = `
mutation createSleagueTeam(
    $sleagueId: Int!,
    $teamId: Int!,
    $rank: Int)
	{
		createSleagueTeam(
			sleagueId:$sleagueId,
			teamId:$teamId,
			rank:$rank)
		{
			sleagueTeamId
			rank
		}
	}
`

const UpdateSleagueTeamRank_mutate = `
mutation updateSleagueteamrank(
    $sleagueTeamId: Int!,
    $rank: Int!)
	{
		updateSleagueteamrank(
			sleagueTeamId:$sleagueTeamId,
			rank:$rank)
		{
			sleagueTeam
			{
				sleagueTeamId
				rank
			}
		}
	}
`

const GetAllSleagueTeams_query = `
	query{
		allSleagueTeam
		{
			sleagueTeamId
      rank
      noPoints
      noGames
      noWins
      noDraws
      noLosses
      noGoalsFor
      noGoalsAgainst
      noGoalDifference
			sleagueId
			{
				sleagueId
				seasonName
			}
			teamId
			{
				teamId
				name
        shortName
        logo
			}
		}
	}
`


const GetSleagueTeams_query = `
	query($sleagueId: Int!){
		sleagueTeam(sleagueId: $sleagueId) {
			sleagueTeamId
			rank
			noPoints
			noGames
			noWins
			noDraws
			noLosses
			noGoalsFor
			noGoalsAgainst
			noGoalDifference
			notes
			sleagueId
			{
				sleagueId
				seasonName
			}
			teamId {
				teamId
				name
        shortName
				abbrName
				logo

			}
		}
	}
`

const AllSleagueTeamsPlayerNotAssigned_query= `
	query($sleagueTeamPlayerId: Int!){
		sleagueTeamPlayerNotAssigned(sleagueTeamPlayerId: $sleagueTeamPlayerId) {
			sleagueId
			{
				title
				sleagueId
			}
			sleagueTeamId
			teamId
			{
				name
        shortName
			}
		}
	}
`

const SleagueTeamsById_query= `
	query($sleagueTeamId: Int!){
		sleagueTeamById(sleagueTeamId:$sleagueTeamId)
		{
      sleagueTeamId
      teamId {
        name
      }
      sleagueId {
        seasonName
        leagueId {
          title
        }
      }
			playerSeqList
			{
				seq

			}
		}
	}
`

const SleagueTeamByTeamId_query= `
	query($teamId: Int!){
		sleagueTeamByTeamId(teamId:$teamId)
		{
      sleagueTeamId
      teamId {
      	teamId
        name
      }
      sleagueId {
        seasonName
        leagueId {
          title
        }
      }
			playerSeqList
			{
				seq

			}
		}
	}
`

const SleagueTeamNowPastPlayer_query = `
    query($sleagueTeamId: Int!){
	  sleagueTeamNowPastPlayer(sleagueTeamId: $sleagueTeamId){
		sleagueTeamPlayerId
		sleagueTeamId{
		  sleagueId{
			sleagueId
			seasonName
      spMax
		  }
		  teamId{
			teamId
			name
			shortName
			code
		  }
		}
		playerId{
		  playerId
		  fName
		  lName
		  name
      shortName
      onFieldName
		  foot
		  height
		  weight
		  birthDate
		  headImage
		  position
		  attrPower
		  attrSpeed
		  attrDefense
		  attrAttack
		  attrStrategy
		  attrTechnical
		  createBy
		  createDate
		  updateBy
		  updateDate
		}
		jerseyNO
		playerSeqNO
		status
    currentTeam
	  }
    }
`
module.exports = {
	CREATE_SLEAGUE_TEAM_MUTATION: CreateSleagueTeam_mutate,
	GET_ALL_SLEAGUE_TEAMS_QUERY : GetAllSleagueTeams_query,
    GET_SLEAGUE_TEAMS_QUERY: GetSleagueTeams_query,
	UPDATE_SLEAGUE_TEAM_RANK: UpdateSleagueTeamRank_mutate,
	ALL_SLEAGUE_TEAMS_PLAYER_NOT_ASSIGNED: AllSleagueTeamsPlayerNotAssigned_query,
	SLEAGUE_TEAM_BY_ID:SleagueTeamsById_query,
	SLEAGUE_TEAM_BY_TEAM_ID: SleagueTeamByTeamId_query,
	SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY: SleagueTeamNowPastPlayer_query
};
