import React, { useState, useEffect, useCallback, useContext, useRef } from "react";

import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import update from "immutability-helper";
import { useNavigate } from "react-router-dom";

import AlertDialog from "../../../../components/AlertDialog.js";
import ShareDialog from "../../ShareDialog.js";
import leagueLogo from "../../../../assets/Premier_League-Logo.png";
import imgRes from "../../../../assets/positionImgs/res.png";
import footField from "../../../../assets/positionImgs/football_field.png";
import { Selection } from "./Selection";
import { POSITIONS_DATA } from "../../../../store/data/DummyData";
import { REFRESH_TOKEN_MUTATION } from "../../../../graphQL/user_qry";
import { ItemTypes } from "../../../../store/data/ItemTypes";

import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../../graphQL/formationToLeague_qry";

import styled from "styled-components";
//import IconButton from "@mui/material/IconButton";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip from "@material-ui/core/Tooltip";

var findAnd = require("find-and");

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingLeft: 33,
    paddingTop: 23,
    paddingRight: 25,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    minHeight: 100,
    borderRadius: 8,
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      height: 48,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 48,
      fontSize: 16,
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;




const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    font-size: 14px;
  }
`;

const TeamInfo = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqData,
  userSeqVal,
  embargoTime,
  sleaguePlayers,
  sleagueTeamsData,
  passTeamSelectionsData,
  passSelectedFormation,
  selectionDate,
  footyNo
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  console.log('T   ', userSeqVal);
  const [players, setPlayers] = useState([]);
  const [formations, setFormations] = useState();
  const [selectedFormation, setSelectedFormation] = useState(() => {
    let userSeq = userSeqVal.trim();
    if (userSeq !== "") return userSeq.slice(0, 2);
    return "01";
  });
  const [selectedFormationTitle, setSelectedFormationTitle] = useState("");
  const [fixDate, setFixDate] = useState("");
  const [fixDateGetTime, setFixDateGetTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [counterDate, setCounterDate] = useState("");
  const [competitor, setCompetitor] = useState([]);
  const [homeTeam, setHomeTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [teamSelections, setTeamSelections] = useState([]);
  const [disableTeamSelection, setDisableTeamSelection] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [isOnshare, setIsOnshare] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);

  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);

  const exportRef = useRef();
  const [selectionCreateDate, setSelectionCreateDate] = useState("");
  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",

  });

  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "day").value +
        " " +
        dayFormat.find((a) => a.type == "month").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find((a) => a.type == "year").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value;
      return newFormat;
    }
  };

  const timeFormat = (day) => {
    const dayDate = new Date();
    const tFormat = formatter.formatToParts(dayDate);
    const newFormat = tFormat.find((a) => a.type == "hour").value +
    ":" +
    tFormat.find((a) => a.type == "minute").value + " " +
    tFormat.find((a) => a.type == "dayPeriod").value ;
    return newFormat;
  };

  const mainPath = "/lms/media/";

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: leagueId,
    },
  });

  useEffect(() => {
    console.log("sleaguePlayerssleaguePlayerssleaguePlayers", sleaguePlayers);
    setPlayers(
      sleaguePlayers.map((player) => {
        let name = player.playerId.name;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        return { name, position, jerseyNO, playerSeqNO, type, status };
      })
    );
  }, [sleaguePlayers]);

  useEffect(() => {
    let findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let droppedPlayerNamesVal = [];
    let draftTeamSelections = findPositionsData.positionsData;
    let val = "";
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i) => {
        switch (i) {
          case "01":
            val = userSeqVal.slice(4, 6);
            break;
          case "02":
            val = userSeqVal.slice(8, 10);
            break;
          case "03":
            val = userSeqVal.slice(12, 14);
            break;
          case "04":
            val = userSeqVal.slice(16, 18);
            break;
          case "05":
            val = userSeqVal.slice(20, 22);
            break;
          case "06":
            val = userSeqVal.slice(24, 26);
            break;
          case "07":
            val = userSeqVal.slice(28, 30);
            break;
          case "08":
            val = userSeqVal.slice(32, 34);
            break;
          case "09":
            val = userSeqVal.slice(36, 38);
            break;
          case "10":
            val = userSeqVal.slice(40, 42);
            break;
          case "11":
            val = userSeqVal.slice(44, 46);
        }

        resFound = findAnd.returnFound(players, {
          playerSeqNO: val,
        });

        if (resFound) {
          let resForPos = findAnd.returnFound(draftTeamSelections, {
            positionSeqNo: i,
          });
          draftTeamSelections = findAnd.changeProps(
            draftTeamSelections,
            { positionSeqNo: i },
            {
              lastDroppedItem: {
                name: resFound.name,
                playerSeqNO: resFound.playerSeqNO,
                jerseyNO: resFound.jerseyNO,
                formationPos: resForPos && resForPos.text,
              },
            }
          );
          droppedPlayerNamesVal.push(resFound.name);
        }
      }
    );
    setDroppedPlayerNames(droppedPlayerNamesVal);
    setTeamSelections(draftTeamSelections);
    passTeamSelectionsData(droppedPlayerNamesVal, draftTeamSelections);
  }, [players]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {
      leagueFormationsData.leagueFormation.map((leagueFrm) => {
        if (
          !parentFormations.includes(leagueFrm.formationId.formationPrimary)
        ) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(
          (frm) => {
            return frm.formationId.formationPrimary === formation;
          }
        );
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedFormation,
    });
    if (resFound) setSelectedFormationTitle(resFound.title);
    setFormations(formationsList);
  }, [leagueFormationsData, selectedFormation]);

  const renderSelectGroup = (formation) => {
    const items = formation.derivedCategories.map((f) => {
      return (
        <MenuItem
          style={{ paddingLeft: 30, fontSize: 14 }}
          divider={true}
          // onMouseEnter={(e) => (e.target.style.backgroundColor = "#F7F4F7")}
          // onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffffff")}
          key={f.formationId.formationSeqNO}
          value={f.formationId.formationSeqNO}
        >
          {f.formationId.title}
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        style={{
          color: "black",
          fontSize: 14,
          fontWeight: "bold",
          backgroundColor: "white",
          marginLeft: 0,
        }}
      >
        {formation.mainCategory}
      </ListSubheader>,
      items,
    ];
  };

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 500 milliseconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 200);
        loadImg.onerror = (err) => {
          reject(err);
        };
      });
    };
    let IMAGES = [];
    if (teamSelections) {
      if (teamSelections.GK) IMAGES.push(imgRes, teamSelections.GK[0].img);
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_1)
        teamSelections.DEF.defLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_2)
        teamSelections.DEF.defLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_1)
        teamSelections.MID.midLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_2)
        teamSelections.MID.midLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_3)
        teamSelections.MID.midLane_col_3.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_1)
        teamSelections.FOR.forLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_2)
        teamSelections.FOR.forLane_col_2.map((i) => IMAGES.push(i.img));
    }
    if (IMAGES.length == 0) return;
    Promise.all(IMAGES.map((img) => loadImage(img)))
      .then(() => setIsLoad(true))
      .catch((err) => console.log("Failed to load images", err));
  }, [teamSelections]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    // Set User Team Data
    if (!loading && data && data.fixtureObjectTeams) {
      data.fixtureObjectTeams.map((fixtureTeam) => {
        // Set User team data
        if (
          parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId
        ) {

          if (fixtureTeam.home) {
            setHomeTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          } else
            setSecondTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);

          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            //second: "2-digit",
            hour12: true,
          };
          // convert to milliseconds
          setCounterDate(
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() -
              (embargoTime && embargoTime.value ? embargoTime.value : 4) *
                3600000
          );
          var fixDate = new Date(
            fixtureTeam.fixtureId.fixtureWeek.scheduledDate
          ).toLocaleTimeString("en-us", options);
          setFixDateGetTime(
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime()
          );

          var formatedTime =
            fixDate.split(" ")[5] + " " + fixDate.split(" ")[6]; //time + " " + pam;
          setFixDate(
            dayFormat(fixtureTeam.fixtureId.fixtureWeek.scheduledDate)
          ); //formatedDate);
          setIsLocked(fixtureTeam.fixtureId.lock);
          //setDisableTeamSelection(fixtureTeam.fixtureId.lock); //false);
          setStartTime(formatedTime);

          setSelectionCreateDate(dayFormat(new Date()) + "  "+ timeFormat(new Date()));
        }
        // Set User team competitor for that fixtureId
        else {
          if (fixtureTeam.home)
            setHomeTeam([
              fixtureTeam.sleagueTeamId.teamId,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          setCompetitor([
            fixtureTeam.sleagueTeamId.teamId,
            fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
          ]);
        }
      });
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin`);
    }
  }, [fixtureId, embargoTime, userFavTeam]);
  useEffect(() => {
    if (!fixDateGetTime) return;
    var timeDiff = Date.now() > fixDateGetTime;
    if (isLocked && disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot edit your Starting XI team. "
        );
      else {
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot select your Starting XI team."
        );
      }
    }
    if (isLocked && !disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is locked now. You cannot edit your Starting XI team."
        );
      else {
        setAlertMsg(
          "This fixture is locked now. You cannot select your Starting XI team."
        );
      }
    }
    if (!isLocked && disableTeamSelection && !timeDiff)
      setAlertMsg(
        "This fixture is in embargo at this time. You cannot create or edit your Starting XI team. "
      );
  }, [disableTeamSelection, isLocked, userSeqVal, fixDateGetTime]);
  useEffect(() => {
    const findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (findPositionsData) {
      let resFound = [];
      let draftTeamSelections = findPositionsData.positionsData;

      // find position seq no from previous selected formation and put in new formation dropped item
      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
        (item) => {
          resFound = findAnd.returnFound(teamSelections, {
            positionSeqNo: item,
          });

          if (resFound) {
            draftTeamSelections = findAnd.changeProps(
              draftTeamSelections,
              { positionSeqNo: item },
              { lastDroppedItem: resFound.lastDroppedItem }
            );
          }
        }
      );

      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
    }
  }, [selectedFormation]);

  //const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUES_QUERY);

  const handleFormationChange = (event) => {
    var resFound = findAnd.returnFound(formations, {
      formationSeqNO: event.target.value,
    });
    console.log("resFound____((()))", resFound.title, selectedFormationTitle);
    if (resFound)
      setAlertMsg(
        `You changed the team formation from ${selectedFormationTitle} to ${resFound.title}. Check players' new positions on the Team Selection as you may want to move them into other positions in the new formation.`
      );
    setSelectedFormation(event.target.value);
    setIsLoad(false);
    passSelectedFormation(event.target.value);
  };

  useEffect(() => {
    if ((disableTeamSelection || isLocked) && userSeqVal === "")
      setSelectedFormation("");
  }, [isLocked, disableTeamSelection, userSeqVal]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  const handleDrop = useCallback(
    (index, item, pos, posLane, formationPos) => {
      if (localStorage.getItem("refreshToken")) {
        onRefreshToken();
      } else navigate(`/lms/signin`);
      console.log("Locked------------", isLocked);
      if (disableTeamSelection || isLocked) return;
      let resFound = findAnd.returnFound(players, {
        playerSeqNO: item.playerSeqNO,
      });
      if (resFound && resFound.status !== "Fit" && resFound.status !== "Booked")
        return;
      let draftTeamSelections = [];
      console.log("itemmmmmmmm___________99999999999999", item);
      const { name, playerSeqNO, jerseyNO } = item;
      if (droppedPlayerNames.indexOf(name) > -1) return;
      // If a player is dropped into the position of another player, delete the previous player
      if (pos === "GK") {
        if (teamSelections[pos][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [index]: {
              lastDroppedItem: {
                $set: { name, playerSeqNO, jerseyNO, formationPos },
              },
            },
          },
        });
      } else {
        if (teamSelections[pos][posLane][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][posLane][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [posLane]: {
              [index]: {
                lastDroppedItem: {
                  $set: { name, playerSeqNO, jerseyNO, formationPos },
                },
              },
            },
          },
        });
      }
      let draftDroppedPlayerNames = update(
        droppedPlayerNames,
        name ? { $push: [name /*, playerSeqNO*/] } : { $push: [] }
      );
      setDroppedPlayerNames(draftDroppedPlayerNames);
      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(draftDroppedPlayerNames, draftTeamSelections);
    },
    [droppedPlayerNames, teamSelections, disableTeamSelection, isLocked]
  );

  const onRemoveDroppedItem = (item, pos, posLane) => {
    // remove player from droppedPlayerNames list
    var i = droppedPlayerNames.indexOf(item.name);
    i > -1 && droppedPlayerNames.splice(i, 1);

    // set lastDroppedItem to null for removed item
    let draftTeamSelections = [];
    if (pos === "GK") {
      if (
        teamSelections.GK[0] &&
        teamSelections.GK[0].lastDroppedItem.name === item.name
      ) {
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [0]: {
              lastDroppedItem: {
                $set: null,
              },
            },
          },
        });
        setTeamSelections(draftTeamSelections);
      }
    } else {
      teamSelections[pos][posLane] &&
        teamSelections[pos][posLane].map(({ lastDroppedItem }, index) => {
          if (lastDroppedItem && lastDroppedItem.name === item.name) {
            draftTeamSelections = update(teamSelections, {
              [pos]: {
                [posLane]: {
                  [index]: {
                    lastDroppedItem: {
                      $set: null,
                    },
                  },
                },
              },
            });
            setTeamSelections(draftTeamSelections);
          }
        });
    }
    // console.log("droppedPlayerNames----",droppedPlayerNames)
    // console.log("draftTeamSelections----",draftTeamSelections)
    passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
  };





  const handleOpenShareDialog = (e) => {
    setOpenShareDialog(true);
    setIsOnshare(true);
  }
  const handleCloseShareDialog = (e) => {
    setOpenShareDialog(false);
    setIsOnshare(false);
  }

  return (
    <Card className={classes.cardContainer}  >
      <Box
        style={{ display: "flex", flexDirection: "row", width: "95%" }}
        alignItems="center"
        flexGrow={1}
      >

        <Box
          style={{ display: "flex", justifyContent: "center", width: "95%" }}
        >
          <Typography variant="subtitle1">
            EPL- Season 2023-24
          </Typography>
        </Box>
      </Box>


      <Box mt={2} style={{ display: 'flex', flexDirection: "row" }}>
        <Box
          style={{
            width: 190,
            display: "flex",
            paddingTop: 5,
            justifyContent: "end",
          }}
        >
          <Typography variant="subtitle2" style={{ fontSize: 18 }}>
            {homeTeam[0] && secondTeam
              ? homeTeam[0].shortName
              : userFavTeam.teamInfo && userFavTeam.teamInfo.shortName}{" "}
          </Typography>
        </Box>
        <Box
          ml={1}
          mr={1}
          style={{
            width: 200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box>
            <img
              src={
                homeTeam[0] && secondTeam && homeTeam[0].logo
                  ? mainPath + homeTeam[0].logo
                  : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                  ? mainPath + userFavTeam.teamInfo.logo
                  : ""
              }
              //alt="teamLogo"
              height={48}
              width={48}
            />
          </Box>
          <Box
            ml={1}
            mr={1}
            style={{
              minWidth: 64,
              backgroundColor: "#E0E0E0",
              height: 40,
              borderRadius: 8,
              display: "flex",
              justifyContent: "center",
              //  paddingTop: 5,
            }}
          >

          <Typography variant="subtitle2">
          {homeTeam && homeTeam[1]}  -  {secondTeam && secondTeam[1]}
          </Typography>

          </Box>
          <Box>
            <img
              src={
                !secondTeam[0]
                  ? competitor[0] && competitor[0].logo
                    ? mainPath + competitor[0].logo
                    : ""
                  : secondTeam[0] && mainPath + secondTeam[0].logo
              }
              //alt="teamLogo"
              height={48}
              width={48}
            />
          </Box>
        </Box>
        <Box
          style={{
            width: 190,
            display: "flex",
            paddingTop: 5,
            justifyContent: "start",
          }}
        >
          <Typography variant="subtitle2" style={{ fontSize: 18 }}>
            {!secondTeam[0]
              ? competitor[0] &&
                competitor[0].shortName
              : secondTeam[0] &&
                secondTeam[0].shortName }
          </Typography>
        </Box>
      </Box>
      <Box
        style={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
        <Typography variant="subtitle2"  style={{fontSize:18}}>
          {fixDate}

        </Typography>
      </Box>
      <Box>
      <br />
      </Box>
      <Box
        mt={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "94%",
        }}
      >
      </Box>
      <Box
        style={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
        <Typography variant="subtitle2">
          Starting XI Team: {userFavTeam.teamInfo.shortName+" ("+selectedFormationTitle + ")"}
        </Typography>
      </Box>


      {!isLoad || selectedFormation === "" ? (
        <div
          style={{
            backgroundImage: `url(${footField})`,
            width: 580,
            height: 408,
            backgroundColor: 'green'
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            float: "left",
            backgroundColor: 'green'
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                //  overflow: "hidden",
                clear: "both",
              }}
            >
              {teamSelections.GK &&
                teamSelections.GK.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(index, item, "GK", null, text)
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "GK", null)
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
            </div>
            {teamSelections.DEF && teamSelections.DEF.defLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.DEF.defLane_col_1.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "DEF",
                              "defLane_col_1",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "DEF", "defLane_col_1")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.DEF && teamSelections.DEF.defLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.DEF.defLane_col_2.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "DEF",
                              "defLane_col_2",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "DEF", "defLane_col_2")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_1.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "MID",
                              "midLane_col_1",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "MID", "midLane_col_1")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_2.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "MID",
                              "midLane_col_2",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "MID", "midLane_col_2")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_3 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_3.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "MID",
                              "midLane_col_3",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "MID", "midLane_col_3")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.FOR && teamSelections.FOR.forLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.FOR &&
                  teamSelections.FOR.forLane_col_1.map(
                    (
                      { accepts, lastDroppedItem, text, img, height, width },
                      index
                    ) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) =>
                              handleDrop(
                                index,
                                item,
                                "FOR",
                                "forLane_col_1",
                                text
                              )
                            }
                            onRemoveDroppedItem={(item) =>
                              onRemoveDroppedItem(item, "FOR", "forLane_col_1")
                            }
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
              </div>
            )}
            {teamSelections.FOR && teamSelections.FOR.forLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.FOR.forLane_col_2.map(
                  (
                    { accepts, lastDroppedItem, text, img, height, width },
                    index
                  ) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                          onDrop={(item) =>
                            handleDrop(
                              index,
                              item,
                              "FOR",
                              "forLane_col_2",
                              text
                            )
                          }
                          onRemoveDroppedItem={(item) =>
                            onRemoveDroppedItem(item, "FOR", "forLane_col_2")
                          }
                          embargoTime={true}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            <div
              style={{
                height: 408,
                width: 29,
                backgroundImage: `url(${imgRes})`,
                //backgroundRepeat: "no-repeat",
                flexDirection: "column",
                //border: "1px solid white"
              }}
            />
          </div>
        </div>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "580px",
          justifyContent: "flex-start",
          marginTop: "5px"
        }}
      >
      <Typography variant="h5" bold>
        Footy No: {footyNo}
      </Typography>
      <Typography variant="h5" bold>
        As of: {selectionCreateDate}
      </Typography>

      </Box>


    </Card>
  );
};

export default TeamInfo;
