const SendTSResultEmail = `
  mutation sendTSResultsEmail($fixtureTeamId : Int!) {
    sendTSResultsEmail(
    fixtureTeamId: $fixtureTeamId ){
      fixtureTeamId
    }
  }
`;

const PreviewTSResultEmail = `query previewEmail($fixtureTeamId:Int!){
  previewEmail(fixtureTeamId:$fixtureTeamId)
}`;

module.exports = {
  TS_RESULT_SEND_EMAIL : SendTSResultEmail,
  TS_RESULT_PREVIEW_EMAIL: PreviewTSResultEmail,
};
