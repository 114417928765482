import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext
} from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import {
  GET_SLEAGUE_TEAMS_QUERY,
  UPDATE_SLEAGUE_TEAM_RANK
} from "../graphQL/sleague_team_query";
import {
  GET_ALL_SLEAGUES_QUERY,
  GET_SLEAGUE_QUERY
} from "../graphQL/sleague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function SleagueTeamList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [teamsData, setTeamsData] = useState([]);

  const initialState = [{ sleagueTeamId: "sleagueTeamRank_0", rank: "" }];

  const [teamsRanking, setTeamsRanking] = useState(initialState);

  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);

  const [pageSize, setPageSize] = React.useState(20);

  const navigate = useNavigate();
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only"
  });
  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only"
  });

  const [rankTeam] = useManualQuery(UPDATE_SLEAGUE_TEAM_RANK, {
    fetchPolicy: "network-only"
  });

  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    }
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") }
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(
    () => {
      if (!leagueLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );

  const handleLeagueChange = async e => {
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid)
      }
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSleagueChange = async e => {
    const tid = e.target.value;
    console.log(tid);
    const {
      loading: teamLoading,
      error: teamError,
      data: allTeamsData
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) }
    });

    if (!teamLoading && allTeamsData && allTeamsData.sleagueTeam) {
      setTeamsData(allTeamsData.sleagueTeam);
      assignTeamRanking(allTeamsData.sleagueTeam);
    }
    if (
      teamError &&
      teamError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const assignTeamRanking = async teams => {
    //assgin ranking for each teams
    {
      teams.map((team, i) =>
        setTeamsRanking(current => [
          ...current,
          {
            sleagueTeamId: "sleagueTeamRank_" + team.sleagueTeamId,
            rank: team.rank
          }
        ])
      );
    }
  };

  const updateTeamRanking = async e => {
    const newTeamsRanking = teamsRanking.map(obj => {
      // 👇️ if sleagueTeamId match, update rank property
      if (obj.sleagueTeamId === e.target.id) {
        return { ...obj, rank: e.target.value };
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setTeamsRanking(newTeamsRanking);
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    //loop over teams ranking to update rank in database
    for (const [i, team] of teamsRanking.entries()) {
      const selectedId = team.sleagueTeamId.replace("sleagueTeamRank_", "");
      if (selectedId != "0") {
        const { data, error } = await rankTeam({
          variables: {
            sleagueTeamId: parseInt(selectedId),
            rank: parseInt(team.rank)
          }
        });

        if (error) {
          console.log("Error", error, data);
          setFailedMessage("Unfortunately an error occurred.");
          setTimeout("");
        } else if (data && data.updateSleagueteamRank) {
          setSuccessMessage("rank of team has been updated successfully.");

          setTimeout("");
        }
      }
    }
    window.location.reload(false);
  };

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 60,
      editable: false,
      renderCell: teamsData => (
        <img src={"/lms/media/" + teamsData.row.teamId.logo} height={40}
        width={40} />
      )
    },

    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
      renderCell: teamsData => <label>{teamsData.row.teamId.name}</label>
    },

    {
      field: "abbrName",
      headerName: "Abbreviate Name",
      width: 200,
      editable: false,
      renderCell: teamsData => <label>{teamsData.row.teamId.abbrName}</label>
    },
    {
      field: "ranking",
      headerName: "Ranking",
      width: 200,
      editable: false,
      renderCell: teamsRanking => (
        <input
          type="text"
          defaultValue={teamsRanking.row.rank}
          id={"sleagueTeamRank_" + teamsRanking.row.sleagueTeamId}
          {...register("sleagueTeamRank_" + teamsRanking.row.sleagueTeamId)}
          onChange={updateTeamRanking}
        />
      )
    }
  ];

  return (
    <>
      <Helmet>
        <title>Predictal | League Season Teams Ranking | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Teams in League Seasons</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleLeagueChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                Season
              </label>
              <select
                className={adminStyle.select}
                id="season"
                {...register("sleague")}
                onChange={handleSleagueChange}
              >
                <option value="0">Select a Season...</option>
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>

          <Grid item>
            <DataGrid
              id="sleagueTeamsTable"
              columns={columns}
              rows={teamsData}
              getRowId={row => row.sleagueTeamId}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              pagination
            />
          </Grid>

          <Grid item>
            <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
              <input className={adminStyle.input} type="submit" />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
