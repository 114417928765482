import React, { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import CookieBubble from "react-cookie-bubble";
import StaticLayout from "../components/StaticLayout.js";
import { Helmet } from "react-helmet";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import overlapGroup2 from "../assets/overlapGroup2.png";
import background from "../assets/background.png";
import logo from "../assets/preditcal_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import teamSelectorImg from "../assets/team-selector-web.jpg";
import teamsH2hImg from "../assets/Community team-team-web.jpg";
import { content_display_config, seo_tag_config } from "../store/data/Constants";

import { GET_USERPROFILE_QUERY } from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 15,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1610,
    width: "100% !important",
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 1330,
    marginLeft: 100,
    marginRight: 100,
  },
  footerCardContent: {
    backgroundColor: "#662483",
    borderRadius: "0 !important",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
    marginLeft: 0,
    marginRight: 0,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  readMoreButtonStyle: {
    width: "140px !important",
    height: "42px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  mobileReadMoreButtonStyle: {
    width: "117px !important",
    height: "63px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  itemList: {
    "& li": {
      backgroundColor: "rgba(102, 36, 131, 0.05)",
      padding: "2rem 2rem 2rem 2rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
      listStyle: "none",
      //@include prefix(border-radius, 0.8rem, moz webkit ms);
      position: "relative",
      "&::before": {
        content: "O !important",
        color: "#6f42c1",
        width: "1rem",
        height: "1rem",
        left: "30px",
        top: "30px",
        backgroundColor: "#6f42c1",
        borderRadius: "50%",
        //@include prefix(border-radius, 50%, moz webkit ms);
      },
    },
  },
  faqList: {
    border: "solid 0.1rem #cedaf1",
    backgroundColor: "#fff",
    marginBottom: "1rem",
    marginRight: "0",
    borderRadius: "4px",
  },
  spReadMoreButtonStyle: {
    // width: "185px !important",
    // height: "60px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  tabletSPReadMoreButtonStyle: {
    // width: "140px !important",
    // height: "63px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
}));

/*##########################################################################
## Const : HowItWorks
## Description : Welcome page
## Author : RR
## Date: : 2023/07/03
##########################################################################*/
const HowItWorks = () => {
  const [userData, setUserData] = useState([]);
  const [sleagueId, setSleagueId] = useState(0);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [favFixtureTeam, setFavFixtureTeam] = useState("");
  const [lastFixtureTeam, setLastFixtureTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);
  const [expanded4, setExpanded4] = useState(true);
  const [expanded5, setExpanded5] = useState(true);
  const [expanded6, setExpanded6] = useState(true);
  const [expanded7, setExpanded7] = useState(true);
  const [expanded8, setExpanded8] = useState(true);
  const [h2hOpen, setH2hOpen] = React.useState(false);
  const [teamSelectorOpen, setTeamSelectorOpen] = React.useState(false);

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });

  const handleH2hOpen = () => {
    setH2hOpen(true);
  };
  const handleH2hClose = () => {
    setH2hOpen(false);
  };
  const handleTeamSelectorOpen = () => {
    setTeamSelectorOpen(true);
  };
  const handleTeamSelectorClose = () => {
    setTeamSelectorOpen(false);
  };

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1099 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (!loading && data && data.profile) {
      console.log(data.profile);
      setUserData(data.profile);
      setSleagueId(data.profile.userFavTeamField.sleagueTeamId.sleagueId.sleagueId);
    }
    if (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      //client.removeHeader("Authorization");
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      setAllSleagueTeams(dataSleague.allSleagueTeam);
      dataSleague.allSleagueTeam.map(sleagueteam => {
        if (
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
          console.log(userFavTeam);
        }
      });
    }
    if (errorSleague) {
      console.log(errorSleague);
    }
    console.log(userFavTeam);
  }, [dataSleague, data]);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              align: "right",
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Grid>
      <>
        {!seo_tag_config ?
          <Helmet>
            <title>Lower-RTS</title>
          </Helmet>
        :
          <Helmet>
            <title>Predictal | How Team Selector Works</title>
            <meta 
              name="description" 
              content="Predictal How Team Selector Works Reimagine Team Selector" 
            />
            <meta 
              property="keywords" 
              content="Predictal, how team selector works, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
            />
          </Helmet>
        }
        {content_display_config ?
        <Grid container direction="row" alignItems="flex-start">
          <Container className={classes.cardContainer}>
            {mobileView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContentMobile}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.5rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        How Team Selector Works
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Selecting your Starting XI team is an easy 1-2-3 step process as
                        follows:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>Register a free account and select your favourite team.</li>
                          <li>Activate your account via email.</li>
                          <li>Sign in to your account.</li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Once you are on your Dashboard page,
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            The system displays details about your favourite team's next
                            fixture.
                          </li>
                          <li>
                            Click the "My Starting XI Team" button and you will be taken
                            to the Team Selector page.
                          </li>
                          <li>
                            Select your formation and pick players for your Starting XI
                            team through an intuitive drag-and-drop experience, and submit
                            your selection.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        That's it. You can select your team on your laptop as well as on
                        your mobile device and from anywhere in the world.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can select from a comprehensive list of formations in order to
                        place players on the pitch. Offensive or defensive tactics, like
                        4-2-3-1, 4-4-2, 3-5-2 or diamond formation. All in all, 14
                        top-level formations to choose from that include their own
                        formation variations. The system will record one player in one
                        position in a formation.
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        What Happens Next?
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        After the match starts, you can compare your starting XI team with
                        the Coach Team. The Predictal analytics service kicks in right
                        after the start of the match and it will perform the following
                        tasks for you:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Collects all Predictal users' Starting XI teams and converts
                            the players info into votes for each favourite team.
                          </li>
                          <li>
                            Publishes as the first-level analytics two lists of Popular
                            Formations and Popular Players.
                          </li>
                          <li>
                            Publishes as the second-level analytics the list of players in
                            the starting XI team that garnered a team-based maximum votes
                            in all 11 positions.
                            <br />
                            This report reveals in effect the team the community has
                            chosen -- We call it the "Community Team."
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can then compare your team with the Coach Team and with the
                        Community Team.
                      </Typography>
                      <img
                        src={teamSelectorImg}
                        alt="Team Selector"
                        title="Team Selector"
                        style={{ width: "100%", flex: "0 0 auto", marginBottom: "25px" }}
                      />
                      <img
                        src={teamsH2hImg}
                        alt="Team Selector"
                        title="Team Selector"
                        style={{ width: "100%", flex: "0 0 auto", marginBottom: "20px" }}
                      />
                      <Box mt={1} mb={1.5}>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.5rem",
                            fontWeight: 600,
                            lineHeight: "1.5",
                            color: "black",
                          }}
                        >
                          Predictal Sport Points Awards
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.5",
                            color: "black",
                            marginBottom: "1rem",
                          }}
                        >
                          You will get awarded with Predictal Sport Points (PSP) for your
                          correct picks compared to the starting lineup of the Coach team.
                          Don't miss out on a special gift given to the member with the
                          highest PSP. A fantastic prediction experience.
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className={classes.spReadMoreButtonStyle}
                            variant="contained"
                            onClick={() => {
                              navigate("/lms/sport-points-awards.html");
                            }}
                          >
                            More about PSP Awards
                          </Button>
                        </Box>
                      </Box>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.5rem",
                          fontWeight: 600,
                          lineHeight: "1.5",
                          color: "black",
                        }}
                      >
                        Benefits of Using Predictal Football Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        While most of our users come to our site to generate their
                        Starting XI teams and share their tactics on the social media,
                        there are also other benefits of using Predictal Team Selector.
                        Some of them are:
                      </Typography>
                      <ul className={classes.itemList}>
                        <li>
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} xl={1} xs={1}>
                              <CircleIcon
                                style={{
                                  color: "#662483",
                                  fontSize: 13,
                                  marginLeft: "-10",
                                }}
                              />
                            </Grid>
                            <Grid item lg={11} md={11} xl={11} xs={11}>
                              <div
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                Supplement football analysis posts for sport articles and
                                blogs to make the article easier to understand and provide
                                a more visually-appealing infographics.
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} xl={1} xs={1}>
                              <CircleIcon
                                style={{
                                  color: "#662483",
                                  fontSize: 13,
                                  marginLeft: "-10",
                                }}
                              />
                            </Grid>
                            <Grid item lg={11} md={11} xl={11} xs={11}>
                              <div
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                Generate local team line-ups as they are hard to find on
                                the Internet.
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} xl={1} xs={1}>
                              <CircleIcon
                                style={{
                                  color: "#662483",
                                  fontSize: 13,
                                  marginLeft: "-10",
                                }}
                              />
                            </Grid>
                            <Grid item lg={11} md={11} xl={11} xs={11}>
                              <div
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                Use the Team selector as a team line-up template to edit
                                it later with a graphics software.
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                        <li>
                          <Grid container spacing={2}>
                            <Grid item lg={1} md={1} xl={1} xs={1}>
                              <CircleIcon
                                style={{
                                  color: "#662483",
                                  fontSize: 13,
                                  marginLeft: "-10",
                                }}
                              />
                            </Grid>
                            <Grid item lg={11} md={11} xl={11} xs={11}>
                              <div
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                Save team line-ups for soccer management video games like
                                Football Manager.
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.5rem",
                          fontWeight: 600,
                          lineHeight: "1.5",
                          color: "black",
                        }}
                      >
                        Frequently Asked Questions
                      </Typography>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded1 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded1(!expanded1);
                          }}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            How late can I select my starting line-up?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            You can predict your starting line-up up to 2 hours before the
                            start time of the fixture in which your favourite team plays.
                            This window of time is called the "embargo period." Note that
                            all fixture times are listed in your local time.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded2 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded2(!expanded2);
                          }}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            Do you update the status of players after a match?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            Yes, we do. For example, in the team roster players are marked
                            with indicators as injured, unavailable, cautioned (yellow
                            carded), and suspended (red-carded).
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded3 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded3(!expanded3);
                          }}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            What if I change my favourite team later during the league
                            season?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            No worries! We've got you covered! Go to your Profile page and
                            select another team. From here on, you will see on your
                            Fixtures page the fixtures for your newly-selected favourite
                            team. For the previous weeks, you will still be able to view
                            fixtures for your previously-selected favorite team.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded4 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded4(!expanded4);
                          }}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            How do you generate the Community Team?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            The Predictal community members' starting line-ups will be
                            tabulated into votes. One vote represents a team player in a
                            position in a formation in a fixture. Next, the popular
                            formation is determined based on the formation that garners
                            the highest number of votes by community members. Finally,
                            Predictal's Community Team Generator runs an algorithm to
                            maximize the sum of the votes in all 11 positions in the
                            popular formation. The result is a table where its columns
                            represent the positions in the popular formation and its rows
                            represent the players with the votes they received.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded5 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded5(!expanded5);
                          }}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            What football event would are you releasing?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            At kickoff, we are making available to the sport fans the
                            English Premier League - Season 2024-25. You will be able to
                            pick the starting line-up of your favorite team every week
                            during this season. Exciting, isn't it?
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded6 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded6(!expanded6);
                          }}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            What prizes are you going to offer?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            With each starting line-up you create, you will be awarded
                            Predictal Sport Points (PSP) based on how close your team is to the Coach
                            Team. The closer to the Coach Team, the more PSPs you earn. We
                            shall award a gift to the predictor with the highest PSPs
                            twice: At the end of the mid-season and at the end of the
                            season. No entry in any form is required to start collecting
                            PSPs for your team selections.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded7 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded7(!expanded7);
                          }}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            Why does the Team Selector not let me select my team?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            This is because when you decided to pick your team, you were
                            already in the embargo period. Embargo period is set as a
                            fixed number of hours before the start time of a fixture.
                            Currently the embargo period is set to 2 hours. This means if
                            a fixture with your favourite team in it starts at 6:00pm, you
                            can select your starting line-up before 4:00pm. All fixture
                            times are indicated in your local time.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className={classes.faqList}>
                        <AccordionSummary
                          expandIcon={expanded8 ? <AddIcon /> : <RemoveIcon />}
                          onClick={event => {
                            setExpanded8(!expanded8);
                          }}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                          style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                        >
                          <Typography
                            style={{
                              paddingLeft: 16,
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.3rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            How do I contact Predictal in case I need help?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.9rem",
                              fontWeight: 400,
                              lineHeight: "1.7",
                              color: "black",
                            }}
                          >
                            We would love to hear from you one way or another. Please drop
                            us an email to{" "}
                            <a href="mailto:support@predictal.com">
                              support@predital.com
                            </a>{" "}
                            about anything, anytime, and from anywhere in the world!
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        xl={5}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.5rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xl={4}
                        xs={12}
                        style={{ textAlign: "center", marginTop: "-20px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team. The Ultimate Game
                          Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={4}
                        xl={3}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className={classes.readMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
            {tabletView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContentTablet}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.8rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        How Team Selector Works
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Selecting your Starting XI team is an easy 1-2-3 step process as
                        follows:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>Register a free account and select your favourite team.</li>
                          <li>Activate your account via email.</li>
                          <li>Sign in to your account.</li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Once you are on your Dashboard page,
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            The system displays details about your favourite team's next
                            fixture.
                          </li>
                          <li>
                            Click the "My Starting XI Team" button and you will be taken
                            to the Team Selector page.
                          </li>
                          <li>
                            Select your formation and pick players for your Starting XI
                            team through an intuitive drag-and-drop experience, and submit
                            your selection.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        That's it. You can select your team on your laptop as well as on
                        your mobile device and from anywhere in the world.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can select from a comprehensive list of formations in order to
                        place players on the pitch. Offensive or defensive tactics, like
                        4-2-3-1, 4-4-2, 3-5-2 or diamond formation. All in all, 14
                        top-level formations to choose from that include their own
                        formation variations. The system will record one player in one
                        position in a formation.
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.3rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        What Happens Next?
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        After the match starts, you can compare your starting XI team with
                        the Coach Team. The Predictal analytics service kicks in right
                        after the start of the match and it will perform the following
                        tasks for you:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Collects all Predictal users' Starting XI teams and converts
                            the players info into votes for each favourite team.
                          </li>
                          <li>
                            Publishes as the first-level analytics two lists of Popular
                            Formations and Popular Players.
                          </li>
                          <li>
                            Publishes as the second-level analytics the list of players in
                            the starting XI team that garnered a team-based maximum votes
                            in all 11 positions.
                            <br />
                            This report reveals in effect the team the community has
                            chosen -- We call it the "Community Team."
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can then compare your team with the Coach Team and with the
                        Community Team.
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleTeamSelectorOpen}>
                            <img
                              src={teamSelectorImg}
                              alt="Team Selector"
                              title="Team Selector"
                              style={{
                                width: "100%",
                                flex: "0 0 auto",
                                marginBottom: "25px",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleH2hOpen}>
                            <img
                              src={teamsH2hImg}
                              alt="Team Selector"
                              title="Team Selector"
                              style={{
                                width: "100%",
                                flex: "0 0 auto",
                                marginBottom: "20px",
                              }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                      <Box mt={1} mb={1.5}>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.7rem",
                            fontWeight: 600,
                            lineHeight: "1.5",
                            color: "black",
                          }}
                        >
                          Predictal Sport Points Awards
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.5",
                            color: "black",
                            marginBottom: "1rem",
                          }}
                        >
                          You will get awarded with Predictal Sport Points (PSP) for your
                          correct picks compared to the starting lineup of the Coach team.
                          Don't miss out on a special gift given to the member with the
                          highest PSP. A fantastic prediction experience.
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className={classes.tabletSPReadMoreButtonStyle}
                            variant="contained"
                            onClick={() => {
                              navigate("/lms/sport-points-awards.html");
                            }}
                          >
                            More about PSP Awards
                          </Button>
                        </Box>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.7rem",
                              fontWeight: 600,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            Benefits of Using Predictal Football Team Selector
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.1rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                              marginBottom: "1rem",
                            }}
                          >
                            While most of our users come to our site to generate their
                            Starting XI teams and share their tactics on the social media,
                            there are also other benefits of using Predictal Team
                            Selector. Some of them are:
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <ul className={classes.itemList}>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Supplement football analysis posts for sport articles
                                    and blogs to make the article easier to understand and
                                    provide a more visually-appealing infographics.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Generate local team line-ups as they are hard to find
                                    on the Internet.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Use the Team selector as a team line-up template to
                                    edit it later with a graphics software.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Save team line-ups for soccer management video games
                                    like Football Manager.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.7rem",
                          fontWeight: 600,
                          lineHeight: "1.5",
                          color: "black",
                        }}
                      >
                        Frequently Asked Questions
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xl={6}
                          xs={6}
                          style={{ marginTop: "20px" }}
                        >
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded1 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded1(!expanded1);
                              }}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How late can I select my starting line-up?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                You can predict your starting line-up up to 2 hours before
                                the start time of the fixture in which your favourite team
                                plays. This window of time is called the "embargo period."
                                Note that all fixture times are listed in your local time.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded2 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded2(!expanded2);
                              }}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                Do you update the status of players after a match?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                Yes, we do. For example, in the team roster players are
                                marked with indicators as injured, unavailable, cautioned
                                (yellow carded), and suspended (red-carded).
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded3 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded3(!expanded3);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What if I change my favourite team later during the league
                                season?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                No worries! We've got you covered! Go to your Profile page
                                and select another team. From here on, you will see on
                                your Fixtures page the fixtures for your newly-selected
                                favourite team. For the previous weeks, you will still be
                                able to view fixtures for your previously-selected
                                favorite team.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded4 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded4(!expanded4);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How do you generate the Community Team?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                The Predictal community members' starting line-ups will be
                                tabulated into votes. One vote represents a team player in
                                a position in a formation in a fixture. Next, the popular
                                formation is determined based on the formation that
                                garners the highest number of votes by community members.
                                Finally, Predictal's Community Team Generator runs an
                                algorithm to maximize the sum of the votes in all 11
                                positions in the popular formation. The result is a table
                                where its columns represent the positions in the popular
                                formation and its rows represent the players with the
                                votes they received.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xl={6}
                          xs={6}
                          style={{ marginTop: "20px" }}
                        >
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded5 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded5(!expanded5);
                              }}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What football event would are you releasing?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                At kickoff, we are making available to the sport fans the
                                English Premier League - Season 2024-25. You will be able
                                to pick the starting line-up of your favorite team every
                                week during this season. Exciting, isn't it?
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded6 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded6(!expanded6);
                              }}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What prizes are you going to offer?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                With each starting line-up you create, you will be awarded
                                Predictal Sport Points (PSP) based on how close your team is to the
                                Coach Team. The closer to the Coach Team, the more PSPs you
                                earn. We shall award a gift to the predictor with the
                                highest PSPs twice: At the end of the mid-season and at the
                                end of the season. No entry in any form is required to
                                start collecting PSPs for your team selections.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded7 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded7(!expanded7);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                Why does the Team Selector not let me select my team?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                This is because when you decided to pick your team, you
                                were already in the embargo period. Embargo period is set
                                as a fixed number of hours before the start time of a
                                fixture. Currently the embargo period is set to 2 hours.
                                This means if a fixture with your favourite team in it
                                starts at 6:00pm, you can select your starting line-up
                                before 4:00pm. All fixture times are indicated in your
                                local time.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded8 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded8(!expanded8);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How do I contact Predictal in case I need help?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                We would love to hear from you one way or another. Please
                                drop us an email to{" "}
                                <a href="mailto:support@predictal.com">
                                  support@predital.com
                                </a>{" "}
                                about anything, anytime, and from anywhere in the world!
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid item lg={1} md={1} xl={1} xs={1}></Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        xl={5}
                        xs={5}
                        style={{
                          border: "solid #E6312B",
                          borderBottomWidth: 0,
                          borderRightWidth: 0,
                          borderTopWidth: 0,
                          borderLeftWidth: 15,
                          height: 70,
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.7rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xl={4}
                        xs={4}
                        style={{ marginTop: "10px", marginLeft: "-20px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "0.9rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team.
                          <br />
                          The Ultimate Game Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        xl={2}
                        xs={2}
                        style={{ paddingLeft: "0 !important", marginLeft: "-20px" }}
                      >
                        <Button
                          className={classes.mobileReadMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
            {!tabletView && !mobileView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "2.2rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        How Team Selector Works
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Selecting your Starting XI team is an easy 1-2-3 step process as
                        follows:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>Register a free account and select your favourite team.</li>
                          <li>Activate your account via email.</li>
                          <li>Sign in to your account.</li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Once you are on your Dashboard page,
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            The system displays details about your favourite team's next
                            fixture.
                          </li>
                          <li>
                            Click the "My Starting XI Team" button and you will be taken
                            to the Team Selector page.
                          </li>
                          <li>
                            Select your formation and pick players for your Starting XI
                            team through an intuitive drag-and-drop experience, and submit
                            your selection.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        That's it. You can select your team on your laptop as well as on
                        your mobile device and from anywhere in the world.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can select from a comprehensive list of formations in order to
                        place players on the pitch. Offensive or defensive tactics, like
                        4-2-3-1, 4-4-2, 3-5-2 or diamond formation. All in all, 14
                        top-level formations to choose from that include their own
                        formation variations. The system will record one player in one
                        position in a formation.
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.3rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        What Happens Next?
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        After the match starts, you can compare your starting XI team with
                        the Coach Team. The Predictal analytics service kicks in right
                        after the start of the match and it will perform the following
                        tasks for you:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Collects all Predictal users' Starting XI teams and converts
                            the players info into votes for each favourite team.
                          </li>
                          <li>
                            Publishes as the first-level analytics two lists of Popular
                            Formations and Popular Players.
                          </li>
                          <li>
                            Publishes as the second-level analytics the list of players in
                            the starting XI team that garnered a team-based maximum votes
                            in all 11 positions.
                            <br />
                            This report reveals in effect the team the community has
                            chosen -- We call it the "Community Team."
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        You can then compare your team with the Coach Team and with the
                        Community Team.
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleTeamSelectorOpen}>
                            <img
                              src={teamSelectorImg}
                              alt="Team Selector"
                              title="Team Selector"
                              style={{ width: "100%", flex: "0 0 auto" }}
                            />
                          </a>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleH2hOpen}>
                            <img
                              src={teamsH2hImg}
                              alt="Team Selector"
                              title="Team Selector"
                              style={{ width: "100%", flex: "0 0 auto" }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                      <Box mt={1} mb={1.5}>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "2.2rem",
                            fontWeight: 600,
                            lineHeight: "1.5",
                            color: "black",
                          }}
                        >
                          Predictal Sport Points Awards
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.5",
                            color: "black",
                            marginBottom: "1rem",
                          }}
                        >
                          You will get awarded with Predictal Sport Points (PSP) for your
                          correct picks compared to the starting lineup of the Coach team.
                          Don't miss out on a special gift given to the member with the
                          highest PSP. A fantastic prediction experience.You will get
                          awarded with Predictal Sport Points (PSP) for your correct picks
                          compared to the starting lineup of the Coach team. Don't miss
                          out on a special gift given to the member with the highest PSP. A
                          fantastic prediction experience.
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className={classes.spReadMoreButtonStyle}
                            variant="contained"
                            onClick={() => {
                              navigate("/lms/sport-points-awards.html");
                            }}
                          >
                            More about PSP Awards
                          </Button>
                        </Box>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "2.2rem",
                              fontWeight: 600,
                              lineHeight: "1.5",
                              color: "black",
                            }}
                          >
                            Benefits of Using Predictal Football Team Selector
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "1.1rem",
                              fontWeight: 400,
                              lineHeight: "1.5",
                              color: "black",
                              marginBottom: "1rem",
                            }}
                          >
                            While most of our users come to our site to generate their
                            Starting XI teams and share their tactics on the social media,
                            there are also other benefits of using Predictal Team
                            Selector. Some of them are:
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <ul className={classes.itemList}>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Supplement football analysis posts for sport articles
                                    and blogs to make the article easier to understand and
                                    provide a more visually-appealing infographics.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Generate local team line-ups as they are hard to find
                                    on the Internet.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Use the Team selector as a team line-up template to
                                    edit it later with a graphics software.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                            <li>
                              <Grid container spacing={2}>
                                <Grid item lg={1} md={1} xl={1} xs={1}>
                                  <CircleIcon
                                    style={{
                                      color: "#662483",
                                      fontSize: 13,
                                      marginLeft: "-10",
                                    }}
                                  />
                                </Grid>
                                <Grid item lg={11} md={11} xl={11} xs={11}>
                                  <div
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "1rem",
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    Save team line-ups for soccer management video games
                                    like Football Manager.
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "2.2rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        Frequently Asked Questions
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded1 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded1(!expanded1);
                              }}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How late can I select my starting line-up?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                You can predict your starting line-up up to 2 hours before
                                the start time of the fixture in which your favourite team
                                plays. This window of time is called the "embargo period."
                                Note that all fixture times are listed in your local time.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded2 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded2(!expanded2);
                              }}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                Do you update the status of players after a match?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                Yes, we do. For example, in the team roster players are
                                marked with indicators as injured, unavailable, cautioned
                                (yellow carded), and suspended (red-carded).
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded3 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded3(!expanded3);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What if I change my favourite team later during the league
                                season?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                No worries! We've got you covered! Go to your Profile page
                                and select another team. From here on, you will see on
                                your Fixtures page the fixtures for your newly-selected
                                favourite team. For the previous weeks, you will still be
                                able to view fixtures for your previously-selected
                                favorite team.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded4 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded4(!expanded4);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How do you generate the Community Team?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                The Predictal community members' starting line-ups will be
                                tabulated into votes. One vote represents a team player in
                                a position in a formation in a fixture. Next, the popular
                                formation is determined based on the formation that
                                garners the highest number of votes by community members.
                                Finally, Predictal's Community Team Generator runs an
                                algorithm to maximize the sum of the votes in all 11
                                positions in the popular formation. The result is a table
                                where its columns represent the positions in the popular
                                formation and its rows represent the players with the
                                votes they received.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded5 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded5(!expanded5);
                              }}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What football event would are you releasing?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                At kickoff, we are making available to the sport fans the
                                English Premier League - Season 2024-25. You will be able
                                to pick the starting line-up of your favorite team every
                                week during this season. Exciting, isn't it?
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded6 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded6(!expanded6);
                              }}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                What prizes are you going to offer?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                With each starting line-up you create, you will be awarded
                                Predictal Sport Points (PSP) based on how close your team is to the
                                Coach Team. The closer to the Coach Team, the more PSPs you
                                earn. We shall award a gift to the predictor with the
                                highest PSPs twice: At the end of the mid-season and at the
                                end of the season. No entry in any form is required to
                                start collecting PSPs for your team selections.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded7 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded7(!expanded7);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                Why does the Team Selector not let me select my team?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                This is because when you decided to pick your team, you
                                were already in the embargo period. Embargo period is set
                                as a fixed number of hours before the start time of a
                                fixture. Currently the embargo period is set to 2 hours.
                                This means if a fixture with your favourite team in it
                                starts at 6:00pm, you can select your starting line-up
                                before 4:00pm. All fixture times are indicated in your
                                local time.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion className={classes.faqList}>
                            <AccordionSummary
                              expandIcon={expanded8 ? <AddIcon /> : <RemoveIcon />}
                              onClick={event => {
                                setExpanded8(!expanded8);
                              }}
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                              style={{ flexDirection: "row-reverse", paddingLeft: 0 }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1.3rem",
                                  fontWeight: 400,
                                  lineHeight: "1.5",
                                  color: "black",
                                }}
                              >
                                How do I contact Predictal in case I need help?
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                style={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.9rem",
                                  fontWeight: 400,
                                  lineHeight: "1.7",
                                  color: "black",
                                }}
                              >
                                We would love to hear from you one way or another. Please
                                drop us an email to{" "}
                                <a href="mailto:support@predictal.com">
                                  support@predital.com
                                </a>{" "}
                                about anything, anytime, and from anywhere in the world!
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        style={{
                          border: "solid #E6312B",
                          borderBottomWidth: 0,
                          borderRightWidth: 0,
                          borderTopWidth: 0,
                          borderLeftWidth: 15,
                          height: 70,
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "2.1rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ marginTop: "-10px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team.
                          <br />
                          The Ultimate Game Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        style={{ paddingLeft: "0 !important" }}
                      >
                        <Button
                          className={classes.readMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
          </Container>
        </Grid>
        :
        <b>Temporary Content</b>
        }

        <BootstrapDialog
          onClose={handleTeamSelectorClose}
          aria-labelledby="customized-dialog-title"
          open={teamSelectorOpen}
          scroll="body"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleTeamSelectorClose}
          ></BootstrapDialogTitle>
          <Paper style={{ minWidth: 650, height: 570 }}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <img
                src={teamSelectorImg}
                alt="Team Selector"
                title="Team Selector"
                style={{ width: "100%", flex: "0 0 auto" }}
              />
            </DndProvider>
          </Paper>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleH2hClose}
          aria-labelledby="customized-dialog-title"
          open={h2hOpen}
          scroll="body"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleH2hClose}
          ></BootstrapDialogTitle>
          <Paper style={{ minWidth: 650, height: 570 }}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <img
                src={teamsH2hImg}
                alt="Team Selector"
                title="Team Selector"
                style={{ width: "100%", flex: "0 0 auto" }}
              />
            </DndProvider>
          </Paper>
        </BootstrapDialog>
      </>
    </Grid>
  );
};

export default HowItWorks;
