import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_ALL_SLEAGUES_QUERY, GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import { GET_SLEAGUE_WEEK_QUERY } from "../graphQL/week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function SleagueWeekList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [weeksData, setWeeksData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);
  const navigate = useNavigate();
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only"
  });
  const [fetchWeeks] = useManualQuery(GET_SLEAGUE_WEEK_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!leagueLoading && leagueData && leagueData.league) {
        setLeaguesData(leagueData.league);
      }
      if (
        leagueError &&
        leagueError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [leagueData]
  );

  const handleLeagueChange = async e => {
    setWeeksData(null);
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid)
      }
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSleagueChange = async e => {
    setWeeksData(null);
    const tid = e.target.value;
    console.log(tid);
    const {
      loading: weekLoading,
      error: weekError,
      data: allWeeksData
    } = await fetchWeeks({
      variables: { sleagueId: parseInt(tid) }
    });

    if (!weekLoading && allWeeksData && allWeeksData.sleagueWeek) {
      console.log(allWeeksData);
      setWeeksData(allWeeksData.sleagueWeek);
    }
    if (weekError){
      if(weekError.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
      else
      console.log(weekError);
    }
  };
  
  const dayFormat = day => {
    console.log(day);
    const dayDate = new Date(day);
    console.log(dayDate);
    const newFormat = dayDate.toDateString();
    return newFormat;
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "name",
      headerName: "Week Name",
      width: 200,
      editable: false,
      renderCell: rows => (
        <a href={`/lms/${admin_url}/sleague-week/${rows.row.id}`} target="_self">
          {rows.row.name}
        </a>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      editable: false,
      renderCell: rows => <> {rows.row.startDate?dayFormat(rows.row.startDate):"-"} </>
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 200,
      editable: false,
      renderCell: rows => <>{rows.row.endDate?dayFormat(rows.row.endDate):"-"}</>
    }
  ];
  let rows = [];
  if (weeksData) {
    rows = weeksData.map((slt, id) => ({ id: slt.weekId, name: slt.name, startDate: slt.startDate, endDate: slt.endDate }));
  }

  return (
    <>
      <Helmet>
        <title>Predictal | Weeks | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Weeks in League Seasons</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleLeagueChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                League Season
              </label>
              <select
                className={adminStyle.select}
                id="season"
                {...register("sleague")}
                onChange={handleSleagueChange}
              >
                <option value="0">Select a Season...</option>
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/sleague-week/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
