import React, { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import CookieBubble from "react-cookie-bubble";
import StaticLayout from "../components/StaticLayout.js";
import { Helmet } from "react-helmet";
import HomeIcon from "@mui/icons-material/Home";
import moment from "moment";
import overlapGroup2 from "../assets/overlapGroup2.png";
import background from "../assets/background.png";
import logo from "../assets/preditcal_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import spLedgerImg from "../assets/sp-ledger-web.jpg";
import spDetailImg from "../assets/sp-detail-web.jpg";
import { content_display_config, seo_tag_config } from "../store/data/Constants";

import { GET_USERPROFILE_QUERY } from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 15,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1610,
    width: "100% !important",
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 1330,
    marginLeft: 100,
    marginRight: 100,
  },
  footerCardContent: {
    backgroundColor: "#662483",
    borderRadius: "0 !important",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
    marginLeft: 0,
    marginRight: 0,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  readMoreButtonStyle: {
    width: "140px !important",
    height: "42px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  mobileReadMoreButtonStyle: {
    width: "117px !important",
    height: "63px",
    textTransform: "none",
    color: "white !important",
    fontFamily: "Poppins, sans-serif",
    fontSize: "1.1rem",
    borderRadius: 0,
    fontWeight: 500,
    marginLeft: 0,
    backgroundColor: "#E6312B !important",
    "&:active": {
      backgroundColor: "#E6312B !important",
    },
    "&:hover": {
      backgroundColor: "#E6312B !important",
    },
  },
  itemList: {
    "& li": {
      backgroundColor: "rgba(102, 36, 131, 0.05)",
      padding: "2rem 2rem 2rem 2rem",
      marginBottom: "0.5rem",
      borderRadius: "0.5rem",
      listStyle: "none",
      //@include prefix(border-radius, 0.8rem, moz webkit ms);
      position: "relative",
      "&::before": {
        content: "O !important",
        color: "#6f42c1",
        width: "1rem",
        height: "1rem",
        left: "30px",
        top: "30px",
        backgroundColor: "#6f42c1",
        borderRadius: "50%",
        //@include prefix(border-radius, 50%, moz webkit ms);
      },
    },
  },
  faqList: {
    border: "solid 0.1rem #cedaf1",
    backgroundColor: "#fff",
    marginBottom: "1rem",
    marginRight: "0",
    borderRadius: "4px",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
}));

/*##########################################################################
## Const : SportPointsAwards
## Description : Sport Points Awards
## Author : DP
## Date: : 2023/07/18
##########################################################################*/
const SportPointsAwards = () => {
  const [userData, setUserData] = useState([]);
  const [sleagueId, setSleagueId] = useState(0);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [favFixtureTeam, setFavFixtureTeam] = useState("");
  const [lastFixtureTeam, setLastFixtureTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);
  const [expanded4, setExpanded4] = useState(true);
  const [expanded5, setExpanded5] = useState(true);
  const [expanded6, setExpanded6] = useState(true);
  const [expanded7, setExpanded7] = useState(true);
  const [expanded8, setExpanded8] = useState(true);
  const [spDetailImgOpen, setSpDetailImgOpen] = React.useState(false);
  const [spLedgerImgOpen, setSpLedgerImgOpen] = React.useState(false);

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });

  const handleSpDetailImgOpen = () => {
    setSpDetailImgOpen(true);
  };
  const handleSpDetailImgClose = () => {
    setSpDetailImgOpen(false);
  };
  const handleSpLedgerImgOpen = () => {
    setSpLedgerImgOpen(true);
  };
  const handleSpLedgerImgClose = () => {
    setSpLedgerImgOpen(false);
  };

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1099 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (!loading && data && data.profile) {
      console.log(data.profile);
      setUserData(data.profile);
      setSleagueId(data.profile.userFavTeamField.sleagueTeamId.sleagueId.sleagueId);
    }
    if (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      //client.removeHeader("Authorization");
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      setAllSleagueTeams(dataSleague.allSleagueTeam);
      dataSleague.allSleagueTeam.map(sleagueteam => {
        if (
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
          console.log(userFavTeam);
        }
      });
    }
    if (errorSleague) {
      console.log(errorSleague);
    }
    console.log(userFavTeam);
  }, [dataSleague, data]);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              align: "right",
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Grid>
      <>
        {!seo_tag_config ?
          <Helmet>
            <title>Lower-RTS</title>
          </Helmet>
        :
          <Helmet>
            <title>Predictal | Predictal Sport Points Awards</title>
            <meta
              name="description"
              content="Predictal Sport Points Awards How awards work"
            />
            <meta
              property="keywords"
              content="Predictal, Predictal sport points awards, PSP awards, predictal sport points, predictal awards, awards for starting line-up, awards starting 11 team, awards starting XI team, swards starting line-up prediction, football prediction"
            />
          </Helmet>
        }
        
        {content_display_config ?
        <Grid container direction="row" alignItems="flex-start">
          <Container className={classes.cardContainer}>
            {mobileView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContentMobile}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.5rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        Predictal Sport Points Awards
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Each week you submit the starting line-up of your favourite team,
                        you will be awarded Predictal Sport Points (referred to as PSP on the
                        Predictal platform) based on your correct picks compared to the
                        Coach team and in accordance to the following PSP award table:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Team Selection: 5 PSP (automatically awarded in appreciation
                            for your effort in creating and submitting your starting XI
                            team)
                          </li>
                          <li>
                            Correct Player: 5 PSP (awarded for selecting the player who
                            appears in the Coach starting XI team)
                          </li>
                          <li>
                            Correct Formation: 10 PSP (awarded for selecting the Coach
                            starting formation)
                          </li>
                          <li>
                            Correct Goalkeeper Position: 5 PSP (awarded for selecting a
                            goalkeeper player in the correct goalkeeper position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Defender Position: 10 PSP (awarded for selecting a
                            defender player in the correct defender position according to
                            the Coach team)
                          </li>
                          <li>
                            Correct Midfielder Position: 10 PSP (awarded for selecting a
                            midfielder player in the correct midfielder position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Forward Position: 10 PSP (awarded for selecting a
                            forward player in the correct forward position according to
                            the Coach team)
                          </li>
                        </ol>
                      </Typography>
                      <img
                        src={spLedgerImg}
                        alt="PSP Ledger"
                        title="PSP Ledger"
                        style={{ width: "100%", flex: "0 0 auto", marginBottom: "25px" }}
                      />
                      <img
                        src={spDetailImg}
                        alt="PSP Detail"
                        title="PSP Detail"
                        style={{ width: "100%", flex: "0 0 auto", marginBottom: "20px" }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        No worries! We do all the calculations for you and update your PSP
                        Ledger page every week.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            After your favorite team plays in a fixture in a week, your PSP
                            for that fixture is tallied up.
                          </li>
                          <li>
                            From your Dashboard page, go to your PSP Ledger page and view
                            your Predictal Sport Points tabulated per fixture.
                          </li>
                          <li>
                            Click the "PSP Detail" link for a fixture and view the details
                            of your Predictal Sport Points for that fixture.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Awards Given to the Person with the Highest PSP
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        We will award a special gift in two occasions to the person with
                        the highest PSP. One award will be given at the end of the half
                        season and another one at the end of the full season. You are not
                        required to put forward any entrance fee to win the awards.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        All you have to do is to select the starting XI team of your
                        favourite team every week. Just sign up and start selecting your
                        team and get awarded Predictal Sport Points as you go.
                      </Typography>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        xl={5}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.5rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xl={4}
                        xs={12}
                        style={{ textAlign: "center", marginTop: "-20px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team. The Ultimate Game
                          Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={4}
                        xl={3}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className={classes.readMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
            {tabletView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContentTablet}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.8rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        Predictal Sport Points Awards
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Each week you submit the starting line-up of your favourite team,
                        you will be awarded Predictal Sport Points (referred to as PSP on the
                        Predictal platform) based on your correct picks compared to the
                        Coach team and in accordance to the following PSP award table:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Team Selection: 5 PSP (automatically awarded in appreciation
                            for your effort in creating and submitting your starting XI
                            team)
                          </li>
                          <li>
                            Correct Player: 5 PSP (awarded for selecting the player who
                            appears in the Coach starting XI team)
                          </li>
                          <li>
                            Correct Formation: 10 PSP (awarded for selecting the Coach
                            starting formation)
                          </li>
                          <li>
                            Correct Goalkeeper Position: 5 PSP (awarded for selecting a
                            goalkeeper player in the correct goalkeeper position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Defender Position: 10 PSP (awarded for selecting a
                            defender player in the correct defender position according to
                            the Coach team)
                          </li>
                          <li>
                            Correct Midfielder Position: 10 PSP (awarded for selecting a
                            midfielder player in the correct midfielder position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Forward Position: 10 PSP (awarded for selecting a
                            forward player in the correct forward position according to
                            the Coach team)
                          </li>
                        </ol>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleSpLedgerImgOpen}>
                            <img
                              src={spLedgerImg}
                              alt="PSP Ledger"
                              title="PSP Ledger"
                              style={{
                                width: "100%",
                                flex: "0 0 auto",
                                marginBottom: "25px",
                              }}
                            />
                          </a>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleSpDetailImgOpen}>
                            <img
                              src={spDetailImg}
                              alt="PSP Detail"
                              title="PSP Detail"
                              style={{
                                width: "76%",
                                flex: "0 0 auto",
                                marginBottom: "20px",
                              }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        No worries! We do all the calculations for you and update your PSP
                        Ledger page every week.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            After your favorite team plays in a fixture in a week, your PSP
                            for that fixture is tallied up.
                          </li>
                          <li>
                            From your Dashboard page, go to your PSP Ledger page and view
                            your Predictal Sport Points tabulated per fixture.
                          </li>
                          <li>
                            Click the "PSP Detail" link for a fixture and view the details
                            of your Predictal Sport Points for that fixture.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.3rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Awards Given to the Person with the Highest PSP
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        We will award a special gift in two occasions to the person with
                        the highest PSP. One award will be given at the end of the half
                        season and another one at the end of the full season. You are not
                        required to put forward any entrance fee to win the awards.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        All you have to do is to select the starting XI team of your
                        favourite team every week. Just sign up and start selecting your
                        team and get awarded Predictal Sport Points as you go.
                      </Typography>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid item lg={1} md={1} xl={1} xs={1}></Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        xl={5}
                        xs={5}
                        style={{
                          border: "solid #E6312B",
                          borderBottomWidth: 0,
                          borderRightWidth: 0,
                          borderTopWidth: 0,
                          borderLeftWidth: 15,
                          height: 70,
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "1.7rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xl={4}
                        xs={4}
                        style={{ marginTop: "10px", marginLeft: "-20px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "0.9rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team.
                          <br />
                          The Ultimate Game Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        xl={2}
                        xs={2}
                        style={{ paddingLeft: "0 !important", marginLeft: "-20px" }}
                      >
                        <Button
                          className={classes.mobileReadMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
            {!tabletView && !mobileView && (
              <>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <section>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "#E6312B",
                        }}
                      >
                        Reimagine Team Selector
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "2.2rem",
                          fontWeight: 600,
                          lineHeight: "2",
                          color: "black",
                        }}
                      >
                        Predictal Sport Points Awards
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Each week you submit the starting line-up of your favourite team,
                        you will be awarded Predictal Sport Points (referred to as PSP on the
                        Predictal platform) based on your correct picks compared to the
                        Coach team and in accordance to the following PSP award table:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            Team Selection: 5 PSP (automatically awarded in appreciation
                            for your effort in creating and submitting your starting XI
                            team)
                          </li>
                          <li>
                            Correct Player: 5 PSP (awarded for selecting the player who
                            appears in the Coach starting XI team)
                          </li>
                          <li>
                            Correct Formation: 10 PSP (awarded for selecting the Coach
                            starting formation)
                          </li>
                          <li>
                            Correct Goalkeeper Position: 5 PSP (awarded for selecting a
                            goalkeeper player in the correct goalkeeper position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Defender Position: 10 PSP (awarded for selecting a
                            defender player in the correct defender position according to
                            the Coach team)
                          </li>
                          <li>
                            Correct Midfielder Position: 10 PSP (awarded for selecting a
                            midfielder player in the correct midfielder position according
                            To the Coach team)
                          </li>
                          <li>
                            Correct Forward Position: 10 PSP (awarded for selecting a
                            forward player in the correct forward position according to
                            the Coach team)
                          </li>
                        </ol>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleSpLedgerImgOpen}>
                            <img
                              src={spLedgerImg}
                              alt="PSP Ledger"
                              title="PSP Ledger"
                              style={{ width: "100%", flex: "0 0 auto" }}
                            />
                          </a>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                          <a href="javascript:;" onClick={handleSpDetailImgOpen}>
                            <img
                              src={spDetailImg}
                              alt="PSP Detail"
                              title="PSP Detail"
                              style={{ width: "76%", flex: "0 0 auto" }}
                            />
                          </a>
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        No worries! We do all the calculations for you and update your PSP
                        Ledger page every week.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.95rem",
                          fontWeight: 400,
                          lineHeight: "1.8",
                          color: "black",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <ol>
                          <li>
                            After your favorite team plays in a fixture in a week, your PSP
                            for that fixture is tallied up.
                          </li>
                          <li>
                            From your Dashboard page, go to your PSP Ledger page and view
                            your Predictal Sport Points tabulated per fixture.
                          </li>
                          <li>
                            Click the "PSP Detail" link for a fixture and view the details
                            of your Predictal Sport Points for that fixture.
                          </li>
                        </ol>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.3rem",
                          fontWeight: 600,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        Awards Given to the Person with the Highest PSP
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.5",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        We will award a special gift in two occasions to the person with
                        the highest PSP. One award will be given at the end of the half
                        season and another one at the end of the full season. You are not
                        required to put forward any entrance fee to win the awards.
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "1.1rem",
                          fontWeight: 400,
                          lineHeight: "1.3",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        All you have to do is to select the starting XI team of your
                        favourite team every week. Just sign up and start selecting your
                        team and get awarded Predictal Sport Points as you go.
                      </Typography>
                    </section>
                  </CardContent>
                </Card>
                <CookieBubble
                  messageText="This site stores cookies. By clicking “Accept Cookies”, you agree to the storage of cookies on your device to enhance site navigation"
                  messageFontSize="14px"
                  buttonText="Accept Cookies"
                  buttonColor="rgb(230, 49, 43)"
                  cookiePolicyButtonText="Cookie Policy"
                  cookiePolicyButtonUrl="/lms/cookie-policy.html"
                  iconVisibility={false}
                />
                <Card className={classes.footerCardContent}>
                  <CardContent>
                    <Grid container spacing={4} style={{ marginTop: 1 }}>
                      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        style={{
                          border: "solid #E6312B",
                          borderBottomWidth: 0,
                          borderRightWidth: 0,
                          borderTopWidth: 0,
                          borderLeftWidth: 15,
                          height: 70,
                          marginTop: 1,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "2.1rem",
                            fontWeight: 600,
                            lineHeight: "1.3",
                            color: "white",
                          }}
                        >
                          How Team Selector Works
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ marginTop: "-10px" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            display: "inline",
                            fontSize: "1.1rem",
                            fontWeight: 400,
                            lineHeight: "1.8",
                            color: "white",
                          }}
                        >
                          Reimagine Selecting Your Starting XI Team.
                          <br />
                          The Ultimate Game Changer in Football.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        style={{ paddingLeft: "0 !important" }}
                      >
                        <Button
                          className={classes.readMoreButtonStyle}
                          variant="contained"
                          onClick={() => {
                            navigate("/lms/how-team-selector-works.html");
                          }}
                        >
                          Learn more
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </>
            )}
          </Container>
        </Grid>
        :
        <b>Temporary Content</b>
        }
        
        <BootstrapDialog
          onClose={handleSpLedgerImgClose}
          aria-labelledby="customized-dialog-title"
          open={spLedgerImgOpen}
          scroll="body"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleSpLedgerImgClose}
          ></BootstrapDialogTitle>
          <Paper style={{ width: 650, height: 450 }}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <img
                src={spLedgerImg}
                alt="Team Selector"
                title="Team Selector"
                style={{ width: "100%", flex: "0 0 auto" }}
              />
            </DndProvider>
          </Paper>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleSpDetailImgClose}
          aria-labelledby="customized-dialog-title"
          open={spDetailImgOpen}
          scroll="body"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleSpDetailImgClose}
          ></BootstrapDialogTitle>
          <Paper style={{ width: 540, height: 490 }}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <img
                src={spDetailImg}
                alt="Team Selector"
                title="Team Selector"
                style={{ width: "99%", flex: "0 0 auto" }}
              />
            </DndProvider>
          </Paper>
        </BootstrapDialog>
      </>
    </Grid>
  );
};

export default SportPointsAwards;
