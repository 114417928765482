import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import { 
         CREATE_CURRENT_BUCKET_WEEK_MUTATION,
		 DELETE_CURRENT_BUCKET_WEEK_MUTATION,
		 GET_CURRENT_SLEAGUE_WEEKS_QUERY
		 } from "../graphQL/current_bucket_week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {
	     GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY} from "../graphQL/current_bucket_sleague_qry";


import adminStyle from "./Admin.module.scss";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Current Bucket Sleague Add
## Description : Submit form data and Add/Remove current bucket sleague
## Author : SS
## Date: : 2022/04/28
##########################################################################*/
export default function CuurentBucketWeekAdd() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  
  const [sleagueWeeksData, setSleagueWeeksData] = useState([]);
  
  const [fetchWeeks] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {fetchPolicy: "network-only"});
  const [pageSize, setPageSize] = React.useState(5);
  const [AddWeekBucket] = useMutation(CREATE_CURRENT_BUCKET_WEEK_MUTATION, {
    awaitRefetchQueries: true
  });
  const [deleteWeekBucket] = useManualQuery(DELETE_CURRENT_BUCKET_WEEK_MUTATION, {fetchPolicy: "network-only"});
  const {loading: sleagueLoading, error: sleagueError, data: sleagueData} = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, {fetchPolicy: "network-only"});
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(() => {
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
    }
	if(sleagueError && sleagueError.graphQLErrors[0].message === "Signature has expired"){
		navigate(`/lms/${admin_url}/signin`);
	}
  }, [sleagueData]);
  
  const handleChange = (async (e) => {
    const tid = e.target.value;
	if (parseInt(tid) != 0)
		{
			
			const {loading: weekLoading, error: weekError, data: weekData} = await fetchWeeks({
			  variables: {
				sleagueId: parseInt(tid)
			  }
			});
			
			if (!weekLoading && weekData && weekData.currentSleagueWeek) {	
				setSleagueWeeksData(weekData.currentSleagueWeek)					  
			}
			
		}
	else{
		setSleagueWeeksData([]);
	}
  });
  
  
  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 20000);
  
  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);
	
	if (formData.sleague == "0")
		alert("Please select one league season.")
	else
		{
			if (formData.week == "0")
			   alert("Please select one week for this league season.")
			else
				{
					//remove week for current season
					const { data:deleteData, error:errorDelete } = await deleteWeekBucket({				
							variables: {				  
							sleagueId : parseInt(formData.sleague)
						  }
				  
						});
				
					if (errorDelete) 
						{					
							if(errorDelete.graphQLErrors[0].message === "Signature has expired")
								navigate(`/lms/${admin_url}/signin`);
						
				  
							setFailedMessage("Unfortunately an error occurred for removing week in current bucket season.");
				  
						} 
					else if (deleteData && deleteData.deleteCurrentBucketWeek.ok === true) 
						{
							console.log("Success", deleteData.deleteCurrentBucketWeek);
							setSuccessMessage("Week remove for current bucket season successfully.");	
						}
					
					//add new current week bucket
					const { data, error} = await AddWeekBucket({				
							variables: {				  
							weekId : parseInt(formData.week)
							}				  
						});
							
					if (error) 
						{
					
							if(error.graphQLErrors[0].message === "Signature has expired")
								navigate(`/lms/${admin_url}/signin`);
							
							setFailedMessage("Unfortunately an error occurred for adding week in current bucket season.");
				  
						} 
					else if (data && data.createCurrentBucketWeek) 
						{
							console.log("Success", data.createCurrentBucketWeek);
							setSuccessMessage("Week add for current bucket season successfully.");
							window.location.reload(false);
				
						} 
					
		
				}
		}
	

   
		
			
		
  };
  


/////////////////////////////////////////////////////////////////////
  return (
  	<>
      <Helmet>
        <title>Predictal | League Season Weeks Bucket | Admin</title>
      </Helmet>
	  <Grid container direction="row" >
	      <Layout />
			

	     <Grid container item direction="column" xs={9} spacing={1}>
		 
	        
	        <Grid item>
			<form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
			<h2 className={adminStyle.h2} >Bucket of Current League Season Weeks</h2>
	          <label className={adminStyle.label} htmlFor="sleague">Current Bucket League Season</label>
	            <select className={adminStyle.select} id="sleague" {...register("sleague")} onChange={handleChange} >
	              <option value="0">Select a League Season...</option>
	              {
	                sleaguesBucketData.map((sleague, i) => (<option key={i} value={sleague.sleagueId.sleagueId}>
	                  {sleague.sleagueId.leagueId.title} ({sleague.sleagueId.seasonName})
	                </option>))
	              }
	            </select>
			
			   <label className={adminStyle.label} htmlFor="week">Week</label>
	            <select className={adminStyle.select} id="week" {...register("week")} >
	              <option value="0">Select a Week...</option>
	              {
	                sleagueWeeksData.map((week, j) => (<option key={j} value={week.weekId} 
						selected={week.extraField?"selected":""}
					>
	                  {week.name}
	                </option>))
	              }
	            </select>
				
				
			  <input className={adminStyle.input} type="submit" />
			 {successMessage && <div className={adminStyle.success}>{successMessage}</div>}
	         {failedMessage && <div className={adminStyle.error}>{failedMessage}</div>}
			   </form >
	        </Grid>
	        		
	      </Grid>     
		  
	   	
		</Grid>
	</>
  );
}
