import { memo, useState, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { useDrop } from "react-dnd";
import { makeStyles, Typography } from "@material-ui/core";

import Tooltip from "@material-ui/core/Tooltip";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import styled from "styled-components";

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #ffffff;
    border-radius: 4;
    font-size: 14px;
    font-weight: 400px;
  }
`;
const useStyles = makeStyles({
  mainStyle: {
    color: "#212121",
    textAlign: "center",
    font: "10px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
  },
  pStyle: {
    backgroundColor: "#E0E0E0",
    borderRadius: "50%",
    width: "41px", //35
    height: "41px", //35
    //  lineHeight: "45px",
    color: "#212121",
    justifyContent: "center",
    font: "8px Roboto, Helvetica, Arial, sans-serif", //9
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "-0.2px",
  },
  pointCnt: {
    display: "flex",
    padding: "1px 1px 0px 5px",
    minHeight: 15,
    position: "relative",
  },
  droppedCnt: {
    display: "flex",
    border: "1px solid #FFFFFF",
    padding: "1px 1px 0px 1px",
    //minHeight: 20,
    //width: 60, //90,
    position: "relative",
    background: "#9FC6A3",
    borderRadius: 2,
  },
  pointValueCnt: {
    width: "30px",
    height: "30px",
    borderRadius: "22px",
    position: "absolute",
    top: "-5px",
    right: "-17px",
    background: "#4E83A2",
    color: "#FFFFFF",
    justifyContent: "center",
    textAlign: "center",
    font: "18px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700,
  },
  pointZeroValueCnt: {
    width: "30px",
    height: "30px",
    borderRadius: "22px",
    position: "absolute",
    top: "-5px",
    right: "-17px",
    background: "#C51800",
    color: "#FFFFFF",
    justifyContent: "center",
    textAlign: "center",
    font: "18px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 700,
  },
});

export const Selection = memo(function Selection({
  accept,
  text,
  lastDroppedItem,
  height,
  width,
  img,
  onDrop,
}) {
  const classes = useStyles();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  let name = "";
  if (lastDroppedItem) {
    let split = lastDroppedItem.name.split(" ");
    name =
      split[0] && split[1]
        ? split[0].charAt(0).toUpperCase() + ". " + split[1]
        : lastDroppedItem.name;
  }
  return (
    <div
      className={classes.mainStyle}
      style={{
        height,
        width,
        backgroundImage: `url(${img})`,
        //backgroundRepeat: "no-repeat",
        flexDirection: "column",
        //border: "1px solid white"
      }}
    >
      <div
        ref={drop}
        role="Selection"
        className={classes.mainStyle}
        style={{
          height: "100px",
          width: text === "GK(1)" ? "110%" : "100%",
          paddingLeft:"10px",
          flexDirection: "column",
          //border: "1px solid black"
        }}
      >
        <div className={classes.pointCnt}>
          <p className={classes.pStyle}>{text}</p>
          {lastDroppedItem && lastDroppedItem.SPDetailsData &&(
            <StyledTooltip title={parseInt(lastDroppedItem.SPDetailsData.player)+
            " (Player) + " + parseInt(lastDroppedItem.SPDetailsData.post)+
            " (Post) + " +  parseInt(lastDroppedItem.SPDetailsData.position)+
            " (Position)"}>
              <div className={ parseInt(lastDroppedItem.SPDetailsData.player)+
                    parseInt(lastDroppedItem.SPDetailsData.position) +
                    parseInt(lastDroppedItem.SPDetailsData.post) !== 0 ?
                    classes.pointValueCnt : classes.pointZeroValueCnt}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 700, color: "#FFFFFF" }}
                >
                  { parseInt(lastDroppedItem.SPDetailsData.player)+
                    parseInt(lastDroppedItem.SPDetailsData.position) +
                    parseInt(lastDroppedItem.SPDetailsData.post)}
                </Typography>
              </div>
            </StyledTooltip>
          )}
        </div>
        {lastDroppedItem && (
          <div className={classes.droppedCnt}>
            <p style={{ /*paddingRight: "5px",*/ margin: "2px" }}>
              {lastDroppedItem.jerseyNO} {name}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
