

const CreateCurrentBucketWeek_mutate = `
    mutation createCurrentBucketWeek(
	         $weekId: Int!)
				{
					createCurrentBucketWeek( 
						weekId: $weekId)
						{
							currentBucketWeek
							{
								weekId
								{
									name
									sleagueId
									{
										seasonName
									}
									extraField
								}
							}
						}
				  }	
`;

const DeleteCurrentBucketWeek_mutate = `
    mutation deleteCurrentBucketWeek(
	         $sleagueId: Int!)  
		{
			deleteCurrentBucketWeek( 
				sleagueId: $sleagueId)
			 {
				ok
			  }
		}	
`;

const GetCurrentSleagueWeeks_query = `
	query($sleagueId: Int!){
		currentSleagueWeek(sleagueId: $sleagueId) {
			weekId
			sleagueId
			{
				sleagueId
				seasonName
			}
			name
			extraField
		}
	}
`


module.exports = {
   
  CREATE_CURRENT_BUCKET_WEEK_MUTATION : CreateCurrentBucketWeek_mutate,
  DELETE_CURRENT_BUCKET_WEEK_MUTATION : DeleteCurrentBucketWeek_mutate,
  GET_CURRENT_SLEAGUE_WEEKS_QUERY: GetCurrentSleagueWeeks_query
}
