import * as React from "react";
import { useState, useEffect } from "react";
import { useQuery, useManualQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { Paper } from "@mui/material";

import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../../graphQL/sleague_qry";
import { GET_ALL_SLEAGUE_TEAMS_QUERY } from "../../../graphQL/sleague_team_query";
import { GET_FIXTURE_OBJECT_TEAMS } from "../../../graphQL/fixture_qry";

import TeamInfo from "./TeamInfo";

var findAnd = require("find-and");
const useStyles = makeStyles((theme) => ({}));

const formatter = new Intl.DateTimeFormat("en-us", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});

const dayFormat = (day) => {
  const dayDate = new Date(day);
  const dayFormat = formatter.formatToParts(dayDate);
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  }
};
const TeamSelectorTablet = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqVal,
  embargoTime,
  footyNo
}) => {
  const [players, setPlayers] = useState([]);
  const [userSeqData, setUserSeqData] = useState([]);
  const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState(() => {
    let userSeq = userSeqVal.trim();
    if (userSeq !== "") return userSeq.slice(0, 2);
    return "01";
  });

  const [fixtureTeam1, setFixtureTeam1] = useState([]);
  const [fixtureTeam2, setFixtureTeam2] = useState([]);
  const [sleagueTeamId1, setSleagueTeamId1] = useState("");
  const [sleagueTeamId2, setSleagueTeamId2] = useState("");
  const [scoreFinalTeam1, setScoreFinalTeam1] = useState("");
  const [scoreFinalTeam2, setScoreFinalTeam2] = useState("");
  const [homeValue, setHomeValue] = useState("");

  const navigate = useNavigate();
  const [fetchFixtureTeams] = useManualQuery(GET_FIXTURE_OBJECT_TEAMS, {
    fetchPolicy: "network-only",
  });
  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });

  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin`);
    }
  }, [dataSLTeam]);

  useEffect(() => {
    if (!playersDataLoading && playersData && playersData.sleagueTeamPlayer) {
      setPlayers(playersData.sleagueTeamPlayer);
    }
  }, [playersData]);

  useEffect(() => {
    if (fixtureId) {
      getFixtureTeams(fixtureId);
    }
  }, [fixtureId]);

  const getFixtureTeams = async (fixtureId) => {
    if (fixtureId != null) {
      const { loading, error, data } = await fetchFixtureTeams({
        variables: {
          fixtureId: parseInt(fixtureId),
        },
      });

      if (!loading && data && data.fixtureObjectTeams) {
        setFixtureTeam1(data.fixtureObjectTeams[0]);
        setSleagueTeamId1(
          data.fixtureObjectTeams[0].sleagueTeamId.sleagueTeamId
        );

        if (data.fixtureObjectTeams[0].scoreFinal != null)
          setScoreFinalTeam1(data.fixtureObjectTeams[0].scoreFinal);

        setFixtureTeam2(data.fixtureObjectTeams[1]);
        setSleagueTeamId2(
          data.fixtureObjectTeams[1].sleagueTeamId.sleagueTeamId
        );

        if (data.fixtureObjectTeams[1].scoreFinal != null)
          setScoreFinalTeam2(data.fixtureObjectTeams[1].scoreFinal);

        if (data.fixtureObjectTeams[0] && data.fixtureObjectTeams[0].home)
          setHomeValue("team1");
        else setHomeValue("team2");
      }
    }
  };
  const getTeamSelectionsData = (droppedPlayerNames, teamSelections) => {
    let userSeq = "<[" + selectedFormation + "]>";
    let draftList = {};
    // GK
    let key = parseInt(teamSelections.GK[0].positionSeqNo);
    let val = teamSelections.GK[0].lastDroppedItem
      ? teamSelections.GK[0].lastDroppedItem.playerSeqNO
      : "00";
    draftList[key] = val;

    // DEF
    teamSelections.DEF.defLane_col_1 &&
      teamSelections.DEF.defLane_col_1.length > 0 &&
      teamSelections.DEF.defLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.DEF.defLane_col_2 &&
      teamSelections.DEF.defLane_col_2.length > 0 &&
      teamSelections.DEF.defLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // MID
    teamSelections.MID.midLane_col_1 &&
      teamSelections.MID.midLane_col_1.length > 0 &&
      teamSelections.MID.midLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_2 &&
      teamSelections.MID.midLane_col_2.length > 0 &&
      teamSelections.MID.midLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.MID.midLane_col_3 &&
      teamSelections.MID.midLane_col_3.length > 0 &&
      teamSelections.MID.midLane_col_3.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    // FOR
    teamSelections.FOR.forLane_col_1 &&
      teamSelections.FOR.forLane_col_1.length > 0 &&
      teamSelections.FOR.forLane_col_1.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );
    teamSelections.FOR.forLane_col_2 &&
      teamSelections.FOR.forLane_col_2.length > 0 &&
      teamSelections.FOR.forLane_col_2.map(
        ({ lastDroppedItem, positionSeqNo }) => {
          let key = parseInt(positionSeqNo);
          let val = lastDroppedItem ? lastDroppedItem.playerSeqNO : "00";
          draftList[key] = val;
        }
      );

    Object.keys(draftList).map((i) => {
      if (i < 10) userSeq += "<[0" + i + "]" + "[" + draftList[i] + "]>";
      else userSeq += "<[" + i + "]" + "[" + draftList[i] + "]>";
    });
    console.log("userSeq_______", userSeq);
    setUserSeqData(userSeq);
    setDroppedPlayerNamesData(droppedPlayerNames);
  };
  const getSelectedFormation = (selectedFormation) => {
    setSelectedFormation(selectedFormation);
  };

  return (
    <div>
      {fixtureTeam1 &&
        fixtureTeam2 &&
        fixtureTeam1.sleagueTeamId &&
        fixtureTeam2.sleagueTeamId && (
          <>

            <Grid xs="auto" align="center" style={{ paddingTop: 2 }}>
              <Paper style={{ width: "100%", minWidth: 320, height: 750 }}>
                <TeamInfo
                  userData={userData}
                  userFavTeam={userFavTeam}
                  fixtureTeamId={fixtureTeamId}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  leagueId={leagueId}
                  userSeqData={userSeqData}
                  userSeqVal={userSeqVal}
                  embargoTime={embargoTime}
                  sleaguePlayers={players}
                  sleagueTeamsData={allSleagueTeamsData}
                  passTeamSelectionsData={getTeamSelectionsData}
                  passSelectedFormation={getSelectedFormation}
                  footyNo={footyNo}
                />
              </Paper>
            </Grid>
          </>
        )}
    </div>
  );
};

export default TeamSelectorTablet;
