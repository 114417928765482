const AddFormationPosition_mutate = `
    mutation addFormationPosition(
      $formationId: Int!,
      $positionId: Int!,
      $positionSeqNO: String!) {
      addFormationPosition(
        formationId: $formationId,
        positionId: $positionId,
        positionSeqNO: $positionSeqNO) {
        formationPositionId       
      }
    }
`;

const AllFormationPositions_query = `
    query{
  allFormationPositions{
    formationPositionId
    positionSeqNO
    formationId{
      formationId
      title
      formationSeqNO
      description
      formationPrimary
      createBy
      createDate
      updateBy
      updateDate
    }
    positionId{
      positionId
      label
      name
      createBy
      createDate
      updateBy
      updateDate
    }    
    updateBy
    updateDate
    createBy
    createDate
  }
}
`;

const GetFormationPosition_query = `

  query($formationId: Int!){

    formationPosition(formationId: $formationId) {
      formationPositionId
      positionSeqNO     
      formationId{
        formationId
        title
        formationSeqNO
        description
        formationPrimary
        createBy
        createDate
        updateBy
        updateDate
      }        
      positionId{
        positionId
        label
        name
        createBy
        createDate
        updateBy
        updateDate
      }  
      updateBy
      updateDate
      createBy
      createDate
    }
  }
`

module.exports = {
  ADD_FORMATION_POSITION_MUTATION : AddFormationPosition_mutate,
  ALL_FORMATION_POSITIONS_QUERY : AllFormationPositions_query,
  GET_FORMATION_POSITION_QUERY: GetFormationPosition_query,
}
