import React from "react";
import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles({
  radioLbl: (props) => ({
    width: "100%",
    height: 30,
    backgroundColor: props.bgColor,
  }),
  radio: {
    fontSize: 14,
  },
});

const CustomRadio = props => {
  const mstyle = useStyles(props);

  return (
    <FormControlLabel
      classes={{root: mstyle.radioLbl, label: mstyle.radio}}
      value={props.value}
      control={<Radio size="small" className={mstyle.radio} />}
      label={props.label}
    />
  );
};

export default CustomRadio;
