import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation, ClientContext, useManualQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout.js";

import { Grid, Box, Button } from "@material-ui/core";
import {
  GET_USERPROFILE_QUERY
} from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";
import { GET_SERVER_DATE_TIME_STRING, GET_DJANGO_DATE_TIME } from "../graphQL/setting_qry";
import "./userProfile/UserProfile/profileDesktopStyles.sass";
import {project_url, admin_url} from "../store/data/Constants";
import adminStyle from "./Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Const : Dashboard
## Description : Welcome page
## Author : RR
## Date: : 2022/05/08
##########################################################################*/
const ServerDate = () => {
  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [serverDate, setServerDate] = useState("");
  const [djangoDate, setDjangoDate] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const navigate = useNavigate();
  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only"
  });
  const { loading: loadingServerDate, error: errorServerDate, data: dataServerDate } = useQuery(GET_SERVER_DATE_TIME_STRING, {
    fetchPolicy: "network-only"
  });
  const { loading: loadingDjangoDate, error: errorDjangoDate, data: dataDjangoDate } = useQuery(GET_DJANGO_DATE_TIME, {
    fetchPolicy: "network-only"
  });
  const [refreshDjangoDate] = useManualQuery(GET_DJANGO_DATE_TIME, {
    fetchPolicy: "network-only",
  });
  const [refreshServerDate] = useManualQuery(GET_SERVER_DATE_TIME_STRING, {
    fetchPolicy: "network-only",
  });
  
  useEffect(
    () => {
      if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
        setAllSleagueTeams(dataSleague.allSleagueTeam);
		dataSleague.allSleagueTeam.map(sleagueteam => {
		  if (
            userData.userFavTeamField &&
            sleagueteam.sleagueTeamId ===
              userData.userFavTeamField.sleagueTeamId.sleagueTeamId
          ) {
            setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
          }
        });
      }
      if (errorSleague) {
        if (
          errorSleague.graphQLErrors[0].message ===
          "You do not have permission to perform this action"
        )
          navigate("/lms/signin");
        if (errorSleague.graphQLErrors[0].message === "Signature has expired")
          navigate("/lms/signin");
      }
    },
    [dataSleague]
  );
  
  useEffect(
    () => {
      if (!loading && data && data.profile) {
		setUserData(data.profile);
        localStorage.setItem("username", data.profile.username);
      }
      if (error){
	    if(error.graphQLErrors[0].message === "Signature has expired") {
          navigate("/lms/signin");
		}
      }
    },
    [data]
  );
  
  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit"
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };
  
  useEffect(() => {
    if (!loadingServerDate && dataServerDate && dataServerDate.serverDateTimeString) {
      setServerDate(dataServerDate.serverDateTimeString);
    }
    if (errorServerDate && errorServerDate.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [dataServerDate, forceRefresh]);
  
  useEffect(() => {
    if (!loadingDjangoDate && dataDjangoDate && dataDjangoDate.djangoDateTime) {
      setDjangoDate(dataDjangoDate.djangoDateTime);
    }
    if (errorDjangoDate && errorDjangoDate.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [dataDjangoDate, forceRefresh]);
  
  const refreshDates = async () => {
      const { loading: loadingDjangoDate, error: errorDjangoDate, data: dataDjangoDate } = await refreshDjangoDate();
      if (!loadingDjangoDate && dataDjangoDate && dataDjangoDate.djangoDateTime) {
        setDjangoDate(dataDjangoDate.djangoDateTime);
      }
      
      const { loading: loadingServerDate, error: errorServerDate, data: dataServerDate } = await refreshServerDate();
      if (!loadingServerDate && dataServerDate && dataServerDate.serverDateTimeString) {
        setServerDate(dataServerDate.serverDateTimeString);
      }
  }
  
  return (
    <>
      <Helmet>
        <title>Predictal | Server Date</title>        
      </Helmet> 
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
         <Box mt={5}>
           <h2>Server Date: { serverDate }</h2>
           <h2>Django Date: { djangoDate }</h2>
           <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => refreshDates()}
            >
              Refresh
            </Button>
         </Box>
      </Grid>
    </>
  );
};

export default ServerDate;
