import React from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import booked from "../../../../assets/StatusImages/booked.gif";
import suspended from "../../../../assets/StatusImages/suspended.gif";
import injured from "../../../../assets/StatusImages/injured.png";
import unavailable from "../../../../assets/StatusImages/unavailable.png";
import available from "../../../../assets/StatusImages/available.png";
import selected from "../../../../assets/StatusImages/selected.png";
import sick from "../../../../assets/StatusImages/sick.png";
import staron from "../../../../assets/BioImages/staron.png";
import staroff from "../../../../assets/BioImages/staroff.png";

const useStyles = makeStyles({
  root: {
    fontSize: 20,
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FBF3FF",
    },
  },
});

const pos = {
  Goalkeeper: "GK",
  Defender: "DEF",
  Midfielder: "MF",
  Forward: "FOR",
};
const Bio = ({ player }) => {
  const mainPath = "/lms/media/";

  const classes = useStyles();
  var currentTime = new Date();
  player.playerId.age =
    currentTime.getFullYear() - parseInt(player.playerId.birthDate.split("-")[0]);

  const bios = [
    {
      name: "Height (cm)",
      bio: player.playerId.height,
    },
    {
      name: "Weight (kg)",
      bio: player.playerId.weight,
    },
    {
      name: "Age",
      bio: player.playerId.age,
    },
    {
      name: "Foot",
      bio: player.playerId.foot,
    },
  ];

  const columns = [
    {
      field: "bios",
      width: 100,
      align: "center",
      flex: 2,
      renderCell: params => {
        return (
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "playerBio",
      width: 70,
      align: "center",
      flex: 1,
      renderCell: params => {
        return <Typography variant="h5">{params.value}</Typography>;
      },
    },
  ];

  const rows = bios.map((bio, id) => ({
    id: id,
    playerBio: bio.bio,
    bios: bio.name,
  }));
  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        //  spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={1} sm={6} md={6} />
        <Grid item xs={4} sm={6} md={6}>
          <Box mr={1}>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 40, height: 40 }}
                src={
                  player.playerId.headImage
                    ? mainPath + player.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Box mr={0.5}>
              <Typography variant="h6" style={{ textAlign: "center", color: "#212121" }}>
                {player.jerseyNO} {player.playerId.name}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {player.playerId.position}
              </Typography>
            </Box>
            <Box mt={1} style={{ textAlign: "center" }}>
              {player.status === "Unavailable" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={unavailable}
                />
              )}
              {player.status === "Fit" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={available}
                />
              )}
              {player.status === "Booked" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={booked}
                />
              )}
              {player.status === "Suspended" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={suspended}
                />
              )}
              {player.status === "Injured" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={injured}
                />
              )}
              {player.status === "Sick" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={sick}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Box
            className={classes.root}
            style={{
              // marginRight: "20px",
              // marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              rowHeight={48}
              headerHeight={0}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Box mt={3} style={{ textAlign: "center" }}>
            <Box mb={2}>
              <Typography variant="h5" style={{ color: "#765285" }}>
                ATTRIBUTES
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Power</Typography>
              </Box>
              <Box>
                {Array.from(Array(3 - parseInt(player.playerId.attrPower)), (e, i) => {
                  return <img src={staroff} style={{ marginRight: "5px" }} />;
                })}
                {Array.from(Array(parseInt(player.playerId.attrPower)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Speed</Typography>
              </Box>
              <Box>
                {Array.from(Array(3 - parseInt(player.playerId.attrSpeed)), (e, i) => {
                  return <img src={staroff} style={{ marginRight: "5px" }} />;
                })}
                {Array.from(Array(parseInt(player.playerId.attrSpeed)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Technique</Typography>
              </Box>
              <Box>
                {Array.from(
                  Array(3 - parseInt(player.playerId.attrTechnical)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(player.playerId.attrTechnical)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Strategy</Typography>
              </Box>
              <Box>
                {Array.from(Array(3 - parseInt(player.playerId.attrStrategy)), (e, i) => {
                  return <img src={staroff} style={{ marginRight: "5px" }} />;
                })}
                {Array.from(Array(parseInt(player.playerId.attrStrategy)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Attack</Typography>
              </Box>
              <Box>
                {Array.from(Array(3 - parseInt(player.playerId.attrAttack)), (e, i) => {
                  return <img src={staroff} style={{ marginRight: "5px" }} />;
                })}
                {Array.from(Array(parseInt(player.playerId.attrAttack)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Defense</Typography>
              </Box>
              <Box>
                {Array.from(Array(3 - parseInt(player.playerId.attrDefense)), (e, i) => {
                  return <img src={staroff} style={{ marginRight: "5px" }} />;
                })}
                {Array.from(Array(parseInt(player.playerId.attrDefense)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Bio;
