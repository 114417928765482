const CreateSleague_mutate = `
    mutation createSleague(
      $title: String!,
      $seasonName: String!,
      $leagueId: Int!,
      $startDate: DateTime!,
      $endDate: DateTime!,
      $endDateFinal: DateTime!)
       {
      createSleague(
        title: $title,
        seasonName:$seasonName,
        leagueId:$leagueId,
        startDate: $startDate,
        endDate:$endDate,
        endDateFinal:$endDateFinal) {
          title
          seasonName
          startDate
          endDate
          endDateFinal
      }
    }
`;

const GetAllSLeagues_query = `
    query{
      sleague{
        sleagueId
        title
        seasonName
        spMax
        startDate
        endDate
        endDateFinal
        leagueId {
          title
        }
    }
  }
`;

const GetSLeague_query = `
    query($leagueId:Int!){
      sleague(leagueId:$leagueId){
        sleagueId
        title
        seasonName
        spMax
        startDate
        endDate
        endDateFinal
        leagueId {
          title
        }
    }
  }
`;

const GetAllSLeagueTeams_query = `
    query{
      allSleagueTeam{
        sleagueTeamId
        sleagueId {
          sleagueId
			    title
          seasonName
			    leagueId {
            leagueId
				    title
            subtitle
			    }
		    }
		      teamId {
			       name
             shortName
             logo
		     }
      }
    }
`;

const GetCurrentSLeagueTeams_query = `
    query{
      currentSleagueTeam{
        sleagueTeamId
        sleagueId {
          sleagueId
          title
          seasonName
          leagueId {
            leagueId
            title
            subtitle
          }
        }
          teamId {
             name
             shortName
             logo
         }
      }
    }
`;

const GetAllSLeagueTeamPlayers_query = `
    query{
        allSleagueTeamPlayer{
        sleagueTeamPlayerId
        sleagueTeamId{
          sleagueTeamId
          sleagueId{
            leagueId{
              title
            }
            sleagueId
            seasonName
          }
          teamId{
            teamId
            name
            shortName
            code
          }
        }
        playerId{
          playerId
          fName
          lName
          foot
          height
          weight
          birthDate
          headImage
          position
          attrPower
          attrSpeed
          attrDefense
          attrAttack
          attrStrategy
          attrTechnical
          createBy
          createDate
          updateBy
          updateDate
        }
        jerseyNO
        playerSeqNO
        status

        }
        }
`;

const GetSleagueTeamPlayer_query = `
  query($sleagueTeamId: Int!){
    sleagueTeamPlayer (sleagueTeamId: $sleagueTeamId){
        sleagueTeamPlayerId
        sleagueTeamId{
          sleagueId{
            sleagueId
            seasonName
          }
          teamId{
            teamId
            name
            shortName
            code
          }
        }
        playerId{
          playerId
          fName
          lName
    		  shortName
          onFieldName
    		  name
          foot
          height
          weight
          birthDate
          headImage
          position
          attrPower
          attrSpeed
          attrDefense
          attrAttack
          attrStrategy
          attrTechnical
          createBy
          createDate
          updateBy
          updateDate
        }
        jerseyNO
        playerSeqNO
        status

        }
        }
`;
module.exports = {
  GET_ALL_SLEAGUETEAMS_QUERY: GetAllSLeagueTeams_query,
  GET_CURRENT_SLEAGUETEAMS_QUERY: GetCurrentSLeagueTeams_query,
  GET_ALL_SLEAGUETEAM_PLAYERS_QUERY: GetAllSLeagueTeamPlayers_query,
  GET_SLEAGUETEAM_PLAYER_QUERY: GetSleagueTeamPlayer_query,
  CREATE_SLEAGUE_MUTATE: CreateSleague_mutate,
  GET_ALL_SLEAGUES_QUERY: GetAllSLeagues_query,
  GET_SLEAGUE_QUERY: GetSLeague_query,
};
