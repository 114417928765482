import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Paper } from "@mui/material";
import {
  Link,
  Typography,
  makeStyles,
  Card,
  CardContent,
  Container,
  Grid,
} from "@material-ui/core";

const footerData = [
  {
    label: "About Us",
    href: "/lms/about-us.html",
  },
  {
    label: "How Team Selector Works",
    href: "/lms/how-team-selector-works.html",
  },
  {
    label: "Privacy Policy",
    href: "/lms/privacy-policy.html",
  },
  {
    label: "Terms of Use",
    href: "/lms/terms-of-use.html",
  },
  {
    label: "Cookie Policy",
    href: "/lms/cookie-policy.html",
  },
];

const footerDataMobile = [
  {
    label: "About",
    href: "/lms/about-us.html",
  },
  {
    label: "Team Selector",
    href: "/lms/how-team-selector-works.html",
  },

  {
    label: "Privacy",
    href: "/lms/privacy-policy.html",
  },
  {
    label: "Terms",
    href: "/lms/terms-of-use.html",
  },
];
const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
	borderRadius: "0 !important",
  },
  cardContainer: {
    paddingBottom: 0,
	paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1610,
  },
  card: {
	borderRadius: "0 !important",
  },
  cardContent: {
    color: "#7F7D7D !important",
    display: "flex",
    fontSize: "14px",
    maxWidth: "1610px",
    alignItems: "center",
    fontFamily: "Roboto, Helvetica, sans-serif",
    paddingLeft: "142px",
	paddingBottom: "10px !important",
    justifyContent: "space-between",
    backgroundColor: "#000 !important",
  },
  copyStyle: {
    paddingRight: "95px",
	color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
  },
  footerTablet: {
    borderRadius: "0 !important",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, sans-serif",
    color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: "0px",
    paddingLeft: "50px",
  },
  footerMobile: {
    borderRadius: "0 !important",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, sans-serif",
    color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: "0px",
    paddingLeft: "10px",
  },
  cardContainerMobile: {
  color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
    paddingBottom: 0,
	paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 900,
  },
  cardContentMobile: {
    color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
    display: "flex",
    flexDirection: "column",
	marginLeft: 0,
	marginRight: 0
  },
  copyStyleMobile: {
    color: "#7F7D7D !important",
    backgroundColor: "#000 !important",
    paddingTop: "15px",
  },
}));

const getFooterItem = (device = "desktop") => {
  let data = footerData;
  if (device === "mobile") data = footerDataMobile;
  return data.map(({ label, href }, index) => {
    return (
      <Link
        {...{
          component: RouterLink,
          to: href,
          color: "inherit",
          style: {
            textDecoration: "none",
            fontSize: 14,
            paddingRight: index !== data.length - 1 ? 30 : 0,
          },
          key: label,
        }}
      >
        {label}
      </Link>
    );
  });
};
const StaticFooter = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1110 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      {mobileView && (
        <Grid container direction="row" alignItems="flex-start" className={classes.footerMobile}>
	    <Container className={classes.cardContainerMobile}>
		  <Card className={classes.card}>
            <CardContent className={classes.cardContentMobile}>
          <div>{getFooterItem("mobile")}</div>
          <div className={classes.copyStyleMobile}>
            <Typography variant="h6">
              Copyright 2022-2024 Predictal
              <br /> All rights reserved
            </Typography>
          </div>
        </CardContent>
		  </Card>
		</Container>
		</Grid>
      )}
      {tabletView && (
		<Grid container direction="row" alignItems="flex-start" className={classes.footerTablet}>
	    <Container className={classes.cardContainerMobile}>
		  <Card className={classes.card}>
            <CardContent className={classes.cardContentMobile}>
          <div>{getFooterItem()}</div>
          <div className={classes.copyStyleMobile}>
            <Typography variant="h6">
              Copyright 2022-2024 Predictal
              <br /> All rights reserved
            </Typography>
          </div>
			</CardContent>
		  </Card>
		</Container>
		</Grid>
      )}
      {!tabletView && !mobileView && (
		<Grid container direction="row" alignItems="flex-start" className={classes.footer}>
	    <Container className={classes.cardContainer}>
		  <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div> {getFooterItem()}</div>
              <div className={classes.copyStyle}>
                <Typography variant="h5">
                  Copyright 2022-2024 Predictal
                  <br /> All rights reserved
                </Typography>
              </div>
			</CardContent>
		  </Card>
		</Container>
		</Grid>
      )}
	</>
  );
};

export default StaticFooter;
