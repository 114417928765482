import { useState, useEffect, useContext, createContext } from "react";
import { Helmet } from "react-helmet";
import TeamSelector from "./TeamSelector";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import { GET_USERPROFILE_QUERY, REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";
import { GET_ALL_SETTINGS_QUERY, GET_SERVER_DATE_TIME } from "../../../graphQL/setting_qry";
import { GET_COACH_SEQ_LATEST_FIXTURE_QUERY } from "../../../graphQL/fixture_qry";

import { useNavigate, useParams } from "react-router-dom";

const PLAYER_SEQ = ["player seq", "player_seq", "player-seq"];
const PLAYER_SEQ_REGEXP = new RegExp(PLAYER_SEQ.join("|"), "i");

const USERTS_QUERY = `query userTSByFixtureteamid($fixtureTeamId:Int!){
  userTSByFixtureteamid(fixtureTeamId:$fixtureTeamId){
    userTSId
    userSeq
    updateDate
    awardSP
  }
}`;

const FIXTURETEAM_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    sleagueTeamId {
      sleagueTeamId
    }
    fixtureId{
      fixtureId
    }
    coachSeq
  }
}`;

const SLEAGUETEAM_QUERY = `query sleagueTeamById($sleagueTeamId:Int!){
  sleagueTeamById(sleagueTeamId:$sleagueTeamId){
    sleagueTeamId
    sleagueId{
      sleagueId
      title
      leagueId{
        leagueId
        title
      }
    }
    teamId{
      name
      shortName
      logo
    }
  }
}`;

export const TeamSelectorContext = createContext();

function UserTeamSelectorAppMain() {
  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [embargoTime, setEmbargoTime] = useState("");
  const [serverTimeInUTC, setServerTimeInUTC] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const [userSeqVal, setUserSeqVal] = useState("");
  const [coachSeqVal, setCoachSeqVal] = useState("");
  const [userTSId, setUserTSId] = useState(0);
  const [spAward, setSpAward] = useState(0);
  const [showSeqNo, setShowSeqNo] = useState(false);
  const [selectionDate, setSelectionDate] = useState("");
  const { fixtureTeamId } = useParams();
  const [pathname] = useState(window.location.pathname);

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: dataSettings,
  } = useQuery(GET_ALL_SETTINGS_QUERY, {
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingServerTime,
    error: errorServerTime,
    data: dataServerTime,
  } = useQuery(GET_SERVER_DATE_TIME, {
    fetchPolicy: "network-only",
  });
  const { loading: lastLoading, error: lastError, data: lastData } = useQuery(
    GET_COACH_SEQ_LATEST_FIXTURE_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );
  const [userTS] = useManualQuery(USERTS_QUERY);
  const [fixtureTeam] = useManualQuery(FIXTURETEAM_QUERY);
  const [sleagueTeam] = useManualQuery(SLEAGUETEAM_QUERY);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [data]);
  useEffect(() => {
    if (!loadingServerTime && dataServerTime.serverDateTime) {
      var local = new Date(dataServerTime.serverDateTime);
      var offset = local.getTimezoneOffset();
      var utc = new Date(local.getTime() - offset * 60000);
      setServerTimeInUTC(utc);
    }
    if (
      errorSettings &&
      errorSettings.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataServerTime, data]);

  useEffect(() => {
    if (!loadingSettings && dataSettings && dataSettings.allSettings) {
      const findSetting = dataSettings.allSettings.find(
        setting => setting.name === "Embargo Time (in hours)"
      );
      setEmbargoTime(findSetting);
      // Check showing player seq
      if (!loading && data && data.profile) {
        if (data.profile.isSuperuser) {
          dataSettings.allSettings.forEach(setting => {
            if (PLAYER_SEQ_REGEXP.test(setting.name)) {
              if (["True", "true", "TRUE", true, 1, "1"].includes(setting.value))
                setShowSeqNo(true);
            }
          });
        }
      }
    }
    if (
      errorSettings &&
      errorSettings.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSettings, data]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setSleagueTeamId(parseInt(data.fixtureTeamById.sleagueTeamId.sleagueTeamId));
      setFixtureId(parseInt(data.fixtureTeamById.fixtureId.fixtureId));
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await sleagueTeam({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });
    if (!loading && data && data.sleagueTeamById) {
      setUserFavTeam({
        teamInfo: data.sleagueTeamById.teamId,
        sleagueteam: data.sleagueTeamById,
      });
      setLeagueId(parseInt(data.sleagueTeamById.sleagueId.leagueId.leagueId));
    }
  }, [sleagueTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await userTS({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    if (!loading && !error && data && data.userTSByFixtureteamid) {
      setSpAward(parseInt(data.userTSByFixtureteamid.awardSP));
      setUserTSId(parseInt(data.userTSByFixtureteamid.userTSId));
      setUserSeqVal(data.userTSByFixtureteamid.userSeq);
      setSelectionDate(data.userTSByFixtureteamid.updateDate);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    } else if (error) {
    //  alert("Message ID: 101\n Something went wrong. Please contact support@predictal.com.");
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);

  useEffect(() => {

    if (!lastLoading && lastData && lastData.coachSeqLatestFixture) {
      setCoachSeqVal(lastData.coachSeqLatestFixture[0].coachSeq);
      }
      if (lastError && lastError.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/signin/?next=${pathname}`);
      } else if (lastError) {
        navigate(`/lms/signin/?next=${pathname}`);
      }
  }, [lastData]);

  // console.log("userData-----------",userData);
  // console.log("userFavTeam---------",userFavTeam);

  return (
    <>
    <Helmet>
      <title>Predictal | Team Selector | My starting line-up</title>      
    </Helmet>
    <TeamSelectorContext.Provider value={showSeqNo}>
      <div>
        {userFavTeam && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <TeamSelector
                userData={userData}
                userFavTeam={userFavTeam}
                fixtureTeamId={parseInt(fixtureTeamId)}
                sleagueTeamId={sleagueTeamId}
                fixtureId={fixtureId}
                leagueId={leagueId}
                userSeqVal={userSeqVal}
                coachSeqVal={coachSeqVal}
                userTSId={userTSId}
                spAward={spAward}
                embargoTime={embargoTime}
                serverTime={serverTimeInUTC}
                selectionDate={selectionDate}
              />
            </DndProvider>
        )}
      </div>
    </TeamSelectorContext.Provider>
    </>
  );
}

export default UserTeamSelectorAppMain;
