import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  // Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";

// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import { Paper } from "@mui/material";
// import Container from "@mui/material/Container";
// import KeyboardReturn from "@mui/icons-material/KeyboardReturn";
// import IconButton from "@mui/material/IconButton";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { Helmet } from "react-helmet";

import MyTeamInfo from "./mobilePanel/MyTeamInfo";
import TeamInfoCoach from "./mobilePanel/TeamInfoCoach";
import TeamInfoCom from "./mobilePanel/TeamInfoCom";

// import FavTeamCommunityVotesMobile from "../favTeamResults/votes/FavTeamCommunityVotesMobile.js";
import FavTeamCommunityVotesMobile from "../FavTeamResults/FavTeamResults.js";

// import classes from "./TeamSelector.module.scss";

import {
  useQuery,
  useMutation,
  ClientContext,
  useManualQuery,
} from "graphql-hooks";
import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../../graphQL/user_qry";

import {
  GET_FIXTURETEAM_BY_ID,
  GET_FIXTURE_OBJECT,
  GET_FIXTURE_OBJECT_TEAMS,
} from "../../graphQL/fixture_qry";
// import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../graphQL/sleague_qry";
import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../graphQL/sleague_team_query";

import footFieldMobile from "../../assets/positionImgs/football_field_mobile.png";

const mainPath = "/lms/media/";

const FIXTURETEAM_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    sleagueTeamId {
      sleagueTeamId
    }
    fixtureId{
      fixtureId
    }
    coachSeq
    communitySeq
  }
}`;
const SLEAGUETEAM_QUERY = `query sleagueTeamById($sleagueTeamId:Int!){
  sleagueTeamById(sleagueTeamId:$sleagueTeamId){
    sleagueTeamId
    sleagueId{
      sleagueId
      title
      leagueId{
        leagueId
        title
      }
    }
    teamId{
      name
      shortName
      logo
    }
  }
}`;
const USERTS_QUERY = `query userTSByFixtureteamid($fixtureTeamId:Int!){
  userTSByFixtureteamid(fixtureTeamId:$fixtureTeamId){
    userTSId
    userSeq
  }
}`;

const formatter = new Intl.DateTimeFormat("en-us", {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  fractionalSecondDigits: 3,
  hour12: true,
});

const dayFormat = (day) => {
  const dayDate = new Date(day);
  const dayFormat = formatter.formatToParts(dayDate);
  let format = "YYYYmonthDD";
  if (localStorage.getItem("preferedDateFormat")) {
    format = localStorage.getItem("preferedDateFormat");
  }
  if (format === "DDmonthYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "day").value +
      " " +
      dayFormat.find((a) => a.type == "month").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else if (format === "monthDDYYYY") {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  } else {
    const newFormat =
      dayFormat.find((a) => a.type == "weekday").value +
      ", " +
      dayFormat.find((a) => a.type == "year").value +
      ", " +
      dayFormat.find((a) => a.type == "month").value +
      " " +
      dayFormat.find((a) => a.type == "day").value +
      " - " +
      dayFormat.find((a) => a.type == "hour").value +
      ":" +
      dayFormat.find((a) => a.type == "minute").value +
      " " +
      dayFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  }
};

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 1366,
    // minHeight: 818,
  },
  cardContent: {
    // padding: theme.spacing(3),
    // paddingLeft: 76,
    // paddingTop: 23,
    // paddingRight: 76,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // position: "relative",
    // minHeight: 100,
    // borderRadius: 8,
  },

  cardContainerTablet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 768,

    // paddingLeft: 48,
    // paddingRight: 48,
    // maxWidth: 900,
    // height: 648,
  },

  cardContainerMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 18,
    paddingTop: 24,
    minWidth: 360,

    // paddingBottom: 38,
    // paddingTop: 38,
    // paddingLeft: 5,
    // paddingRight: 5,
  },

  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },

  overview: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "column-reverse",
    //   alignItems: "flex-start",
    // },
  },

  teamName: {
    // marginRight: theme.spacing(6),
    // marginLeft: theme.spacing(6),
    fontSize: 16,
    fontWeight: 400,
    color: "#212121",
  },

  teamImage: {
    // marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    height: 40,
    width: 40,
  },

  teamScore: {
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    fontSize: 20,
    fontWeight: 700,
    color: "#212121",
  },

  footImage: {
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    height: 408,
    width: 580,
  },

  teamSel: {
    paddingTop: 24,
    paddingBottom: 24,
  },

  formationStyle: {
    // textAlign: "left",
    paddingTop: 12,
    paddingBottom: 24,
    // paddingLeft: 221,
  },
}));

function FavTeamSelector() {
  const classes = useStyles();
  const [userData, setUserData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const [coachSeqVal, setCoachSeqVal] = useState("");
  const [comSeqVal, setComSeqVal] = useState("");
  const [userSeqVal, setUserSeqVal] = useState("");
  const [teamLeft, setTeamLeft] = useState("");
  const [teamRight, setTeamRight] = useState("");

  const [fixtureTeam1, setFixtureTeam1] = useState([]);
  const [fixtureTeam2, setFixtureTeam2] = useState([]);
  const [sleagueTeamId1, setSleagueTeamId1] = useState("");
  const [sleagueTeamId2, setSleagueTeamId2] = useState("");
  const [scoreFinalTeam1, setScoreFinalTeam1] = useState("");
  const [scoreFinalTeam2, setScoreFinalTeam2] = useState("");
  const [homeValue, setHomeValue] = useState("");
  const [pathname] = useState(window.location.pathname);

  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const { fixtureTeamId } = useParams();
  let location = useLocation();

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);
  const [fixtureTeam] = useManualQuery(FIXTURETEAM_QUERY);
  const [sleagueTeam] = useManualQuery(SLEAGUETEAM_QUERY);
  const [userTS] = useManualQuery(USERTS_QUERY);
  const [userTSId, setUserTSId] = useState(0);
  const [showTeam, setShowTeam] = useState("community");

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const [fetchFixtureTeams] = useManualQuery(GET_FIXTURE_OBJECT_TEAMS, {
    fetchPolicy: "network-only",
  });
  //
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });


  useEffect(() => {
    if (!playersDataLoading && playersData && playersData.sleagueTeamNowPastPlayer) {
      setPlayers(playersData.sleagueTeamNowPastPlayer);
    }
  }, [playersData]);

  useEffect(() => {
    if (location.pathname.includes("lms/ts/myteam")) setShowTeam("my");
    if (
      location.pathname.includes("lms/ts/3teams") &&
      location.hash.includes("#community")
    )
      setShowTeam("community");
    if (
      location.pathname.includes("lms/ts/3teams") &&
      location.hash.includes("#coach")
    )
      setShowTeam("coach");
    if (
      location.pathname.includes("lms/ts/3teams") &&
      location.hash.includes("#votes")
    )
      setShowTeam("votes");
  }, [location]);


  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [data]);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (
      errorSLTeam &&
      errorSLTeam.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSLTeam]);
  useEffect(async () => {
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setSleagueTeamId(
        parseInt(data.fixtureTeamById.sleagueTeamId.sleagueTeamId)
      );
      //////////////
      getFixtureTeams(data.fixtureTeamById.fixtureId.fixtureId);
      /////////////
      setFixtureId(parseInt(data.fixtureTeamById.fixtureId.fixtureId));
      setCoachSeqVal(data.fixtureTeamById.coachSeq);
      setComSeqVal(data.fixtureTeamById.communitySeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await userTS({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    console.log("Data----USER_TS--------", data);
    if (!loading && data && data.userTSByFixtureteamid) {
      setUserTSId(parseInt(data.userTSByFixtureteamid.userTSId));
      setUserSeqVal(data.userTSByFixtureteamid.userSeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await sleagueTeam({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });
    if (!loading && data && data.sleagueTeamById) {
      setUserFavTeam({
        teamInfo: data.sleagueTeamById.teamId,
        sleagueteam: data.sleagueTeamById,
      });
      setLeagueId(parseInt(data.sleagueTeamById.sleagueId.leagueId.leagueId));
    }
  }, [sleagueTeamId]);

  const getFixtureTeams = async (fixtureId) => {
    if (fixtureId != null) {
      const {
        loading: fixtureTeamsLoading,
        error: fixtureTeamsError,
        data: fixtureTeamsData,
      } = await fetchFixtureTeams({
        variables: {
          fixtureId: parseInt(fixtureId),
        },
      });

      if (
        !fixtureTeamsLoading &&
        fixtureTeamsData &&
        fixtureTeamsData.fixtureObjectTeams
      ) {
        setFixtureTeam1(fixtureTeamsData.fixtureObjectTeams[0]);
        setSleagueTeamId1(
          fixtureTeamsData.fixtureObjectTeams[0].sleagueTeamId.sleagueTeamId
        );

        if (fixtureTeamsData.fixtureObjectTeams[0].scoreFinal != null)
          setScoreFinalTeam1(fixtureTeamsData.fixtureObjectTeams[0].scoreFinal);

        setFixtureTeam2(fixtureTeamsData.fixtureObjectTeams[1]);
        setSleagueTeamId2(
          fixtureTeamsData.fixtureObjectTeams[1].sleagueTeamId.sleagueTeamId
        );

        if (fixtureTeamsData.fixtureObjectTeams[1].scoreFinal != null)
          setScoreFinalTeam2(fixtureTeamsData.fixtureObjectTeams[1].scoreFinal);

        if (
          fixtureTeamsData.fixtureObjectTeams[0] &&
          fixtureTeamsData.fixtureObjectTeams[0].home
        )
          setHomeValue("team1");
        else setHomeValue("team2");
      }
    }
  };

  const handleSelectedTeamsToView = (name1, name2) => {
    console.log("name1", name1, "name2", name2);
    if (name1 === "Community") setTeamLeft("Community");
    else setTeamLeft("My Team");

    if (name2 === "Coach") setTeamRight("Coach");
    else setTeamRight("Community");
  };

  let coach = "coach";
  let community = "community";

  const handleTabChange = (event, newValue) => {
    if (newValue === "my") setShowTeam("my");
    if (newValue === "community") setShowTeam("community");
    if (newValue === "coach") setShowTeam("coach");
    if (newValue === "votes") setShowTeam("votes");
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Community and Coach Teams</title>        
      </Helmet>
      <div>
        {fixtureTeam1 &&
          fixtureTeam2 &&
          fixtureTeam1.sleagueTeamId &&
          fixtureTeam2.sleagueTeamId && (
            <>
              <Container className={classes.cardContainerMobile} maxWidth="sm">
                <Grid container spacing={2}>
                  <Grid item lg={4} md={12} xl={3} xs={12}>
                    <Card>
                      <CardContent>
                        <Box
                          mt={0.5}
                          // mb={0.5}
                          // style={{
                          //   display: "flex",
                          // }}
                        >
                          <IconButton
                            edge="start"
                            color="primary"
                            onClick={() => {
                              navigate("/lms/fixtures");
                            }}
                            size="large"
                          >
                            <KeyboardReturn
                              sx={{ color: "#662483" }}
                              fontSize="inherit"
                            />
                          </IconButton>
                        </Box>

                        {homeValue === "team1" ? (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className={classes.teamImage}
                                src={
                                  mainPath +
                                  fixtureTeam1.sleagueTeamId.teamId.logo
                                }
                              />
                              <Box mt={1}>
                                <Typography className={classes.teamName}>
                                  {fixtureTeam1.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                alignItems: "center",
                              }}
                            >
                              <Typography className={classes.teamScore}>
                                {typeof scoreFinalTeam1 === "number"
                                  ? scoreFinalTeam1
                                  : ""}
                                &nbsp;{" - "}&nbsp;
                                {typeof scoreFinalTeam2 === "number"
                                  ? scoreFinalTeam2
                                  : ""}
                              </Typography>
                              <Box mb={4} />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className={classes.teamImage}
                                src={
                                  mainPath +
                                  fixtureTeam2.sleagueTeamId.teamId.logo
                                }
                              />
                              <Box mt={1}>
                                <Typography className={classes.teamName}>
                                  {fixtureTeam2.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className={classes.teamImage}
                                src={
                                  mainPath +
                                  fixtureTeam2.sleagueTeamId.teamId.logo
                                }
                              />
                              <Box mt={1}>
                                <Typography className={classes.teamName}>
                                  {fixtureTeam2.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                alignItems: "center",
                              }}
                            >
                              <Typography className={classes.teamScore}>
                                {typeof scoreFinalTeam2 === "number"
                                  ? scoreFinalTeam2
                                  : ""}
                                &nbsp;{" - "}&nbsp;
                                {typeof scoreFinalTeam1 === "number"
                                  ? scoreFinalTeam1
                                  : ""}
                              </Typography>
                              <Box mb={4} />
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "40%",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className={classes.teamImage}
                                src={
                                  mainPath +
                                  fixtureTeam1.sleagueTeamId.teamId.logo
                                }
                              />
                              <Box mt={1}>
                                <Typography className={classes.teamName}>
                                  {fixtureTeam1.sleagueTeamId.teamId.shortName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item lg={4} md={12} xl={3} xs={12}>
                    <Paper style={{ width: "100%" }}>
                      <Box
                        // mt={2}
                        // mb={2}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "center",
                          // position: "relative",
                        }}
                      >
                        <Box
                          mt={2}
                          // mb={2}
                        >
                          <Tabs
                            value={showTeam}
                            onChange={handleTabChange}
                            // textColor="#00000099"
                            indicatorColor="secondary"
                            // TabIndicatorProps={{
                            //   style: {
                            //     backgroundColor: "#765285",
                            //   },
                            // }}
                            variant="fullWidth"
                          >
                            <Tab
                              value="my"
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                paddingLeft:0,
                                paddingRight:0,
                                textTransform: "none",
                                color:
                                  showTeam === "my" ? "#6A1B9A" : "#00000099",
                              }}
                              label="My Team"
                            />
                            <Tab
                              value="community"
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: "none",
                                color:
                                  showTeam === "community"
                                    ? "#6A1B9A"
                                    : "#00000099",
                              }}
                              label="Community"
                            />
                            <Tab
                              value="coach"
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: "none",
                                paddingRight:0,
                                color:
                                  showTeam === "coach"
                                    ? "#6A1B9A"
                                    : "#00000099",
                              }}
                              label="Coach"
                            />
                            <Tab
                              value="votes"
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                paddingLeft:0,
                                textTransform: "none",
                                color:
                                  showTeam === "votes"
                                    ? "#6A1B9A"
                                    : "#00000099",
                              }}
                              label="Votes"
                            />
                          </Tabs>
                        </Box>
                        <Divider />
                        {showTeam === "votes" ? (
                          <FavTeamCommunityVotesMobile />
                        ) : (
                          <Grid
                            xs="auto"
                            align="center"
                            style={{ paddingTop: 20 }}
                          >
                            <Paper>
                              <DndProvider
                                backend={HTML5Backend}
                                debugMode={true}
                              >
                                {showTeam === "my" && (
                                  <MyTeamInfo
                                    userFavTeam={userFavTeam}
                                    fixtureTeamId={fixtureTeamId}
                                    sleagueTeamId={sleagueTeamId}
                                    fixtureId={fixtureId}
                                    leagueId={leagueId}
                                    userSeqVal={userSeqVal}
                                    userTSId={userTSId}
                                    sleaguePlayers={players}
                                  />
                                )}
                                {showTeam === "coach" && (
                                  <TeamInfoCoach
                                    userFavTeam={userFavTeam}
                                    fixtureTeamId={fixtureTeamId}
                                    sleagueTeamId={sleagueTeamId}
                                    fixtureId={fixtureId}
                                    leagueId={leagueId}
                                    coachSeqVal={coachSeqVal}
                                    sleaguePlayers={players}
                                  />
                                )}
                                {showTeam === "community" && (
                                  <TeamInfoCom
                                    userFavTeam={userFavTeam}
                                    fixtureTeamId={fixtureTeamId}
                                    sleagueTeamId={sleagueTeamId}
                                    fixtureId={fixtureId}
                                    leagueId={leagueId}
                                    comSeqVal={comSeqVal}
                                    sleaguePlayers={players}
                                  />
                                )}
                              </DndProvider>
                            </Paper>
                          </Grid>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
      </div>
    </>
  );
}

export default FavTeamSelector;
