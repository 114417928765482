

const CreateCurrentBucketSleague_mutate = `
    mutation createCurrentBucketSleague(
	         $sleagueId: Int!)
				{
					createCurrentBucketSleague( 
						sleagueId: $sleagueId)
						{
							currentBucketSleague
							{
							  currentBucketSleagueId
							  sleagueId
							  {
								sleagueId						
							  }
							  
							}
						}
				  }	
`;

const DeleteCurrentBucketSleague_mutate = `
    mutation deleteCurrentBucketSleague(
	         $sleagueId: Int!)  
		{
			deleteCurrentBucketSleague( 
				sleagueId: $sleagueId)
			 {
				ok
			  }
		}	
`;

const GetAllCurrentBucketSleagues_query = `
    
  query
	{
		currentBucketSleague
		{
			currentBucketSleagueId
			sleagueId
			{
				seasonName
				sleagueId
				leagueId
				{
					leagueId
					title
					subtitle
				}
			}
		  }
	}
`;

const GetAllSleaguesBucket_query = `
    
  query($leagueId:Int!)
	{
		sleagueBucket(leagueId:$leagueId)
		{
			sleagueId
			leagueId
			{
			  title
			}
			seasonName
			extraField
		}
	}
`;

const CurrentLeagueSeason_query = `query{
  currentBucketSleague{
    currentBucketSleagueId
    sleagueId{
      sleagueId
      leagueId
      {
      	leagueId
        title
        subtitle
      }
      seasonName
      title
    }
  }
}`;

module.exports = {
   
  GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY : GetAllCurrentBucketSleagues_query,
  GET_ALL_SLEAGUE_BUCKET_QUERY : GetAllSleaguesBucket_query,
  CREATE_CURRENT_BUCKET_SLEAGUE_MUTATION : CreateCurrentBucketSleague_mutate,
  DELETE_CURRENT_BUCKET_SLEAGUE_MUTATION : DeleteCurrentBucketSleague_mutate,
  CURRENT_LEAGUE_SEASONS_QUERY : CurrentLeagueSeason_query,
}
