import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "../Layout.js";
import { useNavigate } from "react-router-dom";
import { project_url, admin_url } from "../../store/data/Constants";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Material UI Imports
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, Grid, Typography, Box, makeStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SPDetailsDialog from "./SPDetailsDialog.js";
import AlertDialog from "../../components/AlertDialog.js";

//GraphQL Imports
import {
  DENY_USER_ACCESS_MUTATION,
  ALLOW_USER_ACCESS_MUTATION,
  GET_ALL_USERS_QUERY,
  ADMIN_ACTIVATE_USER_MUTATION,
} from "../../graphQL/register_qry";
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { FAV_TEAMS_FIXTURES_QUERY } from "../../graphQL/fixture_qry";
import { USERTS_BY_SELECTED_USERID_QUERY } from "../../graphQL/user_ts_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";
import { USERNOTS_BY_SELECTED_USERSLEAGUEID_QUERY } from "../../graphQL/user_sp_no_ts_qry";

///////////////////////////////
//Styles
import adminStyle from "../Admin.module.scss";
// import "../styles.css";
////////////////////////////////////////////////
//Define Constans

const SWITCH_USER_MUTATION = `mutation switchUser($email: String!) {
  switchUser(email: $email) {
    token
		refreshToken
    role
    email
    username
    autoLogin
    favTeam
    favTeamLogo
    preferedDateFormat
  }
}`;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  leftTeamNameStyle: {
    width: 80,
    textAlign: "right",
  },
  rightTeamNameStyle: {
    width: 80,
    textAlign: "left",
  },
  scoreNoStyle: {
    display: "flex",
    justifyContent: "center",
  },
  logoStyle: {
    height: 24,
    width: 24,
    marginTop: 5,
    marginRight: 12,
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#FBF3FF",
    },
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 14,
      fontWeight: 400,
    },
    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "90px",
    padding: 0,
    height: "25px",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 12,
  },
  dataGridFooterStyle: {
    height: 56,
    marginBottom: 0,
    backgroundColor: "#765285",
  },
  dataGridFooterTextStyle: {
    color: "#ffffff",
    fontWeight: 500,
    fontSize: 16,
    // marginLeft: 22,
    paddingTop: 13,
  },
  dataGridFooterDateStyle: {
    color: "#D2D2D2",
    fontWeight: 400,
    fontSize: 12,
    // marginLeft: 20,
    paddingTop: 15,
  },
}));

/////////////////////////////////////////////
export default function RegisterUsersList() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mediaPath = `/${project_url}/media/`;
  const classes = useStyles();

  const [userstat, setUserstat] = useState("active");
  const [value, setValue] = useState(0);
  const [userTsData, setUserTsData] = useState([]);
  const [totalSP, setTotalSP] = useState(0);
  const [totalNoSP, setTotalNoSP] = useState(0);
  const [rowData, setRowData] = useState({});
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [currentLeagueId, setCurrentLeagueId] = useState(1);
  const [currentSLeagueId, setCurrentSLeagueId] = useState(1);
  const [userTSNoData, setUserTSNoData] = useState([]);

  const date =
    new Date().toLocaleString("en-us", { weekday: "long" }) +
    " " +
    new Date().toLocaleString();

  //Define Constants //////////////////////
  const [denyUserAccessMutation] = useMutation(DENY_USER_ACCESS_MUTATION, {
    refetchQueries: [{ query: GET_ALL_USERS_QUERY }],
    awaitRefetchQueries: true,
  });
  const [allowUserAccessMutation] = useMutation(ALLOW_USER_ACCESS_MUTATION, {
    refetchQueries: [{ query: GET_ALL_USERS_QUERY }],
    awaitRefetchQueries: true,
  });
  const [activateMutation] = useMutation(ADMIN_ACTIVATE_USER_MUTATION, {
    refetchQueries: [{ query: GET_ALL_USERS_QUERY }],
    awaitRefetchQueries: true,
  });
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  let initialFormData = { id: null, username: "", isActive: null, email: "" };
  const [currentUser, setCurrentUser] = useState(initialFormData);

  const [userUId, setUserUId] = useState("");
  const [fixsList, setFixsList] = useState([]);
  const [open, setOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_ALL_USERS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [favTeamFixturesTeams] = useManualQuery(FAV_TEAMS_FIXTURES_QUERY);
  const [userTSQueryData] = useManualQuery(USERTS_BY_SELECTED_USERID_QUERY);

  const [userSPNoTSData] = useManualQuery(USERNOTS_BY_SELECTED_USERSLEAGUEID_QUERY);

  const {
    loading: loadingCurBucketSlg,
    error: errorCurBucketSlge,
    data: dataCurBucketSlg,
  } = useQuery(CURRENT_LEAGUE_SEASONS_QUERY);

  useEffect(() => {
    if (
      !loadingCurBucketSlg &&
      dataCurBucketSlg &&
      dataCurBucketSlg.currentBucketSleague
    ) {
      setCurrentLeagueId(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.leagueId.leagueId
      );

      setCurrentSLeagueId(dataCurBucketSlg.currentBucketSleague[0].sleagueId.sleagueId);
    }
  }, [dataCurBucketSlg]);

  useEffect(async () => {
    if (currentUser && currentUser.id) {
      const {
        loading: loadingUserTSData,
        error: errorUserTSData,
        data: dataUserTSData,
      } = await userTSQueryData({
        variables: {
          userId: parseInt(currentUser.id),
        },
      });

      if (!loadingUserTSData && dataUserTSData && dataUserTSData.userTSBySelectedUserid) {
        let totalPoints = 0;
        dataUserTSData.userTSBySelectedUserid.map(userTsRecord => {
          const awardSP = userTsRecord.awardSP;
          if (awardSP != null) {
            totalPoints = totalPoints + parseInt(awardSP);
          }
          setTotalSP(totalPoints);

          const awardDate = userTsRecord.awardDate;
        });
        setUserTsData(dataUserTSData.userTSBySelectedUserid);
      }

      if (currentSLeagueId) {
        const {
          loading: loadingUserNoTSData,
          error: errorUserNoTSData,
          data: dataUserNoTSData,
        } = await userSPNoTSData({
          variables: {
            sleagueId: parseInt(currentSLeagueId),
            userId: parseInt(currentUser.id),
          },
        });

        if (
          !loadingUserNoTSData &&
          dataUserNoTSData &&
          dataUserNoTSData.userSpNoTsBySelectedUserid
        ) {
          let spNoPoints = 0;
          let userSPNoTsList = [];

          dataUserNoTSData.userSpNoTsBySelectedUserid.map((userSpNoTsRecord, index) => {
            let date = new Date(userSpNoTsRecord.awardDate);

            const awardSP = userSpNoTsRecord.awardSP;
            if (awardSP != null) {
              spNoPoints = spNoPoints + parseInt(awardSP);
            }
            userSPNoTsList.push({
              award: userSpNoTsRecord.awardLabel,
              sp: "+" + userSpNoTsRecord.awardSP,
              awardType: "Registration",
              awardDate: extractDate(userSpNoTsRecord.awardDate),
            });
          });
          setTotalNoSP(spNoPoints);
          setUserTSNoData(userSPNoTsList);
        }
      }
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [currentUser]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const [switchUser] = useMutation(SWITCH_USER_MUTATION);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    // console.log("data:", refreshData, "error:", refreshError);
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  // Extracting only date from date & time value

  const extractDate = day => {
    const date = new Date(day);
    const onlyDate =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");

    return onlyDate;
  };

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });
  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (day == null) {
      return " ";
    }
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "day").value +
        " " +
        dayFormat.find(a => a.type == "month").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getActionButton = (user, rows) => {
    if (user.isactive) {
      return (
        <Button
          className={adminStyle.button}
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => handleClick(rows)}
        >
          {" "}
          Remove Access
        </Button>
      );
    } else if (user.profileField && user.profileField.activationDate == null) {
      return (
        <Button
          className={adminStyle.button}
          variant="contained"
          color="secondary"
          fullWidth={true}
          disabled={user.profileField == null}
          onClick={() => handleClick(rows)}
        >
          {" "}
          Activate{" "}
        </Button>
      );
    } else if (user.profileField && user.profileField.accessDeniedDate != null) {
      return (
        <Button
          className={adminStyle.button}
          variant="contained"
          color="secondary"
          fullWidth={true}
          disabled={user.profileField == null}
          onClick={() => handleClick(rows)}
        >
          {" GRANT ACCESS "}
        </Button>
      );
    } else {
      return (
        <Button
          className={adminStyle.button}
          variant="contained"
          color="secondary"
          fullWidth={true}
          disabled={true}
        >
          {" DELETED "}
        </Button>
      );
    }
  };

  const getDateFieldFromProfile = (user, field) => {
    if (user.profileField) {
      if (field == "activation") {
        if (user.profileField.activationDate) {
          return dayFormat(user.profileField.activationDate);
        }
        return " ";
      } else if (field == "denied") {
        if (user.profileField.accessDeniedDate) {
          return dayFormat(user.profileField.accessDeniedDate);
        }
        return " ";
      } else if (field == "granted") {
        if (user.profileField.accessAllowedDate) {
          return dayFormat(user.profileField.accessAllowedDate);
        }
        return " ";
      }
      return " ";
    }
    return " ";
  };

  const handleUserStatChange = (event: SelectChangeEvent) => {
    setUserstat(event.target.value);
    if (event.target.value === "active") {
      setSelectedUsers(data.allUsers.filter(user => user.isActive === true));
    } else if (event.target.value === "registered") {
      setSelectedUsers(
        data.allUsers.filter(
          user =>
            user.profileField &&
            user.isActive === false &&
            user.profileField.activationDate == null
        )
      );
    } else if (event.target.value === "denied") {
      setSelectedUsers(
        data.allUsers.filter(
          user =>
            user.profileField &&
            user.isActive === false &&
            user.profileField.accessDeniedDate != null &&
            user.profileField.activationDate != null
        )
      );
    } else if (event.target.value === "deleted") {
      setSelectedUsers(
        data.allUsers.filter(user => user.isActive === false && user.deleteDate != null)
      );
    }
  };

  useEffect(() => {
    if (!loading && data && data.allUsers) {
      setAllUsers(data.allUsers);
      setSelectedUsers(data.allUsers.filter(user => user.isActive === true));
      // console.log("All Users: ---- ", data);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [data]);

  useEffect(async () => {
    if (selectedUsers) {
      let selectedUser = selectedUsers.find(
        item => parseInt(item.id) === parseInt(userUId)
      );
      if (selectedUser) {
        setCurrentUser(selectedUser);

        let userFavTeamSeq = selectedUser.userFavTeamField.favTeamSeq;

        let { loading, error, data } = await favTeamFixturesTeams({
          variables: { userFavTeamSeq },
        });
        if (!loading && data && data.favTeamsFixturesObjectTeams) {
          let favTeamsFixtures = {};
          data.favTeamsFixturesObjectTeams.map(team => {
            let fixtureId = team.fixtureId.fixtureId.toString();
            if (fixtureId in favTeamsFixtures) {
              favTeamsFixtures[fixtureId].push(team);
            } else {
              favTeamsFixtures[fixtureId] = [team];
            }
          });

          let fixturesList = [];
          Object.values(favTeamsFixtures).map((fm, index) => {
            let favTeamSleagueId =
              selectedUser.userFavTeamField.sleagueTeamId.sleagueTeamId;
            // let fixtureName =
            //   fm[0].sleagueTeamId.sleagueTeamId === favTeamSleagueId
            //     ? fm[0].sleagueTeamId.teamId.name
            //     : fm[1].sleagueTeamId.teamId.name;
            let fixtureName =
              fm[0].fixtureId.fixtureWeek.weekId.name +
              ": " +
              fm[0].sleagueTeamId.teamId.name +
              " - " +
              fm[1].sleagueTeamId.teamId.name +
              " ( " +
              dayFormat(fm[0].fixtureId.fixtureWeek.scheduledDate) +
              " )";

            fixturesList.push(fixtureName);
          });
          setFixsList(fixturesList.sort());
        }
      }
    }
  }, [userUId]);

  /////////////////////////
  //Data Table
  const handleClick = async clickedUser => {
    // console.log("User is ", clickedUser.row);
    const user = clickedUser.row;
    if (user.isactive) {
      const { data, error } = await denyUserAccessMutation({
        variables: {
          userId: parseInt(user.id),
        },
      });
    } else if (user.profileField && !user.profileField.activationDate) {
      const { data, error } = await activateMutation({
        variables: {
          userId: parseInt(user.id),
        },
      });
    } else if (user.profileField && user.profileField.accessDeniedDate) {
      const { data, error } = await allowUserAccessMutation({
        variables: {
          userId: parseInt(user.id),
        },
      });
    }

    if (error) {
      console.log("Error", error, data);
      //  setFailedMessage("Unfortunately an error occurred.");
      //  setTimeout("");
    } else if (data) {
      window.location.reload();
    }
  };

  const handleSwitchUser = async selectedUser => {
    const { data, error } = await switchUser({
      variables: {
        email: selectedUser.email,
      },
    });
    if (error) {
      alert("Authentication credentials were not provided");
    } else {
      localStorage.setItem("token", data.switchUser.token);
      localStorage.setItem("refreshToken", data.switchUser.refreshToken);
      localStorage.setItem("role", data.switchUser.role);
      localStorage.setItem("email", data.switchUser.email);
      localStorage.setItem("username", data.switchUser.username);
      localStorage.setItem("autoLogin", false);
      localStorage.setItem("favTeam", data.switchUser.favTeam);
      localStorage.setItem("favTeamLogo", data.switchUser.favTeamLogo);
      localStorage.setItem("preferedDateFormat", data.switchUser.preferedDateFormat);
      client.setHeader("Authorization", `JWT ${data.switchUser.token}`);
      navigate("/lms/dashboard");
    }
  };

  const handleClose = () => {
    setUserUId("");
    setFixsList([]);
    setOpen(false);
    setValue(0);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      renderCell: rows => (
        <Link
          to={`/lms/${admin_url}/registeredUser/${rows.row.id}`}
          style={{
            marginRight: "15px",
          }}
          onClick={e => {
            e.preventDefault();
            setUserUId(rows.row.id);
            setOpen(true);
          }}
        >
          {rows.row.username}
        </Link>
      ),
      editable: false,
    },
    {
      field: "favteam",
      headerName: "Favourite Team",
      width: 150,
      editable: false,
    },
    {
      field: "first_name",
      headerName: "First name",
      width: 150,
      editable: false,
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 150,
      editable: false,
    },

    {
      field: "email",
      headerName: "Email Adress",
      width: 200,
      editable: false,
    },
    {
      field: "isadmin",
      headerName: "Is Admin?",
      type: "boolean",
      width: 150,
      editable: false,
    },
    {
      field: "isactive",
      headerName: "Is Active?",
      type: "boolean",
      width: 150,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: rows => <>{getActionButton(rows.row, rows)}</>,
    },
    {
      field: "date_registered",
      headerName: "Date Registered",
      width: 300,
      editable: false,
    },
    {
      field: "date_activated",
      headerName: "Date Activated",
      width: 300,
      editable: false,
    },
    {
      field: "date_denied",
      headerName: "Date Access Denied",
      width: 300,
      editable: false,
    },
    {
      field: "date_granted",
      headerName: "Date Access Granted",
      width: 300,
      editable: false,
    },
    {
      field: "date_deleted",
      headerName: "Date Deleted",
      width: 300,
      editable: false,
    },

    {
      field: "switch_user",
      headerName: "Toggle User",
      width: 160,
      renderCell: rows => {
        if (rows.row.isadmin === false) {
          return (
            <Button
              className={adminStyle.button}
              style={{ textTransform: "none" }}
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled={rows.row.isactive !== true}
              onClick={() => handleSwitchUser(rows.row)}
            >
              Log in as
            </Button>
          );
        }
      },
    },
  ];

  const tsColumns: GridColDef[] = [
    {
      field: "fixture",
      headerName: "Fixtures",
      width: 350,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: params => {
        if (params.row.team1 && params.row.team2) {
          return (
            <Box
              mt={1}
              mb={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "justifyContent",
              }}
            >
              <Box mt={1} mr={2}>
                <Typography variant="body2" className={classes.leftTeamNameStyle}>
                  {params.row.team1}
                </Typography>
              </Box>

              <img
                src={params.row.logo1 && params.row.logo1}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography variant="body2" className={classes.scoreNoStyle}>
                  {params.row.team1FinalScore} - {params.row.team2FinalScore}
                </Typography>
              </Box>

              <img
                src={params.row.logo2 && params.row.logo2}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography variant="body2" className={classes.rightTeamNameStyle}>
                  {params.row.team2}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
    },
    {
      field: "week",
      headerName: "Week",
      width: 90,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      width: 90,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  const spColumns: GridColDef[] = [
    {
      field: "award",
      headerName: "Award",
      minWidth: 320,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      textAlign: "center",
      resizable: false,
      renderCell: params => {
        if (params.row.award) {
          return (
            <Box ml={2}>
              <Typography variant="h5">{params.row.award}</Typography>
            </Box>
          );
        } else if (params.row.team1 && params.row.team2) {
          return (
            <Box
              mt={2}
              mb={2}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "justifyContent",
              }}
            >
              <Box mt={1} mr={2}>
                <Typography variant="h5" className={classes.leftTeamNameStyle}>
                  {params.row.team1}
                </Typography>
              </Box>

              <img
                src={params.row.logo1 && params.row.logo1}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography variant="h5" className={classes.scoreNoStyle}>
                  {params.row.team1FinalScore} - {params.row.team2FinalScore}
                </Typography>
              </Box>

              <img
                src={params.row.logo2 && params.row.logo2}
                className={classes.logoStyle}
              />

              <Box mt={1} mr={1}>
                <Typography variant="h5" className={classes.rightTeamNameStyle}>
                  {params.row.team2}
                </Typography>
              </Box>
            </Box>
          );
        }
      },
    },
    {
      field: "sp",
      headerName: "PSP",
      width: 70,
      maxWidth: 70,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "awardType",
      headerName: "Award Type",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "awardDate",
      headerName: "Award Date",
      width: 100,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "details",
      headerName: "Details",
      type: "actions",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: params => {
        if (!params.row.award) {
          return (
            <Button
              className={classes.outlinedButtonStyle}
              color="primary"
              variant="outlined"
              onClick={() =>
                params.row.calcSPUsersDate != undefined
                  ? showDetails(params.row)
                  : alertDialog(params.row)
              }
            >
              PSP Details
            </Button>
          );
        }
      },
    },
  ];

  const userFavTeamCell = user => {
    if (
      user.userFavTeamField &&
      user.userFavTeamField.sleagueTeamId &&
      user.userFavTeamField.sleagueTeamId.teamId &&
      user.userFavTeamField.sleagueTeamId.teamId.name
    ) {
      return user.userFavTeamField.sleagueTeamId.teamId.name;
    }
    return "--";
  };

  const rows = selectedUsers.map((user, id) => ({
    id: user.id,
    username: user.username,
    first_name: user.firstName,
    last_name: user.lastName,
    favteam: userFavTeamCell(user),
    email: user.email,
    isactive: user.isActive,
    isadmin: user.isSuperuser,
    profileField: user.profileField,
    date_registered: dayFormat(user.dateJoined),
    date_activated: getDateFieldFromProfile(user, "activation"),
    date_denied: getDateFieldFromProfile(user, "denied"),
    date_granted: getDateFieldFromProfile(user, "granted"),
    date_deleted: dayFormat(user.deleteDate),
  }));

  const tsRows = userTsData.map((tm, index) => {
    return {
      id: index,
      team1: tm.home.sleagueTeamId.teamId.shortName,
      logo1: mediaPath + tm.home.sleagueTeamId.teamId.logo,
      team1FinalScore: tm.home.scoreFinal !== null ? tm.home.scoreFinal : "()",
      team2: tm.guest.sleagueTeamId.teamId.shortName,
      logo2: mediaPath + tm.guest.sleagueTeamId.teamId.logo,
      team2FinalScore: tm.guest.scoreFinal !== null ? tm.guest.scoreFinal : "()",
      fixtureTeamId: tm.fixtureTeamId.fixtureTeamId,
      sleagueTeamId: tm.fixtureTeamId.sleagueTeamId.sleagueTeamId,
      week:
        tm.fixtureTeamId.fixtureId.fixtureWeek.playedWeek == true
          ? tm.fixtureTeamId.fixtureId.fixtureWeek.weekId.name
          : null,
      date: tm.fixtureTeamId.fixtureId.fixtureWeek.scheduledDate
        ? extractDate(tm.fixtureTeamId.fixtureId.fixtureWeek.scheduledDate)
        : null,
    };
  });

  const spLedgRows = userTsData.map((tm, index) => {
    return {
      id: index,
      team1: tm.home.sleagueTeamId.teamId.shortName,
      logo1: mediaPath + tm.home.sleagueTeamId.teamId.logo,
      team1FinalScore: tm.home.scoreFinal !== null ? tm.home.scoreFinal : "()",
      team2: tm.guest.sleagueTeamId.teamId.shortName,
      logo2: mediaPath + tm.guest.sleagueTeamId.teamId.logo,
      team2FinalScore: tm.guest.scoreFinal !== null ? tm.guest.scoreFinal : "()",
      sp: tm.awardSP !== null ? "+" + tm.awardSP : "Pending",
      awardType: "Team Selection",
      awardDate: tm.awardDate !== null ? extractDate(tm.awardDate) : "Pending",
      spSeq: tm.userSPSeq,
      coachSeq: tm.fixtureTeamId.coachSeq,
      userSeq: tm.userSeq,
      fixtureTeamId: tm.fixtureTeamId.fixtureTeamId,
      sleagueTeamId: tm.fixtureTeamId.sleagueTeamId.sleagueTeamId,
      calcSPUsersDate: tm.fixtureTeamId.calcSPUsersDate,
      week:
        tm.fixtureTeamId.fixtureId.fixtureWeek.playedWeek == true
          ? tm.fixtureTeamId.fixtureId.fixtureWeek.weekId.name
          : null,
    };
  });

  userTSNoData.map((tm, index) => {
    tm.id = parseInt(spLedgRows.length + index);
  });

  const spRows = spLedgRows.concat(userTSNoData);

  const showDetails = rowData => {
    console.log("row data:", rowData);
    setRowData(rowData);
    setOpenDetailDialog(true);
  };

  const closeDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const alertDialog = rowData => {
    console.log("row data:", rowData);
    setAlertMsg("PSP Calculation for this fixture is not ready yet!");
    setOpenAlertDialog(true);
  };

  const closeAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const customFooter = () => {
    return (
      <Box
        mb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
        className={classes.dataGridFooterStyle}
      >
        <Box mr={2} ml={3}>
          <Typography gutterBottom className={classes.dataGridFooterTextStyle}>
            Total PSP
          </Typography>
        </Box>
        <Box mt={0.5}>
          <Typography gutterBottom className={classes.dataGridFooterDateStyle}>
            (as of {date})
          </Typography>
        </Box>
        <Box ml={3}>
          <Typography gutterBottom className={classes.dataGridFooterTextStyle}>
            + {totalSP + totalNoSP}
          </Typography>
        </Box>
      </Box>
    );
  };

  ////////////////////////

  return (
    <>
      <Helmet>
        <title>Predictal | Users | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>Users List</h2>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="input-user-status">User Status</InputLabel>
                  <Select
                    labelId="label-user-status"
                    id="user-status"
                    value={userstat}
                    autoWidth
                    label="User Status"
                    onChange={handleUserStatChange}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"registered"}>Registered</MenuItem>
                    <MenuItem value={"denied"}>Access Denied</MenuItem>
                    <MenuItem value={"deleted"}>Deleted</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
        </Grid>
        {currentUser && fixsList && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "900px",
                  height: "100%",
                },
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ display: "flex", justifyContent: "space-between", color: "gray" }}
            >
              <Box mt={1} ml={1}></Box>

              <Box mt={1} ml={1}>
                User Details
              </Box>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 1,
                  top: 8,
                  align: "right",
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <label
                  className={adminStyle.label}
                  htmlFor="userInfo"
                  style={{ textAlign: "center" }}
                >
                  Username : <b>{currentUser.username}</b>
                </label>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#765285",
                    },
                  }}
                  style={{ paddingLeft: "5px", height: "48px" }}
                  variant="standard" //"fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Profile"
                    style={{
                      width: "20%",
                      fontSize: 14,
                      fontWeight: 400,
                      color: value === 0 ? "#765285" : "#4D4D4D",
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Fav Team"
                    style={{
                      width: "25%",
                      fontSize: 14,
                      fontWeight: 400,
                      color: value === 0 ? "#765285" : "#4D4D4D",
                    }}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Team Selections"
                    style={{
                      width: "30%",
                      fontSize: 14,
                      fontWeight: 400,
                      color: value === 1 ? "#765285" : "#4D4D4D",
                    }}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="PSP Ledger"
                    style={{
                      width: "25%",
                      fontSize: 14,
                      fontWeight: 400,
                      color: value === 1 ? "#765285" : "#4D4D4D",
                    }}
                    {...a11yProps(3)}
                  />
                </Tabs>
                <Grid>
                  {value === 0 &&
                    userUId &&
                    currentUser &&
                    currentUser.userFavTeamField && (
                      <div style={{ paddingTop: "15px" }}>
                        <label
                          className={adminStyle.userInfolabel}
                          htmlFor="userFaveTeam"
                        >
                          First Name:
                          <b> {currentUser.firstName}</b>
                        </label>

                        <label className={adminStyle.userInfolabel} htmlFor="userFavSeq">
                          Last Name:
                          <b> {currentUser.lastName}</b>
                        </label>

                        <label className={adminStyle.userInfolabel} htmlFor="userFavSeq">
                          Username:
                          <b> {currentUser.username}</b>
                        </label>

                        <label className={adminStyle.userInfolabel} htmlFor="userFavSeq">
                          Email Address:
                          <b> {currentUser.email}</b>
                        </label>

                        {currentUser.userFavTeamField && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFaveTeam"
                          >
                            Current Favourite Team:
                            <b>
                              {" "}
                              {currentUser.userFavTeamField.sleagueTeamId.teamId.name}
                            </b>
                          </label>
                        )}

                        {currentUser.profileField && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFaveTeam"
                          >
                            Idle Time:
                            <b> {currentUser.profileField.idleTime}</b>
                          </label>
                        )}

                        {currentUser.profileField && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFaveTeam"
                          >
                            Preferred Date Format:
                            <b> {currentUser.profileField.preferedDateFormat}</b>
                          </label>
                        )}

                        {currentUser.profileField && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFaveTeam"
                          >
                            Date Registered:
                            <b> {dayFormat(currentUser.dateJoined)}</b>
                          </label>
                        )}

                        {currentUser.profileField && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFaveTeam"
                          >
                            Date Activated:
                            <b> {dayFormat(currentUser.profileField.activationDate)}</b>
                          </label>
                        )}
                      </div>
                    )}
                  {value === 1 &&
                    userUId &&
                    currentUser &&
                    currentUser.userFavTeamField && (
                      <div style={{ paddingTop: "15px" }}>
                        <label
                          className={adminStyle.userInfolabel}
                          htmlFor="userFaveTeam"
                        >
                          Current Favourite Team:
                          <b> {currentUser.userFavTeamField.sleagueTeamId.teamId.name}</b>
                        </label>

                        <label className={adminStyle.userInfolabel} htmlFor="userFavSeq">
                          Favourite Team Seq:
                          <b> {currentUser.userFavTeamField.favTeamSeq}</b>
                        </label>

                        {fixsList && (
                          <label
                            className={adminStyle.userInfolabel}
                            htmlFor="userFavSeq"
                          >
                            Fixtures :
                            <b>
                              {fixsList.map(fixture => (
                                <p style={{ marginLeft: "40px" }}>
                                  {fixture} <br />
                                </p>
                              ))}
                            </b>
                          </label>
                        )}
                      </div>
                    )}
                  {value === 2 && (
                    <Box mt={2} className={classes.dataGridStyle}>
                      <DataGrid
                        rows={tsRows}
                        columns={tsColumns}
                        autoHeight={true}
                        getRowClassName={params => `style--${params.row.id % 2 == 0}`}
                      />
                    </Box>
                  )}
                  {value === 3 && (
                    <Box mt={2} className={classes.dataGridStyle}>
                      <DataGrid
                        autoHeight={true}
                        rows={spRows}
                        columns={spColumns}
                        getRowClassName={params => `style--${params.row.id % 2 == 0}`}
                        hideFooterSelectedRowCount
                        components={{
                          Footer: customFooter,
                        }}
                      />
                    </Box>
                  )}
                  {openDetailDialog && (
                    <SPDetailsDialog
                      leagueId={currentLeagueId}
                      fixtureData={rowData}
                      closeDialog={closeDetailDialog}
                    />
                  )}
                  {openAlertDialog && (
                    <AlertDialog
                      title="Alert"
                      closeDialog={closeAlertDialog}
                      contentMsg={alertMsg}
                    />
                  )}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={handleClose}>Done</Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
    </>
  );
}
