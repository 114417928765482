import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import {
  ADD_FORMATION_POSITION_MUTATION,
  ALL_FORMATION_POSITIONS_QUERY
} from "../graphQL/positionToFormationLeague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { ALL_FORMATIONS_QUERY } from "../graphQL/formation_qry";
import { GET_ALL_POSITIONS_QUERY } from "../graphQL/position_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const approvedPositions = [
  //#====================================================
  // "4-4-2" equivalent formations
  //#====================================================
  {
    formation: "4-4-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-1-3-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-3-1-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-1-2-1-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-2-2-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "1-3-4-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "3-1-4-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-4-1-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },

  //#====================================================
  // "4-2-4" equivalent formations
  //#====================================================

  {
    formation: "4-2-4",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: [] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: [] },
      { label: "lfor", positionSeqNo: ["08"] },
      { label: "cfor", positionSeqNo: ["09", "10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },

  //#====================================================
  // "4-5-1" equivalent formations
  //#====================================================

  {
    formation: "4-5-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-1-4-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-4-1-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-2-3-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-3-2-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "1-3-5-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },  
  {
    formation: "3-1-5-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "2-2-5-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07", "08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },

  //#====================================================
  // "5-3-2" equivalent formations
  //#====================================================

  {
    formation: "5-3-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "5-2-1-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "5-1-2-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "1-4-3-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "4-1-3-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },

  //#====================================================
  // "3-5-2" equivalent formations
  //#====================================================

  {
    formation: "3-5-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },

  {
    formation: "3-1-4-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "3-4-1-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "3-5-1-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07", "08"] },
      { label: "rmf", positionSeqNo: ["09"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["10", "11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },

  //#====================================================
  // "4-3-3" equivalent formations
  //#====================================================

  {
    formation: "4-3-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "4-2-1-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "4-1-2-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "3-1-3-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "1-3-3-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04"] },
      { label: "rdef", positionSeqNo: ["05"] },
      { label: "lmf", positionSeqNo: ["06"] },
      { label: "cmf", positionSeqNo: ["07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },

  //#====================================================
  // "3-4-3" equivalent formations
  //#====================================================

  {
    formation: "3-4-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "3-3-1-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "3-1-3-3",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "3-4-2-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },
  {
    formation: "3-4-1-2",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03"] },
      { label: "rdef", positionSeqNo: ["04"] },
      { label: "lmf", positionSeqNo: ["05"] },
      { label: "cmf", positionSeqNo: ["06", "07"] },
      { label: "rmf", positionSeqNo: ["08"] },
      { label: "lfor", positionSeqNo: ["09"] },
      { label: "cfor", positionSeqNo: ["10"] },
      { label: "rfor", positionSeqNo: ["11"] }
    ]
  },

  //#====================================================
  // "5-4-1" equivalent formations
  //#====================================================

  {
    formation: "5-4-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "3-2-4-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
  {
    formation: "2-3-4-1",
    positionInfo: [
      { label: "gk", positionSeqNo: ["01"] },
      { label: "ldef", positionSeqNo: ["02"] },
      { label: "cdef", positionSeqNo: ["03", "04", "05"] },
      { label: "rdef", positionSeqNo: ["06"] },
      { label: "lmf", positionSeqNo: ["07"] },
      { label: "cmf", positionSeqNo: ["08", "09"] },
      { label: "rmf", positionSeqNo: ["10"] },
      { label: "lfor", positionSeqNo: [] },
      { label: "cfor", positionSeqNo: ["11"] },
      { label: "rfor", positionSeqNo: [] }
    ]
  },
];

/*##########################################################################
## Function : Add a position to a formation
## Description : Submit form data and add position to a formation
## Author : SF
## Date: : 2022/02/23
##########################################################################*/
export default function PositionToFormation() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [
    allFormationPositions,
    setAllFormationPositions
  ] = useState([]);
  const [allFormation, setAllFormations] = useState([]);
  const [allPositions, setAllPositions] = useState([]);
  const [addFormationPosition] = useMutation(
    ADD_FORMATION_POSITION_MUTATION,
    {
      refetchQueries: [{ query: ALL_FORMATION_POSITIONS_QUERY }],
      awaitRefetchQueries: true
    }
  );
  const { loading, error, data } = useQuery(
    ALL_FORMATION_POSITIONS_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );
  const {
    loading: loadingFormations,
    error: errorFormations,
    data: formationsData
  } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const {
    loading: loadingPositions,
    error: errorPositions,
    data: positionData
  } = useQuery(GET_ALL_POSITIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.allFormationPositions) {
        setAllFormationPositions(data.allFormationPositions);
        console.log("AllFormationPositions----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (
        !loadingFormations &&
        formationsData &&
        formationsData.allFormations
      ) {
        setAllFormations(formationsData.allFormations);
      }
    },
    [formationsData]
  );

  useEffect(
    () => {
      if (!loadingPositions && positionData && positionData.position) {
        setAllPositions(positionData.position);
      }
    },
    [positionData]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 7000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    if (formData.formation === "0") {
      setFailedMessage("Please select one formation.");
    } else if (formData.position === "0") {
      setFailedMessage("Please select one position.");
    } else {
      let foundFormation = allFormation.find(
        item =>
          item.formationSeqNO.toLowerCase() ===
          formData.formation.toLowerCase()            
      );

      let foundPosition = allPositions.find(
        item => item.label.toLowerCase() === formData.position.toLowerCase()
      );

      let foundPosSeqNo = allFormationPositions.find(
        item => item.positionSeqNO === formData.positionSeqNO
      );

      // if (foundPosSeqNo){
      //   setFailedMessage(
      //     "This is a duplicate position Sequence No. Please enter another one."
      //   );
      // } else {

      // let approvedFormation = approvedPositions.find(
      //   item =>
      //   item.formation ===
      //   formData.leagueFormation.toLowerCase().split("(")[0].replace( /\s/g, '')
      // );

      // let allowedPosSeqNums = approvedFormation.positionInfo.find(
      //   item =>
      //   item.label === formData.position.toLowerCase()
      // ).positionSeqNo

      // if(allowedPosSeqNums.indexOf(formData.positionSeqNO) === -1) {
      //   setFailedMessage(
      //     "This Position Sequence NO is not valid. It should be " + allowedPosSeqNums.toString()
      //   );
      // }
      // else {

      const { data, error } = await addFormationPosition({
        variables: {
          formationId: parseInt(foundFormation.formationId),
          positionId: parseInt(foundPosition.positionId),
          positionSeqNO: formData.positionSeqNO
        }
      });
      if (error) {
        console.log("Error", error, data);
        setFailedMessage("Unfortunately an error occurred.");
        //  setTimeout("");
      } else if (data && data.addFormationPosition) {
        console.log("Success", data.addFormationPosition);
        var newLgFrmPos = { positionSeqNO: formData.positionSeqNO };
        data.addFormationPosition = {
          ...data.addFormationPosition,
          ...newLgFrmPos
        };
        allFormationPositions.push(data.addFormationPosition);
        setSuccessMessage(
          "New position " +
            formData.position +
            " with SeqNO:" +
            formData.positionSeqNO +
            " was added to this league formation successfully."
        );
        setTimeout(() => {
          navigate(`/lms/${admin_url}/formation-position`);
        }, 2000);
        //  setTimeout("");
      }
      // }
      // }
    }
  };

  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Formation Position | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>
            Add a "Position" to a "Formation"
          </h2>
          <label className={adminStyle.label} htmlFor="formation">
            <b>Formation Title</b>
          </label>
          <select
            className={adminStyle.select}
            id="formation"
            {...register("formation")}
          >
            <option value="0">Select a formation...</option>
            {allFormation.map((formation, i) => (
              <option
                key={i}
                value={
                  formation.formationSeqNO             
                }
              >
                {formation.title}
              </option>
            ))}
          </select>
          <label className={adminStyle.label} htmlFor="position">
            <b>Position</b>
          </label>
          <select
            className={adminStyle.select}
            id="position"
            {...register("position")}
          >
            <option value="0">Select a position...</option>
            {allPositions.map((position, i) => (
              <option key={i} value={position.label}>
                {position.label}
              </option>
            ))}
          </select>
          <label className={adminStyle.label} htmlFor="positionSeqNO">
            <b>Position Seq. NO.</b>
          </label>
          <input
            className={adminStyle.input}
            id="positionSeqNO"
            {...register("positionSeqNO", {
              required: true,
              pattern: /^(0[1-9]|1[01])$/
            })}
          />
          {errors.positionSeqNO && errors.positionSeqNO.type === "required" && (
            <span>Required</span>
          )}
          {errors.positionSeqNO && errors.positionSeqNO.type === "pattern" && (
            <span>Please enter a valid number( 01 - 11 )</span>
          )}
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
