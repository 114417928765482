import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { SLEAGUE_TEAM_BY_ID } from "../graphQL/sleague_team_query";
import { project_url, admin_url } from "../store/data/Constants";

import AlertDialog from "../components/AlertDialog.js";

const CURRENT_LEAGUE_SEASONS_QUERY = `query{
  currentBucketSleague{
    currentBucketSleagueId
    sleagueId{
      sleagueId
      leagueId
      {
        title
        subtitle
      }
      seasonName
      title
    }
  }
}`;

const SLEAGUETEAM_QUERY = `query sleagueTeam($sleagueId:Int!){
  sleagueTeam(sleagueId:$sleagueId){
    sleagueTeamId
    teamId{
      teamId
      name
      shortName
    }
  }
}`;

const PLAYERS_QUERY = `query{
  allPlayers {
    playerId
    fName
    lName
	  shortName
	  name
  }
}`;
const NOT_ASSIGNED_PLAYERS_QUERY = `query{
		sleagueTeamPlayersNotAssigned{
      playerId
      fName
      lName
    	shortName
    	name
    }
}`;

const ADD_SLEAGUETEAMPLAYER_MUTATION = `
mutation addSleagueTeamPlayer($sleagueTeamId: Int!, $playerId: Int!,
          $jerseyNO: Int!, $status: String!,
          $playerSeqNO: String!
) {
  addSleagueTeamPlayer(
    sleagueTeamId: $sleagueTeamId,
    playerId: $playerId,
    jerseyNO: $jerseyNO,
    status: $status,
    playerSeqNO: $playerSeqNO,

  ) {
    responseStr
  }
}`;

export default function SleagueTeamPlayer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [alertDlgMsg, setAlertDlgMsg] = useState("");
  const [closeDialogParFunc, setCloseDialogParFunc] = useState("");
  const [currSleagueTeamId, setCurrSleagueTeamId] = useState(0);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };
  // Set Current League Seasons value
  const [leagueSeasons, setLeagueSeasons] = useState([]);
  const leagueSeasonsQuery = useQuery(CURRENT_LEAGUE_SEASONS_QUERY);
  useEffect(() => {
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.currentBucketSleague) {
      setLeagueSeasons(leagueSeasonsQuery.data.currentBucketSleague);
    }
  }, [leagueSeasonsQuery.data]);

  //Player Seq List
  const [selectedPlayerSeqList, setSelectedPlayerSeqList] = useState([]);

  const [fetchSleagueTeamSeq] = useManualQuery(SLEAGUE_TEAM_BY_ID, {
    fetchPolicy: "network-only",
  });

  // Set Players value
  const [players, setPlayers] = useState([]);
  const playersQuery = useQuery(NOT_ASSIGNED_PLAYERS_QUERY);
  useEffect(() => {
    console.log("sleagueTeamPlayersNotAssigned___", playersQuery.data);
    if (playersQuery.data && playersQuery.data.sleagueTeamPlayersNotAssigned) {
      playersQuery.data.sleagueTeamPlayersNotAssigned.sort((a, b) =>
        a.fName.trim().toLowerCase() > b.fName.trim().toLowerCase() ? 1 : -1
      );
      setPlayers(playersQuery.data.sleagueTeamPlayersNotAssigned);
    }
  }, [selectedPlayerSeqList /*playersQuery*/]);

  // Set Teams value according to select League Season from its drop down
  const [sLeagueTeams, setSLeagueTeams] = useState([]);
  const [currSleagueId, setCurrSleagueId] = useState(-1);
  const {
    loading: teamsLoading,
    error: teamsError,
    data: currTeams,
  } = useQuery(SLEAGUETEAM_QUERY, {
    variables: {
      sleagueId: currSleagueId,
    },
  });
  useEffect(() => {
    if (currTeams && currTeams.sleagueTeam) {
      setSLeagueTeams(currTeams.sleagueTeam);
    }
  }, [currTeams]);

  // Add a Player to a Team
  const [addSleagueTeamPlayer, { loading, error }] = useMutation(
    ADD_SLEAGUETEAMPLAYER_MUTATION
  );

  const onSubmit = async formData => {
    setSubmitBtnDisable(true);
    if (
      formData &&
      (!formData.sleagueTeamId ||
        !formData.playerId ||
        !formData.jerseyNo ||
        !formData.status ||
        !formData.playerSequenceNo)
    ) {
      alert("There is an error, one or more fields are not filled correctly");
      return;
    }

    const { data, error } = await addSleagueTeamPlayer({
      variables: {
        sleagueTeamId: parseInt(formData.sleagueTeamId),
        playerId: parseInt(formData.playerId),
        jerseyNO: parseInt(formData.jerseyNo),
        status: formData.status,
        playerSeqNO: formData.playerSequenceNo,
      },
    });

    if (error) {
      console.log("Error", error, data);
      alert("There is an error");
      return;
    }

    const mutationRes = data.addSleagueTeamPlayer.responseStr.split("#");
    if (mutationRes[0] === "0") {
      setAlertDlgMsg(mutationRes[1]);
      setCloseDialogParFunc("0");
      return;
    } else if (mutationRes[0] === "1") {
      setAlertDlgMsg(mutationRes[1]);
      return;
    } else {
      let foundPlayer = players.find(
        item => parseInt(item.playerId) === parseInt(formData.playerId)
      );
      let selectedTeam = sLeagueTeams.find(
        item => parseInt(item.sleagueTeamId) === parseInt(formData.sleagueTeamId)
      );
      let selectedSLeague = leagueSeasons.find(
        item =>
          parseInt(item.sleagueId.sleagueId) === parseInt(formData.currentBucketSleagueId)
      );
      setAlertDlgMsg(
        "Player '" +
          foundPlayer.name +
          "' is successfully assigned to the " +
          "team '" +
          selectedTeam.teamId.name +
          "' in the league season '" +
          selectedSLeague.sleagueId.seasonName +
          "'."
      );
      setCloseDialogParFunc("2");
    }
  };

  const leagueSeasonHandleChange = e => {
    setCurrSleagueId(parseInt(e.target.value));
  };

  const teamHandleChange = async e => {
    const sleagueTeamId = parseInt(e.target.value);
    setCurrSleagueTeamId(sleagueTeamId);
    const {
      loading: sleagueTeamLoading,
      error: sleagueTeamError,
      data: sleagueTeamData,
    } = await fetchSleagueTeamSeq({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });

    if (!sleagueTeamLoading && sleagueTeamData && sleagueTeamData.sleagueTeamById) {
      setSelectedPlayerSeqList(sleagueTeamData.sleagueTeamById.playerSeqList);
    }
  };

  const closeDialogFunc = async () => {
    setAlertDlgMsg("");
    if (closeDialogParFunc === "2") navigate(`/lms/${admin_url}/sleague-team-player`);
    else if (closeDialogParFunc === "0") {
      setSubmitBtnDisable(false);
      const {
        loading: sleagueTeamLoading,
        error: sleagueTeamError,
        data: sleagueTeamData,
      } = await fetchSleagueTeamSeq({
        variables: {
          sleagueTeamId: currSleagueTeamId,
        },
      });

      if (!sleagueTeamLoading && sleagueTeamData && sleagueTeamData.sleagueTeamById) {
        setSelectedPlayerSeqList(sleagueTeamData.sleagueTeamById.playerSeqList);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Add Player to Team | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Assign a player to a team in a league season</h2>
          <select
            className={adminStyle.select}
            required={true}
            {...register("currentBucketSleagueId")}
            onChange={leagueSeasonHandleChange}
          >
            <option value="">Select a League Season ...</option>
            {leagueSeasons.map(({ currentBucketSleagueId, sleagueId }) => (
              <option key={currentBucketSleagueId} value={sleagueId.sleagueId}>
                {sleagueId.leagueId.subtitle} - Season {sleagueId.seasonName}
              </option>
            ))}
          </select>
          <select
            className={adminStyle.select}
            required={true}
            id="sleagueTeamId"
            {...register("sleagueTeamId")}
            onChange={teamHandleChange}
          >
            <option value="">Select a Team ...</option>
            {sLeagueTeams.map(({ sleagueTeamId, teamId }) => (
              <option key={sleagueTeamId} value={sleagueTeamId}>
                {teamId.name}
              </option>
            ))}
          </select>
          <select
            className={adminStyle.select}
            id="playerId"
            required={true}
            {...register("playerId")}
          >
            <option value="">Select a Player ...</option>
            {players.map(({ playerId, fName, lName, shortName }) => (
              <option key={playerId} value={playerId}>
                {fName} {lName} {shortName ? "(" + shortName + ")" : ""}
              </option>
            ))}
          </select>
          <select
            className={adminStyle.select}
            required={true}
            id="playerSequenceNo"
            {...register("playerSequenceNo")}
          >
            <option value="">Select a Player Sequance No. ...</option>
            {selectedPlayerSeqList.map(playerSeq => (
              <option key={playerSeq.seq} value={playerSeq.seq}>
                {playerSeq.seq}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="jerseyNo">
            Jersey No.
          </label>
          <input
            className={adminStyle.input}
            type="number"
            min="1"
            required={true}
            {...register("jerseyNo", {
              //  required: true,
              // pattern: /^\d+$/i,
            })}
          />

          <label className={adminStyle.label} htmlFor="status">
            Status
          </label>
          <select className={adminStyle.select} id="status" {...register("status")}>
            <option value="Fit">Fit</option>
            <option value="Injured">Injured</option>
            <option value="Booked">Booked</option>
            <option value="Suspended">Suspended</option>
            <option value="Sick">Sick</option>
            <option value="Unavailable">Unavailable</option>
          </select>
          {errors.status && errors.status.type === "required" && <span>Required</span>}

          <input className={adminStyle.input} type="submit" disabled={submitBtnDisable} />
        </form>
      </Grid>
      {alertDlgMsg && (
        <AlertDialog
          title="Alert"
          closeDialog={closeDialogFunc}
          contentMsg={alertDlgMsg}
        />
      )}
    </>
  );
}
