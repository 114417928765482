import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { ALL_FORMATIONS_QUERY } from "../graphQL/formation_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function FormationList() {
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [formationData, setFormationData] = useState([]);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.allFormations) {
        setFormationData(data.allFormations);
        console.log("allData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, formationData]
  );

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      editable: false
    },
    {
      field: "primaryFormation",
      headerName: "Primary Formation",
      width: 200,
      editable: false
    },
    {
      field: "formationSeqNO",
      headerName: "Sequence No.",
      width: 190,
      editable: false
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      editable: false
    }
  ];

  const rows = formationData.map((formation, id) => ({
    id: formation.formationId,
    title: formation.title,
    primaryFormation: formation.formationPrimary,
    formationSeqNO: formation.formationSeqNO,
    description: formation.description
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Formations | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Formations</h2>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/formation/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
