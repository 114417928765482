import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "graphql-hooks";
import { seo_tag_config } from "../../store/data/Constants";

import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import logoImg from "../../assets/preditcal_logo.png";

import { RESET_PASSWORD_MUTATION } from "../../graphQL/user_qry";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 510,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 488,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    width: 430,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 440,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 440,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
    //  maxWidth: 700,
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const ResetPass = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({mode: "all"});

  const navigate = useNavigate();
  const form = useRef(null);

  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const { forgetCode } = useParams();

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  setTimeout(() => {
    setSuccessMsg("");
    setErrorMsg("");
  }, 30000);
 
  /*##########################################################################
  ## Const : onSubmit
  ## Description : Complete the sumbit functionality of the data form 
  ## Author : SF
  ## Date: : 2023/03/13
  ##########################################################################*/

  const onSubmit = async (formData) => {
    await sleep(2000);
    
    if (formData.password === formData.retypePassword) {
      const { data, error } = await resetPassword({
        variables: {
          password: formData.password,
          forgetCode: forgetCode,
        },
      });
      if (error) {
        console.log("Error", error, data);
        setErrorMsg(error.graphQLErrors[0].message);
        setTimeout("");
      } else {
        console.log("Success");
        setSuccessMsg("Your new password is set.");
        setTimeout("");
        navigate("/lms/reset_password_confirmation");
      }
    } else {
      setErrorMsg("Password and re-enter password must be the same.");
      setTimeout("");
    }
  };

  /*##########################################################################
  ## Const : passwordChange
  ## Description : The Change Password functionality  
  ## Author : SF
  ## Date: : 2023/03/13
  ##########################################################################*/

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  /*##########################################################################
  ## Const : retypePasswordChange
  ## Description : The Retype Change Password functionality  
  ## Author : SF
  ## Date: : 2023/03/13
  ##########################################################################*/

  const retypePasswordChange = (event) => {
    setRetypePassword(event.target.value);
  };

  /*##########################################################################
  ## Render Section
  ##########################################################################*/

  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Reset Password</title>
          <meta 
            name="description" 
            content="Predcital reset password to football team prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, reset password, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }
      
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        {mobileView && (
          <Card className={classes.cardMobile}>
            <CardContent className={classes.cardContentMobile}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={1}
                >
                  <div>
                    <Typography gutterBottom variant="subtitle2">
                      Reset password
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box flexGrow={1} mb={3}>
                <Typography variant="body2">
                  Please enter new password. It should be 6-12 alpha-numeric
                  characters. No blank space and no special characters.
                </Typography>
                <TextField
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  required
                  //autoFocus
                  label="Enter New Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  id="password"
                  type="password"
                  {...register("password", { required: true })}
                  onChange={passwordChange}
                />
                {errors.password && errors.password.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Password cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                <TextField
                  className={classes.textFieldStyleMobile}
                  required
                  fullWidth
                  //autoFocus
                  label="Re-enter New Password"
                  margin="normal"
                  name="retypePassword"
                  type="Password"
                  variant="outlined"
                  id="retypePassword"
                  type="password"
                  {...register("retypePassword", { required: true })}
                  onChange={retypePasswordChange}
                />
                {errors.retypePassword &&
                  errors.retypePassword.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Re-enter Password cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      {errorMsg}
                    </FormHelperText>
                  </Box>
                )}
                {successMsg && (
                  <Box mt={2} className={classes.successStyleMobile}>
                    {successMsg}
                  </Box>
                )}
                <Box
                  mt={successMsg ? 2 : 5}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
        {tabletView && (
          <Container className={classes.cardContainerTablet} maxWidth="sm">
            <Card className={classes.cardTablet}>
              <CardContent className={classes.cardContentTablet}>
                <Box alignItems="center" mb={1}>
                  <Box
                    flexGrow={1}
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <div>
                      <Typography gutterBottom variant="subtitle1">
                        Reset password
                      </Typography>
                    </div>
                  </Box>
                </Box>
                <Box flexGrow={1} mb={3}>
                  <Typography variant="body1">
                    Please enter new password. It should be 6-12 alpha-numeric
                    characters. No blank space and no special characters.
                  </Typography>
                  <TextField
                    className={classes.textFieldStyle}
                    fullWidth
                    required
                    //autoFocus
                    label="Enter New Password"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    id="password"
                    type="password"
                    {...register("password", { required: true })}
                    onChange={passwordChange}
                  />
                  {errors.password && 
                    errors.password.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    className={classes.textFieldStyle}
                    required
                    fullWidth
                    //autoFocus
                    label="Re-enter New Password"
                    margin="normal"
                    name="retypePassword"
                    type="Password"
                    variant="outlined"
                    id="retypePassword"
                    type="password"
                    {...register("retypePassword", { required: true })}
                    onChange={retypePasswordChange}
                  />
                  {errors.retypePassword &&
                    errors.retypePassword.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Re-enter Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errorMsg && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        {errorMsg}
                      </FormHelperText>
                    </Box>
                  )}
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box
                    mt={successMsg ? 2 : 5}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>
        )}
        {!mobileView && !tabletView && (
          <Container className={classes.cardContainer} maxWidth="sm">
            <Card style={{ display: "flex", flexDirection: "row" }}>
              <CardContent className={classes.cardContent}>
                <Box alignItems="center" mb={3} flexGrow={1}>
                  <Box mb={2}>
                    <img src={logoImg} alt="logo" />
                  </Box>
                  <Typography variant="body1">
                    Predictal is a premier global destination that connects sports
                    fans to share and challenge their knowledge about sports in a
                    safe and collaborative environment. At its inaugural launch,
                    Predictal is home to the leading digital experience platform
                    for Team Selector in the sport of football.
                  </Typography>
                </Box>
              </CardContent>
              <Divider orientation="vertical" flexItem />
              <CardContent className={classes.cardContent}>
                <Box
                  //  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography gutterBottom variant="subtitle1">
                    Reset password
                  </Typography>
                </Box>

                <Box flexGrow={1}>
                  <Typography variant="body1">
                    Please enter new password. It should be <br /> 6-12
                    alpha-numeric characters. No blank space and no special
                    characters.
                  </Typography>
                  <TextField
                    className={classes.textFieldStyle}
                    fullWidth
                    required
                    //autoFocus
                    label="Enter New Password"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    id="password"
                    type="password"
                    {...register("password", { required: true })}
                    onChange={passwordChange}
                  />
                  {errors.password && 
                    errors.password.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    className={classes.textFieldStyle}
                    fullWidth
                    required
                    //autoFocus
                    label="Re-enter New Password"
                    margin="normal"
                    name="retypePassword"
                    type="Password"
                    variant="outlined"
                    id="retypePassword"
                    type="password"
                    {...register("retypePassword", { required: true })}
                    onChange={retypePasswordChange}
                  />
                  {errors.retypePassword &&
                    errors.retypePassword.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Re-enter Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errorMsg && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        {errorMsg}
                      </FormHelperText>
                    </Box>
                  )}
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box
                    mt={successMsg ? 2 : 5}
                    mb={4}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>
        )}
      </form>
    </>
  );
};

export default ResetPass;
