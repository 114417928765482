import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import { project_url, admin_url } from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import {
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import {
  GET_WEEK_FIXTURE_TEAMS,
  GET_SLEAGUE_FIXTURE_TEAMS,
} from "../graphQL/fixture_qry";
import { GET_SLEAGUE_WEEK_QUERY } from "../graphQL/week_qry";
import {CURRENT_LEAGUE_SEASONS_QUERY} from "../graphQL/current_bucket_sleague_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../graphQL/current_bucket_week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "./Admin.module.scss";
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

import AlertDialog from "../components/AlertDialog.js";
const CALC_FAVTEAM_USERS_SP = `mutation calcFavteamUsersSp($sleagueTeamId: Int!, $fixtureId: Int!, $sleagueId:Int!){
  calcFavteamUsersSp(sleagueTeamId: $sleagueTeamId, fixtureId: $fixtureId, sleagueId: $sleagueId){
    response
  }
}`;

///#############

export default function SPCalculator() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [calcFavTeamUserSP] = useMutation(CALC_FAVTEAM_USERS_SP);
  const [calSPMsg, setCalSPMsg] = useState("");
  const [calSPDlgOpen, setCalSPDlgOpen] = useState(false);
  const [sleagueTeamId, setSleagueTeamId] = useState(0);

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [teamsAll, setTeamsAll] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);
  const [selectedSleague, setSelectedSleague] = useState("");
  const [weeksData, setWeeksData] = useState([]);
  const [weekNote, setWeekNote] = useState("");
  const [fixtureId, setFixtureId] = useState([]);
  const [fixtureTeamId, setFixtureTeamId] = useState("");
  const [currWeekId, setCurrWeekId] = useState(0);
  const [currWeekData, setCurrWeekData] = useState({});
  const [currentLeagueId, setCurrentLeagueId] = useState("");

  const [teamName, setTeamName] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });

  const {
    loading: curLeagueLoading,
    error: curLeagueError,
    data: curLeagueData,
  } = useQuery(CURRENT_LEAGUE_SEASONS_QUERY, { fetchPolicy: "network-only" });

  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchWeeks] = useManualQuery(GET_SLEAGUE_WEEK_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchTeams] = useManualQuery(GET_WEEK_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });
  const [fetchTeamsBySleague] = useManualQuery(GET_SLEAGUE_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [fetchCurrentSleagueWeek] =
    useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  /*const [generateCommunitySelection] = useMutation(GENERATE_COMMUNITY_SELECTION, {
    awaitRefetchQueries: true
  });*/
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  //useEffect(() => {
  //   onGenerateCommunitySelection();
  // }, [fixtureId,fixtureTeamId])

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  /*const onGenerateCommunitySelection = async req =>{
    setOpen(false);
    if (fixtureId !== "" && fixtureTeamId !== ""){
      const { data: communityData, error: communityError } = await generateCommunitySelection({
        variables: {
          fixtureId: parseInt(fixtureId),
          fixtureTeamId: parseInt(fixtureTeamId)
        },
      });
      if (communityError) {
        if (
          communityError.graphQLErrors[0].message ===
          "You do not have permission to perform this action"
        )
          navigate(`/lms/${admin_url}/signin`);
        if (communityError.graphQLErrors[0].message === "Signature has expired")
          navigate(`/lms/${admin_url}/signin`);
      }
      if (communityData && communityData.generateCommunitySelection && communityData.generateCommunitySelection.maxFormation !== 0) {
        alert("Popular formation is " + communityData.generateCommunitySelection.formationTitle + " with " + communityData.generateCommunitySelection.maxFormation + " votes. \nPopular players are " + communityData.generateCommunitySelection.players + " with " + communityData.generateCommunitySelection.votes + " votes.");
      }
	  else {
	    alert("There is no community votes for this fixture and team.");
	  }
	}
  };*/

  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (leagueError && leagueError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);

  useEffect(async() => {
    if (
      !curLeagueLoading && curLeagueData &&
      curLeagueData.currentBucketSleague
    ) {
      setCurrentLeagueId(
      curLeagueData.currentBucketSleague[0].sleagueId.leagueId.leagueId);

      const {
        loading: sleagueLoading,
        error: sleagueError,
        data: sleagueData,
      } = await fetchSleagues({
        variables: {
          leagueId:
          parseInt(curLeagueData.currentBucketSleague[0].sleagueId.leagueId.leagueId),
        },
      });

      if (!sleagueLoading && sleagueData && sleagueData.sleague) {
        setSleaguesData(sleagueData.sleague);
        setSelectedSleague(
        curLeagueData.currentBucketSleague[0].sleagueId.sleagueId);

        const {
          loading: currentSleagueWeekLoading,
          error: currentSleagueWeekErrors,
          data: currentSleagueWeekData,
        } = await fetchCurrentSleagueWeek({
          variables: { sleagueId:
            parseInt(curLeagueData.currentBucketSleague[0].sleagueId.sleagueId)},
        });
        if (!currentSleagueWeekLoading &&
        currentSleagueWeekData && currentSleagueWeekData.currentSleagueWeek) {
          setWeeksData(currentSleagueWeekData.currentSleagueWeek);

          let selectedWeek = currentSleagueWeekData.currentSleagueWeek.find(
            item => item.extraField === true
          )
          if (selectedWeek){
            setCurrWeekId(selectedWeek.weekId);
            let weekData = JSON.stringify({
              "weekId":  selectedWeek.weekId,
              "name": selectedWeek.name})
            setCurrWeekData(weekData);
            handleWeekChange(weekData);
          }
          else{
            alert("Please select a week in the 'Current League Season Weeks Bucket' menu first!");
          }
        }
        if (currentSleagueWeekErrors &&
          currentSleagueWeekErrors.graphQLErrors[0].message === "Signature has expired") {
          navigate(`/lms/${admin_url}/signin`);
        };
      };
    };
  }, [curLeagueData]);

  const handleLeagueChange = async e => {
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData,
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid),
      },
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSLeagueChange = async e => {
    const tid = e.target.value;
    setSelectedSleague(parseInt(tid));
    const {
      loading: weekLoading,
      error: weekError,
      data: weekData,
    } = await fetchWeeks({
      variables: {
        sleagueId: parseInt(tid),
      },
    });

    if (!weekLoading && weekData && weekData.sleagueWeek) {
      setWeeksData(weekData.sleagueWeek);
    }
    if (weekError && weekError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleWeekChange = async tid => {

    setCurrWeekId(parseInt(JSON.parse(tid).weekId));
    setCurrWeekData(tid);

    if (tid.weekId !== "0") {
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeams({
        variables: {
          weekId: parseInt(JSON.parse(tid).weekId),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.weekFixtureTeams) {
        // console.log(allTeamsData);
        setTeamsAll([]);
        let teams = allTeamsData.weekFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });
        let weekN = "Fixtures Listed are for " + JSON.parse(tid).name;
        setWeekNote(weekN);
        setTeamsAll(ars);
      }
      if (teamError) {
        let ars = [];
        setTeamsAll(ars);
      }
    } else {
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeamsBySleague({
        variables: {
          sleagueId: parseInt(selectedSleague),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.sleagueFixtureTeams) {
        // console.log(allTeamsData);
        setTeamsAll([]);
        let teams = allTeamsData.sleagueFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });
        let weekN = "Fixtures Listed are for week ";
        setWeekNote(weekN);
        setTeamsAll(ars);
      }
    }
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const dayFormat = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };

  const coachSelectionHandleChange = e => {
    let fixtureTeamId = parseInt(e.target.value);
    navigate(`/lms/${admin_url}/ts/coachteam/${fixtureTeamId}`);
  };

  const columns = [
    {
      field: "fixture",
      headerName: "Fixture",

      width: 250,
      editable: false,
      renderCell: teams => {
        if (teams.value[0].home)
          return (
            <a
              href={`/lms/${admin_url}/fixture/edit/${teams.value[0].fixtureId.fixtureId}`}
              target="_self"
            >
              {" "}
              {teams.value[0].sleagueTeamId.teamId.name} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}) - {"  "}
              {teams.value[1].sleagueTeamId.teamId.name} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}){" "}
            </a>
          );
        else
          return (
            <a
              href={`/lms/${admin_url}/fixture/edit/${teams.value[0].fixtureId.fixtureId}`}
              target="_self"
            >
              {" "}
              {teams.value[1].sleagueTeamId.teamId.name} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}) - {"  "}
              {teams.value[0].sleagueTeamId.teamId.name} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}){" "}
            </a>
          );
      },
    },

    {
      field: "fixtureDate",
      headerName: "Fixture Date",

      width: 150,
      editable: false,
      renderCell: team => {
        return (
          <>
            {team.value.fixtureId.fixtureWeek.playedWeek == true
              ? dayFormat(team.value.fixtureId.fixtureWeek.scheduledDate)
              : "Delayed"}
          </>
        );
      },
    },

    {
      field: "team1",
      headerName: "Home",

      width: 150,
      editable: false,
      renderCell: team => {
        return <>{team.value.sleagueTeamId.teamId.name}</>;
      },
    },

    {
      field: "spTeam1",
      headerName: "  ",

      width: 200,
      editable: false,
      renderCell: team => {
        console.log();
        return (
          <>
            {
              <Button
                className={adminStyle.button}
                style={{
                  width: "200px",
                  textAlign: "right",
                  marginLeft: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={team.value.calcSPUsersDate}
                onClick={() => {
                  setFixtureId(team.value.fixtureId.fixtureId);
                  setFixtureTeamId(team.value.fixtureTeamId);
                  setTeamName(team.value.sleagueTeamId.teamId.name);
                  setSleagueTeamId(team.value.sleagueTeamId.sleagueTeamId);
                  handleClickOpen();
                }}
              >
                {team.value.calcSPUsersDate
                  ? dayFormat(team.value.calcSPUsersDate).split("-")[0]
                  : "Calculate PSP"}
              </Button>
            }
          </>
        );
      },
    },

    {
      field: "team2",
      headerName: "Away",

      width: 150,
      editable: false,
      renderCell: team => {
        return <>{team.value.sleagueTeamId.teamId.name}</>;
      },
    },

    {
      field: "spTeam2",
      headerName: "  ",

      width: 200,
      editable: false,
      renderCell: team => {
        return (
          <>
            {
              <Button
                className={adminStyle.button}
                style={{
                  width: "200px",
                  textAlign: "right",
                  marginLeft: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={team.value.calcSPUsersDate}
                onClick={() => {
                  setFixtureId(team.value.fixtureId.fixtureId);
                  setFixtureTeamId(team.value.fixtureTeamId);
                  setTeamName(team.value.sleagueTeamId.teamId.name);
                  setSleagueTeamId(team.value.sleagueTeamId.sleagueTeamId);
                  handleClickOpen();
                }}
              >
                {team.value.calcSPUsersDate
                  ? dayFormat(team.value.calcSPUsersDate).split("-")[0]
                  : "Calculate PSP"}
              </Button>
            }
          </>
        );
      },
    },
  ];

  var filteredTeamsAll = teamsAll.filter(item => {
    return item !== null;
  });

  const rows = filteredTeamsAll.map(tm => ({
    id: tm[0].fixtureId.fixtureId,
    fixture: tm,
    fixtureDate: tm[0],
    team1: tm[0],
    spTeam1: tm[0],
    team2: tm[1],
    spTeam2: tm[1],
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateSP = async e => {
    setOpen(false);
    setCalSPDlgOpen(true);
    let { data, error } = await calcFavTeamUserSP({
      variables: {
        sleagueTeamId: parseInt(sleagueTeamId),
        fixtureId: parseInt(fixtureId),
        sleagueId: parseInt(selectedSleague),
      },
    });
    if (error) {
      // console.log("Error", error, data);
      setCalSPMsg(error);
    } else {
      // console.log("Success");
      // console.log(data.calcFavteamUsersSp.response);
      setCalSPMsg(data.calcFavteamUsersSp.response);
    }
  };

  const calSPDlgClose = async e => {
    setCalSPDlgOpen(false);

    // Render the table rows again. RTS-1146
    const {
      loading: teamLoading,
      error: teamError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: {
        weekId: currWeekId,
      },
    });
    if (!teamLoading && allTeamsData && allTeamsData.weekFixtureTeams) {
      let ars = [];
      allTeamsData.weekFixtureTeams.map(team => {
        let a = team.fixtureId.fixtureId.toString();
        if (ars[a] === undefined) {
          ars[a] = [];
          ars[a].push(team);
        } else {
          ars[a].push(team);
        }
      });
      setTeamsAll(ars);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | PSP Calculator | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2>PSP Calculator</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League{" "}
              </label>
              <select
                className={adminStyle.selectWithLabel}
                id="league"
                {...register("league")}
                onChange={handleLeagueChange}
                value={currentLeagueId}>
              >
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                League Season
              </label>
              <select
                className={adminStyle.selectWithLabel}
                id="season"
                {...register("sleague")}
                onChange={handleSLeagueChange}
                value={selectedSleague}
              >
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="week">
                Week
              </label>
              <select
                className={adminStyle.selectWithLabel}
                id="week"
                {...register("week")}
                onChange={e =>handleWeekChange(e.target.value)}
                value={currWeekData}
              >
                {weeksData.map((week, i) => (
                  <option key={i} value={JSON.stringify(week, ["weekId", "name"])}>
                    {week.name}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter
            />
          </Grid>
          <Grid item>{weekNote}</Grid>
          
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By clicking “Calculate PSP”, you will be calculating the PSP for the{" "}
              {teamName} team in the fixture. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={calculateSP}>Calculate PSP</Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={calSPDlgOpen}
          onClose={calSPDlgClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Info</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {calSPMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={calSPDlgClose}>OK</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
