import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { GET_ALL_TEAM_QUERY } from "../graphQL/team_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function TeamList() {
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ALL_TEAM_QUERY, {
    fetchPolicy: "network-only",
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!loading && data && data.team) {
      setTeamsData(data.team);
      console.log("allSleaguesData----", data);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [data, teamsData]);

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 60,
      editable: false,
      renderCell: params => (
        <img src={"/lms/media/" + params.value} height={40} width={40} />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: rows => (
        <a href={`/lms/${admin_url}/team/${rows.row.id}`}>{rows.row.name}</a>
      ),
      editable: false,
    },
    {
      field: "shortName",
      headerName: "Short Name",
      width: 190,
      editable: false,
    },
    {
      field: "abbrName",
      headerName: "Abbreviate Name",
      width: 170,
      editable: false,
    },
    {
      field: "nickName",
      headerName: "Nick Name",
      width: 200,
      editable: false,
    },
    {
      field: "venueName",
      headerName: "Venue Name",

      width: 180,
      editable: false,
    },
    {
      field: "code",
      headerName: "Code",

      width: 120,
      editable: false,
    },
  ];
  const rows = teamsData.map((team, id) => ({
    id: team.teamId,
    name: team.name,
    shortName: team.shortName,
    abbrName: team.abbrName,
    nickName: team.nickName,
    venueName: team.venueName,
    code: team.code,
    logo: team.logo,
  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Teams | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Teams</h2>
          </Grid>
          <Grid item>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/team/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
