import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";

import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { CREATE_LEAGUE_MUTATION } from "../graphQL/league_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url} from "../store/data/Constants";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : League
## Description : Submit form data and create new record in league table
## Author : PS
## Date: : 2022/02/26
##########################################################################*/
export default function League() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [createLeague, { loading, error }] = useMutation(
    CREATE_LEAGUE_MUTATION
  );
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    }
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") }
    });
    console.log("data:", refreshData,"error:", refreshError)
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 20000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(500);
    console.log("DATA-----------", formData);
    const { data, error } = await createLeague({
      variables: {
        title: formData.title,
        subtitle: formData.subtitle,
        foundYear: parseInt(formData.foundYear)
      }
    });
    if (error) {
      console.log("Error", error, data);
      setFailedMessage("Unfortunately an error occurred.");
      setTimeout("");
    } else {
      console.log("Success");
      setSuccessMessage("New League added successfully.");
      setTimeout("");
      setTimeout(() => {
		navigate(`/lms/${admin_url}/league`);
      }, 2000);
    }
  };

  console.log(errors);
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add League | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add leagues to "League" table</h2>
          <label className={adminStyle.label} htmlFor="title">
            Title
          </label>
          <input
            className={adminStyle.input}
            id="title"
            {...register("title", { required: true })}
          />
          {errors.title && errors.title.type === "required" && (
            <span>Required</span>
          )}
          <label className={adminStyle.label} htmlFor="subtitle">
            Subtitle
          </label>
          <input
            className={adminStyle.input}
            id="subtitle"
            {...register("subtitle", { required: true })}
          />
          {errors.subtitle && errors.subtitle.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="foundYear">
            Found Year
          </label>
          <input
            className={adminStyle.input}
            id="foundYear"
            type="number"
            {...register("foundYear", { required: true })}
          />
          {errors.foundYear && errors.foundYear.type === "required" && (
            <span>Required</span>
          )}

          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
