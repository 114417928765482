
const TsByFootyExists_qry = `
  query($fixtureTeamId:Int!, $userSeq: String!){
    tsByFootyExists(fixtureTeamId: $fixtureTeamId, userSeq : $userSeq)
  }
`;



module.exports = {
  TS_BY_FOOTY_EXISTS_QUERY: TsByFootyExists_qry,

};
