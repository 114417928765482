import { memo, useState, useContext } from "react";
import { useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import { useDrop } from "react-dnd";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";

const useStyles = makeStyles({
  mainStyle: {
     color: "#212121",
    textAlign: "center",
    font: "10px Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
  },
  pStyleAdd: {
    backgroundColor: "#E0E0E0",
    border: "1px solid white",
    borderRadius: "50%",
    width: "14px", //38
    height: "14px", //38
    lineHeight: "45px",
    background: "#fff",
    color: "#000",
    justifyContent: "center",
    font: "16px Arial, sans-serif", //8
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    //marginBottom:"2px",
    marginTop: " 0px",
    alignItems: "center",
    justifyContent: "center",
    //marginBottom: "32px",
    cursor: "pointer",
  },
  pStyle: {
    backgroundColor: "#E0E0E0",
    borderRadius: "50%",
    width: "41px", //35
    height: "41px", //35
    //  lineHeight: "45px",
    color: "#212121",
    justifyContent: "center",
    font: "8px Roboto, Helvetica, Arial, sans-serif", //9
    fontWeight: 500,
    marginTop:0,
    marginBottom:2,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "-0.2px",
  },
 droppedCnt: {
    display: "flex",
    border: "1px solid #FFFFFF",
    padding: "1px 1px 0px 1px",
    //minHeight: 20,
    //width: 60, //90,
    position: "relative",
    background: "#9FC6A3",
    borderRadius: 2,
  },
 closeBtn: {
    width: "18px",
    height: "18px",
    borderRadius: "18px",
    position: "absolute",
    top: "-13px",
    right: "-12px",
    background: "#E0E0E0",
    color: "#212121",
    cursor: "pointer",
    justifyContent: "center",
    border: "1px solid #FFFFFF",
  },
});
export const Selection = memo(function Selection({
  accept,
  text,
  lastDroppedItem,
  height,
  width,
  img,
  onDrop,
  handleClickOpenAddPlayerDialog,
  embargoTime,
}) {
  const classes = useStyles();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  //const [removeDroppedItem, setRemoveDroppedItem] = useState(false);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
      navigate(`/lms/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };
  let name = "";
  if (lastDroppedItem) {
    let split = lastDroppedItem.name.split(" ");
    name =
      split[0] && split[1]
        ? split[0].charAt(0).toUpperCase() + ". " + split[1]
        : lastDroppedItem.name;
  }
  return (
    <div
      className={classes.mainStyle}
      style={{
        height,
        width,
        backgroundImage: `url(${img})`,
        //backgroundRepeat: "no-repeat",
        flexDirection: "column",
        //border: "1px solid white"
      }}
    >
      <div
        ref={drop}
        role="Selection"
        className={classes.mainStyle}
        style={{
          height: "100%",
          width: "90%",
          flexDirection: "column",
          //border: "1px solid black"
        }}
      >
        <p className={classes.pStyle}>{text}</p>

        {lastDroppedItem ? (
          <div
            className={classes.droppedCnt}
            style={{ pointerEvents: embargoTime ? "none" : "auto" }}
          >
            {" "}
            <p
              style={{ /*paddingRight: "5px",*/ margin:"2px"}}
              onClick={handleClickOpenAddPlayerDialog}
            >
              {lastDroppedItem.jerseyNO}{" "}
              {name}
            </p>
          </div>
        ) : (
          !embargoTime && (
            <p
              className={classes.pStyleAdd}
              onClick={handleClickOpenAddPlayerDialog}
            >
              +
            </p>
          )
        )}
      </div>
    </div>
  );
});
