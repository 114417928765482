import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext, useManualQuery } from "graphql-hooks";
import Layout from "./Layout.js";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { project_url, admin_url } from "../store/data/Constants";

import {
  ALL_SLEAGUE_TEAMS_PLAYER_NOT_ASSIGNED,
  SLEAGUE_TEAM_BY_ID,
} from "../graphQL/sleague_team_query";

const SLEAGUE_TEAM_PLAYER_BY_ID = `query sleagueTeamPlayerById($playerId:Int!){
  sleagueTeamPlayerById(playerId:$playerId){
    sleagueTeamPlayerId
    playerId{
      fName
      lName
	  shortName
	  name
      position
    }
    jerseyNO
    status
    playerSeqNO

    sleagueTeamId{
      sleagueTeamId
      sleagueId{
        seasonName
      }
      teamId{
        name
        shortName
      }
    }

  }
}`;

const EDIT_SLEAGUETEAMPLAYER_MUTATION = `
mutation editSleagueTeamPlayer($sleagueTeamPlayerId: Int!,
$jerseyNO:Int!, $status:String!, $playerSeqNO:String!,$updateSleagueTeam:String!) {
  editSleagueTeamPlayer(
    sleagueTeamPlayerId: $sleagueTeamPlayerId
    jerseyNO:$jerseyNO
    status:$status
    playerSeqNO:$playerSeqNO,
	updateSleagueTeam:$updateSleagueTeam


  ) {

    sleagueTeamPlayer{
      jerseyNO
    }

  }
}`;
const DELETE_SLEAGUETEAMPLAYER_MUTATION = `
mutation deleteSleagueTeamPlayer($sleagueTeamPlayerId: Int!,) {
  deleteSleagueTeamPlayer(
    sleagueTeamPlayerId: $sleagueTeamPlayerId

  ) {

    response

  }
}`;

export default function SleagueTeamPlayerEdit() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const { sleagueTeamPlayerId } = useParams();

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  // Set Players value
  const [players, setPlayers] = useState([]);
  const [jerseyNO, setJerseyNo] = useState("");
  const [status, setStatus] = useState("");
  const [playerSeqNO, setPlayerSeqNO] = useState("");
  const [newSleagueTeamId, setNewSleagueTeamId] = useState("-1");

  const [open, setOpen] = useState(false);

  const [splayer, setSplayer] = useState([]);
  const [notAssignedSleagueTeams, setNotAssignedSleagueTeams] = useState([]);

  //Player Seq List
  const [selectedPlayerSeqList, setSelectedPlayerSeqList] = useState([]);

  const [fetchSleagueTeamSeq] = useManualQuery(SLEAGUE_TEAM_BY_ID, {
    fetchPolicy: "network-only",
  });

  const teamHandleChange = async sleagueTeamId => {
    if (sleagueTeamId == -1) {
      document.getElementById("playerSequenceNo").disabled = true;
      setSelectedPlayerSeqList([{ seq: playerSeqNO }]);
    } else if (sleagueTeamId == 0)
      document.getElementById("playerSequenceNo").disabled = true;
    else if (sleagueTeamId > 0) {
      const {
        loading: sleagueTeamLoading,
        error: sleagueTeamError,
        data: sleagueTeamData,
      } = await fetchSleagueTeamSeq({
        variables: {
          sleagueTeamId: parseInt(sleagueTeamId),
        },
      });

      if (!sleagueTeamLoading && sleagueTeamData && sleagueTeamData.sleagueTeamById) {
        setSelectedPlayerSeqList(sleagueTeamData.sleagueTeamById.playerSeqList);
        document.getElementById("playerSequenceNo").disabled = false;
      }
    }
  };

  //get teams which player not assigned to them
  const {
    loading: teamsLoading,
    error: teamsError,
    data: teamsData,
  } = useQuery(ALL_SLEAGUE_TEAMS_PLAYER_NOT_ASSIGNED, {
    variables: {
      sleagueTeamPlayerId: parseInt(sleagueTeamPlayerId),
    },
  });

  useEffect(() => {
    if (teamsData && teamsData.sleagueTeamPlayerNotAssigned) {
      setNotAssignedSleagueTeams(teamsData.sleagueTeamPlayerNotAssigned);
    }
  }, [teamsData]);

  const [editSleagueTeamPlayer, { loading, error }] = useMutation(
    EDIT_SLEAGUETEAMPLAYER_MUTATION
  );
  const [deleteSleagueTeamPlayer, { deleteLoading, deleteError }] = useMutation(
    DELETE_SLEAGUETEAMPLAYER_MUTATION
  );
  const {
    loading: splayerLoading,
    error: splayerError,
    data: splayerData,
  } = useQuery(SLEAGUE_TEAM_PLAYER_BY_ID, {
    variables: {
      playerId: parseInt(sleagueTeamPlayerId),
    },
  });

  useEffect(() => {
    if (!splayerLoading && splayerData) {
      //  console.log(splayerData.sleagueTeamPlayerById);
      setSplayer(splayerData.sleagueTeamPlayerById);

      if (splayerData.sleagueTeamPlayerById.jerseyNO) {
        setValue("jerseyNO", splayerData.sleagueTeamPlayerById.jerseyNO);
      }
      if (splayerData.sleagueTeamPlayerById.status) {
        setValue("status", splayerData.sleagueTeamPlayerById.status);
      }
      if (splayerData.sleagueTeamPlayerById.playerSeqNO) {
        setPlayerSeqNO(splayerData.sleagueTeamPlayerById.playerSeqNO);
        setSelectedPlayerSeqList([
          { seq: splayerData.sleagueTeamPlayerById.playerSeqNO },
        ]);
      }
    }
  }, [splayerData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const currentTeamChange = e => {
    setNewSleagueTeamId(e.target.value);
    teamHandleChange(e.target.value);
  };

  const playerSeqChange = e => {
    setPlayerSeqNO(e.target.value);
  };

  //
  const onSubmit = async formData => {
    const { data, error } = await editSleagueTeamPlayer({
      variables: {
        sleagueTeamPlayerId: parseInt(sleagueTeamPlayerId),
        jerseyNO: parseInt(formData.jerseyNO),
        status: formData.status,
        playerSeqNO: playerSeqNO,
        updateSleagueTeam: newSleagueTeamId,
      },
    });

    if (error) {
      alert("Edit Error");
      console.log(error);
    } else {
      localStorage.setItem("ReturnFromEditPage", true);
      navigate(`/lms/${admin_url}/sleague-team-player`);
    }
  };

  const removeRecord = async req => {
    setOpen(false);
    const { data, error } = await deleteSleagueTeamPlayer({
      variables: {
        sleagueTeamPlayerId: parseInt(sleagueTeamPlayerId),
      },
    });
    if (error) {
      alert("Edit Error");
      console.log(error);
    } else {
      //if this player has any recodr in other tables cannot delete it
      if (data.deleteSleagueTeamPlayer.response == "Failed")
        alert(
          "This player cannot be deleted, because he has some stat records in " +
            splayer.sleagueTeamId.teamId.name
        );
      else {
        alert(
          "Player was successfully removed from the team in the selected season league."
        );
        navigate(`/lms/${admin_url}/sleague-team-player`);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Edit A Team Player | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Assign a player to a team in a league season</h2>

          <select
            className={adminStyle.select}
            id="playerId"
            {...register("playerId")}
            disabled={true}
          >
            <option selected="">
              {" "}
              {splayer.playerId != undefined ? splayer.playerId.name : ""}
            </option>
          </select>

          <select
            className={adminStyle.select}
            {...register("currentBucketSleagueId")}
            disabled={true}
          >
            <option>
              {splayer.sleagueTeamId != undefined
                ? splayer.sleagueTeamId.sleagueId.seasonName
                : ""}
            </option>
          </select>

          <select
            className={adminStyle.select}
            id="sleagueTeamId"
            {...register("sleagueTeamId")}
            disabled={true}
          >
            <option>
              {splayer.sleagueTeamId != undefined
                ? splayer.sleagueTeamId.teamId.name
                : ""}
            </option>
          </select>

          <select
            className={adminStyle.select}
            id="notAssignedSleagueTeamId"
            {...register("notAssignedSleagueTeamId")}
            onChange={currentTeamChange}
          >
            <option value="-1">Change Current Team ...</option>
            <option value="0">No Assignment</option>
            {notAssignedSleagueTeams.map(({ sleagueTeamId, teamId }) => (
              <option key={sleagueTeamId} value={sleagueTeamId}>
                {teamId.name}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="playerSeqNO">
            Player Sequence No.
          </label>

          <select
            className={adminStyle.select}
            id="playerSequenceNo"
            {...register("playerSequenceNo")}
            disabled={true}
            value={playerSeqNO}
            onChange={playerSeqChange}
          >
            <option value="">Select a Player Sequance No. ...</option>
            {selectedPlayerSeqList.map(playerSeq => (
              <option key={playerSeq.seq} value={playerSeq.seq}>
                {playerSeq.seq}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="jerseyNO">
            Jersey No.
          </label>
          <input
            className={adminStyle.input}
            type="number"
            min="1"
            {...register("jerseyNO", {
              required: true,
              pattern: /^\d+$/i,
            })}
            defaultValue={jerseyNO}
          />

          <label className={adminStyle.label} htmlFor="status">
            Status
          </label>
          <select className={adminStyle.select} id="status" {...register("status")}>
            <option value="Fit">Fit</option>
            <option value="Injured">Injured</option>
            <option value="Booked">Booked</option>
            <option value="Suspended">Suspended</option>
            <option value="Sick">Sick</option>
            <option value="Unavailable">Unavailable</option>
          </select>
          {errors.status && errors.status.type === "required" && <span>Required</span>}
          <input className={adminStyle.input} type="submit" />
        </form>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are You Sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By accepting to delete, you will unassign the player from the team and will
              delete his aggregated data. Proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={removeRecord}>Delete Player</Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
