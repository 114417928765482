import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useManualQuery, ClientContext } from "graphql-hooks";
import {project_url, media_url, secretPass} from "../../store/data/Constants";
import CryptoJS from "crypto-js";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import StadiumIcon from "@mui/icons-material/Stadium";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

import {
  GET_USERPROFILE_QUERY,
  UPDATE_USERPROFILE_QUERY,
  UPDATE_USER_MUTATION,
  UPDATE_USER_PASSWORD_MUTATION,
  REFRESH_TOKEN_MUTATION,
  DELETE_USER_ACCOUNT,
  UPDATE_USER_SETTINGS_MUTATION,
  UPDATE_USER_FAVTEAM_MUTATION,
  UPDATE_USERPROFILE_WITH_PASSWORD_MUTATION,
} from "../../graphQL/user_qry";
import { GET_CURRENT_SLEAGUETEAMS_QUERY } from "../../graphQL/sleague_qry";
import { GET_SETTING_BY_NAME, GET_SERVER_DATE_TIME } from "../../graphQL/setting_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../../graphQL/current_bucket_week_qry";

import logoImg from "../../assets/preditcal_logo.png";
import logoTeam from "../../assets/Chelsea.png";
import logoTeam1 from "../../assets/Everton.png";
import logoTeam2 from "../../assets/Arsenal.png";
import howWorks from "../../assets/how_works.png";
import howWorksTab from "../../assets/how_works_tablet.png";

const CHKUSRSEQ4CURRUSRFAVTEAM_QUERY = `query chkUsrSeq4CurrUsrFavteam($newFavTeamId: Int!){
  chkUsrSeq4CurrUsrFavteam(newFavTeamId: $newFavTeamId)
}`;

const DELETE_USERTS_MUTATION = `mutation deleteUserTs($userTSId: Int!) {
  deleteUserTs(userTSId: $userTSId) {
    response
  }
}`;

const DELETE_TSSHARE_MUTATION = `mutation deleteTsShare($userTSId: Int!) {
  deleteTsShare(userTSId: $userTSId) {
    response
  }
}`;

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1000,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  dateStyle: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  dateStyleMobile: {
    "& .MuiTypography-body2": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleTablet: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleMobile: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldDelStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldDelStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldStyle: {
    width: "60%",
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#F7F4F7",
    },
  },
  listItemText: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      "&:hover": {
        color: "#765285",
      },
    },
  },
  listItemTextSignout: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      color: "#d32f2f",
    },
  },
  ListItemButton: {
    height: 56,
    "&:hover": {
      borderLeft: "2px solid #765285",
      paddingLeft: "14px",
    },
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#765285",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#765285",
    },
  },
  teamCardSelected: {
    height: "56px",
    border: "1px solid #10A339",
    borderRadius: "8px",
    backgroundColor: "#F7F4F7",
  },
  teamCard: {
    height: "56px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "935px",
      },
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  textButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 16,
    height: "48px",
    padding: 0,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  outlinedRedButtonStyle: {
    width: 140,
    borderRadius: 8,
    color: "#E0281D",
    borderColor: "#E0281D",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  arrowIconStyle: {
    width: 20,
    height: 20,
    padding: 14,
    backgroundColor: "#F8F8F8",
    borderRadius: 8,
  },

  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

export default function UserProfile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cardComponent, setCardComponent] = useState("profile");
  const [preferedDateFormat, setPreferedDateFormat] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [openDelDialog, setOpenDelDialog] = useState(false);
  const [openConfirmDelDialog, setOpenConfirmDelDialog] = useState(false);
  const [openFavTeamDialog, setOpenFavTeamDialog] = useState(false);

  const mediaPath = `${media_url}`;
  const client = useContext(ClientContext);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [passConfirmErrorMsg, setPassConfirmErrorMsg] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState(false);
  const [delPassword, setDelPassword] = useState("");
  const [delPassError, setDelPassError] = useState(false);

  const [userProfileData, setUserProfileData] = useState([]);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  // const [firstname, setFirstname] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  // const [lastname, setLastname] = useState("");
  const [idletime, setIdletime] = useState(0);
  const [username, setUsername] = useState("");
  const [autoLogin, setAutoLogin] = useState(false);
  const [favTeam, setFavTeam] = useState("");
  const [allTeamsData, setAllTeamsData] = useState([]);
  const [dateFormatOptions, setDateFormatOptions] = useState([]);
  const [userTsId, setUserTsId] = useState(0);
  const [userTsMsg, setUserTsMsg] = useState("");
  const [userTsDlg, setUserTsDlg] = useState(false);
  const [chkUsrSeq4CurrUsrFavteam] = useManualQuery(CHKUSRSEQ4CURRUSRFAVTEAM_QUERY);
  const [deleteUserTs] = useMutation(DELETE_USERTS_MUTATION);
  const [deleteTsShare] = useMutation(DELETE_TSSHARE_MUTATION);
  const [currFavTeam, setCurrFavTeam] = useState("");

  const [inputs, setInputs] = useState({});
  const accountFields = ["firstName", "lastName", "username", "email"];
  const [initAutoLogin, setInitAutoLogin] = useState(false);
  const [initIdletime, setInitIdletime] = useState(0);
  const [initPreferedDateFormat, setInitPreferedDateFormat] = useState("");

  const [currPassMobTabValue, setCurrPassMobTabValue] = useState("");
  const [newPassMobTabValue, setNewPassMobTabValue] = useState("");
  const [confirmPassMobTabValue, setConfirmPassMobTabValue] = useState("");
  const [currPassMobTabMsg, setCurrPassMobTabMsg] = useState("");
  const [confirmPassMobTabMsg, setConfirmPassMobTabMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // setError,
    reset,
  } = useForm({ mode: "onChange" });

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_CURRENT_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });

  const {
    loading: loadingDateFormats,
    error: errorDateFormats,
    data: dataDateFormats,
  } = useQuery(GET_SETTING_BY_NAME, {
    fetchPolicy: "network-only",
    variables: {
      settingName: "preferedDateFormat",
    },
  });
  const {
    loading: loadingServerDateTime,
    error: errorServerDateTime,
    data: dataServerDateTime,
  } = useQuery(GET_SERVER_DATE_TIME, {
    fetchPolicy: "network-only",
  });

  const [updateUserProfile] = useMutation(UPDATE_USERPROFILE_QUERY, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const [updateUserProfileWithPass] = useMutation(
    UPDATE_USERPROFILE_WITH_PASSWORD_MUTATION,
    {
      refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
      awaitRefetchQueries: true,
    }
  );
  const [updateUserAccount] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });

  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const [updateUserFavteam] = useMutation(UPDATE_USER_FAVTEAM_MUTATION, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD_MUTATION, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });
  const [deleteAccountMutate] = useMutation(DELETE_USER_ACCOUNT, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true,
  });

  const [getCurrentBucketWeek] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (window.location.hash != "") {
      // console.log(window.location.hash);
      setCardComponent(window.location.hash.substring(1));
    }
  });
  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserProfileData(data.profile);

      setValue("email", data.profile.email);
      setValue("username", data.profile.username);
      setValue("firstname", data.profile.firstName);
      setValue("lastname", data.profile.lastName);
      accountFields.map(i => setInputs(values => ({ ...values, [i]: data.profile[i] })));

      setIdletime(data.profile.profile.idleTime);
      setInitIdletime(data.profile.profile.idleTime);
      setAutoLogin(data.profile.profile.autoLogin);
      setInitAutoLogin(data.profile.profile.autoLogin);
      setPreferedDateFormat(data.profile.profile.preferedDateFormat);
      setInitPreferedDateFormat(data.profile.profile.preferedDateFormat);

      setTimeout(() => {}, 1000);
      if (data.profile.userFavTeamField) {
        setFavTeam(data.profile.userFavTeamField.sleagueTeamId);
        setSelectedTeamId(data.profile.userFavTeamField.sleagueTeamId.sleagueTeamId);
        setCurrFavTeam(data.profile.userFavTeamField.sleagueTeamId);
        // setValue(
        //   "favTeam",
        //   data.profile.userFavTeamField.sleagueTeamId.sleagueTeamId
        // );
      }
      localStorage.setItem("username", data.profile.username);
      if (data.profile.isSuperuser) localStorage.setItem("role", CryptoJS.AES.encrypt("Admin",secretPass).toString());
      else localStorage.setItem("role", CryptoJS.AES.encrypt("Regular User",secretPass).toString());
    }
    if (error) {
      //  console.log("error----", error);
      if (error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/signin/?next=${window.location.pathname}`);
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      !loadingDateFormats &&
      dataDateFormats &&
      dataDateFormats.settingByName.allValues
    ) {
      setDateFormatOptions(dataDateFormats.settingByName.allValues);
    }
  });

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.currentSleagueTeam) {
      setAllTeamsData(dataSleague.currentSleagueTeam);
    }
  });

  const handleClickTeamCard = id => e => {
    setSelectedTeamId(id);
    let team = allTeamsData.find(item => item.sleagueTeamId === id);
    setFavTeam(team);
  };

  const handleChangeDate = (event: SelectChangeEvent) => {
    setPreferedDateFormat(event.target.value);
  };
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const handleClickMenuItem = name => {
    window.location.hash = name;
    setCardComponent(name);
    if (name !== "profile") {
      reset({
        firstname: inputs["firstName"],
        lastname: inputs["lastName"],
        username: inputs["username"],
        email: inputs["email"],
      });
    }
    if (name !== "favTeam") {
      setFavTeam(currFavTeam);
      setSelectedTeamId(currFavTeam.sleagueTeamId);
    }
    if (name !== "settings") {
      setIdletime(initIdletime);
      setAutoLogin(initAutoLogin);
      setPreferedDateFormat(initPreferedDateFormat);
    }
  };

  const handleClickDelButton = () => {
    setOpenDelDialog(true);
  };

  const handleCloseDelDialog = () => {
    setOpenDelDialog(false);
  };
  const handleClickConfirmDelButton = () => {
    setOpenDelDialog(false);
  };
  const handleCloseConfirmDelDialog = () => {
    setOpenConfirmDelDialog(false);
    localStorage.setItem("token", "");
    localStorage.setItem("email", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("username", "");
    navigate("/");
  };
  const handleOpenConfirmDelDialog = () => {
    setOpenConfirmDelDialog(true);
  };
  const handleOpenFavTeamDialog = () => {
    setOpenFavTeamDialog(true);
  };
  const handleCloseFavTeamDialog = () => {
    setOpenFavTeamDialog(false);
  };

  const handleCloseFavTeamDialogMob = () => {
    setFavTeam(currFavTeam);
    setSelectedTeamId(currFavTeam.sleagueTeamId);
    setOpenFavTeamDialog(false);
  };

  const options = ["Day, Month, Year"];
  const [errorMsg, setErrorMsg] = useState(false);
  //  "Incorrect email or password. Or, your account is not activated. Or, this email does not exist. Please try again.";
  const [successMsg, setSuccessMsg] = useState(false);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      // console.log(err);
    },
  });

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${window.location.pathname}`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${window.location.pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${window.location.pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  const handleUpdateProfile = async formData => {
    let autologinStr = "true";
    if (autoLogin === false) {
      autologinStr = "false";
    }
    if (newPassMobTabValue != "") {
      if (currPassMobTabMsg != "" || confirmPassMobTabMsg != "") return;
      const { data: updatePasswordData, error: updatePasswordError } =
        await updateUserPassword({
          variables: {
            currentPassword: currPassMobTabValue,
            newPassword: newPassMobTabValue,
            newPasswordConfirm: confirmPassMobTabValue,
          },
          headers: {
            Authorization: "JWT " + token,
          },
        });
      if (updatePasswordError) {
        setCurrPassMobTabMsg("Please enter your current password correctly");
        return;
      } else {
        if (
          updatePasswordData &&
          updatePasswordData.updateUserPassword &&
          updatePasswordData.updateUserPassword.message
        ) {
          if (updatePasswordData.updateUserPassword.message !== "success") {
            setCurrPassMobTabMsg("Please enter your current password correctly");
            return;
          }
        }
      }

      const { data: updateProfileData, error: updateProfileError } =
        await updateUserProfileWithPass({
          variables: {
            email: formData.email,
            password: currPassMobTabValue,
            newPassword: newPassMobTabValue,
            newPasswordConfirm: confirmPassMobTabValue,
            firstname: formData.firstname,
            lastname: formData.lastname,
            username: formData.username,
            idletime: parseInt(idletime),
            favTeam: "" + selectedTeamId,
            autoLogin: autologinStr,
            preferedDateFormat: preferedDateFormat,
          },
          headers: {
            Authorization: "JWT " + token,
          },
        });

      if (updateProfileError) {
        setFailedMessage(updateProfileError.graphQLErrors[0].message);
        setTimeout("");
      } else {
        localStorage.setItem(
          "username",
          updateProfileData.updateUserProfileWithPass.username
        );
        if (updateProfileData.updateUserProfileWithPass.autoLogin) {
          updateProfileData.updateUserProfileWithPass.autoLogin === "1"
            ? localStorage.setItem("autoLogin", true)
            : localStorage.setItem("autoLogin", false);
        }
        if (updateProfileData.updateUserProfileWithPass.preferedDateFormat) {
          console.log("Hey");
          localStorage.setItem(
            "preferedDateFormat",
            updateProfileData.updateUserProfileWithPass.preferedDateFormat
          );
        }
        if (updateProfileData.updateUserProfileWithPass.email) {
          localStorage.setItem(
            "email",
            updateProfileData.updateUserProfileWithPass.email
          );
        }
        if (updateProfileData.updateUserProfileWithPass.favTeam) {
          localStorage.setItem(
            "favTeam",
            updateProfileData.updateUserProfileWithPass.favTeam
          );
        }
        if (updateProfileError) {
          setErrorMsg(true);
        } else {
          if (
            updateProfileData &&
            updateProfileData.updateUserProfile &&
            updateProfileData.updateUserProfile.message
          ) {
            if (updateProfileData.updateUserProfile.message === "success") {
              setSuccessMsg(true);
              setPassErrorMsg(false);
              setPassword("");
              setNewPassword("");
              setNewPasswordConfirm("");
            } else if (
              updateProfileData.updateUserProfile.message === "incorrect password"
            ) {
              setPassErrorMsg(true);
              setPassConfirmErrorMsg(false);
              setPassword("");
              setNewPassword("");
              setNewPasswordConfirm("");
            }
          } else {
            // setPassConfirmErrorMsg(true);
            setPassErrorMsg(false);
            setNewPassword("");
            setNewPasswordConfirm("");
          }
        }
        await onRefreshToken();
        setSuccessMessage("Your account is updated.");
        setTimeout("");
        window.location.reload();
      }
    } else {
      const { data: updateProfileData, error: updateProfileError } =
        await updateUserProfile({
          variables: {
            email: formData.email,
            firstname: formData.firstname,
            lastname: formData.lastname,
            username: formData.username,
            idletime: parseInt(idletime),
            favTeam: "" + selectedTeamId,
            autoLogin: autologinStr,
            preferedDateFormat: preferedDateFormat,
          },
          headers: {
            Authorization: "JWT " + token,
          },
        });
      if (updateProfileError) {
        console.log("Error", updateProfileError, updateProfileData);
        setFailedMessage(updateProfileError.graphQLErrors[0].message);
        setTimeout("");
      } else {
        localStorage.setItem("username", updateProfileData.updateUserProfile.username);
        if (updateProfileData.updateUserProfile.autoLogin) {
          updateProfileData.updateUserProfile.autoLogin === "1"
            ? localStorage.setItem("autoLogin", true)
            : localStorage.setItem("autoLogin", false);
        }
        if (updateProfileData.updateUserProfile.preferedDateFormat) {
          console.log("Hey");
          localStorage.setItem(
            "preferedDateFormat",
            updateProfileData.updateUserProfile.preferedDateFormat
          );
        }
        if (updateProfileData.updateUserProfile.email) {
          localStorage.setItem("email", updateProfileData.updateUserProfile.email);
        }

        if (updateProfileData.updateUserProfile.favTeam) {
          localStorage.setItem("favTeam", updateProfileData.updateUserProfile.favTeam);
        }

        if (updateProfileData.updateUserProfile.favTeamLogo) {
          localStorage.setItem(
            "favTeamLogo",
            updateProfileData.updateUserProfile.favTeamLogo
          );
        }

        if (updateProfileError) {
          setErrorMsg(true);
        } else {
          if (
            updateProfileData &&
            updateProfileData.updateUserProfile &&
            updateProfileData.updateUserProfile.message
          ) {
            if (updateProfileData.updateUserProfile.message === "success") {
              setSuccessMsg(true);
              setPassErrorMsg(false);
              setPassword("");
              setNewPassword("");
              setNewPasswordConfirm("");
            } else if (
              updateProfileData.updateUserProfile.message === "incorrect password"
            ) {
              setPassErrorMsg(true);
              setPassConfirmErrorMsg(false);
              setPassword("");
              setNewPassword("");
              setNewPasswordConfirm("");
            }
          } else {
            // setPassConfirmErrorMsg(true);
            setPassErrorMsg(false);
            setNewPassword("");
            setNewPasswordConfirm("");
          }
        }
        await onRefreshToken();
        setSuccessMessage("Your account is updated.");
        setTimeout("");
        window.location.reload();
      }
    }
  };

  const handleUpdateAccount = async formData => {
    const { data: updateData, error: updateError } = await updateUserAccount({
      variables: {
        email: formData.email,
        firstname: formData.firstname,
        lastname: formData.lastname,
        username: formData.username,
      },
      headers: {
        Authorization: "JWT " + token,
      },
    });
    if (updateError) {
      //  console.log("Error", updateError, updateData);
      // setFailedMessage(updateError.graphQLErrors[0].message);
      setTimeout("");
    } else {
      localStorage.setItem("username", updateData.updateUser.username);
      if (updateData.updateUser.autoLogin) {
        updateData.updateUser.autoLogin === "1"
          ? localStorage.setItem("autoLogin", true)
          : localStorage.setItem("autoLogin", false);
      }
      if (updateData.updateUser.favTeam) {
        localStorage.setItem("favTeam", updateData.updateUser.favTeam);
      }
      if (updateData.updateUser.preferedDateFormat) {
        localStorage.setItem(
          "preferedDateFormat",
          updateData.updateUser.preferedDateFormat
        );
      }
      if (updateData.updateUser.email) {
        localStorage.setItem("email", updateData.updateUser.email);
      }
      await onRefreshToken();

      // console.log("Success", updateData);
      setSuccessMessage("Your account is updated.");
      setTimeout("");
      window.location.reload();
    }
  };

  const handleUpdateSettings = async e => {
    e.preventDefault();
    let autologinStr = "true";
    if (autoLogin === false) {
      autologinStr = "false";
    }
    const { data: updateSettingsData, error: updateSettingsError } =
      await updateUserSettings({
        variables: {
          autoLogin: autologinStr,
          preferedDateFormat: preferedDateFormat,
          idletime: parseInt(idletime),
        },
        headers: {
          Authorization: "JWT " + token,
        },
      });
    if (updateSettingsError) {
      console.log("Error", updateSettingsError, updateSettingsData);
      // setFailedMessage(updateError.graphQLErrors[0].message);
      setTimeout("");
    } else {
      if (updateSettingsData.updateUserSettings.autoLogin) {
        updateSettingsData.updateUserSettings.autoLogin === "1"
          ? localStorage.setItem("autoLogin", true)
          : localStorage.setItem("autoLogin", false);
      }
      if (updateSettingsData.updateUserSettings.preferedDateFormat) {
        localStorage.setItem(
          "preferedDateFormat",
          updateSettingsData.updateUserSettings.preferedDateFormat
        );
      }
      await onRefreshToken();
      setSuccessMessage("Your account is updated.");
      setTimeout("");
      window.location.reload();
    }
  };

  const handleUpdatePassword = async formData => {
    if (formData.newPassword === formData.confirmNewPassword) {
      setPassConfirmErrorMsg(false);
      const { data: updatePasswordData, error: updatePasswordError } =
        await updateUserPassword({
          variables: {
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
            newPasswordConfirm: formData.confirmNewPassword,
          },
          headers: {
            Authorization: "JWT " + token,
          },
        });
      if (updatePasswordError) {
        setErrorMsg(true);
      } else {
        if (
          updatePasswordData &&
          updatePasswordData.updateUserPassword &&
          updatePasswordData.updateUserPassword.message
        ) {
          if (updatePasswordData.updateUserPassword.message === "success") {
            setSuccessMsg(true);
            setPassErrorMsg(false);
            await onRefreshToken();
            setSuccessMessage("Your Password is updated.");
            setTimeout("");
            window.location.reload();
          } else if (
            updatePasswordData.updateUserPassword.message === "incorrect password"
          ) {
            setPassErrorMsg(true);
          }
        }
      }
    } else {
      setPassConfirmErrorMsg(true);
    }
  };

  const handleCloseUserTsDlg = () => {
    setUserTsId(0);
    setUserTsMsg("");
    setUserTsDlg(false);
  };

  const handleUpdateFavteamMobTab = async e => {
    let { loading, error, data } = await chkUsrSeq4CurrUsrFavteam({
      variables: {
        newFavTeamId: parseInt(selectedTeamId),
      },
    });
    if (error) {
      alert(error);
      return;
    }
    if (!loading && data && data.chkUsrSeq4CurrUsrFavteam) {
      let vals = data.chkUsrSeq4CurrUsrFavteam.split("&$");
      if(parseInt(vals[0]) == 0)
          alert(vals[1]);
      else{
        // if user can change his favTeam for current week, confirm it
        if(vals.length == 2){
            setUserTsId(parseInt(vals[0]));
            setUserTsDlg(true);
            setUserTsMsg(vals[1]);
        }
        // if user can change his favTeam for current week, confirm it
        else{
            setUserTsId(0);
            setUserTsDlg(true);
            setUserTsMsg(vals[1]);
        }
      }
    } else {
      handleCloseFavTeamDialog();
    }
  };

  const proceedUsrTsMobTab = async () => {
    const {
      loading: delUsrTsLoading,
      data: delUsrTsData,
      error: delUsrTsfError,
    } = await deleteUserTs({
      variables: { userTSId: userTsId },
    });

    if (delUsrTsfError) {
      alert(delUsrTsfError);
      return;
    }
    if (
      !delUsrTsLoading &&
      delUsrTsData &&
      delUsrTsData.deleteUserTs.response == "Success"
    ) {
      console.log("User Seq has been deleted from UserTs table");
      setUserTsId(0);
      setUserTsMsg("");
      setUserTsDlg(false);
      handleCloseFavTeamDialog();
    } else {
      alert("Cann't delete User seq");
    }
  };

  const handleUpdateFavteam = async e => {
    let { loading, error, data } = await chkUsrSeq4CurrUsrFavteam({
      variables: {
        newFavTeamId: parseInt(selectedTeamId),
      },
    });
    if (error) {
      alert(error);
      return;
    }
    if (!loading && data && data.chkUsrSeq4CurrUsrFavteam) {
      let vals = data.chkUsrSeq4CurrUsrFavteam.split("&$");
      //If user can not change his favTeam, only alert
      if(parseInt(vals[0]) == 0)
          alert(vals[1]);
      else{
        // if user can change his favTeam for current week, confirm it
        if(vals.length == 2){
            setUserTsId(parseInt(vals[0]));
            setUserTsDlg(true);
            setUserTsMsg(vals[1]);
        }
        // if user can change his favTeam for current week, confirm it
        else{
            setUserTsId(0);
            setUserTsDlg(true);
            setUserTsMsg(vals[1]);
        }
      }
    } else {
      updateFavteamFun();
    }
  };

  const proceedUsrTs = async () => {
    //if user voted for week and his vote must be deleted, userTsId is passed to be deleted
    if(userTsId != 0){
        const {
          loading: delUsrTsLoading,
          data: delUsrTsData,
          error: delUsrTsfError,
        } = await deleteUserTs({
          variables: { userTSId: userTsId },
        });

        if (delUsrTsfError) {
          alert(delUsrTsfError);
          return;
        }
        if (
          !delUsrTsLoading &&
          delUsrTsData &&
          delUsrTsData.deleteUserTs.response == "Success"
        ) {
          console.log("User Seq has been deleted from UserTs table");
          setUserTsId(0);
          setUserTsMsg("");
          setUserTsDlg(false);
          updateFavteamFun();
          // const {
          //   loading: delTsShareLoading,
          //   data: delTsShareData,
          //   error: delTsShareError,
          // } = await deleteTsShare({
          //   variables: { userTSId: userTsId },
          // });
          // console.log("delTsShareLoading load: ", delTsShareLoading);
          // console.log("delTsShareData data: ", delTsShareData);
          // console.log("delTsShareError error: ", delTsShareError);
          // if (delTsShareError) {
          //   alert(delTsShareError);
          //   return;
          // }
          // if (
          //   !delTsShareLoading &&
          //   delTsShareData &&
          //   delTsShareData.deleteTsShare.response == "Success"
          // ) {
          //   console.log("User TS Share has been deleted from TsShare table");
          //   setUserTsId(0);
          //   setUserTsMsg("");
          //   setUserTsDlg(false);
          // } else {
          //   alert("Cann't delete User TS Share");
          // }
        } else {
          alert("Cann't delete User seq");
        }
    }
    //if user cannot vote for new team for this week, it is set for him for next week
    else{
        setUserTsId(0);
        setUserTsMsg("");
        setUserTsDlg(false);
        updateFavteamFun()
    }
  };

  const updateFavteamFun = async () => {
    const { data: updateFavteamData, error: updateFavteamError } =
      await updateUserFavteam({
        variables: {
          favTeam: selectedTeamId,
        },
        headers: {
          Authorization: "JWT " + token,
        },
      });
    if (updateFavteamError) {
      console.log("Error", updateFavteamError, updateFavteamData);
      // setFailedMessage(updateError.graphQLErrors[0].message);
      setTimeout("");
    } else {
      if (updateFavteamData.updateUserFavteam.favTeam) {
        localStorage.setItem("favTeam", updateFavteamData.updateUserFavteam.favTeam);
      }

      if (updateFavteamData.updateUserFavteam.favTeamLogo) {
        localStorage.setItem(
          "favTeamLogo",
          updateFavteamData.updateUserFavteam.favTeamLogo
        );
      }
      await onRefreshToken();
      setSuccessMessage("Your account is updated.");
      setTimeout("");
      window.location.reload();
    }
  };

  const handleDeleteAccount = async e => {
    setDelPassError(false);
    const { data: deleteAccountData, error: deleteAccountError } =
      await deleteAccountMutate({
        variables: {
          password: delPassword,
          confirmPhrase: "Delete My Account",
        },
        headers: {
          Authorization: "JWT " + token,
        },
      });
    if (deleteAccountError) {
      console.log("Error", deleteAccountError, deleteAccountData);
    } else {
      console.log(deleteAccountData);
      if (
        deleteAccountData &&
        deleteAccountData.deleteUserAccount &&
        deleteAccountData.deleteUserAccount.response
      ) {
        if (deleteAccountData.deleteUserAccount.response === "success") {
          handleOpenConfirmDelDialog();
        } else if (deleteAccountData.deleteUserAccount.response === "error:password") {
          setDelPassError(true);
        } else {
        }
      }
    }
  };

  const idletimeHandleChange = event => {
    let value = event.target.value;
    if (isNaN(parseInt(value))) setIdletime(prvVal => prvVal);
    else setIdletime(parseInt(value));
  };

  const currPassMobTabHandleChange = event => {
    const value = event.target.value;
    setCurrPassMobTabValue(value);
    if (value != "") setCurrPassMobTabMsg("");
    else {
      if (newPassMobTabValue == "") setCurrPassMobTabMsg("");
      else setCurrPassMobTabMsg("Current Password cannot be blank");
    }
  };

  const newPassMobTabHandleChange = event => {
    const value = event.target.value;
    setNewPassMobTabValue(value);
    if (value != "") {
      if (currPassMobTabValue == "")
        setCurrPassMobTabMsg("Current Password cannot be blank");
      else setCurrPassMobTabMsg("");

      if (confirmPassMobTabValue == "")
        setConfirmPassMobTabMsg("Re-enter New Password cannot be blank");
      else {
        if (confirmPassMobTabValue != value)
          setConfirmPassMobTabMsg("Password and its repeat must be the same");
        else setConfirmPassMobTabMsg("");
      }
    } else {
      setCurrPassMobTabMsg("");
      setCurrPassMobTabValue("");
      setConfirmPassMobTabMsg("");
      setConfirmPassMobTabValue("");
    }
  };

  const confirmPassMobTabHandleChange = event => {
    const value = event.target.value;
    setConfirmPassMobTabValue(value);
    if (value != "") {
      if (newPassMobTabValue != value)
        setConfirmPassMobTabMsg("Password and its repeat must be the same");
      else setConfirmPassMobTabMsg("");
    } else {
      if (newPassMobTabValue == "") setConfirmPassMobTabMsg("");
      else setConfirmPassMobTabMsg("Current Password cannot be blank");
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | My profile</title>        
      </Helmet>
      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <form onSubmit={handleSubmit(handleUpdateProfile)}>
              <Box alignItems="center" flexGrow={1}>
                <Typography gutterBottom variant="subtitle2">
                  Profile
                </Typography>
              </Box>
              <Box flexGrow={1} mb={1}>
                <TextField
                  className={classes.textFieldStyleMobile}
                  style={{ marginTop: 10 }}
                  fullWidth
                  label="First Name*"
                  margin="normal"
                  name="firstname"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("firstname", {
                    required: true,
                  })}
                />
                {errors.firstname && errors.firstname.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      First name cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                <TextField
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Last Name*"
                  margin="normal"
                  name="lastname"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("lastname", {
                    required: true,
                  })}
                />
                {errors.lastname && errors.lastname.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Last name cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                <TextField
                  disabled
                  required
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="User Name"
                  margin="normal"
                  name="username"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("username", {
                    required: true,
                  })}
                />
                {/* {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      User name cannot be blank.
                    </FormHelperText>
                  </Box>
                )} */}
                <TextField
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Email*"
                  margin="normal"
                  name="email"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Email cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Invalid email format.
                    </FormHelperText>
                  </Box>
                )}
                <Box mt={3} mb={3} style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className={classes.outlinedRedButtonStyle}
                    onClick={handleClickDelButton}
                    variant="outlined"
                  >
                    Delete Account
                  </Button>
                </Box>
                <Divider />
              </Box>
              <Box mt={2} alignItems="center" flexGrow={1}>
                <Typography gutterBottom variant="subtitle2">
                  Favourite Team
                </Typography>
                <Box mt={1}>
                  <Typography gutterBottom variant="h3" style={{ lineHeight: 1.7 }}>
                    You can select another favourite team by pushing the Change button.
                  </Typography>
                </Box>
              </Box>
              <Box mb={3} mt={3} flexGrow={1}>
                <Card className={classes.teamCard}>
                  <CardContent style={{ paddingTop: 8 }}>
                    <Box
                      mt={0.5}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        pt={1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={
                            favTeam && favTeam.teamId && mediaPath + favTeam.teamId.logo
                          }
                          height={20}
                          width={20}
                          style={{ marginRight: 12 }}
                        />
                        <Typography variant="h3">
                          {favTeam && favTeam.teamId && favTeam.teamId.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 90, height: 32 }}
                          onClick={handleOpenFavTeamDialog}
                          color="primary"
                          variant="contained"
                        >
                          Change
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
              <Divider />
              <Box mt={3} mb={3} alignItems="center" flexGrow={1}>
                <Typography gutterBottom variant="subtitle2">
                  Settings
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    gutterBottom
                    style={{ fontSize: 16, fontWeight: 400, marginTop: 5 }}
                  >
                    Remember Me
                  </Typography>
                  <Switch
                    checked={autoLogin}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setAutoLogin(event.target.checked)
                    }
                    className={classes.switch}
                  />
                </Box>
                <Box mt={2} mb={3}>
                  <Typography gutterBottom style={{ fontSize: 14, fontWeight: 400 }}>
                    Check this field, if you wish to bypass the sign-in process when you
                    return to the site to sign in.
                  </Typography>
                </Box>
                <Divider />
                <TextField
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Idle Time*"
                  margin="normal"
                  name="idleTime"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    inputProps: { min: 1, pattern: "[0-9]*" },
                  }}
                  value={idletime}
                  onChange={idletimeHandleChange}
                />
                {/* {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Idle Time cannot be blank.
                    </FormHelperText>
                  </Box>
                )} */}
                <div className={classes.autoComStyleMobile}>
                  <FormControl required>
                    <InputLabel>Preferred Date Format</InputLabel>
                    <Select
                      id="preferedDateFormat"
                      label="Preferred Date Format"
                      value={preferedDateFormat}
                      margin="normal"
                      onChange={handleChangeDate}
                    >
                      {dateFormatOptions.map(op => (
                        <MenuItem value={op.value}>{op.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Preferred Date Format cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
              </Box>
              <Divider />
              <Box mt={2} alignItems="center" flexGrow={1}>
                <Typography gutterBottom variant="subtitle2">
                  Reset Password
                </Typography>
              </Box>
              <TextField
                className={classes.textFieldStyleMobile}
                style={{ marginTop: 10 }}
                fullWidth
                label="Current Password"
                margin="normal"
                name="currentPassword"
                type="password"
                variant="outlined"
                onChange={currPassMobTabHandleChange}
                // {...register("currentPassword", {
                //   required: false,
                // })}
              />
              {currPassMobTabMsg && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    {currPassMobTabMsg}
                  </FormHelperText>
                </Box>
              )}
              {/* {passErrorMsg && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyle} error>
                    Please enter your current password correctly.
                  </FormHelperText>
                </Box>
              )} */}
              <TextField
                className={classes.textFieldStyleMobile}
                fullWidth
                label="New Password"
                margin="normal"
                name="newPassword"
                type="password"
                variant="outlined"
                onChange={newPassMobTabHandleChange}
                // {...register("newPassword", {
                //   required: false,
                // })}
              />
              {/* {errorMsg && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    New Password cannot be blank.
                  </FormHelperText>
                </Box>
              )} */}
              <TextField
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Re-enter New Password"
                margin="normal"
                name="confirmNewPassword"
                type="password"
                variant="outlined"
                onChange={confirmPassMobTabHandleChange}
                // {...register("confirmNewPassword", {
                //   required: false,
                // })}
              />
              {confirmPassMobTabMsg && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    {confirmPassMobTabMsg}
                  </FormHelperText>
                </Box>
              )}
              {/* {passConfirmErrorMsg && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyle} error>
                    Password and its repeat must be the same.
                  </FormHelperText>
                </Box>
              )} */}
              <Box
                mt={7}
                mb={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {/* <Box mr={2}>
                  <Button
                    className={classes.outlinedButtonStyle}
                    style={{ width: 135 }}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box> */}
                <Button
                  className={classes.buttonStyle}
                  style={{ width: 135 }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
              <Dialog
                open={openDelDialog}
                onClose={handleCloseDelDialog}
                className={classes.dialog}
              >
                <DialogTitle style={{ fontSize: 24, fontWeight: 600 }}>
                  Delete Account
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    style={{ fontSize: 16, fontWeight: 400, lineHeight: 1.6 }}
                  >
                    We are sorry to see you are leaving the Predictal platform. We would
                    love to see that you stay. Is there anything we can do so that you
                    stay? How about improving the Team Selector? Let us know please by
                    sending an email to{" "}
                    <a href="mailto:support@predictal.com">support@predictal.com. </a>{" "}
                    <br />
                    If you have made up your mind and you really wish to leave, we respect
                    your decision. So, please confirm your departure by entering your
                    account password and then click the ‘’Delete’’ button, and all your
                    personal data will permanently be deleted. In case you changed your
                    mind and you wish to stay, click the ‘’Cancel’’ button.
                  </DialogContentText>
                  <TextField
                    className={classes.textFieldDelStyleMobile}
                    style={{ marginTop: 15 }}
                    margin="dense"
                    id="password"
                    label="Your password"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={delPassword}
                    onChange={e => setDelPassword(e.target.value)}
                  />
                  {delPassError && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Your entered password is incorrect.
                      </FormHelperText>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Button
                    className={classes.outlinedButtonStyle}
                    onClick={handleCloseDelDialog}
                    style={{ width: 135 }}
                    color="primary"
                    variant="text"
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.outlinedRedButtonStyle}
                    variant="outlined"
                    onClick={handleDeleteAccount}
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openConfirmDelDialog}
                onClose={handleCloseConfirmDelDialog}
                className={classes.dialog}
              >
                <DialogContent>
                  <DialogContentText
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: 1.6,
                      marginTop: 15,
                    }}
                  >
                    Your account and its personal information have permanently been
                    removed from the Predictal platform. Thank you for staying with us.
                    You can always return. Simply register again.
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Button
                    className={classes.outlinedButtonStyle}
                    onClick={handleCloseConfirmDelDialog}
                    style={{ width: 135 }}
                    color="primary"
                    variant="outlined"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                fullScreen
                open={openFavTeamDialog}
                onClose={handleCloseFavTeamDialogMob}
              >
                <DialogTitle
                  style={{
                    fontSize: 24,
                    paddingTop: 50,
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Favourite Team
                  <IconButton aria-label="close" onClick={handleCloseFavTeamDialogMob}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ fontSize: 16, fontWeight: 400 }}>
                    <Box mb={1}>Teams</Box>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Grid container spacing={2}>
                          {allTeamsData.map(elem => (
                            <Grid item xs={12} sm={12} key={allTeamsData.indexOf(elem)}>
                              <CardActionArea
                                onClick={handleClickTeamCard(elem.sleagueTeamId)}
                              >
                                <Card
                                  className={
                                    elem.sleagueTeamId === selectedTeamId
                                      ? classes.teamCardSelected
                                      : classes.teamCard
                                  }
                                >
                                  <CardContent
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      mt={0.5}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <img
                                        src={mediaPath + elem.teamId.logo}
                                        height={20}
                                        width={20}
                                        style={{ marginRight: 12 }}
                                      />
                                      <Typography variant="h3">
                                        {elem.teamId.name}
                                      </Typography>
                                    </Box>
                                    {elem.id === selectedTeamId && (
                                      <CheckCircleOutlineIcon color="success" />
                                    )}
                                  </CardContent>
                                </Card>
                              </CardActionArea>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box
                      mt={6}
                      mb={2}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {/* <Box mr={2}>
                        <Button
                          className={classes.outlinedButtonStyle}
                          onClick={handleCloseFavTeamDialogMob}
                          style={{ width: 135 }}
                          color="primary"
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </Box> */}
                      <Button
                        className={classes.buttonStyle}
                        onClick={handleUpdateFavteamMobTab} // onClick={handleCloseFavTeamDialog}
                        style={{ width: 135 }}
                        color="primary"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              <Dialog
                open={userTsDlg}
                onClose={handleCloseUserTsDlg}
                className={classes.dialog}
              >
                <DialogTitle style={{ fontSize: 24, fontWeight: 600 }}>Info</DialogTitle>
                <DialogContent>
                  <DialogContentText
                    style={{ fontSize: 16, fontWeight: 400, lineHeight: 1.6 }}
                  >
                    {userTsMsg}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Button
                    className={classes.outlinedButtonStyle}
                    onClick={proceedUsrTsMobTab}
                  >
                    Proceed
                  </Button>
                  <Button
                    className={classes.outlinedButtonStyle}
                    color="primary"
                    variant="text"
                    onClick={handleCloseUserTsDlg}
                    autoFocus
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </CardContent>
        </Card>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <form onSubmit={handleSubmit(handleUpdateProfile)}>
                <Box alignItems="center" flexGrow={1}>
                  <Typography gutterBottom variant="subtitle1">
                    Profile
                  </Typography>
                </Box>
                <Box flexGrow={1} mb={1}>
                  <TextField
                    className={classes.textFieldStyleTablet}
                    style={{ marginTop: 10 }}
                    fullWidth
                    label="First Name*"
                    margin="normal"
                    name="firstname"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("firstname", {
                      required: true,
                    })}
                  />
                  {errors.firstname && errors.firstname.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        First name cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    className={classes.textFieldStyleTablet}
                    fullWidth
                    label="Last Name*"
                    margin="normal"
                    name="lastname"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("lastname", {
                      required: true,
                    })}
                  />
                  {errors.lastname && errors.lastname.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Last name cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    disabled
                    required
                    className={classes.textFieldStyleTablet}
                    fullWidth
                    label="User Name"
                    margin="normal"
                    name="username"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("username", {
                      required: true,
                    })}
                  />
                  {/* {errorMsg && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        User name cannot be blank.
                      </FormHelperText>
                    </Box>
                  )} */}
                  <TextField
                    className={classes.textFieldStyleTablet}
                    fullWidth
                    label="Email*"
                    margin="normal"
                    name="email"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Email cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Invalid email format.
                      </FormHelperText>
                    </Box>
                  )}
                  <Box
                    mt={3}
                    mb={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className={classes.outlinedRedButtonStyle}
                      onClick={handleClickDelButton}
                      variant="outlined"
                    >
                      Delete Account
                    </Button>
                  </Box>
                  <Divider />
                </Box>
                <Box mt={2} alignItems="center" flexGrow={1}>
                  <Typography gutterBottom variant="subtitle1">
                    Favourite Team
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      gutterBottom
                      style={{ lineHeight: 1.7, fontSize: 18, fontWeight: 400 }}
                    >
                      You can select another favourite team by pushing the Change button.
                    </Typography>
                  </Box>
                </Box>
                <Box mb={3} mt={3} flexGrow={1}>
                  <Card className={classes.teamCard}>
                    <CardContent style={{ paddingTop: 8 }}>
                      <Box
                        mt={0.5}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          pt={1}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <img
                            src={
                              favTeam && favTeam.teamId && mediaPath + favTeam.teamId.logo
                            }
                            height={20}
                            width={20}
                            style={{ marginRight: 12 }}
                          />
                          <Typography variant="h3">
                            {favTeam && favTeam.teamId && favTeam.teamId.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 90, height: 32 }}
                            onClick={handleOpenFavTeamDialog}
                            color="primary"
                            variant="contained"
                          >
                            Change
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Divider />
                <Box mt={3} mb={3} alignItems="center" flexGrow={1}>
                  <Typography gutterBottom variant="subtitle1">
                    Settings
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      gutterBottom
                      style={{ fontSize: 18, fontWeight: 500, marginTop: 5 }}
                    >
                      Remember Me
                    </Typography>
                    <Switch
                      checked={autoLogin}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setAutoLogin(event.target.checked)
                      }
                      className={classes.switch}
                    />
                  </Box>
                  <Box mt={2} mb={3}>
                    <Typography gutterBottom style={{ fontSize: 16, fontWeight: 400 }}>
                      Check this field, if you wish to bypass the sign-in process when you
                      return to the site to sign in.
                    </Typography>
                  </Box>
                  <Divider />
                  <TextField
                    required
                    className={classes.textFieldStyleTablet}
                    fullWidth
                    label="Idle Time*"
                    margin="normal"
                    name="idleTime"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      inputProps: { min: 1, pattern: "[0-9]*" },
                    }}
                    value={idletime}
                    onChange={idletimeHandleChange}
                  />

                  <div className={classes.autoComStyleTablet}>
                    <FormControl required>
                      <InputLabel>Preferred Date Format</InputLabel>
                      <Select
                        id="preferedDateFormat"
                        label="Preferred Date Format"
                        value={preferedDateFormat}
                        margin="normal"
                        onChange={handleChangeDate}
                      >
                        {dateFormatOptions.map(op => (
                          <MenuItem value={op.value}>{op.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {errorMsg && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyle} error>
                        Preferred Date Format cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
                <Divider />
                <Box mt={2} alignItems="center" flexGrow={1}>
                  <Typography gutterBottom variant="subtitle1">
                    Reset Password
                  </Typography>
                </Box>
                <TextField
                  className={classes.textFieldStyleTablet}
                  style={{ marginTop: 10 }}
                  fullWidth
                  label="Current Password"
                  margin="normal"
                  name="currentPassword"
                  type="password"
                  variant="outlined"
                  onChange={currPassMobTabHandleChange}
                  // {...register("currentPassword", {
                  //   required: false,
                  // })}
                />
                {currPassMobTabMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      {currPassMobTabMsg}
                    </FormHelperText>
                  </Box>
                )}
                {/* {passErrorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      Please enter your current password correctly.
                    </FormHelperText>
                  </Box>
                )} */}
                <TextField
                  className={classes.textFieldStyleTablet}
                  fullWidth
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  type="password"
                  variant="outlined"
                  onChange={newPassMobTabHandleChange}
                  // {...register("newPassword", {
                  //   required: false,
                  // })}
                />
                {/* {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      New Password cannot be blank.
                    </FormHelperText>
                  </Box>
                )} */}
                <TextField
                  className={classes.textFieldStyleTablet}
                  fullWidth
                  label="Re-enter New Password"
                  margin="normal"
                  name="confirmNewPassword"
                  type="password"
                  variant="outlined"
                  onChange={confirmPassMobTabHandleChange}
                  // {...register("confirmNewPassword", {
                  //   required: false,
                  // })}
                />
                {confirmPassMobTabMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      {confirmPassMobTabMsg}
                    </FormHelperText>
                  </Box>
                )}
                {/* {passConfirmErrorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      Password and its repeat must be the same.
                    </FormHelperText>
                  </Box>
                )} */}
                <Box
                  mt={7}
                  mb={1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {/* <Box mr={2}>
                    <Button
                      className={classes.outlinedButtonStyle}
                      style={{ width: 135 }}
                      color="primary"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Box> */}
                  <Button
                    className={classes.buttonStyle}
                    style={{ width: 135 }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
                <Dialog
                  open={openDelDialog}
                  onClose={handleCloseDelDialog}
                  className={classes.dialog}
                >
                  <DialogTitle style={{ fontSize: 28, fontWeight: 600 }}>
                    Delete Account
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{ fontSize: 18, fontWeight: 400, lineHeight: 1.6 }}
                    >
                      We are sorry to see you are leaving the Predictal platform. We would
                      love to see that you stay. Is there anything we can do so that you
                      stay? How about improving the Team Selector? Let us know please by
                      sending an email to{" "}
                      <a href="mailto:support@predictal.com">support@predictal.com. </a>{" "}
                      <br />
                      If you have made up your mind and you really wish to leave, we
                      respect your decision. So, please confirm your departure by entering
                      your account password and then click the ‘’Delete’’ button, and all
                      your personal data will permanently be deleted. In case you changed
                      your mind and you wish to stay, click the ‘’Cancel’’ button.
                    </DialogContentText>
                    <TextField
                      className={classes.textFieldDelStyle}
                      style={{ marginTop: 15 }}
                      margin="dense"
                      id="password"
                      label="Your password"
                      type="password"
                      fullWidth
                      variant="standard"
                      value={delPassword}
                      onChange={e => setDelPassword(e.target.value)}
                    />
                    {delPassError && (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyleMobile} error>
                          Your entered password is incorrect.
                        </FormHelperText>
                      </Box>
                    )}
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      onClick={handleCloseDelDialog}
                      style={{ width: 135 }}
                      color="primary"
                      variant="text"
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.outlinedRedButtonStyle}
                      onClick={handleClickConfirmDelButton}
                      variant="outlined"
                      onClick={handleDeleteAccount}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openConfirmDelDialog}
                  onClose={handleCloseConfirmDelDialog}
                  className={classes.dialog}
                >
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: 18,
                        fontWeight: 400,
                        lineHeight: 1.6,
                        marginTop: 15,
                      }}
                    >
                      Your account and its personal information have permanently been
                      removed from the Predictal platform. Thank you for staying with us.
                      You can always return. Simply register again.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      onClick={handleCloseConfirmDelDialog}
                      style={{ width: 135 }}
                      color="primary"
                      variant="outlined"
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullScreen
                  open={openFavTeamDialog}
                  onClose={handleCloseFavTeamDialogMob}
                >
                  <DialogTitle
                    style={{
                      fontSize: 24,
                      paddingTop: 76,
                      paddingLeft: 100,
                      paddingRight: 100,
                      fontWeight: 600,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Favourite Team
                    <IconButton aria-label="close" onClick={handleCloseFavTeamDialogMob}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: 18,
                        fontWeight: 400,
                        paddingLeft: 80,
                        paddingRight: 80,
                      }}
                    >
                      <Box mb={1}>Teams</Box>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Grid container spacing={2}>
                            {allTeamsData.map(elem => (
                              <Grid item xs={12} sm={12} key={allTeamsData.indexOf(elem)}>
                                <CardActionArea
                                  onClick={handleClickTeamCard(elem.sleagueTeamId)}
                                >
                                  <Card
                                    className={
                                      elem.sleagueTeamId === selectedTeamId
                                        ? classes.teamCardSelected
                                        : classes.teamCard
                                    }
                                  >
                                    <CardContent
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box
                                        mt={0.5}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <img
                                          src={
                                            elem.teamId.logo &&
                                            mediaPath + elem.teamId.logo
                                          }
                                          height={20}
                                          width={20}
                                          style={{ marginRight: 12 }}
                                        />
                                        <Typography variant="h3">
                                          {elem.teamId.name}
                                        </Typography>
                                      </Box>
                                      {elem.sleagueTeamId === selectedTeamId && (
                                        <CheckCircleOutlineIcon color="success" />
                                      )}
                                    </CardContent>
                                  </Card>
                                </CardActionArea>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box
                        mt={6}
                        mb={3}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Box mr={2}>
                          <Button
                            className={classes.outlinedButtonStyle}
                            onClick={handleCloseFavTeamDialog}
                            style={{ width: 135 }}
                            color="primary"
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                        </Box> */}
                        <Button
                          className={classes.buttonStyle}
                          onClick={handleUpdateFavteamMobTab} // onClick={handleCloseFavTeamDialog}
                          style={{ width: 135 }}
                          color="primary"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={userTsDlg}
                  onClose={handleCloseUserTsDlg}
                  className={classes.dialog}
                >
                  <DialogTitle style={{ fontSize: 28, fontWeight: 600 }}>
                    Info
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{ fontSize: 18, fontWeight: 400, lineHeight: 1.6 }}
                    >
                      {userTsMsg}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      className={classes.outlinedButtonStyle}
                      onClick={proceedUsrTsMobTab}
                    >
                      Proceed
                    </Button>
                    <Button
                      className={classes.outlinedButtonStyle}
                      color="primary"
                      variant="text"
                      onClick={handleCloseUserTsDlg}
                      autoFocus
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </CardContent>
          </Card>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Box mb={1}>
            <Typography gutterBottom variant="subtitle1">
              Profile
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xl={4} xs={12}>
              <Card>
                <List style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <ListItem
                    id="account"
                    disablePadding
                    className={classes.listItem}
                    onClick={() => handleClickMenuItem("profile")}
                    style={{
                      backgroundColor: cardComponent === "profile" ? "#F7F4F7" : "",
                    }}
                  >
                    <ListItemButton className={classes.ListItemButton}>
                      <ListItemIcon style={{ minWidth: 40, paddingBottom: 7 }}>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="Profile" className={classes.listItemText} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    id="favteam"
                    disablePadding
                    className={classes.listItem}
                    onClick={() => handleClickMenuItem("favTeam")}
                    style={{
                      backgroundColor: cardComponent === "favTeam" ? "#F7F4F7" : "",
                    }}
                  >
                    <ListItemButton className={classes.ListItemButton}>
                      <ListItemIcon style={{ minWidth: 40, paddingBottom: 7 }}>
                        <img
                          src={
                            favTeam && favTeam.teamId && mediaPath + favTeam.teamId.logo
                          }
                          height={20}
                          width={20}
                          style={{ marginTop: 5 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Favourite Team"
                        className={classes.listItemText}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    className={classes.listItem}
                    onClick={() => handleClickMenuItem("settings")}
                    style={{
                      backgroundColor: cardComponent === "settings" ? "#F7F4F7" : "",
                    }}
                  >
                    <ListItemButton className={classes.ListItemButton}>
                      <ListItemIcon style={{ minWidth: 40, paddingBottom: 7 }}>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Settings" className={classes.listItemText} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    className={classes.listItem}
                    onClick={() => handleClickMenuItem("resetPass")}
                    style={{
                      backgroundColor: cardComponent === "resetPass" ? "#F7F4F7" : "",
                    }}
                  >
                    <ListItemButton className={classes.ListItemButton}>
                      <ListItemIcon style={{ minWidth: 40, paddingBottom: 7 }}>
                        <LockOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Reset Password"
                        className={classes.listItemText}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                  <ListItem
                    disablePadding
                    className={classes.listItem}
                    onClick={() => navigate("/" + project_url + "/signout/")}
                  >
                    <ListItemButton className={classes.ListItemButton}>
                      <ListItemIcon style={{ minWidth: 40, paddingBottom: 7 }}>
                        <LogoutIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Sign out"
                        className={classes.listItemTextSignout}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Card>
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  {cardComponent === "profile" && (
                    <form onSubmit={handleSubmit(handleUpdateAccount)}>
                      <TextField
                        className={classes.textFieldStyle}
                        label="First Name*"
                        margin="normal"
                        name="firstname"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        {...register("firstname", {
                          required: true,
                        })}
                      />
                      {errors.firstname && errors.firstname.type === "required" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            First name cannot be blank.
                          </FormHelperText>
                        </Box>
                      )}
                      <TextField
                        className={classes.textFieldStyle}
                        label="Last Name*"
                        margin="normal"
                        name="lastname"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        {...register("lastname", {
                          required: true,
                        })}
                      />
                      {errors.lastname && errors.lastname.type === "required" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Last name cannot be blank.
                          </FormHelperText>
                        </Box>
                      )}
                      <TextField
                        disabled
                        className={classes.textFieldStyle}
                        label="User Name"
                        margin="normal"
                        name="username"
                        variant="outlined"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        {...register("username", {
                          // required: true,
                        })}
                      />
                      {/* {errors.username && errors.username.type === "required" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            User name cannot be blank.
                          </FormHelperText>
                        </Box>
                      )} */}
                      <TextField
                        className={classes.textFieldStyle}
                        label="Email*"
                        margin="normal"
                        name="email"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        {...register("email", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Email cannot be blank.
                          </FormHelperText>
                        </Box>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Invalid email format.
                          </FormHelperText>
                        </Box>
                      )}

                      <Box
                        mt={7}
                        mb={1}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          mr={4}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box mr={2}>
                            <Button
                              className={classes.buttonStyle}
                              style={{ width: 135 }}
                              color="primary"
                              variant="contained"
                              type="submit"
                            >
                              Save
                            </Button>
                          </Box>
                          <Button
                            className={classes.outlinedRedButtonStyle}
                            onClick={handleClickDelButton}
                            variant="outlined"
                          >
                            Delete Account
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                  {cardComponent === "settings" && (
                    <form onSubmit={handleUpdateSettings}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          gutterBottom
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            marginTop: 5,
                          }}
                        >
                          Remember Me
                        </Typography>
                        <Switch
                          checked={autoLogin}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setAutoLogin(event.target.checked)
                          }
                          className={classes.switch}
                        />
                      </Box>
                      <Box mt={2} mb={3}>
                        <Typography
                          gutterBottom
                          style={{ fontSize: 16, fontWeight: 400 }}
                        >
                          Check this field, if you wish to bypass the sign-in process when
                          you return to the site to sign in.
                        </Typography>
                      </Box>
                      <Divider />
                      <TextField
                        className={classes.textFieldStyle}
                        label="Idle Time*"
                        margin="normal"
                        name="idleTime"
                        variant="outlined"
                        type="number"
                        value={idletime}
                        InputProps={{
                          inputProps: { min: 1, pattern: "[0-9]*" },
                        }}
                        onChange={idletimeHandleChange}
                      />

                      <div className={classes.autoComStyle}>
                        <FormControl required>
                          <InputLabel>Preferred Date Format</InputLabel>
                          <Select
                            id="preferedDateFormat"
                            label="Preferred Date Format"
                            value={preferedDateFormat}
                            margin="normal"
                            onChange={handleChangeDate}
                          >
                            {dateFormatOptions.map(op => (
                              <MenuItem value={op.value}>{op.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      {errorMsg && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Preferred Date Format cannot be blank.
                          </FormHelperText>
                        </Box>
                      )}
                      <Box
                        mt={7}
                        mb={1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 135 }}
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  )}
                  {cardComponent === "resetPass" && (
                    <form onSubmit={handleSubmit(handleUpdatePassword)}>
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        label="Current Password*"
                        margin="normal"
                        name="currentPassword"
                        type="password"
                        variant="outlined"
                        {...register("currentPassword", {
                          required: true,
                        })}
                      />
                      {errors.currentPassword &&
                        errors.currentPassword.type === "required" && (
                          <Box ml={2}>
                            <FormHelperText className={classes.errorStyle} error>
                              Current Password cannot be blank.
                            </FormHelperText>
                          </Box>
                        )}

                      {passErrorMsg && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Please enter your current password correctly.
                          </FormHelperText>
                        </Box>
                      )}
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        label="New Password*"
                        margin="normal"
                        name="newPassword"
                        type="password"
                        variant="outlined"
                        {...register("newPassword", {
                          required: true,
                        })}
                      />
                      {errors.newPassword && errors.newPassword.type === "required" && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            New Password cannot be blank.
                          </FormHelperText>
                        </Box>
                      )}
                      <TextField
                        className={classes.textFieldStyle}
                        fullWidth
                        label="Re-enter New Password*"
                        margin="normal"
                        name="confirmNewPassword"
                        type="password"
                        variant="outlined"
                        {...register("confirmNewPassword", {
                          required: true,
                        })}
                      />
                      {errors.confirmNewPassword &&
                        errors.confirmNewPassword.type === "required" && (
                          <Box ml={2}>
                            <FormHelperText className={classes.errorStyle} error>
                              Re-enter New Password cannot be blank.
                            </FormHelperText>
                          </Box>
                        )}
                      {passConfirmErrorMsg && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyle} error>
                            Password and its repeat must be the same.
                          </FormHelperText>
                        </Box>
                      )}
                      <Box
                        mt={7}
                        mb={1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Box mr={2}>
                          <Button
                            className={classes.outlinedButtonStyle}
                            style={{ width: 135 }}
                            color="primary"
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                        </Box> */}
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 135 }}
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  )}
                  {cardComponent === "favTeam" && (
                    <>
                      <Typography gutterBottom style={{ fontSize: 18, fontWeight: 500 }}>
                        Teams
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            {allTeamsData.map(
                              elem =>
                                allTeamsData.indexOf(elem) < 10 && (
                                  <Grid
                                    item
                                    // xs={12}
                                    // sm={6}
                                    md={12}
                                    key={allTeamsData.indexOf(elem)}
                                  >
                                    <CardActionArea
                                      onClick={handleClickTeamCard(elem.sleagueTeamId)}
                                    >
                                      <Card
                                        className={
                                          elem.sleagueTeamId == selectedTeamId
                                            ? classes.teamCardSelected
                                            : classes.teamCard
                                        }
                                      >
                                        <CardContent
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box
                                            mt={0.5}
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <img
                                              src={
                                                elem.teamId.logo &&
                                                mediaPath + elem.teamId.logo
                                              }
                                              height={20}
                                              width={20}
                                              style={{ marginRight: 12 }}
                                            />
                                            <Typography variant="h3">
                                              {elem.teamId.name}
                                            </Typography>
                                          </Box>
                                          {elem.sleagueTeamId === selectedTeamId && (
                                            <CheckCircleOutlineIcon color="success" />
                                          )}
                                        </CardContent>
                                      </Card>
                                    </CardActionArea>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={2}>
                            {allTeamsData.map(
                              elem =>
                                allTeamsData.indexOf(elem) > 9 && (
                                  <Grid
                                    item
                                    // xs={12}
                                    // sm={6}
                                    md={12}
                                    key={allTeamsData.indexOf(elem)}
                                  >
                                    <CardActionArea
                                      onClick={handleClickTeamCard(elem.sleagueTeamId)}
                                    >
                                      <Card
                                        className={
                                          elem.sleagueTeamId == selectedTeamId
                                            ? classes.teamCardSelected
                                            : classes.teamCard
                                        }
                                      >
                                        <CardContent
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box
                                            mt={0.5}
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <img
                                              src={
                                                elem.teamId.logo &&
                                                mediaPath + elem.teamId.logo
                                              }
                                              height={20}
                                              width={20}
                                              style={{ marginRight: 12 }}
                                            />
                                            <Typography variant="h3">
                                              {elem.teamId.name}
                                            </Typography>
                                          </Box>
                                          {elem.sleagueTeamId === selectedTeamId && (
                                            <CheckCircleOutlineIcon color="success" />
                                          )}
                                        </CardContent>
                                      </Card>
                                    </CardActionArea>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box
                        mt={6}
                        mb={1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Box mr={2}>
                          <Button
                            className={classes.outlinedButtonStyle}
                            style={{ width: 135 }}
                            color="primary"
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                        </Box> */}
                        <Button
                          className={classes.buttonStyle}
                          style={{ width: 135 }}
                          color="primary"
                          variant="contained"
                          onClick={handleUpdateFavteam}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Dialog
            open={openDelDialog}
            onClose={handleCloseDelDialog}
            className={classes.dialog}
          >
            <DialogTitle style={{ fontSize: 28, fontWeight: 600 }}>
              Delete Account
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontSize: 18, fontWeight: 400, lineHeight: 1.7 }}
              >
                We are sorry to see you are leaving the Predictal platform. We would love
                to see that you stay. Is there anything we can do so that you stay? How
                about improving the Team Selector? Let us know please by sending an email
                to <a href="mailto:support@predictal.com">support@predictal.com. </a>{" "}
                <br />
                If you have made up your mind and you really wish to leave, we respect
                your decision. So, please confirm your departure by entering your account
                password and then click the ‘’Delete’’ button, and all your personal data
                will permanently be deleted. In case you changed your mind and you wish to
                stay, click the ‘’Cancel’’ button.
              </DialogContentText>
              <TextField
                className={classes.textFieldDelStyle}
                margin="dense"
                id="password"
                label="Your password"
                type="password"
                fullWidth
                variant="standard"
                value={delPassword}
                onChange={e => setDelPassword(e.target.value)}
              />
              {delPassError && (
                <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    Your entered password is incorrect.
                  </FormHelperText>
                </Box>
              )}
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                className={classes.outlinedButtonStyle}
                onClick={handleCloseDelDialog}
                style={{ width: 135 }}
                color="primary"
                variant="text"
              >
                Cancel
              </Button>
              <Button
                className={classes.outlinedRedButtonStyle}
                onClick={handleDeleteAccount}
                variant="outlined"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirmDelDialog}
            onClose={handleCloseConfirmDelDialog}
            className={classes.dialog}
          >
            <DialogContent>
              <DialogContentText
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.7,
                  marginTop: 15,
                }}
              >
                Your account and its personal information have permanently been removed
                from the Predictal platform. Thank you for staying with us. You can always
                return. Simply register again.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                className={classes.outlinedButtonStyle}
                onClick={handleCloseConfirmDelDialog}
                style={{ width: 135 }}
                color="primary"
                variant="outlined"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={userTsDlg}
            onClose={handleCloseUserTsDlg}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Info</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {userTsMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={proceedUsrTs}>Proceed</Button>
              <Button onClick={handleCloseUserTsDlg} autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
}
