import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext,useManualQuery } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, styled } from "@material-ui/core";
import {project_url, admin_url} from "../store/data/Constants";

import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from '@mui/material/FormControlLabel';

import { useNavigate, useParams } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";

import {GET_AWARD_QUERY,
        EDIT_AWARD_MUTATION} from "../graphQL/award_qry";

export default function SleagueAwardEdit() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  const { awardId }  = useParams();

    
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  // Set award value

  const [leagueName, setLeagueName] = useState("");
  const [sleagueName, setSleagueName] = useState("");
  const [sleagueId, setSleagueId] = useState("");

  const [awardName, setAwardName] = useState("");
  
  const [SP, setSP] = useState("");
  const [active, setActive] = useState("");

  const [editedAward, setEditedAward] = useState("");

  const [editAward, 
      { loadingEdit, errorEdit }] = useMutation(EDIT_AWARD_MUTATION);

  const {
    loading: awardLoading,
    error: awardError,
    data: awardData,
  } = useQuery(GET_AWARD_QUERY, {
    variables: {
      awardId:parseInt(awardId),
    },
  });

  useEffect(()=>{
    if (!awardLoading && awardData && awardData.awardObject){

      setEditedAward(awardData.awardObject);


      if(awardData.awardObject.sleagueId){
        setLeagueName(awardData.awardObject.sleagueId.leagueId.title)
        setSleagueName(awardData.awardObject.sleagueId.seasonName)
        setSleagueId(awardData.awardObject.sleagueId.sleagueId)

      }

      if(awardData.awardObject.name){
        setAwardName(awardData.awardObject.name)
        setValue("awardName", awardData.awardObject.name);
      }

      if(awardData.awardObject.sp){
        setSP(awardData.awardObject.sp)
        setValue("SP", awardData.awardObject.sp);
      }

      if(awardData.awardObject.active !== null){
        setActive(awardData.awardObject.active)  
        setValue("active", awardData.awardObject.active);    
      }
    }
  },
  [awardData]
  );
 
 
  //
  const onSubmit = async formData => {
    if(formData.awardName.indexOf("Post") != -1){
        if(window.confirm("The value for this Post will be applied to other three Posts to keep all four Posts as same value. Do you want to save your changes?")){
            const {data, error} = await editAward( {
                variables:{
                    awardId: parseInt(awardId),	
                    sleagueId: parseInt(sleagueId),		
                    name: formData.awardName,
                    sp: parseInt(formData.SP),
                    active: active,
                }
            });

            if (error){
                alert(error.graphQLErrors[0].message);
                console.log(error.graphQLErrors[0].message);
            }
            else{
                alert("The existing Award record has been edited!");
                // localStorage.setItem("ReturnFromEditPage", true);
                navigate(`/lms/${admin_url}/sleague-award`);
            }
        }
    }
    else{
        const {data, error} = await editAward( {
            variables:{
                awardId: parseInt(awardId),	
                sleagueId: parseInt(sleagueId),		
                name: formData.awardName,
                sp: parseInt(formData.SP),
                active: active,
            }
        });

        if (error){
            alert(error.graphQLErrors[0].message);
            console.log(error.graphQLErrors[0].message);
        }
        else{
            alert("The existing Award record has been edited!");
            // localStorage.setItem("ReturnFromEditPage", true);
            navigate(`/lms/${admin_url}/sleague-award`);
        }
    }
  };

  const selectActive = (e) =>{
    setActive(e.target.checked);
  }

  return (
    <>
      <Helmet>
        <title>Predictal | Edit Award | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Edit Award</h2>

          <label className={adminStyle.label} htmlFor="league">
              League
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id="league"
              {...register("league")}
              disabled={true}
              
            >
              <option value="0">Select a League...</option>
              <option key={1} value={leagueName}
              selected="selected"
              
              >
                  {leagueName}
                </option>
             
            </select> 

            <label className={adminStyle.label} htmlFor="sleague">
              League Season
            </label>
            <select
              className={adminStyle.selectWithLabel}
              id="sleague"
              {...register("sleague")}
              disabled={true}
            >
              <option value="0">Select a Season...</option>
              <option key={1} value={sleagueName}
              selected="selected"
              
              >
                  {sleagueName}
                </option>
            </select>    

          <label className={adminStyle.label} htmlFor="awardName">
            Award Name
          </label>
          <input
            className={adminStyle.input}
            id = "awardName"
            {...register("awardName", { required: true })} defaultValue={awardName}
          />
          {errors.awardName && errors.awardName.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="SP">
            Sport Points
          </label>
          <input
            className={adminStyle.input}
            id = "SP"
            {...register("SP", { required: true })} defaultValue={SP}
          />
          {errors.SP && errors.SP.type === "required" && (
            <span>Required</span>
          )}
          
          <FormControlLabel
            control={<Checkbox checked={active} onChange={selectActive} />}
            className={adminStyle.label}
            label="Active"
          />        

          <input className={adminStyle.input} type="submit" />
        </form>
      </Grid>
    </>
  );
}
