import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";

import {
  LOCK_FIXTURE_MUTATION ,
  GET_WEEK_FIXTURE_TEAMS,
  GET_SLEAGUE_FIXTURE_TEAMS,
} from "../graphQL/fixture_qry";

import { GET_SLEAGUE_WEEK_QUERY } from "../graphQL/week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "./Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function FixtureList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [teamsAll, setTeamsAll] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);
  const [selectedSleague, setSelectedSleague] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeksData, setWeeksData] = useState([]);

  const navigate = useNavigate();
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchWeeks] = useManualQuery(GET_SLEAGUE_WEEK_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchTeams] = useManualQuery(GET_WEEK_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });
  const [fetchTeamsBySleague] = useManualQuery(GET_SLEAGUE_FIXTURE_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [lockFixture] = useManualQuery(LOCK_FIXTURE_MUTATION, {
    fetchPolicy: "network-only"
  })

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);



  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (leagueError && leagueError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);

  const handleLeagueChange = async e => {
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData,
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid),
      },
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSLeagueChange = async e => {
    const tid = e.target.value;
    setSelectedSleague(parseInt(tid));
    const {
      loading: weekLoading,
      error: weekError,
      data: weekData,
    } = await fetchWeeks({
      variables: {
        sleagueId: parseInt(tid),
      },
    });

    if (!weekLoading && weekData && weekData.sleagueWeek) {
      setWeeksData(weekData.sleagueWeek);
      if(weekData.sleagueWeek.length>0){
        setSelectedWeek(weekData.sleagueWeek[0].weekId);
        handleWeekChange(weekData.sleagueWeek[0].weekId);
      }
    }
    if (weekError && weekError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleWeekChange = async tid => {
    // const tid = JSON.parse(e.target.value);
    setSelectedWeek(tid);
    if (tid && tid !== "0") {
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeams({
        variables: {
          weekId: parseInt(tid),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.weekFixtureTeams) {
        setTeamsAll([]);
        let teams = allTeamsData.weekFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });

        setTeamsAll(ars);
      }
      if (teamError) {
        let ars = [];
        setTeamsAll(ars);
      }
    } else {
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData,
      } = await fetchTeamsBySleague({
        variables: {
          sleagueId: parseInt(selectedSleague),
        },
      });

      if (!teamLoading && allTeamsData && allTeamsData.sleagueFixtureTeams) {
        setTeamsAll([]);
        let teams = allTeamsData.sleagueFixtureTeams;

        let ars = [];
        teams.map(team => {
          let a = team.fixtureId.fixtureId.toString();
          if (ars[a] === undefined) {
            ars[a] = [];
            ars[a].push(team);
          } else {
            ars[a].push(team);
          }
        });

        setTeamsAll(ars);
      }
    }
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const dayFormat = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };

  const DateTime = () => {

    var [date,setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }

    });

    return(
        <div>
            <p> Current Date: {date.toDateString()} - {date.toLocaleTimeString()}

	</p>

        </div>
    )
}


  /*
   * Function : updateFixture
   * Description : locking/unlock fixture
   */
  const updateFixture = async (fixtureId,lock) => {
    await sleep(2000);

	const { data, error } = await lockFixture({
        variables: {
          fixtureId: parseInt(fixtureId),
		  lock : lock
        }
      });

      if (error) {
        console.log("Error", error, data);
        setFailedMessage("Unfortunately an error occurred.");
        setTimeout("");

      } else if (data && data.lockFixture) {

        setSuccessMessage("fixture lock/unlock successfully.");

        setTimeout("");
        window.location.reload();
      }

  };

  const columns = [
    {
      field: "fixture",
      headerName: "Fixture",

      width: 300,
      editable: false,
      renderCell: teams => {
        if (teams.value[0].home)
          return (

              <>
              {teams.value[0].sleagueTeamId.teamId.name} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}) - {"  "}
              {teams.value[1].sleagueTeamId.teamId.name} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}){" "}

            </>
          );
        else
          return (

             <>
              {teams.value[1].sleagueTeamId.teamId.name} {teams.value[1].scoreFinal} (
              {teams.value[1].scoreHalf1}) - {"  "}
              {teams.value[0].sleagueTeamId.teamId.name} {teams.value[0].scoreFinal} (
              {teams.value[0].scoreHalf1}){" "}

            </>
          );
      },
    },

	{
      field: "fixtureDateUTC",
      headerName: "Fixture Date (UTC)",

      width: 200,
      editable: false,
      renderCell: team => {
        return <>{team.value.fixtureId.utcDate}</>;
      }
    },

    {
      field: "fixtureDate",
      headerName: "Fixture Date (Local)",

      width: 200,
      editable: false,
      renderCell: team => {
        return <>{dayFormat(team.value.fixtureId.fixtureWeek.scheduledDate)}</>;
      }
    },

	{
      field: "embargoDate",
      headerName: "Embargo Date (Local)",

      width: 200,
      editable: false,
      renderCell: team => {
        return <>{dayFormat(team.value.fixtureId.embargoDate)}</>;
      }
    },

    {
      field: "action",
      headerName: "Action",

      width: 200,
      editable: false,
      renderCell: team => (
        <>
          {team.value.fixtureId.lock ? (

            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled={false}
			  onClick={() => updateFixture(team.value.fixtureId.fixtureId,false)

              }
            >
			Unlock
            </Button>
			) : (
			<Button
              className={adminStyle.button}
              variant="contained"
              color="secondary"
              fullWidth={true}
              disabled={false}
			  onClick={() => updateFixture(team.value.fixtureId.fixtureId,true)

              }
            >
			lock
            </Button>
			)}
        </>
      ),
    },

  ];

  const rows = teamsAll.map(tm => ({
    id: tm[0].fixtureId.fixtureId,
    fixture: tm,
	fixtureDateUTC: tm[0],
    fixtureDate: tm[0],
	embargoDate: tm[0],
	action: tm[0],

  }));

  return (
    <>
      <Helmet>
        <title>Predictal | Lock Fixture | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2>List of Fixtures</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League{" "}
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleLeagueChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                League Season
              </label>
              <select
                className={adminStyle.select}
                id="season"
                {...register("sleague")}
                onChange={handleSLeagueChange}
              >
                <option value="0">Select a Season...</option>
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="week">
                Week
              </label>
              <select
                className={adminStyle.select}
                id="week"
                {...register("week")}
                value={selectedWeek}
                onChange={e =>handleWeekChange(e.target.value)}
              >
                <option value="-1">Select a Week...</option>

                {weeksData.map((week, i) => (
                  <option key={i} value={week.weekId}>
                    {week.name}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
  		<DateTime></DateTime>
            <DataGrid rows={rows} columns={columns} autoHeight={true} />
          </Grid>


        </Grid>
      </Grid>
    </>
  );
}
