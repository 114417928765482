import React from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import booked from "../../../../assets/StatusImages/booked.gif";
import suspended from "../../../../assets/StatusImages/suspended.gif";
import injured from "../../../../assets/StatusImages/injured.png";
import unavailable from "../../../../assets/StatusImages/unavailable.png";
import available from "../../../../assets/StatusImages/available.png";
import selected from "../../../../assets/StatusImages/selected.png";
import sick from "../../../../assets/StatusImages/sick.png";

import staron from "../../../../assets/BioImages/staron.png";
import staroff from "../../../../assets/BioImages/staroff.png";

const useStyles = makeStyles({
  root: {
    fontSize: 20,
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FBF3FF",
    },
  },
});

const pos = {
  Goalkeeper: "GK",
  Defender: "DEF",
  Midfielder: "MF",
  Forward: "FOR",
};
const Bio = ({ players, head2headData }) => {
  console.log("players---------head", players);

  const mainPath = "/lms/media/";

  const classes = useStyles();

  let leftPlayer = players.find(item => item.playerSeqNO === head2headData[0]);

  let rightPlayer = players.find(item => item.playerSeqNO === head2headData[1]);

  // This piece should be removed after reading from database ( start )
  var currentTime = new Date();
  leftPlayer.playerId.age =
    currentTime.getFullYear() - parseInt(leftPlayer.playerId.birthDate.split("-")[0]);

  rightPlayer.playerId.age =
    currentTime.getFullYear() - parseInt(rightPlayer.playerId.birthDate.split("-")[0]);
  // This piece should be removed after reading from database ( end )

  // Player games list
  const bios = [
    {
      name: "Height (cm)",
      lBio: leftPlayer.playerId.height,
      rBio: rightPlayer.playerId.height,
    },
    {
      name: "Weight (kg)",
      lBio: leftPlayer.playerId.weight,
      rBio: rightPlayer.playerId.weight,
    },
    {
      name: "Age",
      lBio: leftPlayer.playerId.age,
      rBio: rightPlayer.playerId.age,
    },
    {
      name: "Foot",
      lBio: leftPlayer.playerId.foot,
      rBio: rightPlayer.playerId.foot,
    },
  ];

  const columns = [
    {
      field: "leftPlayerBio",
      width: 55,
      align: "center",
      renderCell: params => {
        return <Typography variant="h5">{params.value}</Typography>;
      },
    },
    {
      field: "bios",
      width: 80,
      align: "center",
      renderCell: params => {
        return (
          <Typography variant="h4" style={{ fontWeight: 700 }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "rightPlayerBio",
      width: 55,
      align: "center",
      renderCell: params => {
        return <Typography variant="h5">{params.value}</Typography>;
      },
    },
  ];
  const rows = bios.map((bio, id) => ({
    id: id,
    leftPlayerBio: bio.lBio,
    bios: bio.name,
    rightPlayerBio: bio.rBio,
  }));
  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        //  spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3} sm={3} md={3}>
          <Box>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 40, height: 40 }}
                src={
                  leftPlayer.playerId.headImage
                    ? mainPath + leftPlayer.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Box mr={0.5}>
              <Typography variant="h6" style={{ textAlign: "center", color: "#212121" }}>
                {leftPlayer.jerseyNO} {leftPlayer.playerId.name}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {leftPlayer.playerId.position}
              </Typography>
            </Box>
            <Box mt={1} style={{ textAlign: "center" }}>
              {leftPlayer.status === "Unavailable" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={unavailable}
                />
              )}
              {leftPlayer.status === "Fit" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={available}
                />
              )}
              {leftPlayer.status === "Booked" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={booked}
                />
              )}
              {leftPlayer.status === "Suspended" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={suspended}
                />
              )}
              {leftPlayer.status === "Injured" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={injured}
                />
              )}
              {leftPlayer.status === "Sick" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={sick}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Box
            className={classes.root}
            style={{
              // marginRight: "20px",
              // marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              rowHeight={48}
              headerHeight={0}
            />
          </Box>
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Box>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 40, height: 40 }}
                src={
                  rightPlayer.playerId.headImage
                    ? mainPath + rightPlayer.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Box ml={0.5}>
              <Typography variant="h6" style={{ textAlign: "center", color: "#212121" }}>
                {rightPlayer.jerseyNO} {rightPlayer.playerId.name}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {rightPlayer.playerId.position}
              </Typography>
              <Box mt={1} style={{ textAlign: "center" }}>
                {rightPlayer.status === "Unavailable" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={unavailable}
                  />
                )}
                {rightPlayer.status === "Fit" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={available}
                  />
                )}
                {rightPlayer.status === "Booked" && (
                  <img
                    style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                    src={booked}
                  />
                )}
                {rightPlayer.status === "Suspended" && (
                  <img
                    style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                    src={suspended}
                  />
                )}
                {rightPlayer.status === "Injured" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={injured}
                  />
                )}
                {rightPlayer.status === "Sick" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={sick}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Box mt={3} style={{ textAlign: "center" }}>
            <Box mb={2}>
              <Typography variant="h5" style={{ color: "#765285" }}>
                ATTRIBUTES
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrPower)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(leftPlayer.playerId.attrPower)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Power</Typography>
              </Box>
              <Box>
                {Array.from(Array(parseInt(rightPlayer.playerId.attrPower)), (e, i) => {
                  return <img src={staron} style={{ marginLeft: "5px" }} />;
                })}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrPower)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrSpeed)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(leftPlayer.playerId.attrSpeed)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Speed</Typography>
              </Box>
              <Box>
                {Array.from(Array(parseInt(rightPlayer.playerId.attrSpeed)), (e, i) => {
                  return <img src={staron} style={{ marginLeft: "5px" }} />;
                })}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrSpeed)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrTechnical)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(
                  Array(parseInt(leftPlayer.playerId.attrTechnical)),
                  (e, i) => {
                    return <img src={staron} style={{ marginRight: "5px" }} />;
                  }
                )}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Technique</Typography>
              </Box>
              <Box>
                {Array.from(
                  Array(parseInt(rightPlayer.playerId.attrTechnical)),
                  (e, i) => {
                    return <img src={staron} style={{ marginLeft: "5px" }} />;
                  }
                )}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrTechnical)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrStrategy)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(leftPlayer.playerId.attrStrategy)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Strategy</Typography>
              </Box>
              <Box>
                {Array.from(
                  Array(parseInt(rightPlayer.playerId.attrStrategy)),
                  (e, i) => {
                    return <img src={staron} style={{ marginLeft: "5px" }} />;
                  }
                )}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrStrategy)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrAttack)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(leftPlayer.playerId.attrAttack)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Attack</Typography>
              </Box>
              <Box>
                {Array.from(Array(parseInt(rightPlayer.playerId.attrAttack)), (e, i) => {
                  return <img src={staron} style={{ marginLeft: "5px" }} />;
                })}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrAttack)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                {Array.from(
                  Array(3 - parseInt(leftPlayer.playerId.attrDefense)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginRight: "5px" }} />;
                  }
                )}
                {Array.from(Array(parseInt(leftPlayer.playerId.attrDefense)), (e, i) => {
                  return <img src={staron} style={{ marginRight: "5px" }} />;
                })}
              </Box>
              <Box style={{ width: 90 }}>
                <Typography variant="h5">Defense</Typography>
              </Box>
              <Box>
                {Array.from(Array(parseInt(rightPlayer.playerId.attrDefense)), (e, i) => {
                  return <img src={staron} style={{ marginLeft: "5px" }} />;
                })}
                {Array.from(
                  Array(3 - parseInt(rightPlayer.playerId.attrDefense)),
                  (e, i) => {
                    return <img src={staroff} style={{ marginLeft: "5px" }} />;
                  }
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Bio;
