import React, { useState, useEffect, useContext } from "react";

import {
  useMutation,
  ClientContext,
  useManualQuery,
  useQuery,
} from "graphql-hooks";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Link as RouterLink } from "react-router-dom";

import {
  gridDateComparator,
  gridNumberComparator,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  //Tooltip,
  Icon,
  makeStyles,
} from "@material-ui/core";

import { styled } from "@mui/material/styles";

import { IconButtonProps } from "@mui/material/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import delayedIcon from "../../assets/delayed.png";

import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../../graphQL/user_qry";
import {
  FAV_TEAMS_FIXTURES_QUERY,
  GET_FIXTURETEAM_TEAMS,
} from "../../graphQL/fixture_qry";

import { CURRENT_LEAGUE_SEASONS_QUERY } from "../../graphQL/current_bucket_sleague_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../../graphQL/sleague_qry";
import {
  GET_ALL_SETTINGS_QUERY,
  GET_SERVER_DATE_TIME,
} from "../../graphQL/setting_qry";

import { project_url, media_url } from "../../store/data/Constants";

import AlertDialog from "../../components/AlertDialog.js";

import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  typo: {
    flexGrow: 1,
    textAlign: "center",
  },

  mobileTypo: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: 24,
  },

  cardContainer: {
    paddingBottom: 2,
    paddingTop: 40,
    maxWidth: 1290,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
    maxWidth: 360,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  dateStyle: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  dateStyleMobile: {
    "& .MuiTypography-body2": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleTablet: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 16,
    },
  },
  autoComStyleMobile: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldDelStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldDelStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  textFieldStyle: {
    width: "60%",
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },

  listItemOdd: {
    minHeight: 100,
    marginBottom: 8,
  },
  listItemEven: {
    backgroundColor: "#FBF3FF",
    minHeight: 100,
    marginBottom: 8,
  },

  listItemOddTablet: {
    minHeight: 260,
    marginBottom: 8,
  },
  listItemEvenTablet: {
    backgroundColor: "#FBF3FF",
    minHeight: 260,
    marginBottom: 8,
  },

  listItemOddMobile: {
    minHeight: 244,
    marginBottom: 8,
  },

  listItemEvenMobile: {
    backgroundColor: "#FBF3FF",
    minHeight: 244,
    marginBottom: 8,
  },

  gridMargin: {
    marginLeft: 30,
  },

  buttonGrid: {
    paddingLeft: 2,
  },

  listItemText: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      "&:hover": {
        color: "#765285",
      },
    },
  },

  listItemTextSignout: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 600,
      color: "#d32f2f",
    },
  },

  ListItemButton: {
    height: 56,
    "&:hover": {
      borderLeft: "2px solid #765285",
      paddingLeft: "14px",
    },
  },

  switch: {
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#765285",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#765285",
    },
  },
  teamCardSelected: {
    height: "56px",
    border: "1px solid #10A339",
    borderRadius: "8px",
    backgroundColor: "#F7F4F7",
  },
  teamCard: {
    height: "56px",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
  },
  dialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "935px",
      },
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: 1.4,
  },

  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },

  textButtonStyle: {
    textTransform: "none",
    fontWeight: 700,
    fontSize: 14,
    padding: 0,
    margin: 0,
    textDecorationLine: "underline",
    textAlign: "Right",
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  textButtonTabletStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 0,
    textAlign: "Center",
    marginLeft: 25,

    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  textButtonMobileStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 0,
    marginLeft: 0,
    textAlign: "center",

    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  firstTextButtonStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 20,
    marginLeft: 7,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  firstTextButtonMobileStyle: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: 14,
    height: "48px",
    padding: 2,
    marginLeft: 1,
    "&:active": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  outlinedRedButtonStyle: {
    width: 140,
    borderRadius: 8,
    color: "#E0281D",
    borderColor: "#E0281D",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  arrowIconStyle: {
    width: 20,
    height: 20,
    padding: 14,
    backgroundColor: "#F8F8F8",
    borderRadius: 8,
  },

  listItemTextDate: {
    fontSize: 14,
    fontWeight: 400,
    color: "block",
    textAlign: "center",
    paddingBottom: "20px",
  },

  mobileListItemTextDate: {
    fontSize: 12,
    fontWeight: 400,
    color: "block",
    textAlign: "center",
    paddingBottom: "10px",
  },

  ContentCenter: {
    "& .MuiTypography-body1": {
      textAlign: "center",
    },
  },

  textStyle1: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#4D4D4D",
  },
  textStyle2: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
    color: "#4D4D4D",
  },
  textStyle3: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#4D4D4D",
    textAlign: "center",
  },

  textStyle4: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#4D4D4D",
  },

  textStyle5: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#757575",
  },

  textStyle6: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#4D4D4D",
    paddingTop: "30 px",
  },

  textStyle7: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#4D4D4D",
    paddingTop: "30 px",
  },
  textStyle8: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#4D4D4D",
    textAlign: "center",
  },

  linkStyle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },

  gridBorder: {
    borderLeft: "1px solid #EAEDED",
    borderRadius: 5,
  },

  selectStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      height: 56,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 14,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 56,
      width: 328,
      fontSize: 14,
      backgroundColor: "White",
      color: "#212121",
    },
  },
}));

const timeOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  //second: "2-digit",
  hour12: true,
};
const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: '37px 0px 0px -15px'
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 10px;
  }
`;
const FIXTURE_TEAM_SEQs_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    coachSeq
  }
}`;

const USERTS_QUERY = `query userTSByFixtureteamid($fixtureTeamId:Int!){
  userTSByFixtureteamid(fixtureTeamId:$fixtureTeamId){
    userTSId
    userSeq
  }
}`;

const FIXTURES_TOTALVOTESANDFORMATION_QUERY = `query favTeamsTotalVotsAndFormation($userFavTeamSeq:String!){
  favTeamsTotalVotsAndFormation(userFavTeamSeq:$userFavTeamSeq){
    fixtureTeamId
    totalVots
    popularFormation
  }
}`;

const COMMUNITY_SEQ_QUERY = `query favTeamCommunityTS($fixtureTeamId:Int!, $algorithmId:Int!){
  favTeamCommunityTS(fixtureTeamId:$fixtureTeamId, algorithmId: $algorithmId){
    communitySeq
  }
}`;

const FavTeamFixtures = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1099 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const navigate = useNavigate();
  const client = useContext(ClientContext);

  const mediaPath = `${media_url}`;

  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [embargoTime, setEmbargoTime] = useState(0);
  const [userFavTeamSeq, setUserFavTeamSeq] = useState("");
  const [sortModel, setSortModel] = React.useState([
    {
      field: "week",
      sort: "asc",
    },
  ]);

  const [favTeamsAllFixtures, setFavTeamsAllFixtures] = useState({});
  const [favTeamsFixturesId, setFavTeamsFixturesId] = useState({});
  const [sleagueTeamsName, setSleagueTeamsName] = useState({});
  const [sleagueTeamsLogo, setSleagueTeamsLogo] = useState({});
  const [favTeamsFixturesVotes, setFavTeamsFixturesVotes] = useState({});
  const [favTeamsFixturesPopForms, setFavTeamsFixturesPopForms] = useState({});
  const [favTeamsFixturesPopFormsNo, setFavTeamsFixturesPopFormsNo] = useState(
    {}
  );
  const [fixtureId2FavTeamSleagueId, setFixtureId2FavTeamSleagueId] = useState(
    {}
  );
  const [alertMsg, setAlertMsg] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSLeagueId, setCurrentSLeagueId] = useState(null);

  const [favTeamSelectionValue, setFavTeamSelectionValue] = useState("all");
  const [serverTime, setServerTime] = useState("");
  const [pathname] = useState(window.location.pathname);

  const [fixtureTeams] = useManualQuery(GET_FIXTURETEAM_TEAMS);
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const [favTeamFixturesTeams] = useManualQuery(FAV_TEAMS_FIXTURES_QUERY);

  // Get "coachSeq" and value for a fixture team
  const [fixtureTeamSeqs] = useManualQuery(FIXTURE_TEAM_SEQs_QUERY);

  // Get "communitySeq" value for a fixture team
  const [communityTsSeq] = useManualQuery(COMMUNITY_SEQ_QUERY);

  // Get "userTS" value for a fixture team
  const [userTS] = useManualQuery(USERTS_QUERY);

  // Get "Votes" and "Popular Formation" for all fixtures
  const [favTeamsFixturesTotalVotsAndFormation] = useManualQuery(
    FIXTURES_TOTALVOTESANDFORMATION_QUERY
  );
  //Sahar RTS-943
  const {
    loading: loadingCurBucketSlg,
    error: errorCurBucketSlge,
    data: dataCurBucketSlg,
  } = useQuery(CURRENT_LEAGUE_SEASONS_QUERY);
  //
  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY);

  // Get Setting value; need to find embargo time
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: dataSettings,
  } = useQuery(GET_ALL_SETTINGS_QUERY);

  const {
    loading: loadingServerTime,
    error: errorServerTime,
    data: dataServerTime,
  } = useQuery(GET_SERVER_DATE_TIME, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loadingServerTime && dataServerTime.serverDateTime) {
      console.log("GetServerDateTime____", dataServerTime.serverDateTime);
      var local = new Date(dataServerTime.serverDateTime);
      var offset = local.getTimezoneOffset();
      var utc = new Date(local.getTime() - offset * 60000);
      setServerTime(utc);
    }
    if (
      errorSettings &&
      errorSettings.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataServerTime, data]);

  // Samira - 1
  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });

  const dateFormat = (day) => {
    const dayDate = new Date(day);
    const dateFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dateFormat.find((a) => a.type == "weekday").value +
        ", " +
        dateFormat.find((a) => a.type == "day").value +
        " " +
        dateFormat.find((a) => a.type == "month").value +
        ", " +
        dateFormat.find((a) => a.type == "year").value;

      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dateFormat.find((a) => a.type == "weekday").value +
        ", " +
        dateFormat.find((a) => a.type == "month").value +
        " " +
        dateFormat.find((a) => a.type == "day").value +
        ", " +
        dateFormat.find((a) => a.type == "year").value;

      return newFormat;
    } else {
      const newFormat =
        dateFormat.find((a) => a.type == "weekday").value +
        ", " +
        dateFormat.find((a) => a.type == "year").value +
        ", " +
        dateFormat.find((a) => a.type == "month").value +
        " " +
        dateFormat.find((a) => a.type == "day").value;

      return newFormat;
    }
  };

  const hourFormat = (day) => {
    const dayDate = new Date(day);
    const hourFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";

    const newFormat =
      hourFormat.find((a) => a.type == "hour").value +
      ":" +
      hourFormat.find((a) => a.type == "minute").value +
      " " +
      hourFormat.find((a) => a.type == "dayPeriod").value;
    return newFormat;
  };
  // Samira 1 - End

  // Check Valid User
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      // console.log(err);
    },
  });

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate("/" + project_url + "/signin");
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate("/" + project_url + "/signin");
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/" + project_url + "/signin");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  // Set current user data
  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
      setUserFavTeamSeq(data.profile.userFavTeamField.favTeamSeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate("/" + project_url + "/signin");
    }
  }, [data]);

  //Sahar RTS-943
  useEffect(() => {
    if (
      !loadingCurBucketSlg &&
      dataCurBucketSlg &&
      dataCurBucketSlg.currentBucketSleague
    ) {
      setCurrentSLeagueId(
        dataCurBucketSlg.currentBucketSleague[0].sleagueId.sleagueId
      );
    }
  }, [dataCurBucketSlg]);
  //

  useEffect(() => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      let teamName = {};
      let teamLogo = {};
      dataSleague.allSleagueTeam.map((sleagueteam) => {
        if (
          //Sahar RTS-943
          currentSLeagueId &&
          sleagueteam.sleagueId.sleagueId === currentSLeagueId &&
          //
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
        }
        teamName[sleagueteam.sleagueTeamId] = sleagueteam.teamId.shortName;
        teamLogo[sleagueteam.sleagueTeamId] = sleagueteam.teamId.logo;
      });
      setSleagueTeamsName(teamName);
      setSleagueTeamsLogo(teamLogo);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate("/" + project_url + "/signin");
    }
  }, [dataSleague, data]);

  useEffect(async () => {
    let favTeamSeqInfo = userFavTeamSeq.split("#");
    let favTeamsFixturesSeq = {};
    let fixtureId2FavTeam = {};
    for (let i = 0; i < favTeamSeqInfo.length; i++) {
      let vals = favTeamSeqInfo[i].split("&");
      let favTeamId = vals[0].slice(2);
      let fixtureId = vals[1];

      if (favTeamId in favTeamsFixturesSeq) {
        favTeamsFixturesSeq[favTeamId].push(fixtureId);
      } else {
        favTeamsFixturesSeq[favTeamId] = [fixtureId];
      }
      fixtureId2FavTeam[fixtureId] = favTeamId;
    }
    setFavTeamsFixturesId(favTeamsFixturesSeq);
    setFixtureId2FavTeamSleagueId(fixtureId2FavTeam);

    let { loading, error, data } = await favTeamFixturesTeams({
      variables: { userFavTeamSeq },
    });
    if (!loading && data && data.favTeamsFixturesObjectTeams) {
      let favTeamsFixtures = {};

      data.favTeamsFixturesObjectTeams.map((team) => {
        let fixtureId = " " + team.fixtureId.fixtureId.toString();
        //Sahar RTS-943
        if (team.sleagueTeamId.sleagueId.sleagueId !== currentSLeagueId) return;
        //
        if (fixtureId in favTeamsFixtures) {
          favTeamsFixtures[fixtureId].push(team);
        } else {
          favTeamsFixtures[fixtureId] = [team];
        }
      });
      setFavTeamsAllFixtures(favTeamsFixtures);
      setTeamsAll(Object.values(favTeamsFixtures));
    }

    let {
      loading: loadingVotsFors,
      error: errorVotsFors,
      data: dataVotsFors,
    } = await favTeamsFixturesTotalVotsAndFormation({
      variables: { userFavTeamSeq },
    });

    if (
      !loadingVotsFors &&
      dataVotsFors &&
      dataVotsFors.favTeamsTotalVotsAndFormation
    ) {
      let favTeamsVots = {};
      let favTeamsFors = {};
      let favTeamsForNos = {};
      dataVotsFors.favTeamsTotalVotsAndFormation.map((votsFors) => {
        favTeamsVots[votsFors.fixtureTeamId] = votsFors.totalVots;
        favTeamsFors[votsFors.fixtureTeamId] =
          votsFors.popularFormation === "Stay Tuned"
            ? ""
            : votsFors.popularFormation.split(" ", 1)[0];
        favTeamsForNos[votsFors.fixtureTeamId] =
          votsFors.popularFormation === "Stay Tuned"
            ? votsFors.popularFormation
            : votsFors.popularFormation.split(" ").slice(1).join(" ");
      });
      setFavTeamsFixturesVotes(favTeamsVots);
      setFavTeamsFixturesPopForms(favTeamsFors);
      setFavTeamsFixturesPopFormsNo(favTeamsForNos);
    }
  }, [userFavTeam, currentSLeagueId]);

  // Get "Embargo Time" from DB
  useEffect(() => {
    if (!loadingSettings && dataSettings && dataSettings.allSettings) {
      const findSetting = dataSettings.allSettings.find(
        (setting) => setting.name === "Embargo Time (in hours)"
      );
      findSetting !== undefined
        ? setEmbargoTime(findSetting.value)
        : setEmbargoTime(4);
    }
  }, [dataSettings]);

  //RTS-1149 Sahar
  // number 2 represents two hours after the start of the fixture. 95% of the fixtures finish within 2 hours after the fixture start time.
  const isConclude = (fixture) => {
    if (
      serverTime.getTime() /*Date.now()*/ >
      new Date(fixture.fixtureWeek.scheduledDate).getTime() + 2 * 3600000
    )
      return true;
    else return false; /*not conclude*/
  };

  const isEmbargo = (fixture) => {
    // Time remaining until the start of the fixture
    //   let timeDiff =
    //     new Date(fixture.fixtureWeek.scheduledDate).getTime() - Date.now();
    //
    //   if (
    //     Date.now() < new Date(fixture.fixtureWeek.scheduledDate).getTime() ||
    //     timeDiff > embargoTime * 3600000
    //   )
    //     /*not embargo*/ return false;
    //   else return true;

    let fixDate = new Date(fixture.fixtureWeek.scheduledDate).getTime();
    if (
      serverTime.getTime() /*Date.now()*/ > fixDate - embargoTime * 3600000 &&
      serverTime.getTime() /*Date.now()*/ < fixDate + 2 * 3600000
    )
      return true;
    else return false; /*not embargo*/
  };
  // Sahar's changes

  const chkCoachComDataIsAvailable = async (fixtureTeamId, teamType) => {
    if (teamType === "Community") {
      let { loading, error, data } = await communityTsSeq({
        variables: {
          fixtureTeamId: parseInt(fixtureTeamId),
          algorithmId: 1,
        },
      });
      if (data.favTeamCommunityTS && data.favTeamCommunityTS.communitySeq)
        return true;
      else return false;
    } else if (teamType === "Coach") {
      let { loading, error, data } = await fixtureTeamSeqs({
        variables: {
          fixtureTeamId: parseInt(fixtureTeamId),
        },
      });
      if (data.fixtureTeamById && data.fixtureTeamById.coachSeq) return true;
      else return false;
    } else {
      return false;
    }
  };

  const chkUserSeqDataIsAvailable = async (fixtureTeamId) => {
    let { loading, error, data } = await userTS({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });

    if (!loading && data && data.userTSByFixtureteamid) {
      return true;
    } else return false;
  };

  const fixturesComparator = (v1, v2) => {
    let home = v1[0];
    let guest = v1[1];
    if (v1[1].home) {
      home = v1[1];
      guest = v1[0];
    }
    let tFixRow1 =
      home.sleagueTeamId.teamId.shortName +
      home.scoreFinal +
      " - " +
      guest.sleagueTeamId.teamId.shortName +
      guest.scoreFinal;

    home = v2[0];
    guest = v2[1];
    if (v2[1].home) {
      home = v2[1];
      guest = v2[0];
    }
    let tFixRow2 =
      home.sleagueTeamId.teamId.shortName +
      home.scoreFinal +
      " - " +
      guest.sleagueTeamId.teamId.shortName +
      guest.scoreFinal;

    return tFixRow1.localeCompare(tFixRow2);
  };

  const rows = teamsAll.map((tm, index) => {
    let favTeamSleagueId =
      fixtureId2FavTeamSleagueId[tm[0].fixtureId.fixtureId];
    let fixtureTeamId =
      tm[0].sleagueTeamId.sleagueTeamId === favTeamSleagueId
        ? tm[0].fixtureTeamId
        : tm[1].fixtureTeamId;

    let home = tm[0];
    let guest = tm[1];
    if (tm[1].home) {
      home = tm[1];
      guest = tm[0];
    }

    let teamSelection = false;
    if (
      !isEmbargo(home.fixtureId) &&
      !isConclude(home.fixtureId) &&
      !home.fixtureId.lock
    ) {
      // is not in embargo and has not concluded and is not locked: go to team Selection
      teamSelection = true;
    }
    let delayed = tm[0].fixtureId.delayed;
    let rescheduled = tm[0].fixtureId.rescheduled;
    let scheduledWeek = tm[0].fixtureId.scheduledWeek;

    return {
      id: index,
      fixture: tm,
      fixtureTeamId: fixtureTeamId,
      home: home,
      guest: guest,
      week: tm[0].fixtureId.fixtureWeek.weekId.name,
      date: dateFormat(tm[0].fixtureId.fixtureWeek.scheduledDate),
      hour: hourFormat(tm[0].fixtureId.fixtureWeek.scheduledDate),
      votes: favTeamsFixturesVotes[fixtureTeamId],
      formation: favTeamsFixturesPopForms[fixtureTeamId],
      formationNo: favTeamsFixturesPopFormsNo[fixtureTeamId],
      teamSelection: teamSelection,
      delayed: delayed,
      rescheduled: rescheduled,
      scheduledWeek: scheduledWeek
    };
  });
  
  console.log(rows);

  const favTeamFixturesHandleChange = (e) => {
    if (e.target.value === "all") {
      setTeamsAll(Object.values(favTeamsAllFixtures));
    } else {
      let newVals = [];
      for (let i = 0; i < favTeamsFixturesId[e.target.value].length; i++) {
        let fixtureId = favTeamsFixturesId[e.target.value][i];
        newVals.push(favTeamsAllFixtures[" " + fixtureId]);
      }
      setTeamsAll(newVals);
    }

    setFavTeamSelectionValue(e.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (teamsAll.length == 0) {
    return <div></div>;
  }

  return (
    <>
      <Helmet>
        <title>Predictal | My Fixtures</title>
      </Helmet>
      {mobileView && (
        <Container className={classes.cardContainerMobile}>
          <Box alignItems="center" flexGrow={1}>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.mobileTypo}
            >
              {userFavTeam
                ? userFavTeam.sleagueteam.sleagueId.leagueId.subtitle
                : ""}{" "}
              - {userFavTeam ? userFavTeam.sleagueteam.sleagueId.title : ""}
            </Typography>
          </Box>

          <Box
            justifyContent="Center"
            display="flex"
            mb={1}
            className={classes.selectStyle}
          >
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontSize: "12px" }}
                >
                  My Favourite Team
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="My Favourite Team"
                  value={favTeamSelectionValue}
                  onChange={favTeamFixturesHandleChange}
                >
                  <MenuItem value="all">
                    <span className={classes.textStyle1}>All</span>
                  </MenuItem>
                  {Object.keys(favTeamsFixturesId).map((teamId) => (
                    <MenuItem value={teamId}>
                      <IconButton>
                        <img
                          height={20}
                          width={20}
                          src={mediaPath + sleagueTeamsLogo[teamId]}
                        />
                      </IconButton>
                      <span className={classes.textStyle1}>
                        {sleagueTeamsName[teamId]}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              {rows.map((row) => (
                <Card
                  className={`${
                    row.id % 2 === 0
                      ? classes.listItemOddMobile
                      : classes.listItemEvenMobile
                  }`}
                >
                  <CardContent className={classes.cardContentMobile}>
                    <Box alignItems="center" flexGrow={1}>
                      {row.delayed ? (
                        <Typography
                          variant="h5"
                          className={classes.mobileListItemTextDate}
                        >
                          <StyledTooltip title="This fixture has been delayed">
                            <Icon aria-label="delayed">
                              <img
                                src={delayedIcon}
                                width={24}
                                height={24}
                                style={{
                                  verticalAlign: "middle",
                                  marginRight: 8,
                                }}
                              />
                            </Icon>
                          </StyledTooltip>
                          {row.week}: {row.date} - {row.hour}
                          {row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className={classes.mobileListItemTextDate}
                        >
                          {row.week}: {row.date} - {row.hour}
                          {row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}
                        </Typography>
                      )}
                    </Box>

                    <Divider />

                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        mt={5}
                        mb={4}
                        width="100%"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Grid item xs={4}>
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              height={48}
                              width={48}
                              src={
                                mediaPath + row.home.sleagueTeamId.teamId.logo
                              }
                            />
                          </Box>

                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              className={classes.textStyle1}
                              variant="h3"
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                paddingTop: "15px",
                              }}
                            >
                              {row.home.sleagueTeamId.teamId.shortName}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          justifyContent="center"
                          style={{ paddingTop: "15px" }}
                        >
                          <Typography
                            align="center"
                            className={classes.textStyle6}
                          >
                            &nbsp;{row.home.scoreFinal} &nbsp;- &nbsp;
                            {row.guest.scoreFinal}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              height={48}
                              width={48}
                              src={
                                mediaPath + row.guest.sleagueTeamId.teamId.logo
                              }
                            />
                          </Box>

                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              className={classes.textStyle1}
                              variant="h3"
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                paddingTop: "15px",
                              }}
                            >
                              {row.guest.sleagueTeamId.teamId.shortName}
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>

                    <Divider />

                    {row.teamSelection === true ? (
                      <Grid
                        item
                        xs={12}
                        justifyContent="center"
                        style={{ paddingTop: 7 }}
                      >
                        <Box mt={3} style={{ textAlign: "center" }}>
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 197, height: 32 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `/${project_url}/ts/myteam/${row.fixtureTeamId}`
                              );
                              localStorage.removeItem("pastFixData");
                            }}
                          >
                            My Starting XI Team
                          </Button>
                        </Box>
                      </Grid>
                    ) : (
                      <Box>
                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          spacing={1}
                        >
                          <Grid item xs={3}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.firstTextButtonMobileStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let userSeqData = await chkUserSeqDataIsAvailable(
                                    row.fixtureTeamId
                                  );

                                  // not in embargo but is lock
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock
                                  ) {
                                    if (!userSeqData) {
                                      // lock and has no data
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot select your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                    // not embargo is lock and has data
                                    else {
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot edit your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                  }
                                  // is in embargo or Post-Fixture time
                                  else if (
                                    isEmbargo(row.home.fixtureId) ||
                                    isConclude(row.home.fixtureId)
                                    // Date.now() >
                                    //   new Date(
                                    //     row.home.fixtureId.fixtureWeek.scheduledDate
                                    //   ).getTime()
                                  ) {
                                    // go to team Selection read only mode
                                    navigate(
                                      `/lms/ts/myteam/${row.fixtureTeamId}`
                                    );
                                    localStorage.removeItem("pastFixData");
                                  }
                                }}
                              >
                                My Team
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={4} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonMobileStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let community = "#community";
                                  let coachComData = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Community"
                                  );

                                  // is not locked and is not in embargo and community team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and community team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}${community}`
                                    );
                                }}
                              >
                                Community
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={3} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonMobileStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let coachDataReady = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Coach"
                                  );
                                  let coach = "#coach";
                                  // is not locked and is not in embargo and coach team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and coach team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Coach Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Coach Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}${coach}`
                                    );
                                }}
                              >
                                Coach
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={2} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonMobileStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={(e) => {
                                  e.preventDefault();
                                  let votes = "#votes";
                                  // is not locked and is not in embargo and no vote data
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setOpenDialog(true);
                                    setAlertMsg(
                                      "The Community Votes will be available for viewing shortly after the fixture has started"
                                    );
                                  } else if (
                                    isEmbargo(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is in embargo and not lock
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Votes will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no vote data
                                    isConclude(row.home.fixtureId) &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Votes will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is locked is not in embargo
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Votes at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/votes/${row.fixtureTeamId}${votes}`
                                    );
                                }}
                              >
                                Votes
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>

                        <Divider />
                        <Box>
                          <br />

                          <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            spacing={1}
                          >
                            <Typography
                              className={classes.textStyle5}
                              style={{ fontSize: "12px", paddingLeft: "10px" }}
                            >
                              Community Votes: {row.votes}
                            </Typography>
                          </Grid>

                          <br />

                          <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            spacing={1}
                          >
                            <Typography
                              className={classes.textStyle5}
                              style={{ fontSize: "12px", paddingLeft: "10px" }}
                            >
                              Popular Formation: <b>{row.formation}</b>{" "}
                              {row.formationNo}
                            </Typography>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Grid>

            {openDialog && (
              <AlertDialog
                title="Alert"
                closeDialog={handleCloseDialog}
                contentMsg={alertMsg}
              />
            )}
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Box alignItems="center" flexGrow={1}>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.typo}
            >
              {userFavTeam
                ? userFavTeam.sleagueteam.sleagueId.leagueId.subtitle
                : ""}{" "}
              - {userFavTeam ? userFavTeam.sleagueteam.sleagueId.title : ""}
            </Typography>
          </Box>

          <Box
            flexGrow={1}
            mb={1}
            width={328}
            height={65}
            className={classes.selectStyle}
          >
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: "12px" }}
              >
                My Favourite Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="My Favourite Team"
                value={favTeamSelectionValue}
                onChange={favTeamFixturesHandleChange}
              >
                <MenuItem value="all">
                  <span className={classes.textStyle1}>All</span>
                </MenuItem>
                {Object.keys(favTeamsFixturesId).map((teamId) => (
                  <MenuItem value={teamId}>
                    <IconButton>
                      <img
                        height={20}
                        width={20}
                        src={mediaPath + sleagueTeamsLogo[teamId]}
                      />
                    </IconButton>
                    <span className={classes.textStyle1}>
                      {sleagueTeamsName[teamId]}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              {rows.map((row) => (
                <Card
                  className={`${
                    row.id % 2 === 0
                      ? classes.listItemOddTablet
                      : classes.listItemEvenTablet
                  }`}
                >
                  <CardContent className={classes.cardContentTablet}>
                    <Box alignItems="center" flexGrow={1}>
                      {row.delayed ? (
                        <Typography
                          variant="h5"
                          className={classes.listItemTextDate}
                        >
                          <StyledTooltip title="This fixture has been delayed">
                            <Icon aria-label="delayed">
                              <img
                                src={delayedIcon}
                                width={32}
                                height={32}
                                style={{
                                  verticalAlign: "middle",
                                  marginRight: 12,
                                }}
                              />
                            </Icon>
                          </StyledTooltip>
                          {row.week}: {row.date} - {row.hour}
                          {row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h5"
                          className={classes.listItemTextDate}
                        >
                          {row.week}: {row.date} - {row.hour}
                          {row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}
                        </Typography>
                      )}
                    </Box>

                    <Divider />

                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        mt={5}
                        mb={4}
                        width="100%"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Grid item xs={5}>
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box mr={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ paddingTop: 14 }}
                              >
                                {row.home.sleagueTeamId.teamId.shortName}
                              </Typography>
                            </Box>
                            <img
                              height={56}
                              width={56}
                              src={
                                mediaPath + row.home.sleagueTeamId.teamId.logo
                              }
                            />
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          justifyContent="center"
                          style={{ marginLeft: "10px" }}
                        >
                          <Typography
                            className={classes.textStyle7}
                            align="center"
                            style={{ paddingTop: 14, justifyContent: "center" }}
                          >
                            &nbsp;{row.home.scoreFinal} &nbsp;- &nbsp;
                            {row.guest.scoreFinal}
                          </Typography>
                        </Grid>

                        <Grid item xs={5}>
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <img
                              height={56}
                              width={56}
                              src={
                                mediaPath + row.guest.sleagueTeamId.teamId.logo
                              }
                            />
                            <Box ml={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ paddingTop: 14 }}
                              >
                                {row.guest.sleagueTeamId.teamId.shortName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>

                    <Divider />

                    {row.teamSelection === true ? (
                      <Grid
                        item
                        xs={12}
                        justifyContent="center"
                        style={{ paddingTop: 12 }}
                      >
                        <Box mt={3} style={{ textAlign: "center" }}>
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 201, height: 32 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `/${project_url}/ts/myteam/${row.fixtureTeamId}`
                              );
                              localStorage.removeItem("pastFixData");
                            }}
                          >
                            My Starting XI Team{" "}
                          </Button>
                        </Box>
                      </Grid>
                    ) : (
                      <Box>
                        <Grid
                          container
                          item
                          xs={12}
                          direction="row"
                          spacing={1}
                        >
                          <Grid item xs={3}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.firstTextButtonStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let userSeqData = await chkUserSeqDataIsAvailable(
                                    row.fixtureTeamId
                                  );

                                  // not in embargo but is lock
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock
                                  ) {
                                    if (!userSeqData) {
                                      // lock and has no data
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot select your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                    // not embargo is lock and has data
                                    else {
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot edit your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                  }
                                  // is in embargo or Post-Fixture time
                                  else if (
                                    isEmbargo(row.home.fixtureId) ||
                                    isConclude(row.home.fixtureId)
                                    // Date.now() >
                                    //   new Date(
                                    //     row.home.fixtureId.fixtureWeek.scheduledDate
                                    //   ).getTime()
                                  ) {
                                    // go to team Selection read only mode
                                    navigate(
                                      `/lms/ts/myteam/${row.fixtureTeamId}`
                                    );
                                    localStorage.removeItem("pastFixData");
                                  }
                                }}
                              >
                                My Team
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={3} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonTabletStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let community = "#community";
                                  let coachComData = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Community"
                                  );

                                  // is not locked and is not in embargo and community team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and community team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}${community}`
                                    );
                                }}
                              >
                                Community
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={3} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonTabletStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let coach = "#coach";
                                  let coachDataReady = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Coach"
                                  );
                                  // is not locked and is not in embargo and coach team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and coach team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Coach Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Coach Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}${coach}`
                                    );
                                }}
                              >
                                Coach
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={3} className={classes.gridBorder}>
                            <Box justifyContent="center" display="flex">
                              <Button
                                className={classes.textButtonTabletStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={(e) => {
                                  e.preventDefault();
                                  let votes = "#votes";
                                  // is not locked and is not in embargo and no vote data
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setOpenDialog(true);
                                    setAlertMsg(
                                      "The Community Votes will be available for viewing shortly after the fixture has started"
                                    );
                                  } else if (
                                    isEmbargo(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is in embargo and not lock
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Votes will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no vote data
                                    isConclude(row.home.fixtureId) &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Votes will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is locked is not in embargo
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Votes at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/votes/${row.fixtureTeamId}${votes}`
                                    );
                                }}
                              >
                                Votes
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>

                        <Divider />
                        <Box>
                          <br />

                          <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            spacing={1}
                          >
                            <Typography className={classes.textStyle5}>
                              Community Votes: {row.votes}
                            </Typography>
                          </Grid>

                          <br />

                          <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            spacing={1}
                          >
                            <Typography
                              className={classes.textStyle5}
                              style={{ fontSize: "12px" }}
                            >
                              Popular Formation: <b>{row.formation}</b>{" "}
                              {row.formationNo}
                            </Typography>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Grid>

            {openDialog && (
              <AlertDialog
                title="Alert"
                closeDialog={handleCloseDialog}
                contentMsg={alertMsg}
              />
            )}
          </Grid>
        </Container>
      )}

      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Box mb={1}>
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.typo}
            >
              {userFavTeam
                ? userFavTeam.sleagueteam.sleagueId.leagueId.subtitle
                : ""}{" "}
              - {userFavTeam ? userFavTeam.sleagueteam.sleagueId.title : ""}
            </Typography>
          </Box>

          <Box
            flexGrow={1}
            mb={1}
            width={328}
            height={65}
            className={classes.selectStyle}
          >
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: "12px" }}
              >
                My Favourite Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="My Favourite Team"
                value={favTeamSelectionValue}
                onChange={favTeamFixturesHandleChange}
              >
                <MenuItem value="all">
                  <span className={classes.textStyle1}>All</span>
                </MenuItem>
                {Object.keys(favTeamsFixturesId).map((teamId) => (
                  <MenuItem value={teamId}>
                    <IconButton>
                      <img
                        height={20}
                        width={20}
                        src={mediaPath + sleagueTeamsLogo[teamId]}
                      />
                    </IconButton>
                    <span className={classes.textStyle1}>
                      {sleagueTeamsName[teamId]}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              {rows.map((row) => (
                <Card
                  className={`${
                    row.id % 2 === 0
                      ? classes.listItemOdd
                      : classes.listItemEven
                  }`}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      {row.delayed ? (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          xs={3}
                        >
                          <Grid item xs={1.5}>
                            <StyledTooltip title="This fixture has been delayed">
                              <Icon aria-label="delayed">
                                <img
                                  src={delayedIcon}
                                  width={32}
                                  height={32}
                                  style={{ verticalAlign: "middle" }}
                                />
                              </Icon>
                            </StyledTooltip>
                          </Grid>
                          <Grid item xs={10} style={{ paddingLeft: 10 }}>
                            <Typography className={classes.textStyle1}>
                              Match {row.week}
                              <Typography className={classes.textStyle1}>{row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}</Typography>
                            </Typography>
                            <Typography className={classes.textStyle2}>
                              {row.date}
                            </Typography>
                            <Typography className={classes.textStyle1}>
                              {row.hour} (your local time)
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={3}>
                          <Typography className={classes.textStyle1}>
                            Match {row.week}
                            <Typography className={classes.textStyle1}>{row.rescheduled ? ` (Rescheduled from ${row.scheduledWeek.weekId.name})` : ""}</Typography>
                          </Typography>
                          <Typography className={classes.textStyle2}>
                            {row.date}
                          </Typography>
                          <Typography className={classes.textStyle1}>
                            {row.hour} (your local time)
                          </Typography>
                        </Grid>
                      )}

                      <Grid
                        container
                        item
                        xs={5}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={5}
                          direction="row"
                          justifyContent="center"
                        >
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box mr={1}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ paddingTop: 14 }}
                              >
                                {row.home.sleagueTeamId.teamId.shortName}
                              </Typography>
                            </Box>
                            <img
                              height={56}
                              width={56}
                              src={
                                mediaPath + row.home.sleagueTeamId.teamId.logo
                              }
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={2} justifyContent="center">
                          <Typography
                            className={classes.textStyle8}
                            align="center"
                          >
                            &nbsp;{row.home.scoreFinal} &nbsp;- &nbsp;
                            {row.guest.scoreFinal}
                          </Typography>
                        </Grid>

                        <Grid item xs={5}>
                          <Box
                            direction="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <img
                              height={56}
                              width={56}
                              src={
                                mediaPath + row.guest.sleagueTeamId.teamId.logo
                              }
                            />
                            <Box ml={1}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ paddingTop: 14 }}
                              >
                                {row.guest.sleagueTeamId.teamId.shortName}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      {row.teamSelection === true ? (
                        <Grid
                          item
                          xs={4}
                          style={{ textAlign: "Right", marginTop: 14 }}
                        >
                          <Button
                            className={classes.buttonStyle}
                            style={{ width: 201, height: 32 }}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `/${project_url}/ts/myteam/${row.fixtureTeamId}`
                              );
                              localStorage.removeItem("pastFixData");
                            }}
                          >
                            My Starting XI Team{" "}
                          </Button>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          container
                          xs={4}
                          style={{
                            textAlign: "Right",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            className={classes.textStyle1}
                            style={{ paddingBottom: 10 }}
                          >
                            Community Votes: {row.votes}
                          </Typography>
                          <Typography
                            className={classes.textStyle1}
                            style={{ paddingBottom: 10 }}
                          >
                            Popular Formation: <b>{row.formation}</b>{" "}
                            {row.formationNo}
                          </Typography>

                          <Grid
                            item
                            container
                            spacing={1}
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Grid item>
                              <Button
                                className={classes.textButtonStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let userSeqData = await chkUserSeqDataIsAvailable(
                                    row.fixtureTeamId
                                  );

                                  // not in embargo but is lock
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock
                                  ) {
                                    if (!userSeqData) {
                                      // lock and has no data
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot select your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                    // not embargo is lock and has data
                                    else {
                                      setAlertMsg(
                                        "This fixture is locked now. You cannot edit your Starting XI team."
                                      );
                                      setOpenDialog(true);
                                    }
                                  }
                                  // is in embargo or Post-Fixture time
                                  else if (
                                    isEmbargo(row.home.fixtureId) ||
                                    isConclude(row.home.fixtureId)
                                    // Date.now() >
                                    //   new Date(
                                    //     row.home.fixtureId.fixtureWeek.scheduledDate
                                    //   ).getTime()
                                  ) {
                                    // go to team Selection read only mode
                                    navigate(
                                      `/lms/ts/myteam/${row.fixtureTeamId}`
                                    );
                                    localStorage.removeItem("pastFixData");
                                  }
                                }}
                              >
                                My Team
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                className={classes.textButtonStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let coachComData = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Community"
                                  );

                                  // is not locked and is not in embargo and community team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and community team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachComData
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}`
                                    );
                                }}
                              >
                                Community
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                className={classes.textButtonStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  let coachDataReady = await chkCoachComDataIsAvailable(
                                    row.fixtureTeamId,
                                    "Coach"
                                  );
                                  // is not locked and is not in embargo and coach team is not created
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    // is locked is not in embargo and coach team is not created
                                    !isEmbargo(row.home.fixtureId) &&
                                    row.home.fixtureId.lock &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Coach Team at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //is in embargo has no data
                                    isEmbargo(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Coach Team will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no data
                                    isConclude(row.home.fixtureId) &&
                                    !coachDataReady
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Coach Team will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/3teams/${row.fixtureTeamId}`
                                    );
                                }}
                              >
                                Coach
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                className={classes.textButtonStyle}
                                disableRipple={true}
                                color="primary"
                                variant="text"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // is not locked and is not in embargo and no vote data
                                  if (
                                    !isEmbargo(row.home.fixtureId) &&
                                    !isConclude(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setOpenDialog(true);
                                    setAlertMsg(
                                      "The Community Votes will be available for viewing shortly after the fixture has started"
                                    );
                                  } else if (
                                    isEmbargo(row.home.fixtureId) &&
                                    !row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is in embargo and not lock
                                    setAlertMsg(
                                      "This fixture is in embargo at this time. The Community Votes will be published shortly after the fixture starts."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    //has concluded and has no vote data
                                    isConclude(row.home.fixtureId) &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    setAlertMsg(
                                      "This fixture has concluded. The Community Votes will be published shortly."
                                    );
                                    setOpenDialog(true);
                                  } else if (
                                    row.home.fixtureId.lock &&
                                    row.votes === "Stay Tuned"
                                  ) {
                                    // is locked is not in embargo
                                    setAlertMsg(
                                      "This fixture is locked before it starts. There is no Community Votes at this time. "
                                    );
                                    setOpenDialog(true);
                                  } else
                                    navigate(
                                      `/lms/ts/votes/${row.fixtureTeamId}`
                                    );
                                }}
                              >
                                Votes
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            {openDialog && (
              <AlertDialog
                title="Alert"
                closeDialog={handleCloseDialog}
                contentMsg={alertMsg}
              />
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default FavTeamFixtures;
