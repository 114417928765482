import React, { useState, useEffect, useContext  } from "react";
import { Helmet } from "react-helmet";
import { useMutation, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "../Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import adminStyle from "../Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function ComputeTSPosSlices() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const formations  = [ 
    { mainCategory: "4-4-2", 
      derivedCategories: ["4-4-2", "4-1-3-2", "4-3-1-2", "4-1-2-1-2", 
                          "4-2-2-2", "1-3-4-2", "3-1-4-2", "4-4-1-1" ],      
    },
    { mainCategory: "4-2-4", 
      derivedCategories: ["4-2-4"],      
    },

    { mainCategory: "4-5-1", 
      derivedCategories: ["4-5-1", "4-1-4-1", "4-4-1-1", "4-2-3-1", 
                          "4-3-2-1", "1-3-5-1", "3-1-5-1", "2-2-5-1" ],      
    },

    { mainCategory: "5-3-2", 
      derivedCategories: ["5-3-2", "5-2-1-2", "5-1-2-2", "1-4-3-2", 
                          "4-1-3-2" ],      
    },

    { mainCategory: "3-5-2", 
      derivedCategories: ["3-5-2", "3-1-4-2", "3-4-1-2", "3-5-1-1" ],      
    },

    { mainCategory: "4-3-3", 
      derivedCategories: ["4-3-3", "4-2-1-3", "4-1-2-3", "3-1-3-3",
                          "1-3-3-3" ],      
    },

    { mainCategory: "3-4-3", 
      derivedCategories: ["3-4-3", "3-3-1-3", "3-1-3-3", "3-4-2-1",
                          "3-4-1-2" ],
    },

    { mainCategory: "5-4-1", 
      derivedCategories: ["5-4-1", "3-2-4-1", "2-3-4-1" ],
    },

    { mainCategory: "3-6-1", 
      derivedCategories: ["3-4-2-1", "3-2-4-1" ],
    },

  ];

  const posArrangement = [
    { formation: "4-4-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-1-3-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 7 },
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-3-1-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 9 },
                { name: "CMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-1-2-1-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 7 },
                { name: "LMF",  posNo: 6 },
                { name: "RMF",  posNo: 9 },
                { name: "CMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-2-2-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "LMF",  posNo: 6 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "1-3-4-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 3 },
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-1-4-2",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 5 }, 
                { name: "CDEF", posNo: 4 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-4-1-1",
      parent: "4-4-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-2-4",
      parent: "4-2-4",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 8 },
                { name: "CFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-5-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-1-4-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 8 },
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-4-1-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
                { name: "CMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-2-3-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 9 },
                { name: "LMF",  posNo: 6 },    
                { name: "CMF",  posNo: 8 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-3-2-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 8 },                
                { name: "RMF",  posNo: 10},
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "1-3-5-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 3 },
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 4 }, 
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },                
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-1-5-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 5 },                
                { name: "CDEF", posNo: 4 }, 
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },                
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "2-2-5-1",
      parent: "4-5-1",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "LDEF", posNo: 2 },
                { name: "RDEF", posNo: 5 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },                
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "5-3-2",
      parent: "5-3-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },                
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "5-2-1-2",
      parent: "5-3-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },                
        ],
        "MID": [ 
                { name: "LMF",  posNo: 7 },
                { name: "RMF",  posNo: 9 },
                { name: "CMF",  posNo: 8 },                
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "5-1-2-2",
      parent: "5-3-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },                
        ],
        "MID": [                 
                { name: "CMF",  posNo: 8 }, 
                { name: "LMF",  posNo: 7 },
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "1-4-3-2",
      parent: "5-3-2",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 4 },
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },                
        ],
        "MID": [   
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-1-3-2",
      parent: "5-3-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },
                { name: "CDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-5-2",
      parent: "3-5-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 }, 
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-1-4-2",
      parent: "3-5-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "CMF",  posNo: 7 }, 
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-4-1-2",
      parent: "3-5-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
                { name: "CMF",  posNo: 7 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-5-1-1",
      parent: "3-5-2",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 }, 
                { name: "CMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 }, 
                { name: "RMF",  posNo: 9 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-3-3",
      parent: "4-3-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 5 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-2-1-3",
      parent: "4-3-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 5 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 6 },
                { name: "RMF",  posNo: 8 },
                { name: "CMF",  posNo: 7 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "4-1-2-3",
      parent: "4-3-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 5 },
        ],
        "MID": [   
                { name: "CMF",  posNo: 7 },
                { name: "LMF",  posNo: 6 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-1-3-3",
      parent: "4-3-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 5 },
                { name: "CDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "1-3-3-3",
      parent: "4-3-3",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 3 },
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 5 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-4-3",
      parent: "3-4-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-3-1-3",
      parent: "3-4-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "RMF",  posNo: 8 },
                { name: "CMF",  posNo: 7 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-1-3-3",
      parent: "3-4-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "CMF",  posNo: 6 },
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "CFOR", posNo: 10},
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-4-2-1",
      parent: "3-4-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "LFOR", posNo: 9 },
                { name: "RFOR", posNo: 11},
                { name: "CFOR", posNo: 10},
        ],
      },
    },
    { formation: "3-4-1-2",
      parent: "3-4-3",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 10},
                { name: "LFOR", posNo: 9 },
                { name: "RFOR", posNo: 11},
        ],
      },
    },
    { formation: "5-4-1",
      parent: "5-4-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 4 },
                { name: "CDEF", posNo: 5 },
                { name: "RDEF", posNo: 6 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-2-4-1",
      parent: "5-4-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 6 },
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 5 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "2-3-4-1",
      parent: "5-4-1",
      positions: { 
        "DEF": [ 
                { name: "CDEF", posNo: 3 },
                { name: "CDEF", posNo: 5 },
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 4 },
                { name: "RDEF", posNo: 6 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-4-2-1",
      parent: "3-6-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "LMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "CMF",  posNo: 7 },
                { name: "RMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "CMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
    { formation: "3-2-4-1",
      parent: "3-6-1",
      positions: { 
        "DEF": [ 
                { name: "LDEF", posNo: 2 },
                { name: "CDEF", posNo: 3 },
                { name: "RDEF", posNo: 4 },
        ],
        "MID": [   
                { name: "CMF",  posNo: 5 },
                { name: "CMF",  posNo: 6 },
                { name: "LMF",  posNo: 7 },
                { name: "CMF",  posNo: 8 },
                { name: "CMF",  posNo: 9 },
                { name: "RMF",  posNo: 10},
        ],
        "FOR": [ 
                { name: "CFOR", posNo: 11},
        ],
      },
    },
  ]
  
  let defLane = 1;
  let midLane = 1;
  let forLane = 1;

  let midLane_col_1 = 1;
  let midLane_col_2 = 1;
  let midLane_col_3 = 1;
  let defLane_col_1 = 1;
  let defLane_col_2 = 1;
  let forLane_col_1 = 1;
  let forLane_col_2 = 1;  

  let DLane_1_width = 0;
  let DLane_2_width = 0;
  let MLane_1_width = 0;
  let MLane_2_width = 0;
  let MLane_3_width = 0;
  let FLane_1_width = 0;
  let FLane_2_width = 0;

  const navigate = useNavigate();
  let [posAreaInfo, setPosAreaInfo] = useState([]); 

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    }
  });

  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") }
    });
    console.log("data:", refreshData,"error:", refreshError)
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  }; 

  const getRoundedDivisions = (number, parts) => {
    let dividedNums = [];
    let division    = Math.floor(number/parts);
    let diff        = number - ( division * parts)
    for (let i = 0; i < diff; i++){
      dividedNums.push(division + 1);
    }
    for (let j = 0; j < parts - diff; j++){
      dividedNums.push(division);
    }
    return dividedNums
  }

  const onSubmit = async formData => {
    await sleep(2000);

    defLane = 1;
    midLane = 1;
    forLane = 1;

    posAreaInfo = [];    

    if (formData.formation === "0") {
      alert("Please select one formation.");
    } else {      

      const fieldLength   = parseInt(formData.length)
      const fieldWidth    = parseInt(formData.width)

      const gkAreaPerc    = parseInt(formData.gkPerc)
      const resAreaPerc   = parseInt(formData.resPerc)

      let gkAreaLength    = Math.round(fieldLength * gkAreaPerc / 100);
      let resAreaLength   = Math.round(fieldLength * resAreaPerc / 100);

      var [ defAreaLength, 
            midAreaLength, 
            forAreaLength] = getRoundedDivisions(
                            (fieldLength - gkAreaLength - resAreaLength), 
                            3)  

      let defAreaWidth    = fieldWidth;
      let midAreaWidth    = fieldWidth;
      let forAreaWidth    = fieldWidth;  

      let gkAreaXCenter   = Math.round(gkAreaLength / 2);
      let resAreaXCenter  = Math.round(resAreaLength / 2);
      let defAreaXCenter  = Math.round(defAreaLength / 2);
      let midAreaXCenter  = Math.round(midAreaLength / 2);
      let forAreaXCenter  = Math.round(forAreaLength / 2); 

      let gkAreaYCenter   = Math.round(fieldWidth / 2);
      let defAreaYCenter  = 0;
      let midAreaYCenter  = 0;
      let forAreaYCenter  = 0; 

      let defPosInfo = {};
      let midPosInfo = {};
      let forPosInfo = {}; 

      posAreaInfo.push( {position: "RES", 
                        posCategory: "RES",
                        length: resAreaLength, 
                        x_center: resAreaXCenter,
                        width: fieldWidth,
                        y_center: gkAreaYCenter },
                        {position: "GK" + "(1)", 
                        posCategory: "GK",
                        length: gkAreaLength, 
                        x_center: gkAreaXCenter,
                        width: fieldWidth,
                        y_center: gkAreaYCenter },
                        {position: "Defense Area",
                        posCategory: "DEF-AREA-MAIN", 
                        length: defAreaLength, 
                        x_center: "",
                        width: defAreaWidth,
                        y_center: "" }, )                        

      const parentFormation= formData.formation.split("~@")[0];
      const derivedFormation= formData.formation.split("~@")[1];

      let foundFormation = posArrangement.find(
        item => item.formation.toLowerCase() === derivedFormation.toLowerCase() &&
             item.parent.toLowerCase() === parentFormation.toLowerCase()
      );

      console.log("foundFormation:",foundFormation)

      const formationParts = derivedFormation.split("-");

      if (formationParts.length == 3) {

        defAreaWidth  = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[0]));

        midAreaWidth  = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[1]));
        forAreaWidth  = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[2]));         
        
        defPosInfo    = defAreaWidth.map((w, index) => ({
                            position: foundFormation.positions["DEF"][index].name +
                            "(" + foundFormation.positions["DEF"][index].posNo + ")",
                            posCategory: "DEF",
                            length: defAreaLength, 
                            x_center: defAreaXCenter,
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
        defPosInfo.map((info) => (
          posAreaInfo.push(info)
        ))

        posAreaInfo.push({position: "Midfield Area", 
                          posCategory: "MID-AREA-MAIN",
                          length: midAreaLength, 
                          x_center: "",
                          width: fieldWidth,
                          y_center: "" },)

        midPosInfo    = midAreaWidth.map((w, index) => ({
                            position: foundFormation.positions["MID"][index].name +
                            "(" + foundFormation.positions["MID"][index].posNo + ")",
                            posCategory: "MID",
                            length: midAreaLength, 
                            x_center: midAreaXCenter,
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
        midPosInfo.map((info) => (
          posAreaInfo.push(info)
        ))     

        posAreaInfo.push({position: "Forward Area", 
                          posCategory: "FOR-AREA-MAIN",
                          length: forAreaLength, 
                          x_center: "",
                          width: fieldWidth,
                          y_center: "" },)

        forPosInfo    = forAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["FOR"][index].name +
                              "(" + foundFormation.positions["FOR"][index].posNo + ")",
                              posCategory: "FOR",
                              length: forAreaLength, 
                              x_center: forAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
          forPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))

      } else {

        // formation has 5 parts
        if (formationParts.length == 5 ){
          midLane = 3;          
        } else {
          // formation has 4 parts
          if ( parseInt(formationParts[0]) >= 4 || 
              (parseInt(formationParts[0]) + 
              parseInt(formationParts[1]) > 5) ||
              (parentFormation == "3-6-1" && 
                derivedFormation == "3-2-4-1")) {
            midLane = 2;

            if (  parentFormation == "5-3-2" && 
                  derivedFormation == "4-1-3-2") {
              defLane = 2;  
              midLane = 1;            
            }    

            if ( (parentFormation == "4-4-2" && 
                  derivedFormation == "4-4-1-1") ||
                 ( parentFormation == "3-5-2" && 
                  derivedFormation == "3-5-1-1") ||
                 ( parentFormation == "3-4-3" && 
                  derivedFormation == "3-4-2-1") ||
                 ( parentFormation == "3-4-3" && 
                  derivedFormation == "3-4-1-2"))  {
              midLane = 1;  
              forLane = 2;  
            }             
          } else {
            defLane = 2;  

            if ( ( parentFormation == "3-5-2" && 
                  derivedFormation == "3-1-4-2") || 
               (  parentFormation == "3-4-3" && 
                  derivedFormation == "3-1-3-3" ) ) {
              defLane = 1;  
              midLane = 2;            
            }                    
          }
        }   

        // Checking number of Lanes in DEF and MID parts for dividing lane

        if ( forLane == 2 ) {
          forLane_col_1   = parseInt(formationParts[2]);
          forLane_col_2   = parseInt(formationParts[3]);

          let forLengths  = getRoundedDivisions(forAreaLength, 2);

          FLane_1_width   = getRoundedDivisions(fieldWidth, forLane_col_1); 
          FLane_2_width   = getRoundedDivisions(fieldWidth, forLane_col_2); 

          defAreaWidth    = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[0]));

          midAreaWidth    = getRoundedDivisions(
                              fieldWidth, parseInt(formationParts[1]));
          
          defPosInfo      = defAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["DEF"][index].name +
                              "(" + foundFormation.positions["DEF"][index].posNo + ")",
                              posCategory: "DEF",
                              length: defAreaLength, 
                              x_center: defAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));

          defPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))          

          posAreaInfo.push({position: "Midfield Area", 
                            posCategory: "MID-AREA-MAIN",
                            length: midAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },)

          midPosInfo    = midAreaWidth.map((w, index) => ({
                            position: foundFormation.positions["MID"][index].name +
                            "(" + foundFormation.positions["MID"][index].posNo + ")",
                            posCategory: "MID",
                            length: midAreaLength, 
                            x_center: midAreaXCenter,
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
          midPosInfo.map((info) => (
            posAreaInfo.push(info)
          )) 

          posAreaInfo.push({position: "Forward Area", 
                            posCategory: "FOR-AREA-MAIN",
                            length: forAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },
                            {position: "Forward-1 Area", 
                            posCategory: "FOR-AREA",
                            length: forLengths[0], 
                            x_center: "", //Math.round(forLengths[0] / 2),
                            width: FLane_1_width[0],
                            y_center: "" },) //Math.round(FLane_1_width[0] / 2) },)

          forPosInfo    = FLane_1_width.map((w, index) => ({
                            position: foundFormation.positions["FOR"][index].name +
                              "(" + foundFormation.positions["FOR"][index].posNo + ")",
                            posCategory: "FOR",
                            length: forLengths[0], 
                            x_center: Math.round(forLengths[0] / 2 ),
                            width: w,
                            y_center: Math.round(w / 2)
                          }));

          forPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))          

          posAreaInfo.push( {position: "Forward-2 Area", 
                            posCategory: "FOR-AREA",
                            length: forLengths[1], 
                            x_center: "", //Math.round(forLengths[1] / 2),
                            width: FLane_2_width[0],
                            y_center: "" }, )//Math.round(FLane_2_width[0] / 2) },)          
          
          forPosInfo    = FLane_2_width.map((w, index) => ({
                            position: foundFormation.positions["FOR"][index 
                            + forLane_col_1].name + "(" + 
                            foundFormation.positions["FOR"][index + 
                            forLane_col_1].posNo + ")",
                            posCategory: "FOR",
                            length: forLengths[1], 
                            x_center: Math.round(forLengths[1] / 2 ),
                            width: w,
                             y_center: Math.round(w / 2)
                          }));

          forPosInfo.map((info) => (
            posAreaInfo.push(info)
            ))  

        } else 

        if ( defLane == 2 ) {
          defLane_col_1   = parseInt(formationParts[0]);
          defLane_col_2   = parseInt(formationParts[1]);

          let defLengths  = getRoundedDivisions(defAreaLength, 2);         

          DLane_1_width   = getRoundedDivisions(fieldWidth, defLane_col_1); 
          DLane_2_width   = getRoundedDivisions(fieldWidth, defLane_col_2);

          midAreaWidth    = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[2]));

          forAreaWidth    = getRoundedDivisions(
                              fieldWidth, parseInt(formationParts[3]));        

          posAreaInfo.push( {position: "Defense-1 Area",
                            posCategory: "DEF-AREA", 
                            length: defLengths[0], 
                            x_center: "", //Math.round(defLengths[0] / 2),
                            width: DLane_1_width[0],
                            y_center: "", },)//Math.round(DLane_1_width[0] / 2) },)

          defPosInfo      = DLane_1_width.map((w, index) => ({
                              position: foundFormation.positions["DEF"][index].name +
                              "(" + foundFormation.positions["DEF"][index].posNo + ")",
                              posCategory: "DEF",
                              length: defLengths[0], 
                              x_center: Math.round(defLengths[0] / 2 ),
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
          defPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))           

          posAreaInfo.push( {position: "Defense-2 Area",
                            posCategory: "DEF-AREA", 
                            length: defLengths[1], 
                            x_center: "",//Math.round(defLengths[1] / 2),
                            width: DLane_2_width[0],
                            y_center: "" }, )//Math.round(DLane_2_width[0] / 2) },)          
          
          defPosInfo    = DLane_2_width.map((w, index) => ({
                            position: foundFormation.positions["DEF"][index + 
                            defLane_col_1].name + "(" + 
                            foundFormation.positions["DEF"][index + 
                            defLane_col_1].posNo + ")",
                            posCategory: "DEF",
                            length: defLengths[1], 
                            x_center: Math.round(defLengths[1] / 2 ),
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
          defPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))

         posAreaInfo.push({position: "Midfield Area", 
                            posCategory: "MID-AREA-MAIN",
                            length: midAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },)

          midPosInfo    = midAreaWidth.map((w, index) => ({
                            position: foundFormation.positions["MID"][index].name +
                            "(" + foundFormation.positions["MID"][index].posNo + ")",
                            posCategory: "MID",
                            length: midAreaLength, 
                            x_center: midAreaXCenter,
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
          midPosInfo.map((info) => (
            posAreaInfo.push(info)
          ))    

          posAreaInfo.push({position: "Forward Area", 
                            posCategory: "FOR-AREA-MAIN",
                            length: forAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },)

          forPosInfo    = forAreaWidth.map((w, index) => ({
                            position: foundFormation.positions["FOR"][index].name +
                            "(" + foundFormation.positions["FOR"][index].posNo + ")",
                            posCategory: "FOR",
                            length: forAreaLength, 
                            x_center: forAreaXCenter,
                            width: w,
                            y_center: Math.round(w / 2)
                          }));
          forPosInfo.map((info) => (
            posAreaInfo.push(info)
          )) 
          
        } else {
          if ( midLane == 2 ) {
            midLane_col_1 = parseInt(formationParts[1]);
            midLane_col_2 = parseInt(formationParts[2]);

            let midLengths  = getRoundedDivisions(midAreaLength, 2);  

            MLane_1_width   = getRoundedDivisions(fieldWidth, midLane_col_1);
            MLane_2_width   = getRoundedDivisions(fieldWidth, midLane_col_2);
            
            defAreaWidth    = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[0]));

            forAreaWidth    = getRoundedDivisions(
                              fieldWidth, parseInt(formationParts[3])); 

            defPosInfo      = defAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["DEF"][index].name +
                              "(" + foundFormation.positions["DEF"][index].posNo + ")",
                              posCategory: "DEF",
                              length: defAreaLength, 
                              x_center: defAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));

            defPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))  

            posAreaInfo.push({position: "Midfield Area", 
                            posCategory: "MID-AREA-MAIN",
                            length: midAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },)

            posAreaInfo.push( {position: "Midfield-1 Area",
                            posCategory: "MID-AREA", 
                            length: midLengths[0], 
                            x_center: "", //Math.round(midLengths[0] / 2),
                            width: MLane_1_width[0],
                            y_center: "", },)//Math.round(MLane_1_width[0] / 2) },)

            midPosInfo      = MLane_1_width.map((w, index) => ({
                                position: foundFormation.positions["MID"][index].name +
                                "(" + foundFormation.positions["MID"][index].posNo + ")",
                                posCategory: "MID",
                                length: midLengths[0], 
                                x_center: Math.round(midLengths[0] / 2 ),
                                width: w,
                                y_center: Math.round(w / 2)
                              }));
            midPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))           

            posAreaInfo.push( {position: "Midfield-2 Area",
                              posCategory: "MID-AREA", 
                              length: midLengths[1], 
                              x_center: "",//Math.round(midLengths[1] / 2),
                              width: MLane_2_width[0],
                              y_center: "" }, )//Math.round(MLane_2_width[0] / 2) },)          
            
            midPosInfo    = MLane_2_width.map((w, index) => ({
                              position: 
                              foundFormation.positions["MID"][index + midLane_col_1].name +
                                "(" + foundFormation.positions["MID"][index +
                                 midLane_col_1].posNo + ")",
                              posCategory: "MID",
                              length: midLengths[1], 
                              x_center: Math.round(midLengths[1] / 2 ),
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
            midPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))            

            posAreaInfo.push({position: "Forward Area", 
                              posCategory: "FOR-AREA-MAIN",
                              length: forAreaLength, 
                              x_center: "",
                              width: fieldWidth,
                              y_center: "" },)

            forPosInfo    = forAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["FOR"][index].name +
                              "(" + foundFormation.positions["FOR"][index].posNo + ")",
                              posCategory: "FOR",
                              length: forAreaLength, 
                              x_center: forAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
            forPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))           

          } else if (midLane == 3){
            midLane_col_1 = parseInt(formationParts[1]);
            midLane_col_2 = parseInt(formationParts[2]);
            midLane_col_3 = parseInt(formationParts[3]);

            let midLengths  = getRoundedDivisions(midAreaLength, 3);  

            MLane_1_width   = getRoundedDivisions(fieldWidth, midLane_col_1);
            MLane_2_width   = getRoundedDivisions(fieldWidth, midLane_col_2);
            MLane_3_width   = getRoundedDivisions(fieldWidth, midLane_col_3);
            
            defAreaWidth    = getRoundedDivisions(
                            fieldWidth, parseInt(formationParts[0]));

            forAreaWidth    = getRoundedDivisions(
                              fieldWidth, parseInt(formationParts[4])); 

            defPosInfo      = defAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["DEF"][index].name +
                              "(" + foundFormation.positions["DEF"][index].posNo + ")",
                              posCategory: "DEF",
                              length: defAreaLength, 
                              x_center: defAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));

            defPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))

            posAreaInfo.push({position: "Midfield Area", 
                            posCategory: "MID-AREA-MAIN",
                            length: midAreaLength, 
                            x_center: "",
                            width: fieldWidth,
                            y_center: "" },)  

            posAreaInfo.push( {position: "Midfield-1 Area",
                            posCategory: "MID-AREA", 
                            length: midLengths[0], 
                            x_center: "", //Math.round(midLengths[0] / 2),
                            width: MLane_1_width[0],
                            y_center: "", },)//Math.round(MLane_1_width[0] / 2) },)

            midPosInfo      = MLane_1_width.map((w, index) => ({
                                position: foundFormation.positions["MID"][index].name +
                                "(" + foundFormation.positions["MID"][index].posNo + ")",
                                posCategory: "MID",
                                length: midLengths[0], 
                                x_center: Math.round(midLengths[0] / 2 ),
                                width: w,
                                y_center: Math.round(w / 2)
                              }));
            midPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))           

            posAreaInfo.push( {position: "Midfield-2 Area",
                              posCategory: "MID-AREA", 
                              length: midLengths[1], 
                              x_center: "",//Math.round(midLengths[1] / 2),
                              width: MLane_2_width[0],
                              y_center: "" }, )//Math.round(MLane_2_width[0] / 2) },)          
            
            midPosInfo    = MLane_2_width.map((w, index) => ({
                              position: 
                              foundFormation.positions["MID"][index + midLane_col_1].name +
                                "(" + foundFormation.positions["MID"][index +
                                 midLane_col_1].posNo + ")",
                              posCategory: "MID",
                              length: midLengths[1], 
                              x_center: Math.round(midLengths[1] / 2 ),
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
            midPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))

            posAreaInfo.push( {position: "Midfield-3 Area",
                              posCategory: "MID-AREA", 
                              length: midLengths[2], 
                              x_center: "",//Math.round(midLengths[2] / 2),
                              width: MLane_3_width[0],
                              y_center: "" }, )//Math.round(MLane_3_width[0] / 2) },)          
            
            midPosInfo    = MLane_3_width.map((w, index) => ({
                              position: 
                              foundFormation.positions["MID"][index 
                              + midLane_col_1 + midLane_col_2].name +
                                "(" + foundFormation.positions["MID"][index +
                                 midLane_col_1 + midLane_col_2].posNo + ")",
                              posCategory: "MID",
                              length: midLengths[2], 
                              x_center: Math.round(midLengths[2] / 2 ),
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
            midPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))                        

            posAreaInfo.push({position: "Forward Area", 
                              posCategory: "FOR-AREA-MAIN",
                              length: forAreaLength, 
                              x_center: "",
                              width: fieldWidth,
                              y_center: "" },)

            forPosInfo    = forAreaWidth.map((w, index) => ({
                              position: foundFormation.positions["FOR"][index].name +
                              "(" + foundFormation.positions["FOR"][index].posNo + ")",
                              posCategory: "FOR",
                              length: forAreaLength, 
                              x_center: forAreaXCenter,
                              width: w,
                              y_center: Math.round(w / 2)
                            }));
            forPosInfo.map((info) => (
              posAreaInfo.push(info)
            ))           
          }
        }
      }       
      setPosAreaInfo(posAreaInfo)
    }
  }  

  const columns = [ 
    {
      field: "position",
      headerName: "Position",
      width: 150,
      editable: false
    },
    {
      field: "width",
      headerName: "Width (X-Axis)",
      width: 200,
      editable: false
    },
    {
      field: "x_center",
      headerName: "X Center Point",
      width: 200,
      editable: false
    },
    {
      field: "height",
      headerName: "Height (Y-Axis)",
      width: 200,
      editable: false
    },
    {
      field: "y_center",
      headerName: "Y Center Point",
      width: 200,
      editable: false
    },
  ];

  const rows = posAreaInfo.map((pos, id) => ({
    id: id,
    posCategory: pos.posCategory,
    position: pos.position,
    width: pos.length,
    x_center: pos.x_center,
    height: pos.width,
    y_center: pos.y_center
  }));  

  return (
    <>
      <Helmet>
        <title>Predictal | Formations Positions Calculator | Admin</title>
      </Helmet>
            <Grid container direction="row">
              <Layout />
              <Grid container item direction="column" xs={9} spacing={1}>
                <Grid item>
                  <h2 className={adminStyle.h2}>Formations Positions Calculator</h2>
                </Grid>
                <Grid item>
                  <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label className={adminStyle.label} htmlFor="length">
                        Width (px)
                      </label>
                      <input
                        className={adminStyle.input}
                        type="number"
                        min="1"
                        {...register("length", {
                          required: true,
                          pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i
                        })}
                      />
                      {errors.length && errors.length.type === "required" && (
                        <span>Required</span>
                      )}
                      {errors.length && errors.length.type === "pattern" && (
                        <span>Please enter a valid number</span>
                      )}
                    </div>

                    <div>
                      <label className={adminStyle.label} htmlFor="width">
                        Height (px)
                      </label>
                      <input
                        className={adminStyle.input}
                        type="number"
                        min="1"
                        {...register("width", {
                          required: true,
                          pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i
                        })}
                      />
                      {errors.width && errors.width.type === "required" && (
                        <span>Required</span>
                      )}
                      {errors.width && errors.width.type === "pattern" && (
                        <span>Please enter a valid number</span>
                      )}
                    </div>

                    <div>
                      <label className={adminStyle.label} htmlFor="gkPerc">
                        GK-Perc (%)
                      </label>
                      <input
                        className={adminStyle.input}
                        type="number"
                        min="1"
                        {...register("gkPerc", {
                          required: true,
                          pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i
                        })}
                      />
                      {errors.gkPerc && errors.gkPerc.type === "required" && (
                        <span>Required</span>
                      )}
                      {errors.gkPerc && errors.gkPerc.type === "pattern" && (
                        <span>Please enter a valid number</span>
                      )}
                    </div>

                    <div>
                      <label className={adminStyle.label} htmlFor="resPerc">
                        Res-Perc (%)
                      </label>
                      <input
                        className={adminStyle.input}
                        type="number"
                        min="1"
                        {...register("resPerc", {
                          required: true,
                          pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i
                        })}
                      />
                      {errors.resPerc && errors.resPerc.type === "required" && (
                        <span>Required</span>
                      )}
                      {errors.resPerc && errors.resPerc.type === "pattern" && (
                        <span>Please enter a valid number</span>
                      )}
                    </div>

                    <label className={adminStyle.label} htmlFor="formation">
                      Formation
                    </label>
                    <select
                      className={adminStyle.select}
                      id="formation"
                      {...register("formation")}
                      // onChange={handleChange}
                    >
                      <option value="0">Select a Formation...</option>
                      {formations.map((formation, i) => (
                        <optgroup label={formation.mainCategory}>
                          {formation.derivedCategories.map((devFormation, j) => (
                            <option key={j} value={formation.mainCategory + '~@' + 
                                                    devFormation}>{devFormation}</option>
                          ))}
                        </optgroup>
                      ))}             
                    </select>

                    <input className={adminStyle.input} type="submit" value="Compute"/>            
                  </form>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      '& .style--RES': {
                        backgroundColor: 'white',
                      },
                      '& .style--GK': {
                        backgroundColor: '#D0CFCF',
                      },
                      '& .style--DEF': {
                        backgroundColor: '#D8FBFF',
                      },
                      '& .style--MID': {
                        backgroundColor: '#D1F2D4',
                      },
                      '& .style--FOR': {
                        backgroundColor: '#FFB0B0',
                      },  
                      '& .style--DEF-AREA-MAIN': {
                        backgroundColor: '#A2BCBF',
                        fontWeight: 'bold',
                        border: '2px solid black',
                      },   
                      '& .style--DEF-AREA': {
                        backgroundColor: '#BEDDE0',
                        fontWeight: 'bold',
                      },              
                      '& .style--MID-AREA-MAIN': {
                        backgroundColor: '#8FA692',
                        fontWeight: 'bold',
                        border: '2px solid black', 
                      },
                      '& .style--MID-AREA': {
                        backgroundColor: '#B5D1B8',
                        fontWeight: 'bold',
                      },
                      '& .style--FOR-AREA-MAIN': {
                        backgroundColor: '#B57D7D',
                        fontWeight: 'bold',
                        border: '2px solid black', 
                      }, 
                      '& .style--FOR-AREA': {
                        backgroundColor: '#D69494',
                        fontWeight: 'bold',
                      },                           
                    }}
                  >
                    <DataGrid rows={rows} columns={columns} autoHeight={true}
                    getRowClassName={(params) => `style--${params.row.posCategory}`}
                     />
                  </Box>
                </Grid>        
              </Grid>
            </Grid>
        </>
  );
}
