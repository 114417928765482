const SubmitInterestInfo = `
mutation   crmSubmitInterestForm($email: String!, $country:String!,
  $city:String!, $firstName:String!, $lastName:String!,
  $favouriteTeam: String!, $favouriteLeague: String!) {
    crmSubmitInterestForm (city:$city, country:$country,
    email:$email, firstName:$firstName, lastName:$lastName,
    favouriteTeam: $favouriteTeam, favouriteLeague: $favouriteLeague){
      email
      city
      country
      firstName
      lastName
      favouriteTeam
      favouriteLeague
    }
}`;

const SubmitContactInfo = `
mutation submitContactForm($email: String!, $country:String!,
  $name:String!, $topic: String!, $comment: String!) {
    submitContactForm (email:$email,country:$country,name:$name,
     topic:$topic, comment:$comment){
      email
      country
      name
      topic
      comment
    }
}`;

const CRMInterestSubmissionsList = `
  query {
    crmInterestSubmitsList {
    crmInterestInfoId
    firstName
    lastName
    favouriteTeam
    favouriteLeague
    email
    createDate
    city
    country
  }
}
`;

const ContactSubmissionsList = `
  query {
    contactSubmitsList {
    contactId
    name
    topic
    comment
    email
    createDate
    country
  }
}
`

module.exports = {
  SUBMIT_INTEREST_INFO: SubmitInterestInfo,
  CRM_INTEREST_SUBMITS_LIST: CRMInterestSubmissionsList,
  SUBMIT_CONTACT_INFO: SubmitContactInfo,
  CONTACT_SUBMITS_LIST: ContactSubmissionsList,
}
