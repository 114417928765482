import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  GET_CURRENT_SLEAGUES_QUERY,
  GET_ALL_WEEKS_QUERY
} from "../graphQL/week_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {
  CREATE_FIXTURE_MUTATION,
  CREATE_FIXTURE_TEAM_MUTATION
} from "../graphQL/fixture_qry";
import { GET_ALL_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Fixture
## Description : Submit form data and Add Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function Fixture() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm();
  const { control } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [currentBucketSleagues, setCurrentBucketSleagues] = useState([]);
  const [allWeeks, setAllWeeks] = useState([]);
  const [allSleagueWeeks, setAllSleagueWeeks] = useState([]);
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [allSelectedSleagueTeams, setAllSelectedSleagueTeams] = useState([]);
  const [playedUnknown, setPlayedUnknown] = useState("");
  const [selectedDateTime, handleDateTimeChange] = useState(new Date());
  const [createFixture] = useMutation(CREATE_FIXTURE_MUTATION, {
    awaitRefetchQueries: true
  });
  const [createFixtureTeam] = useMutation(CREATE_FIXTURE_TEAM_MUTATION, {
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUES_QUERY);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      console.log(loading);

      if (!loading && data && data.currentBucketSleague) {
        setCurrentBucketSleagues(data.currentBucketSleague);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  const {
    loading: loadingAllWeeks,
    error: errorAllWeeks,
    data: dataAllWeeks
  } = useQuery(GET_ALL_WEEKS_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(
    () => {
      if (!loadingAllWeeks && dataAllWeeks && dataAllWeeks.allWeeks) {
        setAllWeeks(dataAllWeeks.allWeeks);
      }
    },
    [dataAllWeeks]
  );

  const {
    loading: loadingAllSleagueTeams,
    error: errorAllSleagueTeams,
    data: dataAllSleagueTeams
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);

  useEffect(
    () => {
      if (
        !loadingAllSleagueTeams &&
        dataAllSleagueTeams &&
        dataAllSleagueTeams.allSleagueTeam
      ) {
        setAllSleagueTeams(dataAllSleagueTeams.allSleagueTeam);
      }
    },
    [dataAllSleagueTeams]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : addFixtureTeam
   * Description : add fixture team data
   */
  const addFixtureTeam = (fixtureId, teamId, scoreHalf1, scoreFinal, home) => {

    const { teamData, teamError } = createFixtureTeam({

      variables: {
        fixtureId: parseInt(fixtureId),
        sleagueTeamId: parseInt(teamId),
        scoreHalf1: scoreHalf1,
        scoreFinal: scoreFinal,
        home: home
      }
    });

    if (teamError) {
      console.log("error", teamError);

      setFailedMessage(
        "Unfortunately an error occurred for adding fixture team."
      );
    } else if (teamData && teamData.createFixtureTeam) {
      console.log("Success", teamData.createFixtureTeam);
      setSuccessMessage("New Fixture Team added successfully.");
    }
  };

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    let fixtureDateString = selectedDateTime.toISOString().slice(0, 10);
    let fixtureTimeString = selectedDateTime.toISOString().slice(11, 19);
    let fixtureDate = fixtureDateString + ' ' + fixtureTimeString;
    /*let playedUnknown = formData.playedUnknown==='week-unknown-true';*/
    const { data, error } = await createFixture({
      variables: {
        weekId: parseInt(formData.weekId),
        fixtureDate: fixtureDate,

      }
    });

    if (error) {
      console.log("error", error);

      setFailedMessage("Unfortunately an error occurred.");
    } else if (data && data.createFixture) {
      console.log("Success", data.createFixture);
      //add record to fixture_team
      let fixtureId = data.createFixture.fixture.fixtureId;
      let team1Home,
        team2Home = "";

      if (formData.home == "team1-home") {
        team1Home = true;
        team2Home = false;
      } else {
        team1Home = false;
        team2Home = true;
      }

      addFixtureTeam(
        fixtureId,
        formData.teamId1,
        formData.team1scoreHalf1,
        formData.team1scoreFinal,
        team1Home
      );
      addFixtureTeam(
        fixtureId,
        formData.teamId2,
        formData.team2scoreHalf1,
        formData.team2scoreFinal,
        team2Home
      );

      setSuccessMessage("New Fixture and Fixture Teams added successfully.");
      setTimeout("");
    navigate(`/lms/${admin_url}/fixture`);
    }
  };

  /*
   * Function : updateWeeks
   * Description : update weeks according to sleague change
   */

  const updateWeeks = selectedSleague => {
    let week = [];
    // filter week for selected sleague
    allWeeks.map(i => {
      if (selectedSleague && i.sleagueId.sleagueId === selectedSleague)
        week.push(i);
    });

    setAllSleagueWeeks(week);
  };

  /*
   * Function : updateTeams
   * Description : update teams according to sleague change
   */

  const updateTeams = selectedSleague => {
    let team = [];
    // filter week for selected sleague
    allSleagueTeams.map(i => {
      if (selectedSleague && i.sleagueId.sleagueId === selectedSleague)
        team.push(i);
    });

    setAllSelectedSleagueTeams(team);
  };

  /*
   * Function : sleagueChange
   * Description : handle Sleague change
   */
  const sleagueChange = event => {
    updateWeeks(event.target.value);
    updateTeams(event.target.value);
  };

  const selectWeekOption = event => {
    const weekOption = event.target.value;
    weekOption ==='week-unknown-true'?setPlayedUnknown('true'):setPlayedUnknown('false')
  }


  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Fixture | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add fixture for selected week</h2>
          <label className={adminStyle.label} htmlFor="sleagueId">
            <b>League-Season</b>
          </label>
          <select
            className={adminStyle.select}
            id="sleagueId"
            {...register("sleagueId")}
            onChange={sleagueChange}
          >
            <option value="0">Select a season...</option>
            {currentBucketSleagues.map((currentBucketSleague, i) => (
              <option key={i} value={currentBucketSleague.sleagueId.sleagueId}>
                {currentBucketSleague.sleagueId.leagueId.title +
                  " ( " +
                  currentBucketSleague.sleagueId.seasonName +
                  " ) "}
              </option>
            ))}
          </select>



          <label className={adminStyle.label} htmlFor="weekId">
            <b>Week</b>
          </label>
          <select
            className={adminStyle.select}
            id="weekId"
            {...register("weekId")}

          >
            <option value="0">Select a week...</option>
            {allSleagueWeeks.map((allSleagueWeeks, i) => (
              <option key={i} value={allSleagueWeeks.weekId} selected={i===0?"selected":""}>
                {allSleagueWeeks.name}
              </option>
            ))}
          </select>

          <label className={adminStyle.label} htmlFor="SPExtra">
            PSP Extra
          </label>
          <input
            className={adminStyle.input}
            id="SPExtra"
            {...register("SPExtra")}
            defaultValue="0"
            disabled={true}
          />

          <label className={adminStyle.label} htmlFor="teamId1">
            <b>Team1</b>
          </label>
          <select
            className={adminStyle.select}
            id="teamId1"
            {...register("teamId1")}
          >
            <option value="0">Select a team...</option>

            {allSelectedSleagueTeams.map((allSelectedSleagueTeams, i) => (
              <option key={i} value={allSelectedSleagueTeams.sleagueTeamId}>
                {allSelectedSleagueTeams.teamId.name}
              </option>
            ))}
          </select>
          Home
          <input
            className={adminStyle.input}
            id="home"
            {...register("home")}
            value="team1-home"
            name="home"
            type="radio"
          />
          <label className={adminStyle.label} htmlFor="team1scoreHalf1">
            Half Time Score
          </label>
          <input
            className={adminStyle.input}
            id="team1scoreHalf1"
            {...register("team1scoreHalf1")}
          />
          <label className={adminStyle.label} htmlFor="team1scoreFinal">
            Final Score
          </label>
          <input
            className={adminStyle.input}
            id="team1scoreFinal"
            {...register("team1scoreFinal")}
          />
          <label className={adminStyle.label} htmlFor="teamId2">
            <b>Team2</b>
          </label>
          <select
            className={adminStyle.select}
            id="teamId2"
            {...register("teamId2")}
          >
            <option value="0">Select a team...</option>

            {allSelectedSleagueTeams.map((allSelectedSleagueTeams, i) => (
              <option key={i} value={allSelectedSleagueTeams.sleagueTeamId}>
                {allSelectedSleagueTeams.teamId.name}
              </option>
            ))}
          </select>
          Home
          <input
            className={adminStyle.input}
            id="home"
            {...register("home")}
            value="team2-home"
            name="home"
            type="radio"
          />
          <label className={adminStyle.label} htmlFor="team2scoreHalf1">
            Half Time Score
          </label>
          <input
            className={adminStyle.input}
            id="team2scoreHalf1"
            {...register("team2scoreHalf1")}
          />
          <label className={adminStyle.label} htmlFor="team2scoreFinal">
            Final Score
          </label>
          <input
            className={adminStyle.input}
            id="team2scoreFinal"
            {...register("team2scoreFinal")}
          />
          <label className={adminStyle.label} htmlFor="fixtureDate">
            Fixture Date & Time
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              id="fixtureDate"
              {...register("fixtureDate")}
              value={selectedDateTime}
              onChange={handleDateTimeChange}
            />
          </MuiPickersUtilsProvider>
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
