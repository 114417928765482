import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useMutation, useQuery, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url} from "../store/data/Constants";
// import "../styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { EDIT_PLAYER_MUTATION, GET_PLAYER_BY_ID } from "../graphQL/player_qry";


const SLEAGUE_TEAM_PLAYER_BY_PLAYER_ID = `query sleagueTeamPlayerByPlayerId($playerId:Int!){
  sleagueTeamPlayerByPlayerId(playerId:$playerId){
    sleagueTeamPlayerId
    playerId{
      playerId
      fName
      lName
	  shortName
      position
    }
    jerseyNO
    status
    playerSeqNO

    sleagueTeamId{
      sleagueTeamId
      sleagueId{
        seasonName
        leagueId {
          subtitle
        }
      }
      teamId{
        teamId
        name
        shortName
      }
    }

  }
}`;

const formatDate = day=>{
  console.log(day);
  let today = new Date(day);
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;
  return today;

};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default function PlayerEdit() {
  const {
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { playerId } = useParams();
  
  const [player, setPlayer] = useState([]);

  const navigate = useNavigate();

  const [editPlayer, { loading, error }] = useMutation(EDIT_PLAYER_MUTATION);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);


  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [shortName, setShortName] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [headImage, setHeadImage] = useState("");
  const [position, setPosition] = useState("");
  const [foot, setFoot] = useState("");
  const [power, setPower] = useState("");
  const [speed, setSpeed] = useState("");
  const [defense, setDefense] = useState("");
  const [attack, setAttack] = useState("");
  const [strategy, setStrategy] = useState("");
  const [technical, setTechnical] = useState("");
  const [playerTeam, setPlayerTeam] = useState("");

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };




  const updateFName = e =>{

    setFName(e.target.value);
  }

  const {
    loading: playerLoading,
    error: playerError,
    data: playerData,
  } = useQuery(GET_PLAYER_BY_ID, {
    variables: {
      playerId: parseInt(playerId)
    },
    fetchPolicy: "network-only" });

  useEffect(() => {
    if (!playerLoading && playerData && playerData.playerById) {

      setFName(playerData.playerById.fName);
      setLName(playerData.playerById.lName);
	  setShortName(playerData.playerById.shortName);
      setHeight(playerData.playerById.height);
      setWeight(playerData.playerById.weight);
      setBirthDate(new Date(playerData.playerById.birthDate));
      setHeadImage(playerData.playerById.headImage);
      setPosition(playerData.playerById.position);
      setFoot(playerData.playerById.foot);
      setPower(playerData.playerById.attrPower);
      setSpeed(playerData.playerById.attrSpeed);
      setDefense(playerData.playerById.attrDefense);
      setAttack(playerData.playerById.attrAttack);
      setStrategy(playerData.playerById.attrStrategy);
      setTechnical(playerData.playerById.attrTechnical);
    }
    if (playerError && playerError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [playerData]);

  const {
    loading: splayerLoading,
    error: splayerError,
    data: splayerData,
  } = useQuery(SLEAGUE_TEAM_PLAYER_BY_PLAYER_ID, {
    variables: {
      playerId:parseInt(playerId),
    },
  });

  useEffect(()=>{
    if (!splayerLoading && splayerData.sleagueTeamPlayerByPlayerId){
     setPlayerTeam(splayerData.sleagueTeamPlayerByPlayerId)
    }
  });

  const onSubmit = async formData => {
    // await sleep(2000);
    // console.log("DATA-----------", formData);

    // let bDay = formData.bdate.getDate().toString();
    // if (bDay.length === 1) bDay = "0" + bDay;
    // let bMonth = formData.bdate.getMonth() + 1;
    // if (bMonth.toString().length === 1) bMonth = "0" + bMonth.toString();
    let birthday = birthDate.toISOString().slice(0, 10);; //`${formData.bdate.getFullYear().toString()}-${bMonth}-${bDay}`;

    let newHeight = parseInt(height);
    let newWeight = parseInt(weight);



    const { data, error } = await editPlayer({
      variables: {
        playerId: parseInt(playerId),
        fName: fName,
        lName: lName,
		shortName: shortName,
        height:newHeight,
        weight:newWeight,
        birthDate: birthday,
        foot: foot,
        attrPower: power,
        attrSpeed: speed,
        attrDefense: defense,
        attrAttack: attack,
        attrStrategy: strategy,
        attrTechnical: technical,
        headImage: headImage,
        position: position,
      },
    });
    if (error) {
      console.log("Error", error, data);
      alert("There is an error");
    } else {
      console.log("Success");
      alert("Player has been updated successfully.");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/player`);
      }, 1000);
    }
  };

  // console.log(errors);

  return (
    <>
      <Helmet>
        <title>Predictal | Edit A Player | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Edit Player</h2>
          {playerTeam.sleagueTeamId ? (
            <label className={adminStyle.label} htmlFor="team">
              Team:  <b>{playerTeam.sleagueTeamId.teamId.name} (
              {playerTeam.sleagueTeamId.sleagueId.leagueId.subtitle} - {playerTeam.sleagueTeamId.sleagueId.seasonName})</b>
            </label>
          ) :
          (
            <label className={adminStyle.label} htmlFor="team">
              Current Team:  <b>None</b>
            </label>
          )}
          <div>
            <label className={adminStyle.label} htmlFor="fname">
              First Name
            </label>
            <input
              type="text"
              className={adminStyle.input}
              id="fname"
              {...register("fname")}
              defaultValue={fName}
              onChange={e=>setFName(e.target.value) }

            />


            {errors.fname && errors.fname.type === "required" && <span>Required</span>}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="lname">
              Last Name
            </label>
            <input
              className={adminStyle.input}
              id="lname"
              {...register("lname")}
              defaultValue={lName}
              onChange={e=>setLName(e.target.value)}

            />
            {errors.lname && errors.lname.type === "required" && <span>Required</span>}
          </div>
  		
  		<div>
            <label className={adminStyle.label} htmlFor="shortname">
              Short Name
            </label>
            <input
              className={adminStyle.input}
              id="shortname"
              {...register("shortname")}
              value={shortName}
              onChange={e=>setShortName(e.target.value)}

            />
            
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="height">
              Height (cm)
            </label>
            <input
              className={adminStyle.input}
              type="number"
              min="1"
              {...register("height", {
                pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i,
              })}
              defaultValue={height}
              onChange={e=>setHeight(e.target.value)}

            />
            {errors.height && errors.height.type === "required" && <span>Required</span>}
            {errors.height && errors.height.type === "pattern" && (
              <span>Please enter a valid number</span>
            )}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="weight">
              Weight (kg)
            </label>
            <input
              className={adminStyle.input}
              {...register("weight", {
                pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i,
              })}
              defaultValue={weight}
              onChange={e=>setWeight(e.target.value)}
              type="number"
              min="1"

            />
            {errors.weight && errors.weight.type === "required" && <span>Required</span>}
            {errors.weight && errors.weight.type === "pattern" && (
              <span>Please enter a valid number</span>
            )}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="bdate">
              Birth Date

            </label>
            <Controller
              control={control}
              name="bdate"

              id="bdate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"

                  selected={birthDate}
                  onChange={(date)=>setBirthDate(date)}

                />
              )}
            />
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="headimage">
              Head shot image
            </label>
            <input className={adminStyle.input} {...register("headimage")} type="file"   defaultValue={headImage}
              onChange={e=>setHeadImage(e.target.files)}/>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="position">
              Position {position}
            </label>
            <select className={adminStyle.select} id="position"  {...register("position")} onChange={e=>setPosition(e.target.value)}>
              <option value="Goalkeeper" selected={position!=undefined && position==="Goalkeeper"?"selected":""}>Goalkeeper</option>
              <option value="Defender"   selected={position!=undefined && position==="Defender"?"selected":""}>Defender</option>
              <option value="Midfielder" selected={position!=undefined && position==="Midfielder"?"selected":""}>Midfielder</option>
              <option value="Forward"    selected={position!=undefined && position==="Forward"?"selected":""}>Forward</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="foot">
              Foot
            </label>
            <select className={adminStyle.select} id="foot" {...register("foot")} onChange={e=>setFoot(e.target.value)}>
              <option value="L" selected={foot!=undefined && foot==="L"?"selected":""}>Left</option>
              <option value="R" selected={foot!=undefined && foot==="R"?"selected":""}>Right</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="power">
              Power
            </label>
            <select className={adminStyle.select} id="power" {...register("power")} onChange={e=>setPower(e.target.value)}>
              <option value="1" selected={power!=undefined && power==="1"?"selected":""}>1</option>
              <option value="2" selected={power!=undefined && power==="2"?"selected":""}>2</option>
              <option value="3" selected={power!=undefined && power==="3"?"selected":""}>3</option>
            </select>
          </div>
          <div>
            <label className={adminStyle.label} htmlFor="speed">
              Speed
            </label>
            <select className={adminStyle.select} id="speed" {...register("speed")} onChange={e=>setSpeed(e.target.value)}>
              <option value="1" selected={speed!=undefined && speed==="1"?"selected":""}>1</option>
              <option value="2" selected={speed!=undefined && speed==="2"?"selected":""}>2</option>
              <option value="3" selected={speed!=undefined && speed==="3"?"selected":""}>3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="defense">
              Defense
            </label>
            <select className={adminStyle.select} id="defense" {...register("defense")} onChange={e=>setDefense(e.target.value)}>
              <option value="1" selected={defense!=undefined && defense==="1"?"selected":""}>1</option>
              <option value="2" selected={defense!=undefined && defense==="2"?"selected":""}>2</option>
              <option value="3" selected={defense!=undefined && defense==="3"?"selected":""}>3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="attack">
              Attack
            </label>
            <select className={adminStyle.select} id="attack" {...register("attack")} onChange={e=>setAttack(e.target.value)}>
              <option value="1" selected={attack!=undefined && attack==="1"?"selected":""}>1</option>
              <option value="2" selected={attack!=undefined && attack==="2"?"selected":""}>2</option>
              <option value="3" selected={attack!=undefined && attack==="3"?"selected":""}>3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="strategy">
              Strategy
            </label>
            <select className={adminStyle.select} id="strategy" {...register("strategy")} onChange={e=>setStrategy(e.target.value)}>
              <option value="1" selected={strategy!=undefined && strategy==="1"?"selected":""}>1</option>
              <option value="2" selected={strategy!=undefined && strategy==="2"?"selected":""}>2</option>
              <option value="3" selected={strategy!=undefined && strategy==="3"?"selected":""}>3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="technical">
              Technical
            </label>
            <select className={adminStyle.select} id="technical" {...register("technical")} onChange={e=>setTechnical(e.target.value)}>
              <option value="1" selected={technical!=undefined && technical==="1"?"selected":""}>1</option>
              <option value="2" selected={technical!=undefined && technical==="2"?"selected":""}>2</option>
              <option value="3" selected={technical!=undefined && technical==="3"?"selected":""}>3</option>
            </select>
          </div>

          <input className={adminStyle.input} type="submit" />
        </form>
      </Grid>
    </>
  );
}
