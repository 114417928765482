const AddAlgorithm_mutate = `
    mutation addAlgorithm(
      $algorithmName: String!) {
      addAlgorithm(
        algorithmName: $algorithmName) {
        algorithmId
        algorithmName
      }
    }
`;

const AllAlgorithms_query = `
    query{
      allAlgorithms{
        algorithmId
        algorithmName
        updateBy
        updateDate
        createBy
        createDate
      }
    }
`;

const CommunityTSResult_qry = `
query($fixtureTeamId:Int!) {
  communityTSResult (fixtureTeamId:$fixtureTeamId)
  {
  sleagueTeamPlayerId{
    playerSeqNO
	currentTeam
	jerseyNO
    playerId{
      fName
      lName
      name
      position
    }
  }
    positionSeqNO1Vote
    positionSeqNO2Vote
    positionSeqNO3Vote
    positionSeqNO4Vote
    positionSeqNO5Vote
    positionSeqNO6Vote
    positionSeqNO7Vote
    positionSeqNO8Vote
    positionSeqNO9Vote
    positionSeqNO10Vote
    positionSeqNO11Vote

  }
}`;

const RunSelectionTest_mutate = `
    mutation RunSelectionTest(
      $usersNo: Int!,
	  $sleagueTeamId: Int!,
	  $fixtureTeamId: Int!,
	  $formation1: Int!,
	  $formation2: Int!,
	  $formation3: Int!,
	  $formation4: Int!
	  ) {
      RunSelectionTest(
        usersNo: $usersNo,
		sleagueTeamId: $sleagueTeamId,
		fixtureTeamId: $fixtureTeamId,
		formation1: $formation1,
		formation2: $formation2,
		formation3: $formation3,
		formation4: $formation4) {
			formationCode
			formationTitle
			maxFormation
			votes
			players
            invalidSeqs
            blankFormations
            nonExistFormations
            blankPlayer
            nonExistPlayer
      }
    }
`;

module.exports = {
  ADD_ALGORITHM_MUTATION: AddAlgorithm_mutate,
  ALL_ALGORITHMS_QUERY: AllAlgorithms_query,
  COMMUNITY_TEAM_SELECTION_QUERY: CommunityTSResult_qry,
  RUN_SELECTION_TEST_MUTATION: RunSelectionTest_mutate
}
