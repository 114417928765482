import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { seo_tag_config } from "../../store/data/Constants";

import Autocomplete from "@mui/material/Autocomplete";
import logoImg from "../../assets/preditcal_logo.png";

import AlertDialog from "../../components/AlertDialog.js";

import { CREATE_USER_MUTATION } from "../../graphQL/register_qry";
import { GET_CURRENT_SLEAGUETEAMS_QUERY } from "../../graphQL/sleague_qry";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    //maxWidth: 672,
    height: 956,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // paddingTop: 40,
    minHeight: 400,
    maxWidth: 430,
    //  minWidth: 310,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 956,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 956,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
    //  maxWidth: 700,
  },
  autoComStyleMobile: {
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 30,
      marginBottom: 5,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  autoComStyle: {
    "& .MuiFormControl-marginNormal": {
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Registration = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");

  const [options, setOptions] = useState([]);

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    refetchQueries: [{ query: GET_CURRENT_SLEAGUETEAMS_QUERY }],
    awaitRefetchQueries: true,
  });

  const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data && data.currentSleagueTeam) {
      setAllSleagueTeams(data.currentSleagueTeam);
    }
  }, [data]);

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMsg("");
    // setErrorMsg("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    if (formData.password === formData.repeatPassword) {
      // await sleep(2000);

      const { data, error } = await createUser({
        variables: {
          email: formData.email,
          password: formData.password,
          repeatPassword: formData.repeatPassword,
          username: formData.username,
          favTeam: selectedTeam.id,
        },
      });

      if (error) {
        console.log("Error", error, data);
        setErrorMsg(error.graphQLErrors[0].message);
        // setTimeout("");
      } else {
        console.log("Success");
        setSuccessMsg(
          "You must activate your account via email notification you will receive."
        );
        // setTimeout("");
        // Sahar RTS-1093
        localStorage.setItem("userRegisterEmail", formData.email);
        //
        navigate("/lms/register_success");
      }
    } else alert("Password and its repeat must be the same");
  };

  useEffect(() => {
    console.log("executed only once!");
    setTeams();
  }, [allSleagueTeams]);

  const setTeams = async () => {
    let newOptions = [];
    await sleep(1000);
    allSleagueTeams.map(sleagueteam => {
      let newOpt = { id: sleagueteam.sleagueTeamId, label: sleagueteam.teamId.shortName };
      newOptions.push(newOpt);
    });
    setOptions(newOptions);
    console.log(newOptions);
  };

  const errorMsgDlgClose = e => {
    setErrorMsg("");
  };

  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Registration</title>
          <meta
            name="description"
            content="Predictal user registration to join football prediction"
          />
          <meta
            property="keywords"
            content="Predcital, user registration, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction"
          />
        </Helmet>
      }
      
      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <Box alignItems="center" mb={3} flexGrow={1}>
              <Box
                flexGrow={1}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <Typography gutterBottom variant="subtitle2">
                    Register
                  </Typography>
                </div>
              </Box>
              <Typography variant="body2">
                Predictal is a premier global destination that connects sports fans to
                share and challenge their knowledge about sports in a safe and
                collaborative environment. At its inaugural launch, Predictal is home to
                the leading digital experience platform for Team Selector in the sport of
                football.
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box flexGrow={1} mb={1}>
                <TextField
                  required
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  //autoFocus
                  label="Email" // Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />

                {errors.email && errors.email.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Email address cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Invalid email format.
                    </FormHelperText>
                  </Box>
                )}

                <TextField
                  required
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  id="password"
                  type="password"
                  {...register("password", { required: true })}
                />
                {errors.password && errors.password.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Password cannot be blank.
                    </FormHelperText>
                  </Box>
                )}

                <TextField
                  required
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Repeat Password"
                  margin="normal"
                  name="repeatPassword"
                  type="password"
                  variant="outlined"
                  id="repeatPassword"
                  type="password"
                  {...register("repeatPassword", {
                    required: true,
                    validate: value => {
                      const { password } = getValues();
                      return password === value;
                    },
                  })}
                />
                {errors.repeatPassword && errors.repeatPassword.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Repeat Password cannot be blank
                    </FormHelperText>
                  </Box>
                )}
                {errors.repeatPassword && errors.repeatPassword.type === "validate" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Password and Repeat Password dose not match
                    </FormHelperText>
                  </Box>
                )}
                {!errors.repeatPassword && (
                  <Box ml={2}>
                    <Typography variant="h6">Enter your password again</Typography>
                  </Box>
                )}

                <TextField
                  required
                  className={classes.textFieldStyleMobile}
                  fullWidth
                  label="Username"
                  margin="normal"
                  name="username"
                  variant="outlined"
                  id="username"
                  {...register("username", {
                    required: true,
                    pattern: /^[a-zA-Z0-9]{6,12}$/i,
                  })}
                />
                {errors.username && errors.username.type === "required" && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Username cannot be blank.
                    </FormHelperText>
                  </Box>
                )}
                {errors.username && errors.username.type === "pattern" ? (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      6 to 12 alpha-numeric characters.
                      <br /> No spaces, no special characters.
                    </FormHelperText>
                  </Box>
                ) : (
                  <Box ml={2} mr={2}>
                    <Typography variant="h6">
                      6 to 12 alpha-numeric characters.
                      <br /> No spaces, no special characters.
                    </Typography>
                  </Box>
                )}

                <Autocomplete
                  options={options}
                  className={classes.autoComStyle}
                  disableClearable
                  fullWidth
                  getOptionLabel={option => option.label}
                  onChange={(_event, newTeam) => {
                    setSelectedTeam(newTeam);
                  }}
                  renderInput={params => (
                    <TextField
                      required
                      fullWidth
                      label="Favourite English Premier League Team"
                      margin="normal"
                      name="favTeam"
                      id="favTeam"
                      {...register("favTeam")}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />

                <Box ml={2}>
                  <Typography variant="h6">
                    This is the team you will create your Starting XI
                  </Typography>
                </Box>
                {successMsg && (
                  <Box mt={2} className={classes.successStyleMobile}>
                    {successMsg}
                  </Box>
                )}
                <Box mt={5} display="flex" justifyContent="center">
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
              <Box mt={2} mb={2} display="flex" alignItems="center">
                <Typography variant="h5">Already have an account?</Typography>
                <Box ml={0.5}>
                  <Link
                    component={RouterLink}
                    to="/lms/signin"
                    variant="h4"
                    color="primary"
                  >
                    Sign in
                  </Link>
                </Box>
              </Box>
            </form>
          </CardContent>
        </Card>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet}>
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography gutterBottom variant="subtitle1">
                      Register
                    </Typography>
                  </div>
                </Box>
                <Typography variant="body1">
                  Predictal is a premier global destination that connects sports fans to
                  share and challenge their knowledge about sports in a safe and
                  collaborative environment. At its inaugural launch, Predictal is home to
                  the leading digital experience platform for Team Selector in the sport
                  of football.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box flexGrow={1} mb={1}>
                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    //autoFocus
                    label="Email" // Address"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Email address cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Invalid email format.
                      </FormHelperText>
                    </Box>
                  )}

                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    id="password"
                    type="password"
                    {...register("password", { required: true })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Repeat Password"
                    margin="normal"
                    name="repeatPassword"
                    type="password"
                    variant="outlined"
                    id="repeatPassword"
                    type="password"
                    {...register("repeatPassword", {
                      required: true,
                      validate: value => {
                        const { password } = getValues();
                        return password === value;
                      },
                    })}
                  />
                  {errors.repeatPassword && errors.repeatPassword.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Repeat Password cannot be blank
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.repeatPassword && errors.repeatPassword.type === "validate" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Password and Repeat Password dose not match
                      </FormHelperText>
                    </Box>
                  )}
                  {!errors.repeatPassword && (
                    <Box ml={2}>
                      <Typography variant="h6">Enter your password again</Typography>
                    </Box>
                  )}

                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Username"
                    margin="normal"
                    name="username"
                    variant="outlined"
                    id="username"
                    {...register("username", {
                      required: true,
                      pattern: /^[a-zA-Z0-9]{6,12}$/i,
                    })}
                  />
                  {errors.username && errors.username.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Username cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.username && errors.username.type === "pattern" ? (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        6 to 12 alpha-numeric characters.
                        <br /> No spaces, no special characters.
                      </FormHelperText>
                    </Box>
                  ) : (
                    <Box ml={2} mr={2}>
                      <Typography variant="h6">
                        6 to 12 alpha-numeric characters.
                        <br /> No spaces, no special characters.
                      </Typography>
                    </Box>
                  )}

                  <Autocomplete
                    options={options}
                    className={classes.autoComStyle}
                    disableClearable
                    fullWidth
                    getOptionLabel={option => option.label}
                    onChange={(_event, newTeam) => {
                      setSelectedTeam(newTeam);
                    }}
                    renderInput={params => (
                      <TextField
                        required
                        fullWidth
                        label="Favourite English Premier League Team"
                        margin="normal"
                        name="favTeam"
                        id="favTeam"
                        {...register("favTeam")}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />

                  <Box ml={2}>
                    <Typography variant="h5">
                      This is the team you will create your Starting XI
                    </Typography>
                  </Box>
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box mt={5} display="flex" justifyContent="center">
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box mt={2} mb={2} display="flex" alignItems="center">
                  <Typography variant="h3">Already have an account?</Typography>
                  <Box ml={0.5}>
                    <Link
                      component={RouterLink}
                      to="/lms/signin"
                      variant="h2"
                      color="primary"
                    >
                      Sign in
                    </Link>
                  </Box>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box mb={2}>
                  <img src={logoImg} alt="logo" />
                </Box>
                <Typography variant="body1">
                  Predictal is a premier global destination that connects sports fans to
                  share and challenge their knowledge about sports in a safe and
                  collaborative environment. At its inaugural launch, Predictal is home to
                  the leading digital experience platform for Team Selector in the sport
                  of football.
                </Typography>
              </Box>
            </CardContent>

            <Divider orientation="vertical" flexItem />
            <CardContent className={classes.cardContent} style={{ minWidth: 350 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ marginBottom: 0 }}
                  >
                    Register
                  </Typography>
                </Box>

                <Box flexGrow={1} mb={1}>
                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    //autoFocus
                    label="Email" // Address"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Email address cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Invalid email format.
                      </FormHelperText>
                    </Box>
                  )}
                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    id="password"
                    type="password"
                    {...register("password", { required: true })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Password cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}

                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Repeat Password"
                    margin="normal"
                    name="repeatPassword"
                    type="password"
                    variant="outlined"
                    id="repeatPassword"
                    type="password"
                    {...register("repeatPassword", {
                      required: true,
                      validate: value => {
                        const { password } = getValues();
                        return password === value;
                      },
                    })}
                  />
                  {errors.repeatPassword && errors.repeatPassword.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Repeat Password cannot be blank
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.repeatPassword && errors.repeatPassword.type === "validate" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Password and Repeat Password dose not match
                      </FormHelperText>
                    </Box>
                  )}
                  {!errors.repeatPassword && (
                    <Box ml={2}>
                      <Typography variant="h6">Enter your password again</Typography>
                    </Box>
                  )}

                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="Username"
                    margin="normal"
                    name="username"
                    variant="outlined"
                    id="username"
                    {...register("username", {
                      required: true,
                      pattern: /^[a-zA-Z0-9]{6,12}$/i,
                    })}
                  />
                  {errors.username && errors.username.type === "required" && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Username cannot be blank.
                      </FormHelperText>
                    </Box>
                  )}
                  {errors.username && errors.username.type === "pattern" ? (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        6 to 12 alpha-numeric characters.
                        <br /> No spaces, no special characters.
                      </FormHelperText>
                    </Box>
                  ) : (
                    <Box ml={2} mr={2}>
                      <Typography variant="h6">
                        6 to 12 alpha-numeric characters.
                        <br /> No spaces, no special characters.
                      </Typography>
                    </Box>
                  )}

                  <Autocomplete
                    options={options}
                    className={classes.autoComStyle}
                    disableClearable
                    fullWidth
                    getOptionLabel={option => option.label}
                    onChange={(_event, newTeam) => {
                      setSelectedTeam(newTeam);
                    }}
                    renderInput={params => (
                      <TextField
                        required
                        fullWidth
                        label="Favourite English Premier League Team"
                        margin="normal"
                        name="favTeam"
                        id="favTeam"
                        {...register("favTeam")}
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                  <Box ml={2}>
                    <Typography variant="h5">
                      This is the team you will create your Starting XI
                    </Typography>
                  </Box>
                  {successMsg && (
                    <Box mt={2} className={classes.successStyle}>
                      {successMsg}
                    </Box>
                  )}
                  <Box mt={5} display="flex" justifyContent="center">
                    <Button
                      className={classes.buttonStyle}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box mt={2} mb={2} display="flex" alignItems="center">
                  <Typography variant="h3">Already have an account?</Typography>
                  <Box ml={0.5}>
                    <Link
                      component={RouterLink}
                      to="/lms/signin"
                      variant="h2"
                      color="primary"
                    >
                      Sign in
                    </Link>
                  </Box>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      )}

      {errorMsg && (
        <AlertDialog title="Alert" closeDialog={errorMsgDlgClose} contentMsg={errorMsg} />
      )}
    </>
  );
};

export default Registration;
