import { Outlet, Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Fragment, useState, useEffect } from "react";
import adminStyle from "./Admin.module.scss";
import CryptoJS from "crypto-js";
import { project_url, admin_url, secretPass } from "../store/data/Constants";

/*##########################################################################
## Const : Layout
## Description : Layout navigation
## Author : SP
## Date: : 2022/02/28
##########################################################################*/
const Layout = () => {
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const role = CryptoJS.AES.decrypt(localStorage.getItem("role"), secretPass).toString(
    CryptoJS.enc.Utf8
  );
  let isAdmin = false;
  if (role === "Admin") isAdmin = true;

  useEffect(() => {
    setUsername(localStorage.getItem("username"));
  }, [localStorage.getItem("username")]);

  return (
    <Grid container direction="column" item xs={3}>
      {username !== "" ? (
        <>
          <span>
            <h5>
              {username} &nbsp;&nbsp;|
              {isAdmin && (
                <a href={`/lms/${admin_url}/signout`} className={adminStyle.linkStyle}>
                  Sign out
                </a>
              )}
              {!isAdmin && (
                <a href={`/lms/signout`} className={adminStyle.linkStyle}>
                  Sign out
                </a>
              )}
            </h5>
          </span>
          {role === "Admin" ? (
            <>
              <div>
                <Typography>League Management System</Typography>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/formation`} className={adminStyle.linkStyle}>
                  Formations
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/formation/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/formation-position`}
                  className={adminStyle.linkStyle}
                >
                  Formation Positions
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/formation-position/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/position`} className={adminStyle.linkStyle}>
                  Positions
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/position/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/filter`} className={adminStyle.linkStyle}>
                  Filters
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/filter/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/algorithm`} className={adminStyle.linkStyle}>
                  Algorithms
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/algorithm/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/team`} className={adminStyle.linkStyle}>
                  Teams
                </Link>{" "}
                |
                <Link to={`/lms/${admin_url}/team/add`} className={adminStyle.linkStyle}>
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/player`} className={adminStyle.linkStyle}>
                  Players
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/player/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/ts-compute-pos-slice`} className={adminStyle.linkStyle}>
                  Formations Positions Calculator
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/setting`} className={adminStyle.linkStyle}>
                  Settings
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/setting/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/server-date`}
                  className={adminStyle.linkStyle}
                >
                  Server Date
                </Link>{" "}
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>League-Season Setup</Typography>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/league`} className={adminStyle.linkStyle}>
                  Leagues
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/league/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/league-formation`}
                  className={adminStyle.linkStyle}
                >
                  League Formations
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/league-formation/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/sleague`} className={adminStyle.linkStyle}>
                  League Seasons
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/sleague/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-award`}
                  className={adminStyle.linkStyle}
                >
                  League Season Awards
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/sleague-award/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-week`}
                  className={adminStyle.linkStyle}
                >
                  League Season Weeks
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/sleague-week/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-team`}
                  className={adminStyle.linkStyle}
                >
                  League Season Teams
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/sleague-team/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-team-player`}
                  className={adminStyle.linkStyle}
                >
                  League Season Team Players
                </Link>
                |
                <Link
                  to={`/lms/${admin_url}/sleague-team-player/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>League-Season Management</Typography>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/current-bucket-sleague`}
                  className={adminStyle.linkStyle}
                >
                  Current League Seasons Bucket
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/current-bucket-week`}
                  className={adminStyle.linkStyle}
                >
                  Current League Season Weeks Bucket
                </Link>
              </div>
              <div>
                <Link to={`/lms/${admin_url}/fixture`} className={adminStyle.linkStyle}>
                  Fixtures
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/fixture/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/fixture/lock`}
                  className={adminStyle.linkStyle}
                >
                  Lock Fixtures
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/fixture-player-stats`}
                  className={adminStyle.linkStyle}
                >
                  Fixture Player Stats (Old)
                </Link>{" "}
                |
                <Link
                  to={`/lms/${admin_url}/fixture-player-stats/add`}
                  className={adminStyle.linkStyle}
                >
                  New
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/fixture-player-stats-improved`}
                  className={adminStyle.linkStyle}
                >
                  Fixture Player Stats
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/fixture-player-stats-improved-full`}
                  className={adminStyle.linkStyle}
                >
                  Fixture Player Stats (WIP)
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-table`}
                  className={adminStyle.linkStyle}
                >
                  League Season Table
                </Link>{" "}
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/community_selection`}
                  className={adminStyle.linkStyle}
                >
                  Generate Community Team
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sp_calculator`}
                  className={adminStyle.linkStyle}
                >
                  Calculate PSP
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/fixtures`}
                  className={adminStyle.linkStyle}
                  target="_blank"
                >
                  Team Selection Results
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/tsresultsemail`}
                  className={adminStyle.linkStyle}
                >
                  Team Selection Results Notification
                </Link>{" "}
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sleague-team-ranking`}
                  className={adminStyle.linkStyle}
                >
                  League Season Teams Ranking
                </Link>{" "}
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>User Management</Typography>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/user/users`}
                  className={adminStyle.linkStyle}
                >
                  Users
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/notifyUserEmail`}
                  className={adminStyle.linkStyle}
                >
                  Notify Users
                </Link>{" "}
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>CRM</Typography>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/crm/crm-interest-submissions`}
                  className={adminStyle.linkStyle}
                >
                  Interested People
                </Link>{" "}
              </div>
              <div>
                <Link to={`/lms/${admin_url}/contact`} className={adminStyle.linkStyle}>
                  Contacts
                </Link>{" "}
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>Test Automation</Typography>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/test_teamselection`}
                  className={adminStyle.linkStyle}
                >
                  Test Team Selection Results
                </Link>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/sp_award_automation_script`}
                  className={adminStyle.linkStyle}
                >
                  Test PSP Calculator
                </Link>
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>Query</Typography>
              </div>
              <div>
                <Link
                  to={`/lms/${admin_url}/users_teamselection`}
                  className={adminStyle.linkStyle}
                >
                  Users Team Selection
                </Link>
              </div>

              <div>
                <Link
                  to={`/lms/${admin_url}/player_assignment`}
                  className={adminStyle.linkStyle}
                >
                  Player Assignment
                </Link>
              </div>

              <div>
                <Link
                  to={`/lms/${admin_url}/gamestat_positionseqno`}
                  className={adminStyle.linkStyle}
                >
                  Update Seq No in Players Stats
                </Link>
              </div>

              <div>
                <Link
                  to={`/lms/${admin_url}/set_user_fav_team_seq`}
                  className={adminStyle.linkStyle}
                >
                  Set User favarite team
                </Link>
              </div>

              <div className={adminStyle.divider} />
              <div>
                <Typography>Admin Center Menu System</Typography>
              </div>
              <div className={adminStyle.divider} />
              <div>
                <Typography>Act as Regular User</Typography>
              </div>
              <div>
                <Link to="/lms/profile" className={adminStyle.linkStyle}>
                  User Profile
                </Link>
              </div>
            </>
          ) : (
            <>
              <Link to="/lms/profile_v1" className={adminStyle.linkStyle}>
                User Profile (V1)
              </Link>
              <Link to="/lms/profile" className={adminStyle.linkStyle}>
                User Profile
              </Link>
              <div>
                <Link to="/lms/favTeamResults" className={adminStyle.linkStyle}>
                  My Starter XI Teams
                </Link>
              </div>
            </>
          )}
        </>
      ) : (
        <Link to="/lms/signin" className={adminStyle.linkStyle}>
          Sign in
        </Link>
      )}
    </Grid>
  );
};

export default Layout;
