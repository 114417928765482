import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";

import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import Tooltip from "@material-ui/core/Tooltip";

import leagueLogo from "../../assets/Premier_League-Logo.png";
import westHamLogo from "../../assets/West_Ham_United.png";
import chelseaLogo from "../../assets/Chelsea.png";
import footField from "../../assets/positionImgs/football_field.png";
import footFieldMobile from "../../assets/positionImgs/football_field_mobile.png";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 1366,
    // minHeight: 818,
  },
  cardContent: {
    // padding: theme.spacing(3),
    // paddingLeft: 76,
    // paddingTop: 23,
    // paddingRight: 76,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // position: "relative",
    // minHeight: 100,
    // borderRadius: 8,
  },

  cardContainerTablet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 768,

    // paddingLeft: 48,
    // paddingRight: 48,
    // maxWidth: 900,
    // height: 648,
  },

  cardContainerMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 18,
    paddingTop: 24,
    minWidth: 360,

    // paddingBottom: 38,
    // paddingTop: 38,
    // paddingLeft: 5,
    // paddingRight: 5,
  },

  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },

  overview: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "column-reverse",
    //   alignItems: "flex-start",
    // },
  },
  teamImage: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: 40,
    width: 40,
  },

  footImage: {
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    height: 408,
    width: 580,
  },

  teamSel: {
    paddingTop: 24,
    paddingBottom: 24,
    // paddingLeft: 221,
  },

  formationStyle: {
    // textAlign: "left",
    paddingTop: 12,
    paddingBottom: 24,
    // paddingLeft: 221,
  },
}));

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px;
  }
`;

const CoachCommunity = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fixtureName, setFixtureName] = useState(
    "West Ham" + " " + "3" + " - " + "4" + " " + "Chelsea"
  );
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Predictal | Community vs Coach selection</title>
      </Helmet> 
      {mobileView && (
        <Container className={classes.cardContainerMobile} maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    mt={0.5}
                    // mb={0.5}
                    // style={{
                    //   display: "flex",
                    // }}
                  >
                    <StyledTooltip title="Return to Fixtures">
                      <IconButton
                        edge="start"
                        color="primary"
                        onClick={() => {
                          navigate("/lms/fixtures");
                        }}
                        size="large"
                      >
                        <KeyboardReturn sx={{ color: "#662483" }} fontSize="inherit" />
                      </IconButton>
                    </StyledTooltip>
                  </Box>

                  <Box
                    // mt={2}
                    // mb={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // position: "relative",
                    }}
                  >
                    <Box
                      // style={{ display: "flex", textAlign: "center" }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img className={classes.teamImage} src={westHamLogo} />
                      <Typography variant="subtitle2">
                        &nbsp;&nbsp;3 &nbsp;- &nbsp;&nbsp;4&nbsp;&nbsp;
                      </Typography>
                      <img className={classes.teamImage} src={chelseaLogo} />
                    </Box>
                    <Box
                      // style={{ display: "flex", textAlign: "center" }}
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle2">
                        West Ham&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Newcastle
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Paper style={{ width: "100%" }}>
                <Box
                  // mt={2}
                  // mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // position: "relative",
                  }}
                >
                  <Box
                    mt={2}
                    mb={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to="#"
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#00000099",
                        marginLeft: "50px",
                      }}
                    >
                      My Team
                    </Link>
                    <Link
                      to="#"
                      style={{ fontSize: 14, fontWeight: 500, color: "#6A1B9A" }}
                    >
                      Community
                    </Link>
                    <Link
                      to="#"
                      style={{ fontSize: 14, fontWeight: 500, color: "#00000099" }}
                    >
                      Coach
                    </Link>
                    <Link
                      to="#"
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#00000099",
                        marginRight: "50px",
                      }}
                    >
                      Votes
                    </Link>
                  </Box>
                  <Divider variant="fullWidth" />

                  <Box mt={3} style={{ textAlign: "center" }}>
                    <img src={footFieldMobile} />
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="h2" className={classes.formationStyle}>
                      Formation: 3 - 5 - 4 - 1
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent>
                  <Box
                    // mt={0.5}
                    // mb={0.5}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Box flexGrow={0.5}>
                      <StyledTooltip title="Return to Fixtures">
                        <IconButton
                          edge="start"
                          color="primary"
                          onClick={() => {
                            navigate("/lms/fixtures");
                          }}
                          size="large"
                        >
                          <KeyboardReturn sx={{ color: "#662483" }} fontSize="inherit" />
                        </IconButton>
                      </StyledTooltip>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img className={classes.teamImage} src={westHamLogo} />
                      <Typography variant="subtitle2">{fixtureName}</Typography>
                      <img className={classes.teamImage} src={chelseaLogo} />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Paper style={{ width: "100%" }}>
                <Box
                  // mt={2}
                  // mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    // position: "relative",
                  }}
                >
                  <Box
                    mt={2}
                    mb={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to="#"
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#00000099",
                        marginLeft: "50px",
                      }}
                    >
                      My Team
                    </Link>
                    <Link
                      to="#"
                      style={{ fontSize: 14, fontWeight: 500, color: "#6A1B9A" }}
                    >
                      Community
                    </Link>
                    <Link
                      to="#"
                      style={{ fontSize: 14, fontWeight: 500, color: "#00000099" }}
                    >
                      Coach
                    </Link>
                    <Link
                      to="#"
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#00000099",
                        marginRight: "50px",
                      }}
                    >
                      Votes
                    </Link>
                  </Box>
                  <Divider variant="fullWidth" />

                  <Box mt={3} style={{ textAlign: "center" }}>
                    <img src={footField} />
                  </Box>
                  <Box style={{ textAlign: "center" }}>
                    <Typography variant="h2" className={classes.formationStyle}>
                      Formation: 3 - 5 - 4 - 1
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  {/* <Paper variant="outlined"> */}
                  <Box className={classes.overview}>
                    <div>
                      <StyledTooltip title="Return to Fixtures">
                        <IconButton
                          edge="start"
                          color="primary"
                          onClick={() => {
                            navigate("/lms/fixtures");
                          }}
                          size="large"
                        >
                          <KeyboardReturn sx={{ color: "#662483" }} fontSize="inherit" />
                        </IconButton>
                      </StyledTooltip>
                    </div>
                    <Box display="flex" alignItems="center">
                      <img className={classes.teamImage} src={westHamLogo} />
                      <Typography variant="subtitle2">{fixtureName}</Typography>
                      <img className={classes.teamImage} src={chelseaLogo} />
                    </Box>

                    <div>
                      <img src={leagueLogo} alt="leagueLogo" height={39} width={88} />
                    </div>
                  </Box>
                  <Divider variant="fullWidth" />
                  <Box className={classes.details}>
                    <Box display="flex" justifyContent="center" flexDirection="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#6A1B9A",
                              },
                            }}
                          />
                        }
                        label="My Team"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked
                            sx={{
                              "&.Mui-checked": {
                                color: "#6A1B9A",
                              },
                            }}
                          />
                        }
                        label="Community Team"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked
                            sx={{
                              "&.Mui-checked": {
                                color: "#6A1B9A",
                              },
                            }}
                          />
                        }
                        label="Coach Team"
                      />
                    </Box>
                  </Box>
                  {/* </Paper> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={6} style={{ paddingTop: 2 }}>
              <Paper style={{ width: "100%", minWidth: 650, height: 570 }}>
                <Box
                  mt={2}
                  mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Typography variant="subtitle2" className={classes.teamSel}>
                    Community Team
                  </Typography>

                  <Box className={classes.footImage}>
                    <img src={footField} />
                  </Box>
                  <Box
                    style={{
                      width: "90%",
                    }}
                  >
                    <Typography variant="h2" className={classes.formationStyle}>
                      Formation: 3 - 5 - 1 - 1
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={6} sm={6} md={6} style={{ paddingTop: 2 }}>
              <Paper style={{ width: "100%", minWidth: 650, height: 570 }}>
                <Box
                  mt={2}
                  mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Typography variant="subtitle2" className={classes.teamSel}>
                    Coach Team
                  </Typography>

                  <Box className={classes.footImage}>
                    <img src={footField} />
                  </Box>
                  <Box
                    style={{
                      width: "90%",
                    }}
                  >
                    <Typography variant="h2" className={classes.formationStyle}>
                      Formation: 3 - 5 - 1 - 1
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default CoachCommunity;
