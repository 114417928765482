import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {project_url, admin_url} from "../store/data/Constants";

const ALL_LEAGUES_QUERY = `query{
  league{
    leagueId
    title
    subtitle
  }
}`;

const SLEAGUE_QUERY = `query sleague($leagueId:Int!){
  sleague(leagueId:$leagueId){
    sleagueId
    title
    seasonName
  }
}`;

const SLEAGUETEAM_QUERY = `query sleagueTeam($sleagueId:Int!){
  sleagueTeam(sleagueId:$sleagueId){
    sleagueTeamId
    teamId{
      teamId
      name
      shortName
    }
  }
}`;

const SLEAGUETEAMPLAYES_QUERY = `query sleagueTeamPlayer($sleagueTeamId:Int!){
  sleagueTeamPlayer(sleagueTeamId:$sleagueTeamId){
    sleagueTeamPlayerId
    playerId{
      fName
      lName
	  shortName
	  name
      position
    }
    jerseyNO
    status
    playerSeqNO

  }
}`;

export default function SleagueTeamPlayerList() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [pageSize, setPageSize] = React.useState(40);
  const navigate = useNavigate();

  // Set All Leagues value
  const [allLeagues, setAllLeagues] = useState([]);
  const allLeaguesQuery = useQuery(ALL_LEAGUES_QUERY);
  useEffect(() => {
    if (allLeaguesQuery.data && allLeaguesQuery.data.league) {
      setAllLeagues(allLeaguesQuery.data.league);
    }
  }, [allLeaguesQuery.data]);

  // Set list of Seasons based on the selected League
  const [leagueSeasons, setLeagueSeasons] = useState([]);

  const [currLeagueId, setCurrLeagueId] =
    useState(JSON.parse(localStorage.getItem("ReturnFromEditPage")) === true ?
      parseInt(localStorage.getItem("leagueId")) : -1);

  const {
    loading: leagueSeasonsLoading,
    error: leagueSeasonsError,
    data: leagueSeasonsData,
  } = useQuery(SLEAGUE_QUERY, {
    variables: {
      leagueId: currLeagueId,
    },
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };
  useEffect(() => {
    if (leagueSeasonsData && leagueSeasonsData.sleague) {
      setLeagueSeasons(leagueSeasonsData.sleague);
    }
  }, [leagueSeasonsData]);

  // Set list of Teams based on the selected Season
  const [sLeagueTeams, setSLeagueTeams] = useState([]);

  const [currSleagueId, setCurrSleagueId] =
    useState(JSON.parse(localStorage.getItem("ReturnFromEditPage")) === true ?
      parseInt(localStorage.getItem("SleagueId")) : -1);

  const {
    loading: teamsLoading,
    error: teamsError,
    data: currTeams,
  } = useQuery(SLEAGUETEAM_QUERY, {
    variables: {
      sleagueId: currSleagueId,
    },
  });
  useEffect(() => {
    if (currTeams && currTeams.sleagueTeam) {
      setSLeagueTeams(currTeams.sleagueTeam);
    }
  }, [currTeams]);

  // Set list of Players based on the selected Team
  // const [sLeagueTeamPlayers, setSLeagueTeamPlayers] = useState([]);
  const [rows, setRows] =
    useState(JSON.parse(localStorage.getItem("ReturnFromEditPage")) === true ?
      JSON.parse(localStorage.getItem("rows")) : []);

  const [currSleagueTeamId, setCurrSleagueTeamId] =
    useState(JSON.parse(localStorage.getItem("ReturnFromEditPage")) === true ?
      parseInt(localStorage.getItem("SleagueTeamId")) : -1);

  const {
    loading: sLeagueTeamPlayersLoading,
    error: sLeagueTeamPlayersError,
    data: sLeagueTeamPlayersData,
  } = useQuery(SLEAGUETEAMPLAYES_QUERY, {
    variables: {
      sleagueTeamId: currSleagueTeamId,
    },
  });
  useEffect(() => {
    if (sLeagueTeamPlayersData && sLeagueTeamPlayersData.sleagueTeamPlayer) {
      // setSLeagueTeamPlayers(sLeagueTeamPlayersData.sleagueTeamPlayer);
      setRows(sLeagueTeamPlayersData.sleagueTeamPlayer);
      localStorage.setItem("rows",
          JSON.stringify(sLeagueTeamPlayersData.sleagueTeamPlayer));
      localStorage.setItem("ReturnFromEditPage", false);
    }
  }, [sLeagueTeamPlayersData]);

  const getFirstName = rows => {
    return rows.row.playerId.fName;
  };

  const getLastName = rows => {
    return rows.row.playerId.lName;
  };
  
  const getShortName = rows => {
    return rows.row.playerId.shortName;
  };

  const getPosition = rows => {
    return rows.row.playerId.position;
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "First Name",
      valueGetter: getFirstName,
      width: 150,
      renderCell: rows => (
        <a
          href={`/lms/${admin_url}/sleague-team-player/edit/${rows.row.sleagueTeamPlayerId}`}
          target="_self"
        >
          {rows.row.playerId.fName}
        </a>
      ),
      // editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      valueGetter: getLastName,
      width: 150,
      renderCell: rows => (
        <a
          href={`/lms/${admin_url}/sleague-team-player/edit/${rows.row.sleagueTeamPlayerId}`}
          target="_self"
        >
          {rows.row.playerId.lName}
        </a>
      ),
      // editable: true,
    },
	
	{
      field: "shortName",
      headerName: "Short Name",
      valueGetter: getShortName,
      width: 150,
      renderCell: rows => (
        <a
          href={`/lms/${admin_url}/sleague-team-player/edit/${rows.row.sleagueTeamPlayerId}`}
          target="_self"
        >
          {rows.row.playerId.shortName}
        </a>
      ),
      // editable: true,
    },
	
    {
      field: "position",
      headerName: "Position",
      valueGetter: getPosition,
      width: 150,
      renderCell: rows => rows.row.playerId.position,
      // editable: true,
    },
    {
      field: "jerseyNO",
      headerName: "Jersey No.",
      width: 150,
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      // editable: true,
    },
    {
      field: "playerSeqNO",
      headerName: "Player Sequence No.",
      width: 180,
      // editable: true,
    },
  ];

  const leagueHandleChange = e => {
    if (e.target.value !== ''){
      setCurrLeagueId(parseInt(e.target.value));
      localStorage.setItem("leagueId", e.target.value);
    }
    localStorage.setItem("ReturnFromEditPage", false);
    setCurrSleagueId(-1);
    setCurrSleagueTeamId(-1);
  };
  const leagueSeasonHandleChange = e => {
    if (e.target.value !== ''){
      setCurrSleagueId(parseInt(e.target.value));
      localStorage.setItem("SleagueId", e.target.value);
    }
    localStorage.setItem("ReturnFromEditPage", false);
    setCurrSleagueTeamId(-1);
  };

  const sLeagueTeamHandleChange = e => {
    if (e.target.value !== ''){
      setCurrSleagueTeamId(parseInt(e.target.value));
      localStorage.setItem("SleagueTeamId", e.target.value);
    }
    localStorage.setItem("ReturnFromEditPage", false);
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Team Players | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Players in a Team in a League Season</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <select className={adminStyle.select}
                onChange={leagueHandleChange}
                value={currLeagueId}>
                <option value="">Select a League ...</option>
                {allLeagues.map(({ leagueId, title }) => (
                  <option key={leagueId} value={leagueId}>
                    {title}
                  </option>
                ))}
              </select>

              <select className={adminStyle.select}
                onChange={leagueSeasonHandleChange}
                value={currSleagueId}>
                <option value="">Select a Season ...</option>
                {leagueSeasons.map(({ sleagueId, title, seasonName }) => (
                  <option key={sleagueId} value={sleagueId}>
                    {title} ({seasonName})
                  </option>
                ))}
              </select>

              <select className={adminStyle.select}
                onChange={sLeagueTeamHandleChange}
                value={currSleagueTeamId}>
                <option value="">Select a Team ...</option>
                {sLeagueTeams.map(({ sleagueTeamId, teamId }) => (
                  <option key={sleagueTeamId} value={sleagueTeamId}>
                    {teamId.name}
                  </option>
                ))}
              </select>
            </form>
          </Grid>

          <Grid item>
            <DataGrid
              columns={columns}
              rows={rows}
              // rowHeight={80}
              getRowId={row => row.sleagueTeamPlayerId}
              sortingOrder={["desc", "asc"]}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              rowsPerPageOptions={[40,20]}
              pagination
            />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/sleague-team-player/add`)}
            >
              + New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
