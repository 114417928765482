import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

//Material UI Imports
import {DataGrid} from "@material-ui/data-grid"
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { GET_ALL_FILTERS_QUERY } from "../graphQL/filter_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default function FilterList() {

  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(5);
  const { loading, error, data } = useQuery(GET_ALL_FILTERS_QUERY, {fetchPolicy: "network-only"});
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.allFilters) {
        setFilterData(data.allFilters);
        console.log("allData----", data);
      }
	  if(error && error.graphQLErrors[0].message === "Signature has expired"){
		navigate(`/lms/${admin_url}/signin`);
	  }
    },
    [data, filterData]
  );
  
  console.log("data",filterData)
  
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);



  const columns = [
    
    {
      field: 'name',
      headerName: 'Name',
      width: 190,
      editable: false,
	  renderCell: filterData => (
		<label>{filterData.row.name}</label>  
      ),		
	  sortable: true,		
    },
  ];
  console.log("name" , filterData)
  


  return (
    <>
      <Helmet>
        <title>Predictal | Filters | Admin</title>
      </Helmet>

      <Grid container direction="row">
      <Layout />
      <Grid container item direction="column"   xs={9} spacing={1} >
  	
  	  <Grid item>
  		<form >
  		<h2 className={adminStyle.h2} >List of Filters</h2>
            <DataGrid id="filterTable"
              columns={columns}
              rows={filterData}
  			getRowId={row => row.filterId}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              pagination
              
            />
  		 
  		 <Button className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/filter/add`)} > + New  </Button>
  				
  		 
  		   </form >
          	
        
      </Grid>
      </Grid>

    </Grid>
    </>

  );
}
