import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import {
  CREATE_POSITION_MUTATION,
  GET_ALL_POSITIONS_QUERY
} from "../graphQL/position_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const acceptablePosition = [
//   { label: "gk", name: "goalkeeper" },
//   { label: "ldef", name: "left defender" },
//   { label: "cdef", name: "center defender" },
//   { label: "rdef", name: "right defender" },
//   { label: "lmf", name: "left midfielder" },
//   { label: "cmf", name: "center midfielder" },
//   { label: "rmf", name: "right midfielder" },
//   { label: "lfor", name: "left forward" },
//   { label: "cfor", name: "center forward" },
//   { label: "rfor", name: "right forward" }
// ];
/*##########################################################################
## Function : Position
## Description : Submit form data and create new record in position table
## Author : SP
## Date: : 2022/02/23
##########################################################################*/
export default function Position() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [allPositions, setAllPositions] = useState([]);
  const [createPosition] = useMutation(CREATE_POSITION_MUTATION, {
    refetchQueries: [{ query: GET_ALL_POSITIONS_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_ALL_POSITIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.position) {
        setAllPositions(data.position);
        console.log("AllPositionsData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);
    // let findItem = acceptablePosition.find(
    //   item => item.label === formData.label.toLowerCase()
    // );

    // if (!findItem)
    //   setFailedMessage(
    //     "This position label is not acceptable. Please enter another one."
    //   );
    // else if (findItem.name !== formData.name.toLowerCase()) {
    //   setFailedMessage(
    //     "The position name does not match the position label. Please enter another one."
    //   );
    // } else {
    let findLabel = allPositions.find(
      item => item.label.toLowerCase() === formData.label.toLowerCase()
    );

    if (findLabel)
      setFailedMessage(
        "This is a duplicate position label. Please enter another one."
      );
    else {
      const { data, error } = await createPosition({
        variables: {
          label: formData.label.toUpperCase(),
          name: formData.name
        }
      });
      if (error) {
        console.log("Error", error, data);
        setFailedMessage("Unfortunately an error occurred.");
        //  setTimeout("");
      } else if (data && data.createPosition) {
        console.log("Success", data.createPosition);
        allPositions.push(data.createPosition);
        setSuccessMessage("New position added successfully.");
        setTimeout(() => {
          navigate(`/lms/${admin_url}/position`);
        }, 2000);
        //  setTimeout("");
      }
      // }
    }
  };

  //console.log(errors);
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Position | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add a position to "Position" table</h2>
          <label className={adminStyle.label} htmlFor="label">
            Label
          </label>
          <input
            className={adminStyle.input}
            id="label"
            {...register("label", { required: true })}
          />
          {errors.label && errors.label.type === "required" && (
            <span>Required</span>
          )}
          <label className={adminStyle.label} htmlFor="name">
            Name
          </label>
          <input
            className={adminStyle.input}
            id="name"
            {...register("name", { required: true })}
          />
          {errors.name && errors.name.type === "required" && (
            <span>Required</span>
          )}
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
