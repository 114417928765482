import { useState, useEffect, useContext, createContext } from "react";
import { Helmet } from "react-helmet";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  useQuery,
  useMutation,
  useManualQuery,
  ClientContext,
} from "graphql-hooks";
import TeamSelectorMobile from "./TeamSelectorMobile";

import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../../../graphQL/user_qry";
import { GET_ALL_SETTINGS_QUERY } from "../../../graphQL/setting_qry";
import { useNavigate, useParams } from "react-router-dom";

const PLAYER_SEQ = ["player seq", "player_seq", "player-seq"];
const PLAYER_SEQ_REGEXP = new RegExp(PLAYER_SEQ.join("|"), "i");



const FIXTURETEAM_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    sleagueTeamId {
      sleagueTeamId
    }
    fixtureId{
      fixtureId
    }
    coachSeq
  }
}`;

const SLEAGUETEAM_QUERY = `query sleagueTeamById($sleagueTeamId:Int!){
  sleagueTeamById(sleagueTeamId:$sleagueTeamId){
    sleagueTeamId
    sleagueId{
      sleagueId
      title
      leagueId{
        leagueId
        title
      }
    }
    teamId{
      name
      shortName
      logo
    }
  }
}`;

//export const TeamSelectorContext = createContext();

function UserTeamSelectorAppMobile() {
  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [embargoTime, setEmbargoTime] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const { footyNo} = useParams();


  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSettings,
    error: errorSettings,
    data: dataSettings,
  } = useQuery(GET_ALL_SETTINGS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fixtureTeam] = useManualQuery(FIXTURETEAM_QUERY);
  const [sleagueTeam] = useManualQuery(SLEAGUETEAM_QUERY);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin`);
    }
  }, [data]);

  useEffect(() => {
    if (!loadingSettings && dataSettings && dataSettings.allSettings) {
      const findSetting = dataSettings.allSettings.find(
        (setting) => setting.name === "Embargo Time (in hours)"
      );
      setEmbargoTime(findSetting);
      // Check showing player seq disabled for mobile
      // if (!loading && data && data.profile) {
      //   if (data.profile.isSuperuser) {
      //     dataSettings.allSettings.forEach(setting => {
      //       if (PLAYER_SEQ_REGEXP.test(setting.name)) {
      //         if (["True", "true", "TRUE", true, 1, "1"].includes(setting.value))
      //           setShowSeqNo(true);
      //       }
      //     });
      //   }
      // }
    }
    if (
      errorSettings &&
      errorSettings.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin`);
    }
  }, [dataSettings, data]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(footyNo.substring(46)),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setSleagueTeamId(
        parseInt(data.fixtureTeamById.sleagueTeamId.sleagueTeamId)
      );
      setFixtureId(parseInt(data.fixtureTeamById.fixtureId.fixtureId));
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin`);
    }
  }, [footyNo]);

  useEffect(async () => {
    let { loading, error, data } = await sleagueTeam({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });
    if (!loading && data && data.sleagueTeamById) {
      setUserFavTeam({
        teamInfo: data.sleagueTeamById.teamId,
        sleagueteam: data.sleagueTeamById,
      });
      setLeagueId(parseInt(data.sleagueTeamById.sleagueId.leagueId.leagueId));
    }
  }, [sleagueTeamId]);



  // console.log("userData-----------",userData);
  // console.log("userFavTeam-se--------",userFavTeam);

  return (
    <>
    <Helmet>
      <title>Predictal | Footy No Team Selection View</title>      
    </Helmet>
    <div>
      {userFavTeam && (
        <>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            <TeamSelectorMobile
              userData={userData}
              userFavTeam={userFavTeam}
              fixtureTeamId={parseInt(footyNo.substring(46))}
              sleagueTeamId={sleagueTeamId}
              fixtureId={fixtureId}
              leagueId={leagueId}
              userSeqVal={footyNo.substring(0, 46)}
              embargoTime={embargoTime}
              footyNo={footyNo}
            />
          </DndProvider>
        </>
      )}
    </div>
    </>
  );
}

export default UserTeamSelectorAppMobile;
