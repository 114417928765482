const CreateLeague_mutate = `
    mutation createLeague(
      $title: String!,
      $subtitle: String!,
      $foundYear: Int!) {
      createLeague(
        title: $title,
        subtitle: $subtitle,
        foundYear: $foundYear) {
        leagueId
        title
        subtitle
        foundYear
      }
    }
`;


const GetAllLeagues_query = `
    query{
      league{
        leagueId
        title
        subtitle
        foundYear
    }
  }
`;
module.exports = {
  CREATE_LEAGUE_MUTATION : CreateLeague_mutate,
  GET_ALL_LEAGUES_QUERY: GetAllLeagues_query
}
