import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { DataGrid } from "@material-ui/data-grid";
import Box from "@mui/material/Box";
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY } from "../graphQL/current_bucket_sleague_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../graphQL/current_bucket_week_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";
import { GET_FIXTURETEAM_TEAMS } from "../graphQL/fixture_qry";
import { GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY } from "../graphQL/gameStat_qry";
import { project_url, admin_url } from "../store/data/Constants";

import { styled } from "@mui/material/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 25 }}>No Player!</Box>
    </StyledGridOverlay>
  );
}

export default function FixturePlayerStats() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [pageSize, setPageSize] = React.useState(20);
  const navigate = useNavigate();

  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  const [currentWeekName, setCurrentWeekName] = useState("");
  const [matchedFixturesId, setMatchedFixturesId] = useState([]);

  const [allTeamFixtureData, setAllTeamFixtureData] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("allTeamFixtureData")) !== null
      ? JSON.parse(localStorage.getItem("allTeamFixtureData"))
      : []
  );
  const [currentFixtureId, setCurrentFixtureId] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("curFixId")) !== null
      ? JSON.parse(localStorage.getItem("curFixId"))
      : 0
  );

  const [sleagueWeeksData, setSleagueWeeksData] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("weekData")) !== null
      ? JSON.parse(localStorage.getItem("weekData"))
      : []
  );

  const [sleagueTeamsData, setSleagueTeamsData] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("teamData")) !== null
      ? JSON.parse(localStorage.getItem("teamData"))
      : []
  );

  const [teamFixturesData, setTeamFixturesData] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("fixtureData")) !== null
      ? JSON.parse(localStorage.getItem("fixtureData"))
      : []
  );

  const [rows, setRows] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("statRows")) !== null
      ? JSON.parse(localStorage.getItem("statRows"))
      : []
  );

  const [currentSleagueId, setCurrentSleagueId] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("sleagueId")) !== null
      ? parseInt(localStorage.getItem("sleagueId"))
      : -1
  );

  const [currentWeekId, setCurrentWeekId] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("weekId")) !== null
      ? parseInt(localStorage.getItem("weekId"))
      : -1
  );

  const [currentSleagueTeamId, setCurrentSleagueTeamId] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("sleagueTeamId")) !== null
      ? parseInt(localStorage.getItem("sleagueTeamId"))
      : -1
  );

  const [currentTeamIndex, setCurrentTeamIndex] = useState(
    JSON.parse(localStorage.getItem("ReturnedFromEditPage")) === true &&
      JSON.parse(localStorage.getItem("sleagueTeamIndex")) !== null
      ? parseInt(localStorage.getItem("sleagueTeamIndex"))
      : -1
  );

  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, { fetchPolicy: "network-only" });

  const [fetchWeeks] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeamFixtures] = useManualQuery(GET_FIXTURETEAM_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [fetchFixtureTeamGameStats] = useManualQuery(
    GET_GAME_STATS_BY_FIXTURE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    localStorage.setItem("ReturnedFromEditPage", false);
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
      handleSleagueChange(sleagueData.currentBucketSleague[0].sleagueId.sleagueId);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]);

  useEffect(async () => {
    if (currentSleagueTeamId && currentFixtureId && currentFixtureId !== "") {
      const {
        loading: fixtureWeekGameStatLoading,
        error: fixtureWeekGameStatError,
        data: fixtureWeekGameStatData,
      } = await fetchFixtureTeamGameStats({
        variables: {
          sleagueTeamId: currentSleagueTeamId,
          fixtureId: currentFixtureId,
        },
      });

      if (
        !fixtureWeekGameStatLoading &&
        fixtureWeekGameStatData &&
        fixtureWeekGameStatData.gameStatByFixtureTeam
      ) {
        setRows(fixtureWeekGameStatData.gameStatByFixtureTeam);
        localStorage.setItem(
          "statRows",
          JSON.stringify(fixtureWeekGameStatData.gameStatByFixtureTeam)
        );
        localStorage.setItem("ReturnedFromEditPage", false);
      }
    }
  }, [currentSleagueTeamId, currentFixtureId]);

  useEffect(() => {
    if (teamFixturesData) {
      localStorage.setItem("fixtureData", JSON.stringify(teamFixturesData));
    }
  }, [teamFixturesData]);

  useEffect(async () => {
    let tData = [];
    var matchedFixtureId = [];
    await allTeamFixtureData.map(fixture => {
      let filterFixId = 0;
      let reschduledFixture = false;

      filterFixId = allTeamFixtureData.filter(
        x =>
          x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
          parseInt(x.fixtureId.fixtureWeek.weekId.weekId) === parseInt(currentWeekId)
      );

      if (filterFixId.length == 0) {
        filterFixId = allTeamFixtureData.filter(
          x =>
            x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
            parseInt(x.fixtureId.scheduledWeek.weekId.weekId) === parseInt(currentWeekId)
        );

        if (allTeamFixtureData[0].fixtureId.delayed === false) reschduledFixture = true;
      } else {
        reschduledFixture = false;
      }

      let teamData = [];
      if (filterFixId && filterFixId.length > 0) {
        // search for fixtureId
        const index = tData.findIndex(x => x.fixtureId === fixture.fixtureId.fixtureId);
        // add the fixtureId if doesn't exist in list
        if (index == -1) {
          filterFixId.map(i => {
            teamData.push({
              teamName: i.sleagueTeamId.teamId.name,
              halfScore: i.scoreHalf1,
              finalScore: i.scoreFinal,
            });
          });
        }

        // if teamData exist add its data to result
        if (teamData && teamData.length > 0) {
          var formatedDate = dayFormat(fixture.fixtureId.fixtureWeek.scheduledDate);

          // check there are assigned two team for a fixture
          if (teamData && teamData.length > 1) {
            tData.push({
              fixtureId: fixture.fixtureId.fixtureId,
              fixtureWeek: fixture.fixtureId.fixtureWeek.weekId.name,
              fixtureDate: formatedDate,
              delayed: fixture.fixtureId.delayed,
              reschduledFixture: reschduledFixture,
              scheduledWeek: fixture.fixtureId.scheduledWeek.weekId.name,
              fixtureTeams: [
                {
                  teamName: teamData[0].teamName,
                  halfScore: teamData[0].halfScore,
                  finalScore: teamData[0].finalScore,
                },
                {
                  teamName: teamData[1].teamName,
                  halfScore: teamData[1].halfScore,
                  finalScore: teamData[1].finalScore,
                },
              ],
            });
            matchedFixtureId.push(parseInt(fixture.fixtureId.fixtureId));
          }
        }
      }
    });

    setTeamFixturesData(tData);
    setMatchedFixturesId(matchedFixtureId);

    localStorage.setItem("fixtureData", JSON.stringify(tData));

    if (matchedFixtureId.length) {
      let selectedFixture = tData.find(item => item.scheduledWeek === currentWeekName);
      if (selectedFixture) {
        setCurrentFixtureId(parseInt(selectedFixture.fixtureId));
        localStorage.setItem("curFixId", parseInt(selectedFixture.fixtureId));

        const {
          loading: fixturePlayersStatLoading,
          error: fixturePlayersStatError,
          data: fixturePlayersStatData,
        } = await fetchFixtureTeamGameStats({
          variables: {
            fixtureId: parseInt(selectedFixture.fixtureId),
            sleagueTeamId: parseInt(currentSleagueTeamId),
          },
        });

        if (
          !fixturePlayersStatLoading &&
          fixturePlayersStatData &&
          fixturePlayersStatData.gameStatByFixtureTeam
        ) {
          setRows(fixturePlayersStatData.gameStatByFixtureTeam);
          localStorage.setItem(
            "statRows",
            JSON.stringify(fixturePlayersStatData.gameStatByFixtureTeam)
          );
          localStorage.setItem("ReturnedFromEditPage", false);
        }
      }
    } else {
      setRows([]);
      setCurrentFixtureId("");
      localStorage.setItem("curFixId", "");
      localStorage.setItem("ReturnedFromEditPage", false);
    }
  }, [allTeamFixtureData, currentSleagueTeamId, currentWeekId]);

  const localTime = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "day").value +
        " " +
        dayFormat.find(a => a.type == "month").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        " - " +
        dayFormat.find(a => a.type == "hour").value +
        ":" +
        dayFormat.find(a => a.type == "minute").value +
        " " +
        dayFormat.find(a => a.type == "dayPeriod").value;
      return newFormat;
    }
  };

  const columns = [
    {
      field: "player",
      headerName: "Player",
      width: 250,
      renderCell: rows => (
        <a
          href={`/lms/${admin_url}/fixture-player-stats/edit/${rows.row.gameStatId}`}
          target="_self"
        >
          ({rows.row.sleagueTeamPlayerId.jerseyNO}){" "}
          {rows.row.sleagueTeamPlayerId.playerId.name}
        </a>
      ),
      // editable: true,
    },
    {
      field: "position",
      headerName: "Position",
      width: 140,
      renderCell: rows => rows.row.positionId.label,
      // editable: true,
    },
    {
      field: "onField",
      headerName: "On-Field",
      width: 140,
      renderCell: rows => rows.row.playerOnField,

      // editable: true,
    },

    {
      field: "positionSeqNO",
      headerName: "Seq No.",
      width: 140,
      renderCell: rows => rows.row.positionSeqNO,
    },

    {
      field: "minPlayed",
      headerName: "Min. Played",
      width: 160,
      renderCell: rows => rows.row.minutePlayed,
      // editable: true,
    },
    {
      field: "goal",
      headerName: "Goal",
      width: 120,
      renderCell: rows => rows.row.goal,
      // editable: true,
    },
    {
      field: "ownGoal",
      headerName: "Own Goal",
      width: 120,
      renderCell: rows => rows.row.ownGoal,
      // editable: true,
    },
    {
      field: "yc",
      headerName: "YC",
      width: 100,
      renderCell: rows => (rows.row.cardYellow ? 1 : 0),
      // editable: true,
    },
    {
      field: "rc",
      headerName: "RC",
      width: 100,
      renderCell: rows => (rows.row.cardRed ? 1 : 0),
      // editable: true,
    },
  ];

  const selectFixture = async event => {
    const fixtureId = event.target.value;
    setCurrentFixtureId(parseInt(fixtureId));
    localStorage.setItem("curFixId", parseInt(fixtureId));
    localStorage.setItem("ReturnedFromEditPage", false);

    const {
      loading: fixturePlayersStatLoading,
      error: fixturePlayersStatError,
      data: fixturePlayersStatData,
    } = await fetchFixtureTeamGameStats({
      variables: {
        fixtureId: parseInt(fixtureId),
        sleagueTeamId: parseInt(currentSleagueTeamId),
      },
    });

    if (
      !fixturePlayersStatLoading &&
      fixturePlayersStatData &&
      fixturePlayersStatData.gameStatByFixtureTeam
    ) {
      setRows(fixturePlayersStatData.gameStatByFixtureTeam);
      localStorage.setItem(
        "statRows",
        JSON.stringify(fixturePlayersStatData.gameStatByFixtureTeam)
      );
      localStorage.setItem("ReturnedFromEditPage", false);
    }
  };

  const handleSleagueChange = async tid => {
    setRows([]);

    if (tid !== "") {
      setCurrentSleagueId(parseInt(tid));
      localStorage.setItem("sleagueId", tid);
    }
    localStorage.setItem("ReturnedFromEditPage", false);

    if (parseInt(tid) != 0) {
      const {
        loading: weekLoading,
        error: weekError,
        data: weekData,
      } = await fetchWeeks({
        variables: {
          sleagueId: parseInt(tid),
        },
      });

      if (!weekLoading && weekData && weekData.currentSleagueWeek) {
        weekData.currentSleagueWeek.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        setSleagueWeeksData(weekData.currentSleagueWeek);
        localStorage.setItem("weekData", JSON.stringify(weekData.currentSleagueWeek));
        localStorage.setItem("ReturnedFromEditPage", false);

        if (currentWeekId === -1) {
          let selectedWeek = weekData.currentSleagueWeek.find(
            item => item.extraField === true
          );

          if (selectedWeek) {
            setCurrentWeekId(selectedWeek.weekId);
            setCurrentWeekName(selectedWeek.name);

            localStorage.setItem("weekId", selectedWeek.weekId);
          } else {
            setCurrentWeekId(-1);
            setCurrentWeekName("");
            localStorage.setItem("weekId", -1);
          }
        }
      }
    } else {
      setSleagueWeeksData([]);
      localStorage.setItem("weekData", []);
      setTeamFixturesData([]);
      localStorage.setItem("fixtureData", []);
      localStorage.setItem("ReturnedFromEditPage", false);
    }

    const {
      loading: teamsLoading,
      error: teamsError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!teamsLoading && allTeamsData && allTeamsData.sleagueTeam) {
      allTeamsData.sleagueTeam.sort((a, b) =>
        a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1
      );
      setSleagueTeamsData(allTeamsData.sleagueTeam);
      localStorage.setItem("teamData", JSON.stringify(allTeamsData.sleagueTeam));
      localStorage.setItem("ReturnedFromEditPage", false);
    }
    if (teamsError && teamsError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleWeekChange = async e => {
    setRows([]);
    if (e.target.value !== "") {
      const wid = e.target.value;
      let selectedWeek = sleagueWeeksData.find(item => item.weekId === wid);
      setCurrentWeekId(selectedWeek.weekId);
      setCurrentWeekName(selectedWeek.name);

      localStorage.setItem("weekId", selectedWeek.weekId);
    }
    localStorage.setItem("ReturnedFromEditPage", false);
  };

  const handleTeamChange = async e => {
    setRows([]);
    if (e.target.value !== "") {
      localStorage.setItem("sleagueTeamIndex", e.target.value);
      const tid = e.target.value;

      setCurrentTeamIndex(parseInt(tid));

      let selectedTeam = sleagueTeamsData.find(
        item => parseInt(item.teamId.teamId) === parseInt(tid)
      );
      if (selectedTeam) {
        setCurrentSleagueTeamId(parseInt(selectedTeam.sleagueTeamId));
        localStorage.setItem("sleagueTeamId", selectedTeam.sleagueTeamId);

        const {
          loading: teamFixturesLoading,
          error: teamFixturesError,
          data: allTeamFixturesData,
        } = await fetchTeamFixtures({
          variables: { sleagueTeamId: parseInt(selectedTeam.sleagueTeamId) },
        });

        if (
          !teamFixturesLoading &&
          allTeamFixturesData &&
          allTeamFixturesData.fixtureTeamTeams
        ) {
          setAllTeamFixtureData(allTeamFixturesData.fixtureTeamTeams);
          localStorage.setItem(
            "allTeamFixtureData",
            JSON.stringify(allTeamFixturesData.fixtureTeamTeams)
          );
        }
      }
    }
    localStorage.setItem("ReturnedFromEditPage", false);
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Game Stats | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Fixture Player Stats</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="sleague">
                Current League Season
              </label>
              <select
                className={adminStyle.selectWithLabel}
                id="sleague"
                {...register("sleague")}
                onChange={handleSleagueChange}
                value={currentSleagueId}
              >
                {sleaguesBucketData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId.sleagueId}>
                    {sleague.sleagueId.leagueId.subtitle}, Season{" "}
                    {sleague.sleagueId.seasonName}
                  </option>
                ))}
              </select>

              <label className={adminStyle.label} htmlFor="week">
                League Season Weeks
              </label>
              <select
                className={adminStyle.selectWithLabel}
                onChange={handleWeekChange}
                value={currentWeekId}
              >
                <option value="">Select a Week ...</option>
                {sleagueWeeksData.map((week, i) => (
                  <option key={i} value={week.weekId}>
                    {week.name}
                  </option>
                ))}
              </select>

              <label className={adminStyle.label} htmlFor="sleague">
                Teams in the Current League Season
              </label>
              <select
                className={adminStyle.selectWithLabel}
                onChange={handleTeamChange}
                value={currentTeamIndex}
              >
                <option value="">Select a Team ...</option>
                {sleagueTeamsData.map((team, i) => (
                  <option key={i} value={team.teamId.teamId}>
                    {team.teamId.name}
                  </option>
                ))}
              </select>

              {teamFixturesData[0] && teamFixturesData[0].delayed === true ? (
                <label className={adminStyle.label} htmlFor="weekFixture">
                  Delayed
                </label>
              ) : (
                <>
                  {teamFixturesData.length === 1 &&
                  teamFixturesData[0].reschduledFixture === true ? (
                    <label className={adminStyle.label} htmlFor="weekFixture">
                      {" "}
                      Rescheduled{" "}
                    </label>
                  ) : (
                    ""
                  )}
                </>
              )}

              <label className={adminStyle.label} htmlFor="weekFixture">
                Fixture:
                {teamFixturesData.length > 1
                  ? teamFixturesData.map((fixture, i) => (
                      <div>
                        <input
                          id="fixture"
                          {...register("fixture")}
                          key={i}
                          value={fixture.fixtureId}
                          name="fixture"
                          type="radio"
                          checked={
                            parseInt(fixture.fixtureId) === parseInt(currentFixtureId)
                          }
                          onChange={selectFixture}
                        />

                        <label htmlFor="fixture">
                          {fixture && (
                            <b>
                              {" "}
                              {fixture.fixtureTeams[0].teamName}{" "}
                              {fixture.fixtureTeams[0].finalScore} (
                              {fixture.fixtureTeams[0].halfScore}) -{" "}
                              {fixture.fixtureTeams[1].teamName}{" "}
                              {fixture.fixtureTeams[1].finalScore} (
                              {fixture.fixtureTeams[1].halfScore})
                            </b>
                          )}
                        </label>
                      </div>
                    ))
                  : teamFixturesData[0] && (
                      <b>
                        {" "}
                        {teamFixturesData[0].fixtureTeams[0].teamName}{" "}
                        {teamFixturesData[0].fixtureTeams[0].finalScore} (
                        {teamFixturesData[0].fixtureTeams[0].halfScore}) -{" "}
                        {teamFixturesData[0].fixtureTeams[1].teamName}{" "}
                        {teamFixturesData[0].fixtureTeams[1].finalScore} (
                        {teamFixturesData[0].fixtureTeams[1].halfScore})
                      </b>
                    )}
              </label>

              <label className={adminStyle.dateLabel} htmlFor="weekFixture">
                {teamFixturesData[0] && teamFixturesData[0].delayed === true ? (
                  <>
                    Date : <b> {teamFixturesData[0].fixtureDate}</b>
                  </>
                ) : (
                  <>
                    {teamFixturesData.length === 1 &&
                    teamFixturesData[0] &&
                    teamFixturesData[0].reschduledFixture === true ? (
                      <>
                        Rescheduled Date :
                        <b>
                          {" "}
                          {teamFixturesData[0].fixtureDate} (
                          {teamFixturesData[0].fixtureWeek})
                        </b>
                      </>
                    ) : (
                      <>
                        Date:
                        {teamFixturesData.length > 1
                          ? teamFixturesData.map(
                              (fixture, i) =>
                                parseInt(fixture.fixtureId) ===
                                  parseInt(currentFixtureId) && (
                                  <b>
                                    {" "}
                                    {fixture.fixtureDate} - Scheduled:{" "}
                                    {fixture.scheduledWeek}
                                  </b>
                                )
                            )
                          : teamFixturesData[0] && (
                              <b> {teamFixturesData[0].fixtureDate}</b>
                            )}
                      </>
                    )}
                  </>
                )}
              </label>
            </form>
          </Grid>

          <Grid item>
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              columns={columns}
              rows={rows}
              // rowHeight={80}
              getRowId={row => row.gameStatId}
              sortingOrder={["desc", "asc"]}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              rowsPerPageOptions={[40, 20]}
              pagination
            />
          </Grid>
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/fixture-player-stats/add`)}
            >
              + New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
