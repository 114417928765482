import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useParams } from "react-router-dom";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import {
  EDIT_SETTING_MUTATION,
  GET_SETTING_OBJECT
} from "../graphQL/setting_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
// import "../styles.css";
import adminStyle from "./Admin.module.scss";
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Filter
## Description : Submit form data and create new record in filter table
## Author : SS
## Date: : 2022/02/26
##########################################################################*/
export default function AddSetting() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [allSettings, setAllSettings] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const [radioOptionsAll, setRadioOptionsAll] = useState([]);
  const [radioOption, setRadioOption] = useState("");
  const [radioLabel, setRadioLabel] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");

  const [checkboxOption, setCheckboxOption] = useState("");
  const [checkboxOptionsAll, setCheckboxOptionsAll] = useState([]);

  const [oneTextVal, setOneTextVal] = useState([]);
  const [oneNumVal, setOneNumVal] = useState([]);
  const [boolVal, setBoolVal] = useState("");

  const [selectedSetting, setSelectedSetting] = useState([]);
  const [settingName, setSettingName] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { settingId } = useParams();
  const navigate = useNavigate();


  //getting setting information
  const { loading:settingLoading, error:settingError, data:settingData } = useQuery(GET_SETTING_OBJECT,{
    variables: {
      settingId: parseInt(settingId)
    }

  });
  const [editSetting] = useMutation(EDIT_SETTING_MUTATION, {
    fetchPolicy: "network-only"
  })
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
      onRefreshToken();
    }
    else
    navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
    if (refreshError) {
      if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
      navigate(`/lms/${admin_url}/signin`);
      if(refreshError.graphQLErrors[0].message === "Signature has expired")
      navigate(`/lms/${admin_url}/signin`);
    }
    if(refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(
    () => {

      if (!settingLoading && settingData && settingData.settingObject ) {
        console.log(settingData.settingObject);
        setSelectedSetting(settingData.settingObject);
        setSettingName(settingData.settingObject.name);
        setSelectedValue(settingData.settingObject.value);
        setSelectedType(settingData.settingObject.type.toLowerCase());
        if(settingData.settingObject.type.toLowerCase()==="text"){
           setOneTextVal(settingData.settingObject.value)
        }else if(settingData.settingObject.type.toLowerCase()==="number"){
          setOneNumVal(settingData.settingObject.value)
        }else if(settingData.settingObject.type.toLowerCase()==="boolean"){
          setBoolVal(settingData.settingObject.value)
        }if(settingData.settingObject.type.toLowerCase()==="radio"){
          console.log(settingData.settingObject.allValues);
          setRadioOptionsAll(settingData.settingObject.allValues);
          setSelectedRadio(settingData.settingObject.value);
        }
        /***if(settingData.settingObject.type.toLowerCase()==="checkbox"){
          let partsOfStr = settingData.settingObject.value.split(',');
          let checkOptions = []
          for (let ch in settingData.settingObject.allValues) {

            if (ch in partsOfStr){
                checkOptions.push({name: settingData.settingObject.allValues[ch], enable:true})
            }else{
                checkOptions.push({name: settingData.settingObject.allValues[ch], enable:false})
              }
            }
          setCheckboxOptionsAll(checkOptions);
        }***/

      }
      if (settingError && settingError.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [settingData]
  );

  /*
  * Function : setTimeout
  * Description : Disappear message after timeout
  */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const selectSettingType = (e) =>{
    setSelectedType(e.target.value);
    setRadioOptionsAll([]);
  }

  const handleChangeName = ({target})=>{setSettingName(target.value)};
  const handleOneTextChange = ({target})=>{setOneTextVal(target.value)};
  const handleOneNumChange = ({target})=>{setOneNumVal(target.value)};
  const handleRadioOptionChange = ({target})=>{setRadioOption(target.value)};
  const handleCheckboxChange = ({target})=>{setCheckboxOption(target.value)};
  const handleRadioLabelChange = ({target})=>{setRadioLabel(target.value)};
  const handleClearSelections = e =>{
    e.preventDefault();
    setCheckboxOptionsAll([]);
    setCheckboxOption("");
    setRadioOptionsAll([]);
    setRadioOption("");
  };





  const addRadioOption = (e) => {
    e.preventDefault();
    if(radioOption.trim()!=="" && radioOptionsAll.filter(item=>item.value==radioOption).length==0){
        setRadioOptionsAll([...radioOptionsAll, {value:radioOption, label:radioLabel}]);
    }
    setRadioOption("");
    setRadioLabel("");
  }


  const addCheckbox = (e) => {
    e.preventDefault();
    if(checkboxOption.trim()!=="" && !checkboxOptionsAll.includes(checkboxOption)){
      let newCheck = {name:checkboxOption, enable:false}

      setCheckboxOptionsAll(prevState => {

        return [
          ...checkboxOptionsAll.filter(item => item.name !==newCheck.name),
          newCheck
        ]
      });
    }

    setCheckboxOption("");
  }



  const selectTextOption = (e) =>{
    setSelectedRadio(e.target.value);
  }

  const selectCheckBox = (option, checked) =>{
    const newCheckBoxes = checkboxOptionsAll.map(item =>{
      if (option.name==item.name){
        return { ...item, enable:checked}
      }
      return item;
    });
    setCheckboxOptionsAll(newCheckBoxes);
  }

  const selectBool = (e) =>{
    setBoolVal(e.target.value);
    console.log(e.target.value);
  }



  /*
  * Function : onSubmit
  * Description : Submit form data
  */
  const onSubmit = async formData => {
    let name=formData.name;
    //await sleep(2000);
    let selectedValue="";
    let values=[];
    if (selectedType==="text"){
      if(oneTextVal && oneTextVal!="" && oneTextVal.trim()!=""){
        values.push(JSON.stringify({"value": oneTextVal, 'label':oneTextVal}));
        selectedValue = oneTextVal;
      }
    }else if (selectedType==="number") {
      if(oneNumVal && oneNumVal!=""){
        values.push(JSON.stringify({"value": oneNumVal, 'label':settingName}));
        selectedValue = oneNumVal;
      }

    }else if (selectedType==="radio") {
      values = radioOptionsAll.map(item=>{
        return JSON.stringify(item);
      });
      selectedValue = selectedRadio;

    }else if (selectedType==="boolean") {
      console.log(boolVal);
      if(boolVal==="True"){
        values.push(JSON.stringify({"value": "True", 'label':settingName}));
        selectedValue = "True";
      }else {
        values.push(JSON.stringify({"value": "False", 'label':settingName}));
        selectedValue = "False";
      }
    }else if (selectedType==="checkbox") {

      checkboxOptionsAll.map(obj =>

        {
          values.push( JSON.stringify(obj));
          selectedValue = "checkbox"
        });

      }

      if(settingName && values && selectedValue){

        const { loading, data, error } = await editSetting({
          variables: {
            settingId:parseInt(settingId),
            name: settingName,
            values: values,
            selected: selectedValue,
            type: selectedType
          }
        });

        if (error) {
          console.log("Error", error, data);
          setFailedMessage("Unfortunately an error occurred.");
          setTimeout("");
        } else if (data && data.editSetting) {
          console.log(data.editSetting);
          alert("Setting Updated Successfully.");
          setTimeout(1000);
          navigate(`/lms/${admin_url}/setting`);
        }
      }
    };

    /////////////////////////////////////////////////////////////////////
    return (
      <>
        <Helmet>
          <title>Predictal | Edit A Setting | Admin</title>
        </Helmet>
        <Grid container direction="row" alignItems="flex-start">
          <Layout />
          <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
            <h2 className={adminStyle.h2}>Add Setting to "setting" table</h2>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Setting Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedType}
                name="radio-buttons-group"
                onChange={selectSettingType}
                >
                <FormControlLabel value="text" control={<Radio />} label="Text" />
                <FormControlLabel value="number" control={<Radio />} label="Number" />
                <FormControlLabel value="radio" control={<Radio />} label="Radio Button" />
                <FormControlLabel value="boolean" control={<Radio />} label="Boolean" />
              </RadioGroup>
            </FormControl>

            <label className={adminStyle.label} htmlFor="name">
              Name
            </label>
            <input
              className={adminStyle.input}
              id="name"
              value={settingName}
              onChange={handleChangeName}
              />
            {errors.name && errors.name.type === "required" && (
              <span>Required</span>
            )}

            {selectedType==="text"?
              <>
              <label className={adminStyle.label} htmlFor="textval">
                Value
              </label>
              <input
                className={adminStyle.input}
                id="textval"
                onChange={handleOneTextChange}
                value={oneTextVal}
                />
              </>: null }

              {selectedType==="number"?
                <>
                <label className={adminStyle.label} htmlFor="numval">
                  Value
                </label>
                <input
                  type="number"
                  className={adminStyle.input}
                  id="numval"
                  onChange={handleOneNumChange}
                  value={oneNumVal}
                  />
                </>: null }

                {selectedType==="radio"?
                  <>
                  <label className={adminStyle.label} htmlFor="radiooption">
                    Value
                  </label>
                  <input
                    className={adminStyle.input}
                    id="radiooption"
                    onChange={handleRadioOptionChange}
                    value={radioOption}
                    />
                  <label className={adminStyle.label} htmlFor="radiolabel">
                    Label
                  </label>
                  <input
                    className={adminStyle.input}
                    id="radiolabel"
                    onChange={handleRadioLabelChange}
                    value={radioLabel}
                    />
                  <button className={adminStyle.input} onClick={addRadioOption} > Add Value </button>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedRadio}
                    name="txt-radio-buttons-group"
                    onChange={selectTextOption}
                    >
                    {radioOptionsAll.map((item, i) => (
                      <FormControlLabel  key={i} value={item.value} control={<Radio />}
                        label={"Value: "+item.value+",   Label: "+item.label} />
                    ))}
                  </RadioGroup>
                  <button className={adminStyle.input} onClick={handleClearSelections} > Clear Selection </button>
                  </>: null }

                  {selectedType==="boolean"?
                    <>
                    <label className={adminStyle.label} htmlFor="booleanval">
                      Value
                    </label>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={boolVal}
                      name="txt-radio-buttons-group"
                      onChange={selectBool}
                      >
                      <FormControlLabel  key={0} value={"False"} control={<Radio />} label="False" />
                      <FormControlLabel  key={1} value={"True"} control={<Radio />} label="True" />
                    </RadioGroup>
                    </>: null }


                    {selectedType==="checkbox"?
                      <>
                      <label className={adminStyle.label} htmlFor="checkbox">
                        Value
                      </label>
                      <input
                        className={adminStyle.input}
                        id="checkbox"
                        value={checkboxOption}
                        onChange={handleCheckboxChange}

                        />
                      <button className={adminStyle.input}  onClick={addCheckbox}>Add Value </button>
                      <FormGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={checkboxOptionsAll}
                        name="num-radio-buttons-group"

                        >
                        {
                          checkboxOptionsAll.map((chk, idx)=>{
                            return (
                              <FormControlLabel key={idx + chk.name} control={
                                  <Checkbox onChange={e => selectCheckBox(chk, e.target.checked)} />
                                } label={chk.name} checked={chk.enable} />
                              )
                            })
                          }


                        </FormGroup>
                        <button className={adminStyle.input} onClick={handleClearSelections}>clear selections</button>
                        </>: null }


                        <input className={adminStyle.input}  type="submit" />
                        {successMessage && (
                          <div className={adminStyle.success}>{successMessage}</div>
                        )}
                        {failedMessage && (
                          <div className={adminStyle.error}>{failedMessage}</div>
                        )}
                      </form>
                    </Grid>
      </>
    );
  }
