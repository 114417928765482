import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import {project_url, admin_url} from "../store/data/Constants";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";

import { makeStyles } from "@material-ui/core";

import { useNavigate } from "react-router-dom";

import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";

import {  
  GET_SLEAGUE_WEEK_QUERY
} from "../graphQL/week_qry";

import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {
  EDIT_FIXTURE_MUTATION,
  EDIT_FIXTURE_TEAM_MUTATION,
  GET_FIXTURE_OBJECT,
  GET_FIXTURE_OBJECT_TEAMS,
  
} from "../graphQL/fixture_qry";

import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";

import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Fixture
## Description : Submit form data and Add Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function Fixture() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm();
  const { control } = useForm();
  
  const { fixtureId } = useParams();

  const useStyles = makeStyles({
    
    root: {
      border: "solid 1px #e0e7cf",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: "#ec645f",
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          fontSize: 12,
          color: "#ffffff",
          fontFamily: "Verdana, Arial, Helvetica, sans-serif",
        },
      },
      "& .MuiDataGrid-renderingZone": {
        "& .MuiDataGrid-row": {
          backgroundColor: "#fdfff8",
          margin: 0,
          fontSize: 13,
          // padding: "7px 2px 7px 2px",
          "&:nth-child(2n)": { backgroundColor: "#ebd7f4" /*"#f5ffdf"*/ },
          "&:hover": { backgroundColor: "#ce9be4" /*"#e7f7c2"*/ },
        },
      },
    },
  });
  
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  
  const [selectedDateTime, handleDateTimeChange] = useState(new Date());
  
  const [selectedWeek, setSelectedWeek] = useState("");

   const [SPExtra, setSPExtra] = useState(0);
  
    
  const [selectedFixture, setSelectedFixture] = useState([]);
  
  const [fixtureTeam1, setFixtureTeam1] = useState([]);
  const [fixtureTeam2, setFixtureTeam2] = useState([]);

  const [fixtureTeam1Name, setFixtureTeam1Name] = useState([]);
  const [fixtureTeam2Name, setFixtureTeam2Name] = useState([]);

  const [fixtureWeek, setFixtureWeek] = useState([]);
  const [fixtureDateTime, setFixtureDateTime] = useState([]);
  
  const [sleagueTeamId1, setSleagueTeamId1] = useState(0);
  const [sleagueTeamId2, setSleagueTeamId2] = useState(0);
  
  const [scoreHalf1Team1, setScoreHalf1Team1] = useState("");
  const [scoreHalf1Team2, setScoreHalf1Team2] = useState("");
  
  const [scoreFinalTeam1, setScoreFinalTeam1] = useState("");
  const [scoreFinalTeam2, setScoreFinalTeam2] = useState("");

  const [oldScoreFinalTeam1, setOldScoreFinalTeam1] = useState("");
  const [oldScoreFinalTeam2, setOldScoreFinalTeam2] = useState("");

  const [scoreFinalTeam1Bucket, setScoreFinalTeam1Bucket] = useState("");
  const [scoreFinalTeam2Bucket, setScoreFinalTeam2Bucket] = useState("");

  //for invisible table
  const [oldTeam1Win, setOldTeam1Win] = useState(0);
  const [oldTeam2Win, setOldTeam2Win] = useState(0);

  const [oldTeam1Loss, setOldTeam1Loss] = useState(0);
  const [oldTeam2Loss, setOldTeam2Loss] = useState(0); 

  const [oldTeam1Draw, setOldTeam1Draw] = useState(0);
  const [oldTeam2Draw, setOldTeam2Draw] = useState(0);

  const [oldTeam1GoalsFor, setOldTeam1GoalsFor] = useState(0);
  const [oldTeam2GoalsFor, setOldTeam2GoalsFor] = useState(0);

  const [oldTeam1GoalsAgainst, setOldTeam1GoalsAgainst] = useState(0);
  const [oldTeam2GoalsAgainst, setOldTeam2GoalsAgainst] = useState(0);

  const [oldTeam1GoalDifference, setOldTeam1GoalDifference] = useState(0);
  const [oldTeam2GoalDifference, setOldTeam2GoalDifference] = useState(0);

  const [oldTeam1Points, setOldTeam1Points] = useState(0);
  const [oldTeam2Points, setOldTeam2Points] = useState(0);

  //for second table
  const [team1Win, setTeam1Win] = useState(0);
  const [team2Win, setTeam2Win] = useState(0);

  const [team1Loss, setTeam1Loss] = useState(0);
  const [team2Loss, setTeam2Loss] = useState(0); 

  const [team1Draw, setTeam1Draw] = useState(0);
  const [team2Draw, setTeam2Draw] = useState(0);

  const [team1GoalsFor, setTeam1GoalsFor] = useState(0);
  const [team2GoalsFor, setTeam2GoalsFor] = useState(0);

  const [team1GoalsAgainst, setTeam1GoalsAgainst] = useState(0);
  const [team2GoalsAgainst, setTeam2GoalsAgainst] = useState(0);

  const [team1GoalDifference, setTeam1GoalDifference] = useState(0);
  const [team2GoalDifference, setTeam2GoalDifference] = useState(0);

  const [team1Points, setTeam1Points] = useState(0);
  const [team2Points, setTeam2Points] = useState(0);

  //for last table

  const [team1TotalPlayed, setTeam1TotalPlayed] = useState(0);
  const [team2TotalPlayed, setTeam2TotalPlayed] = useState(0);  

  const [team1TotalWin, setTeam1TotalWin] = useState(0);
  const [team2TotalWin, setTeam2TotalWin] = useState(0);  

  const [team1TotalLoss, setTeam1TotalLoss] = useState(0);
  const [team2TotalLoss, setTeam2TotalLoss] = useState(0);  

  const [team1TotalDraw, setTeam1TotalDraw] = useState(0);
  const [team2TotalDraw, setTeam2TotalDraw] = useState(0);  

  const [team1TotalGoalsFor, setTeam1TotalGoalsFor] = useState(0);
  const [team2TotalGoalsFor, setTeam2TotalGoalsFor] = useState(0); 

  const [team1TotalGoalsAgainst, setTeam1TotalGoalsAgainst] = useState(0);
  const [team2TotalGoalsAgainst, setTeam2TotalGoalsAgainst] = useState(0);  

  const [team1TotalGoalDifference, setTeam1TotalGoalDifference] = useState(0);
  const [team2TotalGoalDifference, setTeam2TotalGoalDifference] = useState(0);  

  const [team1TotalPoints, setTeam1TotalPoints] = useState(0);
  const [team2TotalPoints, setTeam2TotalPoints] = useState(0);

  const [fixtureScoreUpdatedScreen, setFixtureScoreUpdatedScreen] = useState([]);
  const [played, setPlayed] = useState([]);
  
  const [homeValue , setHomeValue] = useState("")
  const [open, setOpen] = useState(false);
 
  const [fixtureTeams, setFixtureTeams] = useState([]);
  
  const [leaguesData, setLeaguesData] = useState([]);  
  const [sleaguesData, setSleaguesData] = useState([]);

  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  }); 
  
  const [fetchWeeks] = useManualQuery(GET_SLEAGUE_WEEK_QUERY, {
    fetchPolicy: "network-only",
  });
  
  const [allSleagueWeeks, setAllSleagueWeeks] = useState([]);
  
  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only"
  });
  
  const [editFixture] = useManualQuery(EDIT_FIXTURE_MUTATION, {
    fetchPolicy: "network-only"
  })
  
  const [editFixtureTeam] = useManualQuery(EDIT_FIXTURE_TEAM_MUTATION, {
    fetchPolicy: "network-only"
  })
  
   const [teamsData, setTeamsData] = useState([]);  
  
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
    onRefreshToken();
  }
  else
    navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
  if (refreshError) {
    if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
      navigate(`/lms/${admin_url}/signin`);
    if(refreshError.graphQLErrors[0].message === "Signature has expired")
      navigate(`/lms/${admin_url}/signin`);
  }
  if(refreshData && refreshData.refreshToken) {
    localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
  }
  };
  
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  
  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (leagueError && leagueError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);
  

  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true

  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };
  
  /*
   * Function : updateSleagues
   * Description : update sleagues according to league changes
   */

    const updateSleagues = async league => {
    if (league != null)
    {
      const {loading: sleagueLoading, error: sleagueError, data: sleagueData } = await fetchSleagues({
          variables: {
            leagueId: parseInt(league),
          },
        });
  
      if (!sleagueLoading && sleagueData && sleagueData.sleague) {
        setSleaguesData(sleagueData.sleague);
        
                setAllSleagueWeeks([]);
        setTeamsData([]);
                
        }
      if (
        sleagueError &&
          sleagueError.graphQLErrors[0].message === "Signature has expired"
        )
        {
          navigate(`/lms/${admin_url}/signin`);
        }
    } 
    } 
   
   
  
      
  /*
     * Function : updateWeeks
     * Description : update weeks according to sleague change
     */

    const updateWeeks = async sid => {
      if (sid !== null){      
    
      const { loading:weekLoading, error:weekError, data:weekData } = await fetchWeeks({
        variables: {
          sleagueId: parseInt(sid),
        }
      });

      if (!weekLoading && weekData && weekData.sleagueWeek ) {
        setAllSleagueWeeks(weekData.sleagueWeek);
      }
      if (
        weekError &&
        weekError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    
    }
    };
  
  /*
     * Function : updateTeams
     * Description : update teams according to sleague change
     */

    const updateTeams = async seasonId => {
  
      if (seasonId !== null){
      
      const {
        loading: teamLoading,
        error: teamError,
        data: allTeamsData
        } = await fetchTeams({
          variables: { sleagueId: parseInt(seasonId) }
        });

      if (!teamLoading && allTeamsData && allTeamsData.sleagueTeam) {
        
        setTeamsData(allTeamsData.sleagueTeam);
      }
      
      if (
        teamError &&
        teamError.graphQLErrors[0].message === "Signature has expired"
        ) {
        navigate(`/lms/${admin_url}/signin`);
      }
    
    }
    };
  
  /*
     * Function : updateSelectedDate
     * Description : update selected date according to fixture date
     */

    const updateSelectedDate = async fixtureDate => {
    
      if (fixtureDate !== null){
      
         handleDateTimeChange(fixtureDate)
    
    }
    };
  
  
  /*
     * Function : updateSelectedWeek
     * Description : update selected week according to week Id
     */

    const updateSelectedWeek = async weekId => {
    
      if (weekId !== 0){
      
         setSelectedWeek(weekId)
    
    }
    };

    const updatOldTeam1Table = (scoreTeam1,scoreTeam2) =>
    {
          
      //compare two scores for creating table related data's for team1       

      //if (scoreFinalTeam1 != null && scoreFinalTeam1 !="" && scoreFinalTeam2 != null && scoreFinalTeam2 !="")
       //{
        
        //check if final score team1 is grether than fianl scroe team2, so team1 in winner
        if (parseInt(scoreTeam1) > parseInt(scoreTeam2))
          {
             
             setOldTeam1Points(3); 
             setOldTeam1Win(1);
             setOldTeam1Loss(0);
             setOldTeam1Draw(0);

             
          }           
                        

        //check if final score team1 is same as final score team2, so they drawn
        else if (parseInt(scoreTeam1) == parseInt(scoreTeam2))
          {
            
             setOldTeam1Points(1); 
             setOldTeam1Win(0);
             setOldTeam1Loss(0);
             setOldTeam1Draw(1);

             
          }

        //check if final score team1 is less than final score team2, so team1 is losser
        else
         {
             
             setOldTeam1Points(0); 
             setOldTeam1Win(0);
             setOldTeam1Loss(1);
             setOldTeam1Draw(0);

             
          }

        setOldTeam1GoalsFor(scoreTeam1); 
        setOldTeam1GoalsAgainst(scoreTeam2);
        setOldTeam1GoalDifference(parseInt(scoreTeam1) - parseInt(scoreTeam2));

               

      //}
  }

  const updatOldTeam2Table = (scoreTeam2,scoreTeam1) =>
    {
          
      //compare two scores for creating table related data's for team1       

      //if (scoreFinalTeam1 != null && scoreFinalTeam1 !="" && scoreFinalTeam2 != null && scoreFinalTeam2 !="")
       //{
        
        //check if final score team1 is grether than fianl scroe team2, so team1 in winner
        if (parseInt(scoreTeam2) > parseInt(scoreTeam1))
          {
            
             setOldTeam2Points(3); 
             setOldTeam2Win(1);
             setOldTeam2Loss(0);
             setOldTeam2Draw(0);

             
          }           
                        

        //check if final score team1 is same as final score team2, so they drawn
        else if (parseInt(scoreTeam2) == parseInt(scoreTeam1))
          {
            
             setOldTeam2Points(1); 
             setOldTeam2Win(0);
             setOldTeam2Loss(0);
             setOldTeam2Draw(1);

             
          }

        //check if final score team1 is less than final score team2, so team1 is losser
        else
         {

             setOldTeam2Points(0); 
             setOldTeam2Win(0);
             setOldTeam2Loss(1);
             setOldTeam2Draw(0);

             
          }

        setOldTeam2GoalsFor(scoreTeam2); 
        setOldTeam2GoalsAgainst(scoreTeam1);
        setOldTeam2GoalDifference(parseInt(scoreTeam2) - parseInt(scoreTeam1));

             

      //}
  }

   
   
    
  
//getting fixture information
  const { loading:fixtureLoading, error:fixtureError, data:fixtureData } = useQuery(GET_FIXTURE_OBJECT,{
    variables: {
      fixtureId: parseInt(fixtureId)
    }

    });
  
  useEffect(
    () => {
      
      if (!fixtureLoading && fixtureData && fixtureData.fixtureObject && fixtureData.fixtureObject.fixtureWeek 
          && fixtureData.fixtureObject.fixtureWeek.weekId && fixtureData.fixtureObject.fixtureWeek.weekId.sleagueId ) {
      
        setSelectedFixture(fixtureData.fixtureObject);
        setFixtureWeek(fixtureData.fixtureObject.scheduledWeek.weekId.name);
        setFixtureDateTime(dayFormat(fixtureData.fixtureObject.scheduledWeek.scheduledDate));

        if (fixtureData.fixtureObject.spExtra != null)
            setSPExtra(fixtureData.fixtureObject.spExtra);
        
        updateSleagues(fixtureData.fixtureObject.scheduledWeek.weekId.sleagueId.leagueId.leagueId);           
    
        updateWeeks(fixtureData.fixtureObject.scheduledWeek.weekId.sleagueId.sleagueId);
    
        updateTeams(fixtureData.fixtureObject.scheduledWeek.weekId.sleagueId.sleagueId);
    
        updateSelectedDate(fixtureData.fixtureObject.scheduledWeek.scheduledDate)
    
        updateSelectedWeek(fixtureData.fixtureObject.scheduledWeek.weekId.weekId) 

        setFixtureScoreUpdatedScreen(fixtureData.fixtureObject.fixtureScoreUpdatedScreen);

        setPlayed(fixtureData.fixtureObject.fixtureScoreUpdatedScreen);

        
      }
      if (fixtureError && fixtureError.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [fixtureData, fixtureError, fixtureLoading, navigate]
  );
  
   
      
  //getting fixture teams information
  const { loading, error, data } = useQuery(GET_FIXTURE_OBJECT_TEAMS,{
    variables: {
      fixtureId: parseInt(fixtureId)
    }

    });
  
  useEffect(
    () => {
      
      if (!loading && data && data.fixtureObjectTeams) {


        setFixtureTeams(data.fixtureObjectTeams);

        setFixtureTeam1(data.fixtureObjectTeams[0]);
        setFixtureTeam1Name(data.fixtureObjectTeams[0].sleagueTeamId.teamId.name);    
        setSleagueTeamId1(data.fixtureObjectTeams[0].sleagueTeamId.sleagueTeamId);

        setTeam1TotalPlayed(data.fixtureObjectTeams[0].sleagueTeamId.noGames);
        setTeam1TotalWin(data.fixtureObjectTeams[0].sleagueTeamId.noWins);
        setTeam1TotalDraw(data.fixtureObjectTeams[0].sleagueTeamId.noDraws);
        setTeam1TotalLoss(data.fixtureObjectTeams[0].sleagueTeamId.noLosses);
        setTeam1TotalGoalsFor(data.fixtureObjectTeams[0].sleagueTeamId.noGoalsFor);
        setTeam1TotalGoalsAgainst(data.fixtureObjectTeams[0].sleagueTeamId.noGoalsAgainst);
        setTeam1TotalGoalDifference(data.fixtureObjectTeams[0].sleagueTeamId.noGoalDifference);
        setTeam1TotalPoints(data.fixtureObjectTeams[0].sleagueTeamId.noPoints);

               
    
        if (data.fixtureObjectTeams[0].scoreHalf1 != null)
          setScoreHalf1Team1(data.fixtureObjectTeams[0].scoreHalf1);
    
        if (data.fixtureObjectTeams[0].scoreFinal != null)
          {
        
             setScoreFinalTeam1(data.fixtureObjectTeams[0].scoreFinal);
             setOldScoreFinalTeam1(data.fixtureObjectTeams[0].scoreFinal);
             
          }

        if (data.fixtureObjectTeams[0].scoreFinal != "" && data.fixtureObjectTeams[0].scoreFinal!= null && 
             data.fixtureObjectTeams[1].scoreFinal != "" && data.fixtureObjectTeams[1].scoreFinal!= null)
            updatOldTeam1Table(data.fixtureObjectTeams[0].scoreFinal,data.fixtureObjectTeams[1].scoreFinal); 

        setFixtureTeam2(data.fixtureObjectTeams[1]);
        setFixtureTeam2Name(data.fixtureObjectTeams[1].sleagueTeamId.teamId.name);
        setSleagueTeamId2(data.fixtureObjectTeams[1].sleagueTeamId.sleagueTeamId);

        setTeam2TotalPlayed(data.fixtureObjectTeams[1].sleagueTeamId.noGames);
        setTeam2TotalWin(data.fixtureObjectTeams[1].sleagueTeamId.noWins);
        setTeam2TotalDraw(data.fixtureObjectTeams[1].sleagueTeamId.noDraws);
        setTeam2TotalLoss(data.fixtureObjectTeams[1].sleagueTeamId.noLosses);
        setTeam2TotalGoalsFor(data.fixtureObjectTeams[1].sleagueTeamId.noGoalsFor);
        setTeam2TotalGoalsAgainst(data.fixtureObjectTeams[1].sleagueTeamId.noGoalsAgainst);
        setTeam2TotalGoalDifference(data.fixtureObjectTeams[1].sleagueTeamId.noGoalDifference);
        setTeam2TotalPoints(data.fixtureObjectTeams[1].sleagueTeamId.noPoints);

                

        
        if (data.fixtureObjectTeams[1].scoreHalf1 != null)
          setScoreHalf1Team2(data.fixtureObjectTeams[1].scoreHalf1);
        
        if (data.fixtureObjectTeams[1].scoreFinal != null)
         {
        
            setScoreFinalTeam2(data.fixtureObjectTeams[1].scoreFinal);
            setOldScoreFinalTeam2(data.fixtureObjectTeams[1].scoreFinal);
            
         }

       if (data.fixtureObjectTeams[0].scoreFinal != "" && data.fixtureObjectTeams[0].scoreFinal!= null && 
             data.fixtureObjectTeams[1].scoreFinal != "" && data.fixtureObjectTeams[1].scoreFinal!= null)   
            updatOldTeam2Table(data.fixtureObjectTeams[1].scoreFinal,data.fixtureObjectTeams[0].scoreFinal);

        if (data.fixtureObjectTeams[0] && data.fixtureObjectTeams[0].home)
          setHomeValue("team1");
        else
          setHomeValue("team2");
    
    }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, error, loading, navigate]
  );
  
 
    
  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

   /*
   * Function : fixtureTeamEdit
   * Description : edit fixture team data
   */
  const fixtureTeamEdit = (fixtureTeam, teamId, scoreHalf1, scoreFinal, home, oldScoreFinalAnotherTeam, newScoreFinalAnotherTeam,played) => {  

    const { teamData, teamError } = editFixtureTeam({
      variables: {
         fixtureTeamId: parseInt(fixtureTeam.fixtureTeamId),
         sleagueTeamId: parseInt(teamId),
         scoreHalf1: String(scoreHalf1),
         scoreFinal: String(scoreFinal),
         home: home,
         oldScoreFinalAnotherTeam: String(oldScoreFinalAnotherTeam),
         newScoreFinalAnotherTeam: String(newScoreFinalAnotherTeam),
         played: played,
      }    
    });

    if (teamError) {
      console.log("error", teamError);

      setFailedMessage(
        "Unfortunately an error occurred for editing fixture team."
      );
    } else if (teamData && teamData.createFixtureTeam) {
      console.log("Success", teamData.createFixtureTeam);
      setSuccessMessage("Fixture Team Edit successfully.");
    }
  };


  

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);

    //if played checkbox not checked, don't allow to save 


    if (((scoreHalf1Team1 != "" && scoreHalf1Team1 != null) ||
        (scoreFinalTeam1 != "" && scoreFinalTeam1 != null) || 
        (scoreHalf1Team2 != "" && scoreHalf1Team2 != null) ||
        (scoreFinalTeam2 != "" && scoreFinalTeam2 != null)) && 
        fixtureScoreUpdatedScreen == false)
    {
      alert("Fixture Already Played should be checked.")
    }
    
    else
    {   
    
      const { data, error } = await editFixture({
        variables: {
         fixtureId: parseInt(fixtureId),
          weekId: parseInt(selectedWeek),
          spExtra: String(SPExtra),
          fixtureDate: selectedDateTime,
          fixtureScoreUpdatedScreen: fixtureScoreUpdatedScreen,

        }
      });
      

      if (error) {
        console.log("error", error);

        setFailedMessage("Unfortunately an error occurred.");
      } else if (data && data.editFixture) {
        console.log("Success", data.editFixture);
        //edit record in fixture_team
        
        let team1Home,
          team2Home = "";
    
    
        if (homeValue == "team1") {
          team1Home = true;
          team2Home = false;
        } else {
          team1Home = false;
          team2Home = true;
        }
      
        
        fixtureTeamEdit(
          fixtureTeam1,
          sleagueTeamId1,
          scoreHalf1Team1,
          scoreFinalTeam1,
          team1Home,
          fixtureTeam2.scoreFinal,
          scoreFinalTeam2,
          played
        );



        fixtureTeamEdit(
          fixtureTeam2,
          sleagueTeamId2,
          scoreHalf1Team2,
          scoreFinalTeam2,
          team2Home,
          fixtureTeam1.scoreFinal,
          scoreFinalTeam1,
          played
        );

        setSuccessMessage("Fixture and Fixture Teams has been Edited successfully.");
      setTimeout("");
      navigate(`/lms/${admin_url}/fixture`);
      }
    }
  };

 const updateTeamTable = (score1,score2) =>
    {
       let points, wins , losses, drawns, gf, ga, gd = 0    
      //compare two scores for creating table related data's for team1     
        
        //check if final score team1 is grether than fianl scroe team2, so team1 in winner
        if (parseInt(score1) > parseInt(score2))
          {            
             points = 3; 
             wins = 1;
             losses = 0;
             drawns = 0;             
          }           
                        

        //check if final score team1 is same as final score team2, so they drawn
        else if (parseInt(score1) == parseInt(score2))
          {            
             points = 1; 
             wins = 0;
             losses = 0;
             drawns = 1;             
          }

        //check if final score team1 is less than final score team2, so team1 is losser
        else if (parseInt(score1) < parseInt(score2))
         {            
             points = 0; 
             wins = 0;
             losses = 1;
             drawns = 0;             
          }

        gf = score1; 
        ga = score2;
        gd = parseInt(score1) - parseInt(score2);

        return [points, wins , losses, drawns, gf, ga, gd];
       
  }  

const generateTableStandingValuesTeam1 = async() => 
    {       
      let [points, wins , losses, drawns, gf, ga, gd] = await (updateTeamTable(scoreFinalTeam1,scoreFinalTeam2));    
      //check if fixtureTeam final score is diffrent from new final score
      //or another fixture team final score is diffrent from new another final score
      //update total values
      setTeam1Points(points);
      setTeam1Win(wins);
      setTeam1Loss(losses);
      setTeam1Draw(drawns);
      setTeam1GoalsFor(gf);
      setTeam1GoalsAgainst(ga);
      setTeam1GoalDifference(gd);          

          if (parseInt(fixtureTeam1.scoreFinal) != parseInt(scoreFinalTeam1) || parseInt(fixtureTeam2.scoreFinal) != parseInt(scoreFinalTeam2))
            {
              
               setTeam1TotalWin(parseInt(fixtureTeam1.sleagueTeamId.noWins) - parseInt(oldTeam1Win) + parseInt(wins));
               setTeam1TotalDraw(parseInt(fixtureTeam1.sleagueTeamId.noDraws) - parseInt(oldTeam1Draw) + parseInt(drawns));
               setTeam1TotalLoss(parseInt(fixtureTeam1.sleagueTeamId.noLosses) - parseInt(oldTeam1Loss) + parseInt(losses));
               setTeam1TotalGoalsFor(parseInt(fixtureTeam1.sleagueTeamId.noGoalsFor) - parseInt(oldTeam1GoalsFor) + parseInt(gf));
               setTeam1TotalGoalsAgainst(parseInt(fixtureTeam1.sleagueTeamId.noGoalsAgainst) - parseInt(oldTeam1GoalsAgainst) + parseInt(ga));
               setTeam1TotalGoalDifference(parseInt(fixtureTeam1.sleagueTeamId.noGoalDifference) - parseInt(oldTeam1GoalDifference) + parseInt(gd));
               setTeam1TotalPoints(parseInt(fixtureTeam1.sleagueTeamId.noPoints) - parseInt(oldTeam1Points) + parseInt(points));
            }   
       
    }; 

    const generateTableStandingValuesTeam2 = async() => 
    {       
      let [points, wins , losses, drawns, gf, ga, gd] = await (updateTeamTable(scoreFinalTeam2,scoreFinalTeam1));    
      //check if fixtureTeam final score is diffrent from new final score
      //or another fixture team final score is diffrent from new another final score
      //update total values 
          setTeam2Points(points);
          setTeam2Win(wins);
          setTeam2Loss(losses);
          setTeam2Draw(drawns);
          setTeam2GoalsFor(gf);
          setTeam2GoalsAgainst(ga);
          setTeam2GoalDifference(gd);   

          if (parseInt(fixtureTeam2.scoreFinal) != parseInt(scoreFinalTeam2) || parseInt(fixtureTeam1.scoreFinal) != parseInt(scoreFinalTeam1))
            {
              
               setTeam2TotalWin(parseInt(fixtureTeam2.sleagueTeamId.noWins) - parseInt(oldTeam2Win) + parseInt(wins));
               setTeam2TotalDraw(parseInt(fixtureTeam2.sleagueTeamId.noDraws) - parseInt(oldTeam2Draw) + parseInt(drawns));
               setTeam2TotalLoss(parseInt(fixtureTeam2.sleagueTeamId.noLosses) - parseInt(oldTeam2Loss) + parseInt(losses));
               setTeam2TotalGoalsFor(parseInt(fixtureTeam2.sleagueTeamId.noGoalsFor) - parseInt(oldTeam2GoalsFor) + parseInt(gf));
               setTeam2TotalGoalsAgainst(parseInt(fixtureTeam2.sleagueTeamId.noGoalsAgainst) - parseInt(oldTeam2GoalsAgainst) + parseInt(ga));
               setTeam2TotalGoalDifference(parseInt(fixtureTeam2.sleagueTeamId.noGoalDifference) - parseInt(oldTeam2GoalDifference) + parseInt(gd));
               setTeam2TotalPoints(parseInt(fixtureTeam2.sleagueTeamId.noPoints) - parseInt(oldTeam2Points) + parseInt(points));
            }   
       
    };     



 /*
  * Function : leagueChange
  * Description : handle league change
  */
  const leagueChange = async e => {
    const tid = e.target.value;
  updateSleagues(tid);      
  };

  /*
   * Function : sleagueChange
   * Description : handle Sleague change
   */
   const sleagueChange = event => {
    updateWeeks(event.target.value);
    updateTeams(event.target.value);    
    
  };
    
    /*
   * Function : weekChange
   * Description : handle week change
   */
  const weekChange = event => {
    
    updateSelectedWeek(event.target.value);
    
  };

   /*
   * Function :updateSPExtra
   * Description : handle PSP Extra change
   */
  const updateSPExtra = event => {
   
    setSPExtra(String(event.target.value));
    
  };
  
   /*
   * Function : team1Change
   * Description : handle team1 change
   */
  const team1Change = event => {
    
    setSleagueTeamId1(event.target.value);
    
  };
  
  /*
   * Function :updateScoreHalf1Team1
   * Description : handle score half1 for team1 change
   */
  const updateScoreHalf1Team1 = event => {
   
    setScoreHalf1Team1(String(event.target.value));    
    
  };
  
   /*
   * Function :updateScoreFinalTeam1
   * Description : handle score final for team1 change
   */
  const updateScoreFinalTeam1 = event => {    
   
    setScoreFinalTeam1(String(event.target.value));
        
        
  };
  
  /*
   * Function : team2Change
   * Description : handle team2 change
   */
  const team2Change = event => {
    setSleagueTeamId2(event.target.value);
    
    
  };
  
  /*
   * Function :updateScoreHalf1Team2
   * Description : handle score half1 for team2 change
   */
  const updateScoreHalf1Team2 = event => {
   
    setScoreHalf1Team2(String(event.target.value));
    
  };

    /*
   * Function :updateScoreFinalTeam2
   * Description : handle score final for team2 change
   */
  const updateScoreFinalTeam2 = event => {
   
    setScoreFinalTeam2(String(event.target.value));
    
    
    
  };  
  
  
  /*
   * Function :homeChange
   * Description : handle home change
   */
  const homeChange = event => {
   
    setHomeValue(event.target.value);
    
  };

  /*
   * Function :homehandleCheckBoxChangeChange
   * Description : handle fixture Played changed
   */
  const handleCheckBoxChange = (e) =>{
    setFixtureScoreUpdatedScreen(e.target.checked);
  }
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (async(e) => { 
     
    if ( parseInt(scoreFinalTeam1Bucket) != parseInt(scoreFinalTeam1) || parseInt(scoreFinalTeam2Bucket) != parseInt(scoreFinalTeam2))
    {      
      generateTableStandingValuesTeam1();
      setScoreFinalTeam1Bucket(scoreFinalTeam1);
    
      generateTableStandingValuesTeam2();
      setScoreFinalTeam2Bucket(scoreFinalTeam2);
    }
        
    setOpen(true);
  });

  

  const columns = [

      {
        field: "team",
        headerName: "Team",
        width: 200,
        editable: false
      },

      {
        field: "played",
        headerName: "Played",
        width: 150,
        editable: false
      }, 

      {
        field: "w",
        headerName: "W",
        width: 100,
        editable: false
        
      },

      {
        field: "d",
        headerName: "D",
        width: 100,
        editable: false
      },

      {
        field: "l",
        headerName: "L",
        width: 100,
        editable: false
      },

      {
        field: "gf",
        headerName: "GF",
        width: 100,
        editable: false
      },

      {
        field: "ga",
        headerName: "GA",
        width: 100,
        editable: false
      },

      {
        field: "gd",
        headerName: "GD",
        width: 120,
        editable: false
      },

      {
        field: "points",
        headerName: "Points",
        width: 150,
        editable: false
      },   
      
    ];
    
    
    const rows = fixtureTeams.map(ft => ({
      id: ft.fixtureTeamId,
      team: ft.sleagueTeamId.teamId.name,
      played: ft.sleagueTeamId.noGames,
      w: ft.sleagueTeamId.noWins,
      d: ft.sleagueTeamId.noDraws,
      l: ft.sleagueTeamId.noLosses,
      gf: ft.sleagueTeamId.noGoalsFor,
      ga: ft.sleagueTeamId.noGoalsAgainst,
      gd: ft.sleagueTeamId.noGoalDifference,
      points: ft.sleagueTeamId.noPoints
    }
    ));
      

    const currentRows = [
                        {id:1,team:fixtureTeam1Name,played:1,w:team1Win,d:team1Draw,
                        l:team1Loss,gf:team1GoalsFor,ga:team1GoalsAgainst,gd:team1GoalDifference,points:team1Points},
                        {id:2,team:fixtureTeam2Name,played:1,w:team2Win,d:team2Draw,
                        l:team2Loss,gf:team2GoalsFor,ga:team2GoalsAgainst,gd:team2GoalDifference,points:team2Points}
                        ]


                           

    const totalRows = [
                        {id:1,team:fixtureTeam1Name,
                         played: (played == true?team1TotalPlayed:team1TotalPlayed + 1),
                         w:  team1TotalWin,
                         d:  team1TotalDraw,
                         l:  team1TotalLoss,
                         gf: team1TotalGoalsFor,
                         ga: team1TotalGoalsAgainst,
                         gd:  team1TotalGoalDifference,
                         points:  team1TotalPoints},

                        {id:2,team:fixtureTeam2Name,
                         played: (played == true?team2TotalPlayed:team2TotalPlayed + 1),
                         w:  team2TotalWin,
                         d:  team2TotalDraw,
                         l:  team2TotalLoss,
                         gf: team2TotalGoalsFor,
                         ga: team2TotalGoalsAgainst,
                         gd:  team2TotalGoalDifference,
                         points:  team2TotalPoints},
                        ]

   
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Edit Fixture | Admin</title>
      </Helmet>
      <Grid container spacing={3} alignItems="flex-start">
        <Layout />
        
       <Grid item xs={4} justifyContent="center" direction="row" spacing={3}>
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Edit fixture</h2>
      
          {selectedFixture.rescheduled===true?<label className={adminStyle.label}>As this fixture is Reschduled, you cannot edit week and fixture date</label>:""}
          
          <label className={adminStyle.label} htmlFor="league">
                    League{" "}
                  </label>
                  <select
                    className={adminStyle.select}
                    id="league"
                    {...register("league")}
                    onChange={leagueChange}
              disabled={selectedFixture.rescheduled===true?true:null}
                  >
                    <option value="0">Select a League...</option>
                    {leaguesData.map((league, i) => (
                      <option key={i} value={league.leagueId}
              selected={selectedFixture.scheduledWeek && selectedFixture.scheduledWeek != undefined && 
                             selectedFixture.scheduledWeek.weekId.sleagueId.leagueId.leagueId===league.leagueId?"selected":""}>
                        {league.title}
                      </option>
                    ))}
                  </select>
          
          <label className={adminStyle.label} htmlFor="season">
                    Season
                  </label>
                  <select
                    className={adminStyle.select}
                    id="season"
                    {...register("sleague")}
                    onChange={sleagueChange}
              disabled={selectedFixture.rescheduled===true?true:null}
                  >
                    <option value="0">Select a Season...</option>
                    {sleaguesData.map((sleague, i) => (
                      <option key={i} value={sleague.sleagueId}
              selected={selectedFixture.scheduledWeek && selectedFixture.scheduledWeek != undefined && 
                             selectedFixture.scheduledWeek.weekId.sleagueId.sleagueId===sleague.sleagueId?"selected":""}>
                        {sleague.seasonName}
                      </option>
                    ))}
                  </select> 
            
                  
          <label className={adminStyle.label} htmlFor="weekId">
                <b>Week</b>
              </label>
              <select
                className={adminStyle.select}
                id="weekId"
                {...register("weekId")}
                onChange={weekChange} 
                disabled={selectedFixture.rescheduled===true?true:null}     
              >
                <option value="0">Select a week...</option>
                {allSleagueWeeks.map((allSleagueWeek, i) => (
                  <option key={i} value={allSleagueWeek.weekId}
            selected={selectedFixture.scheduledWeek && selectedFixture.scheduledWeek != undefined && 
                             selectedFixture.scheduledWeek.weekId.weekId===allSleagueWeek.weekId?"selected":""}     
            >
                    {allSleagueWeek.name}
                  </option>
                ))}
              </select>

          {fixtureScoreUpdatedScreen===true?
            <FormControlLabel
              control={<Checkbox  checked={fixtureScoreUpdatedScreen} onChange={handleCheckBoxChange}/>}
              className={adminStyle.label}
              disabled
              label={"Fixture Already Played"}
             />:
            <FormControlLabel
              control={<Checkbox  checked={fixtureScoreUpdatedScreen} onChange={handleCheckBoxChange}/>}
              className={adminStyle.label}                    
              label={"Fixture Already Played"}
            />
          }

          <label className={adminStyle.label} htmlFor="SPExtra">
                PSP Extra
              </label>
              <input
                className={adminStyle.input}
                id="SPExtra"
                {...register("SPExtra")}
            defaultValue={SPExtra}
            onChange={updateSPExtra}
            onSelect={updateSPExtra}
              />
          
          <label className={adminStyle.label} htmlFor="teamId1">
                <b>Team1</b>
              </label>
              <select
                className={adminStyle.select}
                id="teamId1"
                {...register("teamId1")}
            onChange={team1Change}
              >
                <option value="0">Select a team...</option>

                {teamsData.map((team, i) => (
                  <option key={i} value={team.sleagueTeamId}
              selected={sleagueTeamId1 && 
                             sleagueTeamId1===team.sleagueTeamId?"selected":""}
            >
                    {team.teamId.name}
                
                  </option>
                ))}
              </select>
              Home
              <input
                className={adminStyle.input}
            type="radio"
                id="home"
                {...register("home")}          
                name="home"
                value="team1"
            checked={homeValue==="team1"?true:false}
            onChange={homeChange}
            />
          
              <label className={adminStyle.label} htmlFor="team1scoreHalf1">
                Half Time Score
              </label>
              <input
                className={adminStyle.input}
                id="team1scoreHalf1"
                {...register("team1scoreHalf1")}
            defaultValue={scoreHalf1Team1}
            onChange={updateScoreHalf1Team1}
            onSelect={updateScoreHalf1Team1}
              />
              <label className={adminStyle.label} htmlFor="team1scoreFinal">
                Final Score
              </label>
              <input
                className={adminStyle.input}
                id="team1scoreFinal"
                {...register("team1scoreFinal")}
            defaultValue={scoreFinalTeam1}
            onChange={updateScoreFinalTeam1}
            onSelect={updateScoreFinalTeam1}            
              />
           
          
          <label className={adminStyle.label} htmlFor="teamId2">
                <b>Team2</b>
              </label>
              <select
                className={adminStyle.select}
                id="teamId2"
                {...register("teamId2")}
            onChange={team2Change}
              >
                <option value="0">Select a team...</option>

                {teamsData.map((team, i) => (
                  <option key={i} value={team.sleagueTeamId}
              selected={fixtureTeam2 && fixtureTeam2.sleagueTeamId != undefined && 
                             fixtureTeam2.sleagueTeamId.sleagueTeamId===team.sleagueTeamId?"selected":""}
            >
                    {team.teamId.name}
                  </option>
                ))}
              </select>
              Home
              <input
                className={adminStyle.input}
                id="home"
                {...register("home")}
                value="team2"
                name="home"
                type="radio"
            checked={homeValue==="team2"?true:false}
            onChange={homeChange}
                  
              />
              <label className={adminStyle.label} htmlFor="team2scoreHalf1">
                Half Time Score
              </label>
              <input
                className={adminStyle.input}
                id="team2scoreHalf1"
                {...register("team2scoreHalf1")}
            defaultValue={scoreHalf1Team2}
            onChange={updateScoreHalf1Team2}
            onSelect ={updateScoreHalf1Team2}
              />
              <label className={adminStyle.label} htmlFor="team2scoreFinal">
                Final Score
              </label>
              <input
                className={adminStyle.input}
                id="team2scoreFinal"
                {...register("team2scoreFinal")}
            defaultValue={scoreFinalTeam2}
            onChange={updateScoreFinalTeam2}
            onSelect={updateScoreFinalTeam2}
            
              />
          
          


          <label className={adminStyle.label} htmlFor="fixtureDate">
                Fixture Date & Time
              </label>
          
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  id="fixtureDate"
                  {...register("fixtureDate")}
                  value={selectedDateTime}
                  onChange={handleDateTimeChange}
            disabled={selectedFixture.rescheduled===true?true:null}
                />
              </MuiPickersUtilsProvider>
              
          
              <input className={adminStyle.input} type="submit" />
              {successMessage && (
                <div className={adminStyle.success}>{successMessage}</div>
              )}
              {failedMessage && (
                <div className={adminStyle.error}>{failedMessage}</div>
              )}
              
              
            </form>
            </Grid>
            
            <Grid item xs={4} justifyContent="center" spacing={3} style={{alignItems: "center" }}> 
            <Button
                variant="contained"
                color="primary"
                disabled={fixtureScoreUpdatedScreen?false:true}
                onClick={handleOpen}
                style={{
                                             
                      borderRadius: "1px",
                      marginTop: "80px",
                      marginRight: "120px",
                    }}
              >
                Preview Partial Table of Standing
              </Button>     
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1200px",
                    height: "100%",
                    maxHeight: "1000px",
                  },
                },
              }}
            >
              <DialogTitle id="alert-dialog-title"
                    style={{textAlign: "center",
                            color: "gray"}}>Preview Partial Table of Standing</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid item>
                  <Box
                    sx={{
                      height: 100,
                      width: '100%',
                      '& .super-app-theme--header': {
                        backgroundColor: '#CCCCCC',
                      },
                    }}
                  >
                    <Grid item>
                      <h3 className={adminStyle.h3} style={{textAlign: "center"}}>Team Results in Table of Standings before Score Update</h3>
                      <DataGrid rows={rows} columns={columns} autoHeight={true}  hideFooter={true}  className={classes.root} />
                    </Grid>

                    <h3 className={adminStyle.h3} style={{textAlign: "center"}}>
                      {fixtureWeek}: {fixtureTeam1Name} {scoreFinalTeam1} - {scoreFinalTeam2} {fixtureTeam2Name} ({fixtureDateTime})              
                    </h3>                          

                    <Grid item>
                      <h3 className={adminStyle.h3} style={{textAlign: "center"}}>Team Results in This Fixture</h3>
                      <DataGrid rows={currentRows} columns={columns} autoHeight={true}  hideFooter={true} className={classes.root}                   
                        />
                    </Grid>

                    <Grid item>
                      <h3 className={adminStyle.h3} style={{textAlign: "center"}}>Team Results in Table of Standings after Score Update</h3>
                      <DataGrid rows={totalRows} columns={columns} autoHeight={true}  hideFooter={true} className={classes.root}
                          />
                    </Grid>

                  </Box>
                  </Grid>            
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
            </Grid>
          
      
    </Grid>
  </>
  
  );
}
