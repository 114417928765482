import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
//import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
//import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
//import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
//import Box from "@mui/material/Box";
//import IconButton from "@mui/material/IconButton";
import update from "immutability-helper";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

import AlertDialog from "../../../components/AlertDialog.js";
import leagueLogo from "../../../assets/Premier_League-Logo.png";
import imgRes from "../../../assets/positionImgs/resMobile.png";
import footField from "../../../assets/positionImgs/football_field_mobile.png";
import { Selection } from "./Selection";
import { REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";
import { ItemTypes } from "../../../store/data/ItemTypes";
import { POSITIONS_DATA } from "../../../store/data/DummyDataMobile";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../graphQL/formationToLeague_qry";

import { GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../graphQL/gameStatAggr_qry";
import { GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../graphQL/positionPlayedAggr_qry";

import HeadToHeadDialog from "./HeadToHeadDialog.js";
import PlayerDataDialog from "./PlayerDataDialog.js";
import booked from "../../../assets/StatusImages/booked.gif";
import suspended from "../../../assets/StatusImages/suspended.gif";
import injured from "../../../assets/StatusImages/injured.png";
import unavailable from "../../../assets/StatusImages/unavailable.png";
import available from "../../../assets/StatusImages/available.png";
import sick from "../../../assets/StatusImages/sick.png";
import selected from "../../../assets/StatusImages/selected.png";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialog from "../ShareDialog.js";

import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

var findAnd = require("find-and");

const colors = {
  //gray: "#c4c4c4",
  Goalkeeper: "#D9D9D9",
  GoalkeeperLight: "#F7F4F7",
  Defender: "#E1FAFF",
  DefenderLight: "#F3FCFE",
  Midfielder: "#D8FFE3",
  MidfielderLight: "#F2FFF6",
  Forward: "#FFD3DA",
  ForwardLight: "#FFF3F5",
};
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      lock
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;

const useStyles = makeStyles({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    //  height: "48px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  // subheader: {
  //   color: "black",
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   backgroundColor: "white",
  //   marginLeft: 0,
  // },
  // derivedItem: {
  //   fontSize: 14,
  //   paddingLeft: 30,
  // },
  // menuPaper: {
  //   maxHeight: 200,
  // },
});

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px;
  }
`;

const TeamInfo = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqData,
  userSeqVal,
  userTSId,
  embargoTime,
  sleaguePlayers,
  sleagueTeamsData,
  passTeamSelectionsData,
  passSelectedFormation,
  footyNo,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);
  const [fullDataPlayersList, setFullDataPlayersList] = useState([]);
  const [formations, setFormations] = useState([]);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [isOnshare, setIsOnshare] = useState(false);
  const [selectionCreateDate, setSelectionCreateDate] = useState("");

  const [disableTeamSelection, setDisableTeamSelection] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState(() => {
    let userSeq = userSeqVal.trim();
    if (userSeq !== "") return userSeq.slice(0, 2);
    return "01";
  });

  const [selectedFormationTitle, setSelectedFormationTitle] = useState("");
  const [fixDate, setFixDate] = useState("");
  const [fixDateGetTime, setFixDateGetTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [counterDate, setCounterDate] = useState("");
  const [competitor, setCompetitor] = useState([]);
  const [homeTeam, setHomeTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [teamSelections, setTeamSelections] = useState([]);
  const [alertMsg, setAlertMsg] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);
  const [openFormationDialog, setOpenFormationDialog] = useState(false);
  const [openAddPlayerDialog, setOpenAddPlayerDialog] = useState(false);
  const [formationPosTxt, setFormationPosTxt] = useState("");
  const [positionToBeSelected, setPositionToBeSelected] = useState([]);
  const [h2HSelected, setH2HSelected] = useState(false);
  const [playerDataSelected, setPlayerDataSelected] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [h2HList, setH2HList] = useState([]);
  const [openH2HDialog, setOpenH2HDialog] = useState(false);

  const [sleagueTeamGameStatsAggr, setSleagueTeamGameStatsAggr] = useState([]);
  const [sleagueTeamPositionPlayedAggr, setSleagueTeamPositionPlayedAggr] = useState([]);

  // const [sleagueTeamPlayer] = useManualQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
  //   fetchPolicy: "network-only"
  // });

  const [fetchSleagueTeamGameStatAggrs] = useManualQuery(
    GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [fetchSleagueTeamPosPlayedAggrs] = useManualQuery(
    GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "day").value +
        " " +
        dayFormat.find(a => a.type == "month").value +
        ", " +
        dayFormat.find(a => a.type == "year").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value +
        ", " +
        dayFormat.find(a => a.type == "year").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find(a => a.type == "weekday").value +
        ", " +
        dayFormat.find(a => a.type == "year").value +
        ", " +
        dayFormat.find(a => a.type == "month").value +
        " " +
        dayFormat.find(a => a.type == "day").value;
      return newFormat;
    }
  };
  const timeFormat = dayDate => {
    const tFormat = formatter.formatToParts(dayDate);
    const newFormat =
      tFormat.find(a => a.type == "hour").value +
      ":" +
      tFormat.find(a => a.type == "minute").value +
      " " +
      tFormat.find(a => a.type == "dayPeriod").value;
    return newFormat;
  };
  const mainPath = "/lms/media/";

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: leagueId,
    },
  });

  useEffect(() => {
    setPlayers(
      sleaguePlayers.map(player => {
        let sleagueTeamPlayerId = player.sleagueTeamPlayerId;
        let name = player.playerId.name;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        let playerId = player.playerId;
        return {
          name,
          sleagueTeamPlayerId,
          playerId,
          position,
          jerseyNO,
          playerSeqNO,
          type,
          status,
        };
      })
    );
  }, [sleaguePlayers]);

  useEffect(() => {
    if ((disableTeamSelection || isLocked) && userSeqVal === "") setSelectedFormation("");
  }, [isLocked, disableTeamSelection, userSeqVal]);

  useEffect(() => {
    let findPositionsData = POSITIONS_DATA.find(x => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let droppedPlayerNamesVal = [];
    let draftTeamSelections = findPositionsData.positionsData;
    let val = "";
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(i => {
      switch (i) {
        case "01":
          val = userSeqVal.slice(4, 6);
          break;
        case "02":
          val = userSeqVal.slice(8, 10);
          break;
        case "03":
          val = userSeqVal.slice(12, 14);
          break;
        case "04":
          val = userSeqVal.slice(16, 18);
          break;
        case "05":
          val = userSeqVal.slice(20, 22);
          break;
        case "06":
          val = userSeqVal.slice(24, 26);
          break;
        case "07":
          val = userSeqVal.slice(28, 30);
          break;
        case "08":
          val = userSeqVal.slice(32, 34);
          break;
        case "09":
          val = userSeqVal.slice(36, 38);
          break;
        case "10":
          val = userSeqVal.slice(40, 42);
          break;
        case "11":
          val = userSeqVal.slice(44, 46);
      }
      resFound = findAnd.returnFound(players, {
        playerSeqNO: val,
      });

      if (resFound) {
        let resForPos = findAnd.returnFound(draftTeamSelections, {
          positionSeqNo: i,
        });

        draftTeamSelections = findAnd.changeProps(
          draftTeamSelections,
          { positionSeqNo: i },
          {
            lastDroppedItem: {
              name: resFound.name,
              playerSeqNO: resFound.playerSeqNO,
              jerseyNO: resFound.jerseyNO,
              formationPos: resForPos && resForPos.text,
            },
          }
        );
        droppedPlayerNamesVal.push(resFound.name);
      }
    });
    setDroppedPlayerNames(droppedPlayerNamesVal);
    setTeamSelections(draftTeamSelections);
    passTeamSelectionsData(droppedPlayerNamesVal, draftTeamSelections);
  }, [players]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {
      leagueFormationsData.leagueFormation.map(leagueFrm => {
        if (!parentFormations.includes(leagueFrm.formationId.formationPrimary)) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(frm => {
          return frm.formationId.formationPrimary === formation;
        });
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedFormation,
    });
    if (resFound) setSelectedFormationTitle(resFound.title);
    setFormations(formationsList);
  }, [leagueFormationsData, selectedFormation]);

  function RenderPlayerList(props) {
    const { item } = props;
    return players.map(
      ({ playerId, name, position, jerseyNO, playerSeqNO, status }, index) => {
        if (item === position) {
          let resFound = findAnd.returnFound(teamSelections, {
            name: name,
          });
          let odd = index % 2 == 1;
          const labelId = `${playerSeqNO}-${index}`;
          return (
            <ListItem
              key={labelId}
              secondaryAction={
                <>
                  {droppedPlayerNames.indexOf(name) !== -1 &&
                    !h2HSelected &&
                    !playerDataSelected && (
                      <img style={{ paddingLeft: 5, paddingTop: 2 }} src={selected} />
                    )}
                </>
              }
              disablePadding
            ></ListItem>
          );
        }
      }
    );
  }

  const renderSelectGroup = formation => {
    const items = formation.derivedCategories.map(f => {
      return (
        <MenuItem
          style={{ paddingLeft: 30, fontSize: 14 }}
          key={f.formationId.formationSeqNO}
          value={f.formationId.formationSeqNO}
        >
          {f.formationId.title}
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        style={{
          color: "black",
          fontSize: 14,
          fontWeight: "bold",
          backgroundColor: "white",
          marginLeft: 0,
        }}
      >
        {formation.mainCategory}
      </ListSubheader>,
      items,
    ];
  };

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 500 milliseconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 200);
        loadImg.onerror = err => {
          reject(err);
        };
      });
    };
    let IMAGES = [];
    if (teamSelections) {
      if (teamSelections.GK) IMAGES.push(imgRes, teamSelections.GK[0].img);
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_1)
        teamSelections.DEF.defLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_2)
        teamSelections.DEF.defLane_col_2.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_1)
        teamSelections.MID.midLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_2)
        teamSelections.MID.midLane_col_2.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_3)
        teamSelections.MID.midLane_col_3.map(i => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_1)
        teamSelections.FOR.forLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_2)
        teamSelections.FOR.forLane_col_2.map(i => IMAGES.push(i.img));
    }
    if (IMAGES.length == 0) return;
    Promise.all(IMAGES.map(img => loadImage(img)))
      .then(() => setIsLoad(true))
      .catch(err => console.log("Failed to load images", err));
  }, [teamSelections]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    // Set User Team Data
    if (!loading && data && data.fixtureObjectTeams) {
      data.fixtureObjectTeams.map(fixtureTeam => {
        // Set User team data
        if (parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId) {
          if (fixtureTeam.home) {
            console.log("fixtureTeam.home__________", fixtureTeam);
            setHomeTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          } else
            setSecondTeam([
              userFavTeam.teamInfo,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);

          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            //second: "2-digit",
            hour12: true,
          };
          // convert to milliseconds
          setCounterDate(
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime() -
              (embargoTime && embargoTime.value ? embargoTime.value : 4) * 3600000
          );
          var fDate = new Date(
            fixtureTeam.fixtureId.fixtureWeek.scheduledDate
          ).toLocaleTimeString("en-us", options);
          setFixDateGetTime(
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime()
          );
          var year = fDate.split(" ")[3];
          var day = fDate.split(" ")[2];
          var month = fDate.split(" ")[1];
          var time = fDate.split(" ")[4];
          var pam = fDate.split(" ")[5];
          var weekday = fDate.split(" ")[0];
          var formatedDate =
            weekday + " " + day.split(",")[0] + " " + month + " " + year.split(",")[0];
          var formatedTime = time + " " + pam;
          setFixDate(dayFormat(fixtureTeam.fixtureId.fixtureWeek.scheduledDate)); //formatedDate);
          setIsLocked(fixtureTeam.fixtureId.lock);
          //setDisableTeamSelection(fixtureTeam.fixtureId.lock); //false);
          setStartTime(formatedTime);
          setSelectionCreateDate(dayFormat(new Date()) + "  " + timeFormat(new Date()));
        }
        // Set User team competitor for that fixtureId
        else {
          if (fixtureTeam.home)
            setHomeTeam([
              fixtureTeam.sleagueTeamId.teamId,
              fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
            ]);
          setCompetitor([
            fixtureTeam.sleagueTeamId.teamId,
            fixtureTeam.scoreFinal !== null ? fixtureTeam.scoreFinal : "",
          ]);
        }
      });
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate("/lms/as12fgk628gh/signin");
    }
  }, [fixtureId, embargoTime, userFavTeam]);

  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingGSAggr,
        error: errorGSAggr,
        data: dataGSAggr,
      } = await fetchSleagueTeamGameStatAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      if (!loadingGSAggr && dataGSAggr && dataGSAggr.gameStatAggrSleagueteam) {
        setSleagueTeamGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
        console.log("sleagueTeamGameStatsAggrData----", dataGSAggr);
      }
    }
  }, [userFavTeam]);

  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingPosPlayedAggr,
        error: errorPosPlayedAggr,
        data: dataPosPlayedAggr,
      } = await fetchSleagueTeamPosPlayedAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      if (
        !loadingPosPlayedAggr &&
        dataPosPlayedAggr &&
        dataPosPlayedAggr.positionPlayedAggrSleagueteam
      ) {
        setSleagueTeamPositionPlayedAggr(dataPosPlayedAggr.positionPlayedAggrSleagueteam);
        console.log("sleagueTeamPositionPlayedAggr----", dataPosPlayedAggr);
      }
    }
  }, [userFavTeam]);

  useEffect(() => {
    // Updating players by data read from game_stat_aggr table
    if (
      players.length > 0 &&
      sleagueTeamGameStatsAggr.length > 0 &&
      sleagueTeamPositionPlayedAggr.length > 0
    ) {
      let foundGameStatAggr = {};
      let foundPlayedPositionAggr = {};

      players.map(player => {
        foundGameStatAggr = sleagueTeamGameStatsAggr.find(
          item =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        if (foundGameStatAggr) {
          player["gamesInfo"] = {
            full: {
              gamesNo: foundGameStatAggr.fullGame,
              minutes: foundGameStatAggr.fullMin,
            },
            eject: {
              gamesNo: foundGameStatAggr.ejectGame,
              minutes: foundGameStatAggr.ejectMin,
            },
            subIn: {
              gamesNo: foundGameStatAggr.subinGame,
              minutes: foundGameStatAggr.subinMin,
            },
            subOut: {
              gamesNo: foundGameStatAggr.suboutGame,
              minutes: foundGameStatAggr.suboutMin,
            },
            subInOut: {
              gamesNo: foundGameStatAggr.subInOutGame,
              minutes: foundGameStatAggr.subInOutMin,
            },
            total: {
              gamesNo: foundGameStatAggr.totalGame,
              minutes: foundGameStatAggr.totalMin,
            },
            perGame: foundGameStatAggr.avgMinPerGame,
          };

          player["stats"] = {
            games: foundGameStatAggr.totalGame,
            ejected: foundGameStatAggr.ejectGame,
            goals: foundGameStatAggr.goal,
            yc: foundGameStatAggr.cardYellow,
            rc: foundGameStatAggr.cardRed,
          };
        } else {
          player["gamesInfo"] = {
            full: {
              gamesNo: 0,
              minutes: 0,
            },
            eject: {
              gamesNo: 0,
              minutes: 0,
            },
            subIn: {
              gamesNo: 0,
              minutes: 0,
            },
            subOut: {
              gamesNo: 0,
              minutes: 0,
            },
            subInOut: {
              gamesNo: 0,
              minutes: 0,
            },
            total: {
              gamesNo: 0,
              minutes: 0,
            },
            perGame: 0,
          };

          player["stats"] = {
            games: 0,
            ejected: 0,
            goals: 0,
            yc: 0,
            rc: 0,
          };
        }
      });
      players.map(player => {
        foundPlayedPositionAggr = sleagueTeamPositionPlayedAggr.find(
          item =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        if (foundPlayedPositionAggr) {
          player["counts"] = {
            gkCount: foundPlayedPositionAggr.GKCount,
            ldefCount: foundPlayedPositionAggr.LDEFCount,
            cdefCount: foundPlayedPositionAggr.CDEFCount,
            rdefCount: foundPlayedPositionAggr.RDEFCount,
            lmfCount: foundPlayedPositionAggr.LMFCount,
            cmfCount: foundPlayedPositionAggr.CMFCount,
            rmfCount: foundPlayedPositionAggr.RMFCount,
            lforCount: foundPlayedPositionAggr.LFORCount,
            cforCount: foundPlayedPositionAggr.CFORCount,
            rforCount: foundPlayedPositionAggr.RFORCount,
          };
        } else {
          player["counts"] = {
            gkCount: 0,
            ldefCount: 0,
            cdefCount: 0,
            rdefCount: 0,
            lmfCount: 0,
            cmfCount: 0,
            rmfCount: 0,
            lforCount: 0,
            cforCount: 0,
            rforCount: 0,
          };
        }
      });
      players.sort(function (a, b) {
        if (a.gamesInfo && b.gamesInfo) {
          return (
            parseInt(b.gamesInfo.total.gamesNo) - parseInt(a.gamesInfo.total.gamesNo)
          );
        }
      });
      setFullDataPlayersList(players);
      console.log("FullDataPlayersList_____", players);
    }
  }, [sleagueTeamGameStatsAggr, sleagueTeamPositionPlayedAggr, players]);
  useEffect(() => {
    if (!fixDateGetTime) return;
    var timeDiff = Date.now() > fixDateGetTime;
    if (isLocked && disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot edit your Starting XI team. "
        );
      else {
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot select your Starting XI team."
        );
      }
    }
    if (isLocked && !disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg("This fixture is locked now. You cannot edit your Starting XI team.");
      else {
        setAlertMsg(
          "This fixture is locked now. You cannot select your Starting XI team."
        );
      }
    }
    if (!isLocked && disableTeamSelection && !timeDiff)
      setAlertMsg(
        "This fixture is in embargo at this time. You cannot create or edit your Starting XI team. "
      );
  }, [disableTeamSelection, isLocked, userSeqVal, fixDateGetTime]);
  useEffect(() => {
    const findPositionsData = POSITIONS_DATA.find(x => {
      return x.formationSeqNo === selectedFormation;
    });
    if (findPositionsData) {
      let resFound = [];
      let draftTeamSelections = findPositionsData.positionsData;

      // find position seq no from previous selected formation and put in new formation dropped item
      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(item => {
        resFound = findAnd.returnFound(teamSelections, {
          positionSeqNo: item,
        });

        if (resFound) {
          draftTeamSelections = findAnd.changeProps(
            draftTeamSelections,
            { positionSeqNo: item },
            { lastDroppedItem: resFound.lastDroppedItem }
          );
        }
      });

      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
    }
  }, [selectedFormation]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate("/lms/as12fgk628gh/signin");
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  const setSelectedPlayer = (name, playerSeqNO, jerseyNO) => {
    // if (localStorage.getItem("refreshToken")) {
    //   onRefreshToken();
    // } else navigate("/lms/as12fgk628gh/signin");

    if (positionToBeSelected.length > 0) {
      // console.log(
      //   "setSelectedPlayer______",
      //   positionToBeSelected,
      //   droppedPlayerNames,
      //   teamSelections,
      //   openAddPlayerDialog,
      //   positionToBeSelected
      // );
      let index = positionToBeSelected[0];
      let pos = positionToBeSelected[1];
      let posLane = positionToBeSelected[2];
      let formationPos = positionToBeSelected[3];

      if (disableTeamSelection || isLocked) return;
      let resFound = findAnd.returnFound(players, {
        playerSeqNO: playerSeqNO,
      });
      if (resFound && resFound.status !== "Fit" && resFound.status !== "Booked") return;
      let draftTeamSelections = [];

      if (droppedPlayerNames.indexOf(name) > -1) return;
      // If a player is dropped into the position of another player, delete the previous player
      if (pos === "GK") {
        if (teamSelections[pos][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [index]: {
              lastDroppedItem: {
                $set: { name, playerSeqNO, jerseyNO, formationPos },
              },
            },
          },
        });
      } else {
        if (teamSelections[pos][posLane][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][posLane][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [posLane]: {
              [index]: {
                lastDroppedItem: {
                  $set: { name, playerSeqNO, jerseyNO, formationPos },
                },
              },
            },
          },
        });
      }
      let draftDroppedPlayerNames = update(
        droppedPlayerNames,
        name ? { $push: [name /*, playerSeqNO*/] } : { $push: [] }
      );
      // console.log(
      //   "draftDroppedPlayerNames____set",
      //   draftDroppedPlayerNames,
      //   draftTeamSelections,
      // );
      if (!h2HSelected) {
        setDroppedPlayerNames(draftDroppedPlayerNames);
        setTeamSelections(draftTeamSelections);
      }
      if (h2HSelected) passTeamSelectionsData(droppedPlayerNames, teamSelections);
      else passTeamSelectionsData(draftDroppedPlayerNames, draftTeamSelections);
      //  setPositionToBeSelected([]);
    }
  };

  const onRemoveDroppedItem = name => {
    // remove player from droppedPlayerNames list
    if (positionToBeSelected.length > 0) {
      let posIdx = positionToBeSelected[0];
      let pos = positionToBeSelected[1];
      let posLane = positionToBeSelected[2];
      // set lastDroppedItem to null for removed item
      let draftTeamSelections = [];

      if (pos === "GK") {
        if (
          teamSelections.GK[0] &&
          teamSelections.GK[0].lastDroppedItem.name === name &&
          posIdx === 0
        ) {
          draftTeamSelections = update(teamSelections, {
            [pos]: {
              [0]: {
                lastDroppedItem: {
                  $set: null,
                },
              },
            },
          });
          setTeamSelections(draftTeamSelections);
          var i = droppedPlayerNames.indexOf(name);
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
      } else {
        teamSelections[pos][posLane] &&
          teamSelections[pos][posLane].map(({ lastDroppedItem }, index) => {
            if (lastDroppedItem && lastDroppedItem.name === name && index === posIdx) {
              draftTeamSelections = update(teamSelections, {
                [pos]: {
                  [posLane]: {
                    [index]: {
                      lastDroppedItem: {
                        $set: null,
                      },
                    },
                  },
                },
              });
              setTeamSelections(draftTeamSelections);
              var i = droppedPlayerNames.indexOf(name);
              i > -1 && droppedPlayerNames.splice(i, 1);
            }
          });
      }
      // console.log(
      //   "draftTeamSelections___droppedPlayerNames",
      //   draftTeamSelections,
      //   droppedPlayerNames
      // );
      if (draftTeamSelections.length > 0) {
        passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
        //setPositionToBeSelected([]);
      }
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            mt={1.5}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
            }}
          ></Box>
          <Box
            mt={1.5}
            style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" style={{ fontSize: 24 }}>
              EPL-Season 2023-24
            </Typography>
          </Box>

          <Box
            mt={2}
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Box
              style={{
                display: "flex",
                paddingTop: 5,
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                {homeTeam[0] && secondTeam
                  ? homeTeam[0].shortName
                  : userFavTeam.teamInfo && userFavTeam.teamInfo.shortName}{" "}
              </Typography>
            </Box>
            <Box
              ml={1}
              mr={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box>
                <img
                  src={
                    homeTeam[0] && secondTeam && homeTeam[0].logo
                      ? mainPath + homeTeam[0].logo
                      : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                      ? mainPath + userFavTeam.teamInfo.logo
                      : ""
                  }
                  //alt="teamLogo"
                  height={36}
                  width={36}
                />
              </Box>
              <Box
                ml={1}
                mr={1}
                style={{
                  minWidth: 48,
                  backgroundColor: "#E0E0E0",
                  height: 40,
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  //  paddingTop: 5,
                }}
              >
                <Typography variant="subtitle2" style={{ fontSize: 18 }}>
                  {homeTeam && homeTeam[1]} - {secondTeam && secondTeam[1]}
                </Typography>
              </Box>
              <Box>
                <img
                  src={
                    !secondTeam[0]
                      ? competitor[0] && competitor[0].logo
                        ? mainPath + competitor[0].logo
                        : ""
                      : secondTeam[0] && mainPath + secondTeam[0].logo
                  }
                  //alt="teamLogo"
                  height={36}
                  width={36}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                paddingTop: 5,
                justifyContent: "start",
              }}
            >
              <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                {!secondTeam[0]
                  ? competitor[0] && competitor[0].shortName
                  : secondTeam[0] && secondTeam[0].shortName}
              </Typography>
            </Box>
          </Box>

          <Box
            mt={1}
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 16 }}>
              {fixDate}
            </Typography>
          </Box>
          <Box
            mt={1.5}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 18 }}>
              Starting XI Team:{" "}
              {userFavTeam.teamInfo.shortName + " (" + selectedFormationTitle + ")"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {!isLoad || selectedFormation === "" ? (
        <div
          style={{
            backgroundImage: `url(${footField})`,
            width: 320,
            height: 454,
          }}
        />
      ) : (
        <>
          <div
            style={{
              display: "inline-flex",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div
                style={{
                  height: 23,
                  width: 320,
                  backgroundImage: `url(${imgRes})`,
                  //backgroundRepeat: "no-repeat",
                  flexDirection: "row",
                  //border: "1px solid white"
                }}
              />
              {teamSelections.FOR && teamSelections.FOR.forLane_col_2 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.FOR.forLane_col_2.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.FOR && teamSelections.FOR.forLane_col_1 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.FOR &&
                    teamSelections.FOR.forLane_col_1.map(
                      ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              embargoTime={true}
                            />
                          </div>
                        );
                      }
                    )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_3 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_3.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_2 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_2.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.MID && teamSelections.MID.midLane_col_1 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.MID.midLane_col_1.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.DEF && teamSelections.DEF.defLane_col_2 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.DEF.defLane_col_2.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              {teamSelections.DEF && teamSelections.DEF.defLane_col_1 && (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  {teamSelections.DEF.defLane_col_1.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              )}
              <div
                style={{
                  //  overflow: "hidden",
                  clear: "both",
                }}
              >
                {teamSelections.GK &&
                  teamSelections.GK.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                            embargoTime={true}
                          />
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        </>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Footy No: {footyNo}</Typography>
        <Typography variant="h6">
          As of: {selectionCreateDate ? selectionCreateDate : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default TeamInfo;
