
const GetUserTotalSp_query = `
    query ($userId: Int!, 
         $sleagueId:Int!){
    userTotalSp ( userId: $userId,
                  sleagueId: $sleagueId){      
      spTotal      
    }
  }
`;

const GetUsersSPAccount_query = `
  query{
    allUsersSPAccounting{
      spAccountingId
      userId{
        id
        username
        isActive
        email
      }
      sleagueId{
        sleagueId
      }    
      spTotal      
    }
  }
`;


module.exports = {
  GET_USER_TOTAL_SP_QUERY : GetUserTotalSp_query,
  GET_USER_SP_ACCOUNT_QUERY : GetUsersSPAccount_query,
}
