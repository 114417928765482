import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import {
  ADD_FORMATION_MUTATION,
  ALL_FORMATIONS_QUERY
} from "../graphQL/formation_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const primaryFormations = [
  "4-4-2",
  "4-2-4",
  "4-5-1",
  "5-3-2",
  "3-5-2",
  "4-3-3",
  "3-4-3",
  "5-4-1",
  "3-6-1"
];

const approvedFormations = [
  "4-4-2",
  "4-1-3-2",
  "4-3-1-2",
  "4-1-2-1-2",
  "4-2-2-2",
  "1-3-4-2",
  "3-1-4-2",
  "4-4-1-1",
  "4-2-4",
  "4-5-1",
  "4-1-4-1",
  "4-4-1-1",
  "4-2-3-1",
  "4-3-2-1",
  "1-3-5-1",
  "3-1-5-1",
  "2-2-5-1",
  "5-3-2",
  "5-2-1-2",
  "5-1-2-2",
  "1-4-3-2",
  "4-1-3-2",
  "3-5-2",
  "3-1-4-2",
  "3-4-1-2",
  "3-5-1-1",
  "4-3-3",
  "4-2-1-3",
  "4-1-2-3",
  "3-1-3-3",
  "1-3-3-3",
  "3-4-3",
  "3-3-1-3",
  "3-1-3-3",
  "3-4-2-1",
  "3-4-1-2",
  "5-4-1",
  "3-2-4-1",
  "2-3-4-1",
  "3-4-2-1",
  "3-2-4-1"
];

/*##########################################################################
## Function : Formation
## Description : Submit form data and create new record in formation table
## Author : SF
## Date: : 2022/02/23
##########################################################################*/
export default function Formation() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [allFormations, setAllFormations] = useState([]);
  const [addFormation] = useMutation(ADD_FORMATION_MUTATION, {
    refetchQueries: [{ query: ALL_FORMATIONS_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(ALL_FORMATIONS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  useEffect(
    () => {
      if (!loading && data && data.allFormations) {
        setAllFormations(data.allFormations);
        console.log("AllFormationsData----", data);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 7000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    console.log("Formations----", allFormations);
    await sleep(2000);
    let findItem = approvedFormations.includes(formData.title.toLowerCase());

    if (!findItem) {
      setFailedMessage(
        "This derivitive formation is not approved. Please enter another one."
      );
      // else if (findItem.formationSeqNO !== formData.formationSeqNO.toLowerCase()) {
      //   setFailedMessage(
      //     "The formation sequence number does not match the formation label. Please enter another one."
      //   );
    } else {
      // let findTitle = allFormations.find(
      //   item => item.title.toLowerCase() === formData.title.toLowerCase()
      // );

      let findFormationSeqNO = allFormations.find(
        item =>
          item.formationSeqNO.toLowerCase() ===
          formData.formationSeqNO.toLowerCase()
      );

      // if (findTitle) {
      //   setFailedMessage(
      //     "This is a duplicate formation title. Please enter another one."
      //   );
      // } else if (findFormationSeqNO) {
      if (findFormationSeqNO) {
        setFailedMessage(
          "This is a duplicate formation sequence Number. Please enter another one."
      );
      } else {
        const { data, error } = await addFormation({
          variables: {
            title: formData.title,
            formationSeqNO: formData.formationSeqNO,
            description: formData.description,
            formationPrimary: formData.primaryFormation
          }
        });
        if (error) {
          console.log("Error", error, data);
          setFailedMessage("Unfortunately an error occurred.");
          //  setTimeout("");
        } else if (data && data.addFormation) {
          console.log("Success", data.addFormation);
          allFormations.push(data.addFormation);
          setSuccessMessage(
            formData.title + " formation was added successfully."
          );
          setTimeout(() => {
            navigate(`/lms/${admin_url}/formation`);
          }, 2000);
          //  setTimeout("");
        }
      }
    }
  };
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Add Formation | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>
            Add a "Formation" to "Formation" table
          </h2>
          <label className={adminStyle.label} htmlFor="primaryFormation">
            <b>Primary Formation</b>
          </label>
          <select
            className={adminStyle.input}
            id="primaryFormation"
            {...register("primaryFormation", { required: true })}
          >
            <option value="0">Select a primary formation...</option>
            {primaryFormations.map((formation, i) => (
              <option key={i} value={formation}>
                {formation}
              </option>
            ))}
          </select>
          <label className={adminStyle.label} htmlFor="title">
            <b>Derivitive Formation</b>
          </label>
          <input
            className={adminStyle.input}
            id="title"
            {...register("title", { required: true })}
          />
          {errors.title && errors.title.type === "required" && (
            <span>Required</span>
          )}
          <label className={adminStyle.label} htmlFor="formationSeqNO">
            <b>Derivitive Formation Seq. No.</b>
          </label>
          <input
            className={adminStyle.input}
            id="formationSeqNO"
            {...register("formationSeqNO", {
              required: true,
              pattern: /^[0-9][0-9]?$/
            })}
          />
          {errors.formationSeqNO && errors.formationSeqNO.type === "required" && (
            <span>Required</span>
          )}
          {errors.formationSeqNO && errors.formationSeqNO.type === "pattern" && (
            <span>Please enter a valid number( 01 - 99 )</span>
          )}
          <label className={adminStyle.label} htmlFor="description">
            <b>Description</b>
          </label>
          <input
            className={adminStyle.input}
            id="description"
            {...register("description")}
          />
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
