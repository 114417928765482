import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useManualQuery, useQuery, ClientContext, useMutation } from "graphql-hooks";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {project_url, admin_url} from "../store/data/Constants";
import { useForm } from "react-hook-form";

var findAnd = require("find-and");

const useStyles = makeStyles((theme) => ({
  textFieldStyle: {
    width: "30%",
    marginBottom: 5,
    marginTop: 30,
    marginRight: 10,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  buttonStyle: {
    marginTop: 35,
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));


const PLAYERS_QUERY = `query{
  allPlayers {
    playerId
    fName
    lName
	shortName
    birthDate
    height
    weight
    headImage
    position
    foot
    attrPower
    attrSpeed
    attrDefense
    attrAttack
    attrStrategy
    attrTechnical
  }
}`;

const SEARCH_PLAYERS_QUERY = `query($fName: String!,$lName: String!){
	searchPlayers(fName: $fName,lName: $lName) {
	playerId
    fName
    lName
	shortName
    birthDate
    height
    weight
    headImage
    position
    foot
    attrPower
    attrSpeed
    attrDefense
    attrAttack
    attrStrategy
    attrTechnical
  }
}`;

const PLAYERS_SLEAGUE_TEAM_QUERY = `query{
  playerSleagueTeamName {
    playerId
    sleagueTeam
  }
}`;

export default function PlayerList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = React.useState(100);
  const navigate = useNavigate();
  const playersQuery = useQuery(PLAYERS_QUERY);
  const [searchPlayersQuery] = useManualQuery(SEARCH_PLAYERS_QUERY);
  const {
    loading: playerSleagueTeamLoading,
    error: playerSleagueTeamError,
    data: playerSleagueTeamData,
  } = useQuery(PLAYERS_SLEAGUE_TEAM_QUERY);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "headImage",
      headerName: "Head Shot Image",
      width: 120,
      renderCell: rows => (

          rows.row.headImage ? <img
          src={"/lms/media/" + rows.row.headImage} alt="" width="80"  /> : <img
           src={"/lms/media/uploadedFiles/headImage/no-photo.png"} alt="" width="80"  />

      ),
      sortable: false,
      // editable: true,
    },
    {
      field: "teamName",
      headerName: "Current Team",
      width: 300,
    },
    {
      field: "fName",
      headerName: "First Name",
      width: 150,
      renderCell: rows => (
        <a href={`/lms/${admin_url}/player/edit/${rows.row.playerId}`} target="_self">
          {rows.row.fName}
        </a>
      ),
      // editable: true,
    },
    {
      field: "lName",
      headerName: "Last Name",
      width: 150,
      renderCell: rows => (
        <a href={`/lms/${admin_url}/player/edit/${rows.row.playerId}`} target="_self">
          {rows.row.lName}
        </a>
      ),
      // editable: true,
    },
	
	{
      field: "shortName",
      headerName: "Short Name",
      width: 150,
      renderCell: rows => (
        <a href={`/lms/${admin_url}/player/edit/${rows.row.playerId}`} target="_self">
          {rows.row.shortName}
        </a>
      ),
      // editable: true,
    },
	
    {
      field: "height",
      headerName: "Height (cm)",
      // type: "number",
      width: 150,
      // editable: true,
    },
    {
      field: "weight",
      headerName: "Weight (kg)",
      // type: "number",
      width: 150,
      // editable: true,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      width: 150,
      // editable: true,
    },
    {
      field: "position",
      headerName: "Position",
      width: 150,
      // editable: true,
    },
    {
      field: "foot",
      headerName: "Foot",
      width: 120,
      // editable: true,
    },
    {
      field: "attrPower",
      headerName: "Power",
      width: 120,
      // editable: true,
    },
    {
      field: "attrSpeed",
      headerName: "Speed",
      width: 120,
      // editable: true,
    },
    {
      field: "attrDefense",
      headerName: "Defense",
      width: 150,
      // editable: true,
    },
    {
      field: "attrAttack",
      headerName: "Attack",
      width: 120,
      // editable: true,
    },
    {
      field: "attrStrategy",
      headerName: "Strategy",
      width: 150,
      // editable: true,
    },
    {
      field: "attrTechnical",
      headerName: "Technical",
      width: 150,
      // editable: true,
    },
  ];

  useEffect(async () => {
    if (playersQuery.data && playersQuery.data.allPlayers && playerSleagueTeamData) {
      playersQuery.data.allPlayers.map(async player => {
        let resFound = findAnd.returnFound(playerSleagueTeamData.playerSleagueTeamName, {
          playerId: parseInt(player.playerId),
        });
        if (resFound) player.teamName = resFound["sleagueTeam"];
        else player.teamName = "None";
      });
      setRows(playersQuery.data.allPlayers);
    }
  }, [playersQuery.data, playerSleagueTeamData]);
  
  const searchPlayers = async (formData) =>{
    var fName = "";
    var lName = "";
    if(formData.fName)
      fName = formData.fName;
    if(formData.lName)
      lName = formData.lName;
    if(fName != "" || lName != "") {
      let { data: searchPlayersData, error: searchPlayersError } = await searchPlayersQuery({
        variables: {
          fName: fName,
          lName: lName,
        },
        headers: {
              Authorization: "JWT " + token,
        },
      });
      if (playerSleagueTeamData && searchPlayersData){
        await onRefreshToken();
        searchPlayersData.searchPlayers.map(async player => {
          let resFound = findAnd.returnFound(playerSleagueTeamData.playerSleagueTeamName, {
            playerId: parseInt(player.playerId),
          });
          if (resFound) player.teamName = resFound["sleagueTeam"];
          else player.teamName = "None";
        });
        setRows(searchPlayersData.searchPlayers);
      }
    }
    else {
      setRows(playersQuery.data.allPlayers);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Players | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        {!playerSleagueTeamLoading &&
           playerSleagueTeamData &&
           playerSleagueTeamData.playerSleagueTeamName && (
          <>
          <Grid container item direction="column" xs={9} spacing={1}>
            <Grid item>
            <form onSubmit={handleSubmit(searchPlayers)}>
                <TextField
                  className={classes.textFieldStyle}
                  label="First Name"
                  margin="normal"
                  name="fName"
                  type="text"
                  variant="outlined"
                  {...register("fName", {
                      required: false,
                  })}
                />
                <TextField
                  className={classes.textFieldStyle}
                  fullWidth
                  label="Last Name"
                  margin="normal"
                  name="lName"
                  type="text"
                  variant="outlined"
                  {...register("lName", {
                      required: false,
                  })}
                />
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  variant="contained"
                  type="submit" 
                >
                  Search
                </Button>
            </form>
            </Grid>
            <Grid item>
              <h2 className={adminStyle.h2}>Players List</h2>
            </Grid>
            <Grid item>
              <DataGrid
                columns={columns}
                rows={rows}
                rowHeight={80}
                getRowId={row => row.playerId}
                autoHeight={true}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rowsPerPageOptions={[50, 100]}
                pagination
                // checkboxSelection
                // disableSelectionOnClick
              />
            </Grid>
            <Grid item>
              <Button
                className={adminStyle.button}
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={() => navigate(`/lms/${admin_url}/player/add`)}
              >
                + New
              </Button>
            </Grid>
          </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
