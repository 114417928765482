import React, { useState, useEffect,useContext } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./../Layout.js";
import { Grid, TextField, Button,Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./../Admin.module.scss";
import {project_url, admin_url} from "../../store/data/Constants";

import { USER_NOTIFY_SEND_EMAIL } from "../../graphQL/userNotifyEmail_qry"
import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textFieldInput: {
    padding: '15px', 
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Formation
## Description : Submit form data and create new record in formation table
## Author : SF
## Date: : 2022/02/23
##########################################################################*/
export default function NotifyUserEmail() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const classes = useStyles();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  const [open, setOpen] = useState(false);  

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  const [sendUserNotifyEmail] = useMutation(USER_NOTIFY_SEND_EMAIL);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  }; 


  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 7000);

  const handleSend = handleSubmit(async (formData) => {
    await sleep(2000);

    setOpen(false);

    const {data:emailData, error:emailError} = await sendUserNotifyEmail({
      variables: {
        emailAddress: formData.sendTo,
        emailSubject: formData.subject,
        emailContent: formData.textBody
      }
    });
    if(emailError){
      setFailedMessage("Something Failed");
    }else{
      setSuccessMessage("Email Sent!");
      alert("Email Sent!");
    }

  })

  const handleOpen = handleSubmit(async (formData)  => {
    if(formData.sendTo !== "" && 
      formData.subject !== "" && 
      formData.textBody !== "")    setOpen(true);
  });

  const handleClose = () => {
    setOpen(false);
  };
  
  /////////////////////////////////////////////////////////////////////
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Layout />
      <form className={adminStyle.notifyForm}>
        <h2 className={adminStyle.h2}>
          Notify User(s)
        </h2>
        <label className={adminStyle.label} htmlFor="sendTo">
          <b>Send to</b>
        </label>
        <input
          className={adminStyle.input}
          id="sendTo"
          {...register("sendTo", { required: true })}
        />
        {errors.sendTo && errors.sendTo.type === "required" && (
          <span>Required</span>
        )}

        <label className={adminStyle.label} htmlFor="subject">
          <b>Subject</b>
        </label>
        <input
          className={adminStyle.input}
          id="subject"
          {...register("subject", { required: true })}
        />
        {errors.subject && errors.subject.type === "required" && (
          <span>Required</span>
        )}
       
        <label className={adminStyle.label} htmlFor="textBody">
          <b>Body</b>
        </label>
        <TextField
          className={adminStyle.textField}
          id="textBody"
          {...register("textBody", { required: true })}
          placeholder="Write here...."
          multiline
          rows={10}
          maxRows={15}
          variant="standard"
          InputProps={{
            classes: {
               input: classes.textFieldInput
            },
            disableUnderline: true
         }}
        />
        {errors.textBody && errors.textBody.type === "required" && (
          <span>Required</span>
        )}

        <Button style={{  marginLeft: '200px', 
                          marginRight: '30px',
                          marginTop: '20px',
                          marginBottom: '20px'}}
          variant="contained"
          color="primary"
          fullWidth={false}
          onClick={handleOpen}
        >
          {" "}
          Send{" "}
        </Button>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send this email? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSend}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
