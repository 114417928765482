import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "graphql-hooks";
import html2canvas from "html2canvas";
import  jsPDF from 'jspdf';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, TwitterIcon,
         RedditShareButton, RedditIcon, LinkedinShareButton, LinkedinIcon}
         from "react-share";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InstagramLogo from "../../assets/VirtualNetworkImages/Instagram.png";
import TwitterLogo from "../../assets/VirtualNetworkImages/Twitter.png";
import FacebookLogo from "../../assets/VirtualNetworkImages/Facebook.png";
import TiktokLogo from "../../assets/VirtualNetworkImages/Tiktok.png";

import Tooltip from "@material-ui/core/Tooltip";

import { USER_TS_PDF } from "../../graphQL/user_ts_qry";
import {media_url} from "../../store/data/Constants";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  TextField,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import westHamLogo from "../../assets/West_Ham_United.png";
import chelseaLogo from "../../assets/Chelsea.png";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const uploadPostPictureMutation = `
  mutation UploadTSImage($picture: Upload!, $userTSId:Int!) {
    uploadTSImage(tsFile: $picture, userTSId:$userTSId) {
      result
      fileName
      url
    }
  }`;
const uploadTsPDFMutation = `
  mutation UploadTsPDF($pdfFile: Upload!, $userTSId:Int!, $tsShareId:String!) {
    uploadTsPDF(pdfFile: $pdfFile, userTSId:$userTSId, tsShareId:$tsShareId) {
      result
      fileName
    }
  }`;
const Email_TEAM_SELECTION = `
  mutation EmailTS($emailAddress: String!, $userTSId:Int!, $tsShareId:String!) {
    EmailTS(emailAddress: $emailAddress, userTSId:$userTSId, tsShareId:$tsShareId) {
      result
    }
  }`;
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 4,
    width: "110px",
    padding: 0,
    height: "40px",
    textTransform: "none",
    color: "#765285",
    fontSize: 15,
    fontWeight:600
  },
  outlinedButtonStyleMobile: {
    borderRadius: 4,
    width: "85px",
    padding: 0,
    height: "30px",
    textTransform: "none",
    color: "#765285",
    fontSize: 13,
    fontWeight:600
  },
  buttonStyle: {
    borderRadius: 12,
    width: "117px",
    height: "42px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    // "& .MuiFormLabel-asterisk": {
    //   color: "red"
    // },
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleTablet: {
    marginBottom: 5,
    marginTop: 30,
    // "& .MuiFormLabel-asterisk": {
    //   color: "red"
    // },
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    // "& .MuiFormLabel-asterisk": {
    //   color: "red",
    // },
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
  }
`;

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}
    placement='bottom-start'/>
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #757575;
    color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
  }
`;

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;



  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ShareDialog({closeDialog, userTSId, reference}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [disableShareBtn, setDisableShareBtn] = useState(true);
  const [tsShareId, setTsShareId] = useState("no");
  const [requestPDF] = useMutation(USER_TS_PDF);
  const [sendEmail] = useMutation(Email_TEAM_SELECTION);
  const [uploadPostPicture] = useMutation(uploadPostPictureMutation)
  const [uploadTsPDF] = useMutation(uploadTsPDFMutation)
  const mediaPath =  `${media_url}`;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset
  } = useForm({ mode: "onChange" });

  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 963 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog()
  };

  const handlePDFClick = async (e) => {
    if (tsShareId!=="no"){
      const canvas =  await html2canvas(reference.current);
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const fname = "my-team-selection-"+tsShareId+".pdf";
      const pOrl = canvas.height>canvas.width?'portrait':'landscape';
      const scale = mobileView?0.55:tabletView?0.6:1
      const margin = mobileView?100:100;
      const pdf = new jsPDF({
            orientation: pOrl,
            unit:'px',
            format: [(canvas.width+margin)*scale, (canvas.height+margin)*scale],
            compress: true,
          });

      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - margin;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width ;
      pdf.addImage(imgData, 'PNG', margin/2, margin/2, pdfWidth, pdfHeight);
      let p = pdf.output('blob');
      //
      const {loading:pdfLoading, data: pdfData, error: pdfError} =  await uploadTsPDF({
        variables: {
          pdfFile: p,
          userTSId: userTSId,
          tsShareId: tsShareId
        }
      });

      if (!pdfLoading && pdfData){
        if(pdfData.uploadTsPDF.result==='ok'){
          setImageURL( pdfData.uploadTsPDF.fileName);
          setDisableShareBtn(false);
          pdf.save(fname);
        }
      }
    }
  };



  useEffect(() => {
    const pngReady = async () =>{
    const canvas =  await html2canvas(reference.current);
    const image = canvas.toDataURL("image/png", 1.0);
    const fileName = "my-team-selection-"+userTSId+".png";

    const {loading:pngLoading, data: pngData, error: pngError} =   await   uploadPostPicture({
      variables: {
        picture: image,
        userTSId: userTSId
      }
    });
    if (!pngLoading && pngData){
      if(pngData.uploadTSImage.result==='ok'){
        setImageURL( pngData.uploadTSImage.url);
        setDisableShareBtn(false);
        setTsShareId(pngData.uploadTSImage.fileName);
      }
    }
  }
  pngReady();
}, [open]);

  const handleEmailSend = async (e)=> {
    const {loading:emailLoading, data: emailData, error: emailError} =   await sendEmail({
      variables: {
        emailAddress: e['email'],
        userTSId: userTSId,
        tsShareId: tsShareId
      }
    });
    if (!emailLoading && emailData){
      alert("The email is sent successfully");
      reset();
    }
  }

  return (
    <>
      {mobileView && (
        <div>
          <StyledDialog
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { minWidth: 328, maxHeight: 586, borderRadius: 2 } }}
            aria-labelledby="detail-dialog-title"
            aria-describedby="detail-dialog-description"
            open={open}
          >
            <StyledDialogTitle id="detail-dialog-title" onClose={handleClose}>
              <Typography variant="subtitle2" align="center" >
                Share Your Team!
              </Typography>
              <Box align="justify"
                ml={2}
                style={{
                  width: "90%",
                }}>
                <Typography variant="body2" style={{ paddingTop: 15 }}>
                  Share your favourite team's starting lineup with your friends and colleagues and brag about your knowledge!
                </Typography>
              </Box>
              <Box mt={2} mb={1} align="center" style={{ width: "center" }}>
                <StyledTooltip title="Click to view a PDF rendition of your starting lineup">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.outlinedButtonStyleMobile}
                    onClick={handlePDFClick}
                    disabled={disableShareBtn}
                  >
                    View PDF
                  </Button>
                </StyledTooltip>

              </Box>
            </StyledDialogTitle>

            <DialogContent>
              <Box mb={2} mt={0} align="center">
                <Box style={{
                      width: "65%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                >

                  <Box>
                    <img src={TwitterLogo} alt="twitterLogo" height={40} width={40} />
                  </Box>
                  <Box style={{ paddingRight: 2 }}>

                    <img src={FacebookLogo} alt="facebookLogo" height={40} width={40} />
                  </Box>
                  <Box style={{
                        paddingTop: 3,
                        paddingLeft: 2 }}>
                    <img src={InstagramLogo} alt="instagramLogo" height={35} width={35} />
                  </Box>
                  <Box style={{
                        paddingTop: 3,
                        paddingLeft: 6 }}>
                    <img src={TiktokLogo} alt="tiktokLogo" height={33} width={33} />
                  </Box>

                </Box>
              </Box>
              <Divider />
              <Box mb={1}>
                <CustomTooltip title="Enter recipient's email address">
                  <TextField
                    className={classes.textFieldStyleMobile}
                    style={{ marginTop: 25 }}
                    fullWidth
                    label="Email Address *"
                    margin="dense"
                    id="email"
                    type="email"
                    variant="outlined"
                    {...register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      })}
                  />
                </CustomTooltip>
                {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Please enter a valid email address.
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions style={{ display: "block" }}>
              <Box mt={1} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Send
                </Button>
              </Box>
            </DialogActions>
          </StyledDialog>
        </div>
      )}
      {tabletView && (
        <div>
          <StyledDialog
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { minWidth: 696, maxHeight: 550, borderRadius: 2 } }}
            aria-labelledby="detail-dialog-title"
            aria-describedby="detail-dialog-description"
            open={open}
          >
            <StyledDialogTitle id="detail-dialog-title" onClose={handleClose}>
              <Typography variant="subtitle1" align="center" >
                Share Your Team!
              </Typography>
              <Box align="left"
                ml={2}
                style={{
                  width: "95%",
                }}>
                <Typography variant="body1" style={{ paddingTop: 15 }}>
                  Share your favourite team's starting lineup with your friends and colleagues and brag about your knowledge!
                </Typography>
              </Box>
              <Box mt={3} mb={0} align="center" style={{ width: "center" }}>
                <StyledTooltip title="Click to view a PDF rendition of your starting lineup">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.outlinedButtonStyle}
                    onClick={handlePDFClick}
                    disabled={disableShareBtn}
                  >
                    View PDF
                  </Button>
                </StyledTooltip>
              </Box>
            </StyledDialogTitle>

            <DialogContent>
              <Box mb={3} mt={0} align="center">
                <Box style={{
                      width: "35%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                >
                  <Box>
                    <img src={TwitterLogo} alt="twitterLogo" height={40} width={40} />
                  </Box>
                  <Box style={{ paddingRight: 2 }}>
                    <img src={FacebookLogo} alt="facebookLogo" height={40} width={40} />
                  </Box>
                  <Box style={{
                        paddingTop: 3,
                        paddingLeft: 2 }}>
                    <img src={InstagramLogo} alt="instagramLogo" height={35} width={35} />
                  </Box>
                  <Box style={{
                        paddingTop: 3,
                        paddingLeft: 4 }}>
                    <img src={TiktokLogo} alt="tiktokLogo" height={33} width={33} />
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box mb={1}>
                <CustomTooltip title="Enter recipient's email address">
                  <TextField
                    className={classes.textFieldStyleTablet}
                    style={{ marginTop: 25 }}
                    fullWidth
                    label="Email Address *"
                    margin="dense"
                    id="email"
                    type="email"
                    variant="outlined"
                    {...register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      })}
                  />
                </CustomTooltip>
                {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Please enter a valid email address.
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions style={{ display: "block" }}>
              <Box mt={1} mb={3} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Send
                </Button>
              </Box>
            </DialogActions>
          </StyledDialog>
        </div>
      )}
      {!mobileView && !tabletView && (
        <div>
          <StyledDialog
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { minWidth: 564, maxHeight: 536, borderRadius: 2 } }}
            aria-labelledby="detail-dialog-title"
            aria-describedby="detail-dialog-description"
            open={open}
          >
            <StyledDialogTitle id="detail-dialog-title" onClose={handleClose}>
              <Typography variant="subtitle1" align="center" style={{ paddingTop: 15 }}>
                Share Your Team!
              </Typography>
              <Box align="left"
                ml={7}
                mr={4}
                style={{
                  width: "80%",
                }}>
                <Typography variant="body1" style={{ paddingTop: 15 }}>
                  Share your favourite team's starting lineup with your friends and colleagues and brag about your knowledge!
                </Typography>
              </Box>
              <Box mt={3} mb={0} align="center" style={{ width: "center" }}>
                <StyledTooltip title="Click to view a PDF rendition of your starting lineup">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.outlinedButtonStyle}
                    onClick={handlePDFClick}
                    disabled={disableShareBtn}
                  >
                    View PDF
                  </Button>
                </StyledTooltip>

              </Box>
            </StyledDialogTitle>
            <form onSubmit={handleSubmit(handleEmailSend)}>
            <DialogContent>
              <Box mb={3} mt={1} align="center">
                <Box style={{
                      width: "40%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                >
                  <Box>
                    <TwitterShareButton
                    url={imageURL}
                    quote={"This is my team selection. Challenge me!"}
                    disabled={disableShareBtn}>
                      <TwitterIcon logoFillColor="white" size={40} round={true} />

                    </TwitterShareButton >
                  </Box>
                  <Box style={{ paddingRight: 2 }}>
                     <FacebookShareButton
                     url={imageURL}
                     quote={"This is my team selection. Challenge me!"}
                     disabled={disableShareBtn}>
                       <FacebookIcon logoFillColor="white" size={40} round={true} />

                     </FacebookShareButton >

                  </Box>

                  <Box style={{ paddingRight: 2, height:35}}>
                     <RedditShareButton
                     url={imageURL}
                     quote={"This is my team selection. Challenge me!"}
                     disabled={disableShareBtn}>
                       <RedditIcon logoFillColor="white" size={40} round={true} />

                     </RedditShareButton >

                  </Box>
                  <Box style={{ paddingRight: 2, height:35}}>
                     <LinkedinShareButton
                     url={imageURL}
                     quote={"This is my team selection. Challenge me!"}

                     disabled={disableShareBtn}>
                       <LinkedinIcon logoFillColor="white" size={40} round={true} />

                     </LinkedinShareButton >

                  </Box>

                </Box>
              </Box>
              <Divider />

              <Box mb={1}>
                <CustomTooltip title="Enter recipient's email address">
                  <TextField
                    className={classes.textFieldStyle}
                    style={{ marginTop: 25 }}
                    fullWidth
                    label="Email Address *"
                    margin="dense"
                    id="email"
                    type="email"
                    variant="outlined"
                    {...register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      })}
                  />
                </CustomTooltip>
                {errorMsg && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      Please enter a valid email address.
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions style={{ display: "block" }}>
              <Box mt={1} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Send
                </Button>
              </Box>
            </DialogActions>
            </form>
          </StyledDialog>
        </div>
      )}
    </>
  );
}
