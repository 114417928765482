const SendUserNotifyEmail = `
  mutation   sendUserNotifyEmail($emailAddress: String!,
                                  $emailSubject: String!, 
                                  $emailContent:String!) {
    sendUserNotifyEmail (emailAddress:$emailAddress,
                         emailSubject:$emailSubject, 
                         emailContent:$emailContent){
      emailAddress
      emailSubject
      emailContent     
    }

}`;


module.exports = {
  USER_NOTIFY_SEND_EMAIL : SendUserNotifyEmail
};
