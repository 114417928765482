import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Helmet } from "react-helmet";
import { seo_tag_config } from "../store/data/Constants";

import Autocomplete from "@mui/material/Autocomplete";
import logoImg from "../assets/preditcal_logo.png";

import { SUBMIT_INTEREST_INFO } from "../graphQL/crm_qry";
import { CREATE_USER_MUTATION } from "../graphQL/register_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    //maxWidth: 672,
    height: 956,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    // paddingTop: 40,
    minHeight: 400,
    maxWidth: 430,
    //  minWidth: 310,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 956,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 956,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
    //  maxWidth: 700,
  },
  autoComStyleMobile: {
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: 30,
      marginBottom: 5,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  autoComStyle: {
    "& .MuiFormControl-marginNormal": {
      marginTop: 30,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  dialogStyle: {
    borderRadius: 8
  },
  outlinedButtonStyle: {
    border: "1px solid #765285",
    borderRadius: "4px",
    width: "120px",
    padding: 0,
    height: "42px",
    textTransform: "capitalize",
    fontWeight:500,
    fontSize:"15px",
    lineHeight:"26px",
    letterSpacing:"0.46px",
    color:"#765285",

  },


}));

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const InterestedForm = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [submitInfo] = useMutation(SUBMIT_INTEREST_INFO, {
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () =>{
    setOpenDialog(false);
    navigate("/");
  };



  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMsg("");
    setErrorMsg("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async (formData) => {

      const { data, error } = await submitInfo({
        variables: {
          email: formData.email,
          country: formData.country,
          city: formData.city,
          firstName: formData.firstname,
          lastName: formData.lastname,
          favouriteTeam: formData.favteam,
          favouriteLeague: formData.favtournament,
        },

      });

      if (error) {
        console.log("Error", error, data);
        setErrorMsg(error.graphQLErrors[0].message);
        setTimeout("");
      } else {
        setOpenDialog(true);
      }
  };






  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | I am interested</title>
          <meta 
            name="description" 
            content="Predcital interested to join football prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, interested to join, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }
      
      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <Box alignItems="center" mb={3} flexGrow={1}>
              <Box
                flexGrow={1}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <div>
                  <Typography gutterBottom variant="subtitle2">
                    Keep Me Posted
                  </Typography>
                </div>
              </Box>
              <Typography variant="body2">
                This is exciting! Yes, I am definitely interested.
                 I would like to be notified as soon as I can start selecting my Starting XI team for my favourite team in
                 the English Premier League - Season 2023-24 on the Predictal Team Selector platform. <br /><br />
              </Typography>
              <Typography variant="body2">
                Predictal will use your email address only to notify you about the latest updates related to the Predictal web site.
                We shall not use your email address for any other purposes and we shall not share it with any entity outside Predictal.
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Box flexGrow={1} mb={1}>
              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                //autoFocus
                label="Email" // Address"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                id="email"
                  {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                />

                {errors.email && errors.email.type === "required" && (

                  <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    Email address cannot be blank.
                  </FormHelperText>
                </Box>
                )}
                {errors.email && errors.email.type === "pattern" && (

                  <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    Invalid email format.
                  </FormHelperText>
                </Box>
                )}



              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Country of Residense"
                margin="normal"
                name="country"
                variant="outlined"
                id="country"
                  {...register("country", { required: true })}
                />


                {errors.country && errors.country.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Country of Residense cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }

              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="City of Residense"
                margin="normal"
                name="city"
                variant="outlined"
                id="city"
                  {...register("city", { required: true })}
                />


                {errors.city && errors.city.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      City of Residense cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }

              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="First Name"
                margin="normal"
                name="firstname"
                variant="outlined"
                id="firstname"
                  {...register("firstname", { required: true })}
                />


                {errors.firstname && errors.firstname.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      First Name cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }

              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Last Name"
                margin="normal"
                name="lastname"
                variant="outlined"
                id="lastname"
                  {...register("lastname", { required: true })}
                />


                {errors.lastname && errors.lastname.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Last Name cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }

              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Favourite Football Tournament"
                margin="normal"
                name="favtournament"
                variant="outlined"
                id="favtournament"
                  {...register("favtournament", { required: true })}
                />


                {errors.favtournament && errors.favtournament.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Favourite Football Tournament cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }

              <TextField
                required
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Favourite Football Team"
                margin="normal"
                name="favteam"
                variant="outlined"
                id="favteam"
                  {...register("favteam", { required: true })}
                />


                {errors.favteam && errors.favteam.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Favourite Football Team cannot be blank.
                    </FormHelperText>
                  </Box>
                ) }


              {successMsg && (
                <Box mt={2} className={classes.successStyleMobile}>
                  {successMsg}
                </Box>
              )}
              <Box mt={5} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </Box>

            </form>
          </CardContent>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogStyle}
            >
            <DialogTitle id="responsive-dialog-title"
              style={{ fontSize: 24, fontWeight: 600, lineHeight: '32px', display:'flex', alignItems:'center',  color: '#212121', justifyContent:'center' }}>
            Thank You!
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontSize: 16, fontWeight: 400, lineHeight: '32px', color: '#212121', letterSpacing:"0.5px" }}>
                We will notify you when you can start selecting your Starting XI team.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}>
              <Button
                className={classes.outlinedButtonStyle}
                color="primary"
                variant="outlined"
                onClick={handleCloseDialog}>
                  OK
              </Button>
            </DialogActions>
          </Dialog>
        </Card>

      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet}>
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography gutterBottom variant="subtitle1">
                      Keep Me Posted
                    </Typography>
                  </div>
                </Box>
                <Typography variant="body1">
                  This is exciting! Yes, I am definitely interested.
                   I would like to be notified as soon as I can start selecting my Starting XI team for my favourite team in
                   the English Premier League - Season 2023-24 on the Predictal Team Selector platform. <br /><br />
                </Typography>
                <Typography variant="body1">
                  Predictal will use your email address only to notify you about the latest updates related to the Predictal web site.
                  We shall not use your email address for any other purposes and we shall not share it with any entity outside Predictal.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
              <Box flexGrow={1} mb={1}>
                <TextField
                  required
                  className={classes.textFieldStyle}
                  fullWidth
                  //autoFocus
                  label="Email" // Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  id="email"
                  {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                />
                {errors.email && errors.email.type === "required" &&(

                  <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    Email address cannot be blank.
                  </FormHelperText>
                </Box>
                )}
                {errors.email && errors.email.type === "pattern" &&(

                  <Box ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    Invalid email format.
                  </FormHelperText>
                </Box>
                )}

                <TextField
                  required
                  className={classes.textFieldStyle}
                  fullWidth
                  label="Country of Residense"
                  margin="normal"
                  name="country"
                  variant="outlined"
                  id="country"
                    {...register("country", { required: true })}
                  />


                  {errors.country && errors.country.type === "required"  && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        Country of Residense cannot be blank.
                      </FormHelperText>
                    </Box>
                  ) }

                  <TextField
                    required
                    className={classes.textFieldStyle}
                    fullWidth
                    label="City of Residense"
                    margin="normal"
                    name="city"
                    variant="outlined"
                    id="city"
                      {...register("city", { required: true })}
                    />


                    {errors.city && errors.city.type === "required"  && (
                      <Box ml={2}>
                        <FormHelperText className={classes.errorStyleMobile} error>
                          City of Residense cannot be blank.
                        </FormHelperText>
                      </Box>
                    ) }


                    <TextField
                      required
                      className={classes.textFieldStyle}
                      fullWidth
                      label="First Name"
                      margin="normal"
                      name="firstname"
                      variant="outlined"
                      id="firstname"
                        {...register("firstname", { required: true })}
                      />


                      {errors.firstname && errors.firstname.type === "required"  && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyleMobile} error>
                            First Name cannot be blank.
                          </FormHelperText>
                        </Box>
                      ) }

                    <TextField
                      required
                      className={classes.textFieldStyle}
                      fullWidth
                      label="Last Name"
                      margin="normal"
                      name="lastname"
                      variant="outlined"
                      id="lastname"
                        {...register("lastname", { required: true })}
                      />


                      {errors.lastname && errors.lastname.type === "required"  && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyleMobile} error>
                            Last Name cannot be blank.
                          </FormHelperText>
                        </Box>
                      ) }

                    <TextField
                      required
                      className={classes.textFieldStyle}
                      fullWidth
                      label="Favourite Football Tournament"
                      margin="normal"
                      name="favtournament"
                      variant="outlined"
                      id="favtournament"
                        {...register("favtournament", { required: true })}
                      />


                      {errors.favtournament && errors.favtournament.type === "required"  && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyleMobile} error>
                            Favourite Football Tournament cannot be blank.
                          </FormHelperText>
                        </Box>
                      ) }

                    <TextField
                      required
                      className={classes.textFieldStyle}
                      fullWidth
                      label="Favourite Football Team"
                      margin="normal"
                      name="favteam"
                      variant="outlined"
                      id="favteam"
                        {...register("favteam", { required: true })}
                      />


                      {errors.favteam && errors.favteam.type === "required"  && (
                        <Box ml={2}>
                          <FormHelperText className={classes.errorStyleMobile} error>
                            Favourite Football Team cannot be blank.
                          </FormHelperText>
                        </Box>
                      ) }

                {successMsg && (
                  <Box mt={2} className={classes.successStyle}>
                    {successMsg}
                  </Box>
                )}
                <Box mt={5} display="flex" justifyContent="center">
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
              </form>
            </CardContent>
          </Card>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogStyle}
            >
            <DialogTitle id="responsive-dialog-title"
              style={{ fontSize: 32, fontWeight: 600, lineHeight: '40px', display:'flex', alignItems:'center',  color: '#212121', justifyContent:'center' }}>
            Thank You!
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontSize: 18, fontWeight: 400, lineHeight: '32px', color: '#212121', letterSpacing:"0.5px" }}>
                We will notify you when you can start selecting your Starting XI team.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}>
              <Button
                className={classes.outlinedButtonStyle}
                color="primary"
                variant="outlined"
                onClick={handleCloseDialog}>
                  OK
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box mb={2}>
                  <img src={logoImg} alt="logo" />
                </Box>
                <Typography variant="body1">
                  This is exciting! Yes, I am definitely interested.
                   I would like to be notified as soon as I can start selecting my Starting XI team for my favourite team in
                   the English Premier League - Season 2023-24 on the Predictal Team Selector platform. <br /><br />
                </Typography>
                <Typography variant="body1">
                Predictal will use your email address only to notify you about the latest updates related to the Predictal web site.
                 We shall not use your email address for any other purposes and we shall not share it with any entity outside Predictal.
                </Typography>
              </Box>
            </CardContent>

            <Divider orientation="vertical" flexItem />
            <CardContent
              className={classes.cardContent}
              style={{ minWidth: 350 }}
            >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                flexGrow={1}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ marginBottom: 0 }}
                >
                  Keep Me Posted
                </Typography>
              </Box>

              <Box flexGrow={1} mb={1}>
              <TextField
                required
                className={classes.textFieldStyle}
                fullWidth
                  //autoFocus
                label="Email" // Address"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                id="email"
                {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" && (

                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Email address cannot be blank.
                    </FormHelperText>
                  </Box>
                 )}
                {errors.email && errors.email.type === "pattern" && (

                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Invalid email format.
                    </FormHelperText>
                  </Box>
                  )}


              <TextField
                required
                className={classes.textFieldStyle}
                fullWidth
                label="Country of Residense"
                margin="normal"
                name="country"
                variant="outlined"
                id="country"
                  {...register("country", { required: true })}
                />

                {errors.country && errors.country.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Country of Residense cannot be blank.
                    </FormHelperText>
                  </Box>
                  )}

              <TextField
                  required
                  className={classes.textFieldStyle}
                  fullWidth
                  label="City of Residense"
                  margin="normal"
                  name="city"
                  variant="outlined"
                  id="city"
                  {...register("city", { required: true })}
                  />


                  {errors.city && errors.city.type === "required"  && (
                    <Box ml={2}>
                      <FormHelperText className={classes.errorStyleMobile} error>
                        City of Residense cannot be blank.
                      </FormHelperText>
                    </Box>
                    )}

              <TextField
                 required
                 className={classes.textFieldStyle}
                 fullWidth
                 label="First Name"
                 margin="normal"
                 name="firstname"
                 variant="outlined"
                 id="firstname"
                 {...register("firstname", { required: true })}
                />


                {errors.firstname && errors.firstname.type === "required"  && (
                   <Box ml={2}>
                     <FormHelperText className={classes.errorStyleMobile} error>
                        First Name cannot be blank.
                    </FormHelperText>
                  </Box>
                  )}
              <TextField
                required
                className={classes.textFieldStyle}
                fullWidth
                label="Last Name"
                margin="normal"
                name="lastname"
                variant="outlined"
                id="lastname"
                {...register("lastname", { required: true })}
                />


                {errors.lastname && errors.lastname.type === "required"  && (
                  <Box ml={2}>
                     <FormHelperText className={classes.errorStyleMobile} error>
                        Last Name cannot be blank.
                     </FormHelperText>
                  </Box>
                 )}

              <TextField
                required
                className={classes.textFieldStyle}
                fullWidth
                label="Favourite Football Tournament"
                margin="normal"
                name="favtournament"
                variant="outlined"
                id="favtournament"
                {...register("favtournament", { required: true })}
                />
                {errors.favtournament && errors.favtournament.type === "required"  && (
                  <Box ml={2}>
                     <FormHelperText className={classes.errorStyleMobile} error>
                        Favourite Football Tournament cannot be blank.
                     </FormHelperText>
                  </Box>
                  )}

              <TextField
                required
                className={classes.textFieldStyle}
                fullWidth
                label="Favourite Football Team"
                margin="normal"
                name="favteam"
                variant="outlined"
                id="favteam"
                {...register("favteam", { required: true })}
                />
                {errors.favteam && errors.favteam.type === "required"  && (
                  <Box ml={2}>
                    <FormHelperText className={classes.errorStyleMobile} error>
                      Favourite Football Team cannot be blank.
                    </FormHelperText>
                  </Box>
                  )}


              {successMsg && (
                <Box mt={2} className={classes.successStyle}>
                  {successMsg}
                </Box>
              )}

              <Box mt={5} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                  >
                    Submit
                </Button>
              </Box>
            </Box>
            </form>
            </CardContent>
          </Card>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
            className={classes.dialogStyle}
            >
            <DialogTitle id="responsive-dialog-title"
              style={{ fontSize: 32, fontWeight: 600, lineHeight: '40px', display:'flex', alignItems:'center',  color: '#212121', justifyContent:'center' }}>
            Thank You!
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{ fontSize: 18, fontWeight: 400, lineHeight: '32px', color: '#212121', letterSpacing:"0.5px" }}>
                We will notify you when you can start selecting your Starting XI team.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}>
              <Button
                className={classes.outlinedButtonStyle}
                color="primary"
                variant="outlined"
                onClick={handleCloseDialog}>
                  OK
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
};

export default InterestedForm;
