import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
}));

export default function AlertDialog({ title, contentMsg, closeDialog }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    closeDialog && closeDialog();
  };

  return (
    <div style={{ width: "90%" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box mt={1} ml={1}>
            {title}
          </Box>
          {closeDialog ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                align: "right",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Box mb={2}>
            <Button
              variant="text"
              onClick={handleClose}
              color="primary"
              className={classes.outlinedButtonStyle}
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// const handleClickOpen = () => {
//   setOpen(true);
// };

// <Button variant="outlined" onClick={handleClickOpen}>
//   Open alert dialog
// </Button>
