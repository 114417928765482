const CreateFixture_mutate = `
    mutation createFixture(
	         $fixtureDate : String!,
			     $weekId: Int!,
          )
				{
					createFixture(
						fixtureDate : $fixtureDate,
						weekId: $weekId,
            )
						{
							fixture
							{
							  fixtureId
                scheduledWeek{
                  playedWeek
                  scheduledDate
                  weekId {
                    weekId
                    name
                  }
                }
                fixtureWeek{
                  playedWeek
                  scheduledDate
                  weekId {
                    weekId
                    name
                    sleagueId {
                      sleagueId
                    }
                  }
                }

							}
						}
				  }
`;

const CreateFixtureTeam_mutate = `
	mutation createFixtureTeam(

	         $fixtureId	: Int!,
             $sleagueTeamId : Int!,
             $scoreHalf1 : String!,
			 $scoreFinal : String!,
			 $home : Boolean!)

			 {
				 createFixtureTeam(
					fixtureId : $fixtureId,
					sleagueTeamId : $sleagueTeamId,
					scoreHalf1 : $scoreHalf1,
					scoreFinal : $scoreFinal,
					home : $home)
				 {
					fixtureTeam
						{
							fixtureId
								{
									fixtureId
                  scheduledWeek{
                    playedWeek
                    scheduledDate
                    weekId {
                      weekId
                      name
                    }
                  }
                  fixtureWeek {
                    playedWeek
                    scheduledDate
									weekId
									 {
										weekId
										sleagueId
										 {
											sleagueId
											seasonName
											leagueId
											 {
												leagueId
												title
											  }

										  }
										name
									  }

                  }

								}

							sleagueTeamId
							{
								sleagueId
								{
									sleagueId
									title
								}
								teamId
								{
									teamId
									name
								}

							}

							scoreHalf1
							scoreFinal
							home

						}

  					}
				}



`;

const EditFixture_mutate = `
    mutation editFixture(
	        $fixtureId : Int!,
	         $fixtureDate : String!,
			     $weekId: Int!,
           $spExtra: String,
           $fixtureScoreUpdatedScreen: Boolean,
          )
				{
					editFixture(
					    fixtureId : $fixtureId,
						fixtureDate : $fixtureDate,
						weekId: $weekId,
            spExtra: $spExtra,
            fixtureScoreUpdatedScreen: $fixtureScoreUpdatedScreen,
						)
						{
							fixture
							{
							  fixtureId
                spExtra
							  scheduledWeek
							  {
								playedWeek
								scheduledDate
								weekId {
										 weekId
										 name
									   }
							  }

							}
						}
				}
`;

const LockFixture_mutate = `
    mutation lockFixture(
	        $fixtureId : Int!,
	         $lock : Boolean!
          )
				{
					lockFixture(
					    fixtureId : $fixtureId,
						lock : $lock
						)
						{
							fixture
							{
							  fixtureId
							  lock

							}
						}
				}
`;

const EditFixtureTeam_mutate = `
	mutation editFixtureTeam(

	     $fixtureTeamId : Int!,
       $sleagueTeamId : Int!,
       $scoreHalf1 : String!,
			 $scoreFinal : String!,
			 $home : Boolean!,
       $oldScoreFinalAnotherTeam : String!,
       $newScoreFinalAnotherTeam : String!,
       $played: Boolean!)

			 {
				editFixtureTeam(
					fixtureTeamId : $fixtureTeamId,
					sleagueTeamId : $sleagueTeamId,
					scoreHalf1 : $scoreHalf1,
					scoreFinal : $scoreFinal,
					home : $home,
          oldScoreFinalAnotherTeam: $oldScoreFinalAnotherTeam
          newScoreFinalAnotherTeam: $newScoreFinalAnotherTeam,
          played:$played)
				 {
					fixtureTeam
						{
							fixtureId
								{
									fixtureId
									scheduledWeek{
													playedWeek
													scheduledDate
													weekId {
																weekId
																name
															}
												}

								}

							sleagueTeamId
							{
								sleagueId
								{
									sleagueId
									title
								}
								teamId
								{
									teamId
									name
								}

							}

							scoreHalf1
							scoreFinal
							home

						}

  					}
				}



`;

const GetAllFixtureTeams_query = `
    query{
      fixtureTeam{
        fixtureTeamId
        fixtureId{
          fixtureId
          fixtureWeek {
            playedWeek
            scheduledDate
          }
          scheduledWeek{
            playedWeek
            scheduledDate
            weekId {
              weekId
              name
            }
          }
        }
        coachSeq
        communitySeq
        sleagueTeamId{
          sleagueTeamId
          teamId{
            teamId
            name
            shortName
          }
        }
      }
    }
`;

const GetSleagueFixtureTeams = `
  query($sleagueId: Int!){
    sleagueFixtureTeams (sleagueId: $sleagueId){
      fixtureTeamId
      fixtureId {
        fixtureId
        fixtureWeek {
          playedWeek
          scheduledDate
          weekId {
            name
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }

      }
      home
      calcComSeqDate
      scoreFinal
      sleagueTeamId {
        sleagueTeamId
        teamId {
          name
          shortName
          logo
        }
      }
    }
  }
`;
const GetWeekFixtureTeams = `
  query($weekId: Int!){
    weekFixtureTeams (weekId: $weekId){
      fixtureTeamId
      calcSPUsersDate
      calcComSeqDate
      coachSeq
      communitySeq
      resultEmailsCounts
      resultEmailsSentDate
      totalVotes
      fixtureId {
        fixtureId
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }

        }

        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
		embargoDate
		utcDate
		lock
      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        totalFans
        teamId {
          name
          shortName
          logo
        }
      }
    }
  }
`;

const GetTeamFixtureTeams = `
  query($sleagueTeamId: Int!){
    fixtureTeamTeams (sleagueTeamId: $sleagueTeamId){
      fixtureTeamId

      fixtureId {
        fixtureId
        lock
        delayed
        rescheduled
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        teamId {
          name
          shortName
          logo
        }
      }
    }
  }
`;

const GetTeamFixtureWeek = `
  query($sleagueTeamId: Int!,
         $weekId: Int!){
    teamFixtureWeek (sleagueTeamId: $sleagueTeamId,
                     weekId: $weekId){
      fixtureTeamId

      fixtureId {
        fixtureId
        lock
        delayed
        rescheduled
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        teamId {
          name
        }
      }
    }
  }
`;

const GetSelectedTeamFixtures = `
  query($sleagueTeamId: Int!){
    selectedTeamFixtures (sleagueTeamId: $sleagueTeamId){
      fixtureTeamId
      userSeq
      coachSeq
      communitySeq
      userFormation
      coachFormation
      fixtureId {
        fixtureId
        lock
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        sleagueId{
          sleagueId
        }
        teamId {
          name
          shortName
          logo
        }
      }

	  votsFormations
    {
      totalVots
      popularFormation
    }

    }
  }
`;
const GetSelectedTeamPastFixtures = `
  query($sleagueTeamId: Int!){
    selectedTeamPastFixtures (sleagueTeamId: $sleagueTeamId){
      fixtureTeamId
      userSeq
      coachSeq
      communitySeq
      userFormation
      coachFormation
      fixtureId {
        fixtureId
        lock
        delayed
        rescheduled
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        sleagueId{
          sleagueId
        }
        teamId {
          name
          shortName
          logo
        }
      }

	  votsFormations
    {
      totalVots
      popularFormation
    }

    }
  }
`;

const GetFixtureObject = `
  query($fixtureId: Int!){
    fixtureObject (fixtureId: $fixtureId){

        fixtureId
        spExtra
        fixtureScoreUpdatedScreen
		    rescheduled
        delayed
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId
			  seasonName
			  leagueId
			  {
				  leagueId
				  title
			  }
            }
          }
        }
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId
			  seasonName
			  leagueId
			  {
				  title
			  }
            }
          }
        }
      }
  }
`;

const GetFixtureObjectTeams = `
  query($fixtureId: Int!){
    fixtureObjectTeams (fixtureId: $fixtureId){
      fixtureTeamId

      fixtureId {
        fixtureId
        delayed
        rescheduled
        fixtureScoreUpdatedScreen
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId


            }
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId

            }
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        noGames
        noWins
        noDraws
        noLosses
        noGoalsFor
        noGoalsAgainst
        noGoalDifference
        noPoints
        sleagueId {
          sleagueId
          title
          seasonName
          leagueId{
            title
            subtitle
          }
        }
        teamId {
          name
          shortName
          logo
        }
      }
      }
  }
`;

const GetFixtureTeam = `
  query($fixtureTeamId: Int!){
    fixtureTeamById (fixtureTeamId: $fixtureTeamId){
      fixtureTeamId
      communitySeqList
      communitySeq
	  coachSeq
      formationPositionsList
      fixtureId{
        fixtureId
      }
	  sleagueTeamId{
		sleagueTeamId
		sleagueId{
		  sleagueId
		  leagueId{
		    leagueId
	      }
		}
	  }
    }
  }
`;

const GetFixtureTeamFixtureIdSleagueTeamId_query = `
  query($fixtureId: Int!,$sleagueTeamId: Int!){
    fixtureTeamByFixtureidSleagueteamid (fixtureId: $fixtureId,
                                          sleagueTeamId: $sleagueTeamId){
      fixtureTeamId
      communitySeqList
      communitySeq
    coachSeq
      formationPositionsList
      fixtureId{
        fixtureId
      }
    sleagueTeamId{
    sleagueTeamId
    sleagueId{
      sleagueId
      leagueId{
        leagueId
        }
    }
    }
    }
  }
`;

const RescheduleFixture_mutate = `
    mutation rescheduleFixture(
	         $fixtureDate : String!,
			     $weekId: Int!,
           $fixtureId:Int!,
            $playedUnknown: Boolean!)
				{
					rescheduleFixture(
						fixtureDate : $fixtureDate,
						weekId: $weekId,
            fixtureId: $fixtureId,
            playedUnknown: $playedUnknown)
						{
							fixture
							{
							  fixtureId
                scheduledWeek{
                  playedWeek
                  scheduledDate
                  weekId {
                    weekId
                    name
                  }
                }
                fixtureWeek{
                  playedWeek
                  scheduledDate
                  weekId {
                    weekId
                    name
                    sleagueId {
                      sleagueId
                    }
                  }
                }
						}
				  }
        }
`;

const GetFavTeamsFixtures = `
  query($userFavTeamSeq: String!){
    favTeamsFixturesObjectTeams (userFavTeamSeq: $userFavTeamSeq){
      fixtureTeamId

      fixtureId {
        fixtureId
        delayed
        rescheduled
        lock
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId


            }
          }
        }
        scheduledWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
            sleagueId {
              sleagueId

            }
          }
        }

      }
      home
      scoreFinal
      scoreHalf1
      sleagueTeamId {
        sleagueTeamId
        sleagueId {
          sleagueId
          title
          seasonName
          leagueId{
            title
          }
        }
        teamId {
          name
          shortName
          logo
        }
      }
      }
  }
`;

const GetLastFixture = `
  query{
    lastFixture{
        fixtureTeamId
        fixtureId{
          fixtureId
          fixtureWeek {
            scheduledDate
            weekId{
      			  weekId
      			  name
      			}
          }
        }
        communitySeq
        coachSeq
        sleagueTeamId{
		  sleagueTeamId
          teamId{
		    name
        shortName
        logo
			venueName
	      }
	    }
        home
		totalVotes
		scoreHalf1
		scoreFinal
      }
  }
`;

const GetUpcomingFixture = `
  query{
    upcomingFixture{
        fixtureTeamId
        fixtureId{
          fixtureId
          delayed
          rescheduled
          fixtureWeek {
		    fixtureWeekId
			fixtureId{
			  fixtureId
			  lock
			  embargoDate
			  rescheduled
			}
			weekId{
			  weekId
			  name
			}
            scheduledDate
          }
        }
        sleagueTeamId{
		  sleagueTeamId
          teamId{
		    name
        shortName
        logo
			venueName
	      }
	    }
        home
      }
  }
`;

const GetCoachSeqLatestFixture = `
  query{
    coachSeqLatestFixture{
        fixtureTeamId
        fixtureId{
          fixtureId
          fixtureWeek {
            scheduledDate
            weekId{
      			  weekId
      			  name
      			}
          }
        }
        communitySeq
        coachSeq
        sleagueTeamId{
		  sleagueTeamId
          teamId{
		    name
        shortName
        logo
			venueName
	      }
	    }
        home
		totalVotes
		scoreHalf1
		scoreFinal
      }
  }
`;

const GetCoachSeqLatestFixtureBySleagueTeamId = `
  query($sleagueTeamId: Int!){
    coachSeqLatestFixtureBySleagueTeamId (sleagueTeamId: $sleagueTeamId){
        fixtureTeamId
        fixtureId{
          fixtureId
          fixtureWeek {
            scheduledDate
            weekId{
      			  weekId
      			  name
      			}
          }
        }
        communitySeq
        coachSeq
        sleagueTeamId{
		  sleagueTeamId
          teamId{
		    name
        shortName
        logo
			venueName
	      }
	    }
        home
		totalVotes
		scoreHalf1
		scoreFinal
      }
  }
`;

module.exports = {
  CREATE_FIXTURE_MUTATION: CreateFixture_mutate,
  CREATE_FIXTURE_TEAM_MUTATION: CreateFixtureTeam_mutate,
  EDIT_FIXTURE_MUTATION: EditFixture_mutate,
  LOCK_FIXTURE_MUTATION: LockFixture_mutate,
  EDIT_FIXTURE_TEAM_MUTATION: EditFixtureTeam_mutate,
  GET_ALL_FIXTURETEAMS_QUERY: GetAllFixtureTeams_query,
  GET_FIXTURETEAM_TEAMS: GetTeamFixtureTeams,
  GET_TEAM_FIXTURE_WEEK: GetTeamFixtureWeek,
  GET_SLEAGUE_FIXTURE_TEAMS: GetSleagueFixtureTeams,
  GET_WEEK_FIXTURE_TEAMS: GetWeekFixtureTeams,
  GET_FIXTURE_OBJECT: GetFixtureObject,
  GET_FIXTURE_OBJECT_TEAMS: GetFixtureObjectTeams,
  RESCHEDULE_FIXTURE: RescheduleFixture_mutate,
  GET_FIXTURETEAM_BY_ID: GetFixtureTeam,
  GET_FIXTURETEAM_BY_FIXTUREID_SLEAGUETEAMID: GetFixtureTeamFixtureIdSleagueTeamId_query,
  FAV_TEAMS_FIXTURES_QUERY: GetFavTeamsFixtures,
  GET_UPCOMING_FIXTURE_QUERY: GetUpcomingFixture,
  GET_LAST_FIXTURE_QUERY: GetLastFixture,
  GET_COACH_SEQ_LATEST_FIXTURE_QUERY: GetCoachSeqLatestFixture,
  GET_COACH_SEQ_LATEST_FIXTURE_BY_SLEAGUE_TEAM_ID_QUERY: GetCoachSeqLatestFixtureBySleagueTeamId,
  GET_SELECTED_TEAM_FIXTURES: GetSelectedTeamFixtures,
  GET_SELECTED_TEAM_PAST_FIXTURES: GetSelectedTeamPastFixtures,
};
