import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url} from "../store/data/Constants";
// import "../styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";

const ADD_PLAYER_MUTATION = `
mutation addPlayer($fName: String!, $lName: String!, $height: Int!,  $weight: Int!,
                  $foot: String!, $attrPower: String!, $attrSpeed: String!, $attrDefense: String!,
                  $attrAttack: String!, $attrStrategy: String!, $attrTechnical: String!,
                  $birthDate: String!, $headImage:Upload, $position: String!,,$shortName: String,
) {
  addPlayer(
    fName: $fName,
    lName: $lName,
    height: $height,
    weight: $weight,
    birthDate: $birthDate,
    foot: $foot,
    attrPower: $attrPower,
    attrSpeed: $attrSpeed,
    attrDefense: $attrDefense,
    attrAttack: $attrAttack,
    attrStrategy: $attrStrategy,
    attrTechnical: $attrTechnical,
    headImage: $headImage,
    position: $position,
	shortName: $shortName,
  ) {
    player{
      playerId
      fName
    }
  }
}
`;

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default function Player() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [addPlayer, { loading, error }] = useMutation(ADD_PLAYER_MUTATION);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);
  
  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])
  
  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };

  const onSubmit = async formData => {
    // await sleep(2000);
    // console.log("DATA-----------", formData);

    let bDay = formData.bdate.getDate().toString();
    if (bDay.length === 1) bDay = "0" + bDay;
    let bMonth = formData.bdate.getMonth() + 1;
    if (bMonth.toString().length === 1) bMonth = "0" + bMonth.toString();
    let birthday = `${formData.bdate.getFullYear().toString()}-${bMonth}-${bDay}`;

    let height = parseInt(formData.height);
    let weight = parseInt(formData.weight);

    const { data, error } = await addPlayer({
      variables: {
        fName: formData.fname,
        lName: formData.lname,
		shortName: formData.shortname,
        height,
        weight,
        birthDate: birthday,
        foot: formData.foot,
        attrPower: formData.power,
        attrSpeed: formData.speed,
        attrDefense: formData.defense,
        attrAttack: formData.attack,
        attrStrategy: formData.strategy,
        attrTechnical: formData.technical,
        headImage: formData.headimage,
        position: formData.position,
      },
    });
    if (error) {
      console.log("Error", error, data);
      alert("There is an error");
    } else {
      console.log("Success");
      alert("Player has been added successfully.");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/player`);
      }, 1000);
    }
  };

  

  return (
    <>
      <Helmet>
        <title>Predictal | Add Player | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Add a record to "Player" table</h2>
          <div>
            <label className={adminStyle.label} htmlFor="fname">
              First Name
            </label>
            <input
              className={adminStyle.input}
              id="fname"
              {...register("fname", { required: true })}
            />
            {errors.fname && errors.fname.type === "required" && <span>Required</span>}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="lname">
              Last Name
            </label>
            <input
              className={adminStyle.input}
              id="lname"
              {...register("lname", { required: true })}
            />
            {errors.lname && errors.lname.type === "required" && <span>Required</span>}
          </div>
  		
  		
          <div>
            <label className={adminStyle.label} htmlFor="shortname">
              Short Name
            </label>
            <input
              className={adminStyle.input}
              id="shortname"
              {...register("shortname", { required: false })}
            />
            
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="height">
              Height (cm)
            </label>
            <input
              className={adminStyle.input}
              type="number"
              min="1"
              {...register("height", {
                required: true,
                pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i,
              })}
            />
            {errors.height && errors.height.type === "required" && <span>Required</span>}
            {errors.height && errors.height.type === "pattern" && (
              <span>Please enter a valid number</span>
            )}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="weight">
              Weight (kg)
            </label>
            <input
              className={adminStyle.input}
              type="number"
              min="1"
              {...register("weight", {
                required: true,
                pattern: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/i,
              })}
            />
            {errors.weight && errors.weight.type === "required" && <span>Required</span>}
            {errors.weight && errors.weight.type === "pattern" && (
              <span>Please enter a valid number</span>
            )}
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="bdate">
              Birth Date
            </label>
            <Controller
              control={control}
              name="bdate"
              id="bdate"
              render={({ field }) => (
                <DatePicker
                  className={adminStyle.input}
                  placeholderText="Select date"
                  required
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                />
              )}
            />
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="headimage">
              Head shot image
            </label>
            <input className={adminStyle.input} {...register("headimage")} type="file" />
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="position">
              Position
            </label>
            <select className={adminStyle.select} id="position" {...register("position")}>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Defender">Defender</option>
              <option value="Midfielder">Midfielder</option>
              <option value="Forward">Forward</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="foot">
              Foot
            </label>
            <select className={adminStyle.select} id="foot" {...register("foot")}>
              <option value="L">Left</option>
              <option value="R" selected="selected">Right</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="power">
              Power
            </label>
            <select className={adminStyle.select} id="power" {...register("power")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3" selected="selected">3</option>
            </select>
          </div>
          <div>
            <label className={adminStyle.label} htmlFor="speed">
              Speed
            </label>
            <select className={adminStyle.select} id="speed" {...register("speed")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3" selected="selected">3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="defense">
              Defense
            </label>
            <select className={adminStyle.select} id="degense" {...register("defense")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3" selected="selected">3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="attack">
              Attack
            </label>
            <select className={adminStyle.select} id="attack" {...register("attack")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="strategy">
              Strategy
            </label>
            <select className={adminStyle.select} id="strategy" {...register("strategy")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3" selected="selected">3</option>
            </select>
          </div>

          <div>
            <label className={adminStyle.label} htmlFor="technical">
              Technical
            </label>
            <select className={adminStyle.select} id="technical" {...register("technical")}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3" selected="selected">3</option>
            </select>
          </div>

          <input className={adminStyle.input} type="submit" />
        </form>
      </Grid>
    </>
  );
}
