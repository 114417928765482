import React from "react";
import { Grid } from "@mui/material";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import booked from "../../../../../assets/StatusImages/booked.gif";
import suspended from "../../../../../assets/StatusImages/suspended.gif";
import injured from "../../../../../assets/StatusImages/injured.png";
import unavailable from "../../../../../assets/StatusImages/unavailable.png";
import available from "../../../../../assets/StatusImages/available.png";
import selected from "../../../../../assets/StatusImages/selected.png";
import sick from "../../../../../assets/StatusImages/sick.png";

const useStyles = makeStyles({
  root: {
    fontSize: 20,
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FBF3FF",
    },
  },
});

const Positions = ({ players, head2headData }) => {
  console.log("players---------head", players);

  const mainPath = "/lms/media/";

  const classes = useStyles();

  let leftPlayer = players.find(
    item => item.playerSeqNO === head2headData[0].lastH2HDroppedItem.playerSeqNO
  );

  let rightPlayer = players.find(
    item => item.playerSeqNO === head2headData[1].lastH2HDroppedItem.playerSeqNO
  );

  // Player counts list
  const positions = [
    {
      name: "GK",
      lCount: leftPlayer.counts.gkCount,
      rCount: rightPlayer.counts.gkCount,
    },
    {
      name: "LDEF",
      lCount: leftPlayer.counts.ldefCount,
      rCount: rightPlayer.counts.ldefCount,
    },
    {
      name: "CDEF",
      lCount: leftPlayer.counts.cdefCount,
      rCount: rightPlayer.counts.cdefCount,
    },
    {
      name: "RDEF",
      lCount: leftPlayer.counts.rdefCount,
      rCount: rightPlayer.counts.rdefCount,
    },
    {
      name: "LMF",
      lCount: leftPlayer.counts.lmfCount,
      rCount: rightPlayer.counts.lmfCount,
    },
    {
      name: "CMF",
      lCount: leftPlayer.counts.cmfCount,
      rCount: rightPlayer.counts.cmfCount,
    },
    {
      name: "RMF",
      lCount: leftPlayer.counts.rmfCount,
      rCount: rightPlayer.counts.rmfCount,
    },
    {
      name: "LFOR",
      lCount: leftPlayer.counts.lforCount,
      rCount: rightPlayer.counts.lforCount,
    },
    {
      name: "CFOR",
      lCount: leftPlayer.counts.cforCount,
      rCount: rightPlayer.counts.cforCount,
    },
    {
      name: "RFOR",
      lCount: leftPlayer.counts.rforCount,
      rCount: rightPlayer.counts.rforCount,
    },
  ];
  const columns = [
    {
      field: "leftPlayerCount",
      width: 110,
      align: "center",
      renderCell: params => {
        return <Typography variant="h3">{params.value}</Typography>;
      },
    },
    {
      field: "position",
      width: 75,
      align: "center",
      renderCell: params => {
        return <Typography variant="h2">{params.value}</Typography>;
      },
    },
    {
      field: "rightPlayerCount",
      width: 110,
      align: "center",
      renderCell: params => {
        return <Typography variant="h3">{params.value}</Typography>;
      },
    },
  ];

  const rows = positions.map((pos, id) => ({
    id: id,
    leftPlayerCount: pos.lCount,
    position: pos.name,
    rightPlayerCount: pos.rCount,
  }));

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3} sm={3} md={3}>
          <Box>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 64, height: 64 }}
                src={
                  leftPlayer.playerId.headImage
                    ? mainPath + leftPlayer.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Typography variant="body1" style={{ textAlign: "center", color: "#212121" }}>
              {leftPlayer.jerseyNO} {leftPlayer.playerId.name}
            </Typography>
            <Box mt={2}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {leftPlayer.playerId.position}
              </Typography>
            </Box>
            <Box mt={1} style={{ textAlign: "center" }}>
              {leftPlayer.status === "Unavailable" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={unavailable}
                />
              )}
              {leftPlayer.status === "Fit" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={available}
                />
              )}
              {leftPlayer.status === "Booked" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={booked}
                />
              )}
              {leftPlayer.status === "Suspended" && (
                <img
                  style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                  src={suspended}
                />
              )}
              {leftPlayer.status === "Injured" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={injured}
                />
              )}
              {leftPlayer.status === "Sick" && (
                <img
                  style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                  src={sick}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <div
            className={classes.root}
            style={{
              marginRight: "20px",
              marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              rowHeight={48}
              headerHeight={0}
            />
          </div>
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Box>
            <Box mt={3} style={{ textAlign: "center" }}>
              <img
                style={{ width: 64, height: 64 }}
                src={
                  rightPlayer.playerId.headImage
                    ? mainPath + rightPlayer.playerId.headImage
                    : mainPath + "uploadedFiles/headImage/no-photo.png"
                }
              />
            </Box>
            <Typography variant="body1" style={{ textAlign: "center", color: "#212121" }}>
              {rightPlayer.jerseyNO} {rightPlayer.playerId.name}
            </Typography>

            <Box mt={2}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#212121",
                }}
              >
                {rightPlayer.playerId.position}
              </Typography>
              <Box mt={1} style={{ textAlign: "center" }}>
                {rightPlayer.status === "Unavailable" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={unavailable}
                  />
                )}
                {rightPlayer.status === "Fit" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={available}
                  />
                )}
                {rightPlayer.status === "Booked" && (
                  <img
                    style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                    src={booked}
                  />
                )}
                {rightPlayer.status === "Suspended" && (
                  <img
                    style={{ paddingLeft: 7, height: 20, marginBottom: -5 }}
                    src={suspended}
                  />
                )}
                {rightPlayer.status === "Injured" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={injured}
                  />
                )}
                {rightPlayer.status === "Sick" && (
                  <img
                    style={{ paddingLeft: 5, height: 20, marginBottom: -5 }}
                    src={sick}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Positions;
