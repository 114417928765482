import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { DataGrid } from "@material-ui/data-grid";

import {
  Button,
  Grid,
  Box,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Chip,
  Select,
  ListItemText,
  Checkbox,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../graphQL/formationToLeague_qry";
import { GET_FIXTURETEAM_TEAMS } from "../graphQL/fixture_qry";
import { project_url, admin_url } from "../store/data/Constants";

// const ALL_USERS_QUERY = `query{
//   allUsers{
//     id
//     username
//   }
// }`;

const ALL_LEAGUES_QUERY = `query{
  league{
    leagueId
    title
    subtitle
  }
}`;

const SLEAGUE_QUERY = `query sleague($leagueId:Int!){
  sleague(leagueId:$leagueId){
    sleagueId
    title
    seasonName
  }
}`;

const ALL_SLEAGUETEAMS_QUERY = `query sleagueTeam($sleagueId:Int!){
  sleagueTeam(sleagueId:$sleagueId){
    sleagueTeamId
    teamId{
      teamId
      name
      shortName
    }
  }
}`;

const RUN_SP_AWARD_AUTOMATION_SCRIPT = `mutation runSPAwardAutomationScript(
  $numUsers: Int!,
  $selcFormations: String!,
  $sleagueTeamId: Int!,
  $fixtureTeamId: Int!,
) {
  runSPAwardAutomationScript(
    numUsers: $numUsers,
    selcFormations: $selcFormations,
    sleagueTeamId: $sleagueTeamId,
    fixtureTeamId: $fixtureTeamId,
  ) {
    success
  }
}`;

const CALC_FAVTEAM_USERS_SP = `mutation calcFavteamUsersSp(
  $sleagueTeamId: Int!,
  $fixtureId: Int!, 
  $sleagueId:Int!
) {
  calcFavteamUsersSp(
    sleagueTeamId: $sleagueTeamId,
    fixtureId: $fixtureId,
    sleagueId: $sleagueId
  ){
    response
  }
}`;

const SLEAGUETEAMPLAYES_QUERY = `query sleagueTeamPlayer($sleagueTeamId:Int!){
  sleagueTeamPlayer(sleagueTeamId:$sleagueTeamId){
    sleagueTeamPlayerId
    playerId{
      fName
      lName
	    shortName
	    name
      position
    }
    jerseyNO
    status
    playerSeqNO
  }
}`;

// "formation_seq_no": {description}
const POSITIONS_DATA = {
  "01": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "02": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "03": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "04": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "05": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "06": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "07": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  "08": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },

  "09": {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "FOR",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },

  10: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  11: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  12: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  13: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  14: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  15: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  16: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  17: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },

  18: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  19: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  20: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  21: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  22: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },

  23: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  24: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },
  25: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },

  26: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  27: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  28: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  29: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  30: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },

  31: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  32: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  33: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  34: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },
  35: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "FOR",
    10: "FOR",
    11: "FOR",
  },

  36: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "FOR",
    11: "FOR",
  },

  37: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  38: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  39: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "DEF",
    "06": "DEF",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  41: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
  42: {
    "01": "GK",
    "02": "DEF",
    "03": "DEF",
    "04": "DEF",
    "05": "MID",
    "06": "MID",
    "07": "MID",
    "08": "MID",
    "09": "MID",
    10: "MID",
    11: "FOR",
  },
};

const mapSelFormToPOSITIONS_DATA = {
  "4-4-2": "01",
  "4-1-3-2": "02",
  "4-3-1-2": "03",
  "4-1-2-1-2": "04",
  "4-2-2-2": "05",
  "1-3-4-2": "06",
  "3-1-4-2": "07",
  "4-4-1-1": "08",
  "4-2-4": "09",
  "4-5-1": "10",
  "4-1-4-1": "11",
  "4-4-1-1": "12",
  "4-2-3-1": "13",
  "4-3-2-1": "14",
  "1-3-5-1": "15",
  "3-1-5-1": "16",
  "2-2-5-1": "17",
  "5-3-2": "18",
  "5-2-1-2": "19",
  "5-1-2-2": "20",
  "1-4-3-2": "21",
  "4-1-3-2": "22",
  "3-5-2": "23",
  "3-1-4-2": "24",
  "3-4-1-2": "25",
  "4-3-3": "26",
  "4-2-1-3": "27",
  "4-1-2-3": "28",
  "3-1-3-3": "29",
  "1-3-3-3": "30",
  "3-4-3": "31",
  "3-3-1-3": "32",
  "3-1-3-3": "33",
  "3-4-2-1": "34",
  "3-4-1-2": "35",
  "3-5-1-1": "36",
  "5-4-1": "37",
  "3-2-4-1": "38",
  "2-3-4-1": "39",
  "3-4-2-1": "41",
  "3-2-4-1": "42",
};

export default function SPAwardAutomationScript() {
  // const classes = useStyles();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //----------- Get Number of Users
  const [numUsers, setNumUsers] = useState(0);
  // const [allUsers, setAllUsers] = useState([]);
  // const {
  //   loading: allUsersLoading,
  //   error: allUsersError,
  //   data: allUsersData,
  // } = useQuery(ALL_USERS_QUERY);

  // useEffect(() => {
  //   if (!allUsersLoading && !allUsersError && allUsersData) {
  //     setAllUsers(allUsersData.allUsers);
  //   }
  // }, [allUsersData]);

  //----------- Get All Leagues
  const [allLeagues, setAllLeagues] = useState([]);
  const [selcLeague, setSelcLeague] = useState(-1);
  const allLeaguesQuery = useQuery(ALL_LEAGUES_QUERY);
  useEffect(() => {
    if (allLeaguesQuery.data && allLeaguesQuery.data.league) {
      setAllLeagues(allLeaguesQuery.data.league);
    }
  }, [allLeaguesQuery.data]);

  //----------- Get list of Seasons based on the selected League
  const [leagueSeasons, setLeagueSeasons] = useState([]);
  const [selcSeason, setSelcSeason] = useState(-1);
  const leagueSeasonsQuery = useQuery(SLEAGUE_QUERY, {
    variables: {
      leagueId: selcLeague,
    },
  });
  useEffect(() => {
    if (selcLeague === -1) return setLeagueSeasons([]);
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.sleague) {
      setLeagueSeasons(leagueSeasonsQuery.data.sleague);
    }
  }, [leagueSeasonsQuery.data, selcLeague]);

  //----------- Get All Sleague Team
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const [selcSleagueTeam, setSelcSleagueTeam] = useState(-1);
  const {
    loading: allSleagueTeamsLoading,
    error: allSleagueTeamsError,
    data: allSleagueTeamsData,
  } = useQuery(ALL_SLEAGUETEAMS_QUERY, {
    variables: {
      sleagueId: selcSeason,
    },
  });
  useEffect(() => {
    if (selcSeason === -1) return setAllSleagueTeams([]);
    if (!allSleagueTeamsLoading && !allSleagueTeamsError && allSleagueTeamsData) {
      setAllSleagueTeams(allSleagueTeamsData.sleagueTeam);
    }
  }, [allSleagueTeamsData, selcSeason, selcLeague]);

  //----------- Get list of Sleague Team Players
  const [GK, setGK] = useState([]);
  const [DEF, setDEF] = useState([]);
  const [MID, setMID] = useState([]);
  const [FOR, setFOR] = useState([]);
  const sleagueTeamPlayerQuery = useQuery(SLEAGUETEAMPLAYES_QUERY, {
    variables: {
      sleagueTeamId: selcSleagueTeam,
    },
  });
  useEffect(() => {
    if (sleagueTeamPlayerQuery.data && sleagueTeamPlayerQuery.data.sleagueTeamPlayer) {
      let tGK = [];
      let tDEF = [];
      let tMID = [];
      let tFOR = [];
      sleagueTeamPlayerQuery.data.sleagueTeamPlayer.map(player => {
        let plyPos = player.playerId.position;
        if (plyPos === "Goalkeeper") tGK.push(player.playerSeqNO);
        else if (plyPos === "Defender") tDEF.push(player.playerSeqNO);
        else if (plyPos === "Midfielder") tMID.push(player.playerSeqNO);
        else if (plyPos === "Forward") tFOR.push(player.playerSeqNO);
      });
      setGK(tGK);
      setDEF(tDEF);
      setMID(tMID);
      setFOR(tFOR);
    }
  }, [sleagueTeamPlayerQuery.data, selcSleagueTeam]);

  //----------- Get list of Fav Team Fixures
  const [favTeamFixures, setFavTeamFixures] = useState([]);
  const [selcFavTeamFixure, setSelcFavTeamFixure] = useState(-1);
  const [selcFavTeamFixureId, setSelcFavTeamFixureId] = useState(-1);
  const fixtureTeamsQuery = useQuery(GET_FIXTURETEAM_TEAMS, {
    variables: {
      sleagueTeamId: selcSleagueTeam,
    },
  });
  useEffect(() => {
    if (selcSleagueTeam === -1) return setFavTeamFixures([]);
    if (fixtureTeamsQuery.data && fixtureTeamsQuery.data.fixtureTeamTeams) {
      let favTeamsFixtures = {};
      let fixturesData = [];
      fixtureTeamsQuery.data.fixtureTeamTeams.map(team => {
        let fixtureId = team.fixtureId.fixtureId.toString();
        if (fixtureId in favTeamsFixtures) {
          let tmp = {};
          tmp["fixtureId"] = fixtureId;
          tmp["week"] = team.fixtureId.fixtureWeek.weekId.name;
          tmp["team1"] = team.sleagueTeamId.teamId.name;
          tmp["team2"] = favTeamsFixtures[fixtureId].sleagueTeamId.teamId.name;
          if (parseInt(team.sleagueTeamId.sleagueTeamId) === selcSleagueTeam)
            tmp["fixtureTeamId"] = team.fixtureTeamId;
          else tmp["fixtureTeamId"] = favTeamsFixtures[fixtureId].fixtureTeamId;
          // console.log(tmp);
          fixturesData.push(tmp);
        } else {
          favTeamsFixtures[fixtureId] = team;
        }
      });
      setFavTeamFixures(fixturesData);
    }
  }, [fixtureTeamsQuery.data, selcSleagueTeam]);

  //----------- Get list of Formations
  const [formations, setFormations] = useState([]);
  const [selcFormations, setSelcFormations] = useState([]);
  const [selcFormationsObj, setSelcFormationsObj] = useState({});
  const leagueFormationsQuery = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: selcLeague,
    },
  });
  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];
    let formationsObject = {};
    if (leagueFormationsQuery.data && leagueFormationsQuery.data.leagueFormation) {
      leagueFormationsQuery.data.leagueFormation.map(leagueFrm => {
        formationsObject[leagueFrm.formationId.formationId] = leagueFrm.formationId.title;
        if (!parentFormations.includes(leagueFrm.formationId.formationPrimary)) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsQuery.data.leagueFormation.filter(frm => {
          return frm.formationId.formationPrimary === formation;
        });
        formationsList[i].derivedCategories = derivedFormations;
      });
    }
    setFormations(formationsList);
    setSelcFormationsObj(formationsObject);
  }, [leagueFormationsQuery.data, selcLeague]);

  const leagueHandleChange = e => {
    if (e.target.value !== "") {
      setSelcLeague(parseInt(e.target.value));
    } else {
      setSelcLeague(-1);
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const seasonHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSeason(parseInt(e.target.value));
    } else {
      setSelcSeason(-1);
      setSelcSleagueTeam(-1);
    }
  };

  const favTeamHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSleagueTeam(parseInt(e.target.value));
    } else {
      setSelcSleagueTeam(-1);
    }
  };

  const formationsHandleChange = e => {
    const {
      target: { value },
    } = e;
    setSelcFormations(typeof value === "string" ? value.split(",") : value);
  };

  const favTeamFixtureHandleChange = e => {
    console.log(e.target.value);

    if (e.target.value !== "") {
      let data = e.target.value.split(",");
      console.log(data[0]);
      console.log(data[1]);
      setSelcFavTeamFixure(parseInt(data[0]));
      setSelcFavTeamFixureId(parseInt(data[1]));
    } else {
      setSelcFavTeamFixure(-1);
      setSelcFavTeamFixureId(-1);
    }
  };

  // const gntUniqRanNum = (num, max) => {
  //   var arr = [];
  //   while (arr.length < num) {
  //     var r = Math.floor(Math.random() * max);
  //     if (arr.indexOf(r) === -1) arr.push(r);
  //   }
  //   return arr;
  // };

  // Run the script through Mutation
  const [runSPAwardAutomationMutation] = useMutation(RUN_SP_AWARD_AUTOMATION_SCRIPT);
  const [calcFavTeamUserSP] = useMutation(CALC_FAVTEAM_USERS_SP);

  const runTestScript = async () => {
    let selcFormationsStr = "";
    for (const f of selcFormations) selcFormationsStr = selcFormationsStr + f + "&&";

    // Run the script through Mutation; Set the userSeq & coachSeq
    const { data, loading, error } = await runSPAwardAutomationMutation({
      variables: {
        numUsers: numUsers,
        selcFormations: selcFormationsStr,
        sleagueTeamId: selcSleagueTeam,
        fixtureTeamId: selcFavTeamFixure,
      },
    });
    if (error) {
      alert(error);
    } else {
      // Run the SP calculaton Mutation to calculate the user's SP for fav team
      const { data, loading, error } = await calcFavTeamUserSP({
        variables: {
          sleagueTeamId: selcSleagueTeam,
          fixtureId: selcFavTeamFixureId,
          sleagueId: selcSeason,
        },
      });
      if (error) {
        alert(error);
      } else {
        alert(data.calcFavteamUsersSp.response);
      }
    }
  };

  //   return;
  //   let randFormation = [];
  //   let tmpDEF = [];
  //   let tmpMID = [];
  //   let tmpFOR = [];
  //   let index = -1;

  //   for (const f of selcFormations) randFormation.push(mapSelFormToPOSITIONS_DATA[f]);
  //   const posOrdr = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"];

  //   for (let i = 0; i < 8; i++) {
  //     tmpDEF = DEF.slice();
  //     tmpMID = MID.slice();
  //     tmpFOR = FOR.slice();

  //     // Set a formation for user
  //     let randInt = Math.floor(Math.random() * randFormation.length);
  //     let usrFormation = randFormation[randInt];
  //     let usrStr = usrFormation;

  //     for (const pos of posOrdr) {
  //       usrStr += pos;
  //       let plyPos = POSITIONS_DATA[usrFormation][pos];

  //       // console.log("plyPos: ", plyPos);
  //       if (plyPos === "GK") {
  //         randInt = Math.floor(Math.random() * GK.length);
  //         usrStr += GK[randInt];
  //       } else if (plyPos === "DEF") {
  //         randInt = Math.floor(Math.random() * tmpDEF.length);
  //         usrStr += tmpDEF[randInt];
  //         // console.log("tmpDEF[randInt]: ", tmpDEF[randInt]);
  //         index = tmpDEF.indexOf(tmpDEF[randInt]);
  //         if (index > -1) {
  //           // only splice array when item is found
  //           tmpDEF.splice(index, 1); // 2nd parameter means remove one item only
  //         }
  //       } else if (plyPos === "MID") {
  //         randInt = Math.floor(Math.random() * tmpMID.length);
  //         usrStr += tmpMID[randInt];
  //         index = tmpMID.indexOf(tmpMID[randInt]);
  //         if (index > -1) {
  //           tmpMID.splice(index, 1);
  //         }
  //       } else if (plyPos === "FOR") {
  //         randInt = Math.floor(Math.random() * tmpFOR.length);
  //         usrStr += tmpFOR[randInt];
  //         index = tmpFOR.indexOf(tmpFOR[randInt]);
  //         if (index > -1) {
  //           tmpFOR.splice(index, 1);
  //         }
  //       }
  //     }

  //     console.log(usrStr);
  //   }
  // };
  // console.log("************  Formations", formations);
  // console.log("************  selcLeague", selcLeague);
  // console.log("************  leagueSeasons", leagueSeasons);
  // console.log("************  allSleagueTeams", allSleagueTeams);
  console.log("************  favTeamFixures", favTeamFixures);

  // console.log("************  GK", GK);
  // console.log("************  DEF", DEF);
  // console.log("************  MID", MID);
  // console.log("************  FOR", FOR);

  // console.log("&&&&&&&&  selcFormationsObj", selcFormationsObj);

  // const leagueHandleChange = e => {
  //   if (e.target.value !== "") {
  //     setSelcLeague(parseInt(e.target.value));
  //   } else {
  //     setSelcLeague(-1);
  //   }
  // };

  const renderSelectGroup = formation => {
    const items = formation.derivedCategories.map(f => {
      return (
        <MenuItem
          style={{ paddingLeft: 30, fontSize: 14 }}
          divider={true}
          key={f.formationId.formationId}
          value={f.formationId.formationId}
        >
          <Checkbox checked={selcFormations.indexOf(f.formationId.formationId) > -1} />
          <ListItemText primary={f.formationId.title} />
        </MenuItem>
      );
    });
    return [
      <ListSubheader
        style={{
          color: "black",
          fontSize: 14,
          fontWeight: "bold",
          backgroundColor: "white",
          marginLeft: 0,
        }}
      >
        {formation.mainCategory}
      </ListSubheader>,
      items,
    ];
  };

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2 className={adminStyle.h2}>PSP Award Automation Script</h2>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <TextField
              className={adminStyle.select}
              label="Number of users"
              type="number"
              InputProps={{
                inputProps: { min: 0, pattern: "[0-9]*" },
              }}
              onChange={e => {
                setNumUsers(parseInt(e.target.value));
              }}
            />

            {/* <FormControl className={adminStyle.select}>
              <InputLabel id="select-league-label">Select a League ...</InputLabel>
              <Select
                labelId="select-league-label"
                id="select-league"
                value={allLeagues}
                label="League"
                onChange={leagueHandleChange}
              >
                {allLeagues.map(({ leagueId, title }) => (
                  <MenuItem value={leagueId}>{title}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <select
              className={adminStyle.select}
              onChange={leagueHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a League ...</option>
              {allLeagues.map(({ leagueId, title }) => (
                <option key={leagueId} value={leagueId}>
                  {title}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={seasonHandleChange}
              // value={currSleagueTeamId}
            >
              <option value="">Select a Season ...</option>
              {leagueSeasons.map(({ sleagueId, title, seasonName }) => (
                <option key={sleagueId} value={sleagueId}>
                  {title} ({seasonName})
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={favTeamHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select Favourite Team ...</option>
              {allSleagueTeams.map(({ sleagueTeamId, teamId }) => (
                <option key={sleagueTeamId} value={sleagueTeamId}>
                  {teamId.name}
                </option>
              ))}
            </select>

            <select className={adminStyle.select} onChange={favTeamFixtureHandleChange}>
              <option value="">Select a Fixture ...</option>
              {favTeamFixures.map(({ fixtureTeamId, fixtureId, team1, team2 }) => (
                <option key={fixtureTeamId} value={[fixtureTeamId, fixtureId]}>
                  {team1} - {team2}
                </option>
              ))}
            </select>

            <div>
              <FormControl className={adminStyle.select}>
                <InputLabel>Select Formations ...</InputLabel>
                <Select
                  multiple
                  value={selcFormations}
                  onChange={formationsHandleChange}
                  // input={<OutlinedInput label="Tag" />}
                  // renderValue={selected => selected.join(", ")}
                  renderValue={selected => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip key={value} label={selcFormationsObj[value]} />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected": {
                        backgroundColor: "#FBF3FF",
                      },
                    },
                    PaperProps: {
                      sx: { maxHeight: 200 },
                    },
                  }}
                >
                  {formations?.map(p => renderSelectGroup(p))}
                </Select>
              </FormControl>
            </div>
          </form>
        </Grid>

        <Grid item>
          <Button
            className={adminStyle.button}
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={runTestScript}
          >
            Run Test Script
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
