import React, { useState, useEffect, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {project_url, admin_url, 
        secretPass, seo_tag_config} from "../../store/data/Constants";
import CryptoJS from "crypto-js";

import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Grid,
} from "@material-ui/core";

import logoImg from "../../assets/preditcal_logo.png";

import { REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 910,
    minHeight: 468,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 900,
    height: 648,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    maxWidth: 430,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 650,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },
  textFieldStyle: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 16,
    },
  },
  textFieldStyleMobile: {
    marginBottom: 5,
    marginTop: 30,
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      fontSize: 14,
    },
  },
  errorStyle: {
    marginTop: 0,
    fontSize: 14, //16,
    fontFamily: "Arial",
  },
  errorStyleMobile: {
    marginTop: 0,
    fontSize: 12,
    fontFamily: "Arial",
  },
  successStyle: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 16,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  successStyleMobile: {
    color: "#212121",
    backgroundColor: "#F0FEF4",
    borderRadius: 12,
    padding: 16,
    fontSize: 14,
    fontFamily: "Arial",
    lineHeight: 1.4,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const LOGIN_USER_MUTATION = `
    mutation loginAdminUser($email: String!, $password: String!) {
      tokenAuth(email: $email, password: $password) {
        token
    refreshToken
    payload
      }
    }
`;

const LOGIN_AUTO_MUTATION = `
    mutation loginAuto($username: String!) {
      loginAuto(username: $username) {
      userId
    token
    refreshToken
    role
      }
    }
`;

const SignIn = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [autoLoginFlag, setAutoLoginFlag] = useState(localStorage.getItem("autoLogin"));
  const [usernameFlag, setUsernameFlag] = useState(localStorage.getItem("username"));
  const [preferedDateFormat, setPreferedDateFormat] = useState(localStorage.getItem("preferedDateFormat"));
  const [loginAdminUser] = useMutation(LOGIN_USER_MUTATION);
  const [loginAuto] = useMutation(LOGIN_AUTO_MUTATION);
  const navigate = useNavigate();
  const client = useContext(ClientContext);
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const [nextUrl] = useSearchParams();
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  useEffect(() => {
    if (
      localStorage.getItem("autoLogin") &&
      localStorage.getItem("username") &&
      localStorage.getItem("autoLogin") === "true"
    ) {
      client.removeHeader("Authorization");
      onAutoLogin();
    }
    else if(localStorage.getItem("refreshToken")){
      onRefreshToken();
    }
  }, [localStorage]);

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
    if(refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
      if (refreshData.refreshToken.payload.role == "Admin") {
        localStorage.setItem("role", CryptoJS.AES.encrypt("Admin",secretPass).toString());
        navigate(`/lms/${admin_url}/dashboard`);
      } else {
        localStorage.setItem("role", CryptoJS.AES.encrypt("Regular User",secretPass).toString());
        navigate("/lms/dashboard");
      }
    }
  };

  const onAutoLogin = async () => {
    const { data: refreshData, error: refreshError } = await loginAuto({
      variables: { username: localStorage.getItem("username") },
    });
    console.log("data:", refreshData, "error:", refreshError);
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate("/lms/signin");
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signin");
    }
    if (refreshData && refreshData.loginAuto) {
      localStorage.setItem("token", refreshData.loginAuto.token);
      localStorage.setItem("refreshToken", refreshData.loginAuto.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.loginAuto.token}`);
      if (refreshData.loginAuto.role == "Admin") {
        localStorage.setItem("role", CryptoJS.AES.encrypt("Admin",secretPass).toString());
        navigate(`/lms/${admin_url}/dashboard`);
      } else {
        localStorage.setItem("role", CryptoJS.AES.encrypt("Regular User",secretPass).toString());
        navigate("/lms/dashboard");
      }
    }
  };

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMsg("");
    setErrorMsg("");
  }, 10000000000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {

    await sleep(2000);

    console.log("DATA-----------", formData);
    const { data, error } = await loginAdminUser({
      variables: {
        email: formData.email,
        password: formData.password,
      },
    });
    if (error) {
      console.log("Error", error, data);
      setErrorMsg(
        "Incorrect email or password. Or, your account is not activated. Or, this email does not exist. Please try again."
      );
      setTimeout("");
    } else {
      if (data.tokenAuth.payload.role == "Regular User") {
        // window.sessionStorage.setItem("rtsLocation", "true");
        // Sahar RTS-1093 
        localStorage.removeItem("userRegisterEmail");
        //
        localStorage.setItem("token", data.tokenAuth.token);
        localStorage.setItem("refreshToken", data.tokenAuth.refreshToken);
        localStorage.setItem("role", CryptoJS.AES.encrypt(data.tokenAuth.payload.role,secretPass).toString());
        localStorage.setItem("email", data.tokenAuth.payload.email);
        localStorage.setItem("username", data.tokenAuth.payload.username);
        localStorage.setItem("autoLogin", data.tokenAuth.payload.autoLogin);
        localStorage.setItem("favTeam",data.tokenAuth.payload.favTeam);
        localStorage.setItem("favTeamLogo",data.tokenAuth.payload.favTeamLogo);
        localStorage.setItem("preferedDateFormat",data.tokenAuth.payload.preferedDateFormat);
        client.setHeader("Authorization", `JWT ${data.tokenAuth.token}`);
        if (nextUrl && nextUrl.get("next")){
          navigate(`${nextUrl.get("next")}`);
        }else{
          navigate("/lms/dashboard");
        }
      } else {
        alert("Wait a minute! You are in the wrong place. Start from Predictal.com.");
    localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      client.removeHeader("Authorization");
    window.location.replace('https://predictal.com');
      }
    }
  };

  return (
     <Grid>
      {autoLoginFlag !== "true" || (autoLoginFlag === "true" && usernameFlag === null) ? (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Log In or Registration</title>
          <meta 
            name="description" 
            content="Predictal user sign-in to football prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, user sign-in, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }
      
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <Box alignItems="center" mb={3} flexGrow={1}>
              <Box
                flexGrow={1}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={1}
              >
                <div>
                  <Typography gutterBottom variant="subtitle2">
                    Sign in
                  </Typography>
                </div>
              </Box>
              <Typography variant="body2">
                Predictal is a premier global destination that connects sports
                fans to share and challenge their knowledge about sports in a
                safe and collaborative environment. At its inaugural launch,
                Predictal is home to the leading digital experience platform for
                Team Selector in the sport of football.
              </Typography>
            </Box>
            <Box flexGrow={1} mb={1}>
              <TextField
                className={classes.textFieldStyleMobile}
                fullWidth
                //autoFocus
                label="Email" // Address"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                id="email"
                      {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <span className="input-error-message">
                              Email address cannot be blank.
                            </span>
                          )}
                  {errors.email && errors.email.type === "pattern" && (
                  <span className="input-error-message">
                    Invalid email format.
                  </span>
                  )}
              <TextField
                className={classes.textFieldStyleMobile}
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                type="password"
                variant="outlined"
                id="password"
                {...register("password", { required: true })}
                    />
                    {errors.password && errors.password.type === "required" && (
                      <span className="input-error-message">
                        Password cannot be blank.
                      </span>
                    )}

              {errorMsg && (
                <Box mt={1} mb={1} ml={2}>
                  <FormHelperText className={classes.errorStyleMobile} error>
                    {errorMsg}
                  </FormHelperText>
                </Box>
              )}
              {successMsg && (
                <Box mt={2} className={classes.successStyleMobile}>
                  {successMsg}
                </Box>
              )}
              <Box mt={5} mb={1} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Sign In
                </Button>
              </Box>
            </Box>
            <Box mt={1} display="flex" alignItems="center">
              <Typography variant="h5">Don't have an account yet?</Typography>
              <Box ml={0.5}>
                <Link
                  component={RouterLink}
                  to="/lms/registration"
                  variant="h4"
                  color="primary"
                >
                  Register?
                </Link>
              </Box>
            </Box>
            <Box mt={1} display="flex" alignItems="center">
              <Link
                component={RouterLink}
                to="/lms/forgot_password"
                variant="h4"
                color="primary"
              >
                Forgot Password?
              </Link>
            </Box>
          </CardContent>
        </Card>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box
                  flexGrow={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={1}
                >
                  <div>
                    <Typography gutterBottom variant="subtitle1">
                      Sign in
                    </Typography>
                  </div>
                </Box>
                <Typography variant="body1">
                  Predictal is a premier global destination that connects sports
                  fans to share and challenge their knowledge about sports in a
                  safe and collaborative environment. At its inaugural launch,
                  Predictal is home to the leading digital experience platform
                  for Team Selector in the sport of football.
                </Typography>
              </Box>
              <Box flexGrow={1} mb={1}>
                <TextField
                  className={classes.textFieldStyle}
                  fullWidth
                  //autoFocus
                  label="Email" // Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  id="email"
                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span className="input-error-message">
                            Email address cannot be blank.
                          </span>
                        )}
                {errors.email && errors.email.type === "pattern" && (
                <span className="input-error-message">
                  Invalid email format.
                </span>
                )}
                <TextField
                  className={classes.textFieldStyle}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  id="password"
                  {...register("password", { required: true })}
                      />
                      {errors.password && errors.password.type === "required" && (
                        <span className="input-error-message">
                          Password cannot be blank.
                        </span>
                      )}

                {errorMsg && (
                  <Box mt={1} mb={1} ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      {errorMsg}
                    </FormHelperText>
                  </Box>
                )}
                {successMsg && (
                  <Box mt={2} className={classes.successStyle}>
                    {successMsg}
                  </Box>
                )}
                <Box mt={5} mb={1} display="flex" justifyContent="center">
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <Typography variant="h3">Don't have an account yet?</Typography>
                <Box ml={0.5}>
                  <Link
                    component={RouterLink}
                    to="/lms/registration"
                    variant="h2"
                    color="primary"
                  >
                    Register?
                  </Link>
                </Box>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <Link
                  component={RouterLink}
                  to="/lms/forgot_password"
                  variant="h2"
                  color="primary"
                >
                  Forgot Password?
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box alignItems="center" mb={3} flexGrow={1}>
                <Box mb={2}>
                  <img src={logoImg} alt="logo" />
                </Box>
                <Typography variant="body1">
                  Predictal is a premier global destination that connects sports
                  fans to share and challenge their knowledge about sports in a
                  safe and collaborative environment. At its inaugural launch,
                  Predictal is home to the leading digital experience platform
                  for Team Selector in the sport of football.
                </Typography>
              </Box>
            </CardContent>
            <Divider orientation="vertical" flexItem />
            <CardContent
              className={classes.cardContent}
              style={{ minWidth: 350 }}
            >
              <Box
                flexGrow={1}
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <Typography gutterBottom variant="subtitle1">
                  Sign in
                </Typography>
              </Box>

              <Box flexGrow={1} mb={1}>
                <TextField
                  className={classes.textFieldStyle}
                  fullWidth
                  //autoFocus
                  label="Email" // Address"
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                id="email"
                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span className="input-error-message">
                            Email address cannot be blank.
                          </span>
                        )}
                {errors.email && errors.email.type === "pattern" && (
                <span className="input-error-message">
                  Invalid email format.
                </span>
                )}
                <TextField
                  className={classes.textFieldStyle}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  id="password"
                  {...register("password", { required: true })}
                      />
                      {errors.password && errors.password.type === "required" && (
                        <span className="input-error-message">
                          Password cannot be blank.
                        </span>
                      )}

                {errorMsg && (
                  <Box mt={1} mb={1} ml={2}>
                    <FormHelperText className={classes.errorStyle} error>
                      {errorMsg}
                    </FormHelperText>
                  </Box>
                )}
                {successMsg && (
                  <Box mt={2} className={classes.successStyle}>
                    {successMsg}
                  </Box>
                )}
                <Box mt={5} display="flex" justifyContent="center">
                  <Button
                    className={classes.buttonStyle}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
              <Box mt={2} display="flex" alignItems="center">
                <Typography variant="h3">Don't have an account yet?</Typography>
                <Box ml={0.5}>
                  <Link
                    component={RouterLink}
                    to="/lms/registration"
                    variant="h2"
                    color="primary"
                  >
                    Register?
                  </Link>
                </Box>
              </Box>
              <Box mt={2} mb={2} display="flex" alignItems="center">
                <Link
                  component={RouterLink}
                  to="/lms/forgot_password"
                  variant="h2"
                  color="primary"
                >
                  Forgot Password?
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
   </form>
    </>
    ) : (
        <></>
      )}
    </Grid>
  );
};

export default SignIn;
