const profile_query = `
    query profile {
      profile
      {
        id
        username
        email
        firstName
        lastName
		isActive
		isSuperuser
		profile{
		  idleTime
		  autoLogin
			preferedDateFormat
		}
		userFavTeamField{
		  sleagueTeamId {
            sleagueTeamId
			sleagueId{
			  sleagueId
			}
  			teamId {
  			  name
  			  shortName
  			  logo
  			}
		  }
		  favTeamSeq
		}
      }
    }
`;

const UpdateUserProfileWithPassword_mutate = `
    mutation updateUserProfileWithPass(
      $email: String!,
	    $password: String!,
      $newPassword: String!,
      $newPasswordConfirm: String!,
      $firstname: String!,
	    $lastname: String!,
	    $idletime: Int!,
	    $username: String!,
	    $favTeam: String!,
	    $autoLogin: String!,
      $preferedDateFormat: String!) {
        updateUserProfileWithPass(
          email: $email,
	      	password: $password,
          newPassword: $newPassword,
          newPasswordConfirm: $newPasswordConfirm,
          firstname: $firstname,
		      lastname: $lastname,
		      idletime: $idletime,
		      username: $username,
		      favTeam: $favTeam,
		      autoLogin: $autoLogin,
          preferedDateFormat: $preferedDateFormat) {
            userId
		        email
            firstname
		        lastname
		        idletime
		        username
            preferedDateFormat
            autoLogin
            favTeam
      }
    }
`;
const UpdateUserProfile_mutate = `
    mutation updateUserProfile(
      $email: String!,
      $firstname: String!,
	    $lastname: String!,
	    $idletime: Int!,
	    $username: String!,
	    $favTeam: String!,
	    $autoLogin: String!,
      $preferedDateFormat: String!) {
        updateUserProfile(
          email: $email,
          firstname: $firstname,
		      lastname: $lastname,
		      idletime: $idletime,
		      username: $username,
		      favTeam: $favTeam,
		      autoLogin: $autoLogin,
          preferedDateFormat: $preferedDateFormat) {
            userId
		        email
            firstname
		        lastname
		        idletime
		        username
            preferedDateFormat
            autoLogin
            favTeam
      }
    }
`;


const UpdateUser_mutate = `
    mutation updateUser(
      $email: String!,
      $firstname: String!,
	    $lastname: String!,
	    $username: String!) {
      updateUser(
        email: $email,
        firstname: $firstname,
		    lastname: $lastname,
		    username: $username) {
        userId
		    email
        firstname
		    lastname
		    username
      }
    }
`;

const UpdateUserSettings_mutate = `
    mutation updateUserSettings(
	     $idletime: Int!,
	     $autoLogin: String!,
       $preferedDateFormat: String!) {
         updateUserSettings(
           idletime: $idletime,
		       autoLogin: $autoLogin,
           preferedDateFormat: $preferedDateFormat) {
             userId
		         idletime
             preferedDateFormat
             autoLogin

           }
      }
`;

const UpdateUserFavteam_mutate = `
    mutation updateUserFavteam(
	     $favTeam: String!) {
         updateUserFavteam(
           favTeam: $favTeam) {
             userId
		         favTeam
             favTeamLogo

           }
      }
`;
const UpdateUserPassword_mutate = `
    mutation UpdateUserPassword(
	     $currentPassword: String!,
       $newPassword: String!,
       $newPasswordConfirm: String!) {
         updateUserPassword(
           currentPassword: $currentPassword,
            newPassword: $newPassword,
            newPasswordConfirm: $newPasswordConfirm
          ) {
             userId
		         message

           }
      }
`;

const ForgetPassword_mutate = `
    mutation forgetPassword(
      $email: String!) {
      forgetPassword(
        email: $email) {
        userId
		email
      }
    }
`;

const ResetPassword_mutate = `
    mutation resetPassword(
      $password: String!,
	  $forgetCode: String!) {
      resetPassword(
        password: $password,
		forgetCode: $forgetCode) {
        userId
      }
    }
`;

const RefreshToken_mutate = `
    mutation RefreshToken($refreshToken: String!) {
	  refreshToken(refreshToken: $refreshToken) {
		token
		payload
		refreshToken
		refreshExpiresIn
	  }
	}
`;

const DeleteAccount_mutate = `
    mutation DeleteUserAccount($confirmPhrase: String!, $password: String!){
      deleteUserAccount (confirmPhrase :$confirmPhrase, password: $password) {
        response
      }
    }`

module.exports = {
  GET_USERPROFILE_QUERY: profile_query,
  UPDATE_USERPROFILE_QUERY: UpdateUserProfile_mutate,
  UPDATE_USERPROFILE_WITH_PASSWORD_MUTATION: UpdateUserProfileWithPassword_mutate,
  UPDATE_USER_MUTATION: UpdateUser_mutate,
  UPDATE_USER_SETTINGS_MUTATION: UpdateUserSettings_mutate,
  UPDATE_USER_FAVTEAM_MUTATION: UpdateUserFavteam_mutate,
  REFRESH_TOKEN_MUTATION: RefreshToken_mutate,
  FORGET_PASSWORD_MUTATION: ForgetPassword_mutate,
  RESET_PASSWORD_MUTATION: ResetPassword_mutate,
  DELETE_USER_ACCOUNT: DeleteAccount_mutate,
  UPDATE_USER_PASSWORD_MUTATION: UpdateUserPassword_mutate
};
