import React, { useState, useEffect, } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Positions from "./head2HeadScreens/Positions";
import Stats from "./head2HeadScreens/Stats";
import Games from "./head2HeadScreens/Games";
import Bio from "./head2HeadScreens/Bio";

const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    padding-top: 10px;
    min-height: 20px;
    height: 40px;
    width: 100%;
    background-color: white;
  }
`;
const useStyles = makeStyles((theme) => ({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//<Box sx={{ p: 3 }}>
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function HeadToHeadDialog({
  title,
  contentMsg,
  closeH2HDialog,
  head2headData,
  players,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);
  const handleClose = () => {
    setOpen(false);
    closeH2HDialog();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(
    "head2headData--",
    head2headData[0].lastH2HDroppedItem,
    head2headData[1].lastH2HDroppedItem.name
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "836px",

              height: "100%",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            paddingTop: 30,
            fontSize: 18,
            fontWeight: 400,
            textAlign: "center",
            color: "#212121",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                paddingTop: "5px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#765285",
                  },
                }}
                style={{ paddingLeft: "5px", height: "48px" }}
                variant="standard" //"fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Games"
                  style={{
                    width: 85,
                    fontSize: 16,
                    fontWeight: 600,
                    color: value === 0 ? "#765285" : "#4D4D4D",
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    width: 85,
                    fontSize: 16,
                    fontWeight: 600,
                    minHeight: 0,
                    color: value === 1 ? "#765285" : "#4D4D4D",
                  }}
                  label="Stats"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    width: 85,
                    fontSize: 16,
                    fontWeight: 600,
                    color: value === 2 ? "#765285" : "#4D4D4D",
                  }}
                  label="Positions"
                  {...a11yProps(2)}
                />
                <Tab
                  style={{
                    width: 85,
                    fontSize: 16,
                    fontWeight: 600,
                    color: value === 3 ? "#765285" : "#4D4D4D",
                  }}
                  label="Bio"
                  {...a11yProps(3)}
                />
              </Tabs>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={11} sm={11} md={11}>
              <TabPanel value={value} index={0}>
                <Games players={players} head2headData={head2headData} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Stats players={players} head2headData={head2headData} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Positions players={players} head2headData={head2headData} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Bio players={players} head2headData={head2headData} />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
        <Box mb={4} mr={3}>
          <Button
            variant="text"
            onClick={handleClose}
            color="primary"
            className={classes.outlinedButtonStyle}
          >
            Close
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// const handleClickOpen = () => {
//   setOpen(true);
// };

// <Button variant="outlined" onClick={handleClickOpen}>
//   Open alert dialog
// </Button>
