import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useQuery,useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {project_url, admin_url} from "../store/data/Constants";

import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

/* ------------------------------------*/
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
/* ------------------------------------*/
import {
  GET_CURRENT_SLEAGUES_QUERY,
  GET_SLEAGUE_WEEK_QUERY
} from "../graphQL/week_qry";
import {
  GET_FIXTURE_OBJECT,
  GET_FIXTURE_OBJECT_TEAMS,
  RESCHEDULE_FIXTURE
} from "../graphQL/fixture_qry";

// import "../styles.css";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Fixture
## Description : Submit form data and Add Fixture
## Author : SP
## Date: : 2022/03/02
##########################################################################*/
export default function FixtureReschedule() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm();
  const { fixtureId } = useParams();
  const { control } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const navigate = useNavigate();
  const [sleagueId, setSleagueId] = useState("");
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);

  const [allSleagueWeeks, setAllSleagueWeeks] = useState([]);
  const [playedUnknown, setPlayedUnknown] = useState("");
  const [selectedDateTime, handleDateTimeChange] = useState(new Date());
  const [weekId, handleWeekId] = useState("");


  const [getSleagueWeeks] = useManualQuery(GET_SLEAGUE_WEEK_QUERY, {
    fetchPolicy: "network-only"
  })
  const [rescheduleFixture] = useMutation(RESCHEDULE_FIXTURE, {
    awaitRefetchQueries: true
  })
/*--------------------------*/
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION,{onError: (err) => {console.log(err);}});
  const client = useContext(ClientContext);

  useEffect(() => {
    if(localStorage.getItem("refreshToken")){
		onRefreshToken();
	}
	else
		navigate(`/lms/${admin_url}/signin`);
  }, [])

  const onRefreshToken = async () => {
    const { data:refreshData, error:refreshError } = await refreshToken({variables: { refreshToken: localStorage.getItem("refreshToken")}});
	if (refreshError) {
		if(refreshError.graphQLErrors[0].message === "You do not have permission to perform this action")
			navigate(`/lms/${admin_url}/signin`);
		if(refreshError.graphQLErrors[0].message === "Signature has expired")
			navigate(`/lms/${admin_url}/signin`);
	}
	if(refreshData && refreshData.refreshToken) {
		localStorage.setItem("token", refreshData.refreshToken.token);
        localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
        client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
	}
  };
  /*--------------------------*/
  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);


  const { loading, error, data } = useQuery(GET_FIXTURE_OBJECT_TEAMS,{
    variables: {
      fixtureId: parseInt(fixtureId)
    }

    });


    /*
     * Function : updateWeeks
     * Description : update weeks according to sleague change
     */

    const updateWeeks = async sid => {
      if (sid !== null){
      console.log(sid);
      const { loading:weekLoading, error:weekError, data:weekData } = await getSleagueWeeks({
        variables: {
          sleagueId: parseInt(sid),
        }
      });

      if (!weekLoading && weekData && weekData.sleagueWeek ) {
        setAllSleagueWeeks(weekData.sleagueWeek);
      }
      if (
        weekError &&
        weekError.graphQLErrors[0].message === "Signature has expired"
      ) {
        navigate(`/lms/${admin_url}/signin`);
      }

      // filter week for selected sleague
      // allWeeks.map(i => {
      //   if (selectedSleague && i.sleagueId.sleagueId === selectedSleague)
      //     week.push(i);
      // });
    }
    };

  useEffect(
    () => {
      setPlayedUnknown("true");
      if (!loading && data && data.fixtureObjectTeams) {
        setTeam1(data.fixtureObjectTeams[0]);
        setTeam2(data.fixtureObjectTeams[1]);
      //  handleDateTimeChange(new Date(data.fixtureObjectTeams[0].fixtureId.fixtureWeek.scheduledDate));
        updateWeeks(data.fixtureObjectTeams[0].fixtureId.fixtureWeek.weekId.sleagueId.sleagueId);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/${admin_url}/signin`);
      }
    },
    [data, error, loading, navigate]
  );


  const dayFormat = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const newFormat = newDateFormat + ' - ' + newTimeFormat;
    return newFormat;
  };


  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);
    let fixtureDateString = selectedDateTime.toISOString().slice(0, 10);
    let fixtureTimeString = selectedDateTime.toISOString().slice(11, 19);
    let fixtureDate = fixtureDateString + ' ' + fixtureTimeString;
    let playedUnknown = formData.playedUnknown==='week-unknown-true';
    console.log(fixtureDate);


    const { submitData, submitError } = await rescheduleFixture({
      variables: {
        weekId: parseInt(formData.weekId),
        fixtureDate: fixtureDate,
        playedUnknown: playedUnknown,
        fixtureId : parseInt(fixtureId)
      }
    });

    if (error) {
      console.log("error", error);

      setFailedMessage("Unfortunately an error occurred.");
    } else if (data && data.fixtureObjectTeams) {
      //console.log("Success", data);
      //add record to fixture_team



      setSuccessMessage("Fixture was rescheduled successfully.");
      setTimeout("");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/fixture`);
      }, 2000);
    }
  };




  const selectWeekOption = event => {
    const weekOption = event.target.value;
    weekOption ==='week-unknown-true'?setPlayedUnknown('true'):setPlayedUnknown('false')
  }

  const fixtureDateLabel = fixture => {
    if (fixture.fixtureWeek.playedWeek){
      return dayFormat(fixture.fixtureWeek.scheduledDate)
    }else{
      return dayFormat(fixture.scheduledWeek.scheduledDate)

    }
  }
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | Reschedule  Fixture | Admin</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Reschedule the fixture</h2>
          <div className={adminStyle.label}>
            League: &nbsp;
            {team1 && team1.sleagueTeamId && team1.sleagueTeamId.sleagueId ?team1.sleagueTeamId.sleagueId.leagueId.title:'--'}
          </div>
          <div className={adminStyle.label}>
            Season: &nbsp;
            {team1 && team1.sleagueTeamId && team1.sleagueTeamId.sleagueId ?team1.sleagueTeamId.sleagueId.seasonName:'--'}
          </div>
          <div className={adminStyle.label}>
            Week: &nbsp;
            {team1 && team1.fixtureId && team1.fixtureId.scheduledWeek ?team1.fixtureId.scheduledWeek.weekId.name:'--'}
          </div>
          <div className={adminStyle.label} >
            Fixture: &nbsp;
            {team1&& team1.home?
              <b>{team1 && team1.sleagueTeamId && team1.sleagueTeamId.teamId.name ?team1.sleagueTeamId.teamId.name +'('+team1.scoreFinal+ ')':''}
              - {team2 && team2.sleagueTeamId && team2.sleagueTeamId.teamId.name ?team2.sleagueTeamId.teamId.name+'('+team2.scoreFinal+ ')':''}
              </b>
              :
              <b>{team2 && team2.sleagueTeamId && team2.sleagueTeamId.teamId.name ?team2.sleagueTeamId.teamId.name+'('+team2.scoreFinal+ ')':''}
              - {team1 && team1.sleagueTeamId && team1.sleagueTeamId.teamId.name ?team1.sleagueTeamId.teamId.name+'('+team1.scoreFinal+ ')':''}
              </b>

            }
            <br/>


          </div>
          <div className={adminStyle.label}>
            Fixture Date & Time: &nbsp;
            {team1 && team1.fixtureId && team1.fixtureId.fixtureWeek ?fixtureDateLabel(team1.fixtureId):''}
          </div>



          <div className={adminStyle.input}>
            <div>
                <input       id="weeknotset" {...register("playedUnknown")}
                  value="week-unknown-true"  name="playedUnknown"   defaultChecked   type="radio" onChange={selectWeekOption} />

              <label htmlFor="weeknotset">Delay</label>
                </div>

                <br/>
            <div >
            <input        id="weekset" {...register("playedUnknown")}
              value="week-unknown-false"   name="playedUnknown"     type="radio" onChange={selectWeekOption}

              />
          <label htmlFor="weekset">Reschedule</label>
        </div>




          <label className={adminStyle.label} htmlFor="weekId">
            <b>New Week</b>
          </label>
          <select
            className={adminStyle.select}
            id="weekId"
            {...register("weekId")}
            disabled={playedUnknown==='true'?true:null}
          >
            <option value="0">Select a week...</option>
            {allSleagueWeeks.map((allSleagueWeeks, i) => (
              <option key={i} value={allSleagueWeeks.weekId}>
                {allSleagueWeeks.name}
              </option>
            ))}
          </select>




          <label className={adminStyle.label} htmlFor="fixtureDate">
            <b>New Date & Time</b>
          </label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              id="fixtureDate"
              {...register("fixtureDate")}
              value={selectedDateTime}
              onChange={handleDateTimeChange}
              disabled={playedUnknown==='true'?true:null}
            />
          </MuiPickersUtilsProvider>

          </div>

          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
