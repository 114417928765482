import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import { project_url, admin_url } from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid, Typography, Box } from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import {
  GET_ALL_SLEAGUES_QUERY,
  GET_SLEAGUE_QUERY,
} from "../graphQL/sleague_qry";
import { GET_SLEAGUE_AWARD_QUERY } from "../graphQL/award_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";
var findAnd = require("find-and");
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

///#############

export default function SleagueAwardList() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  const [pageSize, setPageSize] = React.useState(10);

  const [awardsData, setAwardsData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);
  const [currentSleagueId, setCurrentSleagueId] = useState(0);
  const [sleagueSpMax, setSleagueSpMax] = useState(null);

  const navigate = useNavigate();
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchAwards] = useManualQuery(GET_SLEAGUE_AWARD_QUERY, {
    fetchPolicy: "network-only",
  });
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only",
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (
      leagueError &&
      leagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);

  const handleLeagueChange = async (e) => {
    setAwardsData(null);
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData,
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid),
      },
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSleagueChange = async (e) => {
    setAwardsData(null);

    const tid = e.target.value;
    setCurrentSleagueId(parseInt(tid));
    var resFound = findAnd.returnFound(sleaguesData, {
      sleagueId: tid,
    });
    if (resFound) setSleagueSpMax(resFound.spMax);

    const {
      loading: awardLoading,
      error: awardError,
      data: allAwardsData,
    } = await fetchAwards({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!awardLoading && allAwardsData && allAwardsData.sleagueAward) {
      setAwardsData(allAwardsData.sleagueAward);
    }
    if (
      awardError &&
      awardError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "name",
      headerName: "Award Name",
      width: 300,
      renderCell: (rows) => (
        <a
          href={`/lms/${admin_url}/sleague-award/edit/${rows.row.id}`}
          target="_self"
        >
          {rows.row.name}
        </a>
      ),
      editable: false,
    },

    {
      field: "sp",
      headerName: "Sport Points",
      width: 200,
      editable: false,
    },

    {
      field: "active",
      headerName: "Active",
      width: 200,
      type: "boolean",
      editable: false,
    },
  ];
  let rows = [];

  if (awardsData) {
    rows = awardsData.map((slt, id) => ({
      id: slt.awardId,
      name: slt.name,
      sp: slt.sp,
      active: slt.active,
    }));
  }

  return (
    <>
      <Helmet>
        <title>Predictal | Awards | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>List of Awards in League Seasons</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="league">
                League
              </label>
              <select
                className={adminStyle.select}
                id="league"
                {...register("league")}
                onChange={handleLeagueChange}
              >
                <option value="0">Select a League...</option>
                {leaguesData.map((league, i) => (
                  <option key={i} value={league.leagueId}>
                    {league.title}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} htmlFor="season">
                League Season
              </label>
              <select
                className={adminStyle.select}
                id="season"
                {...register("sleague")}
                onChange={handleSleagueChange}
              >
                <option value="0">Select a Season...</option>
                {sleaguesData.map((sleague, i) => (
                  <option key={i} value={sleague.sleagueId}>
                    {sleague.seasonName}
                  </option>
                ))}
              </select>
            </form>
          </Grid>
          <Grid item>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25]}
              pagination
            />
          </Grid>
          {sleagueSpMax != null && (
            <Grid item style={{display:"flex", flexDirection:'row'}}>
              <label className={adminStyle.label} htmlFor="season">
                PSP Max per player
              </label>
              <Box pl={25}>
              <label className={adminStyle.label} htmlFor="season">
                {sleagueSpMax}
              </label>
              </Box>
            </Grid>
          )}
          <Grid item>
            <Button
              className={adminStyle.button}
              variant="contained"
              color="primary"
              fullWidth={false}
              onClick={() => navigate(`/lms/${admin_url}/sleague-award/add`)}
            >
              {" "}
              + New{" "}
            </Button>
          </Grid>
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
