import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  useQuery,
  useManualQuery,
  useMutation,
  ClientContext,
} from "graphql-hooks";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  IconButton,
  Icon,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import KeyboardReturn from "@mui/icons-material/KeyboardBackspace";
import CheckIcon from "@mui/icons-material/Check";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import update from "immutability-helper";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import AlertDialog from "../../../components/AlertDialog.js";
import leagueLogo from "../../../assets/Premier_League-Logo.png";
import imgRes from "../../../assets/positionImgs/resMobile.png";
import footField from "../../../assets/positionImgs/football_field_mobile.png";
import delayedIcon from "../../../assets/delayed.png";
import { Selection } from "./Selection";
import { REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";
import { ItemTypes } from "../../../store/data/ItemTypes";
import { POSITIONS_DATA } from "../../../store/data/DummyDataMobile";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../graphQL/formationToLeague_qry";

import { GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../graphQL/gameStatAggr_qry";
import { GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../graphQL/positionPlayedAggr_qry";
import { GET_SETTING_BY_NAME } from "../../../graphQL/setting_qry";

import HeadToHeadDialog from "./HeadToHeadDialog.js";
import PlayerDataDialog from "./PlayerDataDialog.js";
import booked from "../../../assets/StatusImages/booked.gif";
import suspended from "../../../assets/StatusImages/suspended.gif";
import injured from "../../../assets/StatusImages/injured.png";
import unavailable from "../../../assets/StatusImages/unavailable.png";
import sick from "../../../assets/StatusImages/sick.png";
import available from "../../../assets/StatusImages/available.png";
import selected from "../../../assets/StatusImages/selected.png";
import ShareIcon from "@mui/icons-material/Share";
import ShareDialog from "../ShareDialog.js";

import PastFixtures from "./PastFixtures";

import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

var findAnd = require("find-and");

const colors = {
  //gray: "#c4c4c4",
  Goalkeeper: "#D9D9D9",
  GoalkeeperLight: "#F7F4F7",
  Defender: "#E1FAFF",
  DefenderLight: "#F3FCFE",
  Midfielder: "#D8FFE3",
  MidfielderLight: "#F2FFF6",
  Forward: "#FFD3DA",
  ForwardLight: "#FFF3F5",
};
const FIXTUREOBJTEAMS_QUERY = `query fixtureObjectTeams($fixtureId:Int!){
  fixtureObjectTeams(fixtureId:$fixtureId){
    fixtureTeamId
    fixtureId{
      fixtureId
      delayed
      lock
      fixtureWeek{
        scheduledDate
      }
      scheduledWeek{
        scheduledDate
      }
    }
    sleagueTeamId{
      sleagueTeamId
      teamId{
        name
        shortName
        logo
      }
    }
    scoreFinal
    home
    coachSeq
  }
}`;

const ADD_USER_TS = `mutation addUserTS($fixtureTeamId: Int!,  $userSeq: String!) {
  addUserTS(
    fixtureTeamId: $fixtureTeamId,
    userSeq: $userSeq,
  ) {
    userTS{
      userTSId
      userSeq
    }
  }
}`;

const UPDATE_USER_TS = `mutation updateUserTS($userTSId: Int!, $userSeq: String!) {
  updateUserTS(
    userTSId: $userTSId,
    userSeq: $userSeq,
  ) {
    userTS{
      userTSId
      userSeq
    }
  }
}`;
const useStyles = makeStyles({
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "90px",
    padding: 0,
    height: "28px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  formationButtonStyle: {
    borderRadius: 8,
    width: "60px",
    padding: 0,
    textAlign: "center",
    height: "28px",
    textTransform: "none",
    fontSize: "12px",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  // subheader: {
  //   color: "black",
  //   fontSize: 14,
  //   fontWeight: "bold",
  //   backgroundColor: "white",
  //   marginLeft: 0,
  // },
  // derivedItem: {
  //   fontSize: 14,
  //   paddingLeft: 30,
  // },
  // menuPaper: {
  //   maxHeight: 200,
  // },
});

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    PopperProps={{
      popperOptions: {
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: "20px, 0px, 20px, -25px",
          },
        },
      },
    }}
  />
))`
  & .MuiTooltip-tooltip {
    font-size: 14px;
  }
`;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TeamInfo = ({
  userData,
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqData,
  userSeqVal,
  coachSeqVal,
  userTSId,
  spAward,
  embargoTime,
  serverTime,
  sleaguePlayers,
  sleagueNowPastPlayers,
  sleagueTeamsData,
  pastFixtures,
  passTeamSelectionsData,
  passSelectedFormation,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // console.log("players_______league",sleaguePlayers)
  const [players, setPlayers] = useState([]);
  const [nowPastPlayers, setNowPastPlayers] = useState([]);
  const [fullDataPlayersList, setFullDataPlayersList] = useState([]);
  const [formations, setFormations] = useState([]);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [isOnshare, setIsOnshare] = useState(false);
  const [selectionCreateDate, setSelectionCreateDate] = useState("");
  const [delayed, setDelayed] = useState(false);
  const exportRef = useRef();

  const [disableTeamSelection, setDisableTeamSelection] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState("01");
  const [notSelectedPositions, setNotSelectedPositions] = useState([]);

  //   () => {
  //   let userSeq = userSeqVal.trim();
  //   let coachSeq = coachSeqVal.trim();
  //   if (userSeq !== "") return userSeq.slice(0, 2);
  //   else if (coachSeq !== "") return coachSeq.slice(0, 2);
  //   return "01";
  // });

  const [selectedFormationTitle, setSelectedFormationTitle] = useState("");
  const [fixDate, setFixDate] = useState("");
  const [fixDateGetTime, setFixDateGetTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [counterDate, setCounterDate] = useState("");
  const [competitor, setCompetitor] = useState([]);
  const [homeTeam, setHomeTeam] = useState([]);
  const [secondTeam, setSecondTeam] = useState([]);
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [teamSelections, setTeamSelections] = useState([]);
  const [alertMsg, setAlertMsg] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [fixtureTeamsData] = useManualQuery(FIXTUREOBJTEAMS_QUERY);
  const [addUserTeam] = useMutation(ADD_USER_TS);
  const [updateUserTeam] = useMutation(UPDATE_USER_TS);
  const [openFormationDialog, setOpenFormationDialog] = useState(false);
  const [openAddPlayerDialog, setOpenAddPlayerDialog] = useState(false);
  const [formationPosTxt, setFormationPosTxt] = useState("");
  const [positionToBeSelected, setPositionToBeSelected] = useState([]);
  const [h2HSelected, setH2HSelected] = useState(false);
  const [playerDataSelected, setPlayerDataSelected] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [h2HList, setH2HList] = useState([]);
  const [openH2HDialog, setOpenH2HDialog] = useState(false);
  const [pathname] = useState(window.location.pathname);
  const [displayConfetti, setDisplayConfetti] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [copyPastFixtureData, setCopyPastFixtureData] = useState("");
  const [spMax, setSpMax] = useState(0);
  const { width, height } = useWindowSize();

  const [sleagueTeamGameStatsAggr, setSleagueTeamGameStatsAggr] = useState([]);
  const [
    sleagueTeamPositionPlayedAggr,
    setSleagueTeamPositionPlayedAggr,
  ] = useState([]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [sleagueTeamPlayer] = useManualQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
  //   fetchPolicy: "network-only"
  // });

  const [fetchSleagueTeamGameStatAggrs] = useManualQuery(
    GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [fetchSleagueTeamPosPlayedAggrs] = useManualQuery(
    GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const formatter = new Intl.DateTimeFormat("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",
  });

  const dayFormat = (day) => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")) {
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format === "DDmonthYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "day").value +
        " " +
        dayFormat.find((a) => a.type == "month").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else if (format === "monthDDYYYY") {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value;
      return newFormat;
    } else {
      const newFormat =
        dayFormat.find((a) => a.type == "weekday").value +
        ", " +
        dayFormat.find((a) => a.type == "year").value +
        ", " +
        dayFormat.find((a) => a.type == "month").value +
        " " +
        dayFormat.find((a) => a.type == "day").value;
      return newFormat;
    }
  };
  const timeFormat = (dayDate) => {
    const tFormat = formatter.formatToParts(dayDate);
    const newFormat =
      tFormat.find((a) => a.type == "hour").value +
      ":" +
      tFormat.find((a) => a.type == "minute").value +
      " " +
      tFormat.find((a) => a.type == "dayPeriod").value +
      " (" +
      tFormat.find((a) => a.type == "timeZoneName").value +
      ")";
    return newFormat;
  };
  const mainPath = "/lms/media/";

  const {
    loading: loadingConfettiScore,
    error: errorConfettiScore,
    data: dataConfettiScore,
  } = useQuery(GET_SETTING_BY_NAME, {
    fetchPolicy: "network-only",
    variables: {
      settingName: "Confetti Score",
    },
  });
  useEffect(() => {
    console.log(spAward);
    if (
      !loadingConfettiScore &&
      dataConfettiScore &&
      dataConfettiScore.settingByName.allValues
    ) {
      if (
        parseInt(spAward) >=
        parseInt(dataConfettiScore.settingByName.allValues[0].value)
      ) {
        setDisplayAlert(true);
      }
    }
  });

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: leagueId,
    },
  });
  useEffect(() => {
    setPlayers(
      sleaguePlayers.map((player) => {
        let sleagueTeamPlayerId = player.sleagueTeamPlayerId;
        let name = player.playerId.name;
        let onFieldName = player.playerId.onFieldName;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        let playerId = player.playerId;
        return {
          name,
          onFieldName,
          sleagueTeamPlayerId,
          playerId,
          position,
          jerseyNO,
          playerSeqNO,
          type,
          status,
        };
      })
    );
    console.log(displayAlert);
    if (displayAlert) {
      setDisplayAlert(false);
      setTimeout(() => {
        alert(
          "Congratulations! You earned " +
            spAward +
            " Sport Points (SP) out of a maximum " +
            spMax +
            " for your starting line-up team that highly matches the Coach Team."
        );
        setTimeout(() => {
          setDisplayConfetti(false);
        }, 10000);
      }, 100);
      setDisplayConfetti(true);
    }
  }, [sleaguePlayers]);

  useEffect(() => {
    console.log("sleagueNowPastPlayers_______", sleagueNowPastPlayers);
    setNowPastPlayers(
      sleagueNowPastPlayers.map((player) => {
        let sleagueTeamPlayerId = player.sleagueTeamPlayerId;
        let name = player.playerId.name;
        let onFieldName = player.playerId.onFieldName;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        let status = player.status;
        let playerId = player.playerId;
        let currentTeam = player.currentTeam;
        return {
          name,
          onFieldName,
          sleagueTeamPlayerId,
          playerId,
          position,
          jerseyNO,
          playerSeqNO,
          type,
          status,
          currentTeam,
        };
      })
    );
  }, [sleagueNowPastPlayers]);
  useEffect(() => {
    let userSeq = userSeqVal.trim();
    let coachSeq = coachSeqVal.trim();
    if (userSeq !== "") setSelectedFormation(userSeq.slice(0, 2));
    else if (coachSeq !== "") setSelectedFormation(coachSeq.slice(0, 2));
    else setSelectedFormation("01");
  }, [coachSeqVal, userSeqVal]);

  useEffect(() => {
    if ((disableTeamSelection || isLocked) && userSeqVal === "")
      setSelectedFormation("");
  }, [isLocked, disableTeamSelection, userSeqVal]);

  useEffect(() => {
    let findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let droppedPlayerNamesVal = [];
    let draftTeamSelections = findPositionsData.positionsData;
    let val = "";
    let seqVal = userSeqVal;
    let playersData = nowPastPlayers;
    if (!seqVal && !copyPastFixtureData) return;
    if (copyPastFixtureData) {
      seqVal = copyPastFixtureData;
      playersData = players;
    }
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i) => {
        switch (i) {
          case "01":
            val = seqVal.slice(4, 6);
            break;
          case "02":
            val = seqVal.slice(8, 10);
            break;
          case "03":
            val = seqVal.slice(12, 14);
            break;
          case "04":
            val = seqVal.slice(16, 18);
            break;
          case "05":
            val = seqVal.slice(20, 22);
            break;
          case "06":
            val = seqVal.slice(24, 26);
            break;
          case "07":
            val = seqVal.slice(28, 30);
            break;
          case "08":
            val = seqVal.slice(32, 34);
            break;
          case "09":
            val = seqVal.slice(36, 38);
            break;
          case "10":
            val = seqVal.slice(40, 42);
            break;
          case "11":
            val = seqVal.slice(44, 46);
        }
        resFound = findAnd.returnFound(playersData, {
          playerSeqNO: val ? val : "", // "" to support "" playerSeqNO case,
        });
        //console.log('resFound____mobile',resFound)

        if (
          copyPastFixtureData &&
          resFound &&
          resFound.status !== "Fit" &&
          resFound.status !== "Booked"
        )
          return;
        // for that case that found more than one player with this playerSeqNO(val)
        if (resFound && resFound.length > 1) {
          let resFoundTrueCurentTeam = findAnd.returnFound(resFound, {
            currentTeam: true,
          });
          resFound = resFoundTrueCurentTeam;
        }

        if (resFound) {
          // for that case that found more than one player with this playerSeqNO(val) and currentTeam == true
          if (resFound.length > 1) resFound = resFound[0];
          let resForPos = findAnd.returnFound(draftTeamSelections, {
            positionSeqNo: i,
          });

          draftTeamSelections = findAnd.changeProps(
            draftTeamSelections,
            { positionSeqNo: i },
            {
              lastDroppedItem: {
                name: resFound.name,
                onFieldName: resFound.onFieldName,
                playerSeqNO: resFound.playerSeqNO,
                jerseyNO: resFound.jerseyNO,
                formationPos: resForPos && resForPos.text,
              },
            }
          );
          droppedPlayerNamesVal.push(resFound.name);
        }
      }
    );

    let resForPos = findAnd.returnFound(draftTeamSelections, {
      lastDroppedItem: null,
    });

    let notSelectedPositions = [];
    if (resForPos && resForPos.text) notSelectedPositions.push(resForPos.text);
    else if (resForPos && resForPos.length > 0)
      resForPos.map((item) => {
        if (item.text) notSelectedPositions.push(item.text);
        for (var j = 0; j < 6; j++)
          if (item[j]) notSelectedPositions.push(item[j].text);
      });

    console.log("droppedPlayerNamesVal", droppedPlayerNamesVal);
    console.log("draftTeamSelections", draftTeamSelections);
    setDroppedPlayerNames(droppedPlayerNamesVal);
    setTeamSelections(draftTeamSelections);
    passTeamSelectionsData(droppedPlayerNamesVal, draftTeamSelections);
    setNotSelectedPositions(notSelectedPositions);
  }, [nowPastPlayers, copyPastFixtureData]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {
      leagueFormationsData.leagueFormation.map((leagueFrm) => {
        if (
          !parentFormations.includes(leagueFrm.formationId.formationPrimary)
        ) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(
          (frm) => {
            return frm.formationId.formationPrimary === formation;
          }
        );
        formationsList[i].derivedCategories = derivedFormations;
      });

      var resFound = findAnd.returnFound(formationsList, {
        formationSeqNO: selectedFormation,
      });
      if (resFound) setSelectedFormationTitle(resFound.title);
      setFormations(formationsList);
    }
  }, [leagueFormationsData, selectedFormation]);

  function RenderPlayerList(props) {
    const { item } = props;
    //-> players = sleaguePlayers
    return players.map(
      (
        {
          playerId,
          name,
          onFieldName,
          position,
          jerseyNO,
          playerSeqNO,
          status,
        },
        index
      ) => {
        if (item === position) {
          let resFound = findAnd.returnFound(teamSelections, {
            name: name,
          });
          let odd = index % 2 == 1;
          const labelId = `${playerSeqNO}-${index}`;
          return (
            <ListItem
              key={labelId}
              secondaryAction={
                <>
                  {droppedPlayerNames.indexOf(name) !== -1 &&
                    !h2HSelected &&
                    !playerDataSelected && (
                      <img
                        style={{ paddingLeft: 5, paddingTop: 11 }}
                        src={selected}
                      />
                    )}
                </>
              }
              disablePadding
            >
              <ListItemButton
                style={{
                  backgroundColor: colors[item + "Light"],
                  height: 35,
                  paddingRight: 40,
                }}
                onClick={() =>
                  handleTogglePlayer(name, onFieldName, playerSeqNO, jerseyNO)
                }
              >
                <ListItemText
                  id={labelId}
                  primaryTypographyProps={{
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                  primary={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: 25 }}>{jerseyNO}</div>
                      <div style={{ width: "60%" }}>{name}</div>
                      {h2HSelected &&
                      (h2HList[0] === playerSeqNO ||
                        h2HList[1] === playerSeqNO) ? (
                        <div
                          style={{
                            width: 70,
                            textAlign: "right",
                            fontSize: 10,
                            fontWeight: 400,
                            color: "#212121",
                            letterSpacing: 0.4,
                          }}
                        >
                          H2H
                        </div>
                      ) : (
                        <div
                          style={{
                            width: 70,
                            textAlign: "right",
                            fontSize: 10,
                            fontWeight: 400,
                            color: "#212121",
                            letterSpacing: 0.4,
                          }}
                        >
                          {!h2HSelected &&
                            !playerDataSelected &&
                            resFound &&
                            resFound.formationPos}
                        </div>
                      )}
                      <div style={{ paddingLeft: 5, height: 20 }}>
                        {status === "Unavailable" && (
                          <img
                            style={{ paddingLeft: 5, height: 20 }}
                            src={unavailable}
                          />
                        )}
                        {status === "Fit" && (
                          <img
                            style={{ paddingLeft: 5, height: 20 }}
                            src={available}
                          />
                        )}
                        {status === "Booked" && (
                          <img
                            style={{ paddingLeft: 7, height: 20 }}
                            src={booked}
                          />
                        )}
                        {status === "Suspended" && (
                          <img
                            style={{ paddingLeft: 7, height: 20 }}
                            src={suspended}
                          />
                        )}
                        {status === "Injured" && (
                          <img
                            style={{ paddingLeft: 5, height: 20 }}
                            src={injured}
                          />
                        )}
                        {status === "Sick" && (
                          <img
                            style={{ paddingLeft: 5, height: 20 }}
                            src={sick}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        }
      }
    );
  }

  function RenderFormatonList(props) {
    const { derivedCategories } = props;
    if (disableTeamSelection || isLocked) return;
    return derivedCategories.map((formation, index) => {
      const derivedLabelId = `${formation.formationId.formationSeqNO}-{index}`;
      let odd = index % 2 == 1;
      return (
        <ListItem
          key={derivedLabelId}
          secondaryAction={
            <>
              {selectedFormation === formation.formationId.formationSeqNO && (
                <img
                  style={{ paddingLeft: 5, paddingTop: 2, height: 20 }}
                  src={selected}
                />
              )}
            </>
          }
          disablePadding
        >
          <ListItemButton
            style={{
              backgroundColor: odd ? "#FFFFFF" : "#FBF3FF",
              // height: 35,
            }}
            onClick={() =>
              handleFormationChange(formation.formationId.formationSeqNO)
            }
          >
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 400,
                fontSize: 16,
                paddingLeft: 2,
                lineHeight: 2,
              }}
              id={derivedLabelId}
              primary={formation.formationId.title}
            />
          </ListItemButton>
        </ListItem>
      );
    });
  }

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 500 milliseconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 200);
        loadImg.onerror = (err) => {
          reject(err);
        };
      });
    };
    let IMAGES = [];
    if (teamSelections) {
      if (teamSelections.GK) IMAGES.push(imgRes, teamSelections.GK[0].img);
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_1)
        teamSelections.DEF.defLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_2)
        teamSelections.DEF.defLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_1)
        teamSelections.MID.midLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_2)
        teamSelections.MID.midLane_col_2.map((i) => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_3)
        teamSelections.MID.midLane_col_3.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_1)
        teamSelections.FOR.forLane_col_1.map((i) => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_2)
        teamSelections.FOR.forLane_col_2.map((i) => IMAGES.push(i.img));
    }
    if (IMAGES.length == 0) return;
    Promise.all(IMAGES.map((img) => loadImage(img)))
      .then(() => setIsLoad(true))
      .catch((err) => console.log("Failed to load images", err));
  }, [teamSelections]);

  useEffect(async () => {
    let { loading, error, data } = await fixtureTeamsData({
      variables: {
        fixtureId: fixtureId,
      },
    });
    // Set User Team Data
    if (!loading && data && data.fixtureObjectTeams) {
      setDelayed(data.fixtureObjectTeams[0].fixtureId.delayed);
      data.fixtureObjectTeams.map((fixtureTeam) => {
        // Set User team data
        if (
          parseInt(fixtureTeam.sleagueTeamId.sleagueTeamId) === sleagueTeamId
        ) {
          if (fixtureTeam.home) {
            console.log("fixtureTeam.home__________", fixtureTeam);
            setHomeTeam([userFavTeam.teamInfo]);
          } else setSecondTeam([userFavTeam.teamInfo]);

          var options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            //second: "2-digit",
            hour12: true,
          };
          // convert to milliseconds
          //check embargo time with server time
          let diff =
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() - serverTime.getTime();
          if (
            serverTime.getTime() >
            new Date(
              fixtureTeam.fixtureId.fixtureWeek.scheduledDate
            ).getTime() -
              (embargoTime && embargoTime.value) * 3600000
          ) {
            setCounterDate(diff);
          } else {
            setCounterDate(
              new Date(
                fixtureTeam.fixtureId.fixtureWeek.scheduledDate
              ).getTime() -
                (embargoTime && embargoTime.value) * 3600000
            );
          }
          var fixDate = new Date(
            fixtureTeam.fixtureId.fixtureWeek.scheduledDate
          ).toLocaleTimeString("en-us", options);
          setFixDateGetTime(
            new Date(fixtureTeam.fixtureId.fixtureWeek.scheduledDate).getTime()
          );
          var formatedTime =
            fixDate.split(" ")[5] + " " + fixDate.split(" ")[6]; //time + " " + pam;
          setFixDate(
            dayFormat(fixtureTeam.fixtureId.fixtureWeek.scheduledDate)
          ); //formatedDate);
          setIsLocked(fixtureTeam.fixtureId.lock);
          //setDisableTeamSelection(fixtureTeam.fixtureId.lock); //false);
          setStartTime(formatedTime);
          setSelectionCreateDate(
            dayFormat(new Date()) + "  " + timeFormat(new Date())
          );
        }
        // Set User team competitor for that fixtureId
        else {
          if (fixtureTeam.home) setHomeTeam([fixtureTeam.sleagueTeamId.teamId]);
          setCompetitor([fixtureTeam.sleagueTeamId.teamId]);
        }
      });
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureId, embargoTime, userFavTeam]);

  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingGSAggr,
        error: errorGSAggr,
        data: dataGSAggr,
      } = await fetchSleagueTeamGameStatAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      if (!loadingGSAggr && dataGSAggr && dataGSAggr.gameStatAggrSleagueteam) {
        setSleagueTeamGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
        console.log("sleagueTeamGameStatsAggrData----", dataGSAggr);
      }
    }
  }, [userFavTeam]);

  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingPosPlayedAggr,
        error: errorPosPlayedAggr,
        data: dataPosPlayedAggr,
      } = await fetchSleagueTeamPosPlayedAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      if (
        !loadingPosPlayedAggr &&
        dataPosPlayedAggr &&
        dataPosPlayedAggr.positionPlayedAggrSleagueteam
      ) {
        setSleagueTeamPositionPlayedAggr(
          dataPosPlayedAggr.positionPlayedAggrSleagueteam
        );
        console.log("sleagueTeamPositionPlayedAggr----", dataPosPlayedAggr);
      }
    }
  }, [userFavTeam]);

  useEffect(() => {
    // Updating players by data read from game_stat_aggr table
    if (
      players.length > 0 &&
      sleagueTeamGameStatsAggr.length > 0 &&
      sleagueTeamPositionPlayedAggr.length > 0
    ) {
      let foundGameStatAggr = {};
      let foundPlayedPositionAggr = {};

      players.map((player) => {
        foundGameStatAggr = sleagueTeamGameStatsAggr.find(
          (item) =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        if (foundGameStatAggr) {
          player["gamesInfo"] = {
            full: {
              gamesNo: foundGameStatAggr.fullGame,
              minutes: foundGameStatAggr.fullMin,
            },
            eject: {
              gamesNo: foundGameStatAggr.ejectGame,
              minutes: foundGameStatAggr.ejectMin,
            },
            subIn: {
              gamesNo: foundGameStatAggr.subinGame,
              minutes: foundGameStatAggr.subinMin,
            },
            subOut: {
              gamesNo: foundGameStatAggr.suboutGame,
              minutes: foundGameStatAggr.suboutMin,
            },
            subInOut: {
              gamesNo: foundGameStatAggr.subInOutGame,
              minutes: foundGameStatAggr.subInOutMin,
            },
            total: {
              gamesNo: foundGameStatAggr.totalGame,
              minutes: foundGameStatAggr.totalMin,
            },
            perGame: foundGameStatAggr.avgMinPerGame,
          };

          player["stats"] = {
            games: foundGameStatAggr.totalGame,
            ejected: foundGameStatAggr.ejectGame,
            goals: foundGameStatAggr.goal,
            ownGoals: foundGameStatAggr.ownGoal,
            yc: foundGameStatAggr.cardYellow,
            rc: foundGameStatAggr.cardRed,
          };
        } else {
          player["gamesInfo"] = {
            full: {
              gamesNo: 0,
              minutes: 0,
            },
            eject: {
              gamesNo: 0,
              minutes: 0,
            },
            subIn: {
              gamesNo: 0,
              minutes: 0,
            },
            subOut: {
              gamesNo: 0,
              minutes: 0,
            },
            subInOut: {
              gamesNo: 0,
              minutes: 0,
            },
            total: {
              gamesNo: 0,
              minutes: 0,
            },
            perGame: 0,
          };

          player["stats"] = {
            games: 0,
            ejected: 0,
            goals: 0,
            ownGoals: 0,
            yc: 0,
            rc: 0,
          };
        }
      });
      players.map((player) => {
        foundPlayedPositionAggr = sleagueTeamPositionPlayedAggr.find(
          (item) =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        if (foundPlayedPositionAggr) {
          player["counts"] = {
            gkCount: foundPlayedPositionAggr.GKCount,
            ldefCount: foundPlayedPositionAggr.LDEFCount,
            cdefCount: foundPlayedPositionAggr.CDEFCount,
            rdefCount: foundPlayedPositionAggr.RDEFCount,
            lmfCount: foundPlayedPositionAggr.LMFCount,
            cmfCount: foundPlayedPositionAggr.CMFCount,
            rmfCount: foundPlayedPositionAggr.RMFCount,
            lforCount: foundPlayedPositionAggr.LFORCount,
            cforCount: foundPlayedPositionAggr.CFORCount,
            rforCount: foundPlayedPositionAggr.RFORCount,
          };
        } else {
          player["counts"] = {
            gkCount: 0,
            ldefCount: 0,
            cdefCount: 0,
            rdefCount: 0,
            lmfCount: 0,
            cmfCount: 0,
            rmfCount: 0,
            lforCount: 0,
            cforCount: 0,
            rforCount: 0,
          };
        }
      });
      players.sort(function (a, b) {
        if (a.gamesInfo && b.gamesInfo) {
          return (
            parseInt(b.gamesInfo.total.gamesNo) -
            parseInt(a.gamesInfo.total.gamesNo)
          );
        }
      });
      setFullDataPlayersList(players);
      console.log("FullDataPlayersList_____", players);
    }
  }, [sleagueTeamGameStatsAggr, sleagueTeamPositionPlayedAggr, players]);
  useEffect(() => {
    if (!fixDateGetTime) return;
    // Post Fixture
    var timeDiff = serverTime.getTime() /*Date.now()*/ > fixDateGetTime;
    console.log("timeDiff____", timeDiff);
    if (isLocked && disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot edit your Starting XI team. "
        );
      else {
        setAlertMsg(
          "This fixture is in embargo at this time. You cannot select your Starting XI team."
        );
      }
    }
    if (isLocked && !disableTeamSelection && !timeDiff) {
      if (userSeqVal)
        setAlertMsg(
          "This fixture is locked now. You cannot edit your Starting XI team."
        );
      else {
        setAlertMsg(
          "This fixture is locked now. You cannot select your Starting XI team."
        );
      }
    }
    if (!isLocked && disableTeamSelection && !timeDiff)
      setAlertMsg(
        "This fixture is in embargo at this time. You cannot create or edit your Starting XI team. "
      );
  }, [disableTeamSelection, isLocked, userSeqVal, fixDateGetTime]);
  useEffect(() => {
    const findPositionsData = POSITIONS_DATA.find((x) => {
      return x.formationSeqNo === selectedFormation;
    });
    if (findPositionsData) {
      let resFound = [];
      let draftTeamSelections = findPositionsData.positionsData;

      // find position seq no from previous selected formation and put in new formation dropped item
      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
        (item) => {
          resFound = findAnd.returnFound(teamSelections, {
            positionSeqNo: item,
          });

          if (resFound) {
            draftTeamSelections = findAnd.changeProps(
              draftTeamSelections,
              { positionSeqNo: item },
              { lastDroppedItem: resFound.lastDroppedItem }
            );
          }
        }
      );

      setTeamSelections(draftTeamSelections);
      passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
    }
  }, [selectedFormation]);

  //const { loading, error, data } = useQuery(GET_CURRENT_SLEAGUES_QUERY);

  const handleFormationChange = (formation) => {
    var resFound = findAnd.returnFound(formations, {
      formationSeqNO: formation,
    });
    if (
      droppedPlayerNames.length > 0 &&
      resFound &&
      selectedFormationTitle !== resFound.title
    )
      setAlertMsg(
        `You changed the team formation from ${selectedFormationTitle} to ${resFound.title}. Check players' new positions on the Team Selection as you may want to move them into other positions in the new formation.`
      );
    setSelectedFormation(formation);
    setIsLoad(false);
    passSelectedFormation(formation);
  };

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  const setSelectedPlayer = (name, onFieldName, playerSeqNO, jerseyNO) => {
    if (positionToBeSelected.length > 0) {
      // console.log(
      //   "setSelectedPlayer______",
      //   positionToBeSelected,
      //   droppedPlayerNames,
      //   teamSelections,
      //   openAddPlayerDialog,
      //   positionToBeSelected
      // );
      let index = positionToBeSelected[0];
      let pos = positionToBeSelected[1];
      let posLane = positionToBeSelected[2];
      let formationPos = positionToBeSelected[3];

      if (disableTeamSelection || isLocked) return;
      let resFound = findAnd.returnFound(players, {
        playerSeqNO: playerSeqNO,
      });
      if (resFound && resFound.status !== "Fit" && resFound.status !== "Booked")
        return;
      let draftTeamSelections = [];

      if (droppedPlayerNames.indexOf(name) > -1) return;
      // If a player is dropped into the position of another player, delete the previous player
      if (pos === "GK") {
        if (teamSelections[pos][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [index]: {
              lastDroppedItem: {
                $set: {
                  name,
                  onFieldName,
                  playerSeqNO,
                  jerseyNO,
                  formationPos,
                },
              },
            },
          },
        });
      } else {
        if (teamSelections[pos][posLane][index].lastDroppedItem) {
          var i = droppedPlayerNames.indexOf(
            teamSelections[pos][posLane][index].lastDroppedItem.name
          );
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
        draftTeamSelections = update(teamSelections, {
          [pos]: {
            [posLane]: {
              [index]: {
                lastDroppedItem: {
                  $set: {
                    name,
                    onFieldName,
                    playerSeqNO,
                    jerseyNO,
                    formationPos,
                  },
                },
              },
            },
          },
        });
      }
      let draftDroppedPlayerNames = update(
        droppedPlayerNames,
        name ? { $push: [name /*, playerSeqNO*/] } : { $push: [] }
      );
      // console.log(
      //   "draftDroppedPlayerNames____set",
      //   draftDroppedPlayerNames,
      //   draftTeamSelections,
      // );
      if (!h2HSelected) {
        setDroppedPlayerNames(draftDroppedPlayerNames);
        setTeamSelections(draftTeamSelections);
      }
      if (h2HSelected)
        passTeamSelectionsData(droppedPlayerNames, teamSelections);
      else passTeamSelectionsData(draftDroppedPlayerNames, draftTeamSelections);
      //  setPositionToBeSelected([]);
    }
  };

  const onRemoveDroppedItem = (name) => {
    // remove player from droppedPlayerNames list
    if (positionToBeSelected.length > 0) {
      let posIdx = positionToBeSelected[0];
      let pos = positionToBeSelected[1];
      let posLane = positionToBeSelected[2];
      // set lastDroppedItem to null for removed item
      let draftTeamSelections = [];

      if (pos === "GK") {
        if (
          teamSelections.GK[0] &&
          teamSelections.GK[0].lastDroppedItem.name === name &&
          posIdx === 0
        ) {
          draftTeamSelections = update(teamSelections, {
            [pos]: {
              [0]: {
                lastDroppedItem: {
                  $set: null,
                },
              },
            },
          });
          setTeamSelections(draftTeamSelections);
          var i = droppedPlayerNames.indexOf(name);
          i > -1 && droppedPlayerNames.splice(i, 1);
        }
      } else {
        teamSelections[pos][posLane] &&
          teamSelections[pos][posLane].map(({ lastDroppedItem }, index) => {
            if (
              lastDroppedItem &&
              lastDroppedItem.name === name &&
              index === posIdx
            ) {
              draftTeamSelections = update(teamSelections, {
                [pos]: {
                  [posLane]: {
                    [index]: {
                      lastDroppedItem: {
                        $set: null,
                      },
                    },
                  },
                },
              });
              setTeamSelections(draftTeamSelections);
              var i = droppedPlayerNames.indexOf(name);
              i > -1 && droppedPlayerNames.splice(i, 1);
            }
          });
      }
      // console.log(
      //   "draftTeamSelections___droppedPlayerNames",
      //   draftTeamSelections,
      //   droppedPlayerNames
      // );
      if (draftTeamSelections.length > 0) {
        passTeamSelectionsData(droppedPlayerNames, draftTeamSelections);
        //setPositionToBeSelected([]);
      }
    }
  };
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setDisableTeamSelection(true);
    }
    return (
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box
          ml={1}
          style={{
            display: "flex",
            flexDirection: "row",
            width: 160,
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: 1.1 }}
            >
              {days.toString().length > 2 ? days : ("0" + days).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              //  variant="h6"
              style={{
                fontSize: 8,
                fontWeight: 700,
                //paddingLeft: 2,
                color: "#4D4D4D",
                textAlign: "left",
              }}
            >
              Days
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: 1.1 }}
            >
              {("0" + hours).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              //  variant="h6"
              style={{
                fontSize: 8,
                fontWeight: 700,
                //paddingLeft: 2,
                color: "#4D4D4D",
                textAlign: "left",
              }}
            >
              Hrs
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: 1.1 }}
            >
              {" "}
              {("0" + minutes).slice(-2)}
              {" : "}
            </Typography>
            <Typography
              //  variant="h6"
              style={{
                fontSize: 8,
                fontWeight: 700,
                //paddingLeft: 2,
                color: "#4D4D4D",
                textAlign: "left",
              }}
            >
              Mins
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, lineHeight: 1.1 }}
            >
              {("0" + seconds).slice(-2)}{" "}
            </Typography>
            <Typography
              //variant="h6"
              style={{
                fontSize: 8,
                fontWeight: 700,
                //paddingLeft: 2,
                color: "#4D4D4D",
                textAlign: "left",
              }}
            >
              Secs
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            //  variant="h6"
            style={{
              fontSize: 8,
              fontWeight: 400,
              letterSpacing: 0.7,
              color: "#757575",
            }}
          >
            Time left to select your starting team
          </Typography>
        </Box>
      </Box>
    );
  };

  const submitHandler = async (e) => {
    // alert(fixtureTeamId);
    // alert(userSeqData);
    if (Object.keys(userSeqData).length === 0 || userSeqData.includes("[00]")) {
      await setAlertMsg(
        "You must complete your Starter XI team. Please select all 11 players."
      );
      return;
    }

    localStorage.removeItem("pastFixData");
    let userSeqStrVal = userSeqData.replace(/</g, "");
    userSeqStrVal = userSeqStrVal.replace(/>/g, "");
    userSeqStrVal = userSeqStrVal.replace(/\[/g, "");
    userSeqStrVal = userSeqStrVal.replace(/]/g, "");
    // alert(userSeqStrVal);

    if (userTSId !== 0) {
      let { data, error } = await updateUserTeam({
        variables: {
          userTSId: userTSId,
          userSeq: userSeqStrVal,
        },
      });
      if (error) {
        console.log("Error", error, data);
        alert("There is an error");
      } else {
        console.log("Success");
        await setAlertMsg("User Team Selection has been updated successfully.");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    } else {
      let { data, error } = await addUserTeam({
        variables: {
          fixtureTeamId: fixtureTeamId,
          profileId: parseInt(userData.id),
          userSeq: userSeqStrVal,
        },
      });
      if (error) {
        console.log("Error", error, data);
        alert("There is an error");
      } else {
        console.log("Success");
        await setAlertMsg("User Team Selection has been added successfully.");
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    }
  };

  const clearHandler = async (e) => {
    localStorage.removeItem("pastFixData");
    let clearUsrSeq = userSeqData.slice(2, 4);
    let draftTeamSelections = teamSelections;
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(
      (i) => {
        draftTeamSelections = findAnd.changeProps(
          draftTeamSelections,
          { positionSeqNo: i },
          { lastDroppedItem: null }
        );
        clearUsrSeq += i + "00";
      }
    );
    setTeamSelections(draftTeamSelections);
    setDroppedPlayerNames([]);
    await passTeamSelectionsData([], draftTeamSelections);
  };
  const handleOpenShareDialog = (e) => {
    setOpenShareDialog(true);
    setIsOnshare(true);
  };
  const handleCloseShareDialog = (e) => {
    setOpenShareDialog(false);
    setIsOnshare(false);
  };
  const handleClickOpenFormationDialog = () => {
    setOpenFormationDialog(true);
  };

  const handleCloseFormationDialog = () => {
    setOpenFormationDialog(false);
  };
  const handleClickOpenAddPlayerDialog = (index, pos, posLane, text) => {
    if (disableTeamSelection || isLocked) return;
    setOpenAddPlayerDialog(true);
    setFormationPosTxt(text);
    setPositionToBeSelected([index, pos, posLane, text]);
  };

  const handleCloseAddPlayerDialog = () => {
    setOpenAddPlayerDialog(false);
    setH2HSelected(false);
    setH2HList([]);
    setFormationPosTxt("");
    setPositionToBeSelected([]);
    if (notSelectedPositions.length > 0) {
      let msg = createNotSelectedPosAlertMsg(notSelectedPositions);
      if (droppedPlayerNames.length > 0) setAlertMsg(msg);
      setNotSelectedPositions([]);
    }
  };

  const handleCloseH2HDialog = () => {
    setH2HList([]);
    //  setH2HSelected(false);
    setOpenH2HDialog(false);
  };
  const handleClosePlayerDataDialog = () => {
    //setPlayerDataSelected(false);
    setPlayerData(null);
  };
  const handleTogglePlayer = (name, onFieldName, playerSeqNO, jerseyNO) => {
    if (playerDataSelected) {
      let resFound = findAnd.returnFound(players, {
        playerSeqNO: playerSeqNO,
      });
      if (resFound) setPlayerData(resFound);
      return;
    }
    if (h2HSelected) {
      const h2hIndex = h2HList.indexOf(playerSeqNO);
      setH2HList(
        update(
          h2HList,
          h2hIndex > -1
            ? { $splice: [[h2hIndex, 1]] }
            : { $push: [playerSeqNO] }
        )
      );
      if (h2hIndex === -1 && h2HList.length === 1) {
        setOpenH2HDialog(true);
      }
      return;
    }

    const currentIndex = droppedPlayerNames.indexOf(name);
    if (currentIndex === -1) {
      setSelectedPlayer(name, onFieldName, playerSeqNO, jerseyNO);
    } else onRemoveDroppedItem(name);
  };

  const getCopyPastFixtureData = async (copyPastFixtureDataTS) => {
    if (copyPastFixtureDataTS === "clear") {
      localStorage.removeItem("pastFixData");
      let userSeq = userSeqVal.trim();
      if (userSeq !== "") {
        await setSelectedFormation(userSeq.slice(0, 2));
        passSelectedFormation(userSeq.slice(0, 2));
      } else clearHandler();
      setCopyPastFixtureData("");
      return;
    }
    if (copyPastFixtureDataTS) {
      console.log("copyPastFixtureDataTS____", copyPastFixtureDataTS);
      let copyPastFixture = copyPastFixtureDataTS.trim();
      if (copyPastFixture !== "") {
        await setSelectedFormation(copyPastFixture.slice(0, 2));
        passSelectedFormation(copyPastFixture.slice(0, 2));
        setCopyPastFixtureData(copyPastFixture);
      }
    }
  };

  const createNotSelectedPosAlertMsg = (notSelPosArr) => {
    let singleMsgCommonPart = "Please select a player in position ";
    let notSingleMsgCommonPart = "Please select players in positions ";

    if (notSelPosArr.length <= 1) {
      return singleMsgCommonPart + notSelPosArr[0] + ".";
    }
    return (
      notSingleMsgCommonPart +
      notSelPosArr.slice(0, -1).join(", ") +
      " and " +
      notSelPosArr.slice(-1) +
      "."
    );
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div ref={exportRef}>
        <Grid container spacing={0}>
          <Grid item>
            {displayConfetti && <Confetti width={width} height={height} />}
            <Box>
              <StyledTooltip title="Return to Fixtures">
                <IconButton
                  edge="start"
                  color="primary"
                  onClick={() => {
                    navigate("/lms/fixtures");
                    localStorage.removeItem("pastFixData");
                  }}
                  size="large"
                  style={{
                    display: openShareDialog === true ? "none" : "flex",
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: 0,
                  }}
                >
                  <KeyboardReturn
                    sx={{ color: "#662483" }}
                    fontSize="inherit"
                  />
                </IconButton>
              </StyledTooltip>
            </Box>
          </Grid>
          <Grid item>
            <Box
              mt={1.5}
              style={{
                display: openShareDialog === true ? "none" : "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              {selectedFormationTitle ? (
                <Box ml={1}>
                  <Button
                    variant="contained"
                    disabled={disableTeamSelection || isLocked}
                    onClick={handleClickOpenFormationDialog}
                    color="primary"
                    className={classes.formationButtonStyle}
                  >
                    {selectedFormationTitle}
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="text"
                  disabled={disableTeamSelection || isLocked}
                  onClick={handleClickOpenFormationDialog}
                  color="primary"
                  className={classes.outlinedButtonStyle}
                  style={{ width: "90px", paddingRight: 20 }}
                >
                  Formation
                </Button>
              )}

              <Dialog
                fullScreen
                open={openFormationDialog}
                onClose={handleCloseFormationDialog}
                //  TransitionComponent={Transition}
              >
                <AppBar
                  position="sticky"
                  sx={{
                    bgcolor: "white",
                    height: 56,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Toolbar>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: { xs: "flex", md: "none" },
                      }}
                    >
                      <StyledTooltip title="Back to Starting Lineup">
                        <IconButton
                          edge="start"
                          //  color="success"
                          onClick={handleCloseFormationDialog}
                          aria-label="close"
                        >
                          <CheckIcon sx={{ color: "#662483" }} />
                        </IconButton>
                      </StyledTooltip>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: { xs: "flex", md: "none" },
                      }}
                    >
                      <Typography variant="body1">
                        My Starting XI Team: {userFavTeam.teamInfo.shortName}{" "}
                      </Typography>
                    </Box>
                  </Toolbar>
                </AppBar>
                <List dense sx={{ bgcolor: "#B297BE" }}>
                  {formations.map(
                    ({ mainCategory, derivedCategories }, index) => {
                      const labelId = `${mainCategory}`;
                      return (
                        <>
                          <ListItemText
                            primary={mainCategory}
                            primaryTypographyProps={{
                              fontWeight: 600,
                              fontSize: 16,
                              marginLeft: 2,
                              height: 30,
                              marginTop: 1,
                            }}
                          />
                          <RenderFormatonList
                            derivedCategories={derivedCategories}
                          />
                        </>
                      );
                    }
                  )}
                </List>
              </Dialog>
              {counterDate && (
                <Countdown date={counterDate} renderer={countdownRenderer} />
              )}
              <Button
                variant="text"
                disabled={disableTeamSelection || isLocked}
                onClick={clearHandler}
                //style={{ marginTop: 5 }}
                color="primary"
                className={classes.outlinedButtonStyle}
              >
                Clear
              </Button>
            </Box>
            <Box
              mt={1.5}
              style={{
                display: openShareDialog === true ? "inline-flex" : "none",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <Typography variant="body1">
                {userData.username}'s Starting Lineup:{" "}
                {userFavTeam.teamInfo.shortName + ", " + selectedFormationTitle}
              </Typography>
            </Box>
            <Box
              mt={2}
              style={{
                display: openShareDialog === true ? "flex" : "none",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  paddingTop: 5,
                  justifyContent: "end",
                }}
              >
                <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                  {homeTeam[0] && secondTeam
                    ? homeTeam[0].shortName
                    : userFavTeam.teamInfo &&
                      userFavTeam.teamInfo.shortName}{" "}
                </Typography>
              </Box>
              <Box
                ml={1}
                mr={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <img
                    src={
                      homeTeam[0] && secondTeam && homeTeam[0].logo
                        ? mainPath + homeTeam[0].logo
                        : userFavTeam.teamInfo && userFavTeam.teamInfo.logo
                        ? mainPath + userFavTeam.teamInfo.logo
                        : ""
                    }
                    //alt="teamLogo"
                    height={36}
                    width={36}
                  />
                </Box>
                <Box
                  ml={1}
                  mr={1}
                  style={{
                    height: 40,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "center",
                    //  paddingTop: 5,
                  }}
                >
                  <Typography variant="subtitle2">-</Typography>
                </Box>
                <Box>
                  <img
                    src={
                      !secondTeam[0]
                        ? competitor[0] && competitor[0].logo
                          ? mainPath + competitor[0].logo
                          : ""
                        : secondTeam[0] && mainPath + secondTeam[0].logo
                    }
                    //alt="teamLogo"
                    height={36}
                    width={36}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  paddingTop: 5,
                  justifyContent: "start",
                }}
              >
                <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                  {!secondTeam[0]
                    ? competitor[0] && competitor[0].shortName
                    : secondTeam[0] && secondTeam[0].shortName}
                </Typography>
              </Box>
            </Box>
            <Box
              mt={1}
              style={{
                display: openShareDialog === true ? "flex" : "none",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Box>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {delayed ? (
                    <Icon
                      aria-label="delayed"
                      style={{
                        marginRight: 6,
                        //  display: openShareDialog === true ? "none" : "flex",
                        display: "flex",
                        verticalAlign: "middle",
                        height: 18,
                        fontSize: "1.4em",
                      }}
                    >
                      <img
                        src={delayedIcon}
                        width={18}
                        style={{ fontSize: "1em" }}
                      />
                    </Icon>
                  ) : (
                    ""
                  )}
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 700, color: "#757575" }}
                    >
                      Fixture Date:
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 700,
                        color: "#4D4D4D",
                        paddingLeft: 5,
                      }}
                    >
                      {fixDate}
                    </Typography>
                  </Box>
                </Box>

                <Box mt={0.5} style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 700, color: "#757575" }}
                  >
                    Start Time:
                  </Typography>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      color: "#4D4D4D",
                      paddingLeft: 5,
                    }}
                  >
                    {startTime}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#4D4D4D",
                      paddingLeft: 5,
                      paddingTop: 2,
                    }}
                  >
                    (Your local time)
                  </Typography>
                </Box>
              </Box>
              <Box mb={0.5}>
                <img src={leagueLogo} alt="leagueLogo" height={39} width={88} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {!isLoad || selectedFormation === "" ? (
          <div
            style={{
              backgroundImage: `url(${footField})`,
              width: 320,
              height: 454,
            }}
          />
        ) : (
          <>
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    height: 23,
                    width: 320,
                    backgroundImage: `url(${imgRes})`,
                    //backgroundRepeat: "no-repeat",
                    flexDirection: "row",
                    //border: "1px solid white"
                  }}
                />
                {teamSelections.FOR && teamSelections.FOR.forLane_col_2 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.FOR.forLane_col_2.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "FOR",
                                  "forLane_col_2",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {teamSelections.FOR && teamSelections.FOR.forLane_col_1 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.FOR &&
                      teamSelections.FOR.forLane_col_1.map(
                        (
                          {
                            accepts,
                            lastDroppedItem,
                            text,
                            img,
                            height,
                            width,
                          },
                          index
                        ) => {
                          return (
                            <div>
                              <Selection
                                key={index}
                                accept={accepts}
                                img={img}
                                height={height}
                                width={width}
                                text={text}
                                lastDroppedItem={lastDroppedItem}
                                handleClickOpenAddPlayerDialog={() =>
                                  handleClickOpenAddPlayerDialog(
                                    index,
                                    "FOR",
                                    "forLane_col_1",
                                    text
                                  )
                                }
                                embargoTime={disableTeamSelection || isLocked}
                              />
                            </div>
                          );
                        }
                      )}
                  </div>
                )}
                {teamSelections.MID && teamSelections.MID.midLane_col_3 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.MID.midLane_col_3.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "MID",
                                  "midLane_col_3",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {teamSelections.MID && teamSelections.MID.midLane_col_2 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.MID.midLane_col_2.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "MID",
                                  "midLane_col_2",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {teamSelections.MID && teamSelections.MID.midLane_col_1 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.MID.midLane_col_1.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "MID",
                                  "midLane_col_1",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {teamSelections.DEF && teamSelections.DEF.defLane_col_2 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.DEF.defLane_col_2.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "DEF",
                                  "defLane_col_2",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {teamSelections.DEF && teamSelections.DEF.defLane_col_1 && (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {teamSelections.DEF.defLane_col_1.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "DEF",
                                  "defLane_col_1",
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                <div
                  style={{
                    //  overflow: "hidden",
                    clear: "both",
                  }}
                >
                  {teamSelections.GK &&
                    teamSelections.GK.map(
                      (
                        { accepts, lastDroppedItem, text, img, height, width },
                        index
                      ) => {
                        return (
                          <div>
                            <Selection
                              key={index}
                              accept={accepts}
                              img={img}
                              height={height}
                              width={width}
                              text={text}
                              lastDroppedItem={lastDroppedItem}
                              handleClickOpenAddPlayerDialog={() =>
                                handleClickOpenAddPlayerDialog(
                                  index,
                                  "GK",
                                  null,
                                  text
                                )
                              }
                              embargoTime={disableTeamSelection || isLocked}
                            />
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>

            <Dialog
              fullScreen
              open={openAddPlayerDialog}
              onClose={handleCloseAddPlayerDialog}
              //  TransitionComponent={Transition}
            >
              <Box
                mt={2}
                //  mb={1}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box ml={3} style={{ width: 25 }}>
                  <StyledTooltip title="Back to Starting Lineup">
                    <IconButton
                      edge="start"
                      //color="success"
                      onClick={handleCloseAddPlayerDialog}
                      aria-label="close"
                      style={{
                        paddingLeft: 0,
                        paddingRight: 4,
                        paddingTop: 2,
                      }}
                    >
                      <CheckIcon sx={{ color: "#662483" }} />
                    </IconButton>
                  </StyledTooltip>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    flexGrow: 2,
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1">
                    My Starting XI Team: {userFavTeam.teamInfo.shortName}{" "}
                  </Typography>
                </Box>
                <Box style={{ width: 20 }} />
              </Box>

              <Box /*sx={{ borderBottom: 1, borderColor: "divider" }}*/>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  style={{ marginLeft: 13, height: "48px" }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    style={{
                      width: "49%",
                      fontSize: 15,
                      fontWeight: 500,
                      textTransform: "none",
                      borderTop: value === 0 && "1px solid darkGray",
                      borderLeft: value === 0 && "1px solid darkGray",
                      borderRight: value === 0 && "1px solid darkGray",
                      borderRadius: value === 0 && "4px 4px 0px 0px",
                      borderBottom: value === 1 && "1px solid darkGray",
                      color: value === 0 ? "#6A1B9A" /*"#765285"*/ : "#4D4D4D",
                    }}
                    label="Team Roster"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      width: "49%",
                      fontSize: 15,
                      fontWeight: 500,
                      textTransform: "none",
                      borderTop: value === 1 && "1px solid darkGray",
                      borderLeft: value === 1 && "1px solid darkGray",
                      borderRight: value === 1 && "1px solid darkGray",
                      borderBottom: value === 0 && "1px solid darkGray",
                      borderRadius: value === 1 && "4px 4px 0px 0px",
                      color: value === 1 ? "#6A1B9A" /*"#765285"*/ : "#4D4D4D",
                    }}
                    label="Past Fixtures"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <AppBar
                  position="sticky"
                  sx={{
                    bgcolor: "white",
                    height: 90,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Toolbar
                    style={{
                      paddingLeft: 10,
                      paddingRight: 0,
                      paddingBottom: 0,
                      display: "flex",
                      flexDirection: "column",
                      //  width:"100%"
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "95%",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          paddingTop: 5,
                          paddingLeft: 0,
                          paddingRight: 0,
                          height: 50,
                        }}
                      >
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="text"
                            style={{
                              backgroundColor:
                                !h2HSelected && !playerDataSelected
                                  ? "#D9D9D9"
                                  : "",
                              textTransform: "none",
                              borderRadius: 8,
                              fontWeight: 700,
                              fontSize: 14,
                              height: 32,
                            }}
                            onClick={() => {
                              setH2HSelected(false);
                              setPlayerDataSelected(false);
                              setH2HList([]);
                            }}
                          >
                            Player Selection
                          </Button>
                          {formationPosTxt &&
                            !h2HSelected &&
                            !playerDataSelected && (
                              <Typography
                                style={{
                                  color: "#4D4D4D",
                                  fontSize: 12,
                                  padding: 0,
                                  margin: 0,
                                  display: "flex",
                                  justifyContent: "center",
                                  letterSpacing: 0.4,
                                }}
                              >
                                For position {formationPosTxt}
                              </Typography>
                            )}
                        </Box>
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="text"
                            style={{
                              backgroundColor: playerDataSelected
                                ? "#D9D9D9"
                                : "",
                              textTransform: "none",
                              borderRadius: 8,
                              fontWeight: 700,
                              fontSize: 14,
                              height: 32,
                            }}
                            onClick={() => {
                              setPlayerDataSelected(true);
                              setH2HSelected(false);
                              setH2HList([]);
                            }}
                          >
                            Player Data
                          </Button>
                          {playerDataSelected && (
                            <Typography
                              style={{
                                color: "#4D4D4D",
                                fontSize: 12,
                                padding: 0,
                                margin: 0,
                                display: "flex",
                                justifyContent: "center",
                                letterSpacing: 0.4,
                              }}
                            >
                              Select one player
                            </Typography>
                          )}
                        </Box>
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="text"
                            style={{
                              backgroundColor: h2HSelected ? "#D9D9D9" : "",
                              borderRadius: 8,
                              textTransform: "none",
                              fontWeight: 700,
                              fontSize: 14,
                              height: 32,
                              letterSpacing: 0.4,
                            }}
                            onClick={() => {
                              setH2HSelected(true);
                              setPlayerDataSelected(false);
                            }}
                          >
                            Head To Head
                          </Button>
                          {h2HSelected && (
                            <Typography
                              style={{
                                color: "#4D4D4D",
                                fontSize: 12,
                                padding: 0,
                                margin: 0,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Select two players
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Toolbar>
                </AppBar>

                {["Goalkeeper", "Defender", "Midfielder", "Forward"].map(
                  (i) => {
                    return (
                      <List
                        dense
                        sx={{
                          bgcolor: colors[i],
                          //  paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemText
                          primary={i + "s"}
                          primaryTypographyProps={{
                            fontWeight: 600,
                            fontSize: 16,
                            marginLeft: 2,
                            //height: 40,
                            paddingBottom: 1,
                            display: "flex",
                            alignItems: "center",
                            bgcolor: colors[i],
                            color: "#4D4D4D",
                          }}
                        />
                        <RenderPlayerList item={i} />
                      </List>
                    );
                  }
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <PastFixtures
                  pastFixtures={pastFixtures}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  embargoTime={embargoTime}
                  serverTime={serverTime}
                  // copyPastFixtureDataTS={copyPastFixtureData}
                  passCopyPastFixtureData={getCopyPastFixtureData}
                />
              </CustomTabPanel>
            </Dialog>
          </>
        )}
        <Box
          style={{
            display: openShareDialog ? "flex" : "none",
            flexDirection: "row",
            width: "320px",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">
            Copyright &copy; 2023 <br />
            All rights reserved
          </Typography>
          <Typography variant="h6">
            {selectionCreateDate ? selectionCreateDate : "H"}
          </Typography>
        </Box>
      </div>
      <Box
        mt={1}
        style={{
          display: openShareDialog ? "none" : "flex",
          width: "320px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <StyledTooltip title="Share your team">
            <IconButton
              edge="start"
              color="primary"
              onClick={() => setOpenShareDialog(true)}
              size="small"
              style={{ paddingTop: 0, paddingBottom: 0 }}
              disabled={userTSId == 0 || userTSId == ""}
            >
              <ShareIcon
                sx={{
                  color:
                    userTSId == 0 || userTSId == "" ? "#AAAAAA" : "#662483",
                }}
                fontSize="inherit"
              />
            </IconButton>
          </StyledTooltip>
        </Box>

        <Box mr={2} style={{ display: openShareDialog ? "none" : "flex" }}>
          <Button
            variant="contained"
            disabled={disableTeamSelection || isLocked}
            onClick={submitHandler}
            color="primary"
            className={classes.buttonStyle}
          >
            Submit
          </Button>
        </Box>
        <Box />
      </Box>

      {alertMsg && (
        <AlertDialog
          title="Alert"
          contentMsg={alertMsg}
          closeDialog={() => setAlertMsg(false)}
        />
      )}
      {openShareDialog && (
        <ShareDialog
          closeDialog={handleCloseShareDialog}
          userTSId={userTSId}
          reference={exportRef}
        />
      )}
      {openH2HDialog && (
        <HeadToHeadDialog
          title="HEAD TO HEAD COMPARISON"
          contentMsg="content"
          closeH2HDialog={handleCloseH2HDialog}
          head2headData={h2HList}
          players={fullDataPlayersList}
        />
      )}
      {playerDataSelected && playerData && (
        <PlayerDataDialog
          title="PLAYER DATA"
          contentMsg="content"
          closePlayerDataDialog={handleClosePlayerDataDialog}
          sleagueTeamPositionPlayedAggr={sleagueTeamPositionPlayedAggr}
          sleagueTeamGameStatsAggr={sleagueTeamGameStatsAggr}
          player={playerData}
        />
      )}
    </Box>
  );
};

export default TeamInfo;
