import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import Layout from "./Layout.js";
import { DataGrid } from "@material-ui/data-grid";
import Box from '@mui/material/Box';
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import adminStyle from "./Admin.module.scss";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import {GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY} from "../graphQL/current_bucket_sleague_qry";
import { GET_CURRENT_SLEAGUE_WEEKS_QUERY } from "../graphQL/current_bucket_week_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";
import { GET_TEAM_FIXTURE_WEEK } from "../graphQL/fixture_qry";
import { ALL_USERTS_BY_FIXTUREID_SLEAGUETEAMID } from "../graphQL/user_ts_qry";

import {project_url, admin_url} from "../store/data/Constants";

import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  }));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      
      <Box sx={{ mt: 25 }}>No Team Selection!</Box>
    </StyledGridOverlay>
  );
}

export default function UsersTeamSelection() {
    const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [pageSize, setPageSize] = React.useState(20);
  const navigate = useNavigate();
  
  const [sleaguesBucketData, setSleaguesBucketData] = useState([]);
  ;
  const [matchedFixturesId, setMatchedFixturesId] = useState([]);

  //const [allTeamFixtureData, setAllTeamFixtureData] = useState([]);
    
  const [currentFixtureId, setCurrentFixtureId] = useState(0);
    

  const [sleagueWeeksData, setSleagueWeeksData] = useState([]);
  
  const [sleagueTeamsData, setSleagueTeamsData] = useState([]);
  
  const [teamFixturesData, setTeamFixturesData] = useState([]);    

  const [rows, setRows] = useState([]);

  const [currentSleagueId, setCurrentSleagueId] = useState(-1);

  const [currentWeekId, setCurrentWeekId] = useState(-1);

  const [currentWeekName, setCurrentWeekName] = useState("");

  const [currentSleagueTeamId, setCurrentSleagueTeamId] = useState(-1);

  //const [currentTeamIndex, setCurrentTeamIndex] = useState(-1);

  const [disableBtns, setDisableBtns] = useState(true);

  const [ reschduledFixture, setReschduledFixture] = useState(false);

  

  const { loading: sleagueLoading, 
          error: sleagueError, 
          data: sleagueData} = useQuery(GET_ALL_CURRENT_BUCKET_SLEAGUE_QUERY, 
                                        {fetchPolicy: "network-only"});

  const [fetchWeeks] = useManualQuery(GET_CURRENT_SLEAGUE_WEEKS_QUERY, {
    fetchPolicy: "network-only"
  });

  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchTeamFixtureWeek] = useManualQuery(GET_TEAM_FIXTURE_WEEK, {
    fetchPolicy: "network-only",
  }); 

  const [fetchUserTS] = useManualQuery(ALL_USERTS_BY_FIXTUREID_SLEAGUETEAMID, {
    fetchPolicy: "network-only",
  }); 
  

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    
    if (!sleagueLoading && sleagueData && sleagueData.currentBucketSleague) {
      setSleaguesBucketData(sleagueData.currentBucketSleague);
      handleSleagueChange(sleagueData.currentBucketSleague[0].sleagueId.sleagueId)
    }
    if(sleagueError && sleagueError.graphQLErrors[0].message === "Signature has expired"){
        navigate(`/lms/${admin_url}/signin`);
    }
  }, [sleagueData]);  

  
  useEffect(async() => {

    const {
          loading: teamFixtureLoading,
          error: teamFixtureError,
          data: teamFixtureData,
        } = await fetchTeamFixtureWeek({
          variables: { sleagueTeamId: parseInt(currentSleagueTeamId) , weekId: parseInt(currentWeekId)},
        });

        if (!teamFixtureLoading && 
          teamFixtureData && 
          teamFixtureData.teamFixtureWeek) {
            let teamFixtureWeek = teamFixtureData.teamFixtureWeek

              let tData = [];
              var matchedFixtureId = []
              
              await teamFixtureWeek.map(fixture => {
                let filterFixId = 0;

                filterFixId = teamFixtureWeek.filter(
                  x => x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
                       parseInt(x.fixtureId.fixtureWeek.weekId.weekId) === 
                       parseInt(currentWeekId)
                ); 

                if (filterFixId.length == 0)
                {
                  filterFixId = teamFixtureWeek.filter(
                  x => x.fixtureId.fixtureId === fixture.fixtureId.fixtureId &&
                       parseInt(x.fixtureId.scheduledWeek.weekId.weekId) === 
                       parseInt(currentWeekId)                  
                ); 
                  setReschduledFixture(true);
                  setDisableBtns(true);
                }
                else
                {
                  setReschduledFixture(false);
                  setDisableBtns(false);
                }

                let teamData = [];
                if (filterFixId && filterFixId.length > 0) {
                  // search for fixtureId
                  const index = tData.findIndex(
                    x => x.fixtureId === fixture.fixtureId.fixtureId
                  );
                  // add the fixtureId if doesn't exist in list
                  if (index == -1) {
                    filterFixId.map(i => {
                      teamData.push({
                        teamName: i.sleagueTeamId.teamId.name,
                        halfScore: i.scoreHalf1,
                        finalScore: i.scoreFinal,
                      });
                    });
                  }

                  // if teamData exist add its data to result
                  if (teamData && teamData.length > 0) {
                    var formatedDate = dayFormat(
                          fixture.fixtureId.fixtureWeek.scheduledDate)

                    // check there are assigned two team for a fixture
                    if (teamData && teamData.length > 1) {
                      tData.push({
                        fixtureId: fixture.fixtureId.fixtureId,
                        fixtureWeek: fixture.fixtureId.fixtureWeek.weekId.name,
                        fixtureDate: formatedDate,
                        delayed: fixture.fixtureId.delayed,
                        scheduledWeek: fixture.fixtureId.scheduledWeek.weekId.name,              
                        fixtureTeams: [
                          { teamName: teamData[0].teamName, 
                            halfScore: teamData[0].halfScore,
                            finalScore: teamData[0].finalScore},
                          { teamName: teamData[1].teamName, 
                            halfScore: teamData[1].halfScore,
                            finalScore: teamData[1].finalScore }
                        ]
                      });
                      matchedFixtureId.push(parseInt(fixture.fixtureId.fixtureId));
                    }
                  }
                }
              });
              
              setTeamFixturesData(tData);
              setMatchedFixturesId(matchedFixtureId)
              
              

              if (matchedFixtureId.length){

                let selectedFixture = tData.find(
                  item => item.scheduledWeek === currentWeekName
                )
                
                if (selectedFixture){
                  setCurrentFixtureId(parseInt(selectedFixture.fixtureId));
                  if (selectedFixture.delayed === true)
                    setDisableBtns(true);       
                } 
              }
              else
              {
                setRows([]);
                setCurrentFixtureId("");
                
              }      
            }         
        
  },[currentSleagueTeamId, currentWeekId])


  const localTime = day => {
    const dayDate = new Date(day);
    const newDateFormat = dayDate.toDateString();
    const newTimeFormat = dayDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const newFormat = newDateFormat + " - " + newTimeFormat;
    return newFormat;
  };

  const formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: true
  });

  const dayFormat = day => {
    const dayDate = new Date(day);
    const dayFormat = formatter.formatToParts(dayDate);
    let format = "YYYYmonthDD";
    if (localStorage.getItem("preferedDateFormat")){
      format = localStorage.getItem("preferedDateFormat");
    }
    if (format==="DDmonthYYYY"){


    const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
        dayFormat.find(a=>a.type=='day').value + " " +
        dayFormat.find(a=>a.type=='month').value + ", " +
        dayFormat.find(a=>a.type=='year').value +" - " +
        dayFormat.find(a=>a.type=='hour').value +":" +
        dayFormat.find(a=>a.type=='minute').value +" "+
        dayFormat.find(a=>a.type=='dayPeriod').value
        return newFormat;
      }
      else if (format==="monthDDYYYY"){


      const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
          dayFormat.find(a=>a.type=='month').value + " " +
          dayFormat.find(a=>a.type=='day').value + ", " +
          dayFormat.find(a=>a.type=='year').value +" - " +
          dayFormat.find(a=>a.type=='hour').value +":" +
          dayFormat.find(a=>a.type=='minute').value +" "+
          dayFormat.find(a=>a.type=='dayPeriod').value
          return newFormat;
        }
        else{


        const newFormat = dayFormat.find(a=>a.type=='weekday').value + ", " +
            dayFormat.find(a=>a.type=='year').value + ", " +
            dayFormat.find(a=>a.type=='month').value + " " +
            dayFormat.find(a=>a.type=='day').value +" - " +
            dayFormat.find(a=>a.type=='hour').value +":" +
            dayFormat.find(a=>a.type=='minute').value +" "+
            dayFormat.find(a=>a.type=='dayPeriod').value
            return newFormat;
          }
  };   

  const selectFixture = (async (event) => {
    setRows([]);
    const fixtureId = event.target.value;
    setCurrentFixtureId(parseInt(fixtureId));   

  })

  const handleSleagueChange = (async (tid) => {
    setRows([]);
    setReschduledFixture(false); 
    
    if (tid !== ''){
      setCurrentSleagueId(parseInt(tid));
      
    }
        

    if (parseInt(tid) != 0){            
      const { loading: weekLoading, 
              error: weekError, 
              data: weekData} = await fetchWeeks({
              variables: {
                sleagueId: parseInt(tid)
              }
            });
            
      if (!weekLoading && weekData && weekData.currentSleagueWeek) {
        weekData.currentSleagueWeek.sort((a,b) => 
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);      
        setSleagueWeeksData(weekData.currentSleagueWeek) 
        

        if (currentWeekId === -1){
          let selectedWeek = weekData.currentSleagueWeek.find(
            item => item.extraField === true
          )

          if (selectedWeek){
            setCurrentWeekId(selectedWeek.weekId);
            setCurrentWeekName(selectedWeek.name);
            
          } 
          else{
            setCurrentWeekId(-1);
            setCurrentWeekName("");
            setDisableBtns(true);

            
          } 
        }                                                 
      }            
    }
    else{
        setSleagueWeeksData([]);        
        setTeamFixturesData([]);        
        
    }   

    const {
      loading: teamsLoading,
      error: teamsError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!teamsLoading && allTeamsData && allTeamsData.sleagueTeam) {
      allTeamsData.sleagueTeam.sort((a,b) => 
        a.teamId.name.toLowerCase() > b.teamId.name.toLowerCase() ? 1 : -1); 
      setSleagueTeamsData(allTeamsData.sleagueTeam);
      
    }
    if (teamsError && teamsError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  });

  
  const handleWeekChange = (async (e) => {
    setRows([]);
    setReschduledFixture(false); 
             
     setTeamFixturesData([]);
    
    if (e.target.value !== ''){
      const wid = e.target.value;
      
      let selectedWeek = sleagueWeeksData.find(
        item => item.weekId === wid
      )
      
      setCurrentWeekId(wid);           
      setCurrentWeekName(selectedWeek.name);

      if (currentSleagueTeamId == -1)
        setDisableBtns(true);
      else        
         setDisableBtns(false);                      
      
    }
        
  }); 


  const handleTeamChange = (async (e) => {
    setRows([]);
    setReschduledFixture(false); 

    
    if (e.target.value !== ''){
      
      const tid = e.target.value;
          
             
      setCurrentSleagueTeamId(parseInt(tid));       
       
      setDisableBtns(false); 

      
    }
        
});

  const userTSList = (async (event) => {
    
    const {
      loading: userTSLoading,
      error: userTSError,
      data: userTSData,
    } = await fetchUserTS({
      variables: {  fixtureId: parseInt(currentFixtureId),
                    sleagueTeamId: parseInt(currentSleagueTeamId)},
    });
    
    if (!userTSLoading && 
        userTSData && 
        userTSData.allUsertsByFixtureidSleagueteamid) {
        
        if (userTSData.allUsertsByFixtureidSleagueteamid.length == 0)
        {
          alert('No Team Selection!');
          setDisableBtns(true);
        }
            
        else {
          setRows(userTSData.allUsertsByFixtureidSleagueteamid);        
        }
        
    };   

  })

  const columns = [
    {
      field: "user_ts_id",
      headerName: "user_ts_id",
      width: 170,
      renderCell: rows => rows.row.userTSId,
      
    },   
    {
      field: "user_id",
      headerName: "user_id",
      width: 150,
      renderCell: rows => rows.row.userId.id,
      
    },
    {
      field: "username",
      headerName: "username",
      width: 150,
      renderCell: rows => rows.row.userId.username,
      
    },
    {
      field: "user_seq",
      headerName: "user_seq",
      width: 600,
      renderCell: rows => rows.row.userSeqSlice

      
    },
    
  ];
  
  
  return (
    <>
      <Helmet>
        <title>Predictal | User Team Selection | Admin</title>
      </Helmet>
      <Grid container direction="row">
        <Layout />
        <Grid container item direction="column" xs={9} spacing={1}>
          <Grid item>
            <h2 className={adminStyle.h2}>Users Team Selection</h2>
          </Grid>
          <Grid item>
            <form className={adminStyle.form}>
              <label className={adminStyle.label} 
                htmlFor="sleague">Current League Season</label>
              <select className={adminStyle.selectWithLabel} 
                  id="sleague" {...register("sleague")} 
                  onChange={handleSleagueChange}
                  value={currentSleagueId}>
                { 
                  sleaguesBucketData.map((sleague, i) => (<option key={i} value={sleague.sleagueId.sleagueId}>
                    {sleague.sleagueId.leagueId.subtitle}, Season {sleague.sleagueId.seasonName}
                  </option>))
                }
              </select>

              <label className={adminStyle.label} 
                htmlFor="week">League Season Weeks</label>
              <select className={adminStyle.selectWithLabel} 
                onChange={handleWeekChange}
                value={currentWeekId}>
                <option value="">Select a Week ...</option>
                {
                  sleagueWeeksData.map((week, i) => (
                      <option key={i} value={week.weekId}>
                    {week.name}
                  </option>))
                }
              </select>

              <label className={adminStyle.label} 
                htmlFor="sleague">Teams in the Current League Season</label>
              <select className={adminStyle.selectWithLabel} 
                onChange={handleTeamChange}
                value={currentSleagueTeamId}>  
                <option value="">Select a Team ...</option>
                {
                  sleagueTeamsData.map((team, i) => (
                      <option key={i} value={team.sleagueTeamId}>
                    {team.teamId.name}
                  </option>))
                }
              </select>
              
              {reschduledFixture === true ? 
              ( 
              <>               
                <label className={adminStyle.label} htmlFor="weekFixture">
                 Rescheduled
                 </label>
               </>
                )

              : 
              ("")
              }

                {teamFixturesData.length ?
                (
                <> 

                  
                {teamFixturesData[0].delayed === true ?
                 (                
                  <label className={adminStyle.label} htmlFor="weekFixture">
                   Delayed
                   </label>)
                 :("")}
               
                  <label className={adminStyle.label} htmlFor="weekFixture">
                    Fixture:
                      {teamFixturesData.length > 1 ? 
                        teamFixturesData.map((fixture, i) => (
                            <div>
                              <input  id="fixture" {...register("fixture")}
                                key={i}
                                value={fixture.fixtureId}  name="fixture"   
                                 type="radio" 
                                 checked={parseInt(fixture.fixtureId) === parseInt(currentFixtureId)}
                                 onChange={selectFixture} />

                                <label htmlFor="fixture">
                                {fixture && (
                                  <b> {fixture.fixtureTeams[0].teamName} {fixture.fixtureTeams[0].finalScore} ({fixture.fixtureTeams[0].halfScore}) - {fixture.fixtureTeams[1].teamName} {fixture.fixtureTeams[1].finalScore} ({fixture.fixtureTeams[1].halfScore})
                                  </b>
                                )}
                                </label>
                            </div>
                        ))
                      :
                      teamFixturesData[0] && (
                        <b> {teamFixturesData[0].fixtureTeams[0].teamName} {teamFixturesData[0].fixtureTeams[0].finalScore} ({teamFixturesData[0].fixtureTeams[0].halfScore}) - {teamFixturesData[0].fixtureTeams[1].teamName} {teamFixturesData[0].fixtureTeams[1].finalScore} ({teamFixturesData[0].fixtureTeams[1].halfScore})
                        </b>
                      )
                      }
                  </label>

                  <label className={adminStyle.dateLabel} htmlFor="weekFixture">
                    {reschduledFixture === true && teamFixturesData[0] ? 
                      (
                         <>
                         Rescheduled Date :                        
                          <b> {teamFixturesData[0].fixtureDate} ({teamFixturesData[0].fixtureWeek})</b>

                        
                         </>
                      )

                    :
                     (
                      <>
                      Date :
                    {teamFixturesData.length > 1 ? 
                      teamFixturesData.map((fixture, i) => (                          
                        parseInt(fixture.fixtureId) === parseInt(currentFixtureId) && (
                          <b> {fixture.fixtureDate} ( Scheduled: {fixture.scheduledWeek} )</b>)
                      ))
                    :
                    teamFixturesData[0] && (
                      <b> {teamFixturesData[0].fixtureDate}</b>

                    )}
                    </>
                    )}

                  </label> 
                  <br />
                  <Button variant="contained" color="primary" onClick={userTSList} fullWidth={false} disabled={disableBtns}> {" "} Run Query{" "}</Button>
                </>
                )
                :""}

            </form>
          </Grid>

          <Grid item>
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              columns={columns}
              rows={rows}
              
              getRowId={row => row.userTSId}
              sortingOrder={["desc", "asc"]}
              autoHeight={true}
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              rowsPerPageOptions={[40,20]}
              pagination
            />
          </Grid>
          
        </Grid>
      </Grid>
    </>
  );
}
