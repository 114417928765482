import { useState, useEffect, useContext } from "react";
import Layout from "../../components/Layout";
import { useNavigate, useParams, Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  // Link,
  Typography,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";

import TeamInfo from "./leftPanel/TeamInfo";
import TeamInfoCom from "./rightPanel/TeamInfo";
import MyTeamInfo from "./thirdPanel/TeamInfo";
import FavTeamSelectorHeader from "./FavTeamSelectorHeader";
import Footer from "../../components/Footer";
// import classes from "./TeamSelector.module.scss";

import FavTeamSelectorMobile from "./FavTeamSelectorMobile.js";
import FavTeamSelectorTablet from "./FavTeamSelectorTablet.js";

import { useQuery, useMutation, ClientContext, useManualQuery } from "graphql-hooks";
import { GET_USERPROFILE_QUERY, REFRESH_TOKEN_MUTATION } from "../../graphQL/user_qry";
// import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../graphQL/sleague_qry";
import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../graphQL/sleague_team_query";
import { ACCESS_FIXURE_TEAM_ID_QUERY } from "../../graphQL/authorized_user";

const FIXTURETEAM_QUERY = `query fixtureTeamById($fixtureTeamId:Int!){
  fixtureTeamById(fixtureTeamId:$fixtureTeamId){
    sleagueTeamId {
      sleagueTeamId
    }
    fixtureId{
      fixtureId
    }
    coachSeq
    communitySeq
  }
}`;
const SLEAGUETEAM_QUERY = `query sleagueTeamById($sleagueTeamId:Int!){
  sleagueTeamById(sleagueTeamId:$sleagueTeamId){
    sleagueTeamId
    sleagueId{
      sleagueId
      title
      leagueId{
        leagueId
        title
      }
    }
    teamId{
      name
      shortName
      logo
    }
  }
}`;
const USERTS_QUERY = `query userTSByFixtureteamid($fixtureTeamId:Int!){
  userTSByFixtureteamid(fixtureTeamId:$fixtureTeamId){
    userTSId
    userSeq
  }
}`;

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 1366,
    // minHeight: 818,
  },
  cardContent: {
    // padding: theme.spacing(3),
    // paddingLeft: 76,
    // paddingTop: 23,
    // paddingRight: 76,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // position: "relative",
    // minHeight: 100,
    // borderRadius: 8,
  },

  cardContainerTablet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 40,
    minWidth: 768,

    // paddingLeft: 48,
    // paddingRight: 48,
    // maxWidth: 900,
    // height: 648,
  },

  cardContainerMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 18,
    paddingTop: 24,
    minWidth: 360,

    // paddingBottom: 38,
    // paddingTop: 38,
    // paddingLeft: 5,
    // paddingRight: 5,
  },

  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 648,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 768,
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
  },

  overview: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    // flexWrap: "wrap",
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "column-reverse",
    //   alignItems: "flex-start",
    // },
  },
  teamImage: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: 40,
    width: 40,
  },

  footImage: {
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    height: 408,
    width: 580,
  },

  teamSel: {
    paddingTop: 24,
    paddingBottom: 24,
    // paddingLeft: 221,
  },

  formationStyle: {
    // textAlign: "left",
    paddingTop: 12,
    paddingBottom: 24,
    // paddingLeft: 221,
  },
}));

function FavTeamSelector() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const [userData, setUserData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const [coachSeqVal, setCoachSeqVal] = useState("");
  const [comSeqVal, setComSeqVal] = useState("");
  const [userSeqVal, setUserSeqVal] = useState("");
  const [showMyTeam, setShowMyTeam] = useState(false);
  const [teamLeft, setTeamLeft] = useState("");
  const [teamRight, setTeamRight] = useState("");
  const [allSleagueTeamsData, setAllSleagueTeamsData] = useState([]);
  const { fixtureTeamId } = useParams();
  const [pathname] = useState(window.location.pathname);

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSLTeam,
    error: errorSLTeam,
    data: dataSLTeam,
  } = useQuery(GET_ALL_SLEAGUE_TEAMS_QUERY);
  const [fixtureTeam] = useManualQuery(FIXTURETEAM_QUERY);
  const [sleagueTeam] = useManualQuery(SLEAGUETEAM_QUERY);
  const [userTS] = useManualQuery(USERTS_QUERY);
  const [userTSId, setUserTSId] = useState(0);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  //
  const client = useContext(ClientContext);

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: sleagueTeamId,
    },
  });

  // useEffect(() => {
  //   if (window.location.pathname.includes("favMyTeamSelection"))
  //     setShowMyTeam(true);
  // }, []);

  useEffect(() => {
    if (!playersDataLoading && playersData && playersData.sleagueTeamNowPastPlayer) {
      setPlayers(playersData.sleagueTeamNowPastPlayer);
    }
  }, [playersData]);
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!loading && data && data.profile) {
      setUserData(data.profile);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [data]);

  useEffect(async () => {
    if (!loadingSLTeam && dataSLTeam && dataSLTeam.allSleagueTeam) {
      setAllSleagueTeamsData(dataSLTeam.allSleagueTeam);
    }
    if (errorSLTeam && errorSLTeam.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSLTeam]);
  useEffect(async () => {
    let { loading, error, data } = await fixtureTeam({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    if (!loading && data && data.fixtureTeamById) {
      setSleagueTeamId(parseInt(data.fixtureTeamById.sleagueTeamId.sleagueTeamId));

      setFixtureId(parseInt(data.fixtureTeamById.fixtureId.fixtureId));
      setCoachSeqVal(data.fixtureTeamById.coachSeq);
      setComSeqVal(data.fixtureTeamById.communitySeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);
  useEffect(async () => {
    let { loading, error, data } = await userTS({
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    });
    console.log("Data----USER_TS--------", data);
    if (!loading && data && data.userTSByFixtureteamid) {
      setUserTSId(parseInt(data.userTSByFixtureteamid.userTSId));
      setUserSeqVal(data.userTSByFixtureteamid.userSeq);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [fixtureTeamId]);

  useEffect(async () => {
    let { loading, error, data } = await sleagueTeam({
      variables: {
        sleagueTeamId: sleagueTeamId,
      },
    });
    if (!loading && data && data.sleagueTeamById) {
      setUserFavTeam({
        teamInfo: data.sleagueTeamById.teamId,
        sleagueteam: data.sleagueTeamById,
      });
      setLeagueId(parseInt(data.sleagueTeamById.sleagueId.leagueId.leagueId));
    }
  }, [sleagueTeamId]);
  const handleSelectedTeamsToView = (name1, name2) => {
    console.log("name1", name1, "name2", name2);
    if (name1 === "Community") setTeamLeft("Community");
    else setTeamLeft("My Team");

    if (name2 === "Coach") setTeamRight("Coach");
    else setTeamRight("Community");
  };

  const [userAccess, setUserAccess] = useState(true);
  const {
    loading: usrAccessLoadin,
    error: usrAccessError,
    data: usrAccessData,
  } = useQuery(ACCESS_FIXURE_TEAM_ID_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });
  useEffect(() => {
    if (!usrAccessLoadin && usrAccessData) {
      setUserAccess(usrAccessData.accessFixtureTeamId);
    }
  }, [usrAccessData]);
  if (!userAccess) {
    return (
      <h3>
        <br />
        <center>
          You are not able to view the Community/Coach teams. Please start from your{" "}
          <Link to="/lms/fixtures">Fixtures</Link> page.
        </center>
      </h3>
    );
  }

  return (
    <>
      <Helmet>
        <title>Predictal | Fav Team Community/Coach selection</title>        
      </Helmet>
      {mobileView && <FavTeamSelectorMobile />}
      {tabletView && <FavTeamSelectorTablet />}
      <div>
        {!mobileView && !tabletView && userFavTeam && (
          <Container className={classes.cardContainer} maxWidth="lg">
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
                <FavTeamSelectorHeader
                  userFavTeam={userFavTeam}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  passTwoSelectedTeam={handleSelectedTeamsToView}
                  //showMyTeam={showMyTeam}
                  sleagueTeamsData={allSleagueTeamsData}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ paddingTop: 2 }}>
                <Paper style={{ width: "100%", minWidth: 650, height: 570 }}>
                  <DndProvider backend={HTML5Backend} debugMode={true}>
                    {teamLeft === "My Team" ? (
                      <MyTeamInfo
                        userData={userData}
                        userFavTeam={userFavTeam}
                        fixtureTeamId={parseInt(fixtureTeamId)}
                        sleagueTeamId={sleagueTeamId}
                        fixtureId={fixtureId}
                        leagueId={leagueId}
                        userSeqVal={userSeqVal}
                        userTSId={userTSId}
                        sleaguePlayers={players}
                      />
                    ) : (
                      <TeamInfoCom
                        userFavTeam={userFavTeam}
                        fixtureTeamId={fixtureTeamId}
                        sleagueTeamId={sleagueTeamId}
                        fixtureId={fixtureId}
                        leagueId={leagueId}
                        comSeqVal={comSeqVal}
                        sleaguePlayers={players}
                      />
                    )}
                  </DndProvider>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} md={6} style={{ paddingTop: 2 }}>
                <Paper style={{ width: "100%", minWidth: 650, height: 570 }}>
                  <DndProvider backend={HTML5Backend} debugMode={true}>
                    {teamRight === "Community" ? (
                      <TeamInfoCom
                        userFavTeam={userFavTeam}
                        fixtureTeamId={fixtureTeamId}
                        sleagueTeamId={sleagueTeamId}
                        fixtureId={fixtureId}
                        leagueId={leagueId}
                        comSeqVal={comSeqVal}
                        sleaguePlayers={players}
                      />
                    ) : (
                      <TeamInfo
                        userFavTeam={userFavTeam}
                        fixtureTeamId={fixtureTeamId}
                        sleagueTeamId={sleagueTeamId}
                        fixtureId={fixtureId}
                        leagueId={leagueId}
                        coachSeqVal={coachSeqVal}
                        sleaguePlayers={players}
                      />
                    )}
                  </DndProvider>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </>
  );
}

export default FavTeamSelector;
