import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  FormHelperText,
  IconButton,
  makeStyles,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { IconButtonProps } from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TeamInfoCom from "../favTeamSelection/rightPanel/TeamInfo";
import TeamInfoComMobile from "../favTeamSelection/mobilePanel/TeamInfoCom";

//import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tooltip from "@material-ui/core/Tooltip";

import leagueLogo from "../../assets/Premier_League-Logo.png";
import adminStyle from "../../adminPages/Admin.module.scss";
import { project_url, media_url } from "../../store/data/Constants";

import { GET_FORMATION_POPULARITIES_QUERY } from "../../graphQL/formation_qry";
import { COMMUNITY_TEAM_SELECTION_QUERY } from "../../graphQL/algorithm_qry";
import { GET_PLAYER_POPULARITIES_QUERY } from "../../graphQL/player_qry";
import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION,
} from "../../graphQL/user_qry";
import {
  GET_FIXTURETEAM_BY_ID,
  GET_FIXTURE_OBJECT,
  GET_FIXTURE_OBJECT_TEAMS,
} from "../../graphQL/fixture_qry";
import {
  GET_ALL_SLEAGUETEAMS_QUERY,
  GET_SLEAGUETEAM_PLAYER_QUERY,
} from "../../graphQL/sleague_qry";
import {
  GET_ALL_SLEAGUE_TEAMS_QUERY,
  SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY,
} from "../../graphQL/sleague_team_query";

import { ACCESS_FIXURE_TEAM_ID_QUERY } from "../../graphQL/authorized_user";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 40,
    maxWidth: 1290,
    minHeight: 458,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "0px !important",
    marginRight: "0px !important",
    maxWidth: 1100,
    minHeight: "248px !important",
    "& .MuiContainer-root": {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: "0px !important",
      marginRight: "0px !important",
    },
  },
  cardContainerMobile: {
    paddingBottom: 38,
    paddingTop: 38,
    paddingLeft: 5,
    paddingRight: 5,
  },
  cardContent: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
  cardContentMobile: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    paddingTop: 40,
    minHeight: 400,
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: "700 !important",
      fontSize: "12px !important",
    },
  },
  textStyle1: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#4D4D4D",
  },
  textStyle3: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#4D4D4D",
    textAlign: "center",
  },
  dataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    minHeight: 680,
    width: "100%",
    "& .MuiTableRow-head": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 13,
      fontWeight: "100px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 60,
      minWidth: 80,
    },
    "& .MuiTableCell-head": {
      whiteSpace: "normal",
      color: "white",
      fontSize: 13,
      fontWeight: "400px !important",
      height: 42,
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "&:nth-child(2n)": { backgroundColor: "#F3E5F5" },
      },
    },
    "& .MuiTableCell-footer": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 14,
      fontWeight: "600px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      height: 42,
    },
  },
  formationDataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    minHeight: 300,
    "& .MuiDataGrid-columnHeader": {
      minWidth: "90px !important",
    },

    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 13,
      fontWeight: "100px !important",
      fontFamily: "Arial",
      minWidth: "90px !important",
    },
    "& .MuiTableRow-head": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 13,
      fontWeight: "100px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 60,
      minWidth: 80,
    },
    "& .MuiTableCell-head": {
      whiteSpace: "normal",
      color: "white",
      fontSize: 13,
      height: 42,
    },
    "& .MuiTableCell-footer": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 16,
      fontWeight: "600px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      height: 42,
    },
  },
  mobileDataGridStyle: {
    "& .style--true": {
      backgroundColor: "white",
    },
    "& .style--false": {
      backgroundColor: "#F3E5F5",
    },
    minHeight: 300,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 13,
      fontWeight: "100px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 56,
      minWidth: 80,
    },
    "& .MuiTableRow-head": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 13,
      fontWeight: "100px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 40,
      minWidth: 80,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
    },
    "& .MuiTableCell-head": {
      whiteSpace: "normal",
      color: "white",
      fontSize: 13,
      height: 30,
    },
    "& .MuiTableCell-body": {
      fontSize: 14,
      fontWeight: "400px",
      paddingLeft: 0,
      paddingRight: 0,
      //  height: 36
    },
    "& .MuiDataGrid-cell": {
      fontSize: 14,
      //whiteSpace: "normal !important",
      lineHeight: "normal",
      minWidth: 80,
    },
    "& .MuiTableCell-footer": {
      backgroundColor: "#765285",
      color: "white",
      fontSize: 14,
      fontWeight: "600px",
      fontFamily: "Arial",
      whiteSpace: "normal",
      lineHeight: "normal",
      height: 30,
    },
  },
  dataGridSelectedCell: {
    backgroundColor: "#FDDA23",
  },
  dataGridFooterStyle: {
    height: 76,
    marginBottom: 0,
    backgroundColor: "#765285",
    fontFamily: "Arial",
  },
  formationDataGridFooterStyle: {
    height: 56,
    marginBottom: 0,
    backgroundColor: "#765285",
    fontFamily: "Arial",
  },
  formationDataGridFooterStyleMobile: {
    height: 40,
    marginBottom: 0,
    backgroundColor: "#765285",
    fontFamily: "Arial",
  },
  formationDataGridFooterTextStyle: {
    color: "#ffffff",
    fontFamily: "Arial",
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 10,
    paddingTop: 15,
  },
  dataGridFooterTextStyle: {
    color: "#ffffff",
    fontFamily: "Arial",
    fontWeight: 600,
    fontSize: 13,
    marginLeft: 10,
    paddingTop: 25,
  },
  dataGridFooterSmallTextStyle: {
    color: "#ffffff",
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: 12,
    paddingTop: 5,
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "125px",
    padding: 0,
    height: "35px",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 16,
  },
}));

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    display: flex;
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 0;
    font-size: 14px;
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
}));

function Row(props) {
  const { row, index, display } = props;
  const [openArrowDownIcon, setOpenArrowDownIcon] = useState(false);
  let odd = index % 2 == 1;

  return (
    <React.Fragment>
      <TableRow
        style={{ backgroundColor: odd ? "#F3E5F5" : "white" }}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell style={{ width: 140, borderBottom: "solid 1px lightGray" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenArrowDownIcon(!openArrowDownIcon)}
          >
            {openArrowDownIcon ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {row.position}
        </TableCell>
        <TableCell
          style={{
            fontSize: "14px",
            fontWeight: "700",
            width: 10,
            borderBottom: "solid 1px lightGray",
          }}
          component="th"
          scope="row"
        >
          {row.selectedVote.jerseyNO}
        </TableCell>
        <TableCell
          style={{ width: 170, borderBottom: "solid 1px lightGray" }}
          component="th"
          scope="row"
        >
          {row.selectedVote.name}
        </TableCell>
        <TableCell
          style={{ width: 100, borderBottom: "solid 1px lightGray" }}
          component="th"
          scope="row"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {row.selectedVote.vote}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={openArrowDownIcon} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableBody>
                  {row.list.map((r, idx) => (
                    <TableRow
                      key={idx}
                      style={{ backgroundColor: odd ? "#F3E5F5" : "white" }}
                    >
                      <TableCell
                        style={{
                          width: display === "mobile" ? 150 : 130,
                          height: 30,
                          borderBottom: "solid 1px lightGray",
                        }}
                        component="th"
                        scope="row"
                      />
                      <TableCell
                        style={{
                          width: 10,
                          height: 30,
                          fontSize: "14px",
                          fontWeight: "700",
                          borderBottom: "solid 1px lightGray",
                        }}
                        component="th"
                        scope="row"
                      >
                        {r.jerseyNO}
                      </TableCell>
                      <TableCell
                        style={{
                          width: display === "mobile" ? 130 : 150,
                          height: 30,
                          borderBottom: "solid 1px lightGray",
                        }}
                        component="th"
                        scope="row"
                      >
                        {r.name}
                      </TableCell>
                      <TableCell
                        style={{
                          width: 100,
                          height: 30,
                          borderBottom: "solid 1px lightGray",
                        }}
                        component="th"
                        scope="row"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            //  paddingLeft: "12px",
                          }}
                        >
                          {r.vote}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const FavTeamResults = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  let location = useLocation();
  const [value, setValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false);
  const { fixtureTeamId } = useParams();
  const [fixtureTeam1, setFixtureTeam1] = useState([]);
  const [fixtureTeam2, setFixtureTeam2] = useState([]);
  const [fixtureTeam1Logo, setFixtureTeam1Logo] = useState("");
  const [fixtureTeam2Logo, setFixtureTeam2Logo] = useState("");
  const [sleagueTeamId1, setSleagueTeamId1] = useState("");
  const [sleagueTeamId2, setSleagueTeamId2] = useState("");
  const [scoreHalf1Team1, setScoreHalf1Team1] = useState("");
  const [scoreHalf1Team2, setScoreHalf1Team2] = useState("");
  const [scoreFinalTeam1, setScoreFinalTeam1] = useState("");
  const [scoreFinalTeam2, setScoreFinalTeam2] = useState("");
  const [homeValue, setHomeValue] = useState("");
  const [totalPlayerVotes, setTotalPlayerVotes] = useState(0);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [rowList, setRowList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [sleagueTeamId, setSleagueTeamId] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [players, setPlayers] = useState([]);
  const [formationPopularities, setFormationPopularities] = useState([]);
  const [formationVotesSum, setFormationVotesSum] = useState(0);
  const [maxFormation, setMaxFormation] = useState("");
  const [maxFormationVotes, setMaxFormationVotes] = useState(0);
  const [fixtureId, setFixtureId] = useState("");
  const [teamSeq, setTeamSeq] = useState([]);
  const [communitySelection, setCommunitySelection] = useState([]);
  const [communityNotSelected, setCommunityNotSelected] = useState([]);
  const [comSeqVal, setComSeqVal] = useState("");
  const [showHeader, setShowHeader] = useState(true);
  const [positionLen, setPositionLen] = useState([]);
  const [playerPositionLen, setPlayerPositionLen] = useState([]);
  const [pathname] = useState(window.location.pathname);
  const client = useContext(ClientContext);

  const [fetchFixtureTeams] = useManualQuery(GET_FIXTURE_OBJECT_TEAMS, {
    fetchPolicy: "network-only",
  });
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  useEffect(() => {
    if (location.pathname.includes("lms/ts/3teams"))
      setShowHeader(false);
  }, [location]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: (err) => {
      console.log(err);
    },
  });
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/signin/?next=${pathname}`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/signin/?next=${pathname}`);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenMobile = () => {
    setOpenMobile(true);
  };
  const handleCloseMobile = () => {
    setOpenMobile(false);
  };

  const { mobileView, tabletView } = state;
  const {
    loading: profileLoading,
    error: profileError,
    data: profileData,
  } = useQuery(GET_USERPROFILE_QUERY);
  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague,
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY);

  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(
    GET_FIXTURETEAM_BY_ID,
    {
      variables: {
        fixtureTeamId: parseInt(fixtureTeamId),
      },
    }
  );
  const {
    loading: formationPopLoading,
    error: formationPopError,
    data: formationPopData,
  } = useQuery(GET_FORMATION_POPULARITIES_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });
  const {
    loading: communityTSLoading,
    error: communityTSError,
    data: communityTSData,
  } = useQuery(COMMUNITY_TEAM_SELECTION_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });

  useEffect(() => {
    if (!profileLoading && profileData && profileData.profile) {
      setUserData(profileData.profile);
    }
    if (
      profileError &&
      profileError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [profileData]);

  useEffect(async () => {
    if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
      dataSleague.allSleagueTeam.map((sleagueteam) => {
        if (
          userData.userFavTeamField &&
          sleagueteam.sleagueTeamId ===
            userData.userFavTeamField.sleagueTeamId.sleagueTeamId
        ) {
          setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
        }
      });
    }
    if (
      errorSleague &&
      errorSleague.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/signin/?next=${pathname}`);
    }
  }, [dataSleague, profileData]);

  const {
    loading: playersDataLoading,
    error: playersDataError,
    data: playersData,
  } = useQuery(SLEAGE_TEAM_NOW_PAST_PLAYER_QUERY, {
    variables: {
      sleagueTeamId: parseInt(sleagueTeamId),
    },
  });

  useEffect(() => {
    if (
      !playersDataLoading &&
      playersData &&
      playersData.sleagueTeamNowPastPlayer
    ) {
      setPlayers(playersData.sleagueTeamNowPastPlayer);
    }
  }, [playersData]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 1099 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (communityTSData && communityTSData.communityTSResult) {
      let selections = communityTSData.communityTSResult;
      let notSelected = [];
      selections = selections.filter(function (item) {
        if (isZeroVotes(item)) {
          notSelected.push(item);
          return false;
        }
        return true;
      });

      selections = selections.sort((a, b) => {
        if (isZeroVotes(a) > isZeroVotes(b)) {
          return 1;
        }
        if (
          a.sleagueTeamPlayerId.playerId.lName.localeCompare(
            b.sleagueTeamPlayerId.playerId.lName
          ) < 0
        ) {
          return -1;
        }
        return 0;
      });
      selections = selections.sort((a, b) => {
        if (
          getPositionOrder(a.sleagueTeamPlayerId.playerId.position) <
          getPositionOrder(b.sleagueTeamPlayerId.playerId.position)
        ) {
          return -1;
        }
        if (
          getPositionOrder(a.sleagueTeamPlayerId.playerId.position) ==
          getPositionOrder(b.sleagueTeamPlayerId.playerId.position)
        ) {
          if (a.allFormationVotes > b.allFormationVotes) {
            return -1;
          }
        }
        return 1;
      });
      generateList(selections);
      setCommunitySelection(selections);
      setCommunityNotSelected(notSelected);
    }
  }, [communityTSData]);

  useEffect(() => {
    if (
      !teamLoading &&
      !communityTSLoading &&
      teamData &&
      communityTSData &&
      teamData.fixtureTeamById &&
      communityTSData.communityTSResult
    ) {
      let selected = [];
      let total = 0;
      if (teamData.fixtureTeamById.communitySeqList) {
        teamData.fixtureTeamById.communitySeqList.forEach((item, i) => {
          let a = communityTSData.communityTSResult.find(
            (element) =>
              parseInt(element.sleagueTeamPlayerId.playerSeqNO) ===
              parseInt(item)
          );
          selected.push(a);
          switch (i) {
            case 0:
              total += parseInt(a.positionSeqNO1Vote);
              break;
            case 1:
              total += parseInt(a.positionSeqNO2Vote);
              break;
            case 2:
              total += parseInt(a.positionSeqNO3Vote);
              break;
            case 3:
              total += parseInt(a.positionSeqNO4Vote);
              break;
            case 4:
              total += parseInt(a.positionSeqNO5Vote);
              break;
            case 5:
              total += parseInt(a.positionSeqNO6Vote);
              break;
            case 6:
              total += parseInt(a.positionSeqNO7Vote);
              break;
            case 7:
              total += parseInt(a.positionSeqNO8Vote);
              break;
            case 8:
              total += parseInt(a.positionSeqNO9Vote);
              break;
            case 9:
              total += parseInt(a.positionSeqNO10Vote);
              break;
            case 10:
              total += parseInt(a.positionSeqNO11Vote);
              break;
            default:
          }
        });
      }
      setTotalPlayerVotes(total);
      if (selected.length > 0) {
        setSelectedPlayers(selected);
      }
    }
  }, [teamData, communityTSData]);

  useEffect(() => {
    if (!teamLoading && teamData && teamData.fixtureTeamById) {
      if (
        teamData.fixtureTeamById.fixtureId &&
        teamData.fixtureTeamById.fixtureId.fixtureId
      ) {
        getFixtureTeams(teamData.fixtureTeamById.fixtureId.fixtureId);
        setFixtureId(teamData.fixtureTeamById.fixtureId.fixtureId);
      }
      if (
        teamData.fixtureTeamById.sleagueTeamId &&
        teamData.fixtureTeamById.sleagueTeamId.sleagueTeamId
      ) {
        setSleagueTeamId(teamData.fixtureTeamById.sleagueTeamId.sleagueTeamId);
        setLeagueId(
          parseInt(
            teamData.fixtureTeamById.sleagueTeamId.sleagueId.leagueId.leagueId
          )
        );
      }
      setTeamSeq(teamData.fixtureTeamById);
      setComSeqVal(teamData.fixtureTeamById.communitySeq);
    }
  }, [teamData]);

  useEffect(() => {
    if (
      !formationPopLoading &&
      formationPopData &&
      formationPopData.formationPopularity
    ) {
      let formationSorted = formationPopData.formationPopularity;
      formationSorted.sort((a, b) => {
        return a.votes < b.votes;
      });
      setFormationPopularities(formationSorted);

      let total = 0;
      formationSorted.map((popularity) => {
        total += popularity.votes;
      });
      setFormationVotesSum(total);
      let a = getMaxFormation(formationPopData.formationPopularity);
      setMaxFormation(a[0]);
      setMaxFormationVotes(a[1]);
    }
  }, [formationPopData]);

  useEffect(() => {
    if (communitySelection && communitySelection.length > 0) {
      getPositionLen();
      let subs = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

      let gkRowLen = 0;
      let defRowLen = 0;
      let mfRowLen = 0;
      let forRowLen = 0;

      communitySelection.map((selection, idx) => {
        let found = subs.map((item) =>
          selection.sleagueTeamPlayerId.playerId.position.includes(item)
        );
        if (found[0]) gkRowLen += 1;
        if (found[1]) defRowLen += 1;
        if (found[2]) mfRowLen += 1;
        if (found[3]) forRowLen += 1;
      });
      setPlayerPositionLen([gkRowLen, defRowLen, mfRowLen, forRowLen]);
    }
  }, [communitySelection]);

  const getMaxFormation = (formationArray) => {
    let maxIndex = 0;
    if (formationArray && formationArray.length) {
      for (var i = 0; i < formationArray.length; i++) {
        if (formationArray[i].votes >= formationArray[maxIndex].votes) {
          maxIndex = i;
        }
      }

      let formation =
        formationArray[maxIndex].formationLeagueId.formationId.title;
      return [formation, formationArray[maxIndex].percentage];
    } else {
      return "";
    }
  };

  const getFixtureTeams = async (fixtureId) => {
    if (fixtureId != null) {
      const {
        loading: fixtureTeamsLoading,
        error: fixtureTeamsError,
        data: fixtureTeamsData,
      } = await fetchFixtureTeams({
        variables: {
          fixtureId: parseInt(fixtureId),
        },
      });

      if (
        !fixtureTeamsLoading &&
        fixtureTeamsData &&
        fixtureTeamsData.fixtureObjectTeams
      ) {
        setFixtureTeam1(
          fixtureTeamsData.fixtureObjectTeams[0].sleagueTeamId.teamId.shortName
        );
        setFixtureTeam1Logo(
          fixtureTeamsData.fixtureObjectTeams[0].sleagueTeamId.teamId.logo
        );
        setSleagueTeamId1(
          fixtureTeamsData.fixtureObjectTeams[0].sleagueTeamId.sleagueTeamId
        );

        if (fixtureTeamsData.fixtureObjectTeams[0].scoreHalf1 != null)
          setScoreHalf1Team1(fixtureTeamsData.fixtureObjectTeams[0].scoreHalf1);

        if (fixtureTeamsData.fixtureObjectTeams[0].scoreFinal != null)
          setScoreFinalTeam1(fixtureTeamsData.fixtureObjectTeams[0].scoreFinal);

        setFixtureTeam2(
          fixtureTeamsData.fixtureObjectTeams[1].sleagueTeamId.teamId.shortName
        );
        setFixtureTeam2Logo(
          fixtureTeamsData.fixtureObjectTeams[1].sleagueTeamId.teamId.logo
        );
        setSleagueTeamId2(
          fixtureTeamsData.fixtureObjectTeams[1].sleagueTeamId.sleagueTeamId
        );

        if (fixtureTeamsData.fixtureObjectTeams[1].scoreHalf1 != null)
          setScoreHalf1Team2(fixtureTeamsData.fixtureObjectTeams[1].scoreHalf1);

        if (fixtureTeamsData.fixtureObjectTeams[1].scoreFinal != null)
          setScoreFinalTeam2(fixtureTeamsData.fixtureObjectTeams[1].scoreFinal);

        if (
          fixtureTeamsData.fixtureObjectTeams[0] &&
          fixtureTeamsData.fixtureObjectTeams[0].home
        )
          setHomeValue("team1");
        else setHomeValue("team2");
      }
    }
  };

  const getPositionOrder = (position) => {
    if (position === "Goalkeeper") {
      return 0;
    } else if (position === "Defender") {
      return 1;
    }
    if (position === "Midfielder") {
      return 2;
    }
    if (position === "Forward") {
      return 3;
    } else {
      return 4;
    }
  };

  const isZeroVotes = (player) => {
    if (
      player.positionSeqNO1Vote == 0 &&
      player.positionSeqNO2Vote == 0 &&
      player.positionSeqNO3Vote == 0 &&
      player.positionSeqNO4Vote == 0 &&
      player.positionSeqNO5Vote == 0 &&
      player.positionSeqNO6Vote == 0 &&
      player.positionSeqNO7Vote == 0 &&
      player.positionSeqNO8Vote == 0 &&
      player.positionSeqNO9Vote == 0 &&
      player.positionSeqNO10Vote == 0 &&
      player.positionSeqNO11Vote == 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getPositionLen = () => {
    if (
      teamSeq &&
      teamSeq.formationPositionsList &&
      teamSeq.formationPositionsList.length === 11
    ) {
      let subs = ["DEF", "MF", "FOR"];

      let defLen = 0;
      let mfLen = 0;
      let forLen = 0;

      teamSeq.formationPositionsList.map((formationPosition, index) => {
        let result = getPositionStr(index, false);
        let found = subs.map((item) => result.includes(item));
        if (found[0]) defLen += 1;
        if (found[1]) mfLen += 1;
        if (found[2]) forLen += 1;
      });
      setPositionLen([defLen, mfLen, forLen]);
    }
  };
  const getPositionStr = (number, showNumber) => {
    if (
      teamSeq &&
      teamSeq.formationPositionsList &&
      teamSeq.formationPositionsList.length === 11
    ) {
      if (showNumber) {
        return (
          teamSeq.formationPositionsList[number].slice(3) +
          " (" +
          parseInt(teamSeq.formationPositionsList[number].slice(0, 2)) +
          ")"
        );
      }

      return " (" + teamSeq.formationPositionsList[number].slice(3) + ")";
    }
    return "";
  };

  const getPositionAbr = (pos) => {
    switch (pos) {
      case "Goalkeeper":
        return "GK";
      case "Defender":
        return "DEF";
      case "Midfielder":
        return "MF";
      case "Forward":
        return "FOR";
      default:
        return "";
    }
  };

  const generateList = (communitySelection) => {
    let dataList = [];
    let selectedVote = [];
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
      let list = [];
      let position = getPositionStr(i, true);

      communitySelection.map((selection, idx) => {
        let votes = [];
        votes.push(selection.positionSeqNO1Vote);
        votes.push(selection.positionSeqNO2Vote);
        votes.push(selection.positionSeqNO3Vote);
        votes.push(selection.positionSeqNO4Vote);
        votes.push(selection.positionSeqNO5Vote);
        votes.push(selection.positionSeqNO6Vote);
        votes.push(selection.positionSeqNO7Vote);
        votes.push(selection.positionSeqNO8Vote);
        votes.push(selection.positionSeqNO9Vote);
        votes.push(selection.positionSeqNO10Vote);
        votes.push(selection.positionSeqNO11Vote);

        // eliminate 0 votes
        if (votes[i])
          list.push({
            name: selection.sleagueTeamPlayerId.playerId.name,
            jerseyNO: selection.sleagueTeamPlayerId.jerseyNO,
            vote: votes[i],
            playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          });
        else return;
      });

      list.sort(function (a, b) {
        if (a.vote && b.vote) {
          return parseInt(b.vote) - parseInt(a.vote);
        }
      });
      dataList.push({ position, list });
    });
    communitySelection.map((selection, idx) => {
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[0] &&
        parseInt(teamSeq.communitySeqList[0]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO1Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(0, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[1] &&
        parseInt(teamSeq.communitySeqList[1]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO2Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(1, true),
        });
      }

      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[2] &&
        parseInt(teamSeq.communitySeqList[2]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO3Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(2, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[3] &&
        parseInt(teamSeq.communitySeqList[3]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO4Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(3, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[4] &&
        parseInt(teamSeq.communitySeqList[4]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO5Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(4, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[5] &&
        parseInt(teamSeq.communitySeqList[5]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO6Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(5, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[6] &&
        parseInt(teamSeq.communitySeqList[6]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO7Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(6, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[7] &&
        parseInt(teamSeq.communitySeqList[7]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO8Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(7, true),
        });
      }
      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[8] &&
        parseInt(teamSeq.communitySeqList[8]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO9Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(8, true),
        });
      }

      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[9] &&
        parseInt(teamSeq.communitySeqList[9]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO10Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(9, true),
        });
      }

      if (
        teamSeq &&
        teamSeq.communitySeqList &&
        teamSeq.communitySeqList[10] &&
        parseInt(teamSeq.communitySeqList[10]) ===
          parseInt(selection.sleagueTeamPlayerId.playerSeqNO)
      ) {
        selectedVote.push({
          vote: selection.positionSeqNO11Vote,
          name: selection.sleagueTeamPlayerId.playerId.name,
          jerseyNO: selection.sleagueTeamPlayerId.jerseyNo,
          playerSeqNO: selection.sleagueTeamPlayerId.playerSeqNO,
          position: getPositionStr(10, true),
        });
      }
    });

    // Seprate selected vote from list of votes
    const draftList = [];
    dataList.map((dl, idx) => {
      selectedVote.map((s) => {
        if (s.position === dl.position) {
          let unSelectedVote = dl.list.filter((usv) => {
            return usv.playerSeqNO !== s.playerSeqNO;
          });
          let selectedVote = dl.list.filter((sv) => {
            return sv.playerSeqNO === s.playerSeqNO;
          });
          draftList.push({
            list: unSelectedVote,
            position: dl.position,
            selectedVote: selectedVote ? selectedVote[0] : "",
          });
        }
      });
    });
    setRowList(draftList);
  };

  const [playerPopularities, setPlayerPopularities] = useState([]);
  const [playerPopularVotesSum, setPlayerPopularVotesSum] = useState(0);
  const [playerAllVotesSum, setPlayerAllVotesSum] = useState(0);
  const {
    loading: playerPopLoading,
    error: playerPopError,
    data: playerPopData,
  } = useQuery(GET_PLAYER_POPULARITIES_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });
  useEffect(() => {
    if (!playerPopLoading && playerPopData && playerPopData.playerPopularity) {
      let playerArray = playerPopData.playerPopularity;
      playerArray = playerArray.sort((a, b) => {
        if (
          getPositionOrder(a.sleagueTeamPlayerId.playerId.position) <
          getPositionOrder(b.sleagueTeamPlayerId.playerId.position)
        ) {
          return -1;
        }
        if (
          getPositionOrder(a.sleagueTeamPlayerId.playerId.position) ==
          getPositionOrder(b.sleagueTeamPlayerId.playerId.position)
        ) {
          if (a.allFormationVotes > b.allFormationVotes) {
            return -1;
          }
        }

        return 1;
      });
      setPlayerPopularities(playerArray);

      let totalFormation = 0;
      let totalAll = 0;
      playerArray.map((player) => {
        totalFormation += player.popularFormationVotes;
        totalAll += player.allFormationVotes;
      });
      setPlayerPopularVotesSum(totalFormation);
      setPlayerAllVotesSum(totalAll);
    }
  }, [playerPopData]);

  const formationRows = formationPopularities.map((popularity, id) => ({
    // This line is changed by SP(Sahar) according story RTS-798 subtask:
    id: id, //popularity.formationLeagueId.formationId.formationId,

    formation: popularity.formationLeagueId.formationId.title,
    votes: popularity.votes,
    percentage: popularity.percentage,
  }));

  const formationColumns: GridColDef[] = [
    {
      field: "formation",
      headerName: "Formation",
      flex: 1,
      minWidth: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
    {
      field: "votes",
      headerName: "Votes",
      flex: 1,
      minWidth: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      flex: 1,
      minWidth: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
  ];

  const mobileFormationColumns: GridColDef[] = [
    {
      field: "formation",
      headerName: "Formation",
      flex: 1,
      minWidth: 110,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
    {
      field: "votes",
      headerName: "Votes",
      flex: 1,
      minWidth: 20,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      flex: 1,
      minWidth: 80,
      editable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      textAlign: "left",
    },
  ];

  const customFormationFooter = () => {
    return (
      <Box
        mb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
        className={classes.formationDataGridFooterStyle}
      >
        <Box mr={1}>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ textAlign: "left", width: "380px" }}
          >
            Total
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ fontWeight: "400", textAlign: "left", width: "390px" }}
          >
            {formationVotesSum}
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ fontWeight: "400", textAlign: "left", width: "250px" }}
          >
            100%
          </Typography>
        </Box>
      </Box>
    );
  };

  const customTabletFormationFooter = () => {
    return (
      <Box
        mb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
        className={classes.formationDataGridFooterStyle}
      >
        <Box mr={1}>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ textAlign: "left", width: "225px" }}
          >
            Total
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ fontWeight: "400", textAlign: "left", width: "245px" }}
          >
            {formationVotesSum}
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.formationDataGridFooterTextStyle}
            style={{ fontWeight: "400", textAlign: "left", width: "250px" }}
          >
            100%
          </Typography>
        </Box>
      </Box>
    );
  };

  const customMobileFormationFooter = () => {
    return (
      <Box
        mb={1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
        className={classes.formationDataGridFooterStyleMobile}
      >
        <Box mr={1}>
          <Typography
            gutterBottom
            className={classes.dataGridFooterTextStyle}
            style={{
              paddingTop: "10px",
              fontSize: "14px",
              fontWeight: "700",
              textAlign: "left",
              width: "90px",
            }}
          >
            Total
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.dataGridFooterTextStyle}
            style={{
              paddingTop: "10px",
              fontSize: "14px",
              fontWeight: "700",
              textAlign: "left",
              width: "100px",
            }}
          >
            {formationVotesSum}
          </Typography>
        </Box>
        <Box>
          <Typography
            gutterBottom
            className={classes.dataGridFooterTextStyle}
            style={{
              paddingTop: "10px",
              fontSize: "14px",
              fontWeight: "700",
              textAlign: "left",
              width: "100px",
            }}
          >
            100%
          </Typography>
        </Box>
      </Box>
    );
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              align: "right",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const [userAccess, setUserAccess] = useState(true);
  const {
    loading: usrAccessLoadin,
    error: usrAccessError,
    data: usrAccessData,
  } = useQuery(ACCESS_FIXURE_TEAM_ID_QUERY, {
    variables: {
      fixtureTeamId: parseInt(fixtureTeamId),
    },
  });
  useEffect(() => {
    if (!usrAccessLoadin && usrAccessData) {
      setUserAccess(usrAccessData.accessFixtureTeamId);
    }
  }, [usrAccessData]);
  if (!userAccess) {
    return (
      <h3>
        <br />
        <center>
          You are not able to view the Votes page. Please start from your{" "}
          <Link to="/lms/fixtures">Fixtures</Link> page.
        </center>
      </h3>
    );
  }
  return (
    <>
      <Helmet>
        <title>Predictal | Community Team Votes</title>        
      </Helmet>
      {mobileView && (
        <Container
          className={classes.cardContainerMobile}
          style={{ paddingTop: !showHeader ? 0 : "" }}
          maxWidth="sm"
        >
          {showHeader && (
            <Grid container spacing={1}>
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <Card>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "95%",
                    }}
                    alignItems="center"
                    flexGrow={1}
                    mt={3}
                    mb={3}
                  >
                    <Box pl={3} mt={-10}>
                      <StyledTooltip title="Return to Fixtures">
                        <IconButton
                          edge="start"
                          color="primary"
                          onClick={() => {
                            navigate("/lms/fixtures");
                          }}
                          size="large"
                        >
                          <KeyboardBackspaceIcon
                            sx={{ color: "#662483" }}
                            fontSize="inherit"
                          />
                        </IconButton>
                      </StyledTooltip>
                    </Box>

                    { fixtureTeam1Logo === "" || fixtureTeam2Logo === "" ?
                    (
                      <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >

                            <Grid item xs={4}>

                            </Grid>
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: "-45px",
                              }}
                            >

                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            </Box>
                    </Grid>
                    )
                    :
                    (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >
                        {homeValue === "team1" ? (
                          <>
                            <Grid item xs={4}>
                              <img
                                height={48}
                                width={48}
                                src={`/${project_url}/media/${fixtureTeam1Logo}`}
                              />
                              <br />
                              <Typography
                                className={classes.textStyle1}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam1}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: "-45px",
                              }}
                            >
                              <Typography
                                className={classes.textStyle3}
                                style={{ marginTop: "15px" }}
                              >
                                &nbsp;{scoreFinalTeam1} &nbsp;- &nbsp;
                                {scoreFinalTeam2}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <img
                                height={48}
                                width={48}
                                src={`/${project_url}/media/${fixtureTeam2Logo}`}
                              />
                              <br />
                              <Typography
                                className={classes.textStyle1}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam2}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={4}>
                              <img
                                height={48}
                                width={48}
                                src={`/${project_url}/media/${fixtureTeam2Logo}`}
                              />
                              <br />
                              <Typography
                                className={classes.textStyle1}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam2}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              alignItems="center"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: "-45px",
                              }}
                            >
                              <Typography
                                className={classes.textStyle3}
                                style={{ marginTop: "15px" }}
                              >
                                &nbsp;{scoreFinalTeam2} &nbsp;- &nbsp;
                                {scoreFinalTeam1}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <img
                                height={48}
                                width={48}
                                src={`/${project_url}/media/${fixtureTeam1Logo}`}
                              />
                              <br />
                              <Typography
                                className={classes.textStyle1}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam1}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Box>
                    </Grid>
                    )}
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentMobile}>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#765285",
                      },
                    }}
                    style={{ height: "55px" }}
                    variant="standard" //"fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                  >
                    <Tab
                      label="Community Team"
                      style={{
                        width: "25%",
                        minWidth: "80px",
                        fontSize: 12,
                        fontWeight: 400,
                        margin: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        textTransform: "none",
                        color: value === 0 ? "#6A1B9A" : "#00000099",
                      }}
                    />
                    <Tab
                      style={{
                        width: "25%",
                        minWidth: "80px",
                        fontSize: 12,
                        fontWeight: 400,
                        margin: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        textTransform: "none",
                        color: value === 1 ? "#6A1B9A" : "#00000099",
                      }}
                      label="Formation Popularity"
                    />
                    <Tab
                      style={{
                        width: "25%",
                        minWidth: "80px",
                        fontSize: 12,
                        fontWeight: 400,
                        margin: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        textTransform: "none",
                        color: value === 2 ? "#6A1B9A" : "#00000099",
                      }}
                      label="Player Popularity"
                    />
                    <Tab
                      style={{
                        width: "25%",
                        minWidth: "80px",
                        fontSize: 12,
                        margin: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        fontWeight: 400,
                        textTransform: "none",
                        color: value === 3 ? "#6A1B9A" : "#00000099",
                      }}
                      label="Players With No Vote"
                    />
                  </Tabs>
                  <Divider />
                  {value === 0 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Community Team ({maxFormation})
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.mobileDataGridStyle}
                      >
                        <Table size="small" aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: 150 }}>
                                Position
                              </TableCell>
                              <TableCell style={{ width: 190 }} colspan={2}>
                                Player
                              </TableCell>
                              <TableCell style={{ width: 90 }}>Votes</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowList.map((row, index) => (
                              <Row
                                key={row.position}
                                row={row}
                                index={index}
                                display="mobile"
                              />
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                colspan={3}
                                style={{ paddingRight: 0 }}
                              >
                                Maximized Sum of Position Votes:
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {totalPlayerVotes}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                      <Button
                        className={classes.outlinedButtonStyle}
                        style={{
                          width: 200,
                          fontSize: 14,
                          fontWeight: 500,
                          marginLeft: 70,
                          marginTop: 15,
                        }}
                        color="primary"
                        variant="outlined"
                        onClick={handleOpenMobile}
                      >
                        View Community Team
                      </Button>
                    </Box>
                  )}
                  {value === 1 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Formation Popularity
                      </Typography>
                      <Box className={classes.mobileDataGridStyle}>
                        <DataGrid
                          rowHeight={40}
                          autoHeight
                          disableColumnMenu
                          rows={formationRows}
                          columns={mobileFormationColumns}
                          getRowClassName={(params) =>
                            `style--${params.row.id % 2 == 0}`
                          }
                          hideFooterSelectedRowCount
                          components={{
                            Footer: customMobileFormationFooter,
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {value === 2 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Player Popularity
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.mobileDataGridStyle}
                      >
                        <Table size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colspan={2} style={{ width: "40%" }}>
                                Player
                              </TableCell>
                              <TableCell
                                style={{ width: "30%" }}
                                align="center"
                              >
                                All Formations
                              </TableCell>
                              <TableCell
                                style={{ width: "30%" }}
                                align="center"
                              >
                                Popular Formation
                                <br />
                                {maxFormation}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {playerPopularities.map((player, id) => (
                              <TableRow
                                key={id}
                                style={{
                                  backgroundColor:
                                    id % 2 == 1 ? "#F3E5F5" : "white",
                                }}
                              >
                                <TableCell>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                      paddingLeft: 16,
                                    }}
                                  >
                                    {player.sleagueTeamPlayerId.jerseyNO}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {player.sleagueTeamPlayerId.playerId.name}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#757575",
                                    }}
                                  >
                                    (
                                    {
                                      player.sleagueTeamPlayerId.playerId
                                        .position
                                    }
                                    )
                                  </Typography>
                                </TableCell>

                                <TableCell align="center">
                                  {player.allFormationVotes}
                                </TableCell>
                                <TableCell align="center">
                                  {player.popularFormationVotes +
                                    " (" +
                                    player.popularFormationPercentage +
                                    "%)"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colspan={2}>Total</TableCell>
                              <TableCell align="center">
                                {playerAllVotesSum}
                              </TableCell>
                              <TableCell align="center">
                                {playerPopularVotesSum}{" "}
                                {" (" + maxFormationVotes + "%)"}{" "}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {value === 3 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Players With No Vote
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.mobileDataGridStyle}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Position</TableCell>
                              <TableCell colspan={2}>Player</TableCell>
                              <TableCell align="center">Votes</TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Rows for Zero Votes Players */}
                          <TableBody>
                            {communityNotSelected.map((selection, idx) => (
                              <TableRow
                                key={idx}
                                style={{
                                  backgroundColor:
                                    idx % 2 == 1 ? "#F3E5F5" : "white",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ paddingLeft: 10 }}
                                >
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {
                                      selection.sleagueTeamPlayerId.playerId
                                        .position
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                      paddingLeft: 16,
                                    }}
                                  >
                                    {selection.sleagueTeamPlayerId.jerseyNO}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                      paddingLeft: 16,
                                    }}
                                  >
                                    {
                                      selection.sleagueTeamPlayerId.playerId
                                        .name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                      paddingLeft: 16,
                                    }}
                                  >
                                    0
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {tabletView && (
        <Container
          className={classes.cardContainerTablet}
          style={{ paddingTop: !showHeader ? 0 : "" }}
          maxWidth="sm"
        >
          {showHeader && (
            <Grid container spacing={1}>
              <Grid item lg={4} md={12} xl={3} xs={12}>
                <Card>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "95%",
                    }}
                    alignItems="center"
                    flexGrow={1}
                    mt={3}
                    mb={3}
                  >
                    <Box pl={5}>
                      <StyledTooltip title="Return to Fixtures">
                        <IconButton
                          edge="start"
                          color="primary"
                          onClick={() => {
                            navigate("/lms/fixtures");
                          }}
                          size="large"
                        >
                          <KeyboardBackspaceIcon
                            sx={{ color: "#662483" }}
                            fontSize="inherit"
                          />
                        </IconButton>
                      </StyledTooltip>
                    </Box>
                     { fixtureTeam1Logo === "" || fixtureTeam2Logo === "" ?
                    (
                      <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >
                        <Grid item xs={2}></Grid>


                            <Grid item xs={2}>

                            </Grid>
                            <Grid item xs={1} alignItems="center">

                            </Grid>
                            <Grid item xs={2}>

                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={2}>

                            </Grid>
                            <Grid item xs={2}></Grid>
                      </Box>
                    </Grid>

                    )
                    :
                    (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >
                        <Grid item xs={2}></Grid>
                        {homeValue === "team1" ? (
                          <>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam1}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} alignItems="center">
                              <img
                                height={56}
                                width={56}
                                src={`/${project_url}/media/${fixtureTeam1Logo}`}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                style={{ marginTop: "15px" }}
                              >
                                &nbsp;{scoreFinalTeam1} &nbsp;- &nbsp;
                                {scoreFinalTeam2}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <img
                                height={56}
                                width={56}
                                src={`/${project_url}/media/${fixtureTeam2Logo}`}
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "25px",
                                }}
                              >
                                {fixtureTeam2}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{ marginTop: "15px" }}
                              >
                                {fixtureTeam2}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} alignItems="center">
                              <img
                                height={56}
                                width={56}
                                src={`/${project_url}/media/${fixtureTeam2Logo}`}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                style={{ marginTop: "15px" }}
                              >
                                &nbsp;{scoreFinalTeam2} &nbsp;- &nbsp;
                                {scoreFinalTeam1}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <img
                                height={56}
                                width={56}
                                src={`/${project_url}/media/${fixtureTeam1Logo}`}
                                style={{
                                  marginBottom: "-10px",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                className={classes.textStyle3}
                                variant="h3"
                                style={{
                                  marginTop: "15px",
                                  marginLeft: "25px",
                                }}
                              >
                                {fixtureTeam1}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={2}></Grid>
                      </Box>
                    </Grid>
                    )}

                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item lg={4} md={12} xl={3} xs={12}>
              <Card>
                <CardContent className={classes.cardContentTablet}>
                  <Grid>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#765285",
                        },
                      }}
                      style={{ paddingLeft: "5px", height: "35px" }}
                      variant="standard" //"fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Community Team"
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 0 ? "#6A1B9A" : "#00000099",
                        }}
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 1 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Formation Popularity"
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 2 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Player Popularity"
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 3 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Players With No Vote"
                      />
                    </Tabs>
                    <Divider />
                  </Grid>
                  {value === 0 && (
                    <Box mb={1}>
                      <Grid container spacing={2}>
                        <Grid item md={9}>
                          <Typography gutterBottom variant="subtitle2">
                            <br />
                            Community Team ({maxFormation})
                          </Typography>
                        </Grid>
                        <Grid item md={3} style={{ marginBottom: "-10" }}>
                          <Button
                            className={classes.outlinedButtonStyle}
                            style={{
                              width: 225,
                              fontSize: 14,
                              fontWeight: 500,
                              marginLeft: 0,
                              marginTop: 40,
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={handleOpen}
                          >
                            View Community Team
                          </Button>
                        </Grid>
                      </Grid>
                      <TableContainer
                        component={Paper}
                        className={classes.mobileDataGridStyle}
                      >
                        <Table size="small" aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: 140 }}>
                                Position
                              </TableCell>
                              <TableCell style={{ width: 180 }} colspan={2}>
                                Player
                              </TableCell>
                              <TableCell style={{ width: 110 }}>
                                Votes
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowList.map((row, index) => (
                              <Row
                                key={row.position}
                                row={row}
                                index={index}
                                display="tablet"
                              />
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colspan={3}>
                                Maximized Sum of Position Votes:
                              </TableCell>
                              <TableCell
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {totalPlayerVotes}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {value === 1 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Formation Popularity
                      </Typography>
                      <Box className={classes.formationDataGridStyle}>
                        <DataGrid
                          autoHeight
                          rowHeight={48}
                          disableColumnMenu
                          rows={formationRows}
                          columns={formationColumns}
                          getRowClassName={(params) =>
                            `style--${params.row.id % 2 == 0}`
                          }
                          hideFooterSelectedRowCount
                          components={{
                            Footer: customTabletFormationFooter,
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {value === 2 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Player Popularity
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.formationDataGridStyle}
                      >
                        <Table size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colspan={2}>Player</TableCell>
                              <TableCell align="center">
                                All Formations
                              </TableCell>
                              <TableCell align="center">
                                Popular Formation ({maxFormation})
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {playerPopularities.map((player, id) => (
                              <TableRow
                                key={id}
                                style={{
                                  backgroundColor:
                                    id % 2 == 1 ? "#F3E5F5" : "white",
                                }}
                              >
                                <TableCell>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000000",
                                      paddingLeft: 16,
                                    }}
                                  >
                                    {player.sleagueTeamPlayerId.jerseyNO}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ height: 36 }}
                                >
                                  <Box style={{ display: "flex" }}>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        color: "#000000",
                                      }}
                                    >
                                      {player.sleagueTeamPlayerId.playerId.name}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "#757575",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      (
                                      {
                                        player.sleagueTeamPlayerId.playerId
                                          .position
                                      }
                                      )
                                    </Typography>
                                  </Box>
                                </TableCell>

                                <TableCell align="center">
                                  {player.allFormationVotes}
                                </TableCell>
                                <TableCell align="center">
                                  {player.popularFormationVotes +
                                    " (" +
                                    player.popularFormationPercentage +
                                    "%)"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={2}>Total</TableCell>
                              <TableCell align="center">
                                {playerAllVotesSum}
                              </TableCell>
                              <TableCell align="center">
                                {playerPopularVotesSum}{" "}
                                {" (" + maxFormationVotes + "%)"}{" "}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {value === 3 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Players With No Vote
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.formationDataGridStyle}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Position</TableCell>
                              <TableCell colspan={2}>Player</TableCell>
                              <TableCell align="center">Votes</TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Rows for Zero Votes Players */}
                          <TableBody>
                            {communityNotSelected.map((selection, idx) => (
                              <TableRow
                                key={idx}
                                style={{
                                  backgroundColor:
                                    idx % 2 == 1 ? "#F3E5F5" : "white",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {
                                      selection.sleagueTeamPlayerId.playerId
                                        .position
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell width="1%">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {selection.sleagueTeamPlayerId.jerseyNO}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {
                                      selection.sleagueTeamPlayerId.playerId
                                        .name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    0
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                  }}
                  alignItems="center"
                  flexGrow={1}
                  mt={3}
                  mb={3}
                >
                  <Box pl={5}>
                    <StyledTooltip title="Return to Fixtures">
                      <IconButton
                        edge="start"
                        color="primary"
                        onClick={() => {
                          navigate("/lms/fixtures");
                        }}
                        size="large"
                      >
                        <KeyboardBackspaceIcon
                          sx={{ color: "#662483" }}
                          fontSize="inherit"
                        />
                      </IconButton>
                    </StyledTooltip>
                  </Box>

                  { fixtureTeam1Logo === "" || fixtureTeam2Logo === "" ?
                    (
                        <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "95%",
                      }}
                    >
                      <Grid item xs={4}></Grid>
                      <Grid item xs={2}>
                          </Grid>
                          <Grid item xs={1} alignItems="center">

                          </Grid>
                          <Grid item xs={1}>

                          </Grid>
                          <Grid item xs={1}>

                          </Grid>
                          <Grid item xs={2}>

                          </Grid>
                          <Grid item xs={3}></Grid>
                    </Box>
                  </Grid>

                    )
                    :
                    (
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "95%",
                      }}
                    >
                      <Grid item xs={4}></Grid>

                      {homeValue === "team1" ? (
                        <>
                          <Grid item xs={2}>
                            <Typography
                              className={classes.textStyle3}
                              variant="h3"
                              style={{ marginTop: "15px" }}
                            >
                              {fixtureTeam1}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} alignItems="center">
                            <img
                              height={56}
                              width={56}
                              src={`/${project_url}/media/${fixtureTeam1Logo}`}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              className={classes.textStyle3}
                              style={{ marginTop: "15px" }}
                            >
                              &nbsp;{scoreFinalTeam1} &nbsp;- &nbsp;
                              {scoreFinalTeam2}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <img
                              height={56}
                              width={56}
                              src={`/${project_url}/media/${fixtureTeam2Logo}`}
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              className={classes.textStyle3}
                              variant="h3"
                              style={{ marginTop: "15px" }}
                            >
                              {fixtureTeam2}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={2}>
                            <Typography
                              className={classes.textStyle3}
                              variant="h3"
                              style={{ marginTop: "15px" }}
                            >
                              {fixtureTeam2}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} alignItems="center">
                            <img
                              height={56}
                              width={56}
                              src={`/${project_url}/media/${fixtureTeam2Logo}`}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              className={classes.textStyle3}
                              style={{ marginTop: "15px" }}
                            >
                              &nbsp;{scoreFinalTeam2} &nbsp;- &nbsp;
                              {scoreFinalTeam1}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <img
                              height={56}
                              width={56}
                              src={`/${project_url}/media/${fixtureTeam1Logo}`}
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              className={classes.textStyle3}
                              variant="h3"
                              style={{ marginTop: "15px" }}
                            >
                              {fixtureTeam1}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={3}></Grid>
                    </Box>
                  </Grid>
                  )}


                  <Box mb={0.5}>
                    <img
                      src={leagueLogo}
                      alt="leagueLogo"
                      height={39}
                      width={88}
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Grid>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#765285",
                        },
                      }}
                      style={{ paddingLeft: "5px", height: "35px" }}
                      variant="standard" //"fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Community Team"
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 0 ? "#6A1B9A" : "#00000099",
                        }}
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 1 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Formation Popularity"
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 2 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Player Popularity"
                      />
                      <Tab
                        style={{
                          width: "25%",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "none",
                          color: value === 3 ? "#6A1B9A" : "#00000099",
                        }}
                        label="Players With No Vote"
                      />
                    </Tabs>
                    <Divider />
                  </Grid>
                  {value === 0 && communitySelection.length > 0 &&(
                    <Box mb={1}>
                      <Grid container spacing={2}>
                        <Grid item md={9}>
                          <Typography gutterBottom variant="subtitle2">
                            <br />
                            Community Team ({maxFormation})
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          style={{ marginBottom: "-10", marginRight: "-10" }}
                        >
                          <Button
                            className={classes.outlinedButtonStyle}
                            style={{ width: 275, marginBottom: -70 }}
                            color="primary"
                            variant="outlined"
                            onClick={handleOpen}
                          >
                            View Community Team
                          </Button>
                        </Grid>
                      </Grid>
                      <TableContainer
                        component={Paper}
                        className={classes.dataGridStyle}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                colspan={2}
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  paddingRight: 0,
                                }}
                              >
                                Maximized Sum of Position Votes:{" "}
                                {totalPlayerVotes}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[0].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[0].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(0, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[1].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[1].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(1, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[2].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[2].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(2, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[3].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[3].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(3, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[4].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[4].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(4, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[5].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[5].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(5, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[6].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[6].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(6, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[7].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[7].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(7, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[8].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[8].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(8, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[9].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[9].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(9, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[10].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[10].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(10, true)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <React.Fragment>
                              {communitySelection.map((selection, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      width: "1rem",
                                      paddingRight: "0px",
                                      marginRight: "0px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        fontFamily: "Arial",
                                        color: "#000000",
                                      }}
                                    >
                                      {selection.sleagueTeamPlayerId.jerseyNO}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ height: 36 }}
                                  >
                                    <Box style={{ display: "flex" }}>
                                      <Typography
                                        variant="h5"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          fontFamily: "Arial",
                                          color: "#000000",
                                        }}
                                      >
                                        {
                                          selection.sleagueTeamPlayerId.playerId
                                            .name
                                        }
                                      </Typography>

                                      <Typography
                                        variant="h5"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          fontFamily: "Arial",
                                          color: "#757575",
                                          paddingLeft: 5,
                                        }}
                                      >
                                        (
                                        {
                                          selection.sleagueTeamPlayerId.playerId
                                            .position
                                        }
                                        )
                                      </Typography>
                                    </Box>
                                  </TableCell>

                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[0] &&
                                  parseInt(teamSeq.communitySeqList[0]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0] &&
                                          idx === 0
                                            ? "4px solid #757575"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0] &&
                                          playerPositionLen[0] - 1 === idx
                                            ? "4px solid #757575"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0]
                                            ? "4px solid #757575"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0]
                                            ? "4px solid #757575"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO1Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0] &&
                                          idx === 0
                                            ? "4px solid #757575"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0] &&
                                          playerPositionLen[0] - 1 === idx
                                            ? "4px solid #757575"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0]
                                            ? "4px solid #757575"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Goalkeeper" &&
                                          positionLen[0]
                                            ? "4px solid #757575"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO1Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[1] &&
                                  parseInt(teamSeq.communitySeqList[1]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          positionLen[0] > 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          positionLen[0] > 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          positionLen[0]
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO2Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          positionLen[0] > 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          positionLen[0] > 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          positionLen[0]
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 1
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO2Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[2] &&
                                  parseInt(teamSeq.communitySeqList[2]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 2 ||
                                            positionLen[0] === 2)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 2 ||
                                            positionLen[0] === 2)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 2
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO3Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 2 ||
                                            positionLen[0] === 2)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 2 ||
                                            positionLen[0] === 2)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[1] > idx - 1 &&
                                          positionLen[0] === 2
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO3Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[3] &&
                                  parseInt(teamSeq.communitySeqList[3]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 3 ||
                                            positionLen[0] === 3)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 3 ||
                                            positionLen[0] === 3)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 3
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO4Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 3 ||
                                            positionLen[0] === 3)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 3 ||
                                            positionLen[0] === 3)
                                            ? "4px solid #4CAF50"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 3
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO4Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[4] &&
                                  parseInt(teamSeq.communitySeqList[4]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 4 ||
                                            positionLen[0] === 4)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] ===
                                                  idx &&
                                                positionLen[0] < 4 &&
                                                (positionLen[0] +
                                                  positionLen[1] >
                                                  4 ||
                                                  positionLen[0] +
                                                    positionLen[1] ===
                                                    4))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 4
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 4 ||
                                            positionLen[0] === 4)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx + 1 &&
                                                positionLen[0] < 4 &&
                                                (positionLen[1] +
                                                  positionLen[0] >
                                                  4 ||
                                                  positionLen[1] +
                                                    positionLen[0] ===
                                                    4))
                                            ? "4px solid #3F51B5"
                                            : "",

                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 4
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] >
                                                  idx - 1 &&
                                                positionLen[0] < 4 &&
                                                positionLen[0] +
                                                  positionLen[1] ===
                                                  4)
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO5Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 4 ||
                                            positionLen[0] === 4)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] ===
                                                  idx &&
                                                positionLen[0] < 4 &&
                                                (positionLen[0] +
                                                  positionLen[1] >
                                                  4 ||
                                                  positionLen[0] +
                                                    positionLen[1] ===
                                                    4))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 4 ||
                                            positionLen[0] === 4)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx + 1 &&
                                                positionLen[0] < 4 &&
                                                (positionLen[1] +
                                                  positionLen[0] >
                                                  4 ||
                                                  positionLen[1] +
                                                    positionLen[0] ===
                                                    4))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 4
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 4
                                            ? "4px solid #4CAF50"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO5Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[5] &&
                                  parseInt(teamSeq.communitySeqList[5]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 5 ||
                                            positionLen[0] === 5)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] ===
                                                  idx &&
                                                positionLen[0] < 5 &&
                                                (positionLen[0] +
                                                  positionLen[1] >
                                                  5 ||
                                                  positionLen[0] +
                                                    positionLen[1] ===
                                                    5))
                                            ? "4px solid #3F51B5"
                                            : "",

                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 5 ||
                                            positionLen[0] === 5)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx + 1 &&
                                                positionLen[0] < 5 &&
                                                (positionLen[1] +
                                                  positionLen[0] >
                                                  5 ||
                                                  positionLen[1] +
                                                    positionLen[0] ===
                                                    5))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 5
                                            ? "4px solid #3F51B5"
                                            : "",

                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 5
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] ===
                                                  5)
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO6Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] === idx &&
                                          (positionLen[0] > 5 ||
                                            positionLen[0] === 5)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] ===
                                                  idx &&
                                                positionLen[0] < 5 &&
                                                (positionLen[0] +
                                                  positionLen[1] >
                                                  5 ||
                                                  positionLen[0] +
                                                    positionLen[1] ===
                                                    5))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx + 1 &&
                                          (positionLen[0] > 5 ||
                                            positionLen[0] === 5)
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx + 1 &&
                                                positionLen[0] < 5 &&
                                                (positionLen[1] +
                                                  positionLen[0] >
                                                  5 ||
                                                  positionLen[1] +
                                                    positionLen[0] ===
                                                    5))
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 5
                                            ? "4px solid #3F51B5"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Defender" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] >
                                            idx - 1 &&
                                          positionLen[0] === 5
                                            ? "4px solid #4CAF50"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Midfielder" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] ===
                                                  5)
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO6Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[6] &&
                                  parseInt(teamSeq.communitySeqList[6]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            6 ||
                                            positionLen[0] + positionLen[1] ===
                                              6)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  6 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  6 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    6))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            6 ||
                                            positionLen[0] + positionLen[1] ===
                                              6)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  6 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  6 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    6))
                                            ? "4px solid #E53935"
                                            : "",

                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 6
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  6)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 6
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO7Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            6 ||
                                            positionLen[0] + positionLen[1] ===
                                              6)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  6 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  6 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    6))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            6 ||
                                            positionLen[0] + positionLen[1] ===
                                              6)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  6 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  6 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    6))
                                            ? "4px solid #E53935"
                                            : "",

                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 6
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  6)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 6
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO7Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[7] &&
                                  parseInt(teamSeq.communitySeqList[7]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            7 ||
                                            positionLen[0] + positionLen[1] ===
                                              7)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  7 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  7 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    7))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            7 ||
                                            positionLen[0] + positionLen[1] ===
                                              7)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  7 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  7 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    7))
                                            ? "4px solid #E53935"
                                            : "",

                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 7
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  7)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 7
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO8Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            7 ||
                                            positionLen[0] + positionLen[1] ===
                                              7)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  7 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  7 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    7))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            7 ||
                                            positionLen[0] + positionLen[1] ===
                                              7)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  7 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  7 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    7))
                                            ? "4px solid #E53935"
                                            : "",

                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 7
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  7)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 7
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO8Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[8] &&
                                  parseInt(teamSeq.communitySeqList[8]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            8 ||
                                            positionLen[0] + positionLen[1] ===
                                              8)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  8 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  8 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    8))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            8 ||
                                            positionLen[0] + positionLen[1] ===
                                              8)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  8 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  8 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    8))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 8
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  8)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 8
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO9Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            8 ||
                                            positionLen[0] + positionLen[1] ===
                                              8)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  8 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  8 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    8))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            8 ||
                                            positionLen[0] + positionLen[1] ===
                                              8)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  8 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  8 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    8))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 8
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  8)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 8
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO9Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[9] &&
                                  parseInt(teamSeq.communitySeqList[9]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            9 ||
                                            positionLen[0] + positionLen[1] ===
                                              9)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  9 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  9 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    9))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            9 ||
                                            positionLen[0] + positionLen[1] ===
                                              9)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  9 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  9 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    9))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 9
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  9)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 9
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO10Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            9 ||
                                            positionLen[0] + positionLen[1] ===
                                              9)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  9 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  9 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    9))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            9 ||
                                            positionLen[0] + positionLen[1] ===
                                              9)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  9 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  9 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    9))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 9
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  9)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + positionLen[1] === 9
                                            ? "4px solid #3F51B5"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO10Vote}
                                    </TableCell>
                                  )}
                                  {teamSeq &&
                                  teamSeq.communitySeqList &&
                                  teamSeq.communitySeqList[10] &&
                                  parseInt(teamSeq.communitySeqList[10]) ===
                                    parseInt(
                                      selection.sleagueTeamPlayerId.playerSeqNO
                                    ) ? (
                                    <TableCell
                                      align="center"
                                      className={adminStyle.tableCellSelected}
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            10 ||
                                            positionLen[0] + positionLen[1] ===
                                              10)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  10 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  10 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    10))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            10 ||
                                            positionLen[0] + positionLen[1] ===
                                              10)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  10 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  10 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    10))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 10
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  10)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Forward" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] +
                                            playerPositionLen[3] >
                                            idx - 1 &&
                                          positionLen[0] +
                                            positionLen[1] +
                                            positionLen[2] ===
                                            10
                                            ? "4px solid #E53935"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO11Vote}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      align="center"
                                      style={{
                                        borderTop:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] ===
                                            idx &&
                                          (positionLen[0] + positionLen[1] >
                                            10 ||
                                            positionLen[0] + positionLen[1] ===
                                              10)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] ===
                                                  idx &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  10 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  10 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    10))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderBottom:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] ===
                                            idx + 1 &&
                                          (positionLen[0] + positionLen[1] >
                                            10 ||
                                            positionLen[0] + positionLen[1] ===
                                              10)
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] ===
                                                  idx + 1 &&
                                                positionLen[0] +
                                                  positionLen[1] <
                                                  10 &&
                                                (positionLen[0] +
                                                  positionLen[1] +
                                                  positionLen[2] >
                                                  10 ||
                                                  positionLen[0] +
                                                    positionLen[1] +
                                                    positionLen[2] ===
                                                    10))
                                            ? "4px solid #E53935"
                                            : "",
                                        borderLeft:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Midfielder" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] >
                                            idx - 1 &&
                                          positionLen[0] + 1 === 10
                                            ? "4px solid #3F51B5"
                                            : "" ||
                                              (selection.sleagueTeamPlayerId
                                                .playerId.position ===
                                                "Forward" &&
                                                playerPositionLen[0] +
                                                  playerPositionLen[1] +
                                                  playerPositionLen[2] +
                                                  playerPositionLen[3] >
                                                  idx - 1 &&
                                                positionLen[0] +
                                                  positionLen[1] +
                                                  1 ===
                                                  10)
                                            ? "4px solid #E53935"
                                            : "",
                                        borderRight:
                                          selection.sleagueTeamPlayerId.playerId
                                            .position === "Forward" &&
                                          playerPositionLen[0] +
                                            playerPositionLen[1] +
                                            playerPositionLen[2] +
                                            playerPositionLen[3] >
                                            idx - 1 &&
                                          positionLen[0] +
                                            positionLen[1] +
                                            positionLen[2] ===
                                            10
                                            ? "4px solid #E53935"
                                            : "",
                                      }}
                                    >
                                      {selection.positionSeqNO11Vote}
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))}
                            </React.Fragment>
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                colspan={2}
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  paddingRight: 0,
                                }}
                              >
                                Maximized Sum of Position Votes:{" "}
                                {totalPlayerVotes}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[0].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[0].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(0, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[1].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[1].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(1, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[2].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[2].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(2, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[3].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[3].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(3, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[4].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[4].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(4, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[5].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[5].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(5, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[6].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[6].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(6, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[7].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[7].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(7, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[8].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[8].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(8, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontFamily: "Arial",
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[9].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[9].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(9, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {selectedPlayers &&
                                selectedPlayers.length > 10 ? (
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[10].sleagueTeamPlayerId
                                          .playerId.fName
                                      }
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "white",
                                      }}
                                    >
                                      {
                                        selectedPlayers[10].sleagueTeamPlayerId
                                          .playerId.lName
                                      }
                                    </Typography>
                                  </>
                                ) : (
                                  ""
                                )}
                                {getPositionStr(10, true)}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {value === 1 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Formation Popularity
                      </Typography>
                      <Box className={classes.formationDataGridStyle}>
                        <DataGrid
                          autoHeight
                          rowHeight={48}
                          disableColumnMenu
                          rows={formationRows}
                          columns={formationColumns}
                          getRowClassName={(params) =>
                            `style--${params.row.id % 2 == 0}`
                          }
                          hideFooterSelectedRowCount
                          components={{
                            Footer: customFormationFooter,
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {value === 2 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Player Popularity
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.formationDataGridStyle}
                      >
                        <Table size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colspan={2}>Player</TableCell>
                              <TableCell align="center">
                                All Formations
                              </TableCell>
                              <TableCell align="center">
                                Popular Formation ({maxFormation})
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {playerPopularities.map((player, id) => (
                              <TableRow
                                key={id}
                                style={{
                                  backgroundColor:
                                    id % 2 == 1 ? "#F3E5F5" : "white",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width="0.5%"
                                  style={{
                                    paddingRight: "0px",
                                    marginRight: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {player.sleagueTeamPlayerId.jerseyNO}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ height: 36 }}
                                >
                                  <Box style={{ display: "flex" }}>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontFamily: "Arial",
                                        color: "#000000",
                                      }}
                                    >
                                      {player.sleagueTeamPlayerId.playerId.name}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Arial",
                                        color: "#757575",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      (
                                      {
                                        player.sleagueTeamPlayerId.playerId
                                          .position
                                      }
                                      )
                                    </Typography>
                                  </Box>
                                </TableCell>

                                <TableCell align="center">
                                  {player.allFormationVotes}
                                </TableCell>
                                <TableCell align="center">
                                  {player.popularFormationVotes +
                                    " (" +
                                    player.popularFormationPercentage +
                                    "%)"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TableCell colspan={2}>Total</TableCell>
                              <TableCell align="center">
                                {playerAllVotesSum}
                              </TableCell>
                              <TableCell align="center">
                                {playerPopularVotesSum}{" "}
                                {" (" + maxFormationVotes + "%)"}{" "}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  {value === 3 && (
                    <Box mb={1}>
                      <Typography gutterBottom variant="subtitle2">
                        <br />
                        Players With No Vote
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className={classes.dataGridStyle}
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell width="25%" colspan={2}>
                                Player
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(0, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(1, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(2, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(3, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(4, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(5, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(6, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(7, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(8, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(9, true)}
                              </TableCell>
                              <TableCell
                                align="center"
                                width="6%"
                                style={{
                                  fontWeight: 400,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                {getPositionStr(10, true)}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Rows for Zero Votes Players */}
                          <TableBody>
                            {communityNotSelected.map((selection, idx) => (
                              <TableRow key={idx}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width="0.5%"
                                  style={{
                                    paddingRight: "0px",
                                    marginRight: "0px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      fontFamily: "Arial",
                                      color: "#000000",
                                    }}
                                  >
                                    {selection.sleagueTeamPlayerId.jerseyNO}
                                  </span>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ height: 36 }}
                                >
                                  <Box style={{ display: "flex" }}>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontFamily: "Arial",
                                        color: "#000000",
                                      }}
                                    >
                                      {
                                        selection.sleagueTeamPlayerId.playerId
                                          .name
                                      }
                                    </Typography>

                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Arial",
                                        color: "#757575",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      (
                                      {
                                        selection.sleagueTeamPlayerId.playerId
                                          .position
                                      }
                                      )
                                    </Typography>
                                  </Box>
                                </TableCell>

                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[0] &&
                                parseInt(teamSeq.communitySeqList[0]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell
                                    align="center"
                                    className={adminStyle.tableCellSelected}
                                  >
                                    {selection.positionSeqNO1Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO1Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[1] &&
                                parseInt(teamSeq.communitySeqList[1]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO2Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO2Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[2] &&
                                parseInt(teamSeq.communitySeqList[2]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO3Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO3Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[3] &&
                                parseInt(teamSeq.communitySeqList[3]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO4Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO4Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[4] &&
                                parseInt(teamSeq.communitySeqList[4]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO5Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO5Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[5] &&
                                parseInt(teamSeq.communitySeqList[5]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO6Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO6Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[6] &&
                                parseInt(teamSeq.communitySeqList[6]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell
                                    align="center"
                                    className={adminStyle.tableCellSelected}
                                  >
                                    {selection.positionSeqNO7Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO7Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[7] &&
                                parseInt(teamSeq.communitySeqList[7]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO8Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO8Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[8] &&
                                parseInt(teamSeq.communitySeqList[8]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO9Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO9Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[9] &&
                                parseInt(teamSeq.communitySeqList[9]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO10Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO10Vote}
                                  </TableCell>
                                )}
                                {teamSeq &&
                                teamSeq.communitySeqList &&
                                teamSeq.communitySeqList[10] &&
                                parseInt(teamSeq.communitySeqList[10]) ===
                                  parseInt(
                                    selection.sleagueTeamPlayerId.playerSeqNO
                                  ) ? (
                                  <TableCell align="center">
                                    {selection.positionSeqNO11Vote}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    {selection.positionSeqNO11Vote}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Paper style={{ minWidth: 650, height: 570 }}>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            {userFavTeam &&
              fixtureTeamId &&
              sleagueTeamId &&
              fixtureId &&
              leagueId &&
              comSeqVal &&
              players && (
                <TeamInfoCom
                  userFavTeam={userFavTeam}
                  fixtureTeamId={fixtureTeamId}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  leagueId={leagueId}
                  comSeqVal={comSeqVal}
                  sleaguePlayers={players}
                />
              )}
          </DndProvider>
        </Paper>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseMobile}
        aria-labelledby="customized-dialog-title"
        open={openMobile}
        scroll="body"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMobile}
        ></BootstrapDialogTitle>
        <Paper style={{ width: "100%", minWidth: 320, height: 580 }}>
          <DndProvider backend={HTML5Backend} debugMode={true}>
            {userFavTeam &&
              fixtureTeamId &&
              sleagueTeamId &&
              fixtureId &&
              leagueId &&
              comSeqVal &&
              players && (
                <TeamInfoComMobile
                  userFavTeam={userFavTeam}
                  fixtureTeamId={fixtureTeamId}
                  sleagueTeamId={sleagueTeamId}
                  fixtureId={fixtureId}
                  leagueId={leagueId}
                  comSeqVal={comSeqVal}
                  sleaguePlayers={players}
                />
              )}
          </DndProvider>
        </Paper>
      </BootstrapDialog>
    </>
  );
};

export default FavTeamResults;
