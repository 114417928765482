import { Fragment, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  GET_USERPROFILE_QUERY,
  UPDATE_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION
} from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";
import adminStyle from "./Admin.module.scss";
import {project_url, admin_url, secretPass} from "../store/data/Constants";
import CryptoJS from "crypto-js";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Function : Position
## Description : Submit form data and create new record in position table
## Author : SP
## Date: : 2022/02/23
##########################################################################*/
export default function Profile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();



  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [email, setEmail] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [idletime, setIdletime] = useState();
  const [username, setUsername] = useState();
  const [autoLogin, setAutoLogin] = useState();
  const [favTeam, setFavTeam] = useState();
  const [preferedDateFormat, setPreferedDateFormat] = useState(localStorage.getItem("preferedDateFormat"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const today_YYYYmonthDD = moment().format('YYYY MMM, DD');
  const today_DDmonthYYYY = moment().format('DD MMM, YYYY');
  const today_monthDDYYYY = moment().format('MMM DD, YYYY');

  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true
  });

  const [updateUser] = useMutation(UPDATE_USERPROFILE_QUERY, {
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true
  });

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    }
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate("/lms/signin");
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") }
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate("/lms/signin");
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signin");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem(
        "refreshToken",
        refreshData.refreshToken.refreshToken
      );
      client.setHeader(
        "Authorization",
        `JWT ${refreshData.refreshToken.token}`
      );
    }
  };

  useEffect(
    () => {
      if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
        setAllSleagueTeams(dataSleague.allSleagueTeam);
        console.log("allSleaguesData----", dataSleague);
      }
      if (errorSleague) {
        if (
          errorSleague.graphQLErrors[0].message ===
          "You do not have permission to perform this action"
        )
          navigate("/lms/signin");
        if (errorSleague.graphQLErrors[0].message === "Signature has expired")
          navigate("/lms/signin");
      }
    },
    [dataSleague]
  );

  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only"
    //refetchQueries: [{ query: GET_ALL_SLEAGUETEAMS_QUERY }],
    //awaitRefetchQueries: true
  });
  useEffect(
    () => {
      if (!loading && data && data.profile) {
        console.log("Profile----", data);
        setValue("email", data.profile.email);
        setValue("username", data.profile.username);
        setValue("firstname", data.profile.firstName);
        setValue("lastname", data.profile.lastName);
        setValue("idletime", data.profile.profile.idleTime);
        setValue("autoLogin", data.profile.profile.autoLogin.toString());
        setValue("preferedDateFormat", data.profile.profile.preferedDateFormat);
        setTimeout(() => {
          console.log(data.profile.userFavTeamField);
        }, 2000);
        if (data.profile.userFavTeamField)
          // setValue(
          //   "favTeam",
          //   data.profile.userFavTeamField.sleagueTeamId.sleagueTeamId
          // );
          setFavTeam(data.profile.userFavTeamField.sleagueTeamId.sleagueTeamId);
        localStorage.setItem("username", data.profile.username);
        if (data.profile.isSuperuser) localStorage.setItem("role", CryptoJS.AES.encrypt("Admin",secretPass).toString());
        else localStorage.setItem("role", CryptoJS.AES.encrypt("Regular User",secretPass).toString());
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate("/lms/signin");
      }
    },
    [data, dataSleague]
  );

  /*
   * Function : setTimeout
   * Description : Disappear message after timeout
   */
  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  /*
   * Function : onSubmit
   * Description : Submit form data
   */
  const onSubmit = async formData => {
    await sleep(2000);
    console.log(formData.autoLogin, formData.favTeam);
    const { data:updateData, error:updateError } = await updateUser({
      variables: {
        email: formData.email,
        password: formData.password,
        firstname: formData.firstname,
        lastname: formData.lastname,
        idletime: parseInt(formData.idletime),
        username: formData.username,
        favTeam: formData.favTeam,
        autoLogin: formData.autoLogin,
        preferedDateFormat: formData.preferedDateFormat
      },
      headers: {
        Authorization: "JWT " + token
      }
    });
    if (updateError) {
      console.log("Error", updateError, updateData);
      setFailedMessage(updateError.graphQLErrors[0].message);
      setTimeout("");
    } else {

      localStorage.setItem("username", updateData.updateUser.username);
      if(updateData.updateUser.autoLogin){
        updateData.updateUser.autoLogin==="1"?localStorage.setItem("autoLogin", true):localStorage.setItem("autoLogin", false);
      }
      if (updateData.updateUser.preferedDateFormat){
        localStorage.setItem("preferedDateFormat", updateData.updateUser.preferedDateFormat);
      }

      console.log("Success");
      setSuccessMessage("Your account is updated.");
      setTimeout("");
      window.location.reload(false);
    }
  };

  //console.log(errors);
  const favTeamChange = event => {
    setFavTeam(event.target.value);
  };

  const preferedDateFormatChange = event => {
    setPreferedDateFormat(event.target.value);
  };

  // const getSampleDate = ()=><Time value={today} format="YYYY/MM/DD" />
  /////////////////////////////////////////////////////////////////////
  return (
    <>
      <Helmet>
        <title>Predictal | My profile</title>
      </Helmet>
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <form className={adminStyle.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={adminStyle.h2}>Profile Edit</h2>
          <label className={adminStyle.label} htmlFor="email">
            Email
          </label>
          <input
            className={adminStyle.input}
            id="email"
            {...register("email", { required: true })}
            value={email}
          />
          {errors.email && errors.email.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="password">
            New Password
          </label>
          <input
            className={adminStyle.input}
            type="password"
            id="password"
            {...register("password", { required: false })}
          />
          {errors.password && errors.password.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="firstname">
            First Name
          </label>
          <input
            className={adminStyle.input}
            id="firstname"
            {...register("firstname", { required: true })}
            value={firstname}
          />
          {errors.firstname && errors.firstname.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="lastname">
            Last Name
          </label>
          <input
            className={adminStyle.input}
            id="lastname"
            {...register("lastname", { required: true })}
            value={lastname}
          />
          {errors.lastname && errors.lastname.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="username">
            Username
          </label>
          <input
            className={adminStyle.input}
            id="username"
            {...register("username", { required: true })}
            value={username}
            disabled={true}
          />
          {errors.username && errors.username.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="idletime">
            Idletime
          </label>
          <input
            className={adminStyle.input}
            type="number"
            min="1"
            id="idletime"
            {...register("idletime", { required: true })}
            value={idletime}
          />
          {errors.idletime && errors.idletime.type === "required" && (
            <span>Required</span>
          )}

          <label className={adminStyle.label} htmlFor="autoLogin">
            Auto Login
          </label>

          <select
            className={adminStyle.select}
            id="autoLogin"
            {...register("autoLogin")}
            value={autoLogin}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <label htmlFor="favTeam">Favorite Team</label>
          <select
            className={adminStyle.select}
            id="favTeam"
            {...register("favTeam", { required: true })}
            value={favTeam}
            onChange={favTeamChange}
          >
            <option value="0">Select a Team ...</option>
            {allSleagueTeams.map(sleagueteam => (
              <option
                key={sleagueteam.sleagueTeamId}
                value={sleagueteam.sleagueTeamId}
              >
                {sleagueteam.teamId.shortName} ({sleagueteam.sleagueId.leagueId.title}{" "}
                - {sleagueteam.sleagueId.title})
              </option>
            ))}
          </select>

          <label htmlFor="preferedDateFormat">Prefered Date Format</label>

        <select
            className={adminStyle.select}
            id="preferedDateFormat"
            {...register("preferedDateFormat")}
            defaultValue={preferedDateFormat}
            onChange={preferedDateFormatChange}
          >
            <option key="1" value="YYYYmonthDD">Year, Month Day ({today_YYYYmonthDD})</option>
            <option key="2" value="DDmonthYYYY">Day Month, Year ({today_DDmonthYYYY})</option>
            <option key="3" value="monthDDYYYY">Month Day, Year ({today_monthDDYYYY})</option>

          </select>
          <input className={adminStyle.input} type="submit" />
          {successMessage && (
            <div className={adminStyle.success}>{successMessage}</div>
          )}
          {failedMessage && (
            <div className={adminStyle.error}>{failedMessage}</div>
          )}
        </form>
      </Grid>
    </>
  );
}
