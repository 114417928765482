import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createMuiTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { useQuery, useManualQuery } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";

import Positions from "./tabScreens/Positions";
import Stats from "./tabScreens/Stats";
import Games from "./tabScreens/Games";
import Bio from "./tabScreens/Bio";
import TabLayout from "./tabScreens/TabLayout";
import { HeadToHead } from "./HeadToHead";
import { ItemTypes } from "../../../../store/data/ItemTypes";
import TabsMainData from "./tabScreens/TabsMainData";
import HeadToHeadDialog from "./HeadToHeadDialog.js";
import { GET_SLEAGUETEAM_PLAYER_QUERY } from "../../../../graphQL/sleague_qry";
import { GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../../graphQL/gameStatAggr_qry";
import { GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY } from "../../../../graphQL/positionPlayedAggr_qry";

import { TeamSelectorContext } from "../UserTeamSelectorApp";
import {project_url} from "../../../../store/data/Constants";

var findAnd = require("find-and");
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingLeft: 22,
    paddingTop: 10,
    paddingRight: 22,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    minHeight: 100,
    borderRadius: 8,
  },
  autoComStyle: {
    "& label.Mui-focused": {
      color: "#643d74",
    },
    "& .MuiFormControl-root": {
      width: "60%",
      height: 48,
      marginBottom: 5,
      "& .MuiInputLabel-outlined": {
        fontSize: 16,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#643d74",
      },
      borderRadius: 8,
      height: 48,
      fontSize: 16,
    },
  },
  outlinedButtonStyle: {
    borderRadius: 8,
    width: "110px",
    padding: 0,
    height: "48px",
    textTransform: "none",
  },
  buttonStyle: {
    borderRadius: 8,
    width: "100px",
    padding: 0,
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
  h2hMainStyle: {
    color: "#4D4D4D",
    textAlign: "center",
    font: "12px Arial, sans-serif",

    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    //border: "1px",
    // border: "1px solid #D9D9D9",
    // borderRadius: 8,
    // backgroundColor:"#F7F4F7"
  },
  tableHeaderStyle: {
    fontSize: 10,
    fontWeight: 400,
  },
  tableHeader2LineStyle: {
    fontSize: 10,
    fontWeight: 400,
    width: 19,
    lineHeight: 1.2,
    display: "flex",
    textAlign: "center",
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    fontSize: 16,
    fontWeight: 600,
    textTransform: "none",
    color: "#4D4D4D",
    margin: 15,
    marginLeft: 0,
    border: 0,
    "&.Mui-checked": {
      border: 1,
    },
    "&:not(:first-of-type)": {
      width: 150,
      height: 48,
      borderRadius: 8,
    },
    "&:first-of-type": {
      width: 150,
      height: 48,
      borderRadius: 8,
    },
  },
}));

// const StyledTabs = styled(Tabs)`
//   &.MuiTabs-root {
//     padding-top: 10px;
//     min-height: 20px;
//     height: 40px;
//     width: 100%;
//   }
// `;
const colors = {
  gray: "#c4c4c4",
  gk: "#D0CFCF",
  gkLight: "#F3F3F3",
  def: "#D8FBFF",
  defLight: "#ECFDFF",
  mid: "#D1F2D4",
  midLight: "#F0FFF1",
  fr: "#FFB0B0",
  frLight: "#FFE2E2",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
//<Box sx={{ p: 3 }}>
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const PlayerInfo = ({
  droppedPlayerNamesData,
  userSeqData,
  userFavTeam,
  teamSelectionData,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const showSeqNo = useContext(TeamSelectorContext);
  const [filter, setFilter] = useState("");
  const [selectedRole, setSelectedRole] = useState("goalkeeper");
  const [userSeq, setUserSeq] = useState([]);
  const [value, setValue] = useState(0);
  const [bgColor, setBgColor] = useState(colors.gk);
  const [bgLightColor, setBgLightColor] = useState(colors.gkLight);
  const [players, setPlayers] = useState([]);
  const [playersList, setPlayersList] = useState([]);
  const [sleagueTeamGameStatsAggr, setSleagueTeamGameStatsAggr] = useState([]);
  const [
    sleagueTeamPositionPlayedAggr,
    setSleagueTeamPositionPlayedAggr,
  ] = useState([]);
  const [pathname] = useState(window.location.pathname);

  const [sleagueTeamPlayer] = useManualQuery(GET_SLEAGUETEAM_PLAYER_QUERY, {
    fetchPolicy: "network-only",
  });

  const [fetchSleagueTeamGameStatAggrs] = useManualQuery(
    GET_GAME_STATS_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [fetchSleagueTeamPosPlayedAggrs] = useManualQuery(
    GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [droppedH2HNames, setDroppedH2HNames] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [h2HBox, setH2HBox] = useState([
    { accepts: [ItemTypes.PLAYER], lastH2HDroppedItem: [] },
    { accepts: [ItemTypes.PLAYER], lastH2HDroppedItem: [] },
  ]);

  function isDropped(h2hName) {
    return droppedH2HNames.indexOf(h2hName) > -1;
  }
  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const { loading, error, data } = await sleagueTeamPlayer({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      // get players of userFavTeam
      if (!loading && data && data.sleagueTeamPlayer) {
        console.log(
          "userFavTeam.sleagueteam---------",
          data.sleagueTeamPlayer,
          userFavTeam.sleagueteam
        );
        let resFound = [];
        let formationPos = "";
        let draftList = [];
        data.sleagueTeamPlayer.map((player) => {
          resFound = findAnd.returnFound(teamSelectionData, {
            name: player.playerId.name,
          });
          formationPos = "";
          if (resFound) formationPos = resFound.formationPos;
          draftList.push({ ...player, formationPos });
        });
        console.log("draftList____________0000",draftList)
        setPlayers(draftList);
      }
      if (error && error.graphQLErrors[0].message === "Signature has expired") {
        navigate(`/lms/signin/?next=${pathname}`);
      }
    }
  }, [userFavTeam, teamSelectionData]);

  useEffect(() => {
    setUserSeq(userSeqData);
  }, [userSeqData]);

  useEffect(async () => {
    console.log("userFavTeam && userFavTeam.sleagueteam",userFavTeam)
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingGSAggr,
        error: errorGSAggr,
        data: dataGSAggr,
      } = await fetchSleagueTeamGameStatAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });

      if (!loadingGSAggr && dataGSAggr && dataGSAggr.gameStatAggrSleagueteam) {
        setSleagueTeamGameStatsAggr(dataGSAggr.gameStatAggrSleagueteam);
        console.log("sleagueTeamGameStatsAggrData----", dataGSAggr);
      }
      else {
        console.log("errorGSAggr___",errorGSAggr)
      }
    }
  }, [userFavTeam]);

  useEffect(async () => {
    if (userFavTeam && userFavTeam.sleagueteam) {
      const {
        loading: loadingPosPlayedAggr,
        error: errorPosPlayedAggr,
        data: dataPosPlayedAggr,
      } = await fetchSleagueTeamPosPlayedAggrs({
        variables: {
          sleagueTeamId: parseInt(userFavTeam.sleagueteam.sleagueTeamId),
        },
      });
      console.log("dataPosPlayedAggr______", dataPosPlayedAggr);
      if (
        !loadingPosPlayedAggr &&
        dataPosPlayedAggr &&
        dataPosPlayedAggr.positionPlayedAggrSleagueteam
      ) {
        setSleagueTeamPositionPlayedAggr(
          dataPosPlayedAggr.positionPlayedAggrSleagueteam
        );
        console.log("sleagueTeamPositionPlayedAggr----", dataPosPlayedAggr);
      }
    }
  }, [userFavTeam]);

  useEffect(() => {
    // Updating players by data read from game_stat_aggr table
    console.log(
      "playerInfo_______",
      players,
      "sleagueTeamGameStatsAggr",sleagueTeamGameStatsAggr,
      "sleagueTeamPositionPlayedAggr",sleagueTeamPositionPlayedAggr
    );
    if (
      players.length > 0 &&
      sleagueTeamGameStatsAggr.length > 0 &&
      sleagueTeamPositionPlayedAggr.length > 0
    ) {
      let foundGameStatAggr = {};
      let foundPlayedPositionAggr = {};

      players.map((player) => {
        foundGameStatAggr = sleagueTeamGameStatsAggr.find(
          (item) =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        //console.log("foundGameStatAggr_____", foundGameStatAggr);
        if (foundGameStatAggr) {
          player["gamesInfo"] = {
            full: {
              gamesNo: foundGameStatAggr.fullGame,
              minutes: foundGameStatAggr.fullMin,
            },
            subIn: {
              gamesNo: foundGameStatAggr.subinGame,
              minutes: foundGameStatAggr.subinMin,
            },
            subOut: {
              gamesNo: foundGameStatAggr.suboutGame,
              minutes: foundGameStatAggr.suboutMin,
            },
            subInOut: {
              gamesNo: foundGameStatAggr.subInOutGame,
              minutes: foundGameStatAggr.subInOutMin,
            },
            eject: {
              gamesNo: foundGameStatAggr.ejectGame,
              minutes: foundGameStatAggr.ejectMin,
            },
            total: {
              gamesNo: foundGameStatAggr.totalGame,
              minutes: foundGameStatAggr.totalMin,
            },
            perGame: foundGameStatAggr.avgMinPerGame,
          };

          player["stats"] = {
            games: foundGameStatAggr.totalGame,
            goals: foundGameStatAggr.goal,
            ownGoals: foundGameStatAggr.ownGoal,
            yc: foundGameStatAggr.cardYellow,
            rc: foundGameStatAggr.cardRed,
          };
        } else {
          player["gamesInfo"] = {
            full: {
              gamesNo: 0,
              minutes: 0,
            },
            subIn: {
              gamesNo: 0,
              minutes: 0,
            },
            subOut: {
              gamesNo: 0,
              minutes: 0,
            },
            subInOut: {
              gamesNo: 0,
              minutes: 0,
            },
            eject: {
              gamesNo: 0,
              minutes: 0,
            },
            total: {
              gamesNo: 0,
              minutes: 0,
            },
            perGame: 0,
          };

          player["stats"] = {
            games: 0,
            goals: 0,
            ownGoals: 0,
            yc: 0,
            rc: 0,
          };
        }
      });
      players.map((player) => {
        foundPlayedPositionAggr = sleagueTeamPositionPlayedAggr.find(
          (item) =>
            parseInt(item.sleagueTeamPlayerId.sleagueTeamPlayerId) ===
            parseInt(player.sleagueTeamPlayerId)
        );
        if (foundPlayedPositionAggr) {
          player["counts"] = {
            gkCount: foundPlayedPositionAggr.GKCount,
            ldefCount: foundPlayedPositionAggr.LDEFCount,
            cdefCount: foundPlayedPositionAggr.CDEFCount,
            rdefCount: foundPlayedPositionAggr.RDEFCount,
            lmfCount: foundPlayedPositionAggr.LMFCount,
            cmfCount: foundPlayedPositionAggr.CMFCount,
            rmfCount: foundPlayedPositionAggr.RMFCount,
            lforCount: foundPlayedPositionAggr.LFORCount,
            cforCount: foundPlayedPositionAggr.CFORCount,
            rforCount: foundPlayedPositionAggr.RFORCount,
          };
        } else {
          player["counts"] = {
            gkCount: 0,
            ldefCount: 0,
            cdefCount: 0,
            rdefCount: 0,
            lmfCount: 0,
            cmfCount: 0,
            rmfCount: 0,
            lforCount: 0,
            cforCount: 0,
            rforCount: 0,
          };
        }
      });
      players.sort(function (a, b) {
        if (a.gamesInfo && b.gamesInfo) {
          return (
            parseInt(b.gamesInfo.total.gamesNo) -
            parseInt(a.gamesInfo.total.gamesNo)
          );
        }
      });
      setPlayersList(players);
    }
  }, [sleagueTeamGameStatsAggr, sleagueTeamPositionPlayedAggr, players]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleToggleButtonChange = (event) => {
    switch (event.target.value) {
      case "goalkeepers":
        setBgColor(colors.gk);
        setBgLightColor(colors.gkLight);
        setSelectedRole("goalkeeper");
        break;
      case "defenders":
        setBgColor(colors.def);
        setBgLightColor(colors.defLight);
        setSelectedRole("defender");
        break;
      case "midfielders":
        setBgColor(colors.mid);
        setBgLightColor(colors.midLight);
        setSelectedRole("midfielder");
        break;
      case "forwards":
        setBgColor(colors.fr);
        setBgLightColor(colors.frLight);
        setSelectedRole("forward");
        break;
      default:
        setBgColor(colors.gk);
        setBgLightColor(colors.gkLight);
        break;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onRemoveH2HDroppedItem = (index) => {
    // remove player from droppedH2HNames list
    droppedH2HNames.splice(index, 1);
    //console.log("indexxxxxonRemoveH2HDroppedItem", index, droppedH2HNames);
    setH2HBox(
      update(h2HBox, {
        [0]: {
          lastH2HDroppedItem: {
            $splice: [[index, 1]],
          },
        },
      })
    );
  };
  const handleDrop = useCallback(
    (index, item) => {
      var resFound = findAnd.returnFound(h2HBox, {
        playerSeqNO: item.playerSeqNO,
      });
      // Check that the player's name is not duplicated for comparison
      if (resFound) return;
      const { name } = item;
      // If a player is dropped into the position of another player, delete the previous player
      if (h2HBox[index].lastH2HDroppedItem) {
        var i = droppedH2HNames.indexOf(h2HBox[index].lastH2HDroppedItem.name);
        i > -1 && droppedH2HNames.splice(i, 1);
      }
      setDroppedH2HNames(
        update(droppedH2HNames, name ? { $push: [name] } : { $push: [] })
      );
      let draftH2hBox = update(h2HBox, {
        [index]: {
          lastH2HDroppedItem: {
            $set: item,
          },
        },
      });
      setH2HBox(draftH2hBox);
      if (
        draftH2hBox[0].lastH2HDroppedItem.name &&
        draftH2hBox[1].lastH2HDroppedItem.name
      )
        setOpenDialog(true);
    },
    [droppedH2HNames, h2HBox]
  );
  const closeH2HDialog = () => {
    setH2HBox([{ lastH2HDroppedItem: [] }, { lastH2HDroppedItem: [] }]);
    setDroppedH2HNames([]);
    setOpenDialog(false);
  };
  return (
    <Card className={classes.cardContainer}>
      <Box style={{ width: "100%" }}>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            height: 80,
          }}
        >
          <StyledToggleButtonGroup
            size="small"
            //value={selectedRole}
            exclusive
            onChange={handleToggleButtonChange}
            aria-label="text alignment"
          >
            <ToggleButton
              style={{
                backgroundColor: colors.gk,
                border: selectedRole === "goalkeeper" ? "1px solid black" : "",
              }}
              value="goalkeepers"
            >
              Goalkeepers
            </ToggleButton>
            <ToggleButton
              style={{
                backgroundColor: colors.def,
                border: selectedRole === "defender" ? "1px solid black" : "",
              }}
              value="defenders"
            >
              Defenders
            </ToggleButton>
            <ToggleButton
              style={{
                backgroundColor: colors.mid,
                border: selectedRole === "midfielder" ? "1px solid black" : "",
              }}
              value="midfielders"
            >
              Midfielders
            </ToggleButton>
            <ToggleButton
              style={{
                backgroundColor: colors.fr,
                border: selectedRole === "forward" ? "1px solid black" : "",
              }}
              value="forwards"
            >
              Forwards
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Paper>
      </Box>

      <Grid container style={{ paddingBottom: 10 }} spacing={2}>
        <Grid item md={5}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #D9D9D9",
              borderRadius: 8,
              backgroundColor: "#F7F4F7",
            }}
          >
            <div
              className={classes.h2hMainStyle}
              style={{
                height: "30px",
                width: "100%",
              }}
            >
              Head-to-Head Comparison
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              {h2HBox.map(({ accepts, lastH2HDroppedItem }, index) => (
                <>
                  <HeadToHead
                    key={index}
                    accept={ItemTypes.PLAYER}
                    index={index}
                    lastH2HDroppedItem={lastH2HDroppedItem}
                    onDrop={(item) => handleDrop(index, item)}
                    onRemoveH2HDroppedItem={onRemoveH2HDroppedItem}
                  />
                </>
              ))}
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={7}
          style={{ paddingTop: "5px", paddingLeft: "10px" }}
        >
          <Grid>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#765285",
                },
              }}
              style={{ paddingLeft: "5px", height: "48px" }}
              variant="standard" //"fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Games"
                style={{
                  width: "25%",
                  fontSize: 16,
                  fontWeight: 600,
                  color: value === 0 ? "#765285" : "#4D4D4D",
                }}
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  width: "25%",
                  fontSize: 16,
                  fontWeight: 600,
                  color: value === 1 ? "#765285" : "#4D4D4D",
                }}
                label="Stats"
                {...a11yProps(1)}
              />
              <Tab
                style={{
                  width: "25%",
                  fontSize: 16,
                  fontWeight: 600,
                  color: value === 2 ? "#765285" : "#4D4D4D",
                }}
                label="Positions"
                {...a11yProps(2)}
              />
              <Tab
                style={{
                  width: "25%",
                  fontSize: 16,
                  fontWeight: 600,
                  color: value === 3 ? "#765285" : "#4D4D4D",
                }}
                label="Bio"
                {...a11yProps(3)}
              />
            </Tabs>
          </Grid>
          <Grid >
            {value === 0 && (
              <Box
                mt={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "95%",
                  paddingLeft: 0,
                  paddingRight: 20,
                }}
              >
                <Typography className={classes.tableHeaderStyle}>
                  Full
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Ejected
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Sub-in
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Sub-out
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Sub-in-out
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Total
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Per Game
                </Typography>
              </Box>
            )}
            {value === 1 && (
              <Box
                mt={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "90%",
                  paddingLeft: 0,
                  paddingRight: 40,
                }}
              >
                <Typography className={classes.tableHeaderStyle}>
                  Games
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Ejected
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Goals
                </Typography>
                <Typography className={classes.tableHeaderStyle}>YC</Typography>
                <Typography className={classes.tableHeaderStyle}>RC</Typography>
              </Box>
            )}
            {value === 2 && (
              <Box
                mt={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "90%",
                  paddingLeft: 0,
                  paddingRight: 30,
                }}
              >
                <Typography
                  className={classes.tableHeader2LineStyle}
                  style={{ paddingTop: 5 }}
                >
                  GK
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  L DEF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  C DEF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  R DEF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  L MF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  C MF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  R MF
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  L FOR
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  C FOR
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}>
                  R FOR
                </Typography>
              </Box>
            )}
            {value === 3 && (
              <Box
                mt={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "90%",
                  paddingLeft: 0,
                  paddingRight: 25,
                }}
              >
                <Typography className={classes.tableHeaderStyle}>
                  Age
                </Typography>
                <Typography className={classes.tableHeader2LineStyle}  >
                  Wgt (kg)
                </Typography>
                <Typography className={classes.tableHeader2LineStyle} >
                  Ht (cm)
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  Foot
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  POW
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  SPD
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  TCH
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  STA
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  ATT
                </Typography>
                <Typography className={classes.tableHeaderStyle}>
                  DEF
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ maxHeight: "440px", overflowY: "scroll", paddingTop:8  }}
      >

        <Grid item md={5}>
          <TabLayout backgroundColor={bgColor}>
            <TabsMainData
              selectedRole={selectedRole}
              players={players}
              droppedPlayerNamesData={droppedPlayerNamesData}
            />
          </TabLayout>
        </Grid>
        <Grid item md={7}>
          <div
            style={{
              flexDirection: "row",
            //  justifyContent: "space-between",
              width: "100%",
              //height:"100%",
              backgroundColor: bgLightColor,
            }}
          >
            <TabPanel value={value} index={0}>
                <Games
                  players={playersList}
                  selectedRole={selectedRole}
                  gameStatsAggrList={sleagueTeamGameStatsAggr}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stats players={playersList} selectedRole={selectedRole} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Positions
                players={playersList}
                selectedRole={selectedRole}
                positionPlayedAggrList={sleagueTeamPositionPlayedAggr}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Bio players={playersList} selectedRole={selectedRole} />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
      {showSeqNo && userSeq && userSeq.length > 0 && (
        <Typography variant="h5" style={{paddingTop:10}}>User Seq: {userSeq}</Typography>
      )}
      <div>
        {openDialog && (
          <HeadToHeadDialog
            title="HEAD TO HEAD COMPARISON"
            contentMsg="content"
            closeH2HDialog={closeH2HDialog}
            head2headData={h2HBox}
            players={playersList}
          />
        )}
        <div />
      </div>
    </Card>
  );
};

export default PlayerInfo;

//{index === 0 <Typography style={{paddingRight:2}}/>
