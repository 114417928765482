export default {
  h1: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '-0.06px'
  },
  h3: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.3,
    letterSpacing: '-0.05px'
  },
  h5: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4, //1.3,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.4, //1.3,
    letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 500
  },
  subtitle1: { // desktop
    fontSize: 32,
    fontWeight: 600,
    marginBottom: 0,
  },
  subtitle2: { // mobile
    fontSize: 24,
    fontWeight: 600
  },
  button: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: "100px"
  },
  body1:{ // desktop
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.7,
    color: "rgba(33, 33, 33, 0.8)"
  },
  body2:{ // mobile
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.6,
    color: "rgba(33, 33, 33, 0.8)"
  }

};
