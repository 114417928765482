import { useState, useEffect, useContext, createContext } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";

import {
  useQuery,
  useMutation,
  ClientContext,
  useManualQuery
} from "graphql-hooks";
import {
  GET_USERPROFILE_QUERY,
  REFRESH_TOKEN_MUTATION
} from "../../graphQL/user_qry";
import { GET_ALL_SETTINGS_QUERY } from "../../graphQL/setting_qry";
import { useNavigate, useParams } from "react-router-dom";

import PlayerInfo from "./rightPanel/PlayerInfo";


export const TeamSelectorContext = createContext();

const SelectedTeamCoachTeamSelectorDialog = ({  
  fixtureTeam,fixtureTeamName
  
}) => {
	
	const [userSeqData, setUserSeqData] = useState([]);
	const [droppedPlayerNamesData, setDroppedPlayerNamesData] = useState([]);
  const [coachTeam, setCoachTeam] = useState([]);
  
		  
  useEffect(
    () => {
      console.log('fixture',fixtureTeam)
      setCoachTeam({
          teamInfo: fixtureTeam.sleagueTeamId.teamId,
          sleagueteam: fixtureTeam.sleagueTeamId
        });
      
    },
    []
  );
  
  
  
  return (
    <Grid item xs={6} sm={6} md={6}>
	<h3 align="center">
        {fixtureTeamName}  Squad
      </h3>
          <Paper style={{ width: "100%", height: 600, minWidth: 650 }}>
            <PlayerInfo
              userSeqData={userSeqData}               
              userFavTeam={coachTeam}
              droppedPlayerNamesData={droppedPlayerNamesData}
            />
          </Paper>
        </Grid>
  );

}

export default SelectedTeamCoachTeamSelectorDialog;
