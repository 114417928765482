const CreateWeek_mutate = `
    mutation createWeek($name: String!, 
                        $sleagueId: Int!,
                        $startDate: DateTime!,
                        $endDate: DateTime!)
	{
		createWeek(name : $name,
                   sleagueId: $sleagueId,
                   startDate: $startDate,
                   endDate:$endDate)
		{
			week
			{
				weekId
				sleagueId
				{
					sleagueId						
				}
				name
                startDate
                endDate
			}
		}
	}	
`;

const GetCurrentSleagues_query = `
	query
	{
		currentBucketSleague
		{
			currentBucketSleagueId
			sleagueId
			{
				sleagueId	
				leagueId
				{
					title
					subtitle
				}
				sleagueId
				seasonName
			}
		}
	}
`;

const GetSleagueWeeks_query = `
	query($sleagueId: Int!){
		sleagueWeek(sleagueId: $sleagueId) {
			weekId
			sleagueId
			{
				sleagueId
				seasonName
			}
			name
            startDate
            endDate
		}
	}
`

const GetAllWeeks_query = `
    query{
		allWeeks{
			weekId
			sleagueId{
				sleagueId
			}
			name
            startDate
            endDate
		}
    }
`;

const GetWeek_query = `
    query($weekId:Int!){
      weekObject(weekId:$weekId){
        weekId
        name
        startDate
        endDate
        sleagueId {
          sleagueId
          title
          seasonName
          leagueId {
            leagueId
            title
            subtitle
          }
        }
    }
  }
`;

const EditWeek_mutate = `
    mutation editWeek(
	    $weekId: Int!,
        $sleagueId : Int!,
        $name: String!,
        $startDate : DateTime!,
        $endDate : DateTime!
    )
	{
	    editWeek(
		    weekId : $weekId,
			sleagueId : $sleagueId,
            name: $name,
            startDate: $startDate,
            endDate: $endDate,
		)
		{
			week
			{
				weekId
				sleagueId
				{
					sleagueId						
				}
				name
                startDate
                endDate
			}
		}

	}
`;


module.exports = {
  CREATE_WEEK_MUTATION: CreateWeek_mutate,  
  GET_CURRENT_SLEAGUES_QUERY: GetCurrentSleagues_query,
  GET_ALL_WEEKS_QUERY: GetAllWeeks_query,
  GET_SLEAGUE_WEEK_QUERY: GetSleagueWeeks_query,
  GET_WEEK_QUERY: GetWeek_query,
  EDIT_WEEK_MUTATION: EditWeek_mutate
}
