import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { seo_tag_config } from "../../store/data/Constants";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
    maxWidth: 500,
    minHeight: 311,
  },
  cardContainerTablet: {
    paddingBottom: 38,
    paddingTop: 68,
    paddingLeft: 48,
    paddingRight: 48,
    maxWidth: 550,
    height: 311,
  },
  cardTablet: {
    display: "flex",
    flexDirection: "column",
    minHeight: 260,
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingLeft: 45,
    display: "flex",
    flexDirection: "column",
    maxHeight: 311,
  },
  cardContentTablet: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    minHeight: 260,
  },
  cardMobile: {
    display: "flex",
    flexDirection: "column",
    minHeight: 340,
  },
  cardContentMobile: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 60,
  },
  buttonStyle: {
    borderRadius: 12,
    width: "158px",
    height: "48px",
    textTransform: "none",
    "&:active": {
      backgroundColor: "#765285",
    },
    "&:hover": {
      backgroundColor: "#765285",
    },
  },
}));

const ResetPassConfirmation = () => {

  const navigate = useNavigate();
  
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    tabletView: false,
  });
  const { mobileView, tabletView } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 768
        ? setState(() => ({ mobileView: true }))
        : window.innerWidth < 821 && window.innerWidth > 767
        ? setState(() => ({ mobileView: false, tabletView: true }))
        : setState(() => ({ tabletView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <>
      {!seo_tag_config ?
        <Helmet>
          <title>Lower-RTS</title>
        </Helmet>
      :
        <Helmet>
          <title>Predictal | Successful Reset Password</title>
          <meta 
            name="description" 
            content="Predcital reset password to football team prediction" 
          />
          <meta 
            property="keywords" 
            content="Predcital, reset password, reimagine team selector, team selection prediction, starting line-up, starting 11 team, starting XI team, starting line-up prediction, football prediction" 
          />
        </Helmet>
      }

      {mobileView && (
        <Card className={classes.cardMobile}>
          <CardContent className={classes.cardContentMobile}>
            <Box
              mt={2}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
            </Box>
            <Box
              mt={2}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h2">
                Your password is successfully reset
              </Typography>
            </Box>
            <Box
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h5">
                Please sign in to your account.
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                className={classes.buttonStyle}
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => navigate("/lms/signin")}
              >
                Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      {tabletView && (
        <Container className={classes.cardContainerTablet} maxWidth="sm">
          <Card className={classes.cardTablet}>
            <CardContent className={classes.cardContentTablet}>
              <Box
                mt={2}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
              </Box>
              <Box
                mt={2}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h1">Your password is successfully reset</Typography>
              </Box>
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5">
                  Please sign in to your account.
                </Typography>
              </Box>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/lms/signin")}
                >
                  Sign In
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
      {!mobileView && !tabletView && (
        <Container className={classes.cardContainer} maxWidth="sm">
          <Card style={{ display: "flex", flexDirection: "row" }}>
            <CardContent className={classes.cardContent}>
              <Box
                mt={1}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CheckCircleIcon sx={{ color: "#eadcf0", fontSize: 60 }} />
              </Box>
              <Box
                //mt={4}
                mb={1}
                mr={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h1">Your password is successfully reset</Typography>
              </Box>
              <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h5">
                  Please sign in to your account.
                </Typography>
              </Box>
              <Box mt={2} mb={2} display="flex" justifyContent="center">
                <Button
                  className={classes.buttonStyle}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/lms/signin")}
                >
                  Sign In
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
};

export default ResetPassConfirmation;
