import React, { useState, useEffect, useCallback, useContext } from "react";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";

import { Box, Typography, Button, makeStyles } from "@material-ui/core";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
// import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";

import AlertDialog from "../../../components/AlertDialog.js";
import leagueLogo from "../../../assets/Premier_League-Logo.png";
import imgRes from "../../../assets/positionImgs/res.png";
import footField from "../../../assets/positionImgs/football_field.png";
import { Selection } from "./Selection";
import { createStyles, Theme } from "@material-ui/core/styles";
import { POSITIONS_DATA } from "../../../store/data/DummyData";
import { REFRESH_TOKEN_MUTATION } from "../../../graphQL/user_qry";
import { ItemTypes } from "../../../store/data/ItemTypes";
import { GET_LEAGUE_FORMATIONS_QUERY } from "../../../graphQL/formationToLeague_qry";

var findAnd = require("find-and");
const mainPath = "/lms/media/";

const useStyles = makeStyles(theme => ({
  teamSelStyle: {
    fontSize: 24,
    fontWeight: 600,
    color: "#4D4D4D",
    paddingTop: 24,
    paddingBottom: 24,
    // paddingLeft: 221,
  },

  formationStyle: {
    // textAlign: "left",
    paddingTop: 12,
    paddingBottom: 24,
    // paddingLeft: 221,

    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#4D4D4D",
  },
}));

const TeamInfo = ({
  userFavTeam,
  fixtureTeamId,
  sleagueTeamId,
  fixtureId,
  leagueId,
  userSeqData,
  comSeqVal,
  sleaguePlayers,
  embargoTime,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [players, setPlayers] = useState([]);
  const [formations, setFormations] = useState();
  const [selectedFormation, setSelectedFormation] = useState("");
  const [selectedFormationTitle, setSelectedFormationTitle] = useState("");
  const [droppedPlayerNames, setDroppedPlayerNames] = useState([]);
  const [teamSelections, setTeamSelections] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [embargoTimeStatus, setEmbargoTimeStatus] = useState(false);
  const [pathname] = useState(window.location.pathname);

  const {
    loading: leagueFormationsDataLoading,
    error: leagueFormationsDataError,
    data: leagueFormationsData,
  } = useQuery(GET_LEAGUE_FORMATIONS_QUERY, {
    variables: {
      leagueId: leagueId,
    },
  });

  useEffect(() => {
    let communityVal = comSeqVal.slice(0, 2).trim();
    if (communityVal) setSelectedFormation(communityVal);
  }, [comSeqVal]);

  useEffect(() => {
    setPlayers(
      sleaguePlayers.map(player => {
        let name = player.playerId.name;
        let onFieldName = player.playerId.onFieldName;
        let position = player.playerId.position;
        let jerseyNO = player.jerseyNO;
        let playerSeqNO = player.playerSeqNO;
        let type = ItemTypes.PLAYER;
        return { name,onFieldName, position, jerseyNO, playerSeqNO, type };
      })
    );
  }, [sleaguePlayers]);

  useEffect(() => {
    let findPositionsData = POSITIONS_DATA.find(x => {
      return x.formationSeqNo === selectedFormation;
    });
    if (!findPositionsData) return [];

    let resFound = [];
    let droppedPlayerNamesVal = [];
    let draftTeamSelections = findPositionsData.positionsData;
    let val = "";
    ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"].map(i => {
      switch (i) {
        case "01":
          val = comSeqVal.slice(4, 6);
          break;
        case "02":
          val = comSeqVal.slice(8, 10);
          break;
        case "03":
          val = comSeqVal.slice(12, 14);
          break;
        case "04":
          val = comSeqVal.slice(16, 18);
          break;
        case "05":
          val = comSeqVal.slice(20, 22);
          break;
        case "06":
          val = comSeqVal.slice(24, 26);
          break;
        case "07":
          val = comSeqVal.slice(28, 30);
          break;
        case "08":
          val = comSeqVal.slice(32, 34);
          break;
        case "09":
          val = comSeqVal.slice(36, 38);
          break;
        case "10":
          val = comSeqVal.slice(40, 42);
          break;
        case "11":
          val = comSeqVal.slice(44, 46);
      }

      resFound = findAnd.returnFound(players, {
        playerSeqNO: val,
      });

      if (resFound) {
        draftTeamSelections = findAnd.changeProps(
          draftTeamSelections,
          { positionSeqNo: i },
          {
            lastDroppedItem: {
              name: resFound.name,
	      onFieldName: resFound.onFieldName,
              playerSeqNO: resFound.playerSeqNO,
              jerseyNO: resFound.jerseyNO,
            },
          }
        );
        droppedPlayerNamesVal.push(resFound.name);
      }
    });
    setDroppedPlayerNames(droppedPlayerNamesVal);
    setTeamSelections(draftTeamSelections);
  }, [players]);

  useEffect(() => {
    const formationsList = [];
    const parentFormations = [];

    if (
      !leagueFormationsDataLoading &&
      leagueFormationsData &&
      leagueFormationsData.leagueFormation
    ) {
      leagueFormationsData.leagueFormation.map(leagueFrm => {
        if (!parentFormations.includes(leagueFrm.formationId.formationPrimary)) {
          parentFormations.push(leagueFrm.formationId.formationPrimary);
        }
      });

      parentFormations.map((formation, i) => {
        formationsList.push({ mainCategory: formation });
        let derivedFormations = leagueFormationsData.leagueFormation.filter(frm => {
          return frm.formationId.formationPrimary === formation;
        });
        formationsList[i].derivedCategories = derivedFormations;
      });
    }

    var resFound = findAnd.returnFound(formationsList, {
      formationSeqNO: selectedFormation,
    });

    if (resFound) setSelectedFormationTitle(resFound.title);
    setFormations(formationsList);
  }, [leagueFormationsData, selectedFormation]);

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 500 milliseconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 200);
        loadImg.onerror = err => {
          reject(err);
        };
      });
    };
    let IMAGES = [];
    if (teamSelections) {
      if (teamSelections.GK) IMAGES.push(imgRes, teamSelections.GK[0].img);
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_1)
        teamSelections.DEF.defLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.DEF && teamSelections.DEF.defLane_col_2)
        teamSelections.DEF.defLane_col_2.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_1)
        teamSelections.MID.midLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_2)
        teamSelections.MID.midLane_col_2.map(i => IMAGES.push(i.img));
      if (teamSelections.MID && teamSelections.MID.midLane_col_3)
        teamSelections.MID.midLane_col_3.map(i => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_1)
        teamSelections.FOR.forLane_col_1.map(i => IMAGES.push(i.img));
      if (teamSelections.FOR && teamSelections.FOR.forLane_col_2)
        teamSelections.FOR.forLane_col_2.map(i => IMAGES.push(i.img));
    }
    if (IMAGES.length == 0) return;
    Promise.all(IMAGES.map(img => loadImage(img)))
      .then(() => setIsLoad(true))
      .catch(err => console.log("Failed to load images", err));
  }, [teamSelections]);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/signin/?next=${pathname}`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate("/lms/signout");
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  return (
    <Box
      mt={2}
      mb={2}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography className={classes.teamSelStyle}>Community Team</Typography>

      {!isLoad ? (
        <div
          style={{
            backgroundImage: `url(${footField})`,
            width: 580,
            height: 408,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            float: "left",
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                //  overflow: "hidden",
                clear: "both",
              }}
            >
              {teamSelections.GK &&
                teamSelections.GK.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
            </div>
            {teamSelections.DEF && teamSelections.DEF.defLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.DEF.defLane_col_1.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.DEF && teamSelections.DEF.defLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.DEF.defLane_col_2.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_1.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_2.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.MID && teamSelections.MID.midLane_col_3 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.MID.midLane_col_3.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            {teamSelections.FOR && teamSelections.FOR.forLane_col_1 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.FOR &&
                  teamSelections.FOR.forLane_col_1.map(
                    ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                      return (
                        <div>
                          <Selection
                            key={index}
                            accept={accepts}
                            img={img}
                            height={height}
                            width={width}
                            text={text}
                            lastDroppedItem={lastDroppedItem}
                          />
                        </div>
                      );
                    }
                  )}
              </div>
            )}
            {teamSelections.FOR && teamSelections.FOR.forLane_col_2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {teamSelections.FOR.forLane_col_2.map(
                  ({ accepts, lastDroppedItem, text, img, height, width }, index) => {
                    return (
                      <div>
                        <Selection
                          key={index}
                          accept={accepts}
                          img={img}
                          height={height}
                          width={width}
                          text={text}
                          lastDroppedItem={lastDroppedItem}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            <div
              style={{
                height: 408,
                width: 29,
                backgroundImage: `url(${imgRes})`,
                //backgroundRepeat: "no-repeat",
                flexDirection: "column",
                //border: "1px solid white"
              }}
            />
          </div>
        </div>
      )}

      <Box
        style={{
          width: "90%",
        }}
      >
        <Typography className={classes.formationStyle}>
          Formation: {selectedFormationTitle}
        </Typography>
      </Box>

      {embargoTimeStatus && (
        <AlertDialog
          title="Alert"
          contentMsg="You are in embargo time or the fixture is locked."
        />
      )}
    </Box>
  );
};

export default TeamInfo;
