import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation, ClientContext } from "graphql-hooks";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout.js";
import { Grid } from "@material-ui/core";
import {
  GET_USERPROFILE_QUERY
} from "../graphQL/user_qry";
import { GET_ALL_SLEAGUETEAMS_QUERY } from "../graphQL/sleague_qry";
import "./userProfile/UserProfile/profileDesktopStyles.sass";
import {project_url, admin_url, secretPass} from "../store/data/Constants";
import CryptoJS from "crypto-js";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/*##########################################################################
## Const : Dashboard
## Description : Welcome page
## Author : RR
## Date: : 2022/05/08
##########################################################################*/
const Dashboard = () => {
  const [userData, setUserData] = useState([]);
  const [userFavTeam, setUserFavTeam] = useState("");
  const [allSleagueTeams, setAllSleagueTeams] = useState([]);
  const navigate = useNavigate();
  const {
    loading: loadingSleague,
    error: errorSleague,
    data: dataSleague
  } = useQuery(GET_ALL_SLEAGUETEAMS_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [{ query: GET_USERPROFILE_QUERY }],
    awaitRefetchQueries: true
  });
  const { loading, error, data } = useQuery(GET_USERPROFILE_QUERY, {
    fetchPolicy: "network-only"
  });
  
  useEffect(
    () => {
      if (!loadingSleague && dataSleague && dataSleague.allSleagueTeam) {
        setAllSleagueTeams(dataSleague.allSleagueTeam);
        console.log("allSleaguesData----", dataSleague);
		dataSleague.allSleagueTeam.map(sleagueteam => {
		  if (
            userData.userFavTeamField &&
            sleagueteam.sleagueTeamId ===
              userData.userFavTeamField.sleagueTeamId.sleagueTeamId
          ) {
            setUserFavTeam({ teamInfo: sleagueteam.teamId, sleagueteam });
			console.log(userFavTeam);
          }
        });
      }
      if (errorSleague) {
	    console.log("errorSleague----", dataSleague);
        if (
          errorSleague.graphQLErrors[0].message ===
          "You do not have permission to perform this action"
        )
          navigate("/lms/signin");
        if (errorSleague.graphQLErrors[0].message === "Signature has expired")
          navigate("/lms/signin");
      }
    },
    [dataSleague]
  );
  
  useEffect(
    () => {
      if (!loading && data && data.profile) {
        console.log("Profile----@@@@@@@@@@", data);
		setUserData(data.profile);
        localStorage.setItem("username", data.profile.username);
        if (data.profile.isSuperuser) localStorage.setItem("role", CryptoJS.AES.encrypt("Admin",secretPass).toString());
        else localStorage.setItem("role", CryptoJS.AES.encrypt("Regular User",secretPass).toString());
      }
      if (error){
	    console.log("error----", error);
	    if(error.graphQLErrors[0].message === "Signature has expired") {
          navigate("/lms/signin");
		}
      }
    },
    [data]
  );
  return (
    <>
      <Helmet>
        <title>Predictal | Dashboard | Admin</title>        
      </Helmet> 
      <Grid container direction="row" alignItems="flex-start">
        <Layout />
        <h1>Welcome to Predictal Team Selector</h1>
      </Grid>
    </>
  );
};

export default Dashboard;
