import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useManualQuery, ClientContext } from "graphql-hooks";
import { useForm } from "react-hook-form";
import { Grid } from "@material-ui/core";
import {project_url, admin_url} from "../store/data/Constants";

import Layout from "./Layout.js";
import {
  GET_TEAM_QURTY,
  GET_ALL_TEAM_QUERY,
  UPDATE_TEAM_MUTATION,
} from "../graphQL/team_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";

export default function TeamEdit() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [currTeamId, setCurrTeamId] = useState(useParams().teamId);
  const [currTeamData, setCurrTeamData] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  const [getTeamData] = useManualQuery(GET_TEAM_QURTY);
  const { data: allTeamsData } = useQuery(GET_ALL_TEAM_QUERY);
  const [updateTeam] = useMutation(UPDATE_TEAM_MUTATION);

  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);
  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);
  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(async () => {
    let { loading, error, data } = await getTeamData({
      variables: {
        teamId: parseInt(currTeamId),
      },
    });
    if (!loading && data && data.teamData) {
      setCurrTeamData(data.teamData[0]);
    }
    if (error && error.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [currTeamId]);

  const onSubmit = async formData => {
    const newData = {
      teamId: parseInt(currTeamData.teamId),
      name: formData.name,
      shortName: formData.shortName,
      abbrName: formData.abbrName,
      nickName: formData.nickName,
      code: formData.code,
      foundYear: formData.foundYear,
      acceptYear: formData.acceptYear,
      venueName: formData.venueName,
      logo: formData.logo,
    };
    const { data, error } = await updateTeam({ variables: newData });
    if (error) {
      setFailedMessage("Unfortunately an error occurred.");
    } else {
      setSuccessMessage("Team data update successfully.");
      setTimeout(() => {
        navigate(`/lms/${admin_url}/team`);
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Predictal | Edit A Team | Admin</title>
      </Helmet>
      {currTeamData && (
        <Grid container direction="row" alignItems="flex-start">
          <Layout />
          <form
            className={adminStyle.form}
            id="team_form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h2 className={adminStyle.h2}>Edit team: {currTeamData.name}</h2>
            <div>
              <label className={adminStyle.label} htmlFor="name">
                Name
              </label>
              <input
                className={adminStyle.input}
                id="name"
                required={true}
                {...register("name", {
                  validate: {
                    uniqName: v =>
                      !allTeamsData.team.find(
                        t => t.name === v && t.teamId !== currTeamData.teamId
                      ),
                  },
                })}
                defaultValue={currTeamData.name}
              />
              {errors.name && errors.name.type === "uniqName" && (
                <span>This team is currently exists</span>
              )}
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="shortName">
                Short Name
              </label>
              <input
                className={adminStyle.input}
                id="shortName"
                required={true}
                {...register("shortName")}
                defaultValue={currTeamData.shortName}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="abbrName">
                Abbreviation Name
              </label>
              <input
                className={adminStyle.input}
                id="abbrName"
                required={true}
                {...register("abbrName")}
                defaultValue={currTeamData.abbrName}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="nickName">
                Nick Name
              </label>
              <input
                className={adminStyle.input}
                id="nickName"
                required={true}
                {...register("nickName")}
                defaultValue={currTeamData.nickName}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="logo">
                Logo
              </label>
              <input className={adminStyle.input} {...register("logo")} type="file" />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="code">
                Code
              </label>
              <input
                className={adminStyle.input}
                id="code"
                required={true}
                {...register("code")}
                defaultValue={currTeamData.code}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="foundYear">
                Found Year
              </label>
              <input
                className={adminStyle.input}
                id="foundYear"
                required={true}
                {...register("foundYear")}
                defaultValue={currTeamData.foundYear}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="acceptYear">
                Accept Year
              </label>
              <input
                className={adminStyle.input}
                id="acceptYear"
                required={true}
                {...register("acceptYear")}
                defaultValue={currTeamData.acceptYear}
              />
            </div>
            <div>
              <label className={adminStyle.label} htmlFor="venueName">
                Venue Name
              </label>
              <input
                className={adminStyle.input}
                id="venueName"
                required={true}
                {...register("venueName")}
                defaultValue={currTeamData.venueName}
              />
            </div>
            <input className={adminStyle.input} type="submit" />
            {successMessage && <div className={adminStyle.success}>{successMessage}</div>}
            {failedMessage && <div className={adminStyle.error}>{failedMessage}</div>}
          </form>
        </Grid>
      )};
    </>
  );
}
