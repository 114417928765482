const user_TS_By_userId = `query userTSByUserId{
  userTSByUserid{
    fixtureTeamId{
      fixtureTeamId
      calcSPUsersDate
      fixtureId{
        fixtureId
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
      }
      sleagueTeamId{
        sleagueTeamId
        sleagueId{
          sleagueId
          spMax
        }
      }
      coachSeq
    }
    home{
      sleagueTeamId{
        sleagueTeamId
        teamId{
          shortName
          logo
        }
      }
      scoreFinal
    }

    guest{
      sleagueTeamId{
        sleagueTeamId
        teamId{
          shortName
          logo
        }
      }
      scoreFinal
    }
    awardSP
    awardDate
    userSeq
    userSPSeq
  }
}`;

const all_userTS_by_fixtureId_sleagueTeamId = `query($fixtureId: Int!,$sleagueTeamId: Int!){
  allUsertsByFixtureidSleagueteamid(fixtureId: $fixtureId,
                                     sleagueTeamId: $sleagueTeamId)
  {
    userTSId
    userId
    {
      id
      username
    }
    userSeq
    userSeqSlice
  }
}`;

const user_TS_By_selected_userId =  `query ($userId:Int!) {
  userTSBySelectedUserid (userId:$userId)
  {
    fixtureTeamId{
      fixtureTeamId
      calcSPUsersDate
      fixtureId{
        fixtureId
        fixtureWeek{
          playedWeek
          scheduledDate
          weekId {
            weekId
            name
          }
        }
      }
      sleagueTeamId{
        sleagueTeamId
      }
      coachSeq
    }
    home{
      sleagueTeamId{
        sleagueTeamId
        teamId{
          shortName
          logo
        }
      }
      scoreFinal
    }

    guest{
      sleagueTeamId{
        sleagueTeamId
        teamId{
          shortName
          logo
        }
      }
      scoreFinal
    }
    awardSP
    awardDate
    userSeq
    userSPSeq
  }
}`

const user_TS_Award_By_selected_userId =  `query ($userId:Int!) {
  userTSAwardBySelectedUserid (userId:$userId)
  {   
    awardSP    
  }
}`

const requestPDF_mutate = `
    mutation requestPdfMutate(
      $userTSId: Int!) {
        requestPdfMutate(
          userTSId: $userTSId) {
            pdfFile
            result
      }
    }
`;

module.exports = {
 USERTS_BYUSERID_QUERY: user_TS_By_userId,
 ALL_USERTS_BY_FIXTUREID_SLEAGUETEAMID: all_userTS_by_fixtureId_sleagueTeamId,
 USERTS_BY_SELECTED_USERID_QUERY: user_TS_By_selected_userId,
 USERTS_AWARD_BY_SELECTED_USERID_QUERY: user_TS_Award_By_selected_userId,
 USER_TS_PDF:  requestPDF_mutate
};
