import React, { useState, useEffect } from "react";
import { useQuery, useManualQuery, useMutation } from "graphql-hooks";

import { DataGrid } from "@material-ui/data-grid";
import { Grid, Button } from "@material-ui/core";

import Layout from "./Layout.js";
import adminStyle from "./Admin.module.scss";

const ALL_LEAGUES_QUERY = `query{
  league{
    leagueId
    title
    subtitle
  }
}`;

const SLEAGUE_QUERY = `query sleague($leagueId:Int!){
  sleague(leagueId:$leagueId){
    sleagueId
    title
    seasonName
  }
}`;


const RUN_SET_USER_FAV_TEAM = `query setUserFavTeamSeq($selectedSleagueId: Int){
  setUserFavTeamSeq(selectedSleagueId: $selectedSleagueId)
  {
    
      userFavTeamId
      userId
      {
        id
        username
      }
      sleagueTeamId
      {
        sleagueId
        {
          seasonName
        }
        teamId
        {
          name
        }
      }
      favTeamSeq
  }
  
}`;


  

export default function SetUserFavTeam() {
  //----------- Get All Leagues
  const [allLeagues, setAllLeagues] = useState([]);
  const [selcLeague, setSelcLeague] = useState(-1);
  const [userData, setUserData] = useState([]);
  const [pageSize, setPageSize] = React.useState(50);
  const allLeaguesQuery = useQuery(ALL_LEAGUES_QUERY);

  useEffect(() => {
    if (allLeaguesQuery.data && allLeaguesQuery.data.league) {
      setAllLeagues(allLeaguesQuery.data.league);
    }
  }, [allLeaguesQuery.data]);

  //----------- Get list of Seasons based on the selected League
  const [leagueSeasons, setLeagueSeasons] = useState([]);
  const [selcSeason, setSelcSeason] = useState(-1);
  const leagueSeasonsQuery = useQuery(SLEAGUE_QUERY, {
    variables: {
      leagueId: selcLeague,
    },
  });
  useEffect(() => {
    if (selcLeague === -1) return setLeagueSeasons([]);
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.sleague) {
      setLeagueSeasons(leagueSeasonsQuery.data.sleague);
    }
  }, [leagueSeasonsQuery.data, selcLeague]);

 

  // Run the script through query
  const [runSetUserFavTeam] = useManualQuery(RUN_SET_USER_FAV_TEAM, {
    fetchPolicy: "network-only",
  });
  const runUpdateScript = async () => {
    // Run the script through Query;
    
    const {
      loading: runUserFavTeamLoading,
      error: runUserFavTeamError,
      data: runUserFavTeamData,
    } = await runSetUserFavTeam({
      variables: {
        selectedSleagueId: selcSeason,
      },
    });
    //----------- Get All user after set fav team for new season
    
    if (!runUserFavTeamLoading && runUserFavTeamData.setUserFavTeamSeq) {
         console.log('runUserFavTeamData.setUserFavTeamSeq',runUserFavTeamData.setUserFavTeamSeq)
         setUserData(runUserFavTeamData.setUserFavTeamSeq);
      }
    };
  

  
  const leagueHandleChange = e => {
    if (e.target.value !== "") {
      setSelcLeague(parseInt(e.target.value));
    } else {
      setSelcLeague(-1);
      setSelcSeason(-1);      
    }
  };

  const seasonHandleChange = e => {
    if (e.target.value !== "") {
      setSelcSeason(parseInt(e.target.value));
    } else {
      setSelcSeason(-1);      
    }
  };

  const columns = [
  {
    field: "userName",
    headerName: "User Name",
    width: 250,
    
  },
  {
    field: "team",
    headerName: "Favorite Team",
    width: 200,
    
  },

   {
    field: "favTeamSeq",
    headerName: "Favorite Team Seq.",
    width: 200,
    
  },
 ];
   
  const rows = userData.map(user => ({
    id: user.userFavTeamId,
    userName: user.userId.username,
    team: user.sleagueTeamId.teamId.name,
    favTeamSeq: user.favTeamSeq,
  }));

 console.log('userData',userData)

 if (userData.lenght === 0 ) return 

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2 className={adminStyle.h2}>Update Seq No in Players Stats</h2>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <select
              className={adminStyle.select}
              onChange={leagueHandleChange}
              // value={currLeagueId}
            >
              <option value="">Select a League ...</option>
              {allLeagues.map(({ leagueId, title }) => (
                <option key={leagueId} value={leagueId}>
                  {title}
                </option>
              ))}
            </select>

            <select
              className={adminStyle.select}
              onChange={seasonHandleChange}
              // value={currSleagueTeamId}
            >
              <option value="">Select a Season ...</option>
              {leagueSeasons.map(({ sleagueId, title, seasonName }) => (
                <option key={sleagueId} value={sleagueId}>
                  {seasonName}
                </option>
              ))}
            </select>

            
          </form>
        </Grid>

        <Grid item>
          <h4>Update User Fav  Team for selected season:</h4>
          <Button
            className={adminStyle.button}
            variant="contained"
            color="primary"
            fullWidth={false}
            disabled={selcSeason === -1}
            style={{
              textTransform: "none",
              marginBottom: "10px",
            }}
            onClick={runUpdateScript}
          >
            Run
          </Button>

          
          <DataGrid
            columns={columns}
            rows={rows}            
            rowHeight={80}            
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowsPerPageOptions={[50, 100]}
            pagination
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
