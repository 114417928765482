const CreatePositionPlayedAggr_mutate = `
    mutation createPositionplayedaggr(
      $sleagueTeamPlayerId: Int!,
      $filterId: Int!, 
      $GKCount: Int,
      $LDEFCount: Int,
      $CDEFCount: Int,
      $RDEFCount: Int,
      $LMFCount: Int,
      $CMFCount: Int,
      $RMFCount: Int,
      $LFORCount: Int,
      $CFORCount: Int,
      $RFORCount: Int,
      $GKMin: Int,
      $LDEFMin: Int,
      $CDEFMin: Int,
      $RDEFMin: Int,
      $LMFMin: Int,
      $CMFMin: Int,
      $RMFMin: Int,
      $LFORMin: Int,
      $CFORMin: Int,
      $RFORMin: Int) {
      createPositionplayedaggr(
        sleagueTeamPlayerId: $sleagueTeamPlayerId,
        filterId: $filterId,
        GKCount: $GKCount,
        LDEFCount: $LDEFCount,
        CDEFCount: $CDEFCount,
        RDEFCount: $RDEFCount,
        LMFCount: $LMFCount,
        CMFCount: $CMFCount,
        RMFCount: $RMFCount,
        LFORCount: $LFORCount,
        CFORCount: $CFORCount,
        RFORCount: $RFORCount,
        GKMin: $GKMin,
        LDEFMin: $LDEFMin,
        CDEFMin: $CDEFMin,
        RDEFMin: $RDEFMin,
        LMFMin: $LMFMin,
        CMFMin: $CMFMin,
        RMFMin: $RMFMin,
        LFORMin: $LFORMin,
        CFORMin: $CFORMin,
        RFORMin: $RFORMin) {
        positionPlayedAggrId
        sleagueTeamPlayerId
        filterId
        GKCount
        LDEFCount
        CDEFCount
        RDEFCount
        LMFCount
        CMFCount
        RMFCount
        LFORCount
        CFORCount
        RFORCount
        GKMin
        LDEFMin
        CDEFMin
        RDEFMin
        LMFMin
        CMFMin
        RMFMin
        LFORMin
        CFORMin
        RFORMin
      }
    }
`;

const GetAllPositionPlayedAggr_query = `
query{
      positionPlayedAggr{
        positionPlayedAggrId
        sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        GKCount
        LDEFCount
        CDEFCount
        RDEFCount
        LMFCount
        CMFCount
        RMFCount
        LFORCount
        CFORCount
        RFORCount
        GKMin
        LDEFMin
        CDEFMin
        RDEFMin
        LMFMin
        CMFMin
        RMFMin
        LFORMin
        CFORMin
        RFORMin
    }
  }
`;

const GetPositionPlayedAggrBySleagueTeamId_query = `

  query($sleagueTeamId: Int!){

    positionPlayedAggrSleagueteam(sleagueTeamId: $sleagueTeamId){
        positionPlayedAggrId
        sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        GKCount
        LDEFCount
        CDEFCount
        RDEFCount
        LMFCount
        CMFCount
        RMFCount
        LFORCount
        CFORCount
        RFORCount
        GKMin
        LDEFMin
        CDEFMin
        RDEFMin
        LMFMin
        CMFMin
        RMFMin
        LFORMin
        CFORMin
        RFORMin
    }
  }
`;

const EditPositionPlayedAggr_mutation = `
mutation editPositionplayedaggr($positionPlayedAggrId: Int!, 
                                $GKCount: Int, $LDEFCount: Int,
                                $CDEFCount: Int, $RDEFCount: Int, $LMFCount: Int,
                                $CMFCount: Int, $RMFCount: Int, $LFORCount: Int,
                                $CFORCount: Int, $RFORCount: Int, $GKMin: Int,
                                $LDEFMin: Int, $CDEFMin: Int, $RDEFMin: Int,
                                $LMFMin: Int, $CMFMin: Int, $RMFMin: Int,
                                $LFORMin: Int, $CFORMin: Int, $RFORMin: Int
) {
  editPositionplayedaggr(
    positionPlayedAggrId: $positionPlayedAggrId,
    GKCount: $GKCount,
    LDEFCount: $LDEFCount,
    CDEFCount: $CDEFCount,
    RDEFCount: $RDEFCount,
    LMFCount: $LMFCount,
    CMFCount: $CMFCount,
    RMFCount: $RMFCount,
    LFORCount: $LFORCount,
    CFORCount: $CFORCount,
    RFORCount: $RFORCount,
    GKMin: $GKMin,
    LDEFMin: $LDEFMin,
    CDEFMin: $CDEFMin,
    RDEFMin: $RDEFMin,
    LMFMin: $LMFMin,
    CMFMin: $CMFMin,
    RMFMin: $RMFMin,
    LFORMin: $LFORMin,
    CFORMin: $CFORMin,
    RFORMin: $RFORMin
  ) {
    positionPlayedAggr{
      positionPlayedAggrId
      sleagueTeamPlayerId{
          sleagueTeamPlayerId
          sleagueTeamId{
            sleagueId{
              sleagueId
              seasonName
            }
            teamId{
              name
              code
            }
          }
          playerId{
            playerId
            fName
            lName
          }
        }
        filterId{
          filterId
        }

        GKCount
        LDEFCount
        CDEFCount
        RDEFCount
        LMFCount
        CMFCount
        RMFCount
        LFORCount
        CFORCount
        RFORCount
        GKMin
        LDEFMin
        CDEFMin
        RDEFMin
        LMFMin
        CMFMin
        RMFMin
        LFORMin
        CFORMin
        RFORMin
    }
  }
}
`;

const GetPositionPlayedAggrBySTPId_query = `

  query($sleagueTeamPlayerId: Int!){
    positionPlayedAggrSleagueteamplayer(
      sleagueTeamPlayerId: $sleagueTeamPlayerId)
      {
      positionPlayedAggrId
      sleagueTeamPlayerId{
        sleagueTeamPlayerId
        sleagueTeamId{
          sleagueId{
            sleagueId
            seasonName
          }
          teamId{
            name
            code
          }
        }
        playerId{
          playerId
          fName
          lName
        }
      }
      filterId{
        filterId
      }
      GKCount
      LDEFCount
      CDEFCount
      RDEFCount
      LMFCount
      CMFCount
      RMFCount
      LFORCount
      CFORCount
      RFORCount
      GKMin
      LDEFMin
      CDEFMin
      RDEFMin
      LMFMin
      CMFMin
      RMFMin
      LFORMin
      CFORMin
      RFORMin        
    }
  }
`;


module.exports = {
  CREATE_POSITION_PLAYED_AGGR_MUTATION : CreatePositionPlayedAggr_mutate,
  GET_ALL_POSITION_PLAYED_AGGR_QUERY : GetAllPositionPlayedAggr_query,
  EDIT_POSITION_PLAYED_AGGR_MUTATION : EditPositionPlayedAggr_mutation,
  GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_PLAYER_ID_QUERY: 
    GetPositionPlayedAggrBySTPId_query,
  GET_POSITION_PLAYED_AGGR_By_SLEAGUE_TEAM_ID_QUERY: 
    GetPositionPlayedAggrBySleagueTeamId_query
}
