import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useManualQuery, useMutation, ClientContext } from "graphql-hooks";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Layout from "./Layout.js";
import { useNavigate } from "react-router-dom";
import { project_url, admin_url } from "../store/data/Constants";

//Material UI Imports
import { DataGrid } from "@material-ui/data-grid";
import {
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { GET_ALL_LEAGUES_QUERY } from "../graphQL/league_qry";
import { GET_SLEAGUE_TEAMS_QUERY } from "../graphQL/sleague_team_query";
import { GET_ALL_SLEAGUES_QUERY, GET_SLEAGUE_QUERY } from "../graphQL/sleague_qry";
import { CURRENT_LEAGUE_SEASONS_QUERY } from "../graphQL/current_bucket_sleague_qry";
import { REFRESH_TOKEN_MUTATION } from "../graphQL/user_qry";
import adminStyle from "./Admin.module.scss";
// import "../styles.css";

import DeductAddPoints from "./DeductAddPoints";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

///#############

export default function SleagueTeamTableStanding() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMessage, setFailedMessage] = useState("");
  const [pageSize, setPageSize] = React.useState(20);
  const [teamsData, setTeamsData] = useState([]);
  const [leaguesData, setLeaguesData] = useState([]);
  const [sleaguesData, setSleaguesData] = useState([]);
  const [currentLeagueId, setCurrentLeagueId] = useState("");
  const [currentSLeagueId, setCurrentSLeagueId] = useState("");

  const [deductAddDlg, setDeductAddDlg] = useState(false);

  const navigate = useNavigate();
  const [fetchSleagues] = useManualQuery(GET_SLEAGUE_QUERY, {
    fetchPolicy: "network-only",
  });
  const [fetchTeams] = useManualQuery(GET_SLEAGUE_TEAMS_QUERY, {
    fetchPolicy: "network-only",
  });
  const {
    loading: leagueLoading,
    error: leagueError,
    data: leagueData,
  } = useQuery(GET_ALL_LEAGUES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: sleagueLoading,
    error: sleagueError,
    data: sleagueData,
  } = useQuery(GET_ALL_SLEAGUES_QUERY, {
    fetchPolicy: "network-only",
  });
  const [refreshToken] = useMutation(REFRESH_TOKEN_MUTATION, {
    onError: err => {
      console.log(err);
    },
  });
  const client = useContext(ClientContext);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      onRefreshToken();
    } else navigate(`/lms/${admin_url}/signin`);
  }, []);

  const onRefreshToken = async () => {
    const { data: refreshData, error: refreshError } = await refreshToken({
      variables: { refreshToken: localStorage.getItem("refreshToken") },
    });
    if (refreshError) {
      if (
        refreshError.graphQLErrors[0].message ===
        "You do not have permission to perform this action"
      )
        navigate(`/lms/${admin_url}/signin`);
      if (refreshError.graphQLErrors[0].message === "Signature has expired")
        navigate(`/lms/${admin_url}/signin`);
    }
    if (refreshData && refreshData.refreshToken) {
      localStorage.setItem("token", refreshData.refreshToken.token);
      localStorage.setItem("refreshToken", refreshData.refreshToken.refreshToken);
      client.setHeader("Authorization", `JWT ${refreshData.refreshToken.token}`);
    }
  };

  useEffect(() => {
    if (!leagueLoading && leagueData && leagueData.league) {
      setLeaguesData(leagueData.league);
    }
    if (leagueError && leagueError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  }, [leagueData]);

  const leagueSeasonsQuery = useQuery(CURRENT_LEAGUE_SEASONS_QUERY);
  useEffect(async () => {
    if (leagueSeasonsQuery.data && leagueSeasonsQuery.data.currentBucketSleague) {
      setCurrentLeagueId(
        leagueSeasonsQuery.data.currentBucketSleague[0].sleagueId.leagueId.leagueId
      );

      const {
        loading: sleagueLoading,
        error: sleagueError,
        data: sleagueData,
      } = await fetchSleagues({
        variables: {
          leagueId: parseInt(
            leagueSeasonsQuery.data.currentBucketSleague[0].sleagueId.leagueId.leagueId
          ),
        },
      });

      if (!sleagueLoading && sleagueData && sleagueData.sleague) {
        setSleaguesData(sleagueData.sleague);
        setCurrentSLeagueId(
          leagueSeasonsQuery.data.currentBucketSleague[0].sleagueId.sleagueId
        );
        const {
          loading: teamLoading,
          error: teamError,
          data: allTeamsData,
        } = await fetchTeams({
          variables: {
            sleagueId: parseInt(
              leagueSeasonsQuery.data.currentBucketSleague[0].sleagueId.sleagueId
            ),
          },
        });

        if (!teamLoading && allTeamsData && allTeamsData.sleagueTeam) {
          // Sorting teams based on tie-breaker rules
          let sortedTeams = sortSleagueTeams(allTeamsData.sleagueTeam);
          setTeamsData(sortedTeams);
        }
      }
    }
  }, [leagueSeasonsQuery.data]);

  // Sorting teams based on tie-breaker rules
  // ( first : noPoints, second: noGoalDifference, third: noGoalsFor)
  const sortSleagueTeams = leagueTeams => {
    leagueTeams.sort(
      (a, b) =>
        b.noPoints - a.noPoints ||
        b.noGoalDifference - a.noGoalDifference ||
        b.noGoalsFor - a.noGoalsFor
    );

    return leagueTeams;
  };

  const handleLeagueChange = async e => {
    const tid = e.target.value;

    const {
      loading: sleagueLoading,
      error: sleagueError,
      data: sleagueData,
    } = await fetchSleagues({
      variables: {
        leagueId: parseInt(tid),
      },
    });

    if (!sleagueLoading && sleagueData && sleagueData.sleague) {
      setSleaguesData(sleagueData.sleague);
    }
    if (
      sleagueError &&
      sleagueError.graphQLErrors[0].message === "Signature has expired"
    ) {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  const handleSleagueChange = async e => {
    const tid = e.target.value;
    setCurrentSLeagueId(parseInt(tid));

    console.log(tid);
    const {
      loading: teamLoading,
      error: teamError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: { sleagueId: parseInt(tid) },
    });

    if (!teamLoading && allTeamsData && allTeamsData.sleagueTeam) {
      console.log(allTeamsData);
      // Sorting teams based on tie-breaker rules
      let sortedTeams = sortSleagueTeams(allTeamsData.sleagueTeam);
      setTeamsData(sortedTeams);
    }
    if (teamError && teamError.graphQLErrors[0].message === "Signature has expired") {
      navigate(`/lms/${admin_url}/signin`);
    }
  };

  setTimeout(() => {
    setSuccessMessage("");
    setFailedMessage("");
  }, 15000);

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 60,
      editable: false,
      renderCell: params => (
        <img src={"/lms/media/" + params.value} height={40} width={40} />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
    },

    {
      field: "noGames",
      headerName: "No. of Games",
      width: 180,
      editable: false,
    },
    {
      field: "noWins",
      headerName: "No. of Wins",
      width: 180,
      editable: false,
    },
    {
      field: "noDraws",
      headerName: "No. of Draws",
      width: 180,
      editable: false,
    },
    {
      field: "noLosses",
      headerName: "No. of Losses",
      width: 200,
      editable: false,
    },
    {
      field: "noGoalsFor",
      headerName: "No. of Goals For",
      width: 200,
      editable: false,
    },
    {
      field: "noGoalsAgainst",
      headerName: "No. of Goals Against",
      width: 300,
      editable: false,
    },
    {
      field: "noGoalDifference",
      headerName: "No. of Goal Difference",
      width: 300,
      editable: false,
    },
    {
      field: "noPoints",
      headerName: "No. of Points",
      width: 180,
      editable: false,
      renderCell: params => {
        if (params.row.notes)
          return <label title={params.row.notes}> {params.row.noPoints}* </label>;
        return <>{params.row.noPoints}</>;
      },
    },
  ];
  const rows = teamsData.map((slt, id) => ({
    id: slt.teamId.teamId,
    name: slt.teamId.name,
    noGames: slt.noGames,
    noWins: slt.noWins,
    noDraws: slt.noDraws,
    noLosses: slt.noLosses,
    noGoalsFor: slt.noGoalsFor,
    noGoalsAgainst: slt.noGoalsAgainst,
    noGoalDifference: slt.noGoalDifference,
    noPoints: slt.noPoints,
    logo: slt.teamId.logo,
    notes: slt.notes,
  }));

  const handleDeductAddDlgClose = async () => {
    setDeductAddDlg(false);

    const {
      loading: teamLoading,
      error: teamError,
      data: allTeamsData,
    } = await fetchTeams({
      variables: {
        sleagueId: parseInt(
          leagueSeasonsQuery.data.currentBucketSleague[0].sleagueId.sleagueId
        ),
      },
    });

    if (!teamLoading && allTeamsData && allTeamsData.sleagueTeam) {
      // Sorting teams based on tie-breaker rules
      let sortedTeams = sortSleagueTeams(allTeamsData.sleagueTeam);
      setTeamsData(sortedTeams);
    }
  };

  return (
    <Grid container direction="row">
      <Layout />
      <Grid container item direction="column" xs={9} spacing={1}>
        <Grid item>
          <h2 className={adminStyle.h2}>League Season Table</h2>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <label className={adminStyle.label} htmlFor="league">
              League
            </label>
            <select
              className={adminStyle.select}
              id="league"
              {...register("league")}
              onChange={handleLeagueChange}
              value={currentLeagueId}
            >
              {leaguesData.map((league, i) => (
                <option key={i} value={league.leagueId}>
                  {league.title}
                </option>
              ))}
            </select>
          </form>
        </Grid>
        <Grid item>
          <form className={adminStyle.form}>
            <label className={adminStyle.label} htmlFor="season">
              Season
            </label>
            <select
              className={adminStyle.select}
              id="season"
              {...register("sleague")}
              onChange={handleSleagueChange}
              value={currentSLeagueId}
            >
              {sleaguesData.map((sleague, i) => (
                <option key={i} value={sleague.sleagueId}>
                  {sleague.seasonName}
                </option>
              ))}
            </select>
          </form>
        </Grid>

        <Grid item>
          <Button
            className={adminStyle.button}
            variant="contained"
            color="primary"
            fullWidth={false}
            style={{
              textTransform: "none",
            }}
            onClick={() => setDeductAddDlg(true)}
          >
            Points Adjustment
          </Button>
        </Grid>

        <Grid item>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            hideFooterSelectedRowCount
            hideFooterPagination
          />
        </Grid>
      </Grid>

      <Dialog open={deductAddDlg} onClose={handleDeductAddDlgClose}>
        {/* <DialogTitle id="alert-dialog-title">{"Are You Sure mmm?"}</DialogTitle> */}
        <DialogContent>
          <DeductAddPoints
            slTeams={teamsData.slice()}
            cancelbtnFunc={handleDeductAddDlgClose}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={removeRecord}>Delete Player</Button>
          <Button onClick={handleDeductAddDlgClose} autoFocus>
            Cancel
          </Button>
        </DialogActions> */}
      </Dialog>
    </Grid>
  );
}
