const AddLeagueFormation_mutate = `
    mutation addLeagueFormation(
      $formationId: Int!,
      $leagueId: Int!) {
      addLeagueFormation(
        formationId: $formationId,
        leagueId: $leagueId) {
        formationLeagueId       
      }
    }
`;

const AllLeagueFormations_query = `
    query{
  allLeagueFormations{
    formationLeagueId
    formationId{
      formationId
      title
      formationSeqNO
      description
      formationPrimary
      createBy
      createDate
      updateBy
      updateDate
    }
    leagueId{
      leagueId
      title
      subtitle
      foundYear
      createBy
      createDate
      updateBy
      updateDate
    }    
    updateBy
    updateDate
    createBy
    createDate
  }
}
`;

const GetLeagueFormations_query = `

  query($leagueId: Int!){

    leagueFormation(leagueId: $leagueId) {
    formationLeagueId
    formationId{
      formationId
      title
      formationSeqNO
      description
      formationPrimary
      createBy
      createDate
      updateBy
      updateDate
    }
    leagueId{
      leagueId
      title
      subtitle
      foundYear
      createBy
      createDate
      updateBy
      updateDate
    }    
    updateBy
    updateDate
    createBy
    createDate
  }



  }

`

module.exports = {
  ADD_LEAGUE_FORMATION_MUTATION : AddLeagueFormation_mutate,
  ALL_LEAGUE_FORMATIONS_QUERY : AllLeagueFormations_query,
  GET_LEAGUE_FORMATIONS_QUERY: GetLeagueFormations_query,
}
